import { createContext } from "react";
import { IBreakout, IConnector } from "../../../workspace/assembly/breakout/types";
import { Length } from "../../../workspace/assembly/length/types";
import { IPosition, ISize } from "../types";
import { BreakoutAnnotationContainer } from "./annotation/types";
import { BreakoutMarkersContainers } from "./marker/types";

export type BreakoutProps = Partial<IBreakout> & Partial<IBreakoutGraphic> & IPosition & ISize & IOffsetPosition & IBreakoutInfo;

export interface IBreakoutConnector {
    groupPosition: number,
    groupLength: Length,
    connector: IConnector,
    isLastGroupConnector: boolean
}

export interface IBreakoutInfo {
    connectors: IBreakoutConnector[],
    trunkLength: number,
    uniqueLegLengths: number[], // Unique leg lengths for the breakout
    uniqueGroupLengths: number[], // Unique group lengths for the breakout
    groupsUniqueLegLengths: { groupPosition: number, groupOffset: number, uniqueLegLengths: number[], hasGap: boolean }[] // Unique leg lengths for each groups
}

export interface IOffsetPosition {
    offset: number
}

export interface IBreakoutGraphic {
    trunkFurcation: IPosition,
}

export const BreakoutContainer = "breakout-container";
export const BreakoutShield = "breakout-shield";
export const BreakoutFurcation = "breakout-furcation";
export const BreakoutLabel = "breakout-label";

export const BreakoutContainers = {
    BreakoutContainer,
    BreakoutShield,
    BreakoutFurcation,
    BreakoutLabel,
    ...BreakoutMarkersContainers,
    BreakoutAnnotationContainer
} as const;

export interface IBreakoutContext {
    position: number;
    nbGroups: number;
    nbConnectors: number;
    nbConnectorsPerGroup: number;
    nbSpares: number;
}

export const initialBreakoutContext: IBreakoutContext = {
    position: -1,
    nbGroups: -1,
    nbConnectors: -1,
    nbConnectorsPerGroup: -1,
    nbSpares: -1
}

export const BreakoutContext = createContext(initialBreakoutContext);

export interface IBreakoutPosition {
    trunkFurcation: IPosition,
    connectorFurcation: IPosition,
    staggerPoints: IPosition[]
}