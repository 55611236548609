import { createSlice } from "@reduxjs/toolkit";
import { initialFiberMappingState } from "./types";
import { setSideNavigationAction, setBreakoutNavigationIndexAction, setConnectorNavigationIndexAction, setSelectedPinIndexAction, prepareFiberAssignmentsAction, handleAssignmentAction, breakAssignmentAction, handleUnusedPinAction, resetNavigationAction, clearAllAction } from "./actions";

const fiberMappingSlice = createSlice({
    initialState: initialFiberMappingState,
    name: "fiberMapping",
    reducers: {
        setSideNavigation: setSideNavigationAction,
        setBreakoutNavigationIndex: setBreakoutNavigationIndexAction,
        setConnectorNavigationIndex: setConnectorNavigationIndexAction,
        setSelectedPinIndex: setSelectedPinIndexAction,
        prepareFiberAssignments: prepareFiberAssignmentsAction,
        handleAssignment: handleAssignmentAction,
        breakAssignment: breakAssignmentAction,
        handleUnusedPin: handleUnusedPinAction,
        resetNavigation: resetNavigationAction,
        clearAll: clearAllAction
    }
});

export const FiberMappingReducer = fiberMappingSlice.reducer;
export const { setSideNavigation, setBreakoutNavigationIndex, setConnectorNavigationIndex, setSelectedPinIndex, prepareFiberAssignments, handleAssignment, breakAssignment, handleUnusedPin, resetNavigation, clearAll } = fiberMappingSlice.actions;