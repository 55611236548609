import React from 'react';
import { useLegTable } from './hooks';
import { ColorDialog } from '../../../../../ui/dialog/color/ColorDialog';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Checkbox } from '@orbit/checkbox';
import { IconButton } from '@orbit/icon-button';
import { Palette } from '@mui/icons-material';
import { LegRow } from '../row/LegRow';
import { NavigationBar } from '../../../../../ui/navigation/NavigationBar';
import { Typography } from '@orbit/theme-provider';

export const LegTable: React.FC = () => {
    const { colorDialogProps, masterCheckboxProps, tableHeaderProps, connectorColorIcon, labelColorIcon, legRows, navigationBarProps } = useLegTable();
    return (
        <>
            <ColorDialog {...colorDialogProps} />
            <div className='table-container'>
                <Table stickyHeader className="breakout-details-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Checkbox {...masterCheckboxProps} />
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" label={tableHeaderProps.connectorLabel} />
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" label={tableHeaderProps.groupLabel} />
                            </TableCell>
                            <TableCell align="left">
                                <div className="table-header-container">
                                    <Typography variant="body1" label={tableHeaderProps.connectorColorLabel} />
                                    <IconButton {...connectorColorIcon} icon={<Palette />} />
                                </div>
                            </TableCell>
                            <TableCell align="left" className="leg-label">
                                <Typography variant="body1" label={tableHeaderProps.label} />
                            </TableCell>
                            <TableCell align="left">
                                <div className="table-header-container">
                                    <Typography variant="body1" label={tableHeaderProps.labelColorLabel} />
                                    <IconButton {...labelColorIcon} icon={<Palette />} />
                                </div>
                            </TableCell>
                            <TableCell className="breakout-length-label" align="left">
                                <Typography variant="body1" label={tableHeaderProps.breakoutLengthLabel} />
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" label={tableHeaderProps.legLengthLabel} />
                            </TableCell>
                            <TableCell className="total-length" align="right">
                                <Typography variant="body1" label={tableHeaderProps.totalLengthLabel} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { legRows.map(r => <LegRow key={r.data.id} {...r} /> ) }
                    </TableBody>
                </Table>
            </div>
            <NavigationBar {...navigationBarProps} />
        </>
    )
}