import React from 'react';
import { useAnnotationAction } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { SpeakerNotes } from '@mui/icons-material';

export const AnnotationAction: React.FC = () => {
    const { iconProps } = useAnnotationAction();
    return (
        <IconButton {...iconProps} icon={<SpeakerNotes />} />
    )
}