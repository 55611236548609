import { WebService } from "./web-service";
import { AuthenticationService } from "./authentication/authentication-service";

export abstract class SecuredService extends WebService {
    constructor() {
        super();
        this.addDynamicHeader('Authorization', () => {
            const token = AuthenticationService.getToken();
            if (!token || !token.raw || token.isExpired()) {
                return null;
            }
            return 'Bearer ' + token.raw;
        });
    }
}