import React from 'react';
import { Sprite } from '@inlet/react-pixi';
import { ConnectorSpriteProps } from './types';
import { useConnectorSprite } from './hooks';

export const ConnectorSprite: React.FC<ConnectorSpriteProps> = (props) => {
    const { scale, anchor, texture } = useConnectorSprite(props);
    return (
        texture
            ? <Sprite
                scale={scale}
                name={props.sprite.name}
                anchor={anchor}
                x={props.x}
                y={props.y}
                rotation={props.rotation}
                texture={texture}
            />
            : null
    )
}