import React from 'react';
import { useMenuAction } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Close, MoreVert } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { TextButton } from '@orbit/button';

import './MenuAction.scss';

export const MenuAction: React.FC = () => {
    const { menuIconProps, menuProps, logout } = useMenuAction();
    return (
        <>
            <IconButton {...menuIconProps} icon={<MoreVert />} />
            <Menu {...menuProps}>
                <TextButton {...logout.buttonProps} startIcon={<Close />}>
                    {logout.label}
                </TextButton>
            </Menu>          
        </>
    )
}