import { IApiResult } from '../services/api-result';
import { AuthenticationService } from '../services/authentication/authentication-service';
import { logout } from '../workspace/store/reducer';

type DispatchType = (action: any) => void;
type ActionType = ((dispatch: DispatchType) => any);

export const createAsyncAction = (action: ActionType) => (async (dispatch: DispatchType) => action(dispatch));

export const createSecuredAsyncAction = (action: ActionType) => (async (dispatch: DispatchType) => {
    // Keep as safeguard
    const token = AuthenticationService.getToken();
    if (!token) {
        console.warn('Secured action detected missing token');
        dispatch(logout());
        return Promise.resolve();
    }
    return action(dispatch);
});

export const checkResult = (res: IApiResult<any>, dispatch: any) => {
    if (!res.succesful) {
        dispatch(apiError(res));
        return false;
    }
    return true;
};

export const extractData = <T>(res: IApiResult<T>, dispatch: any) => {
    if (checkResult(res, dispatch)) {
        return res.data;
    }
    return undefined;
};

export const apiError = (result: IApiResult<any>) => {
    return async () => {
        console.error(`API Error ${result.code}: ${result.reason}`);
    };
};
