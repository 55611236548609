import { OffsetType, OffsetTypes, Side } from "../../../assembly/breakout/types";
import { ConnLC, IConnectorType } from "../../../assembly/connector/types";
import { Length, Units } from "../../../assembly/length/types";

export interface IBreakoutSetSetup {
    sets: IBreakoutSet[],
    current: IBreakoutSet,
}

export interface IGroupStagger {
    legStagger: Length;
    offset: Length;
}

export interface IBreakoutSet {
    connectorType: IConnectorType;
    groupCount: number;
    connectorGroupCount: number;
    spareCount: number;
    count: number;
    lengthA: Length;
    lengthAp: Length;
    groupStagger: IGroupStagger[];
    startPosition?: number;
    side?: Side;
    offsetType: OffsetType;
}

export const initialGroupStagger: IGroupStagger = {
    legStagger: { value: 0, unit: Units.Millimeter },
    offset: { value: 0, unit: Units.Millimeter }
}

export const defaultUniformGroupStagger: IGroupStagger = {
    legStagger: { value: 0, unit: Units.Millimeter },
    offset: { value: 51, unit: Units.Millimeter }
}

export const initialBreakoutSet: IBreakoutSet = {
    connectorType: ConnLC,
    groupCount: 1,
    connectorGroupCount: 1,
    spareCount: 0,
    count: 1,
    lengthA: { value: 200, unit: Units.Millimeter },
    lengthAp: { value: 200, unit: Units.Millimeter },
    startPosition: 1,
    offsetType: OffsetTypes.Uniform,
    groupStagger: [ defaultUniformGroupStagger ]
}
export const initialBreakoutSetSetup: IBreakoutSetSetup = {
    current: initialBreakoutSet,
    sets: []
}

export interface ISymmetricBreakoutSetup {
    setup: IBreakoutSetSetup;
}

export interface IAsymmetricBreakoutSetup {
    sideASetup: IBreakoutSetSetup,
    sideBSetup: IBreakoutSetSetup
}

export type BreakoutSetupProps = IBreakoutSetSetup & { stepIndex?: number, key?: string }