import React from "react"
import { getConnectorTextColor } from "../../../../../ui/dialog/color/types"
import { Side } from "../../../../../workspace/assembly/breakout/types"

export const SN: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="140" height="700" viewBox="0 0 14 70">
            <g transform="translate(-28 -517)">
                <g transform="translate(28 517)">
                    <g fill="none" stroke="#484646" transform="translate(0 52)">
                        <rect stroke="none" width="14" height="3"/>
                        <rect fill="none" x="0.5" y="0.5" width="13" height="2"/>
                    </g>
                    <g fill="#aaa" stroke="#484646" transform="translate(4 44)">
                        <rect stroke="none" width="6" height="7"/>
                        <rect fill="none" x="0.5" y="0.5" width="5" height="6"/>
                    </g>
                    <g fill="#202022" transform="translate(-1195.526 -1576)">
                        <path stroke="none" d="M 1207.0263671875 1620.5 L 1198.0263671875 1620.5 L 1198.0263671875 1615.118041992188 L 1200.9736328125 1609.2236328125 L 1201.0263671875 1609.118041992188 L 1201.0263671875 1609 L 1201.0263671875 1580 L 1201.0263671875 1579.792846679688 L 1200.8798828125 1579.646484375 L 1199.0263671875 1577.792846679688 L 1199.0263671875 1577.207153320312 L 1199.733520507812 1576.5 L 1205.319213867188 1576.5 L 1206.0263671875 1577.207153320312 L 1206.0263671875 1577.792846679688 L 1204.1728515625 1579.646484375 L 1204.0263671875 1579.792846679688 L 1204.0263671875 1580 L 1204.0263671875 1609 L 1204.0263671875 1609.118041992188 L 1204.0791015625 1609.2236328125 L 1207.0263671875 1615.118041992188 L 1207.0263671875 1620.5 Z"/>
                        <path fill="#484646" stroke="none" d="M 1199.940551757812 1577 L 1199.5263671875 1577.414184570312 L 1199.5263671875 1577.585815429688 L 1201.233520507812 1579.292846679688 L 1201.5263671875 1579.585815429688 L 1201.5263671875 1580 L 1201.5263671875 1609 L 1201.5263671875 1609.236083984375 L 1201.420776367188 1609.447265625 L 1198.5263671875 1615.236083984375 L 1198.5263671875 1620 L 1206.5263671875 1620 L 1206.5263671875 1615.236083984375 L 1203.631958007812 1609.447265625 L 1203.5263671875 1609.236083984375 L 1203.5263671875 1609 L 1203.5263671875 1580 L 1203.5263671875 1579.585815429688 L 1203.819213867188 1579.292846679688 L 1205.5263671875 1577.585815429688 L 1205.5263671875 1577.414184570312 L 1205.112182617188 1577 L 1199.940551757812 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1577 L 1206.5263671875 1578 L 1204.5263671875 1580 L 1204.5263671875 1609 L 1207.5263671875 1615 L 1207.5263671875 1621 L 1197.5263671875 1621 L 1197.5263671875 1615 L 1200.5263671875 1609 L 1200.5263671875 1580 L 1198.5263671875 1578 L 1198.5263671875 1577 L 1199.5263671875 1576 Z"/>
                    </g>
                    <g fill="#045eaa" transform="translate(-1193.526 -1561)">
                        <path stroke="none" d="M 1204.319213867188 1628.5 L 1196.733520507812 1628.5 L 1195.0263671875 1626.792846679688 L 1195.0263671875 1625.081176757812 L 1196.000732421875 1622.158081054688 L 1196.0263671875 1622.081176757812 L 1196.0263671875 1622 L 1196.0263671875 1620 L 1196.0263671875 1619.5 L 1195.5263671875 1619.5 L 1195.0263671875 1619.5 L 1195.0263671875 1600.5 L 1198.0263671875 1600.5 L 1198.0263671875 1609 L 1198.0263671875 1609.005859375 L 1198.026489257812 1609.011596679688 C 1198.027954101562 1609.073852539062 1198.04931640625 1609.636474609375 1198.34912109375 1610.218505859375 C 1198.64501953125 1610.793212890625 1199.261840820312 1611.482055664062 1200.519287109375 1611.5 C 1200.535034179688 1611.500122070312 1200.550537109375 1611.500244140625 1200.566040039062 1611.500244140625 C 1201.536865234375 1611.500244140625 1202.298706054688 1611.047119140625 1202.711303710938 1610.224243164062 C 1203.00390625 1609.640625 1203.024780273438 1609.073974609375 1203.026245117188 1609.011352539062 L 1203.0263671875 1609.005615234375 L 1203.0263671875 1609 L 1203.0263671875 1600.5 L 1206.0263671875 1600.5 L 1206.0263671875 1619.5 L 1205.5263671875 1619.5 L 1205.0263671875 1619.5 L 1205.0263671875 1620 L 1205.0263671875 1622 L 1205.0263671875 1622.081176757812 L 1205.052001953125 1622.158081054688 L 1206.0263671875 1625.081176757812 L 1206.0263671875 1626.792846679688 L 1204.319213867188 1628.5 Z"/>
                        <path fill="#484646" stroke="none" d="M 1195.5263671875 1601 L 1195.5263671875 1619 L 1196.5263671875 1619 L 1196.5263671875 1620 L 1196.5263671875 1622 L 1196.5263671875 1622.162231445312 L 1196.47509765625 1622.316284179688 L 1195.5263671875 1625.162231445312 L 1195.5263671875 1626.585815429688 L 1196.940551757812 1628 L 1204.112182617188 1628 L 1205.5263671875 1626.585815429688 L 1205.5263671875 1625.162231445312 L 1204.57763671875 1622.316284179688 L 1204.5263671875 1622.162231445312 L 1204.5263671875 1622 L 1204.5263671875 1620 L 1204.5263671875 1619 L 1205.5263671875 1619 L 1205.5263671875 1601 L 1203.5263671875 1601 L 1203.5263671875 1609 L 1203.5263671875 1609.011352539062 L 1203.526123046875 1609.022705078125 C 1203.498901367188 1610.219604492188 1202.688232421875 1612.000244140625 1200.566040039062 1612.000244140625 C 1200.548217773438 1612.000244140625 1200.5302734375 1612.000122070312 1200.512084960938 1611.999877929688 C 1198.371826171875 1611.969360351562 1197.554077148438 1610.203125 1197.526611328125 1609.023315429688 L 1197.5263671875 1609.011596679688 L 1197.5263671875 1609 L 1197.5263671875 1601 L 1195.5263671875 1601 M 1194.5263671875 1600 L 1198.5263671875 1600 L 1198.5263671875 1609 C 1198.5263671875 1609 1198.572265625 1610.97216796875 1200.5263671875 1611 C 1200.539672851562 1611.000244140625 1200.552856445312 1611.000244140625 1200.566040039062 1611.000244140625 C 1202.48095703125 1611.000244140625 1202.5263671875 1609 1202.5263671875 1609 L 1202.5263671875 1600 L 1206.5263671875 1600 L 1206.5263671875 1620 L 1205.5263671875 1620 L 1205.5263671875 1622 L 1206.5263671875 1625 L 1206.5263671875 1627 L 1204.5263671875 1629 L 1196.5263671875 1629 L 1194.5263671875 1627 L 1194.5263671875 1625 L 1195.5263671875 1622 L 1195.5263671875 1620 L 1194.5263671875 1620 L 1194.5263671875 1600 Z"/>
                    </g>
                    <rect fill="#c4c4c4" width="2" height="2" transform="translate(4 68)"/>
                    <rect fill="#c4c4c4" width="2" height="2" transform="translate(8 68)"/>
                    <text id="A" transform="translate(10 63) rotate(180)" fill="#fff" fontSize="3" fontFamily="TheSansC4s" fontWeight="700">
                        <tspan x="0" y="0">A</tspan>
                    </text>
                    <text id="B" transform="translate(6 63) rotate(180)" fill="#fff" fontSize="3" fontFamily="TheSansC4s" fontWeight="700">
                        <tspan x="0" y="0">B</tspan>
                    </text>
                </g>
            </g>
        </svg>
    )
}

export const getSNSVGString = (color: string, side?: Side) => { 
    const textColor = getConnectorTextColor(color);
    const [ top, bottom ] = !side ? [ "A", "B" ] : [ "B", "A" ]
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="140" height="700" viewBox="0 0 14 70">
            <g transform="translate(-28 -517)">
                <g transform="translate(28 517)">
                    <g fill="none" stroke="#484646" transform="translate(0 52)">
                        <rect stroke="none" width="14" height="3"/>
                        <rect fill="none" x="0.5" y="0.5" width="13" height="2"/>
                    </g>
                    <g fill="#aaa" stroke="#484646" transform="translate(4 44)">
                        <rect stroke="none" width="6" height="7"/>
                        <rect fill="none" x="0.5" y="0.5" width="5" height="6"/>
                    </g>
                    <g fill="#202022" transform="translate(-1195.526 -1576)">
                        <path stroke="none" d="M 1207.0263671875 1620.5 L 1198.0263671875 1620.5 L 1198.0263671875 1615.118041992188 L 1200.9736328125 1609.2236328125 L 1201.0263671875 1609.118041992188 L 1201.0263671875 1609 L 1201.0263671875 1580 L 1201.0263671875 1579.792846679688 L 1200.8798828125 1579.646484375 L 1199.0263671875 1577.792846679688 L 1199.0263671875 1577.207153320312 L 1199.733520507812 1576.5 L 1205.319213867188 1576.5 L 1206.0263671875 1577.207153320312 L 1206.0263671875 1577.792846679688 L 1204.1728515625 1579.646484375 L 1204.0263671875 1579.792846679688 L 1204.0263671875 1580 L 1204.0263671875 1609 L 1204.0263671875 1609.118041992188 L 1204.0791015625 1609.2236328125 L 1207.0263671875 1615.118041992188 L 1207.0263671875 1620.5 Z"/>
                        <path fill="#484646" stroke="none" d="M 1199.940551757812 1577 L 1199.5263671875 1577.414184570312 L 1199.5263671875 1577.585815429688 L 1201.233520507812 1579.292846679688 L 1201.5263671875 1579.585815429688 L 1201.5263671875 1580 L 1201.5263671875 1609 L 1201.5263671875 1609.236083984375 L 1201.420776367188 1609.447265625 L 1198.5263671875 1615.236083984375 L 1198.5263671875 1620 L 1206.5263671875 1620 L 1206.5263671875 1615.236083984375 L 1203.631958007812 1609.447265625 L 1203.5263671875 1609.236083984375 L 1203.5263671875 1609 L 1203.5263671875 1580 L 1203.5263671875 1579.585815429688 L 1203.819213867188 1579.292846679688 L 1205.5263671875 1577.585815429688 L 1205.5263671875 1577.414184570312 L 1205.112182617188 1577 L 1199.940551757812 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1577 L 1206.5263671875 1578 L 1204.5263671875 1580 L 1204.5263671875 1609 L 1207.5263671875 1615 L 1207.5263671875 1621 L 1197.5263671875 1621 L 1197.5263671875 1615 L 1200.5263671875 1609 L 1200.5263671875 1580 L 1198.5263671875 1578 L 1198.5263671875 1577 L 1199.5263671875 1576 Z"/>
                    </g>
                    <g fill="${color}" transform="translate(-1193.526 -1561)">
                        <path stroke="none" d="M 1204.319213867188 1628.5 L 1196.733520507812 1628.5 L 1195.0263671875 1626.792846679688 L 1195.0263671875 1625.081176757812 L 1196.000732421875 1622.158081054688 L 1196.0263671875 1622.081176757812 L 1196.0263671875 1622 L 1196.0263671875 1620 L 1196.0263671875 1619.5 L 1195.5263671875 1619.5 L 1195.0263671875 1619.5 L 1195.0263671875 1600.5 L 1198.0263671875 1600.5 L 1198.0263671875 1609 L 1198.0263671875 1609.005859375 L 1198.026489257812 1609.011596679688 C 1198.027954101562 1609.073852539062 1198.04931640625 1609.636474609375 1198.34912109375 1610.218505859375 C 1198.64501953125 1610.793212890625 1199.261840820312 1611.482055664062 1200.519287109375 1611.5 C 1200.535034179688 1611.500122070312 1200.550537109375 1611.500244140625 1200.566040039062 1611.500244140625 C 1201.536865234375 1611.500244140625 1202.298706054688 1611.047119140625 1202.711303710938 1610.224243164062 C 1203.00390625 1609.640625 1203.024780273438 1609.073974609375 1203.026245117188 1609.011352539062 L 1203.0263671875 1609.005615234375 L 1203.0263671875 1609 L 1203.0263671875 1600.5 L 1206.0263671875 1600.5 L 1206.0263671875 1619.5 L 1205.5263671875 1619.5 L 1205.0263671875 1619.5 L 1205.0263671875 1620 L 1205.0263671875 1622 L 1205.0263671875 1622.081176757812 L 1205.052001953125 1622.158081054688 L 1206.0263671875 1625.081176757812 L 1206.0263671875 1626.792846679688 L 1204.319213867188 1628.5 Z"/>
                        <path fill="#484646" stroke="none" d="M 1195.5263671875 1601 L 1195.5263671875 1619 L 1196.5263671875 1619 L 1196.5263671875 1620 L 1196.5263671875 1622 L 1196.5263671875 1622.162231445312 L 1196.47509765625 1622.316284179688 L 1195.5263671875 1625.162231445312 L 1195.5263671875 1626.585815429688 L 1196.940551757812 1628 L 1204.112182617188 1628 L 1205.5263671875 1626.585815429688 L 1205.5263671875 1625.162231445312 L 1204.57763671875 1622.316284179688 L 1204.5263671875 1622.162231445312 L 1204.5263671875 1622 L 1204.5263671875 1620 L 1204.5263671875 1619 L 1205.5263671875 1619 L 1205.5263671875 1601 L 1203.5263671875 1601 L 1203.5263671875 1609 L 1203.5263671875 1609.011352539062 L 1203.526123046875 1609.022705078125 C 1203.498901367188 1610.219604492188 1202.688232421875 1612.000244140625 1200.566040039062 1612.000244140625 C 1200.548217773438 1612.000244140625 1200.5302734375 1612.000122070312 1200.512084960938 1611.999877929688 C 1198.371826171875 1611.969360351562 1197.554077148438 1610.203125 1197.526611328125 1609.023315429688 L 1197.5263671875 1609.011596679688 L 1197.5263671875 1609 L 1197.5263671875 1601 L 1195.5263671875 1601 M 1194.5263671875 1600 L 1198.5263671875 1600 L 1198.5263671875 1609 C 1198.5263671875 1609 1198.572265625 1610.97216796875 1200.5263671875 1611 C 1200.539672851562 1611.000244140625 1200.552856445312 1611.000244140625 1200.566040039062 1611.000244140625 C 1202.48095703125 1611.000244140625 1202.5263671875 1609 1202.5263671875 1609 L 1202.5263671875 1600 L 1206.5263671875 1600 L 1206.5263671875 1620 L 1205.5263671875 1620 L 1205.5263671875 1622 L 1206.5263671875 1625 L 1206.5263671875 1627 L 1204.5263671875 1629 L 1196.5263671875 1629 L 1194.5263671875 1627 L 1194.5263671875 1625 L 1195.5263671875 1622 L 1195.5263671875 1620 L 1194.5263671875 1620 L 1194.5263671875 1600 Z"/>
                    </g>
                    <rect fill="#c4c4c4" width="2" height="2" transform="translate(4 68)"/>
                    <rect fill="#c4c4c4" width="2" height="2" transform="translate(8 68)"/>
                    <text id="${top}" transform="translate(10 63) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                        <tspan x="0" y="0">${top}</tspan>
                    </text>
                    <text id="${bottom}" transform="translate(6 63) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                        <tspan x="0" y="0">${bottom}</tspan>
                    </text>
                </g>
            </g>
        </svg>
    `
}