import { PayloadAction } from "@reduxjs/toolkit";
import { IPrintDialogState } from "./types";

export const setDrawingNumberAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.header.drawingNumber = action.payload;
};

export const setDescriptionAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.header.description = action.payload;
};

export const setApplicationAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.header.application = action.payload;
};

export const setApprovalDateAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.approvalDate = action.payload;
};

export const setDrawnByAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.drawnBy = action.payload;
};

export const setInServiceDateAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.inServiceDate = action.payload;
};

export const setLocationAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.location = action.payload;
};

export const setNotesAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.notes = action.payload;
};

export const setRevisionAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.footer.revision = action.payload;
};

export const setHeaderTitleAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.reportTitle.headerTitle = action.payload;
};

export const setDrawingTitleAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.reportTitle.drawingTitle = action.payload;
};

export const setShowCableOverviewAction = (state: IPrintDialogState, action: PayloadAction<boolean>) => {
    state.reportTitle.showCableOverview = action.payload;
}

export const setEndADescriptionAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.header.endADescription = action.payload;
};

export const setEndBDescriptionAction = (state: IPrintDialogState, action: PayloadAction<string>) => {
    state.header.endBDescription = action.payload;
};

export const setPolarityDiagramAction = (state: IPrintDialogState, action: PayloadAction<boolean>) => {
    state.pages.polarityDiagram = action.payload;
};

export const setBuildPlanAction = (state: IPrintDialogState, action: PayloadAction<boolean>) => {
    state.pages.buildPlan = action.payload;
};

export const setConnectorViewsAction = (state: IPrintDialogState, action: PayloadAction<boolean>) => {
    state.pages.connectorViews = action.payload;
};