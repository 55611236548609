import React from 'react';
import { useWorkspace } from '../store/hooks';
import { TopBar } from '../top-bar/TopBar';
import { BottomBar } from '../bottom-bar/BottomBar';
import { CableSetup } from '../wizard/cable-setup/CableSetup';
import { UnitConverter } from '../top-bar/unit-converter/UnitConverter';
import { AssemblyEditDialog } from '../dialogs/assembly-edit/AssemblyEditDialog';
import { BreakoutDetails } from '../assembly/breakout/details/BreakoutDetails';
import { BreakoutEditDialog } from '../assembly/breakout/edit/BreakoutEditDialog';
import { ProjectDrawer } from '../project-drawer/ProjectDrawer';
import { AssemblyInfoDrawer } from '../assembly/info/AssemblyInfoDrawer';
import { AnnotationDialog } from '../annotation/AnnotationDialog';
import { SettingsDialog } from '../settings/SettingsDialog';
import { Polarity } from '../polarity/Polarity';
import { TimeOutDialog } from '../../login/time-out/TimeOutDialog';
import { PrintDialog } from '../report/print/PrintDialog';
import { TermsConditionsDialog } from '../terms-and-conditions/TermsConditionsDialog';
import { ActivityTracker } from '../tracker/ActivityTracker';
import { IdleDialog } from '../../login/idle/IdleDialog';
import { HelpDialog } from '../help/HelpDialog';

export const Overlay: React.FC = () => {
    useWorkspace();
    return (
        <div className="overlay" >
            <TopBar />
            <ActivityTracker />
            <IdleDialog />
            <CableSetup />
            <AssemblyEditDialog />
            <BreakoutDetails />
            <BreakoutEditDialog />
            <AssemblyInfoDrawer />
            <UnitConverter />
            <ProjectDrawer />
            <AnnotationDialog />
            <SettingsDialog />
            <Polarity />
            <TimeOutDialog />
            <PrintDialog />
            <TermsConditionsDialog />
            <HelpDialog />
            <BottomBar />
        </div>
    )
}