import React from 'react';
import { useLogin } from './hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../localization/types';
import { CorningGlassLogoUri } from './types';
import { OutlinedTextField } from '@orbit/text-field';
import { ContainedButton } from '@orbit/button';
import { Navigate } from 'react-router';
import { FontTokens, Typography } from '@orbit/theme-provider';

import "./Login.scss";

export const Login: React.FC = () => {
    const { secured, emailField, passwordField, errorText, loginProps, onLoginClick } = useLogin();
    const { t } = useTranslation();
    return (
        !secured ? 
            <div className="login-container">
                <div className="login-header">
                    <img className="corning-glass-logo" src={CorningGlassLogoUri} alt={t(LocalizationKeys.CabTitle)}/>
                </div>
                <div className="login-content">
                    <div className="cab-title">
                        <Typography variant="h5" label={t(LocalizationKeys.CabTitle)} fontToken={FontTokens.none} />
                     </div>
                    <div className="login">
                        <form onSubmit={onLoginClick}>
                            <div className="title-container">
                                <Typography variant="h6" label={t(LocalizationKeys.WelcomeBack)} fontToken={FontTokens.none} />
                                <Typography variant="caption" label={t(LocalizationKeys.LoginHelp)} fontToken={FontTokens.none} />
                            </div>
                            <div className="login-fields">
                                <OutlinedTextField {...emailField} />
                                <OutlinedTextField {...passwordField} />
                            </div>
                            <div className="login-error-message">
                                <Typography variant="body2" label={errorText} fontToken={FontTokens.none} />
                            </div>
                            <ContainedButton {...loginProps.buttonProps}>
                                {loginProps.label}
                            </ContainedButton>
                        </form>
                    </div>
                </div>
                <div className="login-footer">
                    <Typography variant="subtitle1" label={"\u00A9 " + t(LocalizationKeys.Copyright)} fontToken={FontTokens.none} />
                </div>
            </div> :
            <Navigate to="/"/>
    )
}