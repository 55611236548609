import { useSelector } from "react-redux";
import { isOptimusUserSelector } from "../../store/selectors";
import { annotationDataListSelector } from "../../assembly/store/selectors";

export const useAnnotationsTable = () => {
    const isOptimusUser = useSelector(isOptimusUserSelector);
    const annotations = useSelector(annotationDataListSelector(isOptimusUser));

    return { annotations };
};
