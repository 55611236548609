import { Side } from "../../assembly/breakout/types";
export interface IConnectorImageData {
    type: string;
    imageData: string;
    side?: Side
}

export interface IChip {
    index: number,
    imageData: string,
};

export interface IRowData {
    startY: number,
    finalY: number,
    maxIndex: number,
    pageNumber: number,
};

export const initialPageRow: IRowData = { startY: -1, finalY: -1, maxIndex: -1, pageNumber: -1 }

export interface ITableData {
    rows: IRowData[],
    cellY: number,
    totalPages: number,
    totalRows: number
};

export const initialTableData: ITableData = { totalPages: -1, rows: [], totalRows: -1, cellY: -1}

export function calculateRowHeight(firstTableData: ITableData, secondTableNumRows: number[], table: HTMLTableElement, pageNumber: number){
    const firstTableRow = firstTableData.rows.filter(r => r.pageNumber === pageNumber)[0]
    const numRows = calculateNumRows(table, firstTableData, secondTableNumRows, pageNumber)
    const maxHeight = firstTableData.cellY * firstTableRow.maxIndex;
    return maxHeight / numRows;
}

export const clamp = (num: number, min: number, max: number) => min > max ? min : Math.min(Math.max(num, min), max);

export function calculateNumRows(table: HTMLTableElement, firstTableData: ITableData, secondTableNumRows: number[], pageNumber: number){
    const totalRows = table.rows.length - 2
    const firstTableCurrentPage = firstTableData.rows.filter(r => r.pageNumber === pageNumber)[0]
    const proportionalRowValue = totalRows * firstTableCurrentPage.maxIndex / firstTableData.totalRows
    const prevPagesTotal = secondTableNumRows.reduce((a,b) => a + b, 0)
    if(firstTableData.totalPages === 1){
        return clamp(Math.round(proportionalRowValue), 1, totalRows)
    }
    if(pageNumber === firstTableData.totalPages){
        return clamp(Math.ceil(proportionalRowValue), 1, totalRows - prevPagesTotal)
    }
    const prevTotal = totalRows - prevPagesTotal - (firstTableData.totalPages - pageNumber)
    const numRows = clamp(Math.round(proportionalRowValue), 1, prevTotal < 1 ? 1 : prevTotal)
    return numRows
}

export function updateFirstTableData(firstTableData: ITableData, currentPageRow: IRowData, cursorY: number, pageNumber: number, totalRows: number){
    firstTableData.rows.push({
        ...currentPageRow,
        finalY : cursorY,
        pageNumber : pageNumber,
    })
    firstTableData.totalPages = pageNumber
    firstTableData.totalRows = totalRows
    return firstTableData
}

const MAX_ROWS = 24;
export function updateCurrentPageRow(firstTableData: ITableData, currentPageRow: IRowData, cursorY: number, index: number, pageNumber: number){
    let maxIndex: number;
    if(pageNumber === 1){
        maxIndex = index + 1
    }else{
        maxIndex = 2 + index - (firstTableData.rows.length * MAX_ROWS)
        maxIndex = clamp(maxIndex, 1, MAX_ROWS)
    }
    currentPageRow = {
        ...currentPageRow,
        startY : cursorY,
        maxIndex : maxIndex
    }
    return currentPageRow
}