import { Container } from '@inlet/react-pixi';
import React from 'react';
import { Breakout } from '../breakout/Breakout';
import { CableBase } from '../cable-base/CableBase';
import { useSideContext } from './hooks';
import { initialSideProps, SideContainer, SideContext, SideProps } from './types';

export const Side: React.FC<SideProps> = (props = initialSideProps) => {
    const context = useSideContext(props);

    return (
        <Container name={SideContainer}>
            <SideContext.Provider value={context}>
                {context.breakoutsProps.map((breakout, index) => (
                    <Breakout {...breakout} key={index + 1} />
                ))}
                <CableBase />
            </SideContext.Provider>
        </Container>
    )
}