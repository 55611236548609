import { Container } from "@inlet/react-pixi"
import React from "react"
import { IPosition } from "../../cable/types"
import { useBoundingBox } from "../bounding-box/hooks"
import { BoundingBox, BoundingBoxContext, initialContainerBox } from "../bounding-box/types"
import { AdjacentComponentProps } from "./types"

export const withAdjacentComponent = <T extends Partial<IPosition>, U extends Partial<BoundingBox>>
    (PositionedComponent: React.ComponentType<T>, Component: React.ComponentType<U>): React.FC<AdjacentComponentProps<T, U>> => {

    return (props: AdjacentComponentProps<T, U>) => {
        const { state, containerRef } = useBoundingBox(props, props.bufferBox || initialContainerBox, false)

        return (
            <BoundingBoxContext.Provider value={state}>
                <Component {...props.adjacent} {...state} />
                <Container ref={containerRef} interactive interactiveChildren>
                    <PositionedComponent {...props.component} />
                </Container>
            </BoundingBoxContext.Provider>
        )
    }
}