export const SortOptions = {
    dateModified: "date modified",
    name: "name"
}

export type SortOption = typeof SortOptions [keyof typeof SortOptions]

export interface IDrawerSubHeaderProps {
    sort: SortOption,
    ascending: boolean,
    cableCount: number,
    onAddClick: () => void
}