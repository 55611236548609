import React from 'react';
import { useBreakoutDetails } from './hooks';
import { Dialog } from '@orbit/dialog';
import { BreakoutDetailsContext } from './store/reducer';
import { LegTable } from './leg-table/LegTable';

import './BreakoutDetails.scss';

export const BreakoutDetails: React.FC = () => {
    const { dialogProps, context } = useBreakoutDetails();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <BreakoutDetailsContext.Provider value={context}>
                <LegTable />
            </BreakoutDetailsContext.Provider>
        </Dialog>
    );
}