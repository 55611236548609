import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationService } from "../../services/authentication/authentication-service";
import { createAsyncAction, extractData } from "../../store/actions";
import { loginAction, logoutAction, setStatusAction, showSettingsAction, showAssemblyEditAction, showBreakoutEditAction, showBreakoutDetailsAction, showAssemblyWizardAction, showTermsConditionsAction, showTimeOutAction, showIdleAction, setUserUomAction, showAnnotationDialogAction, showHelpAction } from "./actions";
import { initialWorkspaceState } from "./types";

const workspaceSlice = createSlice({
    initialState: initialWorkspaceState,
    name: "workspace",
    reducers: {
        login: loginAction,
        logout: logoutAction,
        setUserUom: setUserUomAction,
        setStatus: setStatusAction,
        showSettings: showSettingsAction,
        showAssemblyWizard: showAssemblyWizardAction,
        showAssemblyEdit: showAssemblyEditAction,
        showBreakoutEdit: showBreakoutEditAction,
        showBreakoutDetails: showBreakoutDetailsAction,
        showTermsConditions: showTermsConditionsAction,
        showIdle: showIdleAction,
        showTimeOut: showTimeOutAction,
        showHelp: showHelpAction,
        showAnnotationDialog: showAnnotationDialogAction
    }
})

export const WorkspaceReducer = workspaceSlice.reducer;

export const { login, logout, setUserUom, setStatus, showSettings, showAssemblyWizard, showAssemblyEdit, showBreakoutEdit, showBreakoutDetails, showTermsConditions, showIdle, showTimeOut, showHelp, showAnnotationDialog } = workspaceSlice.actions;

export const renewToken = (tokenStr: string) => {
    return createAsyncAction(async (dispatch) => {
        const service = new AuthenticationService();
        const res = await service.renew(tokenStr);
        const renewedToken = extractData(res, dispatch);
        if (renewedToken) {
            AuthenticationService.saveToken(renewedToken);
        }
    });
}

export const logOff = () => {
    return createAsyncAction(async (dispatch) => {
        const service = new AuthenticationService();
        service.logout().then(() => {
            AuthenticationService.saveToken(null);
            dispatch(workspaceSlice.actions.logout());
            window.location.reload();
        });
    });
};