
import React from 'react';
import { Viewport as PixiViewport } from 'pixi-viewport';
import { IViewportComponentProps, ViewportProps } from './types';
import { useViewport, useViewportSelection } from './hooks';
import { PixiComponent } from '@inlet/react-pixi';
import { SelectionContext } from '../interactions/selection/types';

const ReactViewport = PixiComponent<ViewportProps, PixiViewport>('Viewport', {
    create: (props) => {
        return props.viewport || new PixiViewport();
    }
})

export const Viewport: React.FC<IViewportComponentProps> = (props) => {
    const { viewportRef } = useViewport(props);
    const selectionContext = useViewportSelection()
    return (
        <SelectionContext.Provider value={selectionContext}>
            <ReactViewport viewport={viewportRef.current}>
                {props.children}
            </ReactViewport>
        </SelectionContext.Provider>
    )

}