import React from 'react';
import { ILengthMarkerProps, LengthMarkerContainer } from './types';
import { LineMarker } from '../line/LineMarker';
import { Container, Text } from '@inlet/react-pixi';

export const LengthMarker: React.FC<ILengthMarkerProps> = ({ showMarker, lineMarkerProps, textProps}) => {
    return (
        showMarker ? 
        <Container name={LengthMarkerContainer}>
            <LineMarker {...lineMarkerProps} />
            <Text {...textProps} />
        </Container> : null
    );
}