import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { setHighlightedConnectors, showConnectorAssignment, showFiberMapping } from "../store/reducer";
import { showFiberMappingSelector, userFiberMapsSelector } from "../store/selectors";
import { clearAll, FiberMappingReducer, resetNavigation, setBreakoutNavigationIndex, setConnectorNavigationIndex, setSelectedPinIndex, setSideNavigation } from "./reducer/reducer";
import { IFiberMappingContext, initialFiberMappingState, ISideNavigation } from "./reducer/types";
import { ButtonProps } from "@orbit/button";
import { PolarityContext } from "../reducer/types";
import { Sides } from "../../assembly/breakout/types";
import { LC } from "../../assembly/connector/types";
import { resetLocalPolarity } from "../reducer/reducer";
import { prepareFiberAssignments } from "./reducer/reducer";
import { updateConnectorAssignmentWithFiberMap } from "../connector-assignment/reducer/actions";
import { IFiberMap } from "./types";
import { updateConnectorAssignment} from "../../assembly/store/reducer";
import { IInputDialogProps } from "../../../ui/dialog/input/types";
import { userIdSelector } from "../../store/selectors";
import { HighlightStatuses, IHighlightedConnector } from "../../../pixi/cable/breakout/connector-furcation/highlight/types";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { useStoreDispatch } from "../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const useFiberMappingDialog = () => {
    const { dialogProps } = useDialog();
    const [state, dispatch] = useReducer(FiberMappingReducer, initialFiberMappingState);
    const fiberMappingContext: IFiberMappingContext = { state, dispatch };
    const { dispatch: polarityDispatch } = useContext(PolarityContext);
    const { data } = useFiberMappingData(fiberMappingContext);
    const { navigationProps } = useNavigation(fiberMappingContext, dialogProps.open);
    const { clearAllProps } = useClearAll(fiberMappingContext);
    const { cancel } = useCancel();
    const { save, saveAs } = useSave(fiberMappingContext);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const sideA = {
        breakouts: {
            select: {
                value: navigationProps.sideA.breakout.value,
                onChange: navigationProps.sideA.breakout.onChange
            },
            options: data.sideA.breakouts.map((b, i) => ({ value: i, label: `A${b}` }))
        },
        connectors: {
            select: {
                value: navigationProps.sideA.connector.value,
                onChange: navigationProps.sideA.connector.onChange
            },
            options: data.sideA.connectors.map((c, i) => ({ value: i, label: t(LocalizationKeys.ConnectorNumber, { connectorIndex: c.position }) }))
        },
        connectorType: data.sideA.connectorType
    };

    const sideB = {
        breakouts: {
            select: {
                value: navigationProps.sideB.breakout.value,
                onChange: navigationProps.sideB.breakout.onChange
            },
            options: data.sideB.breakouts.map((b, i) => ({ value: i, label: `B${b}` }))
        },
        connectors: {
            select: {
                value: navigationProps.sideB.connector.value,
                onChange: navigationProps.sideB.connector.onChange
            },
            options: data.sideB.connectors.map((c, i) => ({ value: i, label: t(LocalizationKeys.ConnectorNumber, { connectorIndex: c.position }) }))
        },
        connectorType: data.sideB.connectorType
    };

    useEffect(() => {
        if (!dialogProps.open) {
            dispatch(clearAll(false));
            dispatch(resetNavigation());
            polarityDispatch(resetLocalPolarity());
            storeDispatch(setHighlightedConnectors([]));
        }
    }, [dialogProps.open, dispatch, polarityDispatch, storeDispatch]);

    return { dialogProps, clearAll: clearAllProps, fiberMappingContext, polarityType: data.polarityType, sideA, sideB, navigationProps, cancel, save, saveAs };
}

const useDialog = () => {
    const open = useSelector(showFiberMappingSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showFiberMapping(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "fiber-mapping-dialog",
        title: t(LocalizationKeys.FiberPosition),
        header: {
            collapsible: true,
        },
        modal: false,
        onClose
    };

    return { dialogProps };
}

const useFiberMappingData = (context: IFiberMappingContext) => {
    const { state, dispatch } = context;
    const { navigation, isModified } = state;
    const polarityContext = useContext(PolarityContext);
    const { initialNavigation, connectorAssignment } = polarityContext.state;
    const { sideAMapping, sideBMapping } = connectorAssignment.connectors;
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        if (connectorAssignment.id) {
            dispatch(prepareFiberAssignments(connectorAssignment));
            const maps = [...connectorAssignment.connectors.sideAMapping, ...connectorAssignment.connectors.sideBMapping];
            const highlights: IHighlightedConnector[] = maps.map(m => {
                return {
                    side: m.side,
                    breakoutPosition: m.breakoutPosition,
                    connectorPosition: m.index,
                    status: HighlightStatuses.Assigned,
                };
            });
            storeDispatch(setHighlightedConnectors(highlights));
        }
    }, [connectorAssignment, dispatch, storeDispatch]);

    const data = useMemo(() => {
        let sideABreakoutPositions: number[] = [];
        let sideBBreakoutPositions: number[] = [];
        let sideABreakoutNavigationIndex = -1;
        let sideBBreakoutNavigationIndex = -1;
        let sideAConnectors: { position: number, fiberCount: number, connectorType: string }[] = [];
        let sideBConnectors: { position: number, fiberCount: number, connectorType: string }[] = [];
        let sideAConnectorType = LC;
        let sideBConnectorType = LC;
        if (sideAMapping.length && sideBMapping.length) {
            sideABreakoutPositions = sideAMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i);
            sideBBreakoutPositions = sideBMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i);
            sideABreakoutNavigationIndex = navigation.sideA.breakout.currentIndex === -1 ? sideABreakoutPositions.indexOf(initialNavigation.breakoutPosition) : navigation.sideA.breakout.currentIndex;
            sideBBreakoutNavigationIndex = navigation.sideB.breakout.currentIndex === -1 ? 0 : navigation.sideB.breakout.currentIndex;
            sideAConnectors = sideAMapping.filter(c => c.breakoutPosition === sideABreakoutPositions[sideABreakoutNavigationIndex]).map(c => { return { position: c.index, fiberCount: c.fiberCount, connectorType: c.connectorType } });
            sideBConnectors = sideBMapping.filter(c => c.breakoutPosition === sideBBreakoutPositions[sideBBreakoutNavigationIndex]).map(c => { return { position: c.index, fiberCount: c.fiberCount, connectorType: c.connectorType } });
            sideAConnectorType = sideAConnectors[0].connectorType;
            sideBConnectorType = sideBConnectors[0].connectorType;
        }

        const sideAConnectorNavigationIndex = navigation.sideA.connector.currentIndex === -1 ? sideAConnectors.findIndex(c => c.position === initialNavigation.connectorPosition) : navigation.sideA.connector.currentIndex;
        const sideBConnectorNavigationIndex = navigation.sideB.connector.currentIndex === -1 ? 0 : navigation.sideB.connector.currentIndex;
        const sideANavigation: ISideNavigation = {
            side: Sides.SideA,
            breakout: {
                nbIndex: sideABreakoutPositions.length,
                currentIndex: sideABreakoutNavigationIndex
            },
            connector: {
                nbIndex: sideAConnectors.length,
                currentIndex: sideAConnectorNavigationIndex
            }
        }
        const sideBNavigation: ISideNavigation = {
            side: Sides.SideB,
            breakout: {
                nbIndex: sideBBreakoutPositions.length,
                currentIndex: sideBBreakoutNavigationIndex
            },
            connector: {
                nbIndex: sideBConnectors.length,
                currentIndex: sideBConnectorNavigationIndex
            }
        }
        dispatch(setSideNavigation(sideANavigation));
        dispatch(setSideNavigation(sideBNavigation));

        const { versionDate, name } = connectorAssignment.fiberMapData;
        const fiberMapName = isModified ? t(LocalizationKeys.Custom) : name;
        const polarityName = versionDate?.length ? `${fiberMapName} (${isoToDateString(versionDate)})` : fiberMapName;
        const polarityType = t(LocalizationKeys.PolarityType, { polarityType: polarityName });

        return {
            polarityType,
            sideA: {
                breakouts: sideABreakoutPositions,
                connectors: sideAConnectors,
                connectorType: sideAConnectorType
            },
            sideB: {
                breakouts: sideBBreakoutPositions,
                connectors: sideBConnectors,
                connectorType: sideBConnectorType
            }
        }
    }, [sideAMapping, sideBMapping, initialNavigation, navigation.sideA.breakout.currentIndex, navigation.sideA.connector.currentIndex, navigation.sideB.breakout.currentIndex, navigation.sideB.connector.currentIndex, dispatch, connectorAssignment, t, isModified]);

    return { data };
}

export function isoToDateString(isoString: string): string {
    const date = new Date(isoString);
    const dateString = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    return dateString;
}

const useNavigation = (context: IFiberMappingContext, display: boolean) => {
    const { state, dispatch } = context;
    const { navigation } = state;

    const onSideABreakoutChange = useCallback((e) => {
        const breakoutIndex = Number(e.target.value);
        dispatch(setBreakoutNavigationIndex({ side: Sides.SideA, index: breakoutIndex }));
        dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: 0 }));
    }, [dispatch]);

    const onSideAConnectorChange = useCallback((e) => {
        const connectorIndex = Number(e.target.value);
        dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: connectorIndex }));
    }, [dispatch]);

    const onSideBBreakoutChange = useCallback((e) => {
        const breakoutIndex = Number(e.target.value);
        dispatch(setBreakoutNavigationIndex({ side: Sides.SideB, index: breakoutIndex }));
        dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: 0 }));
    }, [dispatch]);

    const onSideBConnectorChange = useCallback((e) => {
        const connectorIndex = Number(e.target.value);
        dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: connectorIndex }));
    }, [dispatch]);

    const onKeyPress = useCallback((e: any) => {
        if (e.keyCode === 27) {
            if (navigation.selectedPinIndex !== -1) {
                dispatch(setSelectedPinIndex(-1));
            }
        }
    }, [navigation.selectedPinIndex, dispatch]);

    useEffect(() => {
        if (display) {
            document.addEventListener("keydown", onKeyPress, false);
        }
        return () => {
            document.removeEventListener("keydown", onKeyPress, false);
        };
    }, [display, onKeyPress]);

    return {
        navigationProps: {
            sideA: {
                breakout: {
                    value: navigation.sideA.breakout.currentIndex.toString(),
                    onChange: onSideABreakoutChange
                },
                connector: {
                    value: navigation.sideA.connector.currentIndex.toString(),
                    onChange: onSideAConnectorChange
                }
            },
            sideB: {
                breakout: {
                    value: navigation.sideB.breakout.currentIndex.toString(),
                    onChange: onSideBBreakoutChange
                },
                connector: {
                    value: navigation.sideB.connector.currentIndex.toString(),
                    onChange: onSideBConnectorChange
                }
            }
        }
    }
}

const useClearAll = (context: IFiberMappingContext) => {
    const { state, dispatch } = context;
    const { t } = useTranslation();

    const clearAllDisabled = state.assignments.length === 0 && state.unused.length === 0;
    const [showClearDialog, setShowClearDialog] = useState(false);
    const onClearAll = useCallback(() => {
        setShowClearDialog(true);
    }, []);

    const onClearClose = useCallback(() => {
        setShowClearDialog(false);
    }, [])

    const onClearConfirm = useCallback(() => {
        dispatch(clearAll(true));
        onClearClose();
    }, [dispatch, onClearClose]);

    const buttonProps: ButtonProps = {
        className: "clear-button",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        disabled: clearAllDisabled,
        onClick: onClearAll,
    };

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onClearClose,
        confirmText: t(LocalizationKeys.Clear), 
        onConfirmClick: onClearConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const dialogProps: DialogProps = {
        open: showClearDialog,
        className: "clear-fiber-map-dialog",
        title: t(LocalizationKeys.ClearAll),
        message: t(LocalizationKeys.ClearFiberMapping),
        actions,
        onClose: onClearClose
    };

    return { 
        clearAllProps: {
            buttonProps,
            label: t(LocalizationKeys.ClearAll),
            dialogProps
        } 
    };
}

const useCancel = () => {
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        storeDispatch(showFiberMapping(false));
        storeDispatch(showConnectorAssignment(true));
    }, [storeDispatch]);

    const cancelButtonProps: ButtonProps = {
        className: "cancel-button",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        onClick: onClose,
    };

    const cancel = {
        buttonProps: cancelButtonProps,
        label: t(LocalizationKeys.Cancel),
    }

    return { cancel };
}

const useSave = (context: IFiberMappingContext) => {
    const { state } = context;
    const { assignments: pinAssignments, unused, isModified } = state;
    const { state: polarityState, dispatch: polarityDispatch } = useContext(PolarityContext);
    const { connectorAssignment } = polarityState;
    const { fiberMapData } = connectorAssignment;
    const userId = useSelector(userIdSelector);
    const userFiberMaps = useSelector(userFiberMapsSelector);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onSave = useCallback(async (name?: string, userId?: number) => {
        const fiberMap: IFiberMap = { name: name ?? t(LocalizationKeys.Custom), pinAssignments, unused };
        const updatedConnectorAssignment = updateConnectorAssignmentWithFiberMap(connectorAssignment, fiberMap);
        if (userId) {
            updatedConnectorAssignment.fiberMapData.userId = userId;
        }
        storeDispatch(updateConnectorAssignment(updatedConnectorAssignment, polarityDispatch));
        storeDispatch(showFiberMapping(false));
        storeDispatch(showConnectorAssignment(true));
    }, [t, pinAssignments, unused, connectorAssignment, storeDispatch, polarityDispatch]);

    const onSaveClick = useCallback(() => {
        storeDispatch(setStatus(Status.Synchronizing));
        onSave();
    }, [storeDispatch, onSave]);

    const saveDisabled = !isModified;
    const saveButtonProps: ButtonProps = {
        className: "save-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: saveDisabled,
        onClick: onSaveClick,
    };

    const save = {
        buttonProps: saveButtonProps,
        label: t(LocalizationKeys.Save),
    };

    const [saveAsInputOpen, setSaveAsInputOpen] = useState(false);

    const onShowSaveAsInputDialog = useCallback(() => {
        setSaveAsInputOpen(true);
    }, []);

    const onCloseSaveAsInputDialog = useCallback(() => {
        setSaveAsInputOpen(false);
    }, [])

    const saveAsButtonProps: ButtonProps = {
        className: " save-as-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: saveDisabled,
        onClick: onShowSaveAsInputDialog,
    };

    const [customFiberMapName, setCustomFiberMapName] = useState(fiberMapData.name);
    const [showSaveAsConfirmDialog, setShowSaveAsConfirmDialog] = useState(false);
    const onSaveAs = useCallback((value?: string | number) => {
        if (value) {
            const fiberMapName = value.toString();
            setCustomFiberMapName(fiberMapName);
            if (userFiberMaps.find(f => f.name.toUpperCase() === fiberMapName.toUpperCase())) {
                setSaveAsInputOpen(false);
                setShowSaveAsConfirmDialog(true);
            } else {
                storeDispatch(setStatus(Status.Saving));
                onSave(fiberMapName, userId);
            }
        }
    }, [userFiberMaps, storeDispatch, onSave, userId])

    const saveAsInputDialogProps: IInputDialogProps = {
        open: saveAsInputOpen,
        onClose: onCloseSaveAsInputDialog,
        value: fiberMapData.name,
        title: t(LocalizationKeys.CustomPolarity),
        fieldLabel: t(LocalizationKeys.PolarityName),
        maxCharacterCount: 20,
        cancelProps: {
            text: t(LocalizationKeys.Cancel)
        },
        saveProps: {
            text: t(LocalizationKeys.Save),
            onClick: onSaveAs
        }
    }

    const onSaveAsConfirmClose = useCallback(() => {
        setShowSaveAsConfirmDialog(false);
    }, [])

    const onSaveAsConfirm = useCallback(() => {
        storeDispatch(setStatus(Status.Synchronizing));
        onSave(customFiberMapName, userId);
    }, [storeDispatch, onSave, customFiberMapName, userId]);

    const saveAsActions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onSaveAsConfirmClose,
        confirmText: t(LocalizationKeys.Save), 
        onConfirmClick: onSaveAsConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const saveAsConfirmDialogProps: DialogProps = {
        open: showSaveAsConfirmDialog,
        className: "save-as-confirm-dialog",
        title: t(LocalizationKeys.PolarityExists, { polarityName: customFiberMapName }),
        message: t(LocalizationKeys.SaveNewVersion),
        actions: saveAsActions,
        onClose: onSaveAsConfirmClose
    };

    return { 
        save, 
        saveAs: {
            buttonProps: saveAsButtonProps,
            label: t(LocalizationKeys.SaveAs),
            inputDialogProps: saveAsInputDialogProps,
            confirmDialogProps: saveAsConfirmDialogProps
        }
    };
}

