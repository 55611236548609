import React, { Fragment } from 'react';
import CorningLogo from '../top-bar/project-manager-panel/resources/Corning_Logo_301Blue_6in.png';
import { Sides } from '../assembly/breakout/types';
import { ConnectorTable } from './connector-table/ConnectorTable';
import { AnnotationTable } from './annotation-table/AnnotationTable';
import { BreakoutTable } from './breakout-table/BreakoutTable';
import { LegTable } from './leg-table/LegTable';
import { FiberMapTable } from './fiber-map-table/FiberMapTable';
import { ColorChips } from './color-chips/ColorChips';
import { PolarityDiagrams } from './polarity-diagrams/PolarityDiagrams';
import { BuildPlanTable } from './build-plan-table/BuildPlanTable';

import './Offscreen.scss';

export const Offscreen: React.FC = () => {
    const sides = [Sides.SideA, Sides.SideB];
    return (
        <div id="offscreen-container">
            <img id="corning-secondary-logo" src={CorningLogo} alt="Corning logo" width={200} />
            <div id="report-images" />
            <ConnectorTable />
            <AnnotationTable />
            {
                sides.map((s, i) =>
                    <Fragment key={i}>
                        <BreakoutTable side={s} />
                        <LegTable side={s} />
                    </Fragment>
                )
            }
            <FiberMapTable />
            <ColorChips />
            <BuildPlanTable />
            <PolarityDiagrams />
        </div>
    );
}