import React from 'react';
import { getConnectorTextColor, Yellow } from '../../../../../ui/dialog/color/types';

export const LCDuplex: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="190" height="868" viewBox="0 0 19 86.8">
            <g id="Group_1249" data-name="Group 1249" transform="translate(-22 -586.2)">
                <g id="LC_UPC_Duplex_Connector_global_" data-name="LC UPC Duplex Connector (global)" transform="translate(22 586.2)">
                    <g id="LC_Duplex_Cable_Link" data-name="LC Duplex Cable Link" transform="translate(3.117)">
                        <g id="Group_1445" data-name="Group 1445" transform="translate(-251 840.012) rotate(-90)">
                            <g id="Group_1442" data-name="Group 1442" transform="translate(625.93 -776.212)">
                                <path id="Path_476" data-name="Path 476" d="M2523.082,823.212c7.993.02,12.4,1.727,20,2,.956.1,20,0,20,0v4s-19.476-.1-20,0c-7.867,0-13.207-1.828-20-2C2523.079,826.194,2523.071,824.633,2523.082,823.212Z" transform="translate(-2349 204)" fill="#4c4649"/>
                                <path id="Path_477" data-name="Path 477" d="M2523.087,824.2c9.225.261,12.118,1.853,20,2.01,1-.011,20,0,20,0v2s-19.006,0-20,0c-8.475-.159-11.231-1.718-20-2.06C2523.063,825.625,2523.064,825.221,2523.087,824.2Z" transform="translate(-2349 204)" fill="#fce43b"/>
                            </g>
                            <g id="Group_1444" data-name="Group 1444" transform="translate(799.999 257.5)">
                                <path id="Path_476-2" data-name="Path 476" d="M-.029,6.5c7.946.062,12.4-1.727,20-2,.212-.114,20,0,20,0V.5h-20c-7.867,0-12.055,2.066-20,2C-.033,3.621-.041,5.079-.029,6.5Z" transform="translate(0.042 0)" fill="#4c4649"/>
                                <path id="Path_477-2" data-name="Path 477" d="M.013,4.482c9.132.05,12.123-1.843,20-2,.538.072,20,0,20,0v-2s-18.938.026-20,0c-8.475.159-11.267,2-20,2C-.012,3.009-.01,3.463.013,4.482Z" transform="translate(0 1.018)" fill="#fce43b"/>
                            </g>
                        </g>
                    </g>
                    <g id="Path_194" data-name="Path 194" transform="translate(-1197.526 -1536.2)" fill="#fffffe">
                        <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                        <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
                    </g>
                    <g id="Path_442" data-name="Path 442" transform="translate(-1188.526 -1536.2)" fill="#fffffe">
                        <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                        <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
                    </g>
                    <rect id="Rectangle_474" data-name="Rectangle 474" width="2" height="2" transform="translate(4 84.8)" fill="#c4c4c4"/>
                    <rect id="Rectangle_1189" data-name="Rectangle 1189" width="2" height="2" transform="translate(13 84.8)" fill="#c4c4c4"/>
                    <g id="Rectangle_739" data-name="Rectangle 739" transform="translate(1 74.8)" fill="#045eaa" stroke="#484646" stroke-width="1">
                        <rect width="8" height="10" stroke="none"/>
                        <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
                    </g>
                    <g id="Rectangle_1190" data-name="Rectangle 1190" transform="translate(10 74.8)" fill="#045eaa" stroke="#484646" stroke-width="1">
                        <rect width="8" height="10" stroke="none"/>
                        <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
                    </g>
                    <g id="Rectangle_1191" data-name="Rectangle 1191" transform="translate(0 64.8)" fill="#045eaa" stroke="#484646" stroke-width="1">
                        <rect width="19" height="11" stroke="none"/>
                        <rect x="0.5" y="0.5" width="18" height="10" fill="none"/>
                    </g>
                    <rect id="Rectangle_621" data-name="Rectangle 621" width="2" height="1" transform="translate(4 41.8)" fill="#484646"/>
                    <rect id="Rectangle_1192" data-name="Rectangle 1192" width="2" height="1" transform="translate(13 41.8)" fill="#484646"/>
                    <rect id="Rectangle_1131" data-name="Rectangle 1131" width="6" height="1" transform="translate(2 62.8)" fill="#484646"/>
                    <rect id="Rectangle_1193" data-name="Rectangle 1193" width="6" height="1" transform="translate(11 62.8)" fill="#484646"/>
                    <rect id="Rectangle_748" data-name="Rectangle 748" width="2" height="1" transform="translate(4 45.8)" fill="#484646"/>
                    <rect id="Rectangle_1194" data-name="Rectangle 1194" width="2" height="1" transform="translate(13 45.8)" fill="#484646"/>
                    <rect id="Rectangle_623" data-name="Rectangle 623" width="6" height="1" transform="translate(2 60.8)" fill="#484646"/>
                    <rect id="Rectangle_1195" data-name="Rectangle 1195" width="6" height="1" transform="translate(11 60.8)" fill="#484646"/>
                    <rect id="Rectangle_1147" data-name="Rectangle 1147" width="6" height="1" transform="translate(2 43.8)" fill="#484646"/>
                    <rect id="Rectangle_1196" data-name="Rectangle 1196" width="6" height="1" transform="translate(11 43.8)" fill="#484646"/>
                    <rect id="Rectangle_1148" data-name="Rectangle 1148" width="6" height="1" transform="translate(2 47.8)" fill="#484646"/>
                    <rect id="Rectangle_1197" data-name="Rectangle 1197" width="6" height="1" transform="translate(11 47.8)" fill="#484646"/>
                    <g id="Rectangle_742" data-name="Rectangle 742" transform="translate(3 71.8)" fill="#045eaa" stroke="#484646" stroke-width="1">
                        <rect width="4" height="11" stroke="none"/>
                        <rect x="0.5" y="0.5" width="3" height="10" fill="none"/>
                    </g>
                    <g id="Rectangle_1198" data-name="Rectangle 1198" transform="translate(12 71.8)" fill="#045eaa" stroke="#484646" stroke-width="1">
                        <rect width="4" height="11" stroke="none"/>
                        <rect x="0.5" y="0.5" width="3" height="10" fill="none"/>
                    </g>
                    <g id="Rectangle_1136" data-name="Rectangle 1136" transform="translate(2 64.8)" fill="none" stroke="#484646" stroke-width="1">
                        <rect width="15" height="8" stroke="none"/>
                        <rect x="0.5" y="0.5" width="14" height="7" fill="none"/>
                    </g>
                    <rect id="Rectangle_626" data-name="Rectangle 626" width="4" height="1" transform="translate(3 76.8)" fill="#484646"/>
                    <rect id="Rectangle_1200" data-name="Rectangle 1200" width="4" height="1" transform="translate(12 76.8)" fill="#484646"/>
                    <rect id="Rectangle_1201" data-name="Rectangle 1201" width="11" height="1" transform="translate(4 69.8)" fill="#484646" opacity="0.8"/>
                    <text id="A" transform="translate(6 66.8) rotate(180)" fill="#fff" font-size="3" font-family="ArialMT, Arial">
                        <tspan x="0" y="0">A</tspan>
                    </text>
                    <text id="B" transform="translate(15 66.8) rotate(180)" fill="#fff" font-size="3" font-family="ArialMT, Arial">
                        <tspan x="0" y="0">B</tspan>
                    </text>
                </g>
            </g>
        </svg>
    )
}

export const getLCDuplexSVGString = (color: string, jacketColor?: string, colorA?: string, colorB?: string) => {
    const textColor = getConnectorTextColor(color);
    const trunkColor = jacketColor ?? Yellow.hex;
    const bootAColor = colorA ?? "#fffffe";
    const bootBColor = colorB ?? "#fffffe";
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="190" height="868" viewBox="0 0 19 86.8">
        <g id="Group_1249" data-name="Group 1249" transform="translate(-22 -586.2)">
            <g id="LC_UPC_Duplex_Connector_global_" data-name="LC UPC Duplex Connector (global)" transform="translate(22 586.2)">
                <g id="LC_Duplex_Cable_Link" data-name="LC Duplex Cable Link" transform="translate(3.117)">
                    <g id="Group_1445" data-name="Group 1445" transform="translate(-251 840.012) rotate(-90)">
                        <g id="Group_1442" data-name="Group 1442" transform="translate(625.93 -776.212)">
                            <path id="Path_476" data-name="Path 476" d="M2523.082,823.212c7.993.02,12.4,1.727,20,2,.956.1,20,0,20,0v4s-19.476-.1-20,0c-7.867,0-13.207-1.828-20-2C2523.079,826.194,2523.071,824.633,2523.082,823.212Z" transform="translate(-2349 204)" fill="#4c4649"/>
                            <path id="Path_477" data-name="Path 477" d="M2523.087,824.2c9.225.261,12.118,1.853,20,2.01,1-.011,20,0,20,0v2s-19.006,0-20,0c-8.475-.159-11.231-1.718-20-2.06C2523.063,825.625,2523.064,825.221,2523.087,824.2Z" transform="translate(-2349 204)" fill="${trunkColor}"/>
                        </g>
                        <g id="Group_1444" data-name="Group 1444" transform="translate(799.999 257.5)">
                            <path id="Path_476-2" data-name="Path 476" d="M-.029,6.5c7.946.062,12.4-1.727,20-2,.212-.114,20,0,20,0V.5h-20c-7.867,0-12.055,2.066-20,2C-.033,3.621-.041,5.079-.029,6.5Z" transform="translate(0.042 0)" fill="#4c4649"/>
                            <path id="Path_477-2" data-name="Path 477" d="M.013,4.482c9.132.05,12.123-1.843,20-2,.538.072,20,0,20,0v-2s-18.938.026-20,0c-8.475.159-11.267,2-20,2C-.012,3.009-.01,3.463.013,4.482Z" transform="translate(0 1.018)" fill="${trunkColor}"/>
                        </g>
                    </g>
                </g>
                <g id="Path_194" data-name="Path 194" transform="translate(-1197.526 -1536.2)" fill="${bootAColor}">
                    <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                    <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
                </g>
                <g id="Path_442" data-name="Path 442" transform="translate(-1188.526 -1536.2)" fill="${bootBColor}">
                    <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                    <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
                </g>
                <rect id="Rectangle_474" data-name="Rectangle 474" width="2" height="2" transform="translate(4 84.8)" fill="#c4c4c4"/>
                <rect id="Rectangle_1189" data-name="Rectangle 1189" width="2" height="2" transform="translate(13 84.8)" fill="#c4c4c4"/>
                <g id="Rectangle_739" data-name="Rectangle 739" transform="translate(1 74.8)" fill="${color}" stroke="#484646" stroke-width="1">
                    <rect width="8" height="10" stroke="none"/>
                    <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
                </g>
                <g id="Rectangle_1190" data-name="Rectangle 1190" transform="translate(10 74.8)" fill="${color}" stroke="#484646" stroke-width="1">
                    <rect width="8" height="10" stroke="none"/>
                    <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
                </g>
                <g id="Rectangle_1191" data-name="Rectangle 1191" transform="translate(0 64.8)" fill="${color}" stroke="#484646" stroke-width="1">
                    <rect width="19" height="11" stroke="none"/>
                    <rect x="0.5" y="0.5" width="18" height="10" fill="none"/>
                </g>
                <rect id="Rectangle_621" data-name="Rectangle 621" width="2" height="1" transform="translate(4 41.8)" fill="#484646"/>
                <rect id="Rectangle_1192" data-name="Rectangle 1192" width="2" height="1" transform="translate(13 41.8)" fill="#484646"/>
                <rect id="Rectangle_1131" data-name="Rectangle 1131" width="6" height="1" transform="translate(2 62.8)" fill="#484646"/>
                <rect id="Rectangle_1193" data-name="Rectangle 1193" width="6" height="1" transform="translate(11 62.8)" fill="#484646"/>
                <rect id="Rectangle_748" data-name="Rectangle 748" width="2" height="1" transform="translate(4 45.8)" fill="#484646"/>
                <rect id="Rectangle_1194" data-name="Rectangle 1194" width="2" height="1" transform="translate(13 45.8)" fill="#484646"/>
                <rect id="Rectangle_623" data-name="Rectangle 623" width="6" height="1" transform="translate(2 60.8)" fill="#484646"/>
                <rect id="Rectangle_1195" data-name="Rectangle 1195" width="6" height="1" transform="translate(11 60.8)" fill="#484646"/>
                <rect id="Rectangle_1147" data-name="Rectangle 1147" width="6" height="1" transform="translate(2 43.8)" fill="#484646"/>
                <rect id="Rectangle_1196" data-name="Rectangle 1196" width="6" height="1" transform="translate(11 43.8)" fill="#484646"/>
                <rect id="Rectangle_1148" data-name="Rectangle 1148" width="6" height="1" transform="translate(2 47.8)" fill="#484646"/>
                <rect id="Rectangle_1197" data-name="Rectangle 1197" width="6" height="1" transform="translate(11 47.8)" fill="#484646"/>
                <g id="Rectangle_742" data-name="Rectangle 742" transform="translate(3 71.8)" fill="${color}" stroke="#484646" stroke-width="1">
                    <rect width="4" height="11" stroke="none"/>
                    <rect x="0.5" y="0.5" width="3" height="10" fill="none"/>
                </g>
                <g id="Rectangle_1198" data-name="Rectangle 1198" transform="translate(12 71.8)" fill="${color}" stroke="#484646" stroke-width="1">
                    <rect width="4" height="11" stroke="none"/>
                    <rect x="0.5" y="0.5" width="3" height="10" fill="none"/>
                </g>
                <g id="Rectangle_1136" data-name="Rectangle 1136" transform="translate(2 64.8)" fill="none" stroke="#484646" stroke-width="1">
                    <rect width="15" height="8" stroke="none"/>
                    <rect x="0.5" y="0.5" width="14" height="7" fill="none"/>
                </g>
                <rect id="Rectangle_626" data-name="Rectangle 626" width="4" height="1" transform="translate(3 76.8)" fill="#484646"/>
                <rect id="Rectangle_1200" data-name="Rectangle 1200" width="4" height="1" transform="translate(12 76.8)" fill="#484646"/>
                <rect id="Rectangle_1201" data-name="Rectangle 1201" width="11" height="1" transform="translate(4 69.8)" fill="#484646" opacity="0.8"/>
                <text id="A" transform="translate(6 66.8) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                    <tspan x="0" y="0">A</tspan>
                </text>
                <text id="B" transform="translate(15 66.8) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                    <tspan x="0" y="0">B</tspan>
                </text>
            </g>
        </g>
    </svg>
    `
}