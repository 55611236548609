import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { currentAssemblySelector } from "../../assembly/store/selectors";
import { disabledOverlaySelector } from "../../overlay/store/selectors";
import { setOpen } from "../../project-drawer/store/reducer";
import { workspaceLoadingSelector } from "../../store/selectors";

export const useProjectManagerPanel = () => {
    const { t } = useTranslation();
    const assembly = useSelector(currentAssemblySelector);
    const loading = useSelector(workspaceLoadingSelector);
    const disabled = useSelector(disabledOverlaySelector);
    let cableName = assembly.assemblyInfo && assembly.assemblyInfo.name !== "" ? assembly.assemblyInfo.name : t(LocalizationKeys.AssemblyName, { assemblyId: assembly.id })
    if (loading) {
        cableName = `[ ${t(LocalizationKeys.DefaultCableName, { name: "1" })} ]`;
    }
    const partNumber = assembly.assemblyInfo?.partNumber;
    const storeDispatch = useStoreDispatch();
    
    const onProjectManagerButtonClick = useCallback(() => {
        storeDispatch(setOpen(true));
    }, [storeDispatch]);

    const drawerIconProps = {
        className: "panel-item",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        title: t(LocalizationKeys.Menu),
        disabled,
        onClick: onProjectManagerButtonClick
    };

    return { drawerIconProps, cableName, partNumber };
}