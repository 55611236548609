import { IReportState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { AssemblyDocument, IUserProvidedImage } from "../document/types";

export const showPrintDialogAction = (state: IReportState, action: PayloadAction<boolean>) => {
    state.showPrint = action.payload;
}

export const setAssemblyDocumentAction = (state: IReportState, action: PayloadAction<AssemblyDocument>) => {
    updateUserProvidedImages(state, action.payload.userProvidedImages);
    state.assemblyDocument = action.payload;
}

export const setUserProvidedImagesAction = (state: IReportState, action: PayloadAction<IUserProvidedImage[]>) => {
    updateUserProvidedImages(state, action.payload);
}

const updateUserProvidedImages = (state: IReportState, userProvidedImages: IUserProvidedImage[]) => {
    const objectUrls = userProvidedImages.map(image => image.objectUrl);
    const removed = state.assemblyDocument.userProvidedImages.filter(({objectUrl}) => !objectUrls.includes(objectUrl));
    removed.forEach(image => URL.revokeObjectURL(image.objectUrl));
    state.assemblyDocument.userProvidedImages = userProvidedImages;
}