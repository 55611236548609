import React from 'react';
import { ITemplateProps } from './types';
import { useLCTemplate } from './hooks';
import { ConnectorPin } from './ConnectorPin';

export const LCTemplate: React.FC<ITemplateProps> = (props) => {
    const { onClick, connectorName, pins } = useLCTemplate(props);
    return (
        <svg width="146" height="134" viewBox="0 0 146 109" onClick={onClick}>
            <defs>
                <filter id="lcTemplateFilter" x="0" y="19" width="146" height="90" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(9 1)">
                <g transform="matrix(1, 0, 0, 1, -9, -1)" filter="url(#lcTemplateFilter)">
                    <g transform="translate(9 25)" fill="#e5ebeb" stroke="#000010" strokeWidth="4px">
                        <rect width="128" height="72" rx="16" stroke="none" />
                        <rect x="2" y="2" width="124" height="68" rx="14" fill="none" />
                    </g>
                </g>
                <text transform="translate(33 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="700">
                    <tspan x="0" y="0">A</tspan>
                </text>
                <text transform="translate(89 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="700">
                    <tspan x="0" y="0">B</tspan>
                </text>
                <text transform="translate(64 115)" stroke="rgba(0,0,0,0)" fontSize="12px" fontFamily="TheSansC4s" fontWeight="700" textAnchor="middle">
                    <tspan x="0" y="0">{connectorName}</tspan>
                </text>
                {
                    pins.map(p => {
                        return (
                            <g key={p.index} transform={`translate(${p.position.x}, ${p.position.y})`}>
                                <ConnectorPin side={p.side} pinIndex={p.index} />
                            </g>
                        )
                    })
                }
            </g>
        </svg>
    )
}