import { PayloadAction } from "@reduxjs/toolkit";
import { IAssembly } from "../../assembly/types";
import { initialWizardSetupState, WizardSetupState } from "./types";

export const moveNextStepAction = (state: WizardSetupState) => {
    if (state.stepIndex < state.stepCount - 1) {
        state.stepIndex = state.stepIndex + 1;
    }
}

export const movePreviousStepAction = (state: WizardSetupState) => {
    if (state.stepIndex > 0) {
        state.stepIndex = state.stepIndex - 1;
    }
}

export const setResetBreakoutsEnableAction = (state: WizardSetupState, action: PayloadAction<boolean>) => {
    state.enableReset = action.payload
}

export const resetWizardStateAction = (state: WizardSetupState) => {
    state.enableApply = initialWizardSetupState.enableApply;
    state.enableContinue = initialWizardSetupState.enableContinue;
    state.enableReset = initialWizardSetupState.enableReset;
    state.stepCount = initialWizardSetupState.stepCount;
    state.stepIndex = initialWizardSetupState.stepIndex;
}

export const setWizardSetupStateAction = (state: WizardSetupState, action: PayloadAction<Partial<WizardSetupState>>) => {
    const { payload: newState } = action;
    if (newState.enableApply !== undefined) state.enableApply = newState.enableApply;
    if (newState.enableContinue !== undefined) state.enableContinue = newState.enableContinue;
    if (newState.enableReset !== undefined) state.enableReset = newState.enableReset;
    if (newState.stepCount !== undefined) state.stepCount = newState.stepCount;
    if (newState.stepIndex !== undefined) state.stepIndex = newState.stepIndex;
    if (newState.hidden !== undefined) state.hidden = newState.hidden;
    if (newState.prevAssembly !== undefined && newState.prevAssembly !== state.prevAssembly) state.prevAssembly = newState.prevAssembly;
}

export const setApplyEnableAction = (state: WizardSetupState, action: PayloadAction<boolean>) => {
    state.enableApply = action.payload
}

export const setHiddenAction = (state: WizardSetupState, action: PayloadAction<boolean>) => {
    state.hidden = action.payload
}

export const setPreviousAssemblyAction = (state: WizardSetupState, action: PayloadAction<IAssembly | undefined>) => {
    state.prevAssembly = action.payload
}