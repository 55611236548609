import React from 'react';

export const LCSimplex: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="470" viewBox="0 0 8 47">
            <g transform="translate(-1198.526 -1576)" fill="#005090">
                <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <rect width="2" height="2" transform="translate(3 45)" fill="#c4c4c4"/>
            <g transform="translate(0 35)" fill="#045eaa" stroke="#484646" strokeWidth="1">
                <rect width="8" height="10" stroke="none"/>
                <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
            </g>
            <g transform="translate(0 25)" fill="#f1f1f2" stroke="#484646" strokeWidth="1">
                <rect width="8" height="11" stroke="none"/>
                <rect x="0.5" y="0.5" width="7" height="10" fill="none"/>
            </g>
            <rect width="2" height="1" transform="translate(3 2)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 23)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(3 6)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 21)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 4)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 8)" fill="#484646"/>
            <g transform="translate(2 30)" fill="#045eaa" stroke="#484646" strokeWidth="1">
                <rect width="4" height="13" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="12" fill="none"/>
            </g>
            <g transform="translate(2 27)" fill="none" stroke="#484646" strokeWidth="1">
                <rect width="4" height="4" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="3" fill="none"/>
            </g>
            <rect width="4" height="1" transform="translate(2 37)" fill="#484646"/>
        </svg>
    )
}

export const getLCSimplexSVGString = (color: string) => 
    `  
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="470" viewBox="0 0 8 47">
            <g transform="translate(-1198.526 -1576)" fill="#005090">
                <path d="M 1206.0263671875 1605.5 L 1199.0263671875 1605.5 L 1199.0263671875 1598.01123046875 L 1200.004150390625 1576.5 L 1205.048583984375 1576.5 L 1206.0263671875 1598.01123046875 L 1206.0263671875 1605.5 Z" stroke="none"/>
                <path d="M 1200.48193359375 1577 L 1199.5263671875 1598.022705078125 L 1199.5263671875 1605 L 1205.5263671875 1605 L 1205.5263671875 1598.022705078125 L 1204.57080078125 1577 L 1200.48193359375 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1598 L 1206.5263671875 1606 L 1198.5263671875 1606 L 1198.5263671875 1598 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <rect width="2" height="2" transform="translate(3 45)" fill="#c4c4c4"/>
            <g transform="translate(0 35)" fill="${color}" stroke="#484646" stroke-width="1">
                <rect width="8" height="10" stroke="none"/>
                <rect x="0.5" y="0.5" width="7" height="9" fill="none"/>
            </g>
            <g transform="translate(0 25)" fill="#f1f1f2" stroke="#484646" stroke-width="1">
                <rect width="8" height="11" stroke="none"/>
                <rect x="0.5" y="0.5" width="7" height="10" fill="none"/>
            </g>
            <rect width="2" height="1" transform="translate(3 2)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 23)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(3 6)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 21)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 4)" fill="#484646"/>
            <rect width="6" height="1" transform="translate(1 8)" fill="#484646"/>
            <g transform="translate(2 30)" fill="${color}" stroke="#484646" stroke-width="1">
                <rect width="4" height="13" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="12" fill="none"/>
            </g>
            <g transform="translate(2 27)" fill="none" stroke="#484646" stroke-width="1">
                <rect width="4" height="4" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="3" fill="none"/>
            </g>
            <rect width="4" height="1" transform="translate(2 37)" fill="#484646"/>
        </svg>
    `