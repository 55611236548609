import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useAnnotationsTable } from "./hooks";

export const AnnotationTable = () => {
    const { t } = useTranslation();
    const { annotations } = useAnnotationsTable();

    if (annotations.length === 0) {
        return null;
    }
    
    return (
        <table id="annotation-table">
            <thead>
                <tr>
                    <th>{t(LocalizationKeys.Number)}</th>
                    <th>{t(LocalizationKeys.Description)}</th>
                    <th>{t(LocalizationKeys.Qty)}</th>
                </tr>
            </thead>
            <tbody>
                {annotations.map((a, index) => (
                    <tr key={index + 1}>
                        <td>{a.number}</td>
                        <td>{a.description}</td>
                        <td>{a.quantity}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
