import { Side } from "../../../workspace/assembly/breakout/types";
import { FitOption } from "../../../workspace/bottom-bar/fit-panel/types";

export interface IViewportState {
    scale: number;
    increment?: { scale: number };
    fitOption?: { option: FitOption };
    selection?: { breakout?: { position: number, side: Side }};
    isDragging: boolean;
}
export const initialViewportState: IViewportState = {
    scale: 1,
    isDragging: false
}