import { createSlice } from "@reduxjs/toolkit";
import { createSecuredAsyncAction, extractData } from "../../../store/actions";
import { projectManagerService } from "../../services/project-manager/project-manager-service";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { setProjectManagerAction, setRecentAssemblyIdAction, setTermsAndConditionsAction, setOpenAction, setDrawerStatusAction, setSortOptionsAction, setCurrentAssemblyInfoAction, resetProjectManagerAction } from "./actions";
import { initialProjectManager } from "./types";

const projectManagerSlice = createSlice({
    name: "project-manager",
    initialState: initialProjectManager,
    reducers: {
        setProjectManager: setProjectManagerAction,
        setRecentAssemblyId: setRecentAssemblyIdAction,
        setTermsAndConditions: setTermsAndConditionsAction,
        setOpen: setOpenAction,
        setDrawerStatus: setDrawerStatusAction,
        setSortOptions: setSortOptionsAction,
        setCurrentAssemblyInfo: setCurrentAssemblyInfoAction,
        resetProjectManager: resetProjectManagerAction
    }
})

export const ProjectManagerReducer = projectManagerSlice.reducer;
export const { setProjectManager, setRecentAssemblyId, setTermsAndConditions, setOpen, setDrawerStatus, setSortOptions, setCurrentAssemblyInfo, resetProjectManager } = projectManagerSlice.actions;

export const updateSortOptions = (userId: number, sortType: string, sortAscending: boolean) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(setSortOptions({ sortType, sortAscending }));
        const res = await projectManagerService.updateProjectSortOptions(userId, sortType, sortAscending);
        const pm = extractData(res, dispatch)
        if (!pm) {
            console.warn("Something wrong happened when trying to update the sort options", sortType, sortAscending);
        }
    });
}

export const updateTermsAndConditions = (userId: number, date: string, version: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const res = await projectManagerService.updateTermsAndConditions(userId, date, version);
        const pm = extractData(res, dispatch)
        if (pm) {
            dispatch(setTermsAndConditions({ date: pm.tncAcceptedAt, version: pm.tncAcceptedVersion }));
            dispatch(setStatus(Status.Active));
        }
    });
}