import React from 'react';
import { IIconProps } from "./types";

export const TableRowSelectionIcon : React.FC<IIconProps> = ({ text, fill, stroke }) => {
    return (
        <div className="row-selection-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill={fill} stroke={stroke}>
                    <rect stroke="none" width="24" height="24" rx="4"/>
                    <rect fill="none" x="0.5" y="0.5" width="23" height="23" rx="3.5"/>
                </g>
                <text fill="#ffffff" fontSize="16px" fontFamily="TheSansC4s" fontWeight="400" transform="translate(12 17)" textAnchor="middle">
                    {text}
                </text>
            </svg>
        </div>
    )
}