import { ButtonProps } from "@orbit/button";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { t } from "i18next";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { useSelectInput } from "../../../ui/select/hooks";
import { userIdSelector } from "../../store/selectors";
import { updateSortOptions } from "../store/reducer";
import { pmSortOptionsSelector } from "../store/selectors";
import { IDrawerSubHeaderProps, SortOptions } from "./types";

const sortOptions = Object.values(SortOptions)
export const useDrawerSubHeader = ({ onAddClick }: IDrawerSubHeaderProps) => {
    const userId = useSelector(userIdSelector);
    const { lastSaveOrder, sortAscending } = useSelector(pmSortOptionsSelector)
    const dispatch = useStoreDispatch()

    const sortSelect = useSelectInput(sortOptions, "", "", lastSaveOrder.length ? lastSaveOrder : SortOptions.name)

    const onChange = useCallback((e) => {
        const value = e.target.value;
        const sortOption = sortOptions.find(c => c === value);
        if (sortOption) {
            dispatch(updateSortOptions(userId, sortOption, sortAscending));
        }
    }, [dispatch, userId, sortAscending]);

    const sort = {
        select: {
            value: sortSelect.value,
            onChange
        },
        options: sortOptions
    };

    const onAscendingClick = useCallback(() => {
        dispatch(updateSortOptions(userId, lastSaveOrder, !sortAscending));
    }, [dispatch, userId, lastSaveOrder, sortAscending]);

    const sortOrderIconProps = {
        className: "sort-order-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: sortAscending ? t(LocalizationKeys.Ascending) : t(LocalizationKeys.Descending),
        placement: "bottom" as TooltipPlacement,
        onClick: onAscendingClick
    };

    const newAssemblyButtonProps: ButtonProps = {
        className: "new-assembly-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onAddClick
    };

    const newAssembly = {
        buttonProps: newAssemblyButtonProps,
        label: t(LocalizationKeys.NewAssembly)
    };

    return {
        sort,
        sortAscending,
        sortOrderIconProps,
        newAssembly
    }
}