import { currentAssemblySelector, sortedAssemblySelector } from "../assembly/store/selectors"
import { useSelector } from "react-redux"
import { useCallback, useMemo } from "react";
import { AssemblyInfoRowProps } from "./row/types";
import { setCurrentAssemblyInfo, setOpen } from "./store/reducer";
import { pmHomeOpenSelector, pmSortOptionsSelector } from "./store/selectors";
import { useAddNewCable } from "../top-bar/action-panel/hooks";
import { IDrawerSubHeaderProps, SortOptions } from "./sub-header/types";
import { deleteAssembly, loadAssembly } from "../assembly/store/reducer";
import { userIdSelector, userUomSelector } from "../store/selectors";
import { setDrawerStatus } from './store/reducer';
import { DrawerStatus } from "./store/types";
import { useStoreDispatch } from "../../store/hooks";
import { DrawerHeaderProps } from "../../ui/drawer/drawer-header/types";
import { CorningLogo } from "../top-bar/project-manager-panel/types";

export const useProjectManager = () => {
    const currentAssembly = useSelector(currentAssemblySelector);
    const { sortAscending, lastSaveOrder } = useSelector(pmSortOptionsSelector)
    const sortedAssemblies = useSelector(sortedAssemblySelector(sortAscending, lastSaveOrder))
    const userId = useSelector(userIdSelector)
    const drawerOpen = useSelector(pmHomeOpenSelector)
    const userUom = useSelector(userUomSelector)
    const dispatch = useStoreDispatch();
    const { onClick: onNewCable } = useAddNewCable();

    const onAddClick = useCallback(() => {
        onNewCable()
        dispatch(setOpen(false))
    }, [onNewCable, dispatch])

    const rows: AssemblyInfoRowProps[] = useMemo(() => {
        return sortedAssemblies.map((d, i) => {
            const onLoadClick = () => {
                if (d.assemblyId && currentAssembly.id !== d.assemblyId) {
                    dispatch(setOpen(false))
                    dispatch(loadAssembly(d.assemblyId, userUom))
                }
                else if (currentAssembly.id === d.assemblyId) {
                    dispatch(setOpen(false))
                }
            }

            const onDuplicateClick = () => {
                if (sortedAssemblies.length && d.assemblyId) {
                    dispatch(setCurrentAssemblyInfo(d));
                }
            }

            const onEditClick = () => {
                if (sortedAssemblies.length && d.assemblyId) {
                    dispatch(setCurrentAssemblyInfo(d));
                    dispatch(setDrawerStatus(DrawerStatus.Edit));
                }
            }

            const onDeleteClick = () => {
                if (sortedAssemblies.length && d.assemblyId) {
                    if (d.assemblyId !== currentAssembly.id) {
                        dispatch(deleteAssembly(userId, d.assemblyId))
                    }
                    else if (sortedAssemblies.length === 1) {
                        dispatch(setOpen(false))
                        onAddClick()
                        dispatch(deleteAssembly(userId, d.assemblyId));
                    }
                    else {
                        const nextAssembly = sortedAssemblies.find(s => s.assemblyId !== currentAssembly.id)
                        dispatch(setOpen(false))
                        dispatch(loadAssembly(nextAssembly!.assemblyId!, userUom))
                        dispatch(deleteAssembly(userId, d.assemblyId))
                    }
                }
            }

            const date = d.lastModified ? new Date(d.lastModified) : new Date()
            const lastModified = getFormattedDate(date)
            return {
                ...d,
                name: d.name && d.name.length ? d.name : `CAB #${d.assemblyId}`,
                onDuplicateClick,
                onEditClick,
                onDeleteClick,
                onLoadClick,
                selected: d.assemblyId === currentAssembly.id,
                lastModified
            }
        })
    }, [sortedAssemblies, currentAssembly.id, dispatch, userUom, userId, onAddClick])

    const onClose = useCallback(() => {
        if (drawerOpen) {
            dispatch(setOpen(false))
        }
    }, [drawerOpen, dispatch]);

    const drawerProps = {
        className: "project-drawer",
        open: drawerOpen,
        onClose
    };

    const header: DrawerHeaderProps = {
        logo: CorningLogo,
        onClose
    };

    const subHeader: IDrawerSubHeaderProps = {
        sort: SortOptions.name,
        ascending: true,
        cableCount: sortedAssemblies.length,
        onAddClick
    };

    return { drawerProps, header, subHeader, rows };
}

function getFormattedDate(date: Date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}