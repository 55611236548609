import React from 'react';
import { FontTokens, Typography } from '@orbit/theme-provider';

import './TitledField.scss';

export const TitledField: React.FC<{ title: string}> = (props) => {
    return <div className="titled-field">
        <div className="field-title">
            <Typography variant="subtitle1" label={props.title} fontToken={FontTokens.none} />
        </div>
        <div className="field-container">
            {props.children}
        </div>
    </div>
}