import React from "react";
import { Dialog } from "@orbit/dialog";
import { useHelpDialog } from "./hooks";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import "./HelpDialog.scss";

export const HelpDialog: React.FC = () => {
    const { dialogProps, interactions, animation } = useHelpDialog();
    
    if (!dialogProps.open) {
        return null;
    }

    return (
        <Dialog {...dialogProps}>
            <div className="help-container">
                <div className="interactions-container">
                    <List>
                        { interactions.map(({ name, className, onClick }) => 
                            <ListItem className={className} divider key={name} disablePadding>
                                <ListItemButton onClick={onClick}>
                                    <ListItemText primary={name} />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </div>
                <div className="animations-container">
                    <video key={animation} loop muted autoPlay>
                        <source src={animation} type="video/mp4" />
                    </video>
                </div>
            </div>
        </Dialog>
    )
};