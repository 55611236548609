import { createContext } from "react"

export interface IContainerBox {
    width: number,
    height: number,
    x: number,
    y: number
}

export const initialContainerBox: IContainerBox = {
    width: 0,
    height: 0,
    x: 0,
    y: 0
}

export type BoundingBox = Partial<IContainerBox>
export type BoundingBoxProps<T> = T & BoundingBox

export const BoundingBoxContext = createContext<BoundingBox>({...initialContainerBox})
