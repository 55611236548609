import React from 'react';
import { useReportAction } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Description, FileDownload, Print } from '@mui/icons-material';
import { Menu } from '@mui/material'
import { TextButton } from '@orbit/button';
import { ExportCSV } from './CSV/ExportCSV';

import './ReportAction.scss';

export const ReportAction: React.FC = () => {
    const { reportIconProps, menuProps, pdfExport, imageExport } = useReportAction();
    return (
        <>
            <IconButton {...reportIconProps} icon={<Print />} />
            <Menu {...menuProps}>
                <div className="report-menu-container">
                    <TextButton {...pdfExport.buttonProps} startIcon={<Description />}>
                        {pdfExport.label}
                    </TextButton>
                    <TextButton {...imageExport.buttonProps} startIcon={<FileDownload />}>
                        {imageExport.label}
                    </TextButton>
                    <ExportCSV />
                </div>
            </Menu>
        </>
    );
}