import { createContext, Dispatch } from "react"
import { AnyAction } from "redux"

export interface ICableColor {
    jacketColor: number,
    sideATrunkColor: number,
    sideALegColor: number,
    sideBTrunkColor: number,
    sideBLegColor: number,
    furcationColor: number,
    outlineColor: number,
    centerLineColor: number
    shieldColor: number,
    spareColor: number,
    legToleranceColors: {
        minColor: number,
        maxColor: number
    },
    legPrimeToleranceColors: {
        minColor: number,
        maxColor: number
    },
    legLabelToleranceColors: {
        minColor: number,
        maxColor: number
    }
}

export const initialCableColor: ICableColor = {
    jacketColor: 0xF9ED3C,
    sideATrunkColor: 0xF9ED3C,
    sideALegColor: 0xF9ED3C,
    sideBTrunkColor: 0xF9ED3C,
    sideBLegColor: 0xF9ED3C,
    furcationColor: 0x717171,
    shieldColor: 0xFEFEFF,
    outlineColor: 0x484646,
    centerLineColor: 0x101010,
    spareColor: 0xFFFFFF,
    legToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legPrimeToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legLabelToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    }
}

export const selectedBreakoutColor: ICableColor = {
    jacketColor: 0xF9ED3C,
    sideATrunkColor: 0xF9ED3C,
    sideALegColor: 0xF9ED3C,
    sideBTrunkColor: 0xF9ED3C,
    sideBLegColor: 0xF9ED3C,
    furcationColor: 0x717171,
    shieldColor: 0xFEFEFF,
    outlineColor: 0x484646,
    centerLineColor: 0x101010,
    spareColor: 0xFFFFFF,
    legToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legPrimeToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legLabelToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    }
}

export const hoveredBreakoutColor: ICableColor = {
    jacketColor: 0xF9ED3C,
    sideATrunkColor: 0xF9ED3C,
    sideALegColor: 0xF9ED3C,
    sideBTrunkColor: 0xF9ED3C,
    sideBLegColor: 0xF9ED3C,
    furcationColor: 0x717171,
    shieldColor: 0xFEFEFF,
    outlineColor: 0x484646,
    centerLineColor: 0x101010,
    spareColor: 0xFFFFFF,
    legToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legPrimeToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    },
    legLabelToleranceColors: {
        minColor: 0x000000,
        maxColor: 0x000000
    }
}

export interface IColorContext {
    cableColor: ICableColor,
    dispatch: Dispatch<AnyAction>
}

export const initialColorContext: IColorContext = {
    cableColor: initialCableColor,
    dispatch: () => { }
}

export const ColorContext = createContext(initialColorContext)