export interface Length {
    id?: number,
    value: number,
    unit: Unit,
}

export interface ILengthFieldProps {
    label?: string,
    length: Length,
    callback?: (e: Length) => void,
    validate?: boolean,
    validateInput?: (value: number) => boolean,
    disabled?: boolean,
    isPrimary?: boolean,
    helperText?: string
}

export const MetricUnits = {
    Meter: 'm',
    Centimeter: 'cm',
    Millimeter: 'mm',
}

export const ImperialUnits = {
    Feet: 'ft',
    Inches: 'in'
}

export const Units = {
    ...MetricUnits,
    ...ImperialUnits
} as const;

export type MetricUnit = typeof MetricUnits[keyof typeof MetricUnits]
export type ImperialUnit = typeof ImperialUnits[keyof typeof ImperialUnits]
export type Unit = typeof Units[keyof typeof Units]

interface IConversionProps { ratio: number, reverse: number, sourceUnit: Unit, targetUnit: Unit }

export const isMetric = (v: Unit): v is MetricUnit => Object.values(MetricUnits).includes(v)
export const isImperial = (v: Unit): v is ImperialUnit => Object.values(ImperialUnits).includes(v)

export const isPrimary = (v: Unit) => {
    return v === Units.Millimeter || v === Units.Inches
}

function genericConversion(conversion: IConversionProps) {
    const { ratio, reverse, sourceUnit: start, targetUnit: end } = conversion;
    const generic = (from: Length, to: Unit): Length => {
        const { unit, value } = from;
        if (unit === start && to === end) {
            return { value: ratio * value, unit: end };
        }
        else if (unit === end && to === start) {
            return { value: reverse * value, unit: start };
        }
        else {
            return { value: value, unit: to };
        }
    }
    return generic;
}

type Conversion = ReturnType<typeof genericConversion>

const meterToMillimeterConversion: IConversionProps = {
    ratio: 1000,
    reverse: 0.001,
    sourceUnit: Units.Meter,
    targetUnit: Units.Millimeter
}
const centimeterToMillimeterConversion: IConversionProps = {
    ratio: 10,
    reverse: 0.1,
    sourceUnit: Units.Centimeter,
    targetUnit: Units.Millimeter
}

const millimeterToMillimeterConversion: IConversionProps = {
    ratio: 1,
    reverse: 1,
    sourceUnit: Units.Millimeter,
    targetUnit: Units.Millimeter
}

const feetToInchesConversion: IConversionProps = {
    ratio: 12,
    reverse: 0.08333333,
    sourceUnit: Units.Feet,
    targetUnit: Units.Inches
}

const inchesToMillimeterConversion: IConversionProps = {
    ratio: 25.4,
    reverse: 0.03937008,
    sourceUnit: Units.Inches,
    targetUnit: Units.Millimeter
}

const inchesToInchesConversion: IConversionProps = {
    ratio: 1,
    reverse: 1,
    sourceUnit: Units.Inches,
    targetUnit: Units.Inches
}

const metersToMillimeters = genericConversion(meterToMillimeterConversion);
const centimeterToMillimeter = genericConversion(centimeterToMillimeterConversion)
const millimeterToMillimeter = genericConversion(millimeterToMillimeterConversion)
const feetToInches = genericConversion(feetToInchesConversion);
const inchesToMillimeters = genericConversion(inchesToMillimeterConversion);
const inchesToInches = genericConversion(inchesToInchesConversion)


function toScaleFunction(conversion: Conversion, unit: Unit) {
    return (length: Length) => conversion(length, unit)
}

type ScaleConversion = ReturnType<typeof toScaleFunction>

const scaleToBaseFunctions: { [k: string]: ScaleConversion } = {}
scaleToBaseFunctions[Units.Meter] = toScaleFunction(metersToMillimeters, Units.Millimeter)
scaleToBaseFunctions[Units.Centimeter] = toScaleFunction(centimeterToMillimeter, Units.Millimeter);
scaleToBaseFunctions[Units.Millimeter] = toScaleFunction(millimeterToMillimeter, Units.Millimeter);
scaleToBaseFunctions[Units.Feet] = toScaleFunction(feetToInches, Units.Inches)
scaleToBaseFunctions[Units.Inches] = toScaleFunction(inchesToInches, Units.Inches)

const baseToScaleFunctions: { [k: string]: ScaleConversion } = {}
baseToScaleFunctions[Units.Meter] = toScaleFunction(metersToMillimeters, Units.Meter)
baseToScaleFunctions[Units.Centimeter] = toScaleFunction(centimeterToMillimeter, Units.Centimeter);
baseToScaleFunctions[Units.Millimeter] = toScaleFunction(millimeterToMillimeter, Units.Millimeter);
baseToScaleFunctions[Units.Feet] = toScaleFunction(feetToInches, Units.Feet)
baseToScaleFunctions[Units.Inches] = toScaleFunction(inchesToInches, Units.Inches)

export function convertTo(length: Length, unit: Unit) {
    if (length.unit === unit) {
        return length;
    }
    const baseLength = scaleToBaseFunctions[length.unit](length)
    const metricConversion = isMetric(unit)
    const targetLength = metricConversion ?
        inchesToMillimeters(baseLength, Units.Millimeter) :
        inchesToMillimeters(baseLength, Units.Inches)

    const scaledLength = baseToScaleFunctions[unit](targetLength)
    return { ...length, ...scaledLength }
}

const precision: { [k: string]: number } = {}
precision[Units.Meter] = 0
precision[Units.Centimeter] = 1
precision[Units.Millimeter] = 0
precision[Units.Feet] = 0
precision[Units.Inches] = 1

export function toLengthString(length: Length, unitPrecision?: number, shouldRoundUp = true) {
    const fractionDigits = unitPrecision ?? precision[length.unit];
    const roundUp = !isPrimary(length.unit) && shouldRoundUp;
    let str = roundUp ? Math.ceil(length.value).toFixed(fractionDigits) : length.value.toFixed(fractionDigits);
    return str
}

export function formattedConvertTo(length: Length, unit: Unit) {
    const convertedLength: Length = convertTo(length, unit);
    return { ...convertedLength, value: parseFloat(toLengthString(convertedLength)) };
}