import React from 'react';
import { useFiberCounter } from './hooks';
import { FontTokens, Typography } from '@orbit/theme-provider';

import './FiberCounter.scss'

export const FiberCounter: React.FC = () => {
    const { workspaceLoading, sideA, assemblyFiberCount, sideB } = useFiberCounter();
    if (workspaceLoading) {
        return null;
    }
    return (
        <div className="fiber-counter">
            <div className={sideA.className}>
                <Typography variant="h6" label={`${sideA.fiberCount}`} fontToken={FontTokens.none} />
            </div>
            <Typography variant="h6" label="/" />
            <div className="assembly">
                <Typography variant="h6" label={`${assemblyFiberCount}`} fontToken={FontTokens.none} />
            </div>
            <Typography variant="h6" label="/" />
            <div className={sideB.className}>
                <Typography variant="h6" label={`${sideB.fiberCount}`} fontToken={FontTokens.none} />
            </div>
        </div>
    )
}