import { useApp } from "@inlet/react-pixi"
import { useEffect } from "react";
import { initializeCanvasExtractor } from "../canvas-extractor/CanvasExtractor";

export const useInitializer = () => {
    const app = useApp();
    useEffect(() => {
        if (app) {
            initializeCanvasExtractor(app);
        }
    }, [app]);
}