import { createSelector } from "@reduxjs/toolkit";
import { viewportBreakoutSelectionSelector } from "../../pixi/viewport/store/selectors";
import { rootSelector } from "../../store/selectors";
import { isPolarityModeSelector } from "../polarity/store/selectors";
import { Status } from "./types";
import { RootState } from "../../store/reducer";

export const workspaceSelector = createSelector(
    rootSelector,
    root => root.workspace
);

export const userIdSelector = createSelector(
    workspaceSelector,
    workspace => workspace.userId
);

export const companySelector = createSelector(
    workspaceSelector,
    workspace => workspace.company
);

export const showAnnotationDialogSelector = ((state: RootState) => state.workspace.dialogs.showAnnotationEdit);

export const userUomSelector = ((state: RootState) => state.workspace.userUom);

export const loggedInSelector = createSelector(
    workspaceSelector,
    workspace => workspace.loggedIn
);

export const statusSelector = createSelector(
    workspaceSelector,
    workspace => workspace.status
);

export const settingsSelector = createSelector(
    workspaceSelector,
    workspace => workspace.settings
);

export const dialogsSelector = createSelector(
    workspaceSelector,
    workspace => workspace.dialogs
);

export const showAssemblyWizardSelector = (({ workspace }: RootState) => {
    const { showAssemblyWizard, showTermsConditions } = workspace.dialogs;
    return showAssemblyWizard && !showTermsConditions;
});

export const showAssemblyEditSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showAssemblyEdit
);

export const showBreakoutDetailsSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showBreakoutDetails
);

export const showBreakoutEditSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showBreakoutEdit
);

export const showTermsConditionsSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showTermsConditions
);

export const showIdleSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showIdle
);

export const showTimeOutSelector = createSelector(
    dialogsSelector,
    dialogs => dialogs.showTimeOut
);

export const showHelpSelector = (({ workspace }: RootState) => {
    const { showHelp, showTermsConditions } = workspace.dialogs;
    return showHelp && !showTermsConditions;
});

export const workspaceInteractableSelector = createSelector(
    showAssemblyWizardSelector,
    showAnnotationDialogSelector,
    isPolarityModeSelector,
    showAssemblyEditSelector,
    (showAssemblyWizard, showAnnotation, isPolarityModeSelector, showAssemblyEdit) => !showAssemblyWizard && !showAnnotation && !isPolarityModeSelector && !showAssemblyEdit
);

export const workspaceActiveSelector = createSelector(
    statusSelector,
    status => status === Status.Active
);

export const workspaceLoadingSelector = createSelector(
    statusSelector,
    status => status === Status.InitialLoad || status === Status.Loading
);

export const workspaceInactiveSelector = createSelector(
    statusSelector,
    showAssemblyWizardSelector,
    (status, showAssemblyWizard) => status === Status.InitialLoad || status === Status.Loading || showAssemblyWizard
);

export const workspaceSavingSelector = createSelector(
    statusSelector,
    status => status === Status.Saving || status === Status.Synchronizing
);

export const workspacePrintingSelector = createSelector(
    statusSelector,
    status => status === Status.Printing
);

export const showStatusIndicatorSelector = createSelector(
    workspaceLoadingSelector,
    workspaceSavingSelector,
    workspacePrintingSelector,
    statusSelector,
    (loading, saving, printing, status) => loading || saving || printing || status === Status.Saved
);

export const showSettingsSelector = createSelector(
    settingsSelector,
    settings => settings.showSettings
);

export const showBreakoutToolbarSelector = createSelector(
    viewportBreakoutSelectionSelector,
    workspaceActiveSelector,
    workspaceInteractableSelector,
    (selectedBreakout, isWorkspaceActive, workspaceInteractable) => selectedBreakout !== undefined && isWorkspaceActive && workspaceInteractable
);

export const isOptimusUserSelector = createSelector(
    companySelector,
    company => company === "Optimus"
);

export const isEMEAUserSelector = createSelector(
    companySelector,
    company => company === "EMEA"  // for now until we support regions
);

export const isTelcoUserSelector = createSelector(
    companySelector,
    company => company === "TELCO"
);

export const isCTCMUserSelector = createSelector(
    companySelector,
    company => company === "CTCM"
);

export const isCorningUserSelector = createSelector(
    companySelector,
    company => company === "Corning"
);

export const isMetricUnitUserSelector = createSelector(
    isEMEAUserSelector,
    isTelcoUserSelector,
    (isEMEAUser, isTelcoUser) => {
        return isEMEAUser || isTelcoUser;
    }
);

export const canToggleDrawingSpecsSelector = createSelector(
    isCTCMUserSelector,
    isEMEAUserSelector,
    isCorningUserSelector,
    (isCTCMUser, isEMEAUser, isCorningUser) => {
        return isCTCMUser || isEMEAUser || isCorningUser
    }
)