import { createContext, Dispatch } from "react";
import { AnyAction } from "redux";
import { IDocumentFooter, IDocumentHeader, IDocumentPages, IDocumentReportTitle } from "../../document/types";

export interface IPrintDialogState {
    reportTitle: IDocumentReportTitle;
    header: IDocumentHeader;
    footer: IDocumentFooter;
    pages: IDocumentPages;
}

export const initialDocumentTitle: IDocumentReportTitle = {
    headerTitle: "",
    drawingTitle: "",
    showCableOverview: true
};

export const initialDocumentHeader: IDocumentHeader = {
    drawingNumber: "",
    description: "",
    application: "",
    endADescription: "",
    endBDescription: ""
};

export const initialDocumentFooter: IDocumentFooter = {
    approvalDate: "",
    inServiceDate: "",
    revision: "",
    location: "",
    drawnBy: "",
    notes: ""
};

export const initialDocumentPages: IDocumentPages = {
    buildPlan: true,
    polarityDiagram: true,
    connectorViews: true,
};

export const initialPrintDialogState: IPrintDialogState = {
    reportTitle: initialDocumentTitle,
    header: initialDocumentHeader,
    footer: initialDocumentFooter,
    pages: initialDocumentPages
};

export interface IPrintDialogContext {
    state: IPrintDialogState,
    dispatch: Dispatch<AnyAction>
}

export const initialPrintDialogContext: IPrintDialogContext = {
    state: initialPrintDialogState,
    dispatch: () => { }
}

export const PrintDialogContext = createContext(initialPrintDialogContext);