import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useCallback } from "react";
import { showBreakoutDetails, showBreakoutEdit } from "../../store/reducer";
import { showBreakoutToolbarSelector } from "../../store/selectors";
import { useStoreDispatch } from "../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { selectedBreakoutSelector } from "../../assembly/store/selectors";
import { PIGTAIL_LEG } from "../../assembly/connector/types";

export const useBreakoutToolbar = () => {
    const showToolbar = useSelector(showBreakoutToolbarSelector);
    const breakout = useSelector(selectedBreakoutSelector);
    const dispatch = useStoreDispatch();
    const { t } = useTranslation();
    const disabledEdit = breakout.furcation.groups[0].connectors[0].type === PIGTAIL_LEG;
    const onEditClick = useCallback(() => {
        dispatch(showBreakoutEdit(true));
    }, [dispatch]);

    const editProps = {
        className: "edit-breakout-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Edit),
        placement: "top" as TooltipPlacement,
        disabled: disabledEdit,
        onClick: onEditClick
    };

    const onDetailsClick = useCallback(() => {
        dispatch(showBreakoutDetails(true));
    }, [dispatch]);

    const detailsProps = {
        className: "breakout-details-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Details),
        placement: "top" as TooltipPlacement,
        onClick: onDetailsClick
    };
    
    return { showToolbar, editProps, detailsProps };
}