import * as Pixi from 'pixi.js';
import { Viewport as PixiViewport } from 'pixi-viewport';
import React, { Dispatch, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useApp } from '@inlet/react-pixi';
import { IViewportComponentProps, ViewportContext, } from './types';
import { setIsDragging, setViewportBreakoutSelection, setViewportScale, ViewportBridgeReducer } from './store/reducer';
import { initialViewportState } from './store/types';
import { useSelector } from 'react-redux';
import { viewportBreakoutSelectionSelector, viewportFitOptionSelector, viewportIncrementSelector } from './store/selectors';
import { extractWorkspaceCable } from '../canvas-extractor/hooks';
import { FitOptions } from '../../workspace/bottom-bar/fit-panel/types';
import { centerPoint } from '../PixiComponent';
import { SelectionReducer, setSelectedBreakout, unselectBreakouts } from '../interactions/selection/reducers';
import { initialSelectionState } from '../interactions/selection/types';
import { SideContainer } from '../cable/side/types';
import { StatusContext } from '../status/types';
import { Status } from '../../workspace/store/types';
import { useStoreDispatch } from '../../store/hooks';
import { workspaceHasAnnotationsSelector } from '../../workspace/assembly/store/selectors';

const increment = 0.1;

export const useViewport = ({ viewportRef }: IViewportComponentProps) => {
    const app = useApp();
    const windowSize = useWindowSize();
    const { dispatch } = useContext(ViewportContext)
    const localViewportRef = useRef(initializeViewport(app, dispatch))
    const onMouseWheel = useMouseWheel(localViewportRef);
    const { onDoubleClick } = useDoubleClick();
    useWindowKeyDown();
    useClearViewportSelection();

    useEffect(() => {
        viewportRef.current = localViewportRef.current
    }, [viewportRef])

    useEffect(() => {
        const viewport = localViewportRef.current
        const updateScale = () => {
            dispatch(setViewportScale(viewport.scale.x))
        }

        viewport.addListener("zoomed-end", updateScale)

        return () => {
            viewport.removeListener("zoomed-end", updateScale)
        }
    }, [localViewportRef, dispatch])

    useEffect(() => {
        if (!viewportRef.current) {
            viewportRef.current = localViewportRef.current
        }

    }, [app, viewportRef])

    useEffect(() => {
        app.view.addEventListener('wheel', onMouseWheel)
        return () => app.view.removeEventListener('wheel', onMouseWheel);
    }, [app, onMouseWheel])

    useEffect(() => {
        if (localViewportRef.current) {
            localViewportRef.current.screenWidth = windowSize.width;
            localViewportRef.current.screenHeight = windowSize.height;
        }
    }, [localViewportRef, windowSize])

    useEffect(() => {
        app.view.addEventListener('dblclick', onDoubleClick)
        return () => app.view.removeEventListener('dblclick', onDoubleClick);
    }, [app, onDoubleClick])

    return { viewportRef: localViewportRef }
}

const useDoubleClick = () => {
    const { state: viewportState, dispatch: viewportDispatch } = useContext(ViewportContext);
    const { state: statusState } = useContext(StatusContext);
    const { showBreakoutEdit, showBreakoutDetails } = statusState.dialogs;

    const canClearSelection = !showBreakoutEdit && !showBreakoutDetails;
    const onDoubleClick = useCallback(() => {
        if (viewportState.selection && viewportState.selection.breakout && canClearSelection) {
            viewportDispatch(setViewportBreakoutSelection())
        }
    }, [viewportState.selection, viewportDispatch, canClearSelection]);

    return { onDoubleClick };
}

const useMouseWheel = (viewportRef: React.MutableRefObject<PixiViewport | undefined>) => {

    const onMouseWheel = useCallback((e: WheelEvent) => {
        e.preventDefault();
        const viewport = viewportRef.current;
        if (!viewport) return;
        const { deltaY } = e;
        if (deltaY) {
            const zoomDelta = deltaY > 0 ? -increment : increment;
            viewport.wheel({
                percent: zoomDelta,
                smooth: 3
            })

        }

    }, [viewportRef])

    return onMouseWheel;
}

const toggleOverlayPointerEvents = (enabled: boolean) => {
    const overlayComponents = document.getElementsByClassName("toggle-pointer-events") as HTMLCollectionOf<HTMLElement>;
    const pointerEventsValue = enabled ? "auto" : "none";
    for (let i = 0; i < overlayComponents.length; i++) {
        overlayComponents[i].style.pointerEvents = pointerEventsValue;
        overlayComponents[i].style.userSelect = pointerEventsValue;
    }
}

const initializeViewport = (app: Pixi.Application, dispatch: Dispatch<any>) => {
    const viewport = new PixiViewport({
        interaction: app.renderer.plugins.interaction,
        divWheel: document.getElementById("pixi-container") || undefined
    });

    viewport.on('drag-start', () => {
        dispatch(setIsDragging(true));
        toggleOverlayPointerEvents(false);
    });
    viewport.on('drag-end', () => {
        dispatch(setIsDragging(false));
        toggleOverlayPointerEvents(true);
    });

    viewport
        .drag({
            wheel: false,
        })
        .pinch()

    return viewport;
}

export const useWindowSize = () => {
    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }
    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return windowSize;
}

const useWindowKeyDown = () => {
    const { state: viewportState, dispatch: viewportDispatch } = useContext(ViewportContext);
    const { state: statusState } = useContext(StatusContext);
    const { status } = statusState;

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && status === Status.Active && viewportState.selection && viewportState.selection.breakout) {
                viewportDispatch(setViewportBreakoutSelection())
            }
        };
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [status, viewportState.selection, viewportDispatch]);
}

const viewportWidthPadding = 30;
const viewportHeightPadding = 200;
const snapTime = 100;

export const useViewportBridge = () => {
    const viewportRef = useRef<PixiViewport>()
    const [state, dispatch] = useReducer(ViewportBridgeReducer, initialViewportState);
    const storeScale = useSelector(viewportIncrementSelector);
    const fitOption = useSelector(viewportFitOptionSelector);
    const breakoutSelection = useSelector(viewportBreakoutSelectionSelector);
    const hasAnnotations = useSelector(workspaceHasAnnotationsSelector)
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        const viewport = viewportRef.current;
        const cab = extractWorkspaceCable();
        if (!viewport || !storeScale || !cab) return;
        const width = viewport.worldScreenWidth;
        const scale = (1 - storeScale.scale)
        viewport.snapZoom({
            width: scale * width,
            time: 100,
            removeOnInterrupt: true
        })

    }, [storeScale])

    useEffect(() => {
        const cab = extractWorkspaceCable()
        const viewport = viewportRef.current
        if (!cab || !fitOption || !viewport) return;

        if (fitOption) {
            const children = cab.children as any;
            const sides = children.filter(c => c.name === SideContainer).map(c => c as Pixi.Container)
            const widthDifference = sides.map(c => c.width).reduce((a, b) => a - b)

            const cabWidth = cab.width
            const cabHeight = cab.height

            const centerX = centerPoint.x - widthDifference * 0.5
            let centerY = centerPoint.y;
            switch (fitOption.option) {
                case FitOptions.FitToHeight:
                    centerY -= hasAnnotations ? 40 : 60;
                    viewport.snap(centerX, centerY, {
                        forceStart: true,
                        removeOnInterrupt: true,
                        time: snapTime
                    })

                    viewport.snapZoom({
                        height: cabHeight + viewportHeightPadding,
                        time: snapTime,
                        forceStart: true,
                        removeOnInterrupt: true
                    })
                    break;
                case FitOptions.FitToWidth:
                    centerY -= 20;
                    viewport.snap(centerX, centerY, {
                        forceStart: true,
                        removeOnInterrupt: true,
                        time: snapTime
                    })

                    viewport.snapZoom({
                        width: cabWidth + viewportWidthPadding,
                        time: snapTime,
                        forceStart: true,
                        removeOnInterrupt: true
                    })
                    break;
                default:
                    break;
            }
        }
    }, [fitOption, hasAnnotations]);

    useEffect(() => {
        storeDispatch(setViewportScale(state.scale));
    }, [state.scale, storeDispatch]);

    useEffect(() => {
        storeDispatch(setViewportBreakoutSelection(state.selection?.breakout));
    }, [storeDispatch, state.selection]);

    useEffect(() => {
        dispatch(setViewportBreakoutSelection(breakoutSelection));
    }, [dispatch, breakoutSelection]);

    return { viewportContext: { state, dispatch }, viewportRef }
}

export const useViewportSelection = () => {
    const [state, dispatch] = useReducer(SelectionReducer, initialSelectionState);
    const { state: viewportState, dispatch: viewportDispatch } = useContext(ViewportContext);
    const { selection } = viewportState;

    useEffect(() => {
        viewportDispatch(setViewportBreakoutSelection(state.selectedBreakout));
    }, [viewportDispatch, state.selectedBreakout]);

    useEffect(() => {
        if (selection && selection.breakout) {
            dispatch(setSelectedBreakout(selection.breakout));
        } else {
            dispatch(unselectBreakouts());
        }
    }, [selection, dispatch]);

    return { state, dispatch }
}

const useClearViewportSelection = () => {
    const { dispatch: viewportDispatch } = useContext(ViewportContext);
    const { state: statusState } = useContext(StatusContext);
    const { showAssemblyWizard } = statusState.dialogs;

    useEffect(() => {
        if (showAssemblyWizard) {
            viewportDispatch(setViewportBreakoutSelection());
        }
    }, [showAssemblyWizard, viewportDispatch]);
}