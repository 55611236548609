import React from 'react';
import { useBreakoutEditDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { FilledTextField } from '@orbit/text-field';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Checkbox } from '@orbit/checkbox';
import { ColorDialog } from '../../../../ui/dialog/color/ColorDialog';
import { IconButton, IconButtonProps } from '@orbit/icon-button';
import { Palette } from '@mui/icons-material';
import { Typography } from '@orbit/theme-provider';
import { LocalizationKeys } from '../../../../localization/types';
import { t } from 'i18next';

import './BreakoutEditDialog.scss'

export const BreakoutEditDialog = () => {
    const { dialogProps, breakoutAttributes, legAttributes, paletteIcon, colorDialogProps, applyAllConfirmDialogProps } = useBreakoutEditDialog();
    const iconProps: IconButtonProps = {
        ...paletteIcon,
        icon: <Palette />
    };
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="breakout-attributes">
                <div className="section-title">
                    <Typography variant="subtitle1" label={breakoutAttributes.title} />
                </div>
                <div className="field-container horizontal">
                    <div className="field">
                        <FilledTextField {...breakoutAttributes.jacket.field} InputProps={{ endAdornment: <IconButton {...iconProps} onClick={breakoutAttributes.jacket.onClick} />, readOnly: true }} />
                    </div>
                    <div className="field">
                        <FormControl>
                            <FilledTextField {...breakoutAttributes.length} />
                            <FormHelperText>{breakoutAttributes.length.error ? '' : t(LocalizationKeys.LengthA1Help)}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className="field">
                    <FilledTextField {...breakoutAttributes.label} />
                </div>
            </div>
            <div className="legs-attributes">
                <div className="section-title">
                    <Typography variant="subtitle1" label={legAttributes.label} />
                </div>
                <div className="field-container horizontal">
                    <div className="field">
                        <FilledTextField {...legAttributes.jacket.field} InputProps={{ endAdornment: <IconButton {...iconProps} onClick={legAttributes.jacket.onClick} />, readOnly: true }} />
                    </div>
                    <div className="field">
                        <FormControl className='select'>
                            <InputLabel>{legAttributes.connectorTypes.label}</InputLabel>
                            <Select {...legAttributes.connectorTypes.select}>
                                { legAttributes.connectorTypes.options.map((o, i) => <MenuItem key={`connector-types-${i}`} value={o}>{o}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Checkbox {...legAttributes.checkboxProps} />
            </div>
            <ColorDialog {...colorDialogProps} />
            <Dialog {...applyAllConfirmDialogProps} />
        </Dialog>
    )
}