import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { Sides } from "../../../workspace/assembly/breakout/types";
import { lengthIndexerSelector } from "../../../workspace/assembly/store/selectors";
import { CablePositionReducer, setRelativeIndices } from "./reducer";
import { initialCablePostion, SidePositions } from "./types";

export const useCablePosition = () => {
    const [state, dispatch] = useReducer(CablePositionReducer, initialCablePostion);
    const relativeLengths = useSelector(lengthIndexerSelector)

    useEffect(() => {
        const sideIndices: SidePositions = {}
        sideIndices[Sides.SideA] = relativeLengths.sideA.relativeIndices;
        sideIndices[Sides.SideB] = relativeLengths.sideB.relativeIndices;
        dispatch(setRelativeIndices(sideIndices))
    }, [relativeLengths])

    return { 
        cablePositionContext: { state, dispatch } 
    }
}