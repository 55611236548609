import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n";
import { Provider } from "react-redux";
import { CTCMThemeProvider } from '@orbit/theme-provider'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SecureRoute } from "./services/secure-route/SecureRoute";
import { Workspace } from './workspace/Workspace';
import { Login } from "./login/Login";
import { store } from "./store/store";
import { detectBrowser } from "./utils";
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <CTCMThemeProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SecureRoute element={<Workspace />} />} />
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
        </CTCMThemeProvider>
    </Provider>,
rootElement);

detectBrowser();
registerServiceWorker();