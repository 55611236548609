import { createSlice } from "@reduxjs/toolkit";
import { initialSymmetricBreakoutStep } from "./types";

import {
    convertLengthUnitsAction,
    pushBreakoutSetAction,
    resetBreakoutSetupAction,
    setBreakoutStepStateAction,
    setCurrentBreakoutAction,
    undoBreakoutSetAction
} from "./actions"

const breakoutStepSlice = createSlice({
    initialState: initialSymmetricBreakoutStep,
    name: "breakout-step",
    reducers: {
        pushBreakoutSet: pushBreakoutSetAction,
        resetBreakoutSetup: resetBreakoutSetupAction,
        setCurrentBreakout: setCurrentBreakoutAction,
        setBreakoutStepState: setBreakoutStepStateAction,
        undoBreakoutSet: undoBreakoutSetAction,
        convertBreakoutLengthUnits: convertLengthUnitsAction
    }
})

export const BreakoutStepReducer = breakoutStepSlice.reducer;

export const {
    pushBreakoutSet,
    resetBreakoutSetup,
    setCurrentBreakout,
    setBreakoutStepState,
    undoBreakoutSet,
    convertBreakoutLengthUnits } = breakoutStepSlice.actions;
