import React from 'react'
import ReactDOM from 'react-dom'
import { useAnnotationDialog } from './hooks'
import { Dialog } from '@orbit/dialog';
import { Tab, Tabs } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AnnotationRow } from './row/AnnotationRow';
import { FilledTextField } from '@orbit/text-field';
import { ContainedButton, TextButton } from '@orbit/button';
import { Alert } from '@orbit/snackbar';

import './AnnotationDialog.scss'
import { Typography } from '@orbit/theme-provider';

export const AnnotationDialog: React.FC = () => {
    const { dialogProps, tabsProps, annotations, calloutProps, notesProps, snackbarProps } = useAnnotationDialog();
    if (!dialogProps.open) {
        return null;
    }
    const masterIcon = annotations.length === 0 ? <VisibilityOff /> : <Visibility />
    return (
        <Dialog {...dialogProps}>
            <Tabs {...tabsProps} variant="fullWidth">
                <Tab {...calloutProps.tab} />
                <Tab {...notesProps.tab} />
            </Tabs>
            {
                calloutProps.display ?
                <Table stickyHeader className='annotation-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <IconButton {...calloutProps.tableHeaderProps.masterButtonProps} icon={masterIcon} />
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" label={calloutProps.tableHeaderProps.numberLabel} />
                            </TableCell>
                            <TableCell className="description-column" align="left">
                                <Typography variant="body1" label={calloutProps.tableHeaderProps.descriptionLabel} />
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" label={calloutProps.tableHeaderProps.quantityLabel} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { calloutProps.annotationsData.map((d, i) => <AnnotationRow key={`annotation-${i}`} data={d} />) }
                    </TableBody>
                </Table> : null
            }
            {
                notesProps.display ?
                <div className="notes-section">
                    <div className='notes-area'>
                        <FilledTextField {...notesProps.textArea} />
                    </div>
                    <div className="dialog-buttons-container">
                        <TextButton {...notesProps.clear.buttonProps}>
                            {notesProps.clear.label}
                        </TextButton>
                        <ContainedButton {...notesProps.save.buttonProps}>
                            {notesProps.save.label}
                        </ContainedButton>
                    </div>
                </div> : null
            }
            { ReactDOM.createPortal(<Alert {...snackbarProps} />, document.body) }
        </Dialog>
    )
}