import { Sides } from "../../../assembly/breakout/types";
import { IBreakoutSetSetup, initialBreakoutSetSetup } from "../breakout-setup/types";

export interface IBreakoutStep {
    breakoutSetups: IBreakoutSetSetup[],
    isModified: boolean,
    setupIndex: number,
}

export const initialSymmetricBreakoutStep: IBreakoutStep = {
    breakoutSetups: [{ ...initialBreakoutSetSetup }],
    isModified: false,
    setupIndex: 0,
}

export const initialAsymmetricBreakoutStep: IBreakoutStep = {
    breakoutSetups: [
        {
            ...initialBreakoutSetSetup,
            current: { ...initialBreakoutSetSetup.current, side: Sides.SideA }
        },
        {
            ...initialBreakoutSetSetup,
            current: { ...initialBreakoutSetSetup.current, side: Sides.SideB }
        }
    ],
    isModified: false,
    setupIndex: 0,
}