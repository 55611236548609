import React from 'react';
import { disabledOverlaySelector } from '../../workspace/overlay/store/selectors';
import { useSelector } from "react-redux"
import { showAssemblyWizardSelector } from '../../workspace/store/selectors';

export const ImperialIcon: React.FC = () => {
    const disabledOverlay = useSelector(disabledOverlaySelector);
    const showWizard = useSelector(showAssemblyWizardSelector);
    const disabled = disabledOverlay && !showWizard;
    return (
        <svg id="Icon_Imperial" data-name="Icon Imperial" className="imperial-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <rect id="Rectangle_606" data-name="Rectangle 606" width="24" height="24" fill="none"/>
            <path id="Path_319" data-name="Path 319" d="M1273.182,5832.111h5.559v.356a2.472,2.472,0,0,0-1.167.2c-.327.24-.313,1.272-.313,1.272v7.588a.876.876,0,0,0,.216.631c.259.308,1.264.251,1.264.251v.329h-5.559v-.329s1.148.035,1.368-.382.157-.5.157-.5v-7.588s.027-1.032-.311-1.272a2.664,2.664,0,0,0-1.214-.2Z" transform="translate(-1264 -5825.723)" fill={disabled ? "#0000003b" : "#4e4e50"}/>
            <g id="Rectangle_604" data-name="Rectangle 604" transform="translate(2 2)" fill="none" stroke={disabled ? "#0000003b" : "#005293"} strokeWidth="2">
                <rect width="20" height="20" rx="4" stroke="none"/>
                <rect x="1" y="1" width="18" height="18" rx="3" fill="none"/>
            </g>
        </svg>
    );
}