export const MTP = '12 F, MTP'; // default mtp
export const MTP8F_NP = '8 F, MTP, non-pinned';
export const MTP8F_P = '8 F, MTP, pinned';
export const MTP12F_NP = '12 F, MTP, non-pinned';
export const MTP12F_P = '12 F, MTP, pinned';
export const MTP12F_FP_NP = '12 F, MTP Ferrule Push, non-pinned';
export const MTP12F_FP_P = '12 F, MTP Ferrule Push, pinned';
export const MTP24F_NP = '24 F, MTP, non-pinned';
export const MTP24F_P = '24 F, MTP, pinned';
export const MTP24F_FP_NP = '24 F, MTP Ferrule Push, non-pinned';
export const MTP24F_FP_P = '24 F, MTP Ferrule Push, pinned';
export const LC = '2 F, LC Uniboot';
export const LC_APC_UNIBOOT = '2 F, LC APC Uniboot';
export const LC_APC = '2 F, LC APC duplex';
export const LC_UPC = '2 F, LC UPC duplex';
export const SC_APC = '2 F, SC APC duplex';
export const SC_UPC = '2 F, SC UPC duplex';
export const MDC = "2 F, MDC";
export const SN = '2 F, SN';
export const LSH = '1 F, LSH';
export const LC_SIMPLEX = '1 F, LC simplex';
export const LC_APC_SIMPLEX = '1 F, LC APC simplex';
export const SC_SIMPLEX = '1 F, SC simplex';
export const SC_APC_SIMPLEX = '1 F, SC APC simplex';
export const SC_UPC_SIMPLEX = '1 F, SC UPC simplex';
export const PIGTAIL_LEG = 'Pigtail, With Legs';
export const PIGTAIL_NO_LEG = 'Pigtail, No Leg';
export const MMC16F_NP = "16 F, MMC, non-pinned";
export const MMC16F_P = "16 F, MMC, pinned";
export const MMC24F_NP = "24 F, MMC, non-pinned";
export const MMC24F_P = "24 F, MMC, pinned";

export const ConnectorNames = {
    PIGTAIL_LEG,    // Pigtail, With Leg
    PIGTAIL_NO_LEG,  // Pigtail, No Leg
    MTP,            // Default MTP that can be used as a placeholder (12F MTP)
    MTP8F_NP,       // 8F non-pinned MTP connector
    MTP8F_P,        // 8F pinned MTP connector
    MTP12F_NP,      // 12F non-pinned MTP connector
    MTP12F_P,       // 12F pinned MTP connector
    MTP12F_FP_NP,   // 12F non-pinned ferrule push MTP connector
    MTP12F_FP_P,    // 12F pinned ferrule push MTP connector
    MTP24F_NP,      // 24F non-pinned MTP connector
    MTP24F_P,       // 24F pinned MTP connector
    MTP24F_FP_NP,   // 24F non-pinned ferrule push MTP connector
    MTP24F_FP_P,    // 24F pinned ferrule push MTP connector
    LC,             // 2F LC uniboot connector
    LC_APC_UNIBOOT, // 2F LC APC uniboot connector
    LC_APC,         // 2F LC APC duplex connector
    LC_UPC,         // 2F LC UPC duplex connector
    SC_APC,         // 2F SC APC duplex connector
    SC_UPC,         // 2F SC UPC duplex connector
    MDC,            // 2F MDC connector
    SN,             // 2F Senko Nano connector
    LSH,            // 1F Laser Shock Hardering connector
    LC_SIMPLEX,     // 1F LC simplex connector
    LC_APC_SIMPLEX, // 1F LC APC simplex connector
    SC_SIMPLEX,     // 1F SC simplex connector
    SC_APC_SIMPLEX, // 1F SC APC simplex connector
    SC_UPC_SIMPLEX, // 1F SC UPC simplex connector
    MMC16F_NP,      // 16F MMC Non-pinned
    MMC16F_P,       // 16F MMC Pinned
    MMC24F_NP,      // 24F MMC Non-pinned
    MMC24F_P,       // 24F MMC Pinned
} as const

export type ConnectorName = typeof ConnectorNames[keyof typeof ConnectorNames]

export interface IConnectorType {
    id?: number,
    key: string,
    description: string,
    type: ConnectorName,
    fiberCount: number,
    smCode?: string,
    mmCode?: string
}

// Originally we were going to use the part # as the key but some connectors do not have a part #, assigning incrementing key values
export const ConnLCSimplex: IConnectorType = { key: '1', description: LC_SIMPLEX, type: LC_SIMPLEX, fiberCount: 1 };
export const ConnLCAPCSimplex: IConnectorType = { key: '2', description: LC_APC_SIMPLEX, type: LC_APC_SIMPLEX, fiberCount: 1 };
export const ConnSCSimplex: IConnectorType = { key: '3', description: SC_SIMPLEX, type: SC_SIMPLEX, fiberCount: 1 };
export const ConnSCAPCSimplex: IConnectorType = { key: '4', description: SC_APC_SIMPLEX, type: SC_APC_SIMPLEX, fiberCount: 1 };
export const ConnSCUPCSimplex: IConnectorType = { key: '5', description: SC_UPC_SIMPLEX, type: SC_UPC_SIMPLEX, fiberCount: 1 }; 
export const ConnLSH: IConnectorType = { key: '6', description: LSH, type: LSH, fiberCount: 1 };
export const ConnLC: IConnectorType = { key: '7', description: LC, type: LC, fiberCount: 2, smCode: '78', mmCode: '79' };
export const ConnLCAPC: IConnectorType = { key: '8', description: LC_APC_UNIBOOT, type: LC_APC_UNIBOOT, fiberCount: 2, smCode: '78', mmCode: '79' };
export const ConnLCAPCDuplex: IConnectorType = { key: '9', description: LC_APC, type: LC_APC, fiberCount: 2 };
export const ConnLCDuplex: IConnectorType = { key: '10', description: LC_UPC, type: LC_UPC, fiberCount: 2 };
export const ConnSCAPCDuplex: IConnectorType = { key: '11', description: SC_APC, type: SC_APC, fiberCount: 2 };
export const ConnSCDuplex: IConnectorType = { key: '12', description: SC_UPC, type: SC_UPC, fiberCount: 2 };
export const ConnMDC: IConnectorType = { key: '13', description: MDC, type: MDC, fiberCount: 2 };
export const ConnSN: IConnectorType = { key: '14', description: SN, type: SN, fiberCount: 2 };
export const ConnMTP8_NP: IConnectorType = { key: '15', description: MTP8F_NP, type: MTP8F_NP, fiberCount: 8, smCode: 'E8' };
export const ConnMTP8_P: IConnectorType = { key: '16', description: MTP8F_P, type: MTP8F_P, fiberCount: 8, smCode: 'E7' };
export const ConnMTP12_NP: IConnectorType = { key: '17', description: MTP12F_NP, type: MTP12F_NP, fiberCount: 12, smCode: '90', mmCode: '75' };
export const ConnMTP12_P: IConnectorType = { key: '18', description: MTP12F_P, type: MTP12F_P, fiberCount: 12, smCode: '89', mmCode: '93' };
export const ConnMTP12_FP_NP: IConnectorType = { key: '19', description: MTP12F_FP_NP, type: MTP12F_FP_NP, fiberCount: 12, smCode: 'F4' };
export const ConnMTP12_FP_P: IConnectorType = { key: '20', description: MTP12F_FP_P, type: MTP12F_FP_P, fiberCount: 12, smCode: 'F5' };
export const ConnMTP24_NP: IConnectorType = { key: '21', description: MTP24F_NP, type: MTP24F_NP, fiberCount: 24, smCode: 'A9', mmCode: 'A6' };
export const ConnMTP24_P: IConnectorType = { key: '22', description: MTP24F_P, type: MTP24F_P, fiberCount: 24 };
export const ConnMTP24_FP_NP: IConnectorType = { key: '23', description: MTP24F_FP_NP, type: MTP24F_FP_NP, fiberCount: 24, smCode: 'F6' };
export const ConnMTP24_FP_P: IConnectorType = { key: '24', description: MTP24F_FP_P, type: MTP24F_FP_P, fiberCount: 24, smCode: 'F7' };
export const ConnPigtail_No_Leg: IConnectorType = { key: '25', description: PIGTAIL_LEG, type: PIGTAIL_LEG, fiberCount: 1, };
export const ConnPigtail_With_Leg: IConnectorType = { key: '26', description: PIGTAIL_NO_LEG, type: PIGTAIL_NO_LEG, fiberCount: 1 };
export const ConnMMC16_NP: IConnectorType = { key: '27', description: MMC16F_NP, type: MMC16F_NP, fiberCount: 16 };
export const ConnMMC16_P: IConnectorType = { key: '28', description: MMC16F_P, type: MMC16F_P, fiberCount: 16 };
export const ConnMMC24_NP: IConnectorType = { key: '29', description: MMC24F_NP, type: MMC24F_NP, fiberCount: 24 };
export const ConnMMC24_P: IConnectorType = { key: '30', description: MMC24F_P, type: MMC24F_P, fiberCount: 24 }

export const ConnectorTypes = { 
    ConnPigtail_No_Leg,
    ConnPigtail_With_Leg,
    ConnLCSimplex,
    ConnLCAPCSimplex,
    ConnSCSimplex,
    ConnSCAPCSimplex,
    ConnSCUPCSimplex,
    ConnLSH,
    ConnLC,
    ConnLCAPC,
    ConnLCAPCDuplex,
    ConnLCDuplex,
    ConnSCAPCDuplex,
    ConnSCDuplex,
    ConnMDC,
    ConnSN,
    ConnMMC16_NP,
    ConnMMC16_P,
    ConnMMC24_NP,
    ConnMMC24_P,
    ConnMTP8_NP,
    ConnMTP8_P,
    ConnMTP12_NP,
    ConnMTP12_P,
    ConnMTP12_FP_NP,
    ConnMTP12_FP_P,
    ConnMTP24_NP,
    //ConnMTP24_P, Tim asked to hide this connector until we have more info
    ConnMTP24_FP_NP,
    ConnMTP24_FP_P,
} as const;

export type ConnectorType = typeof ConnectorTypes[keyof typeof ConnectorTypes]

export const getConnectorType = (type: string) : IConnectorType => {
    switch (type) {
        case ConnLC.type:
            return ConnLC;
        case ConnLCAPC.type:
            return ConnLCAPC;
        case ConnLCAPCDuplex.type:
            return ConnLCAPCDuplex;
        case ConnLCDuplex.type:
            return ConnLCDuplex;
        case ConnLCSimplex.type:
            return ConnLCSimplex;
        case ConnLCAPCSimplex.type:
            return ConnLCAPCSimplex;
        case ConnMDC.type:
            return ConnMDC;
        case ConnSCAPCDuplex.type:
            return ConnSCAPCDuplex;
        case ConnSCDuplex.type:
            return ConnSCDuplex;
        case ConnSCSimplex.type:
            return ConnSCSimplex;
        case ConnSCAPCSimplex.type:
            return ConnSCAPCSimplex;
        case ConnSCUPCSimplex.type:
            return ConnSCUPCSimplex;
        case ConnSN.type:
            return ConnSN;
        case ConnLSH.type:
            return ConnLSH;
        case ConnMTP8_NP.type:
            return ConnMTP8_NP;
        case ConnMTP8_P.type:
            return ConnMTP8_P; 
        case ConnMTP12_NP.type: 
            return ConnMTP12_NP;
        case ConnMTP12_P.type:
            return ConnMTP12_P;
        case ConnMTP12_FP_NP.type:
            return ConnMTP12_FP_NP;
        case ConnMTP12_FP_P.type:
            return ConnMTP12_FP_P;
        case ConnMTP24_NP.type:
            return ConnMTP24_NP;
        case ConnMTP24_P.type:
            return ConnMTP24_P;
        case ConnMTP24_FP_NP.type:
            return ConnMTP24_FP_NP;
        case ConnMTP24_FP_P.type:
            return ConnMTP24_FP_P;
        case ConnPigtail_No_Leg.type:
            return ConnPigtail_No_Leg;
        case ConnPigtail_With_Leg.type:
            return ConnPigtail_With_Leg;
        case ConnMMC16_NP.type:
            return ConnMMC16_NP;
        case ConnMMC16_P.type:
            return ConnMMC16_P;
        case ConnMMC24_NP.type:
            return ConnMMC24_NP;
        case ConnMMC24_P.type:
            return ConnMMC24_P;
        default:
            return { key: "-1", type: LC, description: "ConnectorNotFound", fiberCount: -1 };
    }
}

export const LC_KEY = "LCUniboot";
export const MTP_KEY = "MTP";
export const MMC_KEY = "MMC"
export const PINNED_KEY = "Pinned";
export const UNPINNED_KEY = "Unpinned"
export const MTP_PINNED_KEY = `${MTP_KEY}${PINNED_KEY}`;
export const MTP_UNPINNED_KEY = `${MTP_KEY}${UNPINNED_KEY}`;
export const MTP24_PINNED_KEY = `${MTP_KEY}24${PINNED_KEY}`;
export const MTP24_UNPINNED_KEY = `${MTP_KEY}24${UNPINNED_KEY}`;
export const MMC_PINNED_KEY = `${MMC_KEY}_${PINNED_KEY}`;
export const MMC_UNPINNED_KEY = `${MMC_KEY}_${UNPINNED_KEY}`;
export const SN_KEY = "SN";
export const LSH_KEY = "LSH";
export const LC_DUPLEX_KEY = "LCDuplex";
export const SC_DUPLEX_KEY = "SCDuplex";
export const MDC_KEY = "MDC";
export const LC_SIMPLEX_KEY = "LCSimplex";
export const SC_SIMPLEX_KEY = "SCSimplex";
export const PIGTAIL_KEY = "Pigtail"

export const ConnectoryTypeKeys = {
    LC_KEY,
    MTP_PINNED_KEY,
    MTP_UNPINNED_KEY,
    MTP24_PINNED_KEY,
    MTP24_UNPINNED_KEY,
    SN_KEY,
    LSH_KEY,
    LC_DUPLEX_KEY,
    SC_DUPLEX_KEY,
    MDC_KEY,
    LC_SIMPLEX_KEY,
    SC_SIMPLEX_KEY,
    PIGTAIL_KEY,
    MMC_PINNED_KEY,
    MMC_UNPINNED_KEY,
}

export type ConnectorTypeKey = typeof ConnectoryTypeKeys[keyof typeof ConnectoryTypeKeys]

export const getConnectorTypeString = (type: string) => {
    switch (type) {
        case ConnMTP8_NP.type:
        case ConnMTP12_NP.type: 
        case ConnMTP12_FP_NP.type:
            return MTP_UNPINNED_KEY;
        case ConnMTP8_P.type:
        case ConnMTP12_P.type:
        case ConnMTP12_FP_P.type:
            return MTP_PINNED_KEY;
        case ConnMTP24_NP.type:
        case ConnMTP24_FP_NP.type:
            return MTP24_UNPINNED_KEY;
        case ConnMTP24_P.type:
        case ConnMTP24_FP_P.type:
            return MTP24_PINNED_KEY;
        case ConnSN.type:
            return SN_KEY;
        case ConnLSH.type:
            return LSH_KEY;
        case ConnLCAPCDuplex.type:
        case ConnLCDuplex.type:
            return LC_DUPLEX_KEY;
        case ConnLCSimplex.type:
        case ConnLCAPCSimplex.type:
            return LC_SIMPLEX_KEY;
        case ConnSCAPCDuplex.type:
        case ConnSCDuplex.type:
            return SC_DUPLEX_KEY;
        case ConnSCSimplex.type:
        case ConnSCAPCSimplex.type:
        case ConnSCUPCSimplex.type:
            return SC_SIMPLEX_KEY
        case ConnMDC.type:
            return MDC_KEY;
        case ConnPigtail_No_Leg.type:
        case ConnPigtail_With_Leg.type:
            return PIGTAIL_KEY
        case ConnMMC16_NP.type:
        case ConnMMC24_NP.type:
            return MMC_UNPINNED_KEY;
        case ConnMMC16_P.type:
        case ConnMMC24_P.type:
            return MMC_PINNED_KEY;
        case ConnLC.type:
        case ConnLCAPC.type:
        default:
            return LC_KEY;
    }
}

export const isMTP = (type: string) => {
    return type.includes(MTP_KEY);
}

export const isMMC = (type: string) => {
    return type.includes(MMC_KEY);
}

export const isDuplex = (type: string) => {
    return type.toLowerCase().includes("duplex");
}

export const isPigtailConnector = (type: string) => {
    return type.includes(PIGTAIL_KEY)
}

export const LCSIMPLEX_FAMILY = "LC Simplex";
export const SCSIMPLEX_FAMILY = "SC Simplex";
const LC_FAMILY = "LC";
const MDC_FAMILY = "MDC";
const SN_FAMILY = "SN";
const LSH_FAMILY = "LSH";
export const LCDUPLEX_FAMILY = "LC Duplex";
export const SCDUPLEX_FAMILY = "SC Duplex";
const MTP8_FAMILY = "MTP8";
const MTP12_FAMILY = "MTP12";
const MTP24_FAMILY = "MTP24";
const MMC16_FAMILY = "MMC16";
const MMC24_FAMILY = "MMC24";

export const getConnectorFamilyFromType = (connectorType: string) => {
    switch (connectorType) {
        case ConnMTP8_NP.type:
        case ConnMTP8_P.type:
            return MTP8_FAMILY;
        case ConnMTP12_NP.type: 
        case ConnMTP12_FP_NP.type:
        case ConnMTP12_P.type:
        case ConnMTP12_FP_P.type:
            return MTP12_FAMILY;
        case ConnMTP24_NP.type:
        case ConnMTP24_FP_NP.type:
        case ConnMTP24_P.type:
        case ConnMTP24_FP_P.type:
            return MTP24_FAMILY;
        case ConnSN.type:
            return SN_FAMILY;
        case ConnLSH.type:
            return LSH_FAMILY;
        case ConnMDC.type:
            return MDC_FAMILY;
        case ConnLCAPCDuplex.type:
        case ConnLCDuplex.type:
            return LCDUPLEX_FAMILY;
        case ConnLCSimplex.type:
        case ConnLCAPCSimplex.type:
            return LCSIMPLEX_FAMILY;
        case ConnSCAPCDuplex.type:
        case ConnSCDuplex.type:
            return SCDUPLEX_FAMILY;
        case ConnSCSimplex.type:
        case ConnSCAPCSimplex.type:
        case ConnSCUPCSimplex.type:
            return SCSIMPLEX_FAMILY;
        case ConnMMC16_NP.type:
        case ConnMMC16_P.type:
            return MMC16_FAMILY;
        case ConnMMC24_NP.type:
        case ConnMMC24_P.type:
            return MMC24_FAMILY;
        case ConnLC.type:
        case ConnLCAPC.type:
        default:
            return LC_FAMILY;
    }
}

export const getConnectorFamilyFromFiberCount = (fiberCount: number) => {
    return fiberCount > 2 ? `MTP${fiberCount}` : `${fiberCount}F`;
}

export interface ICableType {
    key: string,
    description: string | null;
}