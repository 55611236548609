import { Container, Graphics, Text } from '@inlet/react-pixi';
import React from 'react';
import { BezierCurve } from '../../../../bezier-curve/BezierCurve';
import { ConnectorSprite } from '../connector-sprite/ConnectorSprite';
import { ConnectorHighlight } from '../highlight/ConnectorHighlight';
import { useConnectorLeg } from './hooks';
import { ConnectorLegContainer, ConnectorLegText, ConnectorLegTrigger, ConnectorLegTrunk, IConnectorLegProps } from './types';

export const ConnectorLeg: React.FC<IConnectorLegProps> = (props) => {
    const { drawTrunk, drawTrigger, connectorSpriteProps, connectorTextProps, bezierCurve, highlightProps, isPigtail, isPigtailWithNoLeg } = useConnectorLeg(props)
    return (
        <Container name={ConnectorLegContainer}>
            <BezierCurve {...bezierCurve}/>
            <Graphics name={ConnectorLegTrunk} draw={drawTrunk} />
            {!isPigtail && <ConnectorSprite {...connectorSpriteProps} />}
            {!isPigtailWithNoLeg && <Graphics name={ConnectorLegTrigger} draw={drawTrigger} />}
            <ConnectorHighlight {...highlightProps} />
            {!isPigtailWithNoLeg && <Text name={ConnectorLegText} {...connectorTextProps} />}
        </Container>
    )
}