import { useCallback } from "react"
import * as Pixi from 'pixi.js'
import { IBezierCurveProps } from "./types";

export const useBezierCurve = (props: IBezierCurveProps) => {
    const drawCurve = useCallback((g: Pixi.Graphics) => {
        g.clear();
        const cpX = props.cp.x, cpY = props.cp.y;
        const cpX2 = props.cp2.x, cpY2 = props.cp2.y;
        const dstX = props.dst.x, dstY = props.dst.y;
        
        g.lineStyle(props.outline.thickness, props.outline.color)
        g.moveTo(cpX, cpY)
        g.bezierCurveTo(
            cpX, cpY,
            cpX2, cpY2,
            dstX, dstY
        )
        
        g.moveTo(cpX, cpY)
        g.lineStyle(props.line.thickness, props.line.color)
        g.bezierCurveTo(
            cpX, cpY,
            cpX2, cpY2,
            dstX, dstY
        )
    }, [props])

    return { drawCurve }
}