import { createSelector } from "@reduxjs/toolkit";
import { isPolarityModeSelector } from "../../polarity/store/selectors";
import { dialogsSelector, showAnnotationDialogSelector, showAssemblyWizardSelector, showSettingsSelector, statusSelector } from "../../store/selectors";
import { Status } from "../../store/types";

export const disabledOverlaySelector = createSelector(
    statusSelector,
    showAnnotationDialogSelector,
    isPolarityModeSelector,
    showSettingsSelector,
    dialogsSelector,
    showAssemblyWizardSelector,
    (status, showAnnotationDialog, isPolarityModeSelector, showSettings, dialogs, showWizard) => status !== Status.Active || showAnnotationDialog || isPolarityModeSelector || showSettings || dialogs.showAssemblyEdit || dialogs.showBreakoutEdit || dialogs.showBreakoutDetails || showWizard
);