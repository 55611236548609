import { ButtonProps } from "@orbit/button"
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { LocalizationKeys } from "../localization/types"
import { AuthenticationService, authenticationService, Token } from "../services/authentication/authentication-service"
import { useStoreDispatch } from "../store/hooks"
import { login } from "../workspace/store/reducer"
import { loggedInSelector } from "../workspace/store/selectors"

export const useLogin = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const loggedIn = useSelector(loggedInSelector);
    const dispatch = useStoreDispatch();

    const onEmailChange = useCallback((e) => {
        const email = e.target.value;
        setEmail(email);
    }, []);

    const emailField = {
        label: t(LocalizationKeys.Email),
        value: email,
        palette: MainPalettes.primary,
        onChange: onEmailChange
    }

    const onPasswordChange = useCallback((e) => {
        const password = e.target.value;
        setPassword(password);
    }, []);

    const passwordField = {
        label: t(LocalizationKeys.Password),
        value: password,
        palette: MainPalettes.primary,
        type: "password",
        onChange: onPasswordChange
    };

    const onLoginClick = useCallback(async (e) => {
        e.preventDefault();
        const res = await authenticationService.login(`${email}`, `${password}`);
        setErrorText(res.succesful ? "" : t(LocalizationKeys.LoginFailed));
        if (res.succesful && res.data) {
            AuthenticationService.saveToken(res.data);
            const token = Token.fromString(res.data);
            if (token) {
                dispatch(login({ userId: Number(token.sub), company: token.company, uom: token.uom }));                           
            }
        } else {
            AuthenticationService.saveToken(null);
        }
    }, [email, password, dispatch, t]);

    const loginButtonProps: ButtonProps = {
        className: "login-button",
        type: "submit",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onLoginClick
    }

    const loginProps = {
        buttonProps: loginButtonProps,
        label: t(LocalizationKeys.Login)
    }

    const token = AuthenticationService.getToken();
    const secured = token && !token.isExpired();

    useEffect(() => {
        const token = AuthenticationService.getToken();
        const secured = token && !token.isExpired();
        if (secured || loggedIn) {
            window.location.reload()
        }
    }, [loggedIn]);

    return { secured, emailField, passwordField, errorText, loginProps, onLoginClick }
}