import React from 'react';
import { ILabelToleranceProps } from './types';
import { ToleranceMarker } from './ToleranceMarker';
import { LineMarker } from '../line/LineMarker';

export const LabelToleranceMarker: React.FC<ILabelToleranceProps> = ({ toleranceProps, leftLineMarkerProps, rightLineMarkerProps }) => {
    return (
        <>
            <ToleranceMarker {...toleranceProps} />
            <LineMarker {...leftLineMarkerProps} />
            <LineMarker {...rightLineMarkerProps} />
        </>
    )
}