import { Box, Chip, Tab, Tabs } from '@mui/material';
import { ContainedButton, TextButton } from '@orbit/button';
import { Checkbox } from '@orbit/checkbox';
import { Dialog } from '@orbit/dialog';
import { CircularProgressIndeterminate } from '@orbit/progress-indicator';
import { FilledTextField } from '@orbit/text-field';
import { MainPalettes, Typography } from '@orbit/theme-provider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';
import { TabPanel } from '../../../ui/tab-panel/TabPanel';
import { FileSelector } from './file-selector/FileSelector';
import { usePrintDialog } from './hook';
import './PrintDialog.scss';
import { HelpIcon } from '../../../ui/icon/help-icon/HelpIcon';

export const PrintDialog: React.FC = () => {
    const {
        dialogProps,
        drawingNumber,
        description,
        application,
        approvalDate,
        inServiceDate,
        revision,
        headerTitle,
        drawingTitle,
        cableOverview,
        endATitle,
        endBTitle,
        print,
        cancel,
        onTabChange,
        currentTabIndex,
        drawnBy,
        location,
        notes,
        connectorViews,
        polarityDiagram,
        buildPlan,
        userProvidedImages,
        onFileChange,
        onDeleteImage
    } = usePrintDialog();
    const { t } = useTranslation();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="print-container">
                <div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentTabIndex} onChange={onTabChange} variant="fullWidth">
                            <Tab label={t(LocalizationKeys.ReportTitle)} />
                            <Tab label={t(LocalizationKeys.Header)} />
                            <Tab label={t(LocalizationKeys.Footer)} />
                            <Tab label={t(LocalizationKeys.Pages)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={currentTabIndex} index={0}>
                        <div className="field-container">
                            <FilledTextField {...headerTitle} />
                        </div>
                        <div className="field-container">
                            <FilledTextField {...drawingTitle} />
                        </div>
                        <div className="checkbox-fields">
                            <Checkbox {...cableOverview} />
                        </div>
                    </TabPanel>
                    <TabPanel value={currentTabIndex} index={1}>
                        <div className="field-container">
                            <FilledTextField {...drawingNumber} />
                        </div>
                        <div className="field-container">
                            <FilledTextField {...description} />
                        </div>
                        <div className="field-container horizontal">
                            <FilledTextField {...endATitle} />
                            <FilledTextField {...endBTitle} />
                        </div>
                        <div className="field-container" style={{ marginTop: "8px" }}>
                            <FilledTextField {...application} />
                        </div>
                    </TabPanel>
                    <TabPanel value={currentTabIndex} index={2}>
                        <div className="field-container">
                            <FilledTextField {...location} />
                        </div>
                        <div className="field-container horizontal">
                            <FilledTextField {...drawnBy} />
                            <FilledTextField {...revision} />
                        </div>
                        <div className="field-container horizontal" style={{ marginTop: 0 }}>
                            <FilledTextField {...approvalDate} />
                            <FilledTextField {...inServiceDate} />
                        </div>
                        <div className="field-container" style={{ marginTop: 8 }}>
                            <FilledTextField {...notes} multiline minRows={4} maxRows={4} style={{ height: "100%" }} />
                        </div>
                    </TabPanel>
                    <TabPanel value={currentTabIndex} index={3}>
                        <div className="checkbox-fields">
                            <Checkbox {...connectorViews} />
                            <Checkbox {...polarityDiagram} />
                            <span>
                                <Checkbox {...buildPlan} />
                                <HelpIcon text={t(LocalizationKeys.BuildPlanHelp)} />
                            </span>
                        </div>
                        <div className="image-upload">
                            <Typography variant='body1' label={t(LocalizationKeys.PrintDialogNote)} />
                            <FileSelector onFileChange={onFileChange} />
                            <div className="filename-chips">
                                {userProvidedImages.map((image, i) => <Chip key={image.objectUrl} label={image.fileName} onDelete={() => onDeleteImage(i)} />)}
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
            <div className="buttons-container">
                <TextButton {...cancel.buttonProps}>
                    {cancel.label}
                </TextButton>
                <ContainedButton {...print.buttonProps} startIcon={print.isPrinting ? <CircularProgressIndeterminate palette={MainPalettes.primary} /> : null}>
                    {print.label}
                </ContainedButton>
            </div>
        </Dialog>
    )
}