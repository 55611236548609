import { IInputDialogProps } from "./types";
import { useInputField } from "../../input/hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { validateInputChars } from "../../../workspace/assembly/breakout/details/row/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes } from "@orbit/theme-provider";

export const useInputDialog = ({ open, value, title, fieldLabel, maxCharacterCount, restriction, onClose, cancelProps, saveProps }: IInputDialogProps) => {
    const validate = restriction?.validate ?? validateInputChars;
    const inputField = useInputField(fieldLabel, value, maxCharacterCount, undefined, validate);
    const helperText = restriction?.message(inputField?.value) ?? LocalizationKeys.EmptyInputField;
    const { t } = useTranslation();

    const onCancel = useCallback(() => {
        if (cancelProps.onClick) {
            cancelProps.onClick();
        }
        onClose();
    }, [cancelProps, onClose]);

    const onSave = useCallback(() => {
        if (saveProps.onClick) {
            saveProps.onClick(inputField.value);
        }
        onClose();
    }, [saveProps, inputField.value, onClose]);

    const actions: IDialogActions = {
        cancelText: cancelProps.text,
        onCancelClick: onCancel,
        confirmText: saveProps.text, 
        onConfirmClick: onSave,
        disableConfirm: saveProps.disabled || !inputField.isValid,
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        },
    };
 
    const dialogProps: DialogProps = {
        open,
        className: "input-dialog",
        title,
        actions,
        preventOutsideDismiss: true,
        onClose
    };

    const input = {
        className: "input-field",
        label: inputField.label,
        value: inputField.value,
        palette: MainPalettes.primary,
        error: !inputField.isValid,
        maxLength: inputField.maxLength,
        helperText: inputField.isValid ? "" : t(helperText),
        onChange: inputField.onChange
    };

    return { 
        dialogProps,
        input
    }
}