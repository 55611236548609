import { useSelector } from "react-redux";
import { currentAssemblySelector, tolerancesSelector } from "../../assembly/store/selectors";
import { useCallback } from "react"
import { AssemblyDocument } from "./types";
import { setAssemblyDocument, setUserProvidedImages } from "../store/reducer";
import { useCanvasExtract } from "../../../pixi/canvas-extractor/hooks";
import { save } from "../../services/export/file-saver";
import { useTranslation } from "react-i18next";
import { extractConnectorMapping } from "../../offscreen/connector-table/hooks";
import { IAssemblyInfo } from "../../assembly/info/types";
import { isOptimusUserSelector } from "../../store/selectors";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { useStoreDispatch } from "../../../store/hooks";
import { userProvidedImagesSelector } from "../store/selectors";

export const useAssemblyDocument = () => {
    const assembly = useSelector(currentAssemblySelector);
    const tolerances = useSelector(tolerancesSelector);
    const isOptimusUser = useSelector(isOptimusUserSelector);
    const userProvidedImages = useSelector(userProvidedImagesSelector);
    const dispatch = useStoreDispatch();
    const { extractCanvas } = useCanvasExtract();
    const { t } = useTranslation();

    const exportAssembly = useCallback(async (saveAsImage?: boolean, info?: IAssemblyInfo) => {
        const connectorRows = extractConnectorMapping(assembly, t);
        const assemblyInfo = info ?? { ...assembly.assemblyInfo };
        const document: AssemblyDocument = {
            assembly: { ...assembly, assemblyInfo },
            connectorRows,
            showToleranceNotes: !isOptimusUser,
            inch: tolerances.inch,
            milimeter: tolerances.milimeter,
            userProvidedImages
        }
        dispatch(setAssemblyDocument(document));
        await timeout(500);
        extractCanvas();
        await timeout(500);
        await save(document, saveAsImage);
        dispatch(setUserProvidedImages([]));
        dispatch(setStatus(Status.Active));
    }, [assembly, tolerances, isOptimusUser, userProvidedImages, dispatch, extractCanvas, t]);

    return { exportAssembly };
}

export function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}