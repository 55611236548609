import React from 'react';
import { useScalePanel } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Add, Remove } from '@mui/icons-material';
import { Typography } from '@orbit/theme-provider';
import { Divider } from '@mui/material';

import "./ScalePanel.scss";

export const ScalePanel = () => {
    const { zoomIn, zoomOut, scalePercent } = useScalePanel();
    return (
        <div className="panel scale-panel toggle-pointer-events">
            <div className="panel-item">
                <IconButton {...zoomIn} icon={<Add />} />
                <IconButton {...zoomOut} icon={<Remove />} />
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="panel-item scale-percentage non-selectable">
                <Typography variant="body1" label={scalePercent} />
            </div>
        </div>
    )
}