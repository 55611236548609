import { ICableBaseAnnotationProps } from "./types";
import { useContext, useMemo } from "react";
import { SideContext } from "../../side/types";
import { CircleMarkerProps } from "../../../markers/circle/types";
import { Orientations, Unidirections } from "../../../markers/types";
import { AnnotationContext } from "../../../../workspace/annotation/store/types";

const fiberCountName = "1";

const radius = 10;
const orientation = Orientations.Vertical;
const unidirection = Unidirections.Start;
const color = 0x000000;
const thickness = 1;
const alpha = 1;

export const useCableBaseAnnotation = (props: ICableBaseAnnotationProps) => {
    const { annotations } = useContext(AnnotationContext);
    const { m } = useContext(SideContext);
    const { shieldStart, furcationStart } = props;
    
    const cableBaseAnnotations: CircleMarkerProps[] = useMemo(() => {
        const annotationMarkers: CircleMarkerProps[] = [];

        if (annotations.includes(fiberCountName)) {
            annotationMarkers.push({
                coordinate: { x: 0, y: 7.5 },
                radius,
                orientation,
                unidirection,
                length: 25,
                color,
                thickness,
                alpha,
                text: "1"
            });
        }
    
        const sideCharacter = m > 0 ? "b" : "a";
        const serialNumberName = `2${sideCharacter}`;
        if (annotations.includes(serialNumberName)) {
            annotationMarkers.push({
                coordinate: { x: m * shieldStart, y: 5 },
                radius,
                orientation,
                unidirection,
                length: 30,
                color,
                thickness,
                alpha,
                text: serialNumberName
            });
        }
        
        const heatshrinkTransitionName = `3${sideCharacter}`;
        if (annotations.includes(heatshrinkTransitionName)) {
            annotationMarkers.push({
                coordinate: { x: m * furcationStart, y: 5 },
                radius,
                orientation,
                unidirection,
                length: 30,
                color,
                thickness,
                alpha,
                text: heatshrinkTransitionName
            });
        }

        return annotationMarkers;
    }, [annotations, m, shieldStart, furcationStart]);

    return { cableBaseAnnotations };
}