import { TextButton } from "@orbit/button";
import React from "react";
import { useFileSelector } from "./hooks";
import { IFileSelectorProps } from "./types";

export const FileSelector = (props: IFileSelectorProps) => {
    const { inputProps, buttonProps, label } = useFileSelector(props);
    return (
        <div className="file-selector">
            <input {...inputProps} />
            <TextButton {...buttonProps}>{label}</TextButton>
        </div>
    )
}