import JwtDecode from 'jwt-decode';
import { WebService } from '../web-service';
import { IApiResult } from '../api-result';

interface IToken {
    iat: number; // issued at time
    exp: number; // expires
    sub: string; // identity
    username: string;
    company: string;
    raw?: string; // the token itself
    uom: string; // units of measurement
}

export class AuthenticationService extends WebService {
    constructor() {
        super();
        this.isUnstableApi = false;
    }

    public login(username: string, password: string): Promise<IApiResult<string>> {
        return this.post('api/auth/login', { username, password });
    }

    public logout(): Promise<IApiResult<void>> {
        return this.post('api/auth/logout', {});
    }

    public renew(token: string): Promise<IApiResult<string>> {
        return this.post('api/auth/renew', { token });
    }

    public async getUomFromUser(id: number): Promise<IApiResult<string>> {
        return await this.get(`api/auth/uom/${id}`);
    }

    public async updateUom(userId: number, uom: string): Promise<IApiResult<string>> {
        return await this.post('api/auth/uom', {userId, uom});
    }

    public async getBuildTime(): Promise<any> {
        try {
            const res = await this.client.get('/buildtime.txt');
            return res.data;
        }
        catch (err) {
            console.error('Unable to get version', err);
        }
    }

    private static LS_TOKEN_KEY = 'token';
    public static saveToken(token: string | null): void {
        if (token) {
            window.localStorage.setItem(AuthenticationService.LS_TOKEN_KEY, token);
        }
        else {
            window.localStorage.removeItem(AuthenticationService.LS_TOKEN_KEY);
        }
    }

    public static getToken(): Token | null {
        const tokenStr = window.localStorage.getItem(AuthenticationService.LS_TOKEN_KEY);
        if (!tokenStr) {
            return null;
        }
        const token = Token.fromString(tokenStr);
        return token;
    }

    public static getTokenString(): string | null {
        const tokenStr = window.localStorage.getItem(AuthenticationService.LS_TOKEN_KEY);
        if (!tokenStr) {
            return null;
        }
        return tokenStr;
    }
}

export class Token implements IToken {
    public iat: number;
    public exp: number;
    public sub: string;
    public username: string;
    public company: string;
    public uom: string;
    public raw?: string;

    constructor(token: IToken, raw: string) {
        this.iat = token.iat;
        this.exp = token.exp;
        this.sub = token.sub;
        this.username = token.username;
        this.company = token.company;
        this.uom = token.uom;
        this.raw = raw;
    }

    public isExpired(): boolean {
        return !this.raw || !this.exp || this.exp * 1000 < Date.now();
    }

    public static fromString(tokenStr: string): Token | null {
        try {
            const itoken = JwtDecode<IToken>(tokenStr);
            return new Token(itoken, tokenStr);
        }
        catch {
            console.error('Unable to create token from string:', tokenStr);
            return null;
        }
    }
}

export const authenticationService = new AuthenticationService();
