import React from 'react';
import { useTermsConditionsDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { ContainedButton, TextButton } from '@orbit/button';
import { Typography } from '@orbit/theme-provider';

import './TermsConditionsDialog.scss';

export const TermsConditionsDialog: React.FC = () => {
    const { dialogProps, tncAcceptedAt, tncCurrentVersionAccepted, accept, cancel } = useTermsConditionsDialog();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="tnc-container">
                <div className="text-area-container">
                    <div className="text-area-header">
                        {`CAB Configurator Software\nEnd User License Agreement "EULA"\n\n`}
                        <b>{"Corning Optical Communications LLC"}</b>
                        {`\n\n---------------------------------------------------------------------------------------------------------------------------------------------\n\n`}
                        <b>{`IMPORTANT: PLEASE READ CAREFULLY BEFORE ACCESSING THE CAB CONFIGURATOR SOFTWARE THROUGH THE SITE`}</b>
                    </div>
                    <div className="text-area-body">
                        <b>{`\nTHIS IS A LEGAL END USER LICENSE AGREEMENT BETWEEN THE CUSTOMER AND CORNING OPTICAL COMMUNICATIONS LLC ("CORNING"). `}</b>
                        {`THESE TERMS AND CONDITIONS GOVERN YOUR ACCESS TO AND USE OF THIS SITE AND THE CAB CONFIGURATIOR SOFTWARE AND BY ACCESSING, VIEWING OR USING THIS SITE OR USING THE CAB CONFIGURATOR SOFTWARE YOU ACKNOWLEDGE THAT YOU HAVE READ, FULLY UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS AND CONDITIONS.\n\n`}
                        {`CORNING PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT CUSTOMER ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE "ACCEPT" BUTTON YOU (A) ACCEPT THIS AGREEMENT AND AGREE THAT CUSTOMER IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT IF CUSTOMER IS A CORPORATION, GOVERNMENTAL ORGANIZATION OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF CUSTOMER AND BIND CUSTOMER TO ITS TERMS.  IF CUSTOMER DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, CORNING WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO CUSTOMER AND YOU MUST NOT USE THE CAB CONFIGURATOR SOFTWARE\n\n`}
                        <b>{`1. DEFINITIONS\n\n`}</b>
                        <b>{`"Affiliate"`}</b>{` means, with respect to a party, any corporation or other legal entity which is directly or indirectly controlling or controlled by, or under common control with that party. As used in this definition, "control" means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of a corporation or legal entity.\n\n`}
                        <b>{`"Agreement"`}</b>{` means this EULA, including all Exhibits annexed hereto or otherwise incorporated herein, as amended from time to time.\n\n`}
                        <b>{`"Computer"`}</b>{` means an electronic device that accepts information in digital or similar forms and manipulates it for a specific result based on a sequence of instructions.\n\n`}
                        <b>{`"Customer"`}</b>{` means the person or entity that is using the Corning property.\n\n`}
                        <b>{`"Customer Data"`}</b>{` means any data, information, content, record, and files that Customer (or any of its users) loads or entered into, transmits to, or makes available to the Corning Property, including but not limited to Personal Information.\n\n`}
                        <b>{`"Documentation"`}</b>{` means the documentation accompanying or relating to the Software or other Corning Property.\n\n`}
                        <b>{`"End User License Agreement"`}</b>{` or `}<b>{`"EULA"`}</b>{` is a legal agreement between the Customer and Corning for the Use of all or any portion of the Corning Property.\n\n`}
                        <b>{`"Corning Property"`}</b>{` has the meaning ascribed to it in Section 2 of this EULA.\n\n`}
                        <b>{`"Personal Information"`}</b>{` means information about an identifiable individual or other information that is subject to applicable privacy or data protection laws.\n\n`}
                        <b>{`"Software"`}</b>{` means the software used to configure and define a cable assembly product.\n\n`}
                        <b>{`"Use"`}</b>{` or `}<b>{`"Using"`}</b>{` means to access, install, or otherwise use the Software or other Corning Property.\n\n`}
                        <b>{`2. OWNERSHIP\n\n`}</b>
                        {`Corning, together with its Affiliates and licensors as applicable, own all rights, title and interest, including all intellectual property rights, in and to the Software, Documentation, and any other products or services ("Corning Property") made available by Corning in connection with this Agreement, including any modifications, adaptations, translations or derivative works thereto. The Corning Property is licensed, not sold and, except for the license rights expressly set forth in this Agreement, Corning does not grant any rights or interests in and to the Corning Property. Customer will treat Corning Property as Corning's proprietary information and property and will not Use the Corning Property except as provided for in this Agreement.\n\n`}
                        <b>{`3. SOFTWARE LICENSE\n\n`}</b>
                        {`Subject to the terms of this Agreement, Corning grants to Customer a non-transferable, non-sublicensable and non-exclusive license during the to Use the Software to model and design a cable assembly. In addition Customer may only:\n\n`}
                        {`(A) Use the Software in accordance with this Agreement and solely for Customer's internal business purposes; and\n\n`}
                        {`(B) Use the Documentation and other Corning Property in order to exercise Customer's rights under Section 3(A) above.\n\n`}
                        <b>{`4. PROHIBITED USE\n\n`}</b>
                        {`Except as expressly permitted by this Agreement, Customer will not itself, and will not permit others to:\n\n`}
                        {`(A) reverse-engineer, alter, decompile, disassemble, unlock, adapt or translate the Software or any other Corning Property, or make any attempt to reconstruct or discover in any way, any source code, object code, programming, underlying algorithms design structure interoperability interfaces, concepts, or underlying ideas of the Corning Property;\n\n`}
                        {`(B) copy, transfer, rent, lease, distribute or sublicense the Corning Property or any intellectual property rights therein, or otherwise make the Corning Property available to any third party;\n\n`}
                        {`(C) Use the Corning Property for the purposes of building a similar or competitive product or service;\n\n`}
                        {`(D) repackage the Corning Property for sale as a competing product;\n\n`}
                        {`(E) remove any identification markings, including but not limited to copyrights notices and trademarks of the Corning Property;\n\n`}
                        {`(F) make any modifications or enhancement to the Corning Property, unless expressly agreed to in writing by Corning;\n\n`}
                        {`(G) Use the Corning Property: (i) in violation of any applicable law or intellectual property right; or (ii) for any purpose or in any manner not expressly permitted in this Agreement; or\n\n`}
                        {`(H) Use the Corning Property to create, collect, transmit, store, use or process any Customer Data (i) that Customer does not have the lawful right to create, collect, transmit, store, use or process; (ii) that violates any applicable laws, or infringes, violates or otherwise misappropriates the intellectual property rights or other rights of any third party (including any moral right, privacy right or right of publicity); or (iii) that contains any computer viruses, worms, malicious code, or any software intended to damage or alter a computer system or data.\n\n`}
                        <b>{`5. CUSTOMER DATA\n\n`}</b>
                        {`Customer is responsible for maintaining, protecting, and making backups of Customer Data. To the extent permitted by applicable law, Corning will not be liable for any failure to store, or for loss or corruption of Customer Data. The Customer grants to Corning, its Affiliates, and its subcontractors a nonexclusive, worldwide, royalty-free, irrevocable, sublicensable, and fully paid-up right during the Term to access, collect, use, process, store, disclose, transmit, copy, modify and display Customer Data to:\n\n`}
                        {`(A) provide the Corning Property in accordance with this Agreement (including the Maintenance and Support Services);\n\n`}
                        {`(B) improve and enhance the Corning Property and any internal Corning business purpose;\n\n`}
                        {`(C) perform data analyses for the purpose of improving Company's products or Services or developing new products or services; and\n\n`}
                        {`(D) use the data to manufacture and sell cable assembly products to the Customer.\n\n`}
                        <b>{`6. PRIVACY\n\n`}</b>
                        {`Corning's collection, use storage, processing, disclosure and deletion of Personal Information will be treated in accordance with Corning's privacy statement located at `}<a href={`https://www.corning.com/worldwide/en/privacy-policy.html`} tabIndex={-1}>{`https://www.corning.com/worldwide/en/privacy-policy.html`}</a>{`.\n\n`}
                        <b>{`7. RESPONSIBILITY FOR USERS\n\n`}</b>
                        {`Customer will identify  of all individuals who are employees of Customer that Customer wishes to Use the Corning Property (each, a "Permitted User"). Customer will ensure that Permitted Users only use the Corning Property through their respective user accounts and Customer will not allow any Permitted User to share their user account details with any other person. Customer is responsible for identifying and authenticating all Permitted Users, for ensuring only Permitted Users Use the Corning Property, and for Permitted Users' compliance with this Agreement. Customer will promptly notify Corning of any actual or suspected unauthorized use of the Corning Property. Corning reserves the right to suspend, deactivate, or replace any user account if it determines that a user account may have been used for an unauthorized purpose. Customer will ensure that all Permitted Users are contractually bound to terms and conditions with Customer that are no less restrictive or protective of Corning's rights than those set forth in this Agreement.\n\n`}
                        <b>{`8. THIRD PARTY CONTENT\n\n`}</b>
                        {`The Corning Property may contain links or access to third-party content, websites, or services. Corning does not endorse any third-party content, websites, or services, or guarantee their quality, accuracy, reliability, completeness, currency, timeliness, non-infringement, merchantability, or fitness for any purpose. Third-party content, websites, and services are not under Corning's control, and if Customer chooses to access or use any such content, websites, or service, Customer does so at its own risk. Customer acknowledges that it is responsible for entering into and complying with the terms of use applicable to any third-party content, websites, or services.\n\n`}
                        <b>{`9. MALICIOUS CODE AND SECURITY\n\n`}</b>
                        {`The Use of the Corning Property is at Customer's own risk. Corning does not guarantee or warrant that the Corning Property is compatible with Customer's Computers. Customer will be responsible for implementing safeguards to protect the security and integrity of Customer's Computers.\n\n`}
                        <b>{`10. CONFIDENTIAL INFORMATION\n\n`}</b>
                        {`For the purposes of this Agreement, a party receiving Confidential Information (as defined below) will be the `}<b>{`"Recipient"`}</b>{`, the party disclosing such information will be the `}<b>{`"Discloser"`}</b>{` and `}<b>{`"Confidential Information"`}</b>{` of Discloser means any and all information of Discloser, its Affiliates, or any of its licensors that has or will come into the possession or knowledge of the Recipient in connection with or as a result of entering into this Agreement, including information concerning the Discloser's past, present or future customers, suppliers, technology or business, and in the case of Customer, including the Customer Data, and in the case of Corning, including the Corning Property, provided that Discloser's Confidential Information does not include: (A) information already known or independently developed by Recipient without access to Discloser's Confidential Information; (B) information that is publicly available through no wrongful act of Recipient; or (C) information received by Recipient from a third party who was free to disclose it without confidentiality obligations.\n\n`}
                        {`Recipient hereby agrees that during the Term and at all times thereafter it will not, except to exercise its license rights or perform its obligations under this Agreement: (A) disclose Confidential Information of the Discloser to any person, except to its own personnel or Affiliates that have a "need to know" and that have entered into written agreements no less protective of such Confidential Information than this Agreement, and to such other recipients as the Discloser may approve in writing; (B) use Confidential Information of the Discloser; or (C) alter or remove from any Confidential Information of the Discloser any proprietary legend. Each party will take industry standard precautions to safeguard the other party's Confidential Information, which will in any event be at least as stringent as the precautions that the Recipient takes to protect its own Confidential Information of a similar type.\n\n`}
                        {`Notwithstanding the foregoing, Recipient may disclose Discloser's Confidential Information: (A) to the extent that such disclosure is required by applicable law or by the order of a court or similar judicial or administrative body, provided that, except to the extent prohibited by law, the Recipient promptly notifies the Discloser in writing of such required disclosure and cooperates with the Discloser to seek an appropriate protective order; or (B) to its legal counsel and other professional advisors if and to the extent such persons need to know such Confidential Information in order to provide applicable professional advisory services in connection with the party's business.\n\n`}
                        <b>{`11. DISCLAIMER OF WARRANTIES\n\n`}</b>
                        {`CORNING DOES NOT REPRESENT OR WARRANT THAT THE CORNING PROPERTY WILL BE UNINTERRUPTED OR ERROR FREE OR THAT ALL ERRORS CAN OR WILL BE CORRECTED; NOR DOES IT MAKE ANY REPRESENTATIONS OR  WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE CORNING PROPERTY. EXCEPT AS SPECIFICALLY PROVIDED IN THIS AGREEMENT, THE CORNING PROPERTY IS PROVIDED "AS IS" AND "AS AVAILABLE". ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY LICENSED THIRD-PARTY TECHNOLOGY IS STRICTLY BETWEEN CUSTOMER AND THE THIRD PARTY.\n\n`}
                        {`TO THE EXTENT PERMITTED BY APPLICABLE LAW, CORNING HEREBY DISCLAIMS ALL EXPRESS, IMPLIED, COLLATERAL OR STATUTORY WARRANTIES, REPRESENTATIONS AND CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, COMPATIBILITY, TITLE, NON-INFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE OR USE, OR ANY WARRANTIES OR CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF ANY OF THE FOREGOING, CORNING EXPRESSLY DISCLAIMS ANY REPRESENTATION, CONDITION OR WARRANTY THAT ANY DATA OR INFORMATION PROVIDED TO CUSTOMER IN CONNECTION WITH CUSTOMER'S USE OF THE CORNING PROPERTY (OR ANY PART THEREOF), INCLUDING THE MAINTENANCE AND SUPPORT SERVICES, OR CAN OR SHOULD BE RELIED UPON BY CUSTOMER FOR ANY PURPOSE WHATSOEVER.\n\n`}
                        {`CUSTOMER ACKNOWLEDGES AND AGREES THAT CUSTOMER IS RESPONSIBLE FOR THE COMPATIBILITY OF THE CORNING PROPERTY WITH ANY EQUIPMENT, SOFTWARE, SERVICES AND THIRD-PARTY EQUIPMENT OR ANY OTHER MATERIALS NOT PROVIDED BY CORNING. CUSTOMER ACKNOWLEDGES AND AGREES THAT THE CORNING PROPERTY IS NOT DESIGNED, MANUFACTURED OR INTENDED FOR USE OR DISTRIBUTION WITH ON-LINE CONTROL EQUIPMENT IN HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE, SUCH AS IN THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT, NAVIGATION, COMMUNICATION, OR CONTROL SYSTEMS, DIRECT LIFE SUPPORT MACHINES, WEAPONS SYSTEMS, OR OTHER USES IN WHICH FAILURE OF THE CORNING PROPERTY COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE.\n\n`}
                        <b>{`12. CUSTOMER INDEMNITY\n\n`}</b>
                        {`Customer will defend, indemnify and hold harmless Corning, its employees, officers, directors, Affiliates, agents, contractors, successors, and assigns against any and all third-party claims (including damages, recoveries, deficiencies, interest, penalties and legal fees), directly or indirectly arising from or in connection with: (A) Customer's breach of any of Customer's obligations, representations, warranties or covenants under this Agreement; (B) use of the Corning Property (or any part thereof) by Customer or any of its users in combination with any unauthorized third-party software, application or service; or (C) Customer Data. Customer will fully cooperate with Corning in the defense of any claim defended by Customer pursuant to its indemnification obligations under this Agreement and will not settle any such claim without the prior written consent of Corning.\n\n`}
                        <b>{`13. LIMITATION OF LIABILITY\n\n`}</b>
                        {`TO THE EXTENT PERMITTED BY APPLICABLE LAW, WHATEVER THE LEGAL BASIS FOR THE CLAIM, NEITHER CORNING NOR ITS AFFILIATES, DISTRIBUTORS, SUPPLIERS OR AGENTS WILL BE LIABLE TO CUSTOMER FOR ANY DIRECT OR INDIRECT DAMAGES (INCLUDING WITHOUT LIMITATION CONSEQUENTIAL, INDIRECT, INCIDENTAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUES, SAVINGS, DATA OR USE OR BUSINESS INTERRUPTION) EVEN IF CORNING, ITS AFFILIATES' DISTRIBUTORS, SUPPLIERS OR AGENTS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH POSSIBILITY WAS REASONABLY FORESEEABLE. CORNING'S AGGREGATE LIABILITY AND THAT OF ITS DISTRIBUTORS, SUPPLIERS AND AGENTS UNDER OR IN CONNECTION WITH THIS AGREEMENT SHALL BE LIMITED TO $10.00 US.\n\n`}
                        <b>{`14. TERM AND TERMINATION\n\n`}</b>
                        {`This Agreement will commence on the date of acceptance of this Agreement electronically by the Permitted User and continue for a period of one year following such date, unless terminated earlier in accordance with this EULA ("Term"). The Term of this Agreement will automatically renew on the terms and conditions contained herein for additional successive periods of one year, unless either party gives notice to the other that it does not wish to renew this Agreement at least 30 days prior to the end of the then current Term, as applicable, or unless terminated in accordance with this EULA.\n\n`}
                        {`Corning may terminate this Agreement and the license(s) granted hereunder, upon providing 30 days' prior written notice, if Customer materially breaches any terms and conditions of this Agreement.\n\n`}
                        {`Upon termination of this Agreement, each party will promptly destroy or return all Confidential Information of the other party in its possession or control including for greater clarity, in the case of Customer, Customer will destroy or return all copies of the Corning Property in its possession and control.\n\n`}
                        {`All provisions that expressly or by their nature survive termination of this Agreement will survive termination.\n\n`}
                        <b>{`15. EXPORT RULES\n\n`}</b>
                        {`The Customer agrees that the Corning Property will not be shipped, transferred or exported into any country or Used in any manner prohibited by the United States Export Administration Act or any other applicable export laws and related regulations (collectively the `}<b>{`"Export Laws"`}</b>{`). All rights to Use the Corning Property are granted on condition that such rights are forfeited if Customer fails to comply with the terms of this Agreement.\n\n`}
                        <b>{`16. GOVERNING LAW\n\n`}</b>
                        {`This Agreement is hereby governed by the laws of the State of North Carolina without regards to the conflicts of law principles. The parties will initiate any lawsuits in connection with this Agreement in North Carolina, and irrevocably attorn to the exclusive personal jurisdiction and venue of the courts sitting therein. The U.N. Convention on Contracts for the International Sale of Goods will not apply to this Agreement. This choice of jurisdiction does not prevent Corning from seeking injunctive relief with respect to a violation of intellectual property rights or confidentiality obligations in any appropriate jurisdiction.\n\n`}
                        <b>{`17. NOTICES\n\n`}</b>
                        {`Notices sent to either party will be effective when delivered in writing and in person or by email, one day after being sent by overnight courier, or five days after being sent by first class mail postage prepaid to the official contact designated by the party to whom a notice is being given. Notices must be sent: (A) if to Corning, to the following address and email contact:\n\n`}
                        <b>{`Address:`}</b>{`Corning Incorporated, One  Riverfront Plaza, Corning, New York 14831 Attention: Corporate Secretary\n\n`}
                        <b>{`E-mail: `}</b><a href={`mailto:notices@corning.com`} tabIndex={-1}>{`notices@corning.com`}</a>
                        {`\n\nand (B) if to Customer, to the current mailing or email address that Corning has on file with respect to Customer. Either party may change its contact information by giving notice thereof to the other party.\n\n`}
                        <b>{`18. ASSIGNMENT\n\n`}</b>
                        {`Customer may not assign this Agreement to any third party without Corning's prior written consent. Notwithstanding the foregoing, Customer may assign this Agreement without consent to any parent, subsidiary or other Affiliate, or to any successor to its business (whether by merger, change of control, sale of all or substantially all of its business), or in the event of an internal reorganization. Any purported assignment or delegation by Customer in violation of this Section will be null and void. Corning may assign this Agreement or any rights under this Agreement to any third party without Customer's consent. This Agreement inures to the benefit of and is binding upon the parties and their respective successors and permitted assigns.\n\n`}
                        <b>{`19. FORCE MAJEURE\n\n`}</b>
                        {`Neither party will be liable for delays caused by any event or circumstances beyond that party's reasonable control (except for a failure by Customer to pay Fees or Customer's indemnities under this Agreement), including acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (other than those involving that party's employees), Internet service failures or delays, or the unavailability or modification by third parties of telecommunications or hosting infrastructure or third party websites (`}<b>{`"Force Majeure"`}</b>{`). For clarity, a Force Majeure event shall not excuse the Customer from its failure to pay Fees or Customer's indemnities under this Agreement.\n\n`}
                        <b>{`20. SEVERABILITY\n\n`}</b>
                        {`Any provision of this Agreement found by a tribunal or court of competent jurisdiction to be invalid, illegal, or unenforceable will be severed from this Agreement and all other provisions of this Agreement will remain in full force and effect.\n\n`}
                        <b>{`21. AMENDMENTS\n\n`}</b>
                        {` No amendment, supplement, modification, waiver, or termination of this Agreement and, unless otherwise expressly specified in this Agreement, no consent or approval by any party, will be binding unless executed in writing by the party or parties to be bound thereby.`}<b>{` NOTWITHSTANDING THE PRECEDING SENTENCE, CORNING MAY UNILATERALLY AMEND THIS EULA, IN WHOLE OR IN PART (EACH, AN "AMENDMENT"), BY GIVING CUSTOMER PRIOR NOTICE OF SUCH AMENDMENT. UNLESS OTHERWISE INDICATED BY CORNING, ANY SUCH AMENDMENT WILL BECOME EFFECTIVE AS OF THE DATE THE NOTICE OF SUCH AMENDMENT IS PROVIDED TO CUSTOMER.\n\n`}</b>
                        <b>{`22. ACKNOWLEDGEMENT AND ENTIRE AGREEMENT\n\n`}</b>
                        {`THE CUSTOMER ACKNOWLEDGES TO HAVE READ THIS AGREEMENT, UNDERSTAND IT AND AGREES TO BE BOUND BY ITS TERMS AND CONDITIONS. THE CUSTOMER FURTHER AGREES THAT THIS AGREEMENT (INCLUDING ANY APPLICABLE ORDER FORMS) REPRESENTS THE ENTIRE AGREEMENT BETWEEN CUSTOMER AND CORNING WITH RESPECT TO THE CORNING PROPERTY AND SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.`}
                    </div>
                </div>
                <div className="actions-container">
                    <div className="tnc-accepted">
                        <Typography variant="body1" label={tncAcceptedAt} />
                    </div>
                    { tncCurrentVersionAccepted ? null : <TextButton {...cancel.buttonProps}>{cancel.label}</TextButton>}
                    <ContainedButton {...accept.buttonProps}>
                        {accept.label}
                    </ContainedButton>
                </div>
            </div>
        </Dialog>
    );
}