import React from 'react';

export const LSH: React.FC = () => {
    return (
        <svg id="Group_1487" data-name="Group 1487" xmlns="http://www.w3.org/2000/svg" width="120" height="640" viewBox="0 0 12 64">
            <g id="Group_1455" data-name="Group 1455">
                <path id="Path_480" data-name="Path 480" d="M.462,32.325l.02,1.167A6.309,6.309,0,0,1,1.54,36.94,6.341,6.341,0,0,1,.482,40.525v.952H1.906v2.4H5.4V40.434L2.678,32.357S.462,32.37.462,32.325Z" fill="#045eaa"/>
                <path id="Path_481" data-name="Path 481" d="M5.4,44.375h-3.5a.478.478,0,0,1-.462-.492V41.97H.482a.477.477,0,0,1-.461-.492v-.953a.508.508,0,0,1,.087-.287,5.822,5.822,0,0,0,.97-3.292,5.822,5.822,0,0,0-.97-3.167A.513.513,0,0,1,.021,33.5L0,32.334H0l.549-.469v-.02c.151.019,1.2.027,2.126.02h0a.466.466,0,0,1,.435.325l2.724,8.077a.522.522,0,0,1,.027.167v3.449A.478.478,0,0,1,5.4,44.375ZM2.367,43.39H4.941V40.519L2.355,32.851c-.663,0-1.113,0-1.422,0l.008.483A6.866,6.866,0,0,1,2,36.935,6.865,6.865,0,0,1,.944,40.68v.306h.962a.477.477,0,0,1,.461.492Z" fill="#484646"/>
            </g>
            <g id="Group_1479" data-name="Group 1479">
                <g id="Group_1456" data-name="Group 1456">
                    <path id="Path_482" data-name="Path 482" d="M9.568.492H7.149l-1.43,25.48a12.913,12.913,0,0,1,2.64,0,12.9,12.9,0,0,1,2.639,0Z" fill="#045eaa"/>
                    <path id="Path_483" data-name="Path 483" d="M5.719,26.464a.445.445,0,0,1-.333-.151.56.56,0,0,1-.122-.469L6.688.463A.475.475,0,0,1,7.148,0h2.42a.476.476,0,0,1,.461.463l1.425,25.382a.558.558,0,0,1-.121.467.442.442,0,0,1-.493.123.388.388,0,0,1-.081-.042c-.36-.028-1.454.011-2.373.07-.97-.059-2.069-.1-2.427-.07a.439.439,0,0,1-.081.041A.423.423,0,0,1,5.719,26.464Zm.67-1.071c.44,0,1.074.028,2,.088,1.023-.068,1.682-.1,2.118-.087L9.133.985H7.583L6.214,25.4Z" fill="#484646"/>
                </g>
                <g id="Group_1471" data-name="Group 1471">
                    <g id="Group_1463" data-name="Group 1463">
                        <g id="Group_1457" data-name="Group 1457">
                            <line id="Line_248" data-name="Line 248" x2="0.427" transform="translate(7.094 1.88)" fill="#045eaa"/>
                            <path id="Path_484" data-name="Path 484" d="M7.521,2.372H7.093a.477.477,0,0,1-.461-.492.478.478,0,0,1,.461-.493h.428a.478.478,0,0,1,.461.493A.477.477,0,0,1,7.521,2.372Z" fill="#484646"/>
                        </g>
                        <g id="Group_1458" data-name="Group 1458">
                            <line id="Line_249" data-name="Line 249" x2="0.427" transform="translate(7.094 5.108)" fill="#045eaa"/>
                            <path id="Path_485" data-name="Path 485" d="M7.521,5.6H7.093a.493.493,0,0,1,0-.984h.428a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1459" data-name="Group 1459">
                            <line id="Line_250" data-name="Line 250" x2="0.427" transform="translate(7.094 8.502)" fill="#045eaa"/>
                            <path id="Path_486" data-name="Path 486" d="M7.521,8.994H7.093a.493.493,0,0,1,0-.984h.428a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1460" data-name="Group 1460">
                            <line id="Line_251" data-name="Line 251" x2="0.946" transform="translate(6.574 12.31)" fill="#045eaa"/>
                            <path id="Path_487" data-name="Path 487" d="M7.521,12.8H6.574a.493.493,0,0,1,0-.984h.947a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1461" data-name="Group 1461">
                            <line id="Line_252" data-name="Line 252" x2="0.946" transform="translate(6.574 17.049)" fill="#045eaa"/>
                            <path id="Path_488" data-name="Path 488" d="M7.521,17.542H6.574a.494.494,0,0,1,0-.985h.947a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                        </g>
                        <g id="Group_1462" data-name="Group 1462">
                            <line id="Line_253" data-name="Line 253" x2="1.476" transform="translate(6.045 21.623)" fill="#045eaa"/>
                            <path id="Path_489" data-name="Path 489" d="M7.521,22.115H6.045a.493.493,0,0,1,0-.984H7.521a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                    </g>
                    <g id="Group_1470" data-name="Group 1470">
                        <g id="Group_1464" data-name="Group 1464">
                            <line id="Line_254" data-name="Line 254" x1="0.427" transform="translate(9.169 1.88)" fill="#045eaa"/>
                            <path id="Path_490" data-name="Path 490" d="M9.6,2.372H9.169a.477.477,0,0,1-.462-.492.478.478,0,0,1,.462-.493H9.6a.478.478,0,0,1,.461.493A.477.477,0,0,1,9.6,2.372Z" fill="#484646"/>
                        </g>
                        <g id="Group_1465" data-name="Group 1465">
                            <line id="Line_255" data-name="Line 255" x1="0.427" transform="translate(9.169 5.108)" fill="#045eaa"/>
                            <path id="Path_491" data-name="Path 491" d="M9.6,5.6H9.169a.493.493,0,0,1,0-.984H9.6a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1466" data-name="Group 1466">
                            <line id="Line_256" data-name="Line 256" x1="0.427" transform="translate(9.169 8.502)" fill="#045eaa"/>
                            <path id="Path_492" data-name="Path 492" d="M9.6,8.994H9.169a.493.493,0,0,1,0-.984H9.6a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1467" data-name="Group 1467">
                            <line id="Line_257" data-name="Line 257" x1="0.946" transform="translate(9.169 12.31)" fill="#045eaa"/>
                            <path id="Path_493" data-name="Path 493" d="M10.115,12.8H9.169a.493.493,0,0,1,0-.984h.946a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1468" data-name="Group 1468">
                            <line id="Line_258" data-name="Line 258" x1="0.946" transform="translate(9.169 17.049)" fill="#045eaa"/>
                            <path id="Path_494" data-name="Path 494" d="M10.115,17.542H9.169a.494.494,0,0,1,0-.985h.946a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                        </g>
                        <g id="Group_1469" data-name="Group 1469">
                            <line id="Line_259" data-name="Line 259" x1="1.476" transform="translate(9.169 21.623)" fill="#045eaa"/>
                            <path id="Path_495" data-name="Path 495" d="M10.644,22.115H9.169a.493.493,0,0,1,0-.984h1.475a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                    </g>
                </g>
                <g id="Group_1478" data-name="Group 1478">
                    <g id="Group_1472" data-name="Group 1472">
                        <line id="Line_260" data-name="Line 260" x2="2.392" transform="translate(7.056 3.515)" fill="#045eaa"/>
                        <path id="Path_496" data-name="Path 496" d="M9.448,4.007H7.056a.494.494,0,0,1,0-.985H9.448a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1473" data-name="Group 1473">
                        <line id="Line_261" data-name="Line 261" x2="2.392" transform="translate(7.056 6.743)" fill="#045eaa"/>
                        <path id="Path_497" data-name="Path 497" d="M9.448,7.236H7.056a.494.494,0,0,1,0-.985H9.448a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1474" data-name="Group 1474">
                        <line id="Line_262" data-name="Line 262" x2="2.392" transform="translate(7.056 10.137)" fill="#045eaa"/>
                        <path id="Path_498" data-name="Path 498" d="M9.448,10.629H7.056a.493.493,0,0,1,0-.984H9.448a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                    </g>
                    <g id="Group_1475" data-name="Group 1475">
                        <line id="Line_263" data-name="Line 263" x2="3.987" transform="translate(6.351 14.752)" fill="#045eaa"/>
                        <path id="Path_499" data-name="Path 499" d="M10.339,15.244H6.351a.493.493,0,0,1,0-.984h3.988a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                    </g>
                    <g id="Group_1476" data-name="Group 1476">
                        <line id="Line_264" data-name="Line 264" x2="3.987" transform="translate(6.351 19.409)" fill="#045eaa"/>
                        <path id="Path_500" data-name="Path 500" d="M10.339,19.9H6.351a.494.494,0,0,1,0-.985h3.988a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1477" data-name="Group 1477">
                        <line id="Line_265" data-name="Line 265" x2="3.987" transform="translate(6.351 23.776)" fill="#045eaa"/>
                        <path id="Path_501" data-name="Path 501" d="M10.339,24.268H6.351a.494.494,0,0,1,0-.985h3.988a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                </g>
            </g>
            <g id="Group_1480" data-name="Group 1480">
                <path id="Path_502" data-name="Path 502" d="M4.375,61.789V57.2L2.8,53.949V51.086H3.822v-1.2L2.714,47.7v-5.48A1.256,1.256,0,0,1,3.9,40.9H6.27v.618h4.4V61.848S4.419,61.74,4.375,61.789Z" fill="#045eaa"/>
                <path id="Path_503" data-name="Path 503" d="M10.673,62.34h-.007c-2.218-.038-5.82-.089-6.212-.066a.449.449,0,0,1-.25-.028.493.493,0,0,1-.29-.457V57.321L2.391,54.175a.516.516,0,0,1-.051-.226V51.086a.477.477,0,0,1,.461-.493H3.36v-.586l-1.052-2.07a.524.524,0,0,1-.056-.234V42.222A1.736,1.736,0,0,1,3.9,40.411H6.27a.477.477,0,0,1,.462.492v.126h3.941a.478.478,0,0,1,.462.492V61.848A.509.509,0,0,1,11,62.2.451.451,0,0,1,10.673,62.34ZM5.446,61.282c.868,0,2.316.024,4.766.066V42.013H6.27a.477.477,0,0,1-.461-.492V41.4H3.9a.78.78,0,0,0-.721.827v5.356l1.052,2.069a.515.515,0,0,1,.056.235v1.2a.477.477,0,0,1-.461.492H3.263V53.83l1.523,3.146a.516.516,0,0,1,.051.226v4.085C5,61.284,5.2,61.282,5.446,61.282Z" fill="#484646"/>
            </g>
            <g id="Group_1481" data-name="Group 1481">
                <path id="Path_504" data-name="Path 504" d="M7.888,63.508H9.159a1.581,1.581,0,0,0,1.487-1.66h-2.8l.036,1.66" fill="#c4c4c4"/>
                <path id="Path_505" data-name="Path 505" d="M7.88,64a.477.477,0,0,1-.461-.481L7.383,61.86a.51.51,0,0,1,.131-.356.451.451,0,0,1,.33-.148h2.8a.478.478,0,0,1,.462.492A2.063,2.063,0,0,1,9.177,64H7.88Zm.451-.985h.828a1.029,1.029,0,0,0,.93-.675H8.317Z" fill="#484646"/>
            </g>
            <g id="Group_1482" data-name="Group 1482">
                <path id="Path_506" data-name="Path 506" d="M7.272,61.838H5.886l-.011-1.121a.515.515,0,0,0-.482-.538l-1.978-.01,0,0-.011,0a.532.532,0,0,0-.5.559v.128a3.082,3.082,0,0,0,2.92,2.652H7.639A1.588,1.588,0,0,0,9.13,61.843Z" fill="#c4c4c4"/>
                <path id="Path_507" data-name="Path 507" d="M7.639,64H5.831a3.568,3.568,0,0,1-3.383-3.047l-.009-.224A1.013,1.013,0,0,1,3.4,59.676h.015l1.979.011a.989.989,0,0,1,.941,1.029l.006.63h.931l1.858.005a.477.477,0,0,1,.46.492A2.064,2.064,0,0,1,7.639,64ZM3.4,60.663l-.04.194a2.609,2.609,0,0,0,2.46,2.158H7.639a1.036,1.036,0,0,0,.936-.68l-2.689,0a.477.477,0,0,1-.462-.488l-.01-1.122-1.962-.059Z" fill="#484646"/>
            </g>
            <g id="Group_1483" data-name="Group 1483">
                <rect id="Rectangle_1233" data-name="Rectangle 1233" width="5.318" height="1.978" transform="translate(6.221 43.184)" fill="#045eaa"/>
                <path id="Path_508" data-name="Path 508" d="M11.538,45.654H6.221a.478.478,0,0,1-.462-.492V43.184a.479.479,0,0,1,.462-.493h5.317a.478.478,0,0,1,.462.493v1.978A.477.477,0,0,1,11.538,45.654Zm-4.856-.985h4.395v-.993H6.682Z" fill="#484646"/>
            </g>
            <g id="Group_1484" data-name="Group 1484">
                <rect id="Rectangle_1234" data-name="Rectangle 1234" width="5.318" height="2.967" transform="translate(6.148 38.899)" fill="#045eaa"/>
                <path id="Path_509" data-name="Path 509" d="M11.465,42.359H6.148a.479.479,0,0,1-.462-.493V38.9a.478.478,0,0,1,.462-.492h5.317a.477.477,0,0,1,.462.492v2.967A.478.478,0,0,1,11.465,42.359Zm-4.856-.985H11V39.391H6.609Z" fill="#484646"/>
            </g>
            <g id="Group_1485" data-name="Group 1485">
                <rect id="Rectangle_1235" data-name="Rectangle 1235" width="4.396" height="2.967" transform="translate(6.148 37.012)" fill="#045eaa"/>
                <path id="Path_510" data-name="Path 510" d="M10.543,40.471h-4.4a.478.478,0,0,1-.462-.492V37.012a.478.478,0,0,1,.462-.492h4.4a.478.478,0,0,1,.462.492v2.967A.478.478,0,0,1,10.543,40.471Zm-3.934-.984h3.473V37.505H6.609Z" fill="#484646"/>
            </g>
            <g id="Group_1486" data-name="Group 1486">
                <rect id="Rectangle_1236" data-name="Rectangle 1236" width="5.605" height="12.354" transform="translate(5.524 24.964)" fill="#045eaa"/>
                <path id="Path_511" data-name="Path 511" d="M11.128,37.811h-5.6a.478.478,0,0,1-.462-.493V24.964a.477.477,0,0,1,.462-.492h5.6a.478.478,0,0,1,.462.492V37.318A.479.479,0,0,1,11.128,37.811Zm-5.143-.985h4.682V25.456H5.985Z" fill="#484646"/>
            </g>
        </svg>
    )
}

export const getLSHSVGString = () =>
    `
        <svg id="Group_1487" data-name="Group 1487" xmlns="http://www.w3.org/2000/svg" width="120" height="640" viewBox="0 0 12 64">
            <g id="Group_1455" data-name="Group 1455">
                <path id="Path_480" data-name="Path 480" d="M.462,32.325l.02,1.167A6.309,6.309,0,0,1,1.54,36.94,6.341,6.341,0,0,1,.482,40.525v.952H1.906v2.4H5.4V40.434L2.678,32.357S.462,32.37.462,32.325Z" fill="#045eaa"/>
                <path id="Path_481" data-name="Path 481" d="M5.4,44.375h-3.5a.478.478,0,0,1-.462-.492V41.97H.482a.477.477,0,0,1-.461-.492v-.953a.508.508,0,0,1,.087-.287,5.822,5.822,0,0,0,.97-3.292,5.822,5.822,0,0,0-.97-3.167A.513.513,0,0,1,.021,33.5L0,32.334H0l.549-.469v-.02c.151.019,1.2.027,2.126.02h0a.466.466,0,0,1,.435.325l2.724,8.077a.522.522,0,0,1,.027.167v3.449A.478.478,0,0,1,5.4,44.375ZM2.367,43.39H4.941V40.519L2.355,32.851c-.663,0-1.113,0-1.422,0l.008.483A6.866,6.866,0,0,1,2,36.935,6.865,6.865,0,0,1,.944,40.68v.306h.962a.477.477,0,0,1,.461.492Z" fill="#484646"/>
            </g>
            <g id="Group_1479" data-name="Group 1479">
                <g id="Group_1456" data-name="Group 1456">
                    <path id="Path_482" data-name="Path 482" d="M9.568.492H7.149l-1.43,25.48a12.913,12.913,0,0,1,2.64,0,12.9,12.9,0,0,1,2.639,0Z" fill="#045eaa"/>
                    <path id="Path_483" data-name="Path 483" d="M5.719,26.464a.445.445,0,0,1-.333-.151.56.56,0,0,1-.122-.469L6.688.463A.475.475,0,0,1,7.148,0h2.42a.476.476,0,0,1,.461.463l1.425,25.382a.558.558,0,0,1-.121.467.442.442,0,0,1-.493.123.388.388,0,0,1-.081-.042c-.36-.028-1.454.011-2.373.07-.97-.059-2.069-.1-2.427-.07a.439.439,0,0,1-.081.041A.423.423,0,0,1,5.719,26.464Zm.67-1.071c.44,0,1.074.028,2,.088,1.023-.068,1.682-.1,2.118-.087L9.133.985H7.583L6.214,25.4Z" fill="#484646"/>
                </g>
                <g id="Group_1471" data-name="Group 1471">
                    <g id="Group_1463" data-name="Group 1463">
                        <g id="Group_1457" data-name="Group 1457">
                            <line id="Line_248" data-name="Line 248" x2="0.427" transform="translate(7.094 1.88)" fill="#045eaa"/>
                            <path id="Path_484" data-name="Path 484" d="M7.521,2.372H7.093a.477.477,0,0,1-.461-.492.478.478,0,0,1,.461-.493h.428a.478.478,0,0,1,.461.493A.477.477,0,0,1,7.521,2.372Z" fill="#484646"/>
                        </g>
                        <g id="Group_1458" data-name="Group 1458">
                            <line id="Line_249" data-name="Line 249" x2="0.427" transform="translate(7.094 5.108)" fill="#045eaa"/>
                            <path id="Path_485" data-name="Path 485" d="M7.521,5.6H7.093a.493.493,0,0,1,0-.984h.428a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1459" data-name="Group 1459">
                            <line id="Line_250" data-name="Line 250" x2="0.427" transform="translate(7.094 8.502)" fill="#045eaa"/>
                            <path id="Path_486" data-name="Path 486" d="M7.521,8.994H7.093a.493.493,0,0,1,0-.984h.428a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1460" data-name="Group 1460">
                            <line id="Line_251" data-name="Line 251" x2="0.946" transform="translate(6.574 12.31)" fill="#045eaa"/>
                            <path id="Path_487" data-name="Path 487" d="M7.521,12.8H6.574a.493.493,0,0,1,0-.984h.947a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1461" data-name="Group 1461">
                            <line id="Line_252" data-name="Line 252" x2="0.946" transform="translate(6.574 17.049)" fill="#045eaa"/>
                            <path id="Path_488" data-name="Path 488" d="M7.521,17.542H6.574a.494.494,0,0,1,0-.985h.947a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                        </g>
                        <g id="Group_1462" data-name="Group 1462">
                            <line id="Line_253" data-name="Line 253" x2="1.476" transform="translate(6.045 21.623)" fill="#045eaa"/>
                            <path id="Path_489" data-name="Path 489" d="M7.521,22.115H6.045a.493.493,0,0,1,0-.984H7.521a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                    </g>
                    <g id="Group_1470" data-name="Group 1470">
                        <g id="Group_1464" data-name="Group 1464">
                            <line id="Line_254" data-name="Line 254" x1="0.427" transform="translate(9.169 1.88)" fill="#045eaa"/>
                            <path id="Path_490" data-name="Path 490" d="M9.6,2.372H9.169a.477.477,0,0,1-.462-.492.478.478,0,0,1,.462-.493H9.6a.478.478,0,0,1,.461.493A.477.477,0,0,1,9.6,2.372Z" fill="#484646"/>
                        </g>
                        <g id="Group_1465" data-name="Group 1465">
                            <line id="Line_255" data-name="Line 255" x1="0.427" transform="translate(9.169 5.108)" fill="#045eaa"/>
                            <path id="Path_491" data-name="Path 491" d="M9.6,5.6H9.169a.493.493,0,0,1,0-.984H9.6a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1466" data-name="Group 1466">
                            <line id="Line_256" data-name="Line 256" x1="0.427" transform="translate(9.169 8.502)" fill="#045eaa"/>
                            <path id="Path_492" data-name="Path 492" d="M9.6,8.994H9.169a.493.493,0,0,1,0-.984H9.6a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1467" data-name="Group 1467">
                            <line id="Line_257" data-name="Line 257" x1="0.946" transform="translate(9.169 12.31)" fill="#045eaa"/>
                            <path id="Path_493" data-name="Path 493" d="M10.115,12.8H9.169a.493.493,0,0,1,0-.984h.946a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                        <g id="Group_1468" data-name="Group 1468">
                            <line id="Line_258" data-name="Line 258" x1="0.946" transform="translate(9.169 17.049)" fill="#045eaa"/>
                            <path id="Path_494" data-name="Path 494" d="M10.115,17.542H9.169a.494.494,0,0,1,0-.985h.946a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                        </g>
                        <g id="Group_1469" data-name="Group 1469">
                            <line id="Line_259" data-name="Line 259" x1="1.476" transform="translate(9.169 21.623)" fill="#045eaa"/>
                            <path id="Path_495" data-name="Path 495" d="M10.644,22.115H9.169a.493.493,0,0,1,0-.984h1.475a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                        </g>
                    </g>
                </g>
                <g id="Group_1478" data-name="Group 1478">
                    <g id="Group_1472" data-name="Group 1472">
                        <line id="Line_260" data-name="Line 260" x2="2.392" transform="translate(7.056 3.515)" fill="#045eaa"/>
                        <path id="Path_496" data-name="Path 496" d="M9.448,4.007H7.056a.494.494,0,0,1,0-.985H9.448a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1473" data-name="Group 1473">
                        <line id="Line_261" data-name="Line 261" x2="2.392" transform="translate(7.056 6.743)" fill="#045eaa"/>
                        <path id="Path_497" data-name="Path 497" d="M9.448,7.236H7.056a.494.494,0,0,1,0-.985H9.448a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1474" data-name="Group 1474">
                        <line id="Line_262" data-name="Line 262" x2="2.392" transform="translate(7.056 10.137)" fill="#045eaa"/>
                        <path id="Path_498" data-name="Path 498" d="M9.448,10.629H7.056a.493.493,0,0,1,0-.984H9.448a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                    </g>
                    <g id="Group_1475" data-name="Group 1475">
                        <line id="Line_263" data-name="Line 263" x2="3.987" transform="translate(6.351 14.752)" fill="#045eaa"/>
                        <path id="Path_499" data-name="Path 499" d="M10.339,15.244H6.351a.493.493,0,0,1,0-.984h3.988a.493.493,0,0,1,0,.984Z" fill="#484646"/>
                    </g>
                    <g id="Group_1476" data-name="Group 1476">
                        <line id="Line_264" data-name="Line 264" x2="3.987" transform="translate(6.351 19.409)" fill="#045eaa"/>
                        <path id="Path_500" data-name="Path 500" d="M10.339,19.9H6.351a.494.494,0,0,1,0-.985h3.988a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                    <g id="Group_1477" data-name="Group 1477">
                        <line id="Line_265" data-name="Line 265" x2="3.987" transform="translate(6.351 23.776)" fill="#045eaa"/>
                        <path id="Path_501" data-name="Path 501" d="M10.339,24.268H6.351a.494.494,0,0,1,0-.985h3.988a.494.494,0,0,1,0,.985Z" fill="#484646"/>
                    </g>
                </g>
            </g>
            <g id="Group_1480" data-name="Group 1480">
                <path id="Path_502" data-name="Path 502" d="M4.375,61.789V57.2L2.8,53.949V51.086H3.822v-1.2L2.714,47.7v-5.48A1.256,1.256,0,0,1,3.9,40.9H6.27v.618h4.4V61.848S4.419,61.74,4.375,61.789Z" fill="#045eaa"/>
                <path id="Path_503" data-name="Path 503" d="M10.673,62.34h-.007c-2.218-.038-5.82-.089-6.212-.066a.449.449,0,0,1-.25-.028.493.493,0,0,1-.29-.457V57.321L2.391,54.175a.516.516,0,0,1-.051-.226V51.086a.477.477,0,0,1,.461-.493H3.36v-.586l-1.052-2.07a.524.524,0,0,1-.056-.234V42.222A1.736,1.736,0,0,1,3.9,40.411H6.27a.477.477,0,0,1,.462.492v.126h3.941a.478.478,0,0,1,.462.492V61.848A.509.509,0,0,1,11,62.2.451.451,0,0,1,10.673,62.34ZM5.446,61.282c.868,0,2.316.024,4.766.066V42.013H6.27a.477.477,0,0,1-.461-.492V41.4H3.9a.78.78,0,0,0-.721.827v5.356l1.052,2.069a.515.515,0,0,1,.056.235v1.2a.477.477,0,0,1-.461.492H3.263V53.83l1.523,3.146a.516.516,0,0,1,.051.226v4.085C5,61.284,5.2,61.282,5.446,61.282Z" fill="#484646"/>
            </g>
            <g id="Group_1481" data-name="Group 1481">
                <path id="Path_504" data-name="Path 504" d="M7.888,63.508H9.159a1.581,1.581,0,0,0,1.487-1.66h-2.8l.036,1.66" fill="#c4c4c4"/>
                <path id="Path_505" data-name="Path 505" d="M7.88,64a.477.477,0,0,1-.461-.481L7.383,61.86a.51.51,0,0,1,.131-.356.451.451,0,0,1,.33-.148h2.8a.478.478,0,0,1,.462.492A2.063,2.063,0,0,1,9.177,64H7.88Zm.451-.985h.828a1.029,1.029,0,0,0,.93-.675H8.317Z" fill="#484646"/>
            </g>
            <g id="Group_1482" data-name="Group 1482">
                <path id="Path_506" data-name="Path 506" d="M7.272,61.838H5.886l-.011-1.121a.515.515,0,0,0-.482-.538l-1.978-.01,0,0-.011,0a.532.532,0,0,0-.5.559v.128a3.082,3.082,0,0,0,2.92,2.652H7.639A1.588,1.588,0,0,0,9.13,61.843Z" fill="#c4c4c4"/>
                <path id="Path_507" data-name="Path 507" d="M7.639,64H5.831a3.568,3.568,0,0,1-3.383-3.047l-.009-.224A1.013,1.013,0,0,1,3.4,59.676h.015l1.979.011a.989.989,0,0,1,.941,1.029l.006.63h.931l1.858.005a.477.477,0,0,1,.46.492A2.064,2.064,0,0,1,7.639,64ZM3.4,60.663l-.04.194a2.609,2.609,0,0,0,2.46,2.158H7.639a1.036,1.036,0,0,0,.936-.68l-2.689,0a.477.477,0,0,1-.462-.488l-.01-1.122-1.962-.059Z" fill="#484646"/>
            </g>
            <g id="Group_1483" data-name="Group 1483">
                <rect id="Rectangle_1233" data-name="Rectangle 1233" width="5.318" height="1.978" transform="translate(6.221 43.184)" fill="#045eaa"/>
                <path id="Path_508" data-name="Path 508" d="M11.538,45.654H6.221a.478.478,0,0,1-.462-.492V43.184a.479.479,0,0,1,.462-.493h5.317a.478.478,0,0,1,.462.493v1.978A.477.477,0,0,1,11.538,45.654Zm-4.856-.985h4.395v-.993H6.682Z" fill="#484646"/>
            </g>
            <g id="Group_1484" data-name="Group 1484">
                <rect id="Rectangle_1234" data-name="Rectangle 1234" width="5.318" height="2.967" transform="translate(6.148 38.899)" fill="#045eaa"/>
                <path id="Path_509" data-name="Path 509" d="M11.465,42.359H6.148a.479.479,0,0,1-.462-.493V38.9a.478.478,0,0,1,.462-.492h5.317a.477.477,0,0,1,.462.492v2.967A.478.478,0,0,1,11.465,42.359Zm-4.856-.985H11V39.391H6.609Z" fill="#484646"/>
            </g>
            <g id="Group_1485" data-name="Group 1485">
                <rect id="Rectangle_1235" data-name="Rectangle 1235" width="4.396" height="2.967" transform="translate(6.148 37.012)" fill="#045eaa"/>
                <path id="Path_510" data-name="Path 510" d="M10.543,40.471h-4.4a.478.478,0,0,1-.462-.492V37.012a.478.478,0,0,1,.462-.492h4.4a.478.478,0,0,1,.462.492v2.967A.478.478,0,0,1,10.543,40.471Zm-3.934-.984h3.473V37.505H6.609Z" fill="#484646"/>
            </g>
            <g id="Group_1486" data-name="Group 1486">
                <rect id="Rectangle_1236" data-name="Rectangle 1236" width="5.605" height="12.354" transform="translate(5.524 24.964)" fill="#045eaa"/>
                <path id="Path_511" data-name="Path 511" d="M11.128,37.811h-5.6a.478.478,0,0,1-.462-.493V24.964a.477.477,0,0,1,.462-.492h5.6a.478.478,0,0,1,.462.492V37.318A.479.479,0,0,1,11.128,37.811Zm-5.143-.985h4.682V25.456H5.985Z" fill="#484646"/>
            </g>
        </svg>
    `