import React from 'react';
import { useExportCSVAction } from './hooks';
import { useSelector } from 'react-redux';
import { isCTCMUserSelector } from '../../../../store/selectors';
import { TextButton } from '@orbit/button';
import { TableChart } from '@mui/icons-material';

export const ExportCSV: React.FC = () => {
    const { csvExport } = useExportCSVAction();
    const isCTCMUser = useSelector(isCTCMUserSelector);
    return (
        isCTCMUser ? 
            <TextButton {...csvExport.buttonProps} startIcon={<TableChart />}>
                {csvExport.label}
            </TextButton>
        : null
    );
}