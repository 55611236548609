import { IConnectorAssignmentMap, IConnectorAssignmentSet, IConnectorAssignmentState, IConnectorMap } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Sides } from "../../../assembly/breakout/types";
import { AssignedIndexCodes, connectorModelToConnectorType, IFiberMap, IFiberMapData, IFiberMapIndex, IPinAssignmentMap, IPinMap } from "../../fiber-map/types";
import { getConnectorType, IConnectorType } from "../../../assembly/connector/types";
import { CUSTOM_MAP_KEY } from "../../store/types";
import { IFiberMapInfo, IFiberMapRowProps } from "../../../offscreen/fiber-map-table/types";
import { FiberColors } from "../../../assembly/palette/types";
import { HighlightStatus, HighlightStatuses, IHighlightedConnector } from "../../../../pixi/cable/breakout/connector-furcation/highlight/types";
import { AssignmentPattern } from "../types";

export const setConnectorAssignmentsAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignments = action.payload;
    state.assignments = assignments;

    if (assignments.length > 0) {
        const maps = assignments.flatMap(a => [...a.connectors.sideAMapping, ...a.connectors.sideBMapping]);
        addOrUpdateHighlights(state, maps, HighlightStatuses.Assigned);
    } else {
        state.highlights = [];
    }
}

const setAssignedSelection = (state: IConnectorAssignmentState, assignment: IConnectorAssignmentMap) => {
    if (state.assignedSelection.sideAMapping.length > 0 && state.assignedSelection.sideAMapping.every(m => assignment.connectors.sideAMapping.map(m => m.id).includes(m.id))) {
        resetAssignmentSelectionAction(state);
    } else {
        const assignedSelected = [...state.assignedSelection.sideAMapping, ...state.assignedSelection.sideBMapping];
        addOrUpdateHighlights(state, assignedSelected, HighlightStatuses.Assigned);

        removeHighlightsByStatus(state, HighlightStatuses.Selected);
        state.selection = {
            sideAMapping: [],
            sideBMapping: []
        };

        const maps = [...assignment.connectors.sideAMapping, ...assignment.connectors.sideBMapping];
        addOrUpdateHighlights(state, maps, HighlightStatuses.AssignedSelected);
        state.assignedSelection.sideAMapping = assignment.connectors.sideAMapping;
        state.assignedSelection.sideBMapping = assignment.connectors.sideBMapping;
    }
}

const addOrUpdateHighlight = (state: IConnectorAssignmentState, map: IConnectorMap, status: HighlightStatus) => {
    const highlight: IHighlightedConnector = {
        side: map.side,
        breakoutPosition: map.breakoutPosition,
        connectorPosition: map.index,
        status
    };
    const highlightIndex = state.highlights.findIndex(c => c.side === map.side && c.breakoutPosition === map.breakoutPosition && c.connectorPosition === map.index);
    if (highlightIndex < 0) {
        state.highlights.push(highlight);
    } else {
        state.highlights[highlightIndex] = highlight;
    }
}

const addOrUpdateHighlights = (state: IConnectorAssignmentState, maps: IConnectorMap[], status: HighlightStatus) => {
    for (const map of maps) {
        addOrUpdateHighlight(state, map, status);
    }
}

export const addOrUpdateHighlightsAction = (state: IConnectorAssignmentState, action: PayloadAction<{ maps: IConnectorMap[], status: HighlightStatus }>) => {
    const { maps, status } = action.payload;
    addOrUpdateHighlights(state, maps, status);
}

const removeHighlight = (state: IConnectorAssignmentState, map: IConnectorMap) => {
    const highlightIndex = state.highlights.findIndex(c => c.side === map.side && c.breakoutPosition === map.breakoutPosition && c.connectorPosition === map.index);
    if (highlightIndex > -1) {
        state.highlights.splice(highlightIndex, 1);
    }
}

const removeHighlights = (state: IConnectorAssignmentState, maps: IConnectorMap[]) => {
    for (const map of maps) {
        removeHighlight(state, map);
    }
}

const removeHighlightsByStatus = (state: IConnectorAssignmentState, status: HighlightStatus) => {
    state.highlights = state.highlights.filter(h => h.status !== status);
}

const handleSelection = (state: IConnectorAssignmentState, map: IConnectorMap) => {
    if (map.side === Sides.SideA) {
        const assignmentMap = state.assignments.find(m => m.connectors.sideAMapping.some(a => a.breakoutPosition === map.breakoutPosition && a.index === map.index))
        if (assignmentMap) {
            if (!state.automaticMode) {
                setAssignedSelection(state, assignmentMap);
            }
        } else {
            const selectedMap = state.selection.sideAMapping.find(a => a.breakoutPosition === map.breakoutPosition && a.index === map.index);
            if (selectedMap) {
                if (!state.automaticMode) {
                    state.selection.sideAMapping = state.selection.sideAMapping.filter(a => a !== selectedMap);
                    removeHighlight(state, map);
                }
            } else {
                const orderIndex = state.selection.sideAMapping.length;
                state.selection.sideAMapping.push({ ...map, orderIndex });
                addOrUpdateHighlight(state, map, HighlightStatuses.Selected);
            }

            if (state.assignedSelection) {
                resetAssignmentSelectionAction(state);
            }
        }
    } else {
        const assignmentMap = state.assignments.find(m => m.connectors.sideBMapping.some(b => b.breakoutPosition === map.breakoutPosition && b.index === map.index));
        if (assignmentMap) {
            if (!state.automaticMode) {
                setAssignedSelection(state, assignmentMap);
            }
        } else {
            const selectedMap = state.selection.sideBMapping.find(b => b.breakoutPosition === map.breakoutPosition && b.index === map.index);
            if (selectedMap) {
                if (!state.automaticMode) {
                    state.selection.sideBMapping = state.selection.sideBMapping.filter(b => b !== selectedMap);
                    removeHighlight(state, map);
                }
            } else {
                const orderIndex = state.selection.sideBMapping.length;
                state.selection.sideBMapping.push({ ...map, orderIndex });
                addOrUpdateHighlight(state, map, HighlightStatuses.Selected);
            }

            if (state.assignedSelection) {
                resetAssignmentSelectionAction(state);
            }
        }
    }
}

export const handleSelectionAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorMap>) => {
    const map = action.payload;
    handleSelection(state, map);
}

export const handleMultiSelectionAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorMap[]>) => {
    const maps = action.payload;
    for (const map of maps) {
        handleSelection(state, map);
    }
}

export const addAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignment = action.payload;

    const maps = [...assignment.connectors.sideAMapping, ...assignment.connectors.sideBMapping];
    addOrUpdateHighlights(state, maps, HighlightStatuses.Assigned);

    state.assignments.push(action.payload);
}

export const addAssignmentsAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignments = action.payload;
    
    const maps = assignments.flatMap(a => [...a.connectors.sideAMapping, ...a.connectors.sideBMapping]);
    addOrUpdateHighlights(state, maps, HighlightStatuses.Assigned);

    state.assignments.push(...assignments);
}

export const updateAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignment = action.payload;
    if (assignment.id) {
        const index = state.assignments.findIndex(a => a.id === assignment.id);
        if (index < 0) {
            state.assignments.push(assignment);
        } else {
            state.assignments[index] = { ...assignment };
        }
    }
}

export const deleteAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<number>) => {
    const assignmentId = action.payload;

    const assignment = state.assignments.find(a => a.id === assignmentId);
    if (assignment) {
        const maps = [...assignment.connectors.sideAMapping, ...assignment.connectors.sideBMapping];
        removeHighlights(state, maps);
    }

    state.assignments = state.assignments.filter(a => a.id !== assignmentId);
}

export const resetSelectionAction = (state: IConnectorAssignmentState) => {
    removeHighlightsByStatus(state, HighlightStatuses.Selected);
    state.selection = {
        sideAMapping: [],
        sideBMapping: []
    };
    state.automaticMode = false;
    state.patternType = "Standard";
}

export const resetAssignmentSelectionAction = (state: IConnectorAssignmentState) => {
    const assignedSelected = [...state.assignedSelection.sideAMapping, ...state.assignedSelection.sideBMapping];
    addOrUpdateHighlights(state, assignedSelected, HighlightStatuses.Assigned);
    
    removeHighlightsByStatus(state, HighlightStatuses.AssignedSelected);
    state.assignedSelection = {
        sideAMapping: [],
        sideBMapping: []
    };
}

export const resetAllAction = (state: IConnectorAssignmentState) => {
    resetSelectionAction(state);
    resetAssignmentSelectionAction(state);
    removeHighlightsByStatus(state, HighlightStatuses.Assigned);
    state.assignments = [];
}

export function generateConnectorAssignment(connectorSet: IConnectorAssignmentSet, polarityKey: number, predefinedFiberMap?: IFiberMapData): IConnectorAssignmentMap {
    if (predefinedFiberMap) {
        return generateConnectorAssignmentWithPredefinedFiberMap(connectorSet, polarityKey, predefinedFiberMap);
    } else {
        return generateConnectorAssignmentWithEmptyFiberMap(connectorSet, polarityKey);
    }
}

function generateConnectorAssignmentWithPredefinedFiberMap (connectorSet: IConnectorAssignmentSet, polarityKey: number, predefinedFiberMapData: IFiberMapData): IConnectorAssignmentMap {
    const predefinedFiberMap = generateFiberMapUI(connectorSet, predefinedFiberMapData);
    const customFiberMapData = generateCustomFiberMapData(connectorSet, predefinedFiberMap);
    const connectorAssignment = generateConnectorSetWithFiberMapData(connectorSet, customFiberMapData);

    customFiberMapData.sourceIndices.forEach((s, i) => {
        s.index = i;
        s.assignedIndex = s.assignedIndex > AssignedIndexCodes.Unassigned ? i : s.assignedIndex;
    });
    customFiberMapData.destinationIndices.forEach((d, i) => { d.index = i; });

    return {
        polarityKey,
        fiberMapData: { ...customFiberMapData, key: polarityKey },
        connectors: connectorAssignment
    }
}

function generateConnectorSetWithFiberMapData(connectorSet: IConnectorAssignmentSet, fiberMapData: IFiberMapData): IConnectorAssignmentSet {
    const sideAMapping: IConnectorMap[] = [];
    const sideBMapping: IConnectorMap[] = [];

    let startIndex = 0;
    for (const sideAConnectorMap of connectorSet.sideAMapping) {
        const fiberCount = getAdjustedFiberCount(sideAConnectorMap.fiberCount);
        const indices = fiberMapData.sourceIndices.slice(startIndex, startIndex + fiberCount);
        const blockedFiberCount = indices.filter(i => i.assignedIndex === AssignedIndexCodes.Blocked).length;
        const mtp8Correction = sideAConnectorMap.fiberCount === 8 ? 4 : 0;
        const unassignedFibers = indices.filter(i => i.assignedIndex === AssignedIndexCodes.Unassigned).length + blockedFiberCount - mtp8Correction;
        
        sideAMapping.push({
            ...sideAConnectorMap,
            unassignedFibers,
            blockedFiberCount
        });

        startIndex += fiberCount;
    }

    startIndex = 0;
    for (const sideBConnectorMap of connectorSet.sideBMapping) {
        const fiberCount = getAdjustedFiberCount(sideBConnectorMap.fiberCount);
        const indices = fiberMapData.destinationIndices.slice(startIndex, startIndex + fiberCount);
        const blockedFiberCount = indices.filter(i => i.assignedIndex === AssignedIndexCodes.Blocked).length;
        const mtp8Correction = sideBConnectorMap.fiberCount === 8 ? 4 : 0;
        const unassignedFibers = indices.filter(i => i.assignedIndex === AssignedIndexCodes.Unassigned).length + blockedFiberCount - mtp8Correction;

        const assignedFibers = indices.filter(i => i.assignedIndex > AssignedIndexCodes.Unassigned);
        for (const assignedFiber of assignedFibers) {
            const assignedSideAFiberIndex = fiberMapData.sourceIndices.findIndex(i => i.assignedIndex === assignedFiber.assignedIndex);
            if (assignedSideAFiberIndex > AssignedIndexCodes.Unassigned) {
                assignedFiber.assignedIndex = assignedSideAFiberIndex;
            }
        }

        sideBMapping.push({
            ...sideBConnectorMap,
            unassignedFibers,
            blockedFiberCount
        });

        startIndex += fiberCount;
    }

    return { sideAMapping, sideBMapping };
}

function generateConnectorAssignmentWithEmptyFiberMap(connectorSet: IConnectorAssignmentSet, polarityKey: number): IConnectorAssignmentMap {
    const emptyFiberMap = generateEmptyFiberMappingData(connectorSet);
    return {
        polarityKey,
        fiberMapData: emptyFiberMap,
        connectors: connectorSet
    }
}

export function generateFiberMapUI(connectorSet: IConnectorAssignmentSet, fiberMap: IFiberMapData): IFiberMap {
    const { sideAMapping, sideBMapping } = connectorSet;

    const sideABreakoutMapping = getBreakoutMappingFromSide(sideAMapping);
    const sideBBreakoutMapping = getBreakoutMappingFromSide(sideBMapping);
    const sideAFibers = fiberMapIndicesToMatrix(fiberMap.sourceIndices);
    const sideBFibers = fiberMapIndicesToMatrix(fiberMap.destinationIndices);

    const pinAssignments: IPinAssignmentMap[] = [];
    const unused: IPinMap[] = [];
    for (let sideABreakoutIndex = 0; sideABreakoutIndex < sideABreakoutMapping.length; sideABreakoutIndex++) {
        const sideABreakoutMap = sideABreakoutMapping[sideABreakoutIndex];
        for (let sideBBreakoutIndex = 0; sideBBreakoutIndex < sideBBreakoutMapping.length; sideBBreakoutIndex++) {
            const sideBBreakoutMap = sideBBreakoutMapping[sideBBreakoutIndex];
            for (let sideAConnectorIndex = 0; sideAConnectorIndex < sideABreakoutMap.length; sideAConnectorIndex++) {
                for (let sideBConnectorIndex = 0; sideBConnectorIndex < sideBBreakoutMap.length; sideBConnectorIndex++) {
                    const sideAConnectorMap = sideABreakoutMap[sideAConnectorIndex];
                    const sideAIndices = sideAFibers[sideAConnectorMap.orderIndex];
                    const sideBConnectorMap = sideBBreakoutMap[sideBConnectorIndex];
                    const sideBIndices = sideBFibers[sideBConnectorMap.orderIndex];
                    if (sideAIndices && sideBIndices) {
                        for (let sideAPinIndex = 0; sideAPinIndex < sideAIndices.length; sideAPinIndex++) {
                            const sideAFiberIndex = sideAIndices[sideAPinIndex];
                            const sideAPin: IPinMap = {
                                pinIndex: sideAPinIndex + 1,
                                side: sideAConnectorMap.side,
                                breakout: {
                                    index: sideABreakoutIndex
                                },
                                connector: {
                                    index: sideAConnectorIndex
                                }
                            };
                            const sideBPinIndex = sideBIndices.findIndex(b => b.assignedIndex === sideAFiberIndex.assignedIndex)
                            if (sideAFiberIndex.assignedIndex > AssignedIndexCodes.Unassigned && sideBPinIndex > -1) {
                                const sideBPin: IPinMap = {
                                    pinIndex: sideBPinIndex + 1,
                                    side: sideBConnectorMap.side,
                                    breakout: {
                                        index: sideBBreakoutIndex
                                    },
                                    connector: {
                                        index: sideBConnectorIndex
                                    }
                                };
                                pinAssignments.push({ sideAPin, sideBPin });
                            }
                        }
                    }

                    if (sideAIndices) {
                        for (let sideAPinIndex = 0; sideAPinIndex < sideAIndices.length; sideAPinIndex++) {
                            const sideAFiberIndex = sideAIndices[sideAPinIndex];
                            if (sideAFiberIndex.assignedIndex === AssignedIndexCodes.Blocked) {
                                unused.push({
                                    pinIndex: sideAPinIndex + 1,
                                    side: Sides.SideA,
                                    breakout: {
                                        index: sideABreakoutIndex
                                    },
                                    connector: {
                                        index: sideAConnectorIndex
                                    }
                                })
                            }
                        }
                    }

                    if (sideBIndices) {
                        for (let sideBPinIndex = 0; sideBPinIndex < sideBIndices.length; sideBPinIndex++) {
                            const sideBFiberIndex = sideBIndices[sideBPinIndex];
                            if (sideBFiberIndex.assignedIndex === AssignedIndexCodes.Blocked) {
                                unused.push({
                                    pinIndex: sideBPinIndex + 1,
                                    side: Sides.SideB,
                                    breakout: {
                                        index: sideBBreakoutIndex
                                    },
                                    connector: {
                                        index: sideBConnectorIndex
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    return { name: fiberMap.name, pinAssignments, unused };
}

export function generateCustomFiberMapData(connectorSet: IConnectorAssignmentSet, fiberMap: IFiberMap) {
    const { sideAMapping, sideBMapping } = connectorSet;
    const { name, pinAssignments, unused } = fiberMap;

    const sideABreakoutMapping = getBreakoutMappingFromSide(sideAMapping);
    const sideBBreakoutMapping = getBreakoutMappingFromSide(sideBMapping);
    const customFiberMap = generateEmptyFiberMappingData(connectorSet, name);
    for (const pinAssignment of pinAssignments) {
        const { sideAPin, sideBPin } = pinAssignment;
        const sideAConnector = sideABreakoutMapping[sideAPin.breakout.index][sideAPin.connector.index];
        const sideBConnector = sideBBreakoutMapping[sideBPin.breakout.index][sideBPin.connector.index];
        if (sideAConnector && sideBConnector) {
            const sideAPinIndex = getFiberMapIndex(sideAMapping, sideAConnector, sideAPin.pinIndex);
            const sideAFiberIndex = customFiberMap.sourceIndices[sideAPinIndex];
            customFiberMap.sourceIndices[sideAPinIndex] = { ...sideAFiberIndex, assignedIndex: sideAPinIndex };

            const sideBPinIndex = getFiberMapIndex(sideBMapping, sideBConnector, sideBPin.pinIndex);
            const sideBFiberIndex = customFiberMap.destinationIndices[sideBPinIndex];
            customFiberMap.destinationIndices[sideBPinIndex] = { ...sideBFiberIndex, assignedIndex: sideAPinIndex };
        }
    }

    for (let unusedPin of unused) {
        if (unusedPin.side === Sides.SideA) {
            const sideAConnector = sideABreakoutMapping[unusedPin.breakout.index][unusedPin.connector.index];
            if (sideAConnector) {
                const sideAPinIndex = getFiberMapIndex(sideAMapping, sideAConnector, unusedPin.pinIndex)
                customFiberMap.sourceIndices[sideAPinIndex].assignedIndex = AssignedIndexCodes.Blocked;
            }
        } else {
            const sideBConnector = sideBBreakoutMapping[unusedPin.breakout.index][unusedPin.connector.index];
            if (sideBConnector) {
                const sideBPinIndex = getFiberMapIndex(sideBMapping, sideBConnector, unusedPin.pinIndex);
                customFiberMap.destinationIndices[sideBPinIndex].assignedIndex = AssignedIndexCodes.Blocked;
            }
        }
    }

    return customFiberMap;
}

export function generateEmptyFiberMappingData(connectorSet: IConnectorAssignmentSet, name?: string): IFiberMapData {
    const { sideAMapping, sideBMapping } = connectorSet;

    let fiberStart = 0;
    const sideAIndices = sideAMapping.flatMap(a => {
        const fiberIndices: IFiberMapIndex[] = [];
        const fiberCount = getAdjustedFiberCount(a.fiberCount);
        for (let i = 0; i < fiberCount; i++) {
            const fiberIndex: IFiberMapIndex = {
                index: fiberStart + i,
                assignedIndex: AssignedIndexCodes.Unassigned,
                connectorType: a.connectorType,
                side: a.side,
                breakoutPosition: a.breakoutPosition
            };
            fiberIndices.push(fiberIndex);
        }
        fiberStart += fiberCount;
        return fiberIndices;
    });

    fiberStart = 0;
    const sideBIndices = sideBMapping.flatMap(b => {
        const fiberIndices: IFiberMapIndex[] = [];
        const fiberCount = getAdjustedFiberCount(b.fiberCount);
        for (let i = 0; i < fiberCount; i++) {
            const fiberIndex: IFiberMapIndex = {
                index: fiberStart + i,
                assignedIndex: AssignedIndexCodes.Unassigned,
                connectorType: b.connectorType,
                side: b.side,
                breakoutPosition: b.breakoutPosition
            };
            fiberIndices.push(fiberIndex);
        }
        fiberStart += fiberCount;
        return fiberIndices;
    });

    return {
        key: CUSTOM_MAP_KEY,
        name: name || "Custom",
        sourceIndices: sideAIndices,
        destinationIndices: sideBIndices,
    };
}

function getBreakoutMappingFromSide(sideMapping: IConnectorMap[]) {
    const connectorsByBreakoutPosition = [...sideMapping].sort((a, b) => {
        if (a.breakoutPosition === b.breakoutPosition) {
            return a.index > b.index ? 1 : -1;
        } else {
            return a.breakoutPosition > b.breakoutPosition ? 1 : -1;
        }
    });
    const breakouts: { [position: number]: IConnectorMap[] } = {};
    for (var connector of connectorsByBreakoutPosition) {
        const existingBreakout = breakouts[connector.breakoutPosition];
        if (existingBreakout) {
            existingBreakout.push(connector);
        } else {
            breakouts[connector.breakoutPosition] = [ connector ];
        }
    }

    const breakoutPositions = Object.keys(breakouts).map(p => Number(p));
    return breakoutPositions.map(p => breakouts[p]);
}

function getAdjustedFiberCount(fiberCount: number) {
    return fiberCount === 8 ? 12 : fiberCount;
}

function fiberMapIndicesToMatrix(fiberMapIndices: IFiberMapIndex[]): IFiberMapIndex[][] {
    let matrix: IFiberMapIndex[][] = [];
    const connectorTypes = getFiberMapDataConnectorTypes(fiberMapIndices)
    let startIndex = 0;
    for (let i = 0; i < connectorTypes.length; i++) {
        const connectorType = connectorTypes[i];
        const fiberCount = getAdjustedFiberCount(connectorType.fiberCount);
        const indices = [...fiberMapIndices].slice(startIndex, startIndex + fiberCount);
        matrix.push(indices);
        startIndex += fiberCount;
    }
    return matrix;
}

export function getFiberMapDataConnectorTypes(fiberMapIndices: IFiberMapIndex[]) {
    const uniqueConnectorTypes: { [connectorType: string]: IConnectorType[]; } = {};
    let i = 0;
    while (i < fiberMapIndices.length) {
        const index = fiberMapIndices[i];
        const connectorType = index.connectorType ? getConnectorType(index.connectorType) : connectorModelToConnectorType(index.connector);
        if (connectorType && connectorType.type) {
            if (uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type].push({ ...connectorType })
            } else if (!uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type] = [{ ...connectorType }];
            }
            const fiberCount = getAdjustedFiberCount(connectorType.fiberCount)
            i += fiberCount;
        } else {
            i++;
        }
    }
    const connectorTypes = Object.values(uniqueConnectorTypes).flat();
    return connectorTypes;
}

function getFiberMapIndex(sideMap: IConnectorMap[], connectorMap: IConnectorMap, pinIndex: number) {
    const startIndex = getFiberStartIndex(sideMap, connectorMap);
    return startIndex + pinIndex - 1;
}

function getFiberStartIndex(sideMap: IConnectorMap[], connectorMap: IConnectorMap) {
    const previousConnectors = sideMap.filter(m => m.orderIndex < connectorMap.orderIndex);
    return previousConnectors.length ? previousConnectors.map(c => getAdjustedFiberCount(c.fiberCount)).reduce((a, b) => a + b, 0) : 0
}

export function updateConnectorAssignmentWithFiberMap(connectorAssignment: IConnectorAssignmentMap, fiberMap: IFiberMap): IConnectorAssignmentMap {
    const customFiberMapData = generateCustomFiberMapData(connectorAssignment.connectors, fiberMap);
    const fiberMapData: IFiberMapData = { ...customFiberMapData, id: connectorAssignment.fiberMapData.id };
    const connectorSet = generateConnectorSetWithFiberMapData(connectorAssignment.connectors, fiberMapData);
    return { ...connectorAssignment, connectors: connectorSet, fiberMapData };
}

export function generateFiberMapReportRows(connectorSet: IConnectorAssignmentSet, fiberMapData: IFiberMapData): IFiberMapRowProps[] {
    const { sideAMapping, sideBMapping } = connectorSet;

    const sideABreakoutMapping = getBreakoutMappingFromSide(sideAMapping);
    const sideBBreakoutMapping = getBreakoutMappingFromSide(sideBMapping);
    const sideAFibers = fiberMapIndicesToMatrix(fiberMapData.sourceIndices);
    const sideBFibers = fiberMapIndicesToMatrix(fiberMapData.destinationIndices);

    const rows: IFiberMapRowProps[] = [];
    for (let sideABreakoutIndex = 0; sideABreakoutIndex < sideABreakoutMapping.length; sideABreakoutIndex++) {
        const sideABreakoutMap = sideABreakoutMapping[sideABreakoutIndex];
        for (let sideBBreakoutIndex = 0; sideBBreakoutIndex < sideBBreakoutMapping.length; sideBBreakoutIndex++) {
            const sideBBreakoutMap = sideBBreakoutMapping[sideBBreakoutIndex];
            for (let sideAConnectorIndex = 0; sideAConnectorIndex < sideABreakoutMap.length; sideAConnectorIndex++) {
                for (let sideBConnectorIndex = 0; sideBConnectorIndex < sideBBreakoutMap.length; sideBConnectorIndex++) {
                    const sideAConnectorMap = sideABreakoutMap[sideAConnectorIndex];
                    const sideAIndices = sideAFibers[sideAConnectorMap.orderIndex];
                    const sideBConnectorMap = sideBBreakoutMap[sideBConnectorIndex];
                    const sideBIndices = sideBFibers[sideBConnectorMap.orderIndex];
                    if (sideAIndices && sideBIndices) {
                        for (let sideAPinIndex = 0; sideAPinIndex < sideAIndices.length; sideAPinIndex++) {
                            const sideAFiberIndex = sideAIndices[sideAPinIndex];
                            const sideBFiberIndex = sideBIndices.find(i => i.assignedIndex === sideAFiberIndex.assignedIndex)
                            if (sideAFiberIndex.assignedIndex > AssignedIndexCodes.Unassigned && sideBFiberIndex) {
                                const sideABreakout = `A${sideAConnectorMap.breakoutPosition}`;
                                const sideAFiberCount = getAdjustedFiberCount(sideAConnectorMap.fiberCount);
                                const sideAPinIndex = (sideAFiberIndex.index % sideAFiberCount);
                                const sideA: IFiberMapInfo = {
                                    position: sideAFiberCount === 2 ? String.fromCharCode(65 + sideAPinIndex) : (sideAPinIndex + 1).toString(),
                                    fiberColor: FiberColors[sideAPinIndex % 12].name,
                                    connectorPosition: sideAConnectorMap.index,
                                    connectorType: sideAConnectorMap.connectorType,
                                    legID: `${sideABreakout}'${sideAConnectorMap.index}`,
                                    breakout: sideABreakout
                                };
                                const sideBBreakout = `B${sideBConnectorMap.breakoutPosition}`;
                                const sideBFiberCount = getAdjustedFiberCount(sideBConnectorMap.fiberCount);
                                const sideBPinIndex = (sideBFiberIndex.index % sideBFiberCount);
                                const sideB: IFiberMapInfo = {
                                    position: sideBFiberCount === 2 ? String.fromCharCode(65 + sideBPinIndex) : (sideBPinIndex + 1).toString(),
                                    fiberColor: FiberColors[sideBPinIndex % 12].name,
                                    connectorPosition: sideBConnectorMap.index,
                                    connectorType: sideBConnectorMap.connectorType,
                                    legID: `${sideBBreakout}'${sideBConnectorMap.index}`,
                                    breakout: sideBBreakout
                                };
                                rows.push({ sideA, sideB });
                            }
                        }
                    }
                }
            }
        }
    }
    return rows;
}

export const setAutomaticModeAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    state.automaticMode = action.payload;
}

export const setSavingAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    state.saving = action.payload;
}

export const setPropagatingAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    state.propagating = action.payload;
}

export const setPatternTypeAction = (state: IConnectorAssignmentState, action: PayloadAction<AssignmentPattern>) => {
    state.patternType = action.payload;
}

export const handleAssignmentMethodAction = (state: IConnectorAssignmentState, action: PayloadAction<AssignmentPattern>) => {
    state.patternType = action.payload;
    state.selection.sideAMapping = [];
    state.selection.sideBMapping = [];
}

