import JSZip from "jszip";
import { AssemblyDocument } from "../../report/document/types";
import { exportToPDF, getComponentImageFromOffscrenContainer } from "./pdf-exporter";
import { t } from 'i18next';
import { LocalizationKeys } from "../../../localization/types";
import { saveAs } from 'file-saver';
import { ComponentNames } from "../../assembly/types";

const mimeType = "image/png";
const pdfExtension = ".pdf";
const zipExtension = ".zip";

export const save = async (document: AssemblyDocument, saveAsImage: boolean = false) => {
    const pdf = await exportToPDF(document);

    let fileName = getDefaultFileName();
    if (document.fileName) {
        fileName = document.fileName.replace('.', '_');
    }

    const pdfPath = fileName + pdfExtension;

    if (saveAsImage) {
        const assemblyImage = getComponentImageFromOffscrenContainer(ComponentNames.DocumentGraphicsContainer);
        if (assemblyImage) {
            assemblyImage.toBlob(async function(imageData) {
                if (imageData) {
                    saveAs(imageData, fileName)
                }
            }, mimeType);
        } else {
            console.warn("There was an error when trying to export the assembly image.")
        }
    } else {
        pdf.save(pdfPath);
    }
}

export async function generateZip(zip: JSZip, fileName: string) {
    const zipPath = fileName + zipExtension;
    await zip.generateAsync({ type: 'blob' }).then(function(content) {
        saveAs(content, zipPath);
    });
}

export function getDefaultFileName() {
    const dateString = new Date().toISOString().replace('T', ' ').slice(0, 10).replaceAll('-', "");
    return t(LocalizationKeys.ReportDefaultFileName, { dateString: dateString });
}