import { createContext, Dispatch } from "react";
import { AnyAction } from "redux";
import { IColor, Yellow } from "../../../../../ui/dialog/color/types";
import { LC } from "../../../connector/types";
import { Length, Units } from "../../../length/types";

export interface IBreakoutAttributes {
    jacketColor: IColor;
    length: Length;
    label: string;
}

export interface ILegsAttributes {
    jacketColor: IColor;
    connectorType: string;
}

export interface IBreakoutState {
    breakoutAttributes: IBreakoutAttributes;
    legsAttributes: ILegsAttributes;
    applyAll: boolean;
}

export const initialBreakoutState: IBreakoutState = {
    breakoutAttributes: {
        jacketColor: Yellow,
        length: { value: 0, unit: Units.Millimeter },
        label: "N/A"
    },
    legsAttributes: {
        jacketColor: Yellow,
        connectorType: LC
    },
    applyAll: false
}

export interface IBreakoutEditContext {
    state: IBreakoutState,
    dispatch: Dispatch<AnyAction>
}

export const initialBreakoutEditContext: IBreakoutEditContext = {
    state: initialBreakoutState,
    dispatch: () => {}
}

export const BreakoutEditContext = createContext(initialBreakoutState);