import React from 'react';
import { CableBaseAnnotationContainer, ICableBaseAnnotationProps } from './types';
import { useCableBaseAnnotation } from './hooks';
import { Container } from '@inlet/react-pixi';
import { CircleMarker } from '../../../markers/circle/CircleMarker';

export const CableBaseAnnotation: React.FC<ICableBaseAnnotationProps> = (props) => {
    const { cableBaseAnnotations } = useCableBaseAnnotation(props);
    return (
        <Container name={CableBaseAnnotationContainer}>
            { cableBaseAnnotations.map(m => <CircleMarker key={`cable-base-annotation-${m.text}`} {...m} />) }
        </Container>
    )
}