import React from 'react';
import { LineMarkerContainer, LineMarkerProps } from './types';
import { useLineMarker } from './hooks';
import { Container, Text } from '@inlet/react-pixi';
import { Arrow } from '../base/arrow/Arrow';
import { Line } from '../base/line/Line';
import { Unidirections } from '../types';
import { ToleranceMarker } from '../tolerance/ToleranceMarker';

export const LineMarker: React.FC<LineMarkerProps> = (props) => {
    const { start, startPath, end, endPath, delimiters, startDelimiterPath, endDelimiterPath, unidirection, toleranceProps, direction, text, textProps, thickness, delimiterThickness, color, alpha } = useLineMarker(props);
    if (start === end) {
        return null;
    }
    return (
        <Container name={LineMarkerContainer} {...props.coordinate} >
            {
                delimiters ?
                <>
                    { unidirection === Unidirections.End ? null : <Line coordinates={startDelimiterPath} thickness={delimiterThickness} color={color} alpha={alpha} /> }
                    { unidirection === Unidirections.Start ? null : <Line coordinates={endDelimiterPath} thickness={delimiterThickness} color={color} alpha={alpha} /> }
                </> : null
            }
            { unidirection === Unidirections.End ? null : <Arrow coordinate={start} side={direction.start} thickness={thickness} color={color} alpha={alpha} /> }
            {
                text ?
                <>
                    <Line coordinates={startPath} thickness={thickness} color={color} alpha={alpha} />
                    { toleranceProps ? <ToleranceMarker {...toleranceProps} textProps={textProps} /> : <Text {...textProps} /> }
                    <Line coordinates={endPath} thickness={thickness} color={color} alpha={alpha} />
                </> 
                :
                <Line coordinates={[start, end]} thickness={thickness} color={color} alpha={alpha} />
            }
            { unidirection === Unidirections.Start ? null: <Arrow coordinate={end} side={direction.end} thickness={thickness} color={color} alpha={alpha} /> }
        </Container>
    );
}