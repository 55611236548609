import React from 'react';
import { Container, Graphics, Sprite } from '@inlet/react-pixi';
import * as Pixi from 'pixi.js';
import { useWindowSize } from '../viewport/hooks';
import { useInitializer } from './hooks';

export const Background: React.FC = () => {
    const windowSize = useWindowSize();
    useInitializer();
    return (
        <Container>
            <Graphics draw={draw}/>
            <Sprite texture={Pixi.Texture.WHITE} {...windowSize}/>
        </Container>
    )
}

const draw = (g: Pixi.Graphics) => {
    g.clear();
}