import { useCallback } from "react";
import { ComponentName, ComponentNames } from "../../workspace/assembly/types";
import { CABContainer } from "../cable/types";
import { canvasExtractor, pixiToImage } from "./CanvasExtractor";
import * as Pixi from "pixi.js";

const removeOffscreenElements = () => {
    const offscreen = document.querySelector('#offscreen-container');
    if (offscreen) {
        const imageContainer = document.querySelector('#report-images');
        if (imageContainer) {
            for (let i = imageContainer.children.length - 1; i > -1; i--) {
                const image = imageContainer.children[i] as Node;
                imageContainer.removeChild(image);
            }   
        }
    }
};

const pixiCanvasToHTMLCanvas = (...components: ComponentName[]) => {   
    const imageElements = pixiToImage(...components);
    const imageElementsValue = Object.values(imageElements).reduce((a, b) => [...a, ...b]);
    imageElementsValue.forEach((imageElement) => {        
        imageElement.setAttribute("style", "background-color: white;")
    });
    return imageElements;
}

export const extractWorkspaceCable = () => {
    const canvas = canvasExtractor()
    if (canvas) {
        canvas.setCanvasContainer()
        const cabContainer = [ComponentNames.CABContainer]
        const cabContainers = canvas.getDisplayObjects(1, undefined, ...cabContainer)
        if (cabContainers[CABContainer] && cabContainers[CABContainer].length) {
            return cabContainers[CABContainer][0] as Pixi.Container
        }
    }
}

export const useCanvasExtract = () => {
    const extractCanvas = useCallback(() => {
        const offscreen = document.querySelector('#offscreen-container');
        if (offscreen) {
            removeOffscreenElements();
            const imageContainer = document.querySelector('#report-images');
            if (imageContainer) {
                const imageElements = pixiCanvasToHTMLCanvas(ComponentNames.DocumentGraphicsContainer);
                const imageElementsValue = Object.values(imageElements).reduce((a, b) => [...a, ...b]);
                imageElementsValue.forEach((imageElement) => {
                    imageContainer!.appendChild(imageElement);
                });
            }
        }
    }, []);

    return { extractCanvas };
}