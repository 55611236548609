import React from 'react';
import { usePolaritySchemeDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { InputDialog } from '../../../ui/dialog/input/InputDialog';
import { PolaritySchemeRow } from './polarity-row/PolaritySchemeRow';
import { OutlinedButton } from '@orbit/button';
import { MainPalettes, MainThemeTokens, Typography } from '@orbit/theme-provider';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';
import { IconButton } from '@orbit/icon-button';
import { Error } from '@mui/icons-material';

import './PolaritySchemeDialog.scss';

export const PolaritySchemeDialog: React.FC = () => {
    const { t } = useTranslation();
    const { 
        dialogProps, 
        polarityConfigurationButtons, 
        selectedScheme, 
        selectedConfiguration, 
        editInputDialogProps, 
        deleteDialogProps, 
        polaritySchemes, 
        polaritySchemesRowProps
    } = usePolaritySchemeDialog();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="dialog-container">
                <div className="polarity-button-container">
                    { polarityConfigurationButtons.map((b, i) => <OutlinedButton key={i} {...b.buttonProps}>{b.label}</OutlinedButton>) }
                </div>
                <div className="polarity-scheme-container">
                    {
                        polaritySchemes.length > 0 ?
                        <div className="polarity-schemes-list">
                            { 
                                polaritySchemesRowProps.map((b, i) => <PolaritySchemeRow key={`polaritySchemeButton-${i}`} {...b} />)
                            }
                        </div> : null
                    }
                    <div className="polarity-diagram-container">
                        {
                            selectedScheme ?
                            <>
                                <div className="polarity-name">
                                    <Typography variant='h5' label={selectedScheme.label} />
                                </div>
                                {
                                    selectedScheme.imageUri ?
                                    <>
                                        <div className="polarity-diagram">
                                            <img alt={selectedScheme.label} src={selectedScheme.imageUri} />
                                        </div>
                                        <div className="polarity-reference">
                                            <Typography variant="caption" label={t(LocalizationKeys.PolarityReference)} />
                                        </div>
                                    </> :
                                    <div className="polarity-no-diagram">
                                        <Typography variant="body2" label={t(LocalizationKeys.PolarityNoDiagram)} />
                                    </div>
                                }
                            </> :
                            polaritySchemes.length > 0 ?
                            <div className="instruction-container">
                                <div className="instruction-title">
                                    <Typography variant='h5' label={t(LocalizationKeys.Instructions)} />
                                </div>
                                <div className="instructions">
                                    <Typography variant="body2" label={t(LocalizationKeys.PolarityInstructions)} />
                                </div>
                                <div className="notes">
                                    <Typography variant="body2" label={t(LocalizationKeys.PolarityImportantNote)} />
                                </div>
                            </div> :
                            <div className="polarity-unavailable">
                                <IconButton palette={MainPalettes.error} token={MainThemeTokens.main} placement="bottom" icon={<Error />} />
                                <div className="unavailable-message">
                                    <Typography variant="body1" label={t(LocalizationKeys.PolarityUnavailable, { scheme: selectedConfiguration.label })} />
                                    <Typography variant="body1" label={t(LocalizationKeys.ContactCorning)} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <InputDialog {...editInputDialogProps}/>
            <Dialog {...deleteDialogProps} />
        </Dialog>
    )
}