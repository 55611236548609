import React from 'react';
import { White } from '../types';
import { getScaledChipDimensions, IColorChipProps } from './types';

// This chip can be scaled for better quality output in the report
export const ColorChip: React.FC<IColorChipProps> = ({ color, scaleMultiplier }) => {
    const { width: chipWidth, height: chipHeight } = getScaledChipDimensions(scaleMultiplier);
    const strokeWidth = 1 * scaleMultiplier;
    const rectRX = 13 * scaleMultiplier;
    const rect2X = 0.5 * scaleMultiplier;
    const rect2Y = 0.5 * scaleMultiplier;
    const rect2Width = 109 * scaleMultiplier;
    const rect2Height = 25 * scaleMultiplier;
    const rect2RX = 12.5 * scaleMultiplier;
    const textX = 30 * scaleMultiplier;
    const textY = 4 * scaleMultiplier;
    const fontSize = 14 * scaleMultiplier;
    const tspanY = 14 * scaleMultiplier;
    const circleRadius = 9 * scaleMultiplier;
    const circleX = 4 * scaleMultiplier;
    const circleY = 4 * scaleMultiplier;

    const stroke = color.hex === White.hex ? "#ACACAC" : color.hex; 
    const text = color.name.toUpperCase();
    return (
        <svg width={chipWidth} height={chipHeight} viewBox={`0 0 ${chipWidth} ${chipHeight}`}>
            <g>
                <g fill="#fff" stroke={stroke} strokeWidth={`${strokeWidth}px`}>
                    <rect width={chipWidth} height={chipHeight} rx={rectRX} stroke="none" />
                    <rect x={rect2X} y={rect2Y} width={rect2Width} height={rect2Height} rx={rect2RX} fill="none" />
                </g>
                <text transform={`translate(${textX} ${textY})`} fill="#4e4e50" fontSize={`${fontSize}px`} fontFamily="TheSansC4s" fontWeight="300">
                    <tspan x="0" y={tspanY}>{text}</tspan>
                </text>
                <circle cx={circleRadius} cy={circleRadius} r={circleRadius} transform={`translate(${circleX} ${circleY})`} fill={color.hex} stroke={stroke} strokeWidth={`${strokeWidth}px`} />
            </g>
        </svg>
    )
}