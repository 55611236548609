import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useBuildPlanTable } from "./hooks";

export const BuildPlanTable : React.FC = () => {
    const { t } = useTranslation();
    const { rows } = useBuildPlanTable();

    return (
            <table id="build-plan-table">
                <thead>
                    <tr>
                        <th>{t(LocalizationKeys.Description)}</th>
                        <th>{t(LocalizationKeys.Quantity)}</th>
                        <th>{t(LocalizationKeys.UOM)}</th>
                    </tr>
                </thead>
                <tbody>
                    { rows.map((r, i) => 
                        <tr key={`tablerow${i}`}>
                            <td>{r.description}</td>
                            <td>{r.quantity}</td>
                            <td>{r.uom}</td>
                        </tr>) 
                    }
                </tbody>
            </table>
    )
};