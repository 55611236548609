import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { createContext } from "react";
import { GeneralSetupReducer } from "./general-setup/reducer";
import { initialWizardSetupState, ICableSetupContext, initialCableSetupContext } from "./types";
import { moveNextStepAction, movePreviousStepAction, resetWizardStateAction, setResetBreakoutsEnableAction, setApplyEnableAction, setHiddenAction, setWizardSetupStateAction, setPreviousAssemblyAction } from './actions';
import { BreakoutStepReducer } from "./breakout-step/reducer";

const wizardSetupSlice = createSlice({
    initialState: initialWizardSetupState,
    name: "cable-setup",
    reducers: {
        moveNextStep: moveNextStepAction,
        movePreviousStep: movePreviousStepAction,
        setResetEnable: setResetBreakoutsEnableAction,
        resetWizardState: resetWizardStateAction,
        setApplyEnable: setApplyEnableAction,
        setHidden: setHiddenAction,
        setWizardSetupState: setWizardSetupStateAction,
        setPreviousAssembly: setPreviousAssemblyAction
    }
})

export const CableSetupReducer = combineReducers({
    generalSetup: GeneralSetupReducer,
    breakoutSetup: BreakoutStepReducer,
    wizardSetup: wizardSetupSlice.reducer
})

export type CableSetupState = ReturnType<typeof CableSetupReducer>

export const CableSetupContext = createContext<ICableSetupContext>(initialCableSetupContext)

export const { moveNextStep, movePreviousStep, setResetEnable, setApplyEnable, setHidden, resetWizardState, setWizardSetupState, setPreviousAssembly } = wizardSetupSlice.actions;
