import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../localization/types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { showTimeOutSelector } from "../../workspace/store/selectors";
import { logOff } from "../../workspace/store/reducer";
import { useStoreDispatch } from "../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";

export const useTimeOutDialog = () => {
    const open = useSelector(showTimeOutSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onLoginAgainClick = useCallback(() => {
        storeDispatch(logOff());
    }, [storeDispatch]);

    const dialogActions: IDialogActions = {
        confirmText: t(LocalizationKeys.LoginAgain),
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        },
        onConfirmClick: onLoginAgainClick
    };

    const dialogProps: DialogProps = {
        open,
        className: "timeout-dialog",
        title: t(LocalizationKeys.TimeOutTitle),
        message: t(LocalizationKeys.TimeOutMessage),
        actions: dialogActions
    };

    return { dialogProps };
}