import { IBreakoutDetailsState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setSelectedLegsAction = (state: IBreakoutDetailsState, action: PayloadAction<number[]>) => {
    state.selectedLegs = action.payload;
}

export const updateSelectedLegsAction = (state: IBreakoutDetailsState, action: PayloadAction<number>) => {
    const leg = action.payload;
    if (state.selectedLegs.includes(leg)) {
        state.selectedLegs = state.selectedLegs.filter(l => l !== leg);
    } else {
        state.selectedLegs.push(leg);
    }
}

export const clearSelectionAction = (state: IBreakoutDetailsState) => {
    state.selectedLegs = [];
}