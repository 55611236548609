import { Container, withFilters } from '@inlet/react-pixi'
import { GlowFilter, AdjustmentFilter } from 'pixi-filters'
import React from 'react'
import { useBoundingBox } from '../bounding-box/hooks';
import { BoundingBoxContext, initialContainerBox } from '../bounding-box/types';
import { SelectionProps } from './types';

export const SelectFilter = withFilters(Container, {
    adjust: AdjustmentFilter,
    glow: GlowFilter
})

export const selectFilter = { alpha: 1, green: (86 / 51), blue: (146 / 51), brightness: 1, gamma: 0.5, enabled: true };
export const hoverFilter = { alpha: 1, brightness: 0.8, enabled: true };
export const glowFilter = { innerStrength: 0, outerStrength: 2, color: 0x0098DB, knockout: false, enabled: true };
export const defaultFilter = { enabled: false };

export const withSelection = <T extends object>(Component: React.ComponentType<T>, bufferRec = initialContainerBox): React.FC<SelectionProps<T>> => {
    return (props: SelectionProps<T>) => {
        const { containerRef, state, isInteractive } = useBoundingBox(props, bufferRec)
        const { mousedown, mouseout, mouseover, mouseup, mouseupoutside } = props;
        return (
            <BoundingBoxContext.Provider value={state}>
                <Container name={props.name || ''} ref={containerRef} interactive={isInteractive} interactiveChildren {...{ mousedown, mouseout, mouseover, mouseup, mouseupoutside }} cursor="pointer">
                    <Component {...props} />
                </Container>
            </BoundingBoxContext.Provider>
        )
    }
}