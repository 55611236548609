import { useCallback, useEffect, useRef, useState } from "react";
import { batch, useSelector } from "react-redux";
import { AuthenticationService } from "../../services/authentication/authentication-service";
import { renewToken, setStatus, showIdle } from "../store/reducer";
import { statusSelector } from "../store/selectors";
import { Status } from "../store/types";
import { FIVE_MINS, MS_MULTIPLIER, FOUR_HOURS } from "./timer/types";
import { rootSelector } from "../../store/selectors";
import { useStoreDispatch } from "../../store/hooks";

export const useActivityTracker = () => {
    const status = useSelector(statusSelector);
    const token = AuthenticationService.getToken();
    const expiration = (token ? (token.exp - token.iat) : FOUR_HOURS) * MS_MULTIPLIER;

    // Listen for changes on root state
    const rootState = useSelector(rootSelector);

    const changeRef = useRef(false);
    const clickRef = useRef(false);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const storeDispatch = useStoreDispatch();

    const onTimeout = useCallback(async () => {
        if (changeRef.current && clickRef.current) {
            changeRef.current = false;
            const tokenStr = AuthenticationService.getTokenString();
            if (tokenStr) {
                storeDispatch(renewToken(tokenStr));
            }
        } else {
            batch(() => {
                storeDispatch(showIdle(true));
                storeDispatch(setStatus(Status.Inactive));
            });
        }
        clickRef.current = false;
        setTimeoutId(undefined);
    }, [storeDispatch]);

    // Detect user activity
    useEffect(() => {
        window.addEventListener("click", () => { clickRef.current = true; });
        if (status !== Status.Inactive) {
            changeRef.current = true;
        }

        return () => { window.removeEventListener("click", () => clickRef.current = true); };
    }, [status, rootState]);

    // Handle idling
    useEffect(() => {
        if (status !== Status.Inactive && !timeoutId) {
            changeRef.current = false;

            const id = setTimeout(onTimeout, expiration - (FIVE_MINS * MS_MULTIPLIER));
            setTimeoutId(id);
        }
    }, [status, expiration, timeoutId, onTimeout]);
};