import React from 'react'
import { useUnitConverter } from './hooks';
import { Dialog } from '@orbit/dialog';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Typography } from '@orbit/theme-provider';

import "./UnitConverter.scss";

export const UnitConverter: React.FC = () => {
    const { dialogProps, unitProps } = useUnitConverter();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className='unit-container'>
                <RadioGroup row value={unitProps.value}>
                    { 
                        unitProps.options.map((o, i) => {
                            return (
                                <div key={`unit-option-${i}`} className='radio-container'>
                                    <Typography variant="subtitle2" label={o.title} />
                                    <FormControlLabel className={o.className} label={o.label} control={<Radio {...o.radioButtonProps} />} />
                                    <div className='radio-hint'>
                                        <Typography variant="helperText" label={o.hint} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </RadioGroup>
                <Divider />
                <Typography variant="helperText" label={unitProps.hint} />
            </div>
        </Dialog>
    );
}