import { useSelector } from "react-redux";
import { useStoreDispatch } from "../../../../store/hooks";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { showHelp } from "../../../store/reducer";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { LocalizationKeys } from "../../../../localization/types";
import { IconButtonProps, TooltipPlacement } from "@orbit/icon-button";
import { showAssemblyWizardSelector } from "../../../store/selectors";

export const useHelpAction = () => {
    const { t } = useTranslation();
    const dispatch = useStoreDispatch();
    const showWizard = useSelector(showAssemblyWizardSelector);
    const disabledUI = useSelector(disabledOverlaySelector);

    const onHelpClick = useCallback(() => {
        dispatch(showHelp(true));
    }, [dispatch]);

    const disabled = disabledUI && !showWizard;
    const helpIconProps: Omit<IconButtonProps, "icon"> = {
        className: "settings-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Settings),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: onHelpClick
    }

    return { helpIconProps };
};