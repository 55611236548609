import { createSlice } from "@reduxjs/toolkit";
import { displayUnitConverterAction, setWorkspaceUnitAction } from "./actions";
import { initialWorkspaceUnit } from "./types";

export const unitSlice = createSlice({
    name: "unit",
    initialState: initialWorkspaceUnit,
    reducers: {
        displayUnitConverter: displayUnitConverterAction,
        setWorkspaceUnit: setWorkspaceUnitAction,
    }
})

export const UnitReducer = unitSlice.reducer;
export const { displayUnitConverter, setWorkspaceUnit } = unitSlice.actions;