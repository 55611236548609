import React from 'react';
import { useActionPanel } from './hooks';
import { PolarityAction } from './polarity/PolarityAction';
import { TextButton } from '@orbit/button';
import { IconButton } from '@orbit/icon-button';
import { AddBox } from '@mui/icons-material';
import { AnnotationAction } from './annotation/AnnotationAction';
import { ReportAction } from './report/ReportAction';
import { UnitAction } from './unit/UnitAction';
import { SettingsAction } from './settings/SettingsAction';
import { MenuAction } from './menu/MenuAction';
import { Divider } from '@mui/material';
import { HelpAction } from './help/HelpAction';

import "./ActionPanel.scss";

export const ActionPanel: React.FC = () => {
    const { display, assemblyEdit, newCableIconProps } = useActionPanel();
    if (!display) {
        return null;
    }
    return (
        <div className="action-panel toolbar toggle-pointer-events">
            <PolarityAction />
            <Divider orientation="vertical" variant="middle" flexItem />
            <TextButton {...assemblyEdit.buttonProps}>
                {assemblyEdit.label}
            </TextButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <AnnotationAction />
            <ReportAction />
            <UnitAction />
            <SettingsAction />
            <IconButton {...newCableIconProps} icon={<AddBox />} />
            <HelpAction />
            <Divider orientation="vertical" variant="middle" flexItem />
            <MenuAction />
        </div>
    )
}