import React from 'react';
import { useSettingsDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../localization/types';
import { Checkbox } from '@orbit/checkbox';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { Typography } from '@orbit/theme-provider';

import './SettingsDialog.scss';

export const SettingsDialog: React.FC = () => {
    const { dialogProps, cableBaseCheckboxProps, toleranceCheckboxProps, pullingGripCheckboxProps, polaritySortType } = useSettingsDialog();
    const { t } = useTranslation();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="drawing-specs" tabIndex={0}>
                <div className="section-title">
                    <Typography variant="subtitle1" label={t(LocalizationKeys.DrawingSpecs)} />
                </div>
                <Checkbox {...cableBaseCheckboxProps} />
                <Checkbox {...toleranceCheckboxProps} />
                <Checkbox {...pullingGripCheckboxProps} />
            </div>
            <div className="polarity-report">
                <div className="section-title">
                    <Typography variant="subtitle1" label={polaritySortType.label} />
                </div>
                <RadioGroup row value={polaritySortType.value}>
                    { polaritySortType.options.map((o, i) => <FormControlLabel key={`sort-type-${i}`} className={o.className} label={o.label} control={<Radio {...o.radioButtonProps} />} />)}
                </RadioGroup>
            </div>
        </Dialog>
    )
}