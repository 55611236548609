import { createSlice } from "@reduxjs/toolkit";
import { setApplicationAction, setApprovalDateAction, setBuildPlanAction, setConnectorViewsAction, setDescriptionAction, setDrawingNumberAction, setDrawingTitleAction, setDrawnByAction, setEndADescriptionAction, setEndBDescriptionAction, setHeaderTitleAction, setInServiceDateAction, setLocationAction, setNotesAction, setPolarityDiagramAction, setRevisionAction, setShowCableOverviewAction } from "./actions";
import { initialPrintDialogState } from "./types";

const printDialogSlice = createSlice({
    name: "print-dialog",
    initialState: initialPrintDialogState,
    reducers: {
        setDrawingNumber: setDrawingNumberAction,
        setDescription: setDescriptionAction,
        setApplication: setApplicationAction,
        setApprovalDate: setApprovalDateAction,
        setInServiceDate: setInServiceDateAction,
        setRevision: setRevisionAction,
        setHeaderTitle: setHeaderTitleAction,
        setDrawingTitle: setDrawingTitleAction,
        showCableOverview: setShowCableOverviewAction,
        setDrawnBy: setDrawnByAction,
        setEndADescription: setEndADescriptionAction,
        setEndBDescription: setEndBDescriptionAction,
        setLocation: setLocationAction,
        setNotes: setNotesAction,
        setPolarityDiagram: setPolarityDiagramAction,
        setBuildPlan: setBuildPlanAction,
        setConnectorViews: setConnectorViewsAction
    }
});

export const PrintDialogReducer = printDialogSlice.reducer;
export const {
    setDrawingNumber, setDescription, setApplication, setApprovalDate,
    setInServiceDate, setRevision, setHeaderTitle, setDrawingTitle, showCableOverview,
    setEndADescription, setEndBDescription, setNotes, setLocation, setDrawnBy,
    setBuildPlan, setPolarityDiagram, setConnectorViews
} = printDialogSlice.actions;