import { createSlice } from "@reduxjs/toolkit";
import { initialPolarityState, TYPEA_MTP24_LC_KEY, TYPEB_MTP12_LC_KEY, TYPEU2_MTP8_LC_KEY, TYPEU_MTP12_LC_KEY, TYPEU1_MTP8_LC_KEY, TYPEB_MTP8_LC_KEY } from "./types";
import { showPolaritySchemesAction, showConnectorAssignmentAction, showFiberMappingAction, setFiberMapsAction, setUserFiberMapsAction, updateUserFiberMapAction, setHighlightedConnectorsAction, setForceUpdateAction, deleteUserFiberMapAction, setShowPropagationAction } from "./actions";
import { createSecuredAsyncAction, extractData } from "../../../store/actions";
import { AssemblyService } from "../../services/assembly/assembly-service";
import { IFiberMapData } from "../fiber-map/types";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";

const polaritySlice = createSlice({
    name: "polarity",
    initialState: initialPolarityState,
    reducers: {
        showPolaritySchemes: showPolaritySchemesAction,
        showConnectorAssignment: showConnectorAssignmentAction,
        showFiberMapping: showFiberMappingAction,
        setShowPropagation: setShowPropagationAction,
        setFiberMaps: setFiberMapsAction,
        setUserFiberMaps: setUserFiberMapsAction,
        updateUserFiberMap: updateUserFiberMapAction,
        deleteUserFiberMap: deleteUserFiberMapAction,
        setHighlightedConnectors: setHighlightedConnectorsAction,
        setForceUpdate: setForceUpdateAction
    }
});

export const PolarityReducer = polaritySlice.reducer;
export const { showPolaritySchemes, showConnectorAssignment, showFiberMapping, setShowPropagation, setFiberMaps, setUserFiberMaps, updateUserFiberMap, setHighlightedConnectors, setForceUpdate } = polaritySlice.actions;

export const getPolarityFiberMaps = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.getPolarityFiberMaps();
        const fiberMaps = extractData(res, dispatch);
        if (fiberMaps) {
            const keysToReverse = [TYPEU1_MTP8_LC_KEY, TYPEU_MTP12_LC_KEY, TYPEA_MTP24_LC_KEY, TYPEU2_MTP8_LC_KEY, TYPEB_MTP8_LC_KEY, TYPEB_MTP12_LC_KEY];
            const reversedFiberMaps: IFiberMapData[] = [];
            for (const key of keysToReverse) {
                const regularFiberMap = fiberMaps.find(m => m.key === key);
                if (regularFiberMap) {
                    const newKey = regularFiberMap.key * 100; // Hard-coded decision to make the reversed schemes the same index times 100.
                    reversedFiberMaps.push({
                        key: newKey,
                        name: `Reversed ${regularFiberMap.name}`,
                        sourceIndices: regularFiberMap.destinationIndices,
                        destinationIndices: regularFiberMap.sourceIndices
                    })
                }
            }

            const predefinedFiberMaps = [ ...fiberMaps, ...reversedFiberMaps ];
            dispatch(setFiberMaps(predefinedFiberMaps));
        }
    });
}

export const getUserFiberMaps = (userId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.getUserFiberMaps(userId);
        const fiberMaps = extractData(res, dispatch);
        if (fiberMaps) {
            dispatch(setUserFiberMaps(fiberMaps));
        }
    });
}

export const deleteUserFiberMap = (fiberMap: IFiberMapData) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        dispatch(polaritySlice.actions.deleteUserFiberMap(fiberMap.id!)); // Pre-update for the UI
        const res = await service.deleteUserFiberMap(fiberMap.id!);
        if (res.succesful) {
            dispatch(setStatus(Status.Active));
        } else {
            dispatch(updateUserFiberMap(fiberMap)); // In case the delete fails, we want to re-add the fiberMap
            console.warn(`Something wrong happened when trying to delete Fiber Map ${fiberMap.name}`)
        }
    })
}