import React from 'react';
import { ITemplateProps } from './types';
import { useSimplexTemplate } from './hooks';
import { ConnectorPin } from './ConnectorPin';

export const SimplexTemplate: React.FC<ITemplateProps> = (props) => {
    const { onClick, connectorName, pin } = useSimplexTemplate(props);
    return (
        <svg width="82" height="115" viewBox="0 0 82 90" onClick={onClick}>
            <defs>
                <filter id="simplexTemplateFilter" x="0" y="0" width="82" height="90" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 -18)">
                <g transform="matrix(1, 0, 0, 1, -9, 18)" filter="url(#simplexTemplateFilter)">
                    <g transform="translate(9 6)" fill="#e5ebeb" stroke="#000010" strokeWidth="4px">
                        <rect width="64" height="72" rx="16" stroke="none"/>
                        <rect x="2" y="2" width="60" height="68" rx="14" fill="none"/>
                    </g>
                </g>
                <text transform="translate(28 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="700">
                    <tspan x="0" y="0">A</tspan>
                </text>
                <text transform="translate(32 115)" stroke="rgba(0,0,0,0)" fontSize="12px" fontFamily="TheSansC4s" fontWeight="700" textAnchor="middle">
                    <tspan x="0" y="0">{connectorName}</tspan>
                </text>
                <circle cx="20" cy="20" r="20" fill="rgba(255,255,255,0)"/>
                <g key={pin.index} transform={`translate(${pin.position.x}, ${pin.position.y})`}>
                    <ConnectorPin side={pin.side} pinIndex={pin.index} />
                </g>
            </g>
        </svg>
    )
}