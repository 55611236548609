import { combineReducers } from "@reduxjs/toolkit";
import { WorkspaceReducer } from '../workspace/store/reducer'
import { CableSetupStoreReducer } from '../workspace/wizard/store/reducer'
import { AssemblyReducer } from '../workspace/assembly/store/reducer';
import { ReportReducer } from "../workspace/report/store/reducer";
import { UnitReducer } from "../workspace/assembly/length/store/reducer";
import { ViewportBridgeReducer } from "../pixi/viewport/store/reducer";
import { ProjectManagerReducer } from "../workspace/project-drawer/store/reducer";
import { PolarityReducer } from "../workspace/polarity/store/reducer";

export const RootReducer = combineReducers({
    workspace: WorkspaceReducer,
    cableSetup: CableSetupStoreReducer,
    assembly: AssemblyReducer,
    report: ReportReducer,
    unit: UnitReducer,
    viewportBridge: ViewportBridgeReducer,
    projectManager: ProjectManagerReducer,
    polarity: PolarityReducer
});

export type RootState = ReturnType<typeof RootReducer>;