import { createSelector } from "@reduxjs/toolkit";
import { rootSelector } from "../../../store/selectors";

export const cableSetupSelector = createSelector(
    rootSelector,
    root => root.cableSetup
);

export const cableSetupStateSelector = createSelector(
    cableSetupSelector,
    cableSetup => cableSetup.setupState
);