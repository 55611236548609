import { useSelector } from "react-redux";
import { showHelpSelector } from "../store/selectors";
import { useCallback, useState } from "react";
import { useStoreDispatch } from "../../store/hooks";
import { showHelp } from "../store/reducer";
import { DialogProps } from "@orbit/dialog";
import { LocalizationKeys, capitalize } from "../../localization/types";
import { UserInteraction, UserInteractions, getAnimation } from "./types";
import { useTranslation } from "react-i18next";

export const useHelpDialog = () => {
    const { t } = useTranslation();
    const display = useSelector(showHelpSelector);
    const dispatch = useStoreDispatch();

    const [currentInteraction, setCurrentInteraction] = useState<UserInteraction>(UserInteractions.CreateCableAssembly);

    const onClose = useCallback(() => {
        dispatch(showHelp(false));
        setCurrentInteraction(UserInteractions.CreateCableAssembly);
    }, [dispatch]);

    const dialogProps: DialogProps = {
        open: display,
        className: "help-dialog",
        title: "Help",
        modal: true,
        onClose
    };

    const onInteractionClick = useCallback((interaction: UserInteraction) => {
        if (currentInteraction !== interaction) {
            setCurrentInteraction(interaction);
        }
    }, [currentInteraction]);

    const interactions = Object.values(UserInteractions).map(i => ({ 
        name: capitalize(t(LocalizationKeys[i])), 
        className: i === currentInteraction ? "interaction selected" : "interaction", 
        onClick: () => onInteractionClick(i) 
    }));

    const animation = getAnimation(currentInteraction);

    return {
        dialogProps,
        interactions,
        animation
    }
};