import { ICablePositionContext } from "../cable/position/types";
import { CableProps, IPosition } from "../cable/types";

export interface IDocumentGraphicsProps {
    position: IPosition,
    cablePosition: ICablePositionContext,
    loaded: boolean
}

export type DocumentGraphicsProps = IDocumentGraphicsProps & CableProps

export const DocumentGraphicsContainer = "document-graphics-container";