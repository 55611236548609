import React from 'react';
import { useProjectManager } from './hooks';
import { Drawer } from '@orbit/drawer';
import { DrawerHeader } from '../../ui/drawer/drawer-header/DrawerHeader';
import { DrawerSubHeader } from './sub-header/DrawerSubHeader';
import { AssemblyInfoRow } from './row/AssemblyInfoRow';

import './ProjectDrawer.scss';

export const ProjectDrawer: React.FC = () => {
    const { drawerProps, header, subHeader, rows } = useProjectManager();
    return (
        <Drawer variant="temporary" {...drawerProps}>
            <DrawerHeader {...header} />
            <DrawerSubHeader {...subHeader} />
            <div className="project-drawer-body">
                {rows.map(r => <AssemblyInfoRow key={r.id} {...r} />)}
            </div>
        </Drawer>
    )
}