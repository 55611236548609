import { Canvg } from 'canvg';
import i18next from 'i18next';
import jsPDF from "jspdf";
import autoTable, { Color } from "jspdf-autotable";
import { LocalizationKeys } from "../../../localization/types";
import { getConnectorSVG, getDefaultConnectorColor } from "../../../pixi/cable/breakout/connector-furcation/connector-sprite/types";
import { defaultLabelTolerances, defaultTolerances } from "../../../pixi/markers/tolerance/types";
import { chipOriginalHeight, chipOriginalWidth, getScaledChipDimensions } from "../../../ui/dialog/color/chip/types";
import { Sides } from "../../assembly/breakout/types";
import { isDuplex, SN } from "../../assembly/connector/types";
import { IAssemblyInfo } from "../../assembly/info/types";
import { convertTo, Units } from "../../assembly/length/types";
import { FiberColors, MTPColors } from "../../assembly/palette/types";
import { assemblyHasCustomTolerances, CableType, CableTypes, ComponentNames, FiberType, FiberTypes, FurcationOuterDiameters, getDefaultToleranceValues, IAssembly, PullingGrips } from "../../assembly/types";
import { IConnectorRowProps } from "../../offscreen/connector-table/types";
import { IConnectorAssignmentMap } from "../../polarity/connector-assignment/reducer/types";
import { CUSTOM_MAP_KEY } from "../../polarity/store/types";
import { AssemblyDocument, IUserProvidedImage } from "../../report/document/types";
import { calculateNumRows, calculateRowHeight, IChip, IConnectorImageData, initialPageRow, initialTableData, IRowData, ITableData, updateCurrentPageRow, updateFirstTableData } from "./types";

const pageOrientation = 'l' // Landscape
const pageUnits = 'pt' // Points
const pageFormat = "ledger" // 17x11 inches or 1224x792 points in landscape

let scaleFactor = 0;
let pageWidth = 0;
let pageHeight = 0;

const WHITE_RGB: Color = [255, 255, 255];
const WHITE_SMOKE_RGB: Color = [244, 244, 244];
const LIGHT_GREY_RBG: Color = [231, 230, 230];
const DARK_GREY_RBG: Color = [208, 206, 206];
const BLACK_RGB: Color = [0, 0, 0];

const LIGHT_BLUE_RGB = { R: 0, G: 82, B: 147 };

const padding = 60;
const topLeft = { x: padding, y: padding };
const fontScale = 4.76;
const sectionFontSize = 6 * fontScale;
const cellPadding = 10;
const tableMargin = 60;

const originalImageWidth = 7;
const originalImageHeight = 35;
const imageScaling = 3.5;
const imageWidth = originalImageWidth * imageScaling;
const imageHeight = originalImageHeight * imageScaling;
const tableSpacerWidth = 4;

let boxWidth = 0;
let boxHeight = 0;
let tableWidth = 0;
let pageContentStart = 0;

let contentWidth = 0;
let contentHeight = 0;
let contentY = 0;
let headerHeight = 0;

const tableLabelFontSize = 20;
const tableLabelTextColor = 0x000000;
const tablePaddingTop = 5;
const tableFontSize = 16;
const tableTextColor = 0x000000;
const tableFillColor = false;
const tableLineColor = 0x000000;
const tableLineWidth = 1;

export const exportToPDF = async (document: AssemblyDocument) => {
    const pdf = new jsPDF(pageOrientation, pageUnits, pageFormat);
    scaleFactor = pdf.internal.scaleFactor;
    pageWidth = pdf.internal.pageSize.width * scaleFactor;
    pageHeight = pdf.internal.pageSize.height * scaleFactor;
    contentWidth = pageWidth - (2 * padding);
    headerHeight = pageHeight * 0.05;
    contentHeight = pageHeight - headerHeight - (3 * padding);
    contentY = headerHeight + (2 * padding);

    boxWidth = pageWidth - (2 * padding);
    boxHeight = pageHeight * 0.051;
    tableWidth = boxWidth * 0.5;
    pageContentStart = topLeft.y;

    pdf.deletePage(1);

    const chips = await generateChips();

    addAssembly(pdf, document);
    if (document.assembly.assemblyInfo && document.assembly.assemblyInfo.connectorViews) {
        await addConnectorCloseUp(pdf, document);
    }

    addAnnotationTable(pdf, document.assembly.annotation?.notes);

    addBreakoutTable(pdf, chips, document);
    addLegTable(pdf, chips, document);

    if (document.assembly.assemblyInfo && document.assembly.assemblyInfo.buildPlan) {
        addBuildPlanPage(pdf, document);
    }

    if (document.assembly.assemblyInfo && document.assembly.assemblyInfo.polarityDiagram) {
        addPolarityTable(pdf, chips);
        addPolarityDiagrams(pdf, document);
    }

    if (document.assembly?.assemblyInfo?.userProvidedImages && document.assembly.assemblyInfo.userProvidedImages.length > 0) {
        await addUserProvidedImages(pdf, document.assembly.assemblyInfo.userProvidedImages);
    }

    for (let pageNumber = 1; pageNumber < pdf.internal.pages.length; pageNumber++) {
        pdf.setPage(pageNumber);
        if (document.assembly.assemblyInfo) {
            addFooter(pdf, pageNumber, pdf.internal.pages.length - 1, { ...document.assembly.assemblyInfo, notes: formatAssemblyNotes(document.assembly.assemblyInfo.notes) });
        }
    }

    return pdf;
}

async function addUserProvidedImages(pdf: jsPDF, images: IUserProvidedImage[]) {
    const uploads = images.map(({ objectUrl }) => {
        const image = new Image();
        image.src = objectUrl;
        const loaded = new Promise<void>((resolve) => image.onload = () => resolve());
        return { image, loaded };
    });

    for (const upload of uploads) {
        await upload.loaded;
        addImage(pdf, upload.image);
    }
}

function addImage(pdf: jsPDF, image: HTMLImageElement) {
    const { t } = i18next;
    let { imageWidth, imageHeight } = fitImageToPage(image);
    const x = (pageWidth - imageWidth) * 0.5;
    const y = headerHeight;
    pdf.addPage(pageFormat, pageOrientation);
    pdf.addImage(image, 'PNG', x, y, imageWidth, imageHeight);
    pdf.setFontSize(10);
    const wrappedNote = pdf.splitTextToSize(t(LocalizationKeys.ReportUserProvidedImagesNote), imageWidth);
    pdf.text(wrappedNote, x, y + imageHeight + 10, { baseline: "top" });
}

const formatAssemblyNotes = (assemblyNotes?: string) => {
    if (assemblyNotes && assemblyNotes.length > 0) {
        const firstLineIndex = assemblyNotes.indexOf("\n");
        let endSliceIndex = -1;
        for (let i = 0; i < assemblyNotes.length; i++) {
            if (assemblyNotes[i] === "\n" && i !== firstLineIndex) {
                endSliceIndex = i;
                break;
            }
        }

        let formattedAssemblyNotes = assemblyNotes;
        if (endSliceIndex !== -1) {
            formattedAssemblyNotes = assemblyNotes.slice(0, endSliceIndex);
        }
        return formattedAssemblyNotes;
    }
    return undefined;
}

async function generateChips(): Promise<IChip[]> {
    const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
    const colorChipsContainer = offscreen.querySelector("#color-chips-container") as HTMLDivElement;
    const chipSVGs = colorChipsContainer.getElementsByTagName("svg");

    const chipsDimensions = getScaledChipDimensions(5);
    const canvas = document.createElement('canvas');
    canvas.width = chipsDimensions.width;
    canvas.height = chipsDimensions.height;
    const ctx = canvas.getContext('2d')!;

    const chips: { index: number, imageData: string }[] = [];
    for (let index = 0; index < chipSVGs.length; index++) {
        const svg = chipSVGs[index];
        const image = await Canvg.from(ctx, svg.innerHTML);
        image.start();
        const imageData = canvas.toDataURL('image/png');
        chips.push({ index, imageData })
    }

    return chips;
}

function addHeader(pdf: jsPDF, info: IAssemblyInfo) {
    const headerTitle = info.headerTitle && info.headerTitle.length > 0 ? info.headerTitle : "INFRASTRUCTURE DELIVERY STANDARD CABLE ASSEMBLY DRAWING";
    const drawingNumber = info.drawingNumber && info.drawingNumber.length > 0 ? info.drawingNumber : "";
    const revision = info.revision && info.revision.length > 0 ? info.revision : "";
    const approvalDate = info.approvalDate && info.approvalDate.length > 0 ? info.approvalDate : "";
    const inServiceDate = info.inServiceDate && info.inServiceDate.length > 0 ? info.inServiceDate : "";
    const description = info.description && info.description.length > 0 ? info.description : "";
    const application = info.application && info.application.length > 0 ? info.application : "";
    const drawingTitle = info.drawingTitle && info.drawingTitle.length > 0 ? info.drawingTitle : "Cable Assembly Drawing";
    const endADescription = info.endADescription && info.endADescription.length > 0 ? info.endADescription : "";
    const endBDescription = info.endBDescription && info.endBDescription.length > 0 ? info.endBDescription : "";
    const cableOverviewText = "Cable Overview";
    const fiberSummary = getFiberSummary(info);
    const tolerancesAndUOMSummary = getTolerancesAndUOMSummary(info);

    const bottomLeft = { x: padding, y: pageHeight - padding };

    pdf.setFontSize(14);
    const titleDimensions = pdf.getTextDimensions(headerTitle);
    pdf.text(headerTitle, (pageWidth - titleDimensions.w) * 0.5, topLeft.y);

    pdf.setFontSize(8);
    pdf.setFont("helvetica", "bold");
    const cellY = topLeft.y + 6;
    let cellWidth = (pageWidth - padding * 2) * 0.25;
    const cellHeight = 15;
    const textX = topLeft.x + 4;
    const textY = topLeft.y + 16;

    pdf.rect(topLeft.x, cellY, cellWidth, cellHeight);
    const dnTitle = `${i18next.t(LocalizationKeys.AssemblyDrawingNumber)}: `;
    const dnDimensions = pdf.getTextDimensions(dnTitle)

    pdf.rect(topLeft.x + cellWidth, cellY, cellWidth, cellHeight);
    const rTitle = `${i18next.t(LocalizationKeys.Revision)}: `;
    const rDimensions = pdf.getTextDimensions(rTitle);

    pdf.rect(topLeft.x + cellWidth * 2, cellY, cellWidth, cellHeight);
    const adTitle = `${i18next.t(LocalizationKeys.ApprovalDate)}: `;
    const adDimensions = pdf.getTextDimensions(adTitle);

    pdf.rect(topLeft.x + cellWidth * 3, cellY, cellWidth, cellHeight);
    const isdTitle = `${i18next.t(LocalizationKeys.InServiceDate)}: `;
    const isdDimensions = pdf.getTextDimensions(isdTitle);

    const cellWidthLarge = pageWidth - padding * 2 - cellWidth;
    pdf.rect(topLeft.x, cellY + cellHeight, cellWidthLarge, cellHeight);
    const dTitle = `${i18next.t(LocalizationKeys.Description)}: `;
    const dDimensions = pdf.getTextDimensions(dTitle);

    pdf.rect(topLeft.x, cellY + cellHeight * 2, cellWidthLarge, cellHeight);
    const aTitle = `${i18next.t(LocalizationKeys.Application)}: `;
    const aDimensions = pdf.getTextDimensions(aTitle);

    pdf.rect(topLeft.x + cellWidth * 3, cellY + cellHeight, cellWidth, cellHeight);
    const eaTitle = `${i18next.t(LocalizationKeys.EndLabel, { side: "A" })}: `;
    const eaDimension = pdf.getTextDimensions(eaTitle);

    pdf.rect(topLeft.x + cellWidth * 3, cellY + cellHeight * 2, cellWidth, cellHeight);
    const ebTitle = `${i18next.t(LocalizationKeys.EndLabel, { side: "B" })}: `;
    const ebDimension = pdf.getTextDimensions(ebTitle);

    pdf.setFont("helvetica", "bold");
    pdf.text(dnTitle, textX, textY);
    pdf.text(rTitle, textX + cellWidth, textY);
    pdf.text(adTitle, textX + cellWidth * 2, textY);
    pdf.text(isdTitle, textX + cellWidth * 3, textY);
    pdf.text(dTitle, textX, textY + cellHeight);
    pdf.text(aTitle, textX, textY + cellHeight * 2);
    pdf.text(eaTitle, textX + cellWidth * 3, textY + cellHeight);
    pdf.text(ebTitle, textX + cellWidth * 3, textY + cellHeight * 2);

    pdf.setFont("helvetica", "normal");
    pdf.text(drawingNumber, textX + dnDimensions.w, textY);
    pdf.text(revision, textX + cellWidth + rDimensions.w, textY);
    pdf.text(getDateString(approvalDate), textX + cellWidth * 2 + adDimensions.w, textY);
    pdf.text(getDateString(inServiceDate), textX + cellWidth * 3 + isdDimensions.w, textY);
    pdf.text(description, textX + dDimensions.w, textY + cellHeight);
    pdf.text(application, textX + aDimensions.w, textY + cellHeight * 2);
    pdf.text(endADescription, textX + cellWidth * 3 + eaDimension.w, textY + cellHeight);;
    pdf.text(endBDescription, textX + cellWidth * 3 + ebDimension.w, textY + cellHeight * 2);

    pdf.setFontSize(14);

    let titleY = textY + cellHeight * 3.5;
    if (info.showCableOverview && fiberSummary.length > 0) {
        pdf.setTextColor(LIGHT_BLUE_RGB.R, LIGHT_BLUE_RGB.G, LIGHT_BLUE_RGB.B);
        pdf.text(cableOverviewText, topLeft.x, titleY);
        pdf.setTextColor(tableLabelTextColor);
        pdf.text(fiberSummary, topLeft.x, textY + cellHeight * 4.75);
        titleY = textY + cellHeight * 6;
    }

    pdf.setTextColor(LIGHT_BLUE_RGB.R, LIGHT_BLUE_RGB.G, LIGHT_BLUE_RGB.B);
    pdf.text(drawingTitle, topLeft.x, titleY);

    if (tolerancesAndUOMSummary.length > 0) {
        pdf.setTextColor(tableLabelTextColor);
        pdf.text(tolerancesAndUOMSummary, bottomLeft.x, bottomLeft.y);
    }
}

function addFooter(pdf: jsPDF, pageNumber: number, pageCount: number, info: IAssemblyInfo) {
    const companyColumnWidth = contentWidth / 8;
    const logoPadding = 16;
    const bottomLeft = { x: padding, y: pageHeight - padding };
    const offscreen = document.body.querySelector('#offscreen-container') as HTMLDivElement;
    if (offscreen) {
        const logo = offscreen.querySelector("#corning-secondary-logo") as HTMLImageElement;
        if (logo) {
            const width = companyColumnWidth - logoPadding;
            const height = (width / logo.width) * logo.height;
            const x = bottomLeft.x + (companyColumnWidth - width) * 0.5;
            const y = bottomLeft.y + (headerHeight - height) * 0.5 + 10;
            pdf.addImage(logo, "PNG", x, y, width, height, '', 'FAST')
        }
    }

    pdf.setFontSize(16);
    const pageCountColumnWidth = contentWidth / 12;
    const pageCountColumnX = bottomLeft.x + contentWidth - pageCountColumnWidth;
    const pageCountText = `Sheet ${pageNumber} of ${pageCount}`;
    const pageCountTextDimensions = pdf.getTextDimensions(pageCountText);
    const pageCountX = pageCountColumnX + (pageCountColumnWidth - pageCountTextDimensions.w) * 0.5;
    const pageCountY = bottomLeft.y + pageCountTextDimensions.h * 2;
    pdf.text(pageCountText, pageCountX, pageCountY);

    const titleColumnWidth = contentWidth - companyColumnWidth - pageCountColumnWidth;
    const titleColumnX = bottomLeft.x + companyColumnWidth;
    const title = i18next.t(LocalizationKeys.ReportFooterTitle);
    const titleDimensions = pdf.getTextDimensions(title);
    const titleX = titleColumnX + (titleColumnWidth - titleDimensions.w) * 0.5;
    const titleY = bottomLeft.y + titleDimensions.h * 2;
    pdf.text(title, titleX, titleY);

    if (pageNumber >= 2) {
        const cellWidth = ((pageWidth - padding * 2) / 7) - 1.45;
        const cellHeight = 16 * 2;
        const offsets = { xOffset: 5, yOffset: 10 };
        const footerStartPoints = { x: bottomLeft.x, y: bottomLeft.y - 26 };

        pdf.setDrawColor(0, 0, 0);
        pdf.setFontSize(8);
        const locationPrefix = `${i18next.t(LocalizationKeys.Location)}:`;
        const location = info.location ?? "";
        pdf.rect(footerStartPoints.x, footerStartPoints.y, cellWidth, cellHeight);
        pdf.text(locationPrefix, footerStartPoints.x + offsets.xOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(location, footerStartPoints.x + offsets.xOffset, footerStartPoints.y + offsets.yOffset + 12);

        const drawnByPrefix = `${i18next.t(LocalizationKeys.DrawnBy)}: `;
        const drawnBy = info.drawnBy ?? "";
        pdf.rect(footerStartPoints.x + cellWidth, footerStartPoints.y, cellWidth, cellHeight);
        pdf.text(drawnByPrefix, footerStartPoints.x + cellWidth + offsets.xOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(drawnBy, footerStartPoints.x + cellWidth + offsets.xOffset, footerStartPoints.y + offsets.yOffset + 12);

        const dateCellWidth = cellWidth / 2;
        const dateCellOffset = cellWidth - dateCellWidth;
        const approvalDatePrefix = `${i18next.t(LocalizationKeys.ApprovalDate)}:`;
        const approvalDate = info.approvalDate && info.approvalDate.length > 0 ? info.approvalDate : "";
        pdf.rect(footerStartPoints.x + cellWidth * 2, footerStartPoints.y, dateCellWidth, cellHeight);
        pdf.text(approvalDatePrefix, footerStartPoints.x + cellWidth * 2 + offsets.xOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(approvalDate, footerStartPoints.x + cellWidth * 2 + offsets.xOffset, footerStartPoints.y + offsets.yOffset + 12);

        const inServiceDatePrefix = `${i18next.t(LocalizationKeys.InServiceDate)}:`;
        const inServiceDate = info.inServiceDate && info.inServiceDate.length > 0 ? info.inServiceDate : "";
        pdf.rect(footerStartPoints.x + cellWidth * 3 - dateCellOffset, footerStartPoints.y, dateCellWidth, cellHeight);
        pdf.text(inServiceDatePrefix, footerStartPoints.x + cellWidth * 3 + offsets.xOffset - dateCellOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(inServiceDate, footerStartPoints.x + cellWidth * 3 + offsets.xOffset - dateCellOffset, footerStartPoints.y + offsets.yOffset + 12);

        const revisionPrefix = `${i18next.t(LocalizationKeys.Revision)}:`;
        const revision = info.revision && info.revision.length > 0 ? info.revision : "";
        pdf.rect(footerStartPoints.x + cellWidth * 3, footerStartPoints.y, cellWidth, cellHeight);
        pdf.text(revisionPrefix, footerStartPoints.x + cellWidth * 3 + offsets.xOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(revision, footerStartPoints.x + cellWidth * 3 + offsets.xOffset, footerStartPoints.y + offsets.yOffset + 12);

        const unitsPrefix = `${i18next.t(LocalizationKeys.Units)}:`;
        const units = info.uom && info.uom.length > 0 ? info.uom : "";
        pdf.rect(footerStartPoints.x + cellWidth * 4, footerStartPoints.y, dateCellWidth, cellHeight);
        pdf.text(unitsPrefix, footerStartPoints.x + cellWidth * 4 + offsets.xOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(units, footerStartPoints.x + cellWidth * 4 + offsets.xOffset, footerStartPoints.y + offsets.yOffset + 12);

        const notesPrefix = `${i18next.t(LocalizationKeys.Notes)}:`;
        const notes = info.notes && info.notes.length > 0 ? info.notes : "";
        pdf.rect(footerStartPoints.x + cellWidth * 5 - dateCellOffset, footerStartPoints.y, cellWidth * 2.565, cellHeight);
        pdf.text(notesPrefix, footerStartPoints.x + cellWidth * 5 + offsets.xOffset - dateCellOffset, footerStartPoints.y + offsets.yOffset);
        pdf.text(notes, footerStartPoints.x + cellWidth * 5 + offsets.xOffset - dateCellOffset, footerStartPoints.y + offsets.yOffset + 9);

        pdf.setFontSize(16);
    }
}

async function addAssembly(pdf: jsPDF, document: AssemblyDocument) {
    pdf.addPage(pageFormat, pageOrientation);

    if (document.assembly.assemblyInfo) {
        addHeader(pdf, document.assembly.assemblyInfo);
    }
    const assemblyImage = getComponentImageFromOffscrenContainer(ComponentNames.DocumentGraphicsContainer);
    if (assemblyImage) {
        let { imageWidth, imageHeight } = fitImageToPage(assemblyImage);
        imageHeight *= 0.95;

        const imageX = (pageWidth - imageWidth) * 0.5;
        const imageY = contentY + (contentHeight - imageHeight) * 0.5;
        pdf.addImage(assemblyImage, "PNG", imageX, imageY, imageWidth, imageHeight, '', 'FAST');
    }
}

function fitImageToPage({ width, height }: { width: number, height: number }) {
    let imageWidth = contentWidth;
    let ratio = width / imageWidth;
    let imageHeight = height / ratio;
    if (imageHeight > contentHeight) {
        imageHeight = contentHeight;
        ratio = height / imageHeight;
        imageWidth = width / ratio;
    }
    return { imageWidth, imageHeight }
}

async function addConnectorCloseUp(pdf: jsPDF, assemblyDocument: AssemblyDocument) {
    const { connectorRows } = assemblyDocument;
    const connectorImages = await generateConnectorCloseUps(connectorRows);

    pdf.addPage(pageFormat, pageOrientation);

    const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
    if (offscreen) {
        createConnectorCloseUpTable(pdf, connectorRows, connectorImages);
    }
}

async function generateConnectorCloseUps(connectorRows: IConnectorRowProps[]) {
    const connectorInfo = getConnectorInfoFromRows(connectorRows);

    const canvas = document.createElement('canvas');
    canvas.width = originalImageWidth;
    canvas.height = originalImageHeight;

    const ctx = canvas.getContext('2d')!;

    const SNSideB = connectorRows.flatMap(c => c.sideBConnector).find(c => c.type === SN);
    if (SNSideB) {
        connectorInfo.push({ ...SNSideB, side: Sides.SideB });
    }

    const connectors: IConnectorImageData[] = [];
    for (let i = 0; i < connectorInfo.length; i++) {
        const { type, side, bootColors } = connectorInfo[i];

        const defaultColor = getDefaultConnectorColor(type);

        const svg = isDuplex(type) ? getConnectorSVG(type, defaultColor.hex, side, bootColors).trim() : getConnectorSVG(type, defaultColor.hex, side).trim();

        const image = await Canvg.from(ctx, svg);
        image.start();
        const imageData = canvas.toDataURL('image/png')

        connectors.push({ type, imageData, side });
    }

    return connectors;
}

function getConnectorInfoFromRows(connectorRows: IConnectorRowProps[]) {
    const sideAConnectors = connectorRows.map(m => m.sideAConnector);
    const sideBConnectors = connectorRows.map(m => m.sideBConnector);

    const connectors = sideAConnectors.concat(sideBConnectors);
    return connectors.filter((c, i, self) => self.findIndex(s => s.type === c.type) === i);
}

function extractTextInfo(key: string) {
    const keySplit = key.split('/');
    const textInfo = keySplit[0];

    return textInfo;
}

export const createConnectorCloseUpTable = (pdf: jsPDF, connectorRows: IConnectorRowProps[], connectorImages: IConnectorImageData[]) => {
    const table = getOffscreenTable("#connector-table");
    if (table) {
        autoTable(pdf, {
            html: table,
            tableLineColor: BLACK_RGB,
            tableLineWidth: 0,
            startY: pageContentStart - 20,
            showHead: "everyPage",
            showFoot: "never",
            alternateRowStyles: {
                fillColor: WHITE_RGB,
            },
            headStyles: {
                fillColor: WHITE_SMOKE_RGB,
                fontSize: sectionFontSize,
                fontStyle: "normal",
                halign: "center"
            },
            styles: {
                fontSize: sectionFontSize,
                textColor: BLACK_RGB,
                lineColor: BLACK_RGB,
                lineWidth: 1,
                valign: "middle",
                cellPadding: {
                    left: cellPadding,
                    right: cellPadding,
                    top: cellPadding / 4,
                    bottom: cellPadding / 4
                },
            },
            willDrawCell: (data) => {
                if (data.cell.section === 'body') {
                    data.row.height = imageHeight;
                    data.cell.height = imageHeight;
                }
            },
            didDrawCell: (data) => {
                const columnIndex = data.column.index;
                const rowIndex = data.row.index;

                const side = columnIndex === 0 ? -1 : 1;
                const connectorRow = connectorRows[rowIndex];
                const connector = columnIndex === 1 ? connectorRow.sideBConnector : connectorRow.sideAConnector;

                if (connector.type.length > 0 && connector.color.length > 0) {
                    const connectorType = connector.type;
                    const imageData = connectorImages.find(i => i.type === connectorType && ((connectorType === SN && side === 1) ? i.side === Sides.SideB : !i.side))!.imageData;
                    const textInfo = extractTextInfo(connector.key);

                    const cellCenterX = data.cell.x + (tableWidth / 2);
                    const connectorOffSetY = (columnIndex === 0 ? -25 : 0) - imageHeight / 2.5;

                    const imageX = cellCenterX + (side * (imageWidth * 2));
                    const imageY = data.cell.y + connectorOffSetY;

                    if (data.cell.section === 'body') {
                        const orientation = side * 90;

                        const textOffsetY = (columnIndex === 1 ? 25 : 0);
                        const textInfoY = imageY + imageHeight - 15 - textOffsetY;
                        pdf.text(textInfo, cellCenterX, textInfoY, { align: "center" });

                        pdf.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight, undefined, "FAST", orientation);

                        const initialFontSize = pdf.getFontSize();
                        const connectorTypeY = imageY + imageHeight + 50 - textOffsetY;
                        pdf.setFontSize(initialFontSize / 1.5);
                        pdf.text(connectorType, cellCenterX, connectorTypeY, { align: "center" });
                        pdf.setFontSize(initialFontSize);
                    }
                }
            },
            columnStyles: {
                0: { halign: "center", cellWidth: tableWidth },
                1: { halign: "center", cellWidth: tableWidth }
            },
            margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart - 20 },

        }
        )
    }
}

export function getComponentImageFromOffscrenContainer(componentName: string) {
    const offscreen = document.body.querySelector('#offscreen-container');
    if (offscreen) {
        const componentImages = offscreen.getElementsByClassName(componentName);
        return componentImages[0] as HTMLCanvasElement;
    }
}

export function getOffscreenTable(tableSelector: string) {
    const offscreen = document.body.querySelector<HTMLDivElement>("#offscreen-container");
    let table: HTMLTableElement | null = null;
    if (offscreen) {
        table = offscreen.querySelector<HTMLTableElement>(tableSelector);
    }
    return table;
}

function addBuildPlanPage(pdf: jsPDF, document: AssemblyDocument) {
    const textStart = pageContentStart + 10;

    pdf.addPage(pageFormat, pageOrientation);

    pdf.setFontSize(tableLabelFontSize);
    pdf.setTextColor(tableLabelTextColor);
    pdf.text(i18next.t(LocalizationKeys.BuildPlan), topLeft.x, textStart);

    addBuildPlanSummary(pdf, document);
    addBuildPlanTable(pdf, document);
}

function addBuildPlanSummary(pdf: jsPDF, document: AssemblyDocument) {
    const textStart = pageContentStart + 50;
    const summary = getBuildPlanSummary(document.assembly);
    pdf.text(i18next.t(LocalizationKeys.Summary), topLeft.x, textStart);
    pdf.setFontSize(18);
    pdf.text(summary, topLeft.x + 45, textStart + 25);
}

function addBuildPlanTable(pdf: jsPDF, document: AssemblyDocument) {
    const { assemblyInfo: info } = document.assembly;
    const endADesignation = info && info.endADesignation ? info.endADesignation : "END A";
    const endBDesignation = info && info.endBDesignation ? info.endBDesignation : "END B";

    const table = getOffscreenTable("#build-plan-table");
    if (!table) {
        return;
    }

    const textStart = pageContentStart + 250;
    pdf.text(i18next.t(LocalizationKeys.Table), topLeft.x, textStart);

    const startY = textStart + 25;
    autoTable(pdf, {
        html: table,
        startY,
        theme: "grid",
        styles: {
            fontSize: 12,
            fillColor: tableFillColor,
            textColor: tableTextColor,
            lineColor: tableLineColor,
            lineWidth: tableLineWidth,
            valign: "middle"
        },
        margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart },
        willDrawCell: (data) => {
            const cells = Object.values(data.row.cells)
            const index = cells.findIndex(c => c.text[0] === endADesignation || c.text[0] === endBDesignation);
            if (index !== -1) {
                data.cell.styles.fillColor = WHITE_SMOKE_RGB;
                if (data.cell.text[0] === "0") {
                    data.cell.text[0] = "";
                }
            }

            if (data.cell.text[0].includes("-")) {
                data.cell.text[0] = `\u0009 ${data.cell.text[0]}`;
            }
        }
    });
}

function addAnnotationTable(pdf: jsPDF, notes?: string) {
    const table = getOffscreenTable("#annotation-table");
    if (!table) {
        return;
    }

    const textStart = pageContentStart + 10;

    pdf.addPage(pageFormat, pageOrientation);

    pdf.setFontSize(tableLabelFontSize);
    pdf.setTextColor(tableLabelTextColor);
    pdf.text(i18next.t(LocalizationKeys.Annotations), topLeft.x, textStart);

    const startY = textStart + tablePaddingTop - 30;
    const tableWidth = pageWidth * 0.6 - tableMargin
    autoTable(pdf, {
        html: table,
        startY,
        theme: "grid",
        tableWidth,
        headStyles: {
            fillColor: WHITE_SMOKE_RGB
        },
        styles: {
            fontSize: 12,
            fillColor: tableFillColor,
            textColor: tableTextColor,
            lineColor: tableLineColor,
            lineWidth: tableLineWidth,
            valign: "middle",
        },
        columnStyles: {
            2: { halign: "right" },
        },
        margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart },
        willDrawCell: (data) => {
            if (data.cell.section === "head" && data.column.index === 2) {
                data.cell.styles.halign = "right";
            }
        },
    });

    if (notes && notes.length > 0) {
        pdf.setFontSize(10);
        const x = tableWidth + tableMargin + 10;
        pdf.text(`${i18next.t(LocalizationKeys.Notes)}:`, x, startY + 10);
        const notesWidth = pageWidth * 0.4 - tableMargin;
        const wrappedNotes = pdf.splitTextToSize(notes, notesWidth);
        pdf.text(wrappedNotes, x, startY + 30);

    }
}

function addBreakoutTable(pdf: jsPDF, chips: IChip[], document: AssemblyDocument) {
    const sideABreakoutTable = getOffscreenTable(`#breakout-table-${Sides.SideA}`);
    const sideBBreakoutTable = getOffscreenTable(`#breakout-table-${Sides.SideB}`);
    if (!sideABreakoutTable || !sideBBreakoutTable) {
        return;
    }

    pdf.addPage(pageFormat, pageOrientation);
    pdf.setFontSize(tableLabelFontSize);
    pdf.setTextColor(tableLabelTextColor);

    const currentPage = pdf.internal.pages.length - 1;
    const startY = pageContentStart + 10;
    pdf.text(i18next.t(LocalizationKeys.Breakouts), topLeft.x, startY - 25);

    const maxRows = Math.max(sideABreakoutTable.rows.length - 2, sideBBreakoutTable.rows.length - 2); // Ignoring header rows
    const firstTable = sideABreakoutTable.rows.length > sideBBreakoutTable.rows.length ? sideABreakoutTable : sideBBreakoutTable;
    const firstTableLeftMargin = firstTable === sideABreakoutTable ? tableMargin : pageWidth * 0.5 + tableSpacerWidth * 0.5;
    const firstTableData: ITableData = addSideBreakoutTable(firstTable, pdf, startY, firstTableLeftMargin, maxRows, chips);
    pdf.setPage(currentPage);
    const secondTable = firstTable === sideABreakoutTable ? sideBBreakoutTable : sideABreakoutTable;
    const secondTableLeftMargin = secondTable === sideABreakoutTable ? tableMargin : pageWidth * 0.5 + tableSpacerWidth * 0.5;
    addSideBreakoutTable(secondTable, pdf, startY, secondTableLeftMargin, maxRows, chips, firstTableData);
    const { showToleranceNotes, inch, milimeter } = document
    if (showToleranceNotes) {
        const { min: mmMinTolerance, max: mmMaxTolerance } = milimeter;
        const { min: inMinTolerance, max: inMaxTolerance } = inch;
        pdf.setFontSize(9);
        pdf.text(i18next.t(LocalizationKeys.ReportToleranceNote, { mmMaxTolerance, mmMinTolerance, inMaxTolerance, inMinTolerance }), tableMargin, firstTableData.rows[firstTableData.rows.length - 1].finalY + 20);
    }
    firstTableData.rows = []
}

function addSideBreakoutTable(table: HTMLTableElement, pdf: jsPDF, startY: number, leftMargin: number, maxRows: number, chips: IChip[], firstTableData: ITableData = initialTableData) {
    let currentPageRow: IRowData = initialPageRow
    let secondTableNumRows: number[] = []
    autoTable(pdf, {
        html: table,
        startY: startY + tablePaddingTop - 25,
        tableWidth: tableWidth - tableSpacerWidth * 0.5,
        showHead: "everyPage",
        showFoot: "never",
        pageBreak: "auto",
        alternateRowStyles: {
            fillColor: LIGHT_GREY_RBG
        },
        headStyles: {
            fillColor: WHITE_RGB
        },
        styles: {
            fontSize: tableFontSize,
            fillColor: tableFillColor,
            textColor: tableTextColor,
            lineColor: tableLineColor,
            lineWidth: tableLineWidth,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding * 0.75,
                bottom: cellPadding * 0.75
            },
        },
        columnStyles: {
            0: { halign: "left" },
            1: { halign: "left" },
            2: { halign: "right" },
            3: { halign: "right" },
            4: { halign: "left" },
        },
        margin: { left: leftMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart - 35 },
        willDrawCell: (data) => {
            if (data.cell.section === "head") {
                if (data.row.index === 0) {
                    data.cell.styles.halign = "center";
                }
                if (data.row.index === 1 && (data.column.index === 2 || data.column.index === 3)) {
                    data.cell.styles.halign = "right";
                }
            }
            // TABLE 1
            if (data.cell.section === "body" && (table.rows.length - 2) === maxRows) {
                firstTableData.cellY = data.cell.height
                currentPageRow = updateCurrentPageRow(firstTableData, currentPageRow, data.cursor ? data.cursor.y : -1, data.row.index, data.table.pageNumber)
            }
            // TABLE 2
            if (data.cell.section === "body" && (table.rows.length - 2) < maxRows) {
                const rowHeight = calculateRowHeight(firstTableData, secondTableNumRows, table, data.table.pageNumber)
                data.cell.height = rowHeight;
                data.row.height = rowHeight;
            }
        },
        didParseCell: (data) => {
            if ((data.cell.section === "head" && data.row.index === 1) || data.cell.section === "body") {
                data.cell.styles.fontSize = 9;
            }
        },
        didDrawCell: (data) => {
            if (data.cell.section === "body" && data.column.index === 4) {
                const jacketColor = data.cell.text[0];
                const chipIndex = MTPColors.findIndex(c => c.name === jacketColor);
                if (chipIndex > -1) {
                    const chip = chips[chipIndex];
                    const chipHeight = chipOriginalHeight * 0.75;
                    const chipOffsetY = (data.cell.height - chipHeight) * 0.5;
                    pdf.addImage(chip.imageData, "PNG", data.cell.x + 8, data.cell.y + chipOffsetY, chipOriginalWidth * 0.75, chipHeight, undefined, "FAST");
                }
            }
            // TABLE 2: manual page break
            if (data.cell.section === "body" && (table.rows.length - 2) < maxRows) {
                const firstTableRow = firstTableData.rows.filter(r => r.pageNumber === data.table.pageNumber)[0]
                const numRows = calculateNumRows(table, firstTableData, secondTableNumRows, data.table.pageNumber)
                const currentIndex = data.table.pageNumber === 1 ? (data.row.index + 1) : (data.row.index + 1) - secondTableNumRows.reduce((a, b) => a + b, 0)
                if (currentIndex === numRows && data.column.index === data.table.columns.length - 1) {
                    secondTableNumRows.push(numRows)
                    const row = firstTableData.rows.filter(r => r.pageNumber === data.table.pageNumber)[0]
                    if (data.cursor) {
                        data.cursor.y = row.startY + firstTableRow.finalY
                    }
                }
            }
        },
        didDrawPage: (data) => {
            // TABLE 1: push page row data
            if ((table.rows.length - 2) === maxRows) {
                firstTableData = updateFirstTableData(firstTableData, currentPageRow, data.cursor ? data.cursor.y : -1, data.table.pageNumber, table.rows.length - 2)
            }
        }
    });
    return firstTableData
}

function addLegTable(pdf: jsPDF, chips: IChip[], document: AssemblyDocument) {
    const sideALegTable = getOffscreenTable(`#leg-table-${Sides.SideA}`);
    const sideBLegTable = getOffscreenTable(`#leg-table-${Sides.SideB}`);
    if (!sideALegTable || !sideBLegTable) {
        return;
    }

    pdf.addPage(pageFormat, pageOrientation);
    pdf.setFontSize(tableLabelFontSize);
    pdf.setTextColor(tableLabelTextColor);

    const currentPage = pdf.internal.pages.length - 1;
    const startY = pageContentStart + 10;
    pdf.text(i18next.t(LocalizationKeys.Legs), topLeft.x, startY - 30);

    const maxRows = Math.max(sideALegTable.rows.length - 2, sideBLegTable.rows.length - 2); // Ignoring header rows
    const firstTable = sideALegTable.rows.length > sideBLegTable.rows.length ? sideALegTable : sideBLegTable;
    const firstTableLeftMargin = firstTable === sideALegTable ? tableMargin : pageWidth * 0.5 + tableSpacerWidth * 0.5;
    const firstTableData: ITableData = addSideLegTable(firstTable, pdf, startY, firstTableLeftMargin, maxRows, chips)
    pdf.setPage(currentPage);
    const secondTable = firstTable === sideALegTable ? sideBLegTable : sideALegTable;
    const secondTableLeftMargin = secondTable === sideALegTable ? tableMargin : pageWidth * 0.5 + tableSpacerWidth * 0.5;
    addSideLegTable(secondTable, pdf, startY, secondTableLeftMargin, maxRows, chips, firstTableData)
    const { showToleranceNotes, inch, milimeter } = document;
    if (showToleranceNotes) {
        const { min: mmMinTolerance, max: mmMaxTolerance } = milimeter;
        const { min: inMinTolerance, max: inMaxTolerance } = inch;
        pdf.setFontSize(9);
        pdf.text(i18next.t(LocalizationKeys.ReportToleranceNote, { mmMaxTolerance, mmMinTolerance, inMaxTolerance, inMinTolerance }), tableMargin, firstTableData.rows[firstTableData.rows.length - 1].finalY + 20);
    }
    firstTableData.rows = []
}

function addSideLegTable(table: HTMLTableElement, pdf: jsPDF, startY: number, leftMargin: number, maxRows: number, chips: IChip[], firstTableData: ITableData = initialTableData) {
    let currentPageRow: IRowData = initialPageRow
    let secondTableNumRows: number[] = []
    autoTable(pdf, {
        html: table,
        startY: startY + tablePaddingTop - 25,
        tableWidth: tableWidth - tableSpacerWidth * 0.5,
        showHead: "everyPage",
        showFoot: "lastPage",
        pageBreak: "auto",
        alternateRowStyles: {
            fillColor: LIGHT_GREY_RBG
        },
        headStyles: {
            fillColor: WHITE_RGB
        },
        styles: {
            fontSize: tableFontSize,
            fillColor: tableFillColor,
            textColor: tableTextColor,
            lineColor: tableLineColor,
            lineWidth: tableLineWidth,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding * 0.75,
                bottom: cellPadding * 0.75
            },
        },
        columnStyles: {
            0: { halign: "right" },
            1: { halign: "left" },
            2: { halign: "left" },
            3: { halign: "left", cellWidth: 100 },
            4: { halign: "left", cellWidth: 100 },
            5: { halign: "right" },
            6: { halign: "right" },
            7: { halign: "left", cellWidth: 100 },
        },
        margin: { left: leftMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart - 35 },
        willDrawCell: (data) => {
            if (data.cell.section === "head") {
                if (data.row.index === 0) {
                    data.cell.styles.halign = "center";
                }
                if (data.row.index === 1 && (data.column.index === 5 || data.column.index === 6)) {
                    data.cell.styles.halign = "right";
                }
            }
            // TABLE 1 
            if (data.cell.section === "body" && (table.rows.length - 2) === maxRows) {
                firstTableData.cellY = data.cell.height
                currentPageRow = updateCurrentPageRow(firstTableData, currentPageRow, data.cursor ? data.cursor.y : -1, data.row.index, data.table.pageNumber)
            }
            // TABLE 2
            if (data.cell.section === "body" && (table.rows.length - 2) < maxRows && data.table.pageNumber <= firstTableData.totalPages) {
                const rowHeight = calculateRowHeight(firstTableData, secondTableNumRows, table, data.table.pageNumber)
                data.cell.height = rowHeight;
                data.row.height = rowHeight;
            }
        },
        didParseCell: (data) => {
            if ((data.cell.section === "head" && data.row.index === 1) || data.cell.section === "body") {
                data.cell.styles.fontSize = 9;
            }
        },
        didDrawCell: (data) => {
            if (data.cell.section === "body" && (data.column.index === 3 || data.column.index === 7)) {
                const color = data.cell.text[0];
                const chipIndex = MTPColors.findIndex(c => c.name === color);
                if (chipIndex > -1) {
                    const chip = chips[chipIndex];
                    const chipHeight = chipOriginalHeight * 0.75;
                    const chipOffsetY = (data.cell.height - chipHeight) * 0.5;
                    pdf.addImage(chip.imageData, "PNG", data.cell.x + 8, data.cell.y + chipOffsetY, chipOriginalWidth * 0.75, chipHeight, undefined, "FAST");
                    data.cell.text = [];
                }
            }
            // TABLE 2: manual page break
            if (data.cell.section === "body" && (table.rows.length - 2) < maxRows && data.table.pageNumber <= firstTableData.totalPages) {
                const firstTableRow = firstTableData.rows.filter(r => r.pageNumber === data.table.pageNumber)[0]
                const numRows = calculateNumRows(table, firstTableData, secondTableNumRows, data.table.pageNumber)
                const currentIndex = data.table.pageNumber === 1 ? (data.row.index + 1) : (data.row.index + 1) - secondTableNumRows.reduce((a, b) => a + b, 0)
                if (currentIndex === numRows && data.column.index === data.table.columns.length - 1) {
                    secondTableNumRows.push(numRows)
                    const row = firstTableData.rows.filter(r => r.pageNumber === data.table.pageNumber)[0]
                    if (data.cursor) {
                        data.cursor.y = row.startY + firstTableRow.finalY
                    }
                }
            }
        },
        didDrawPage: (data) => {
            // TABLE 1: push page row data
            if ((table.rows.length - 2) === maxRows) {
                firstTableData = updateFirstTableData(firstTableData, currentPageRow, data.cursor ? data.cursor.y : -1, data.table.pageNumber, table.rows.length - 2)
            }
        }
    });
    return firstTableData
}

function addPolarityTable(pdf: jsPDF, chips: IChip[]) {
    const table = getOffscreenTable("#fiber-map-table");
    if (!table) {
        return;
    }

    const startY = pageContentStart + 10;

    pdf.addPage(pageFormat, pageOrientation);

    pdf.setFontSize(tableLabelFontSize);
    pdf.setTextColor(tableLabelTextColor);
    pdf.text(i18next.t(LocalizationKeys.Polarity), topLeft.x, startY);

    autoTable(pdf, {
        html: table,
        startY: startY + tablePaddingTop,
        tableWidth: pageWidth - tableMargin * 2,
        showHead: "everyPage",
        showFoot: "lastPage",
        pageBreak: "auto",
        alternateRowStyles: {
            fillColor: LIGHT_GREY_RBG
        },
        headStyles: {
            fillColor: WHITE_RGB
        },
        styles: {
            fontSize: tableFontSize,
            fillColor: tableFillColor,
            textColor: tableTextColor,
            lineColor: tableLineColor,
            lineWidth: tableLineWidth,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding * 0.75,
                bottom: cellPadding * 0.75
            },
        },
        columnStyles: {
            0: { halign: "left" },
            1: { halign: "left", cellWidth: 100 },
            2: { halign: "right" },
            3: { halign: "left" },
            4: { halign: "left" },
            5: { halign: "left" },
            6: { halign: "center" },
            7: { halign: "left" },
            8: { halign: "left" },
            9: { halign: "left" },
            10: { halign: "right" },
            11: { halign: "left", cellWidth: 100 },
            12: { halign: "left" }
        },
        margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: pageContentStart + 35 },
        willDrawCell: (data) => {
            if ((data.cell.section === "head" && data.row.index === 0) || data.column.index === 6) {
                data.cell.styles.halign = "center";
            }
        },
        didParseCell: (data) => {
            if ((data.cell.section === "head" && data.row.index === 1) || data.cell.section === "body") {
                data.cell.styles.fontSize = 9;
            }
            if (data.column.index === 6) {
                data.cell.styles.fillColor = DARK_GREY_RBG;
            }
        },
        didDrawCell: (data) => {
            if (data.cell.section === "body" && (data.column.index === 1 || data.column.index === 11)) {
                const color = data.cell.text[0];
                const chipIndex = FiberColors.findIndex(c => c.name === color);
                if (chipIndex > -1) {
                    const chip = chips[chipIndex];
                    const chipHeight = chipOriginalHeight * 0.75;
                    const chipOffsetY = (data.cell.height - chipHeight) * 0.5;
                    pdf.addImage(chip.imageData, "PNG", data.cell.x + 8, data.cell.y + chipOffsetY, chipOriginalWidth * 0.75, chipHeight, undefined, "FAST");
                    data.cell.text = [];
                }
            }
        },
    });
}

function addPolarityDiagrams(pdf: jsPDF, doc: AssemblyDocument) {
    if (doc.assembly.polarity && doc.assembly.polarity.connectorAssignments.length > 0) {
        const connectorAssignments = doc.assembly.polarity.connectorAssignments;

        pdf.addPage(pageFormat, pageOrientation);

        const cell = { width: 75, height: 20, padding: 5 };
        const fontSize = cell.height * 0.75;
        const color = "black";
        pdf.setFontSize(fontSize);
        pdf.setTextColor(color);

        const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
        const container = offscreen.querySelector("#polarity-container") as HTMLDivElement;
        if (container) {
            const images = Array.from(container.getElementsByTagName("img"));

            let startY = pageContentStart + 10;
            const mapping = getPolarityMapping(connectorAssignments);
            for (const { key, index, name, sideABreakouts, sideBBreakouts } of mapping) {
                const maxBreakouts = Math.max(sideABreakouts.length, sideBBreakouts.length);
                const maxImageHeight = 125;
                const maxHeight = Math.max(maxBreakouts * (fontSize + cell.padding * 2), maxImageHeight);

                // HEADER
                let rowWitdh = cell.width * 2;
                pdf.setDrawColor(color);
                pdf.rect(topLeft.x, startY, rowWitdh, cell.height);
                pdf.rect(topLeft.x + rowWitdh, startY, contentWidth - rowWitdh, cell.height);
                pdf.text(i18next.t(LocalizationKeys.Breakouts), topLeft.x + cell.padding, startY + fontSize);
                pdf.text(name, topLeft.x + cell.padding + + rowWitdh, startY + fontSize);
                startY += cell.height;

                // SIDE COLUMNS
                rowWitdh = cell.width;
                pdf.rect(topLeft.x, startY, rowWitdh, cell.height);
                pdf.rect(topLeft.x + rowWitdh, startY, rowWitdh, cell.height);
                pdf.text("END A", topLeft.x + cell.padding, startY + fontSize);
                pdf.text("END B", topLeft.x + cell.padding + rowWitdh, startY + fontSize);
                startY += cell.height;

                let rowHeight = maxHeight - cell.height;
                pdf.rect(topLeft.x, startY, rowWitdh, rowHeight);
                pdf.rect(topLeft.x + rowWitdh, startY, rowWitdh, rowHeight);

                rowWitdh += cell.width;
                rowHeight = maxHeight;
                pdf.rect(topLeft.x + rowWitdh, startY - cell.height, contentWidth - rowWitdh, rowHeight);

                rowWitdh = cell.width;
                const sideAString = sideABreakouts.join("\n");
                const sideBString = sideBBreakouts.join("\n");
                pdf.text(sideAString, topLeft.x + cell.padding, startY + fontSize, { lineHeightFactor: 1 });
                pdf.text(sideBString, topLeft.x + cell.padding + rowWitdh, startY + fontSize, { lineHeightFactor: 1 });

                // IMAGE COLUMN
                const image = images.find(i => i.id === key);
                if (image) {
                    const maxImageWidth = contentWidth - cell.width * 2;

                    // Scale image
                    let imageWidth = image.width;
                    let imageHeight = image.height;
                    if (imageHeight > maxImageHeight) {
                        // Scale down the image to fit
                        const scale = maxImageHeight / imageHeight;
                        imageHeight *= scale;
                        imageWidth *= scale;
                    }

                    if (imageWidth > maxImageWidth) {
                        const scale = maxImageWidth / imageWidth;
                        imageHeight *= scale;
                        imageWidth *= scale;
                    }

                    imageHeight -= cell.padding;

                    const x = topLeft.x + cell.width * 2 + cell.padding;
                    const y = (startY - cell.height) + (maxHeight - imageHeight) * 0.5;
                    pdf.addImage(image, x, y, imageWidth, imageHeight);
                } else {
                    const x = topLeft.x + cell.width * 2 + cell.padding;
                    const y = (startY - cell.height * 0.5) + (maxHeight - fontSize) * 0.5;
                    pdf.text(i18next.t(LocalizationKeys.PolarityNoDiagram), x, y);
                }

                // Change page if height of table exceeds available page space
                startY += rowHeight;
                if (startY + rowHeight >= pageHeight - padding - boxHeight * 2 && index !== mapping.length - 1) {
                    pdf.addPage(pageFormat, pageOrientation);
                    startY = pageContentStart + 10;
                }
            }
        }
    }
}

const getPolarityMapping = (connectorAssignments: IConnectorAssignmentMap[]) => {
    const mapping: { [key: string | number]: { name: string, sideABreakouts: string[], sideBBreakouts: string[] } } = {};
    for (const { fiberMapData, connectors } of connectorAssignments) {
        const { key: polarityKey, name } = fiberMapData;
        const { sideAMapping, sideBMapping } = connectors;
        const sideABreakouts = Array.from(new Set([...sideAMapping.map(c => `A${c.breakoutPosition}`)]));
        const sideBBreakouts = Array.from(new Set([...sideBMapping.map(c => `B${c.breakoutPosition}`)]));
        const key = `${polarityKey}` === `${CUSTOM_MAP_KEY}` ? name : polarityKey;
        if (mapping[key]) {
            mapping[key].sideABreakouts = Array.from(new Set([...mapping[key].sideABreakouts, ...sideABreakouts]));
            mapping[key].sideBBreakouts = Array.from(new Set([...mapping[key].sideBBreakouts, ...sideBBreakouts]));
        } else {
            mapping[key] = { name, sideABreakouts, sideBBreakouts }
        }
    }

    return Object.keys(mapping).map((key, index) => ({ key: `polarity-image-${key}`, index, ...mapping[key] }));
}

const getTolerancesAndUOMSummary = (info: IAssemblyInfo) => {
    const { t } = i18next;
    const uom = info.uom && info.tolerance ? `UOM: ${info.uom} | ${t(LocalizationKeys.ReportTolerancesValue, { tolerance: info.trunkTolerance })}` : undefined;

    const isMetric = info.uom ? info.uom === "Millimeter" : false;
    const defaultToleranceValues = info.type ? getDefaultToleranceValues(info.type) : defaultTolerances;
    const legToleranceMin = isMetric ? defaultToleranceValues.milimeter.min : defaultToleranceValues.inch.min;
    const legToleranceMax = isMetric ? defaultToleranceValues.milimeter.max : defaultToleranceValues.inch.max;
    const labelToleranceMin = isMetric ? defaultLabelTolerances.millimeter.min : defaultLabelTolerances.inch.min;
    const labelToleranceMax = isMetric ? defaultLabelTolerances.millimeter.max : defaultLabelTolerances.inch.max;

    const subunits = t(LocalizationKeys.ReportSubunitsTolerance, { min: legToleranceMin, max: legToleranceMax });
    const legs = t(LocalizationKeys.ReportLegsTolerance, { min: legToleranceMin, max: legToleranceMax });
    const labels = t(LocalizationKeys.ReportLabelTolerance, { min: labelToleranceMin, max: labelToleranceMax });

    const hasCustomTolerances = assemblyHasCustomTolerances(info);
    const customTolerances = hasCustomTolerances ? t(LocalizationKeys.ReportCustomTolerance) : undefined;

    const pullingGrip = info.pullingGrip && info.pullingGrip.length > 0 && info.pullingGrip !== PullingGrips.None ? ` | ${i18next.t(LocalizationKeys.PullingGrip)}: ${info.pullingGrip}` : "";

    const summary = [uom, subunits, legs, labels, customTolerances].filter(s => s?.length).join("; ") + pullingGrip;
    return summary;
}

const getBuildPlanSummary = (assembly: IAssembly) => {
    const { t } = i18next;
    const { assemblyInfo: info } = assembly;

    let fibercount: string | undefined = undefined;
    let layout: string | undefined = undefined;
    if (assembly.sideA && assembly.sideB) {
        const { fiberCount: sideAFiberCount } = assembly.sideA;
        const { fiberCount: sideBFiberCount } = assembly.sideB;
        const { fiberCount: assemblyFiberCount } = assembly;
        const endADesignation = info && info.endADesignation ? info.endADesignation : "EndA";
        const endADescription = `${t(LocalizationKeys.FiberCount)} ${endADesignation}`;
        const endBDesignation = info && info.endBDesignation ? info.endBDesignation : "EndB"
        const endBDescription = `${t(LocalizationKeys.FiberCount)} ${endBDesignation}`;
        const fiberCountLabel = `${endADescription}/${t(LocalizationKeys.Target)}/${endBDescription}`;
        fibercount = assembly.sideA.fiberCount && assembly.sideB.fiberCount
            ? `${fiberCountLabel}: ${sideAFiberCount}/${assemblyFiberCount}/${sideBFiberCount}`
            : fibercount;

        const layoutString = `${assembly.sideA.breakouts.length}x${assembly.sideB.breakouts.length}`;
        layout = `${t(LocalizationKeys.Layout)}: ${layoutString}`;
    }

    const flameRating = info && info.flameRating ? `${t(LocalizationKeys.FlameRating)}: ${info.flameRating}` : undefined;
    const pullingGrip = info && info.pullingGrip ? `${t(LocalizationKeys.PullingGrip)}: ${info.pullingGrip}` : undefined;
    const cableType = info && info.type ? `${t(LocalizationKeys.CableType)}: ${info.type}` : undefined;
    const fiberType = info && info.fiberType ? `${t(LocalizationKeys.Mode)}: ${info.fiberType}` : undefined;
    const outerDiameter = info && info.furcationOuterDiameter && info.furcationOuterDiameter !== FurcationOuterDiameters[0] ? `${t(LocalizationKeys.FurcationOD)}: ${info.furcationOuterDiameter}` : undefined;

    let overallLength: string | undefined = undefined;
    if (assembly.overallLength) {
        const isImperial = info && info.uom === "Inches";
        const { value, unit } = isImperial ? convertTo(assembly.overallLength, Units.Inches) : assembly.overallLength;
        overallLength = `${t(LocalizationKeys.OverallLength)}: ${(Math.round(value * 10) / 10)} ${unit}`;
    }

    return [fibercount, flameRating, pullingGrip, cableType, fiberType, outerDiameter, overallLength, layout]
        .filter(s => s?.length)
        .map(f => `\u2022     ${f}\n`).reduce((a, b) => a + b, "");
}

const getFiberSummary = (info: IAssemblyInfo) => {
    const { t } = i18next;
    const fibercount = info.fiberCount ? `${t(LocalizationKeys.FiberCount)}: ${info.fiberCount}` : undefined;
    const flameRating = info.flameRating ? `${t(LocalizationKeys.FlameRating)}: ${info.flameRating}` : info.flameRating;
    const cableType = info.type && !isLegacyCableType(info.type) ? `${t(LocalizationKeys.CableType)}: ${info.type}` : undefined;

    const fiberType = getFiberType(info);
    const mode = fiberType ? `${t(LocalizationKeys.Mode)}: ${fiberType}` : fiberType;

    const outerDiameter = info && info.furcationOuterDiameter && info.furcationOuterDiameter !== FurcationOuterDiameters[0] ? `${t(LocalizationKeys.FurcationOD)}: ${info.furcationOuterDiameter}` : undefined;

    return [fibercount, flameRating, cableType, mode, outerDiameter].filter(s => s?.length).join(" | ");
}

const isLegacyCableType = (type: CableType | undefined): boolean => {
    return type ? (type === CableTypes.SingleMode || type === CableTypes.MultiMode) : false;
}

const getFiberType = (info: IAssemblyInfo): FiberType | undefined => {
    return info.fiberType ? info.fiberType : getLegacyFiberType(info.type);
};

const getLegacyFiberType = (type: CableType | undefined): FiberType | undefined => {
    if (type) {
        switch (type) {
            case CableTypes.SingleMode:
                return FiberTypes.SMOS2;
            case CableTypes.MultiMode:
                return FiberTypes.MMOM3;
        }
    }
}

function getDateString(dateString: string) {
    if (!dateString.length) return "";
    const date = new Date(dateString.replaceAll('-', '/'));
    const month = String(date.getMonth() + 1).padStart(2, '0');;
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}