import { ITolerance } from "../../../pixi/markers/tolerance/types";
import { IUserProvidedImage } from "../../report/document/types";
import { CableEnvironment, CableType, FiberType } from "../types";

export interface IAssemblyInfo {
    id?: number;
    assemblyId?: number;
    name?: string;
    description?: string;
    partNumber?: string;
    lastModified?: string;
    type?: CableType;
    fiberType?: FiberType;
    environment?: CableEnvironment;
    flameRating?: string;
    fiberCount?: number;
    uom?: string;
    tolerance?: ITolerance;
    trunkTolerance?: number;
    legTolerance?: ITolerance;
    legPrimeTolerance?: ITolerance;
    legLabelTolerance?: ITolerance;
    pullingGrip?: string;
    endADesignation?: string;
    endBDesignation?: string;
    endADescription?: string;
    endBDescription?: string;
    polaritySortType?: string;
    furcationOuterDiameter?: string;
    drawingNumber?: string;
    application?: string;
    approvalDate?: string;
    inServiceDate?: string;
    revision?: string;
    headerTitle?: string;
    drawingTitle?: string;
    location?: string;
    drawnBy?: string;
    notes?: string;
    buildPlan?: boolean;
    polarityDiagram?: boolean;
    connectorViews?: boolean;
    showCableOverview?: boolean;
    userProvidedImages?: IUserProvidedImage[];
    showA0B0?: boolean;
    showLabelDistance?: boolean;
    showPullingGrip?: boolean;
}

export const getDefaultDescription = (fiberType: FiberType, fiberCount: number) => {
    return `${fiberCount} FIBER ${fiberType} CABLE`;
}