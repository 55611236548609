import React from 'react';
import { withDrawerRow } from '../../../ui/drawer/drawer-row/DrawerRow';
import { AssemblyInfoRowProps } from './types';
import { MainPalettes, MainThemeTokens, Typography } from '@orbit/theme-provider';
import { IconButton, TooltipPlacement } from '@orbit/icon-button';
import { CloudDownload } from '@mui/icons-material';
import { AssemblyInfo } from './assembly-info/AssemblyInfo';
import { AssemblyInfoToolBar } from './toolbar/AssemblyInfoToolBar';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';

import "./AssemblyInfoRow.scss";

export const AssemblyInfoRow = withDrawerRow((props: AssemblyInfoRowProps) => {
    const { t } = useTranslation();
    const loadProps = {
        className: "load-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.LoadCable),
        placement: "top" as TooltipPlacement,
        onClick: props.onLoadClick
    };
    return (
        <>
            <div className="row">
                <div className="row-start">
                    <IconButton {...loadProps} icon={<CloudDownload />} />
                </div>
                <div className="row-middle">
                    <AssemblyInfo {...props} />
                </div>
                <div className="row-end">
                    <AssemblyInfoToolBar onDuplicate={props.onDuplicateClick} onEdit={props.onEditClick} onDelete={props.onDeleteClick} />
                    <div className="assembly-date">
                        <Typography variant="body2" label={props.lastModified ?? ""} />
                    </div>
                </div>
            </div>
        </>
    )
})
