import { ConnectorSpriteProps } from './types';
import { useApp, _ReactPixi } from '@inlet/react-pixi';
import { useContext, useMemo } from 'react';
import { LC_DUPLEX_KEY, LSH_KEY, SC_DUPLEX_KEY, SN_KEY } from '../../../../../workspace/assembly/connector/types';
import { hexNumberToString, White } from '../../../../../ui/dialog/color/types';
import { JacketColors } from '../../../../../workspace/assembly/palette/types';
import { SideContext } from '../../../side/types';

export const useConnectorSprite = (props: ConnectorSpriteProps) => {
    const { color, jacketColor: spriteJacketColor, type, bootColors } = props;
    const { m } = useContext(SideContext)
    const app = useApp();

    const textureKey = useMemo(() => {
        if (type === LC_DUPLEX_KEY || type === SC_DUPLEX_KEY) {
            const jacketColorString = hexNumberToString(spriteJacketColor);
            const jacketColor = JacketColors.find(c => c.hex === jacketColorString.toUpperCase());
            if (jacketColor) {
                if (bootColors && bootColors.bootAColor && bootColors.bootBColor){
                    return `${type}_${color.name}_${jacketColor.name}Jacket_${bootColors.bootAColor.name}A_${bootColors.bootBColor.name}B`;
                }
                return `${type}_${color.name}_${jacketColor.name}Jacket_${White.name}A_${White.name}B`;
            }
        }
        if (type === SN_KEY && m > 0) {
            return `${type}B_${color.name}`;
        }
        return `${type}_${color.name}`;
    }, [type, spriteJacketColor, color.name, m, bootColors]);

    const scale = useMemo(() => {
        if (type === LSH_KEY && m > 0) {
            return { x: -0.05, y: 0.05 }
        }
        return { x: 0.05, y: 0.05 };
    }, [type, m]);
    const anchor: _ReactPixi.PointLike = useMemo(() => {
        if (type === LSH_KEY && m > 0) {
            return [1.9, 0];
        }
        return [-0.5, 0];
    }, [type, m]);
    
    const texture = app.loader.resources[textureKey]?.texture;

    if (texture === undefined) {
        console.warn(`Texture '${textureKey}' was not found in the loader resources.`)
    }


    return { scale, anchor, texture };
}