import { BoundingBox } from "../bounding-box/types";
import * as Pixi from 'pixi.js';
import { Side } from "../../../workspace/assembly/breakout/types";
import { createContext } from "react";
import { IName } from "../../cable/types";

interface ISelectionProps {
    mouseover: (e: Pixi.InteractionEvent) => void,
    mouseout: (e: Pixi.InteractionEvent) => void,
    mouseup: (e: Pixi.InteractionEvent) => void,
    mousedown: (e: Pixi.InteractionEvent) => void,
    mouseupoutside: (e: Pixi.InteractionEvent) => void,
}

export type SelectionProps<T> = T & BoundingBox & Partial<ISelectionProps> & Partial<IName>

export interface ISelectionState {
    selectedBreakout?: { side: Side, position: number }
}

export const initialSelectionState: ISelectionState = {

}

export interface ISelectionContext {
    state: ISelectionState,
    dispatch: React.Dispatch<any>
}

export const initialSelectionContext: ISelectionContext = {
    state: {},
    dispatch: (state) => { }
}

export const SelectionContext = createContext(initialSelectionContext)

