import { createContext, Dispatch } from "react";
import { AnyAction } from "redux";
import { Side, Sides } from "../../assembly/breakout/types";
import { IConnectorAssignmentMap } from "../connector-assignment/reducer/types";

export interface INavigationInfo {
    side: Side,
    breakoutPosition: number;
    connectorPosition: number;
}

export interface ILocalPolarityState {
    initialNavigation: INavigationInfo;
    connectorAssignment: IConnectorAssignmentMap;
    connectorAssignmentToUpdate?: IConnectorAssignmentMap;
}

export const initialLocalNavigation: INavigationInfo = {
    side: Sides.SideA,
    breakoutPosition: -1,
    connectorPosition: -1
}

export const initialLocalConnectorAssignment: IConnectorAssignmentMap = {
    polarityKey: -1,
    fiberMapData: {
        key: -1,
        name: "",
        sourceIndices: [],
        destinationIndices: []
    },
    connectors: {
        sideAMapping: [],
        sideBMapping: []
    }
}

export const initialLocalPolarityState: ILocalPolarityState = {
    initialNavigation: initialLocalNavigation,
    connectorAssignment: initialLocalConnectorAssignment
}

export interface IPolarityContext {
    state: ILocalPolarityState;
    dispatch: Dispatch<AnyAction>;
}

export const initialPolarityContext: IPolarityContext = {
    state: initialLocalPolarityState,
    dispatch: () => {}
}

export const PolarityContext = createContext(initialPolarityContext);