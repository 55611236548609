import React from 'react';
import { useUnitAction } from './hooks';
import { MetricIcon } from '../../../../ui/icon/MetricIcon';
import { ImperialIcon } from '../../../../ui/icon/ImperialIcon';
import { IconButton } from '@orbit/icon-button';

export const UnitAction: React.FC = () => {
    const { unitProps, isMetricUnit } = useUnitAction();
    const unitIcon = isMetricUnit ? <MetricIcon /> : <ImperialIcon />;
    return <IconButton {...unitProps} icon={unitIcon} />
}