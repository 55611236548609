import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { ConnectorRow } from "./ConnectorRow";
import { useConnectorTable } from "./hooks";

export const ConnectorTable: React.FC = () => {
    const { connectorRows: rows } = useConnectorTable();
    const { t } = useTranslation();

    return (
        <div id="connector-table-container">
            <table id="connector-table">
                <thead>
                    <tr>
                        <th>{t(LocalizationKeys.EndLabel, { side: "A" })}</th>
                        <th>{t(LocalizationKeys.EndLabel, { side: "B" })}</th>
                    </tr>
                </thead>
                <tbody>
                    { rows.map((row, i) => <ConnectorRow key={`row${i}`} {...row} />) }
                </tbody>
            </table>
        </div>
    )
}