import { Unit, Units } from "../types";

export interface IUnitState {
    display: boolean,
    unit: Unit,
    secondaryUnit: Unit,
}

export const initialWorkspaceUnit: IUnitState = {
    display: false,
    unit: Units.Millimeter,
    secondaryUnit: Units.Meter,
}