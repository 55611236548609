import { IPosition } from "../../cable/types";
import { IToleranceProps } from "../tolerance/types";
import { IDrawingProps, Orientation, Orientations, Unidirection } from "../types";

export const LineMarkerContainer = "line-marker-container";

export interface ILineMarkerProps {
    coordinate: IPosition;
    orientation: Orientation;
    length: number;
    startDelimiterLength: number;
    endDelimiterLength: number;
    text: string;
    unidirection?: Unidirection;
    toleranceProps?: IToleranceProps;
    isTrunkMarker?: Boolean;
}

export type LineMarkerProps = ILineMarkerProps & IDrawingProps;

export const initialLineMarkerProps: LineMarkerProps = {
    coordinate: { x: 0, y: 0 },
    orientation: Orientations.Horizontal,
    length: 0,
    text: "",
    thickness: 1,
    color: 0x000000,
    alpha: 1,
    startDelimiterLength : 0,
    endDelimiterLength: 0,
};