import { createSelector } from "@reduxjs/toolkit"
import i18n from "../../../../i18n";
import { localizeUnit } from "../../../../localization/types";
import { rootSelector } from "../../../../store/selectors";
import { convertTo, Length } from "../types";

export const unitStateSelector = createSelector(
    rootSelector,
    root => root.unit
)

export const displayConverterSelector = createSelector(
    unitStateSelector,
    state => state.display
);

export const unitSelector = createSelector(
    unitStateSelector,
    state => { return { unit: state.unit, secondaryUnit: state.secondaryUnit } }
);

export const unitLabelSelectorFactory = (label: string|undefined, isPrimary: boolean = true) => {
    return createSelector(
        unitSelector,
        contextUnit => {
            if (!label) {
                return "";
            }
            const displayUnit = isPrimary ? contextUnit.unit : contextUnit.secondaryUnit;
            return `${label} (${i18n.t(localizeUnit(displayUnit)).toLocaleLowerCase()})`
        }
    )
}

export const displayLengthSelectorFactory = (length: Length, isPrimary: boolean = true) => {
    return createSelector(
        unitSelector,
        contextUnit => {
            return isPrimary ? convertTo(length, contextUnit.unit) : convertTo(length, contextUnit.secondaryUnit);
        }
    )
}