import { IApiResult } from "../../../services/api-result";
import { SecuredService } from "../../../services/secured-service";
import { IAssembly } from "../../assembly/types";
import { ToAssemblyRequest } from "../assembly/assembly-service";
import { IProjectAssemblyResponse, IProjectManagerResponse } from "./types";

export class ProjectManagerService extends SecuredService {
    private readonly baseURL = 'api/pm';

    public async getAssembly(assemblyId: number): Promise<IApiResult<IAssembly>> {
        return await this.get(`${this.baseURL}/assembly/${assemblyId}`);
    }

    public async getProjectAssembly(userId: number): Promise<IApiResult<IProjectAssemblyResponse>> {
        return await this.get(`${this.baseURL}/user/${userId}/assembly`);
    }

    public async addAssembly(assembly: IAssembly, userId: number): Promise<IApiResult<IProjectAssemblyResponse>> {
        const request = ToAssemblyRequest(assembly, userId);
        return await this.post(`${this.baseURL}/assembly`, request);
    }

    public async duplicateAssembly(assemblyId: number, userId: number, name: string): Promise<IApiResult<IProjectAssemblyResponse>> {
        return await this.post(this.baseURL + '/duplicate', { assemblyId, userId, name });
    }

    public async updateProjectManager(projectManager: IProjectManagerResponse ): Promise<IApiResult<IProjectManagerResponse>> {
        return this.put(this.baseURL, projectManager);
    }

    public async updateProjectSortOptions(userId: number, sortType: string, sortAscending: boolean): Promise<IApiResult<IProjectManagerResponse>> {
        return this.post(`${this.baseURL}/sortOptions`, { userId, sortType, sortAscending });
    }

    public async updateTermsAndConditions(userId: number, date: string, version: string): Promise<IApiResult<IProjectManagerResponse>> {
        return this.post(`${this.baseURL}/tnc`, { userId, date, version });
    }

    public async deleteProjectAssembly(assemblyId: number, userId: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseURL}/user/${userId}/assembly/${assemblyId}`);
    }
}

export const projectManagerService = new ProjectManagerService();