import { ButtonProps } from "@orbit/button";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { setViewportFitOption } from "../../../pixi/viewport/store/reducer";
import { useStoreDispatch } from "../../../store/hooks";
import { timeout } from "../../report/document/hooks";
import { showAssemblyWizardSelector } from "../../store/selectors";
import { FitOptions } from "./types"

export const FitOptionValues = Object.values(FitOptions)
export const useFitPanel = () => {
    const showAssemblyWizard = useSelector(showAssemblyWizardSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const options = FitOptionValues.map(f => { return { value: f, label: t(f) } });
    
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [selectedFitOption, setSelectedFitOption] = useState(options[0]);

    const resetPosition = useCallback(async () => {
        if (showAssemblyWizard) {
            await timeout(1); // The canvas extractor needs the updated dimensions of the new cable
            storeDispatch(setViewportFitOption(FitOptions.FitToWidth));
        }
    }, [showAssemblyWizard, storeDispatch]);

    useEffect(() => {
        resetPosition();
    }, [resetPosition]);

    const setFitOption = useCallback(() => {
        storeDispatch(setViewportFitOption(selectedFitOption.value))
    }, [selectedFitOption, storeDispatch]);

    const fitIconProps = {
        className: "panel-item",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.PageResize),
        placement: "top" as TooltipPlacement,
        onClick: setFitOption
    };

    const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    }, []);

    const fitButtonProps: ButtonProps = {
        className: "fit-panel-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: openMenu
    };

    const fitPanel = {
        buttonProps: fitButtonProps,
        label: selectedFitOption.label
    };

    const onMenuClose = useCallback(() => {
        setOpen(false);
        setAnchorEl(null);
    }, []);

    const menuProps = {
        open,
        className: "fit-menu",
        anchorEl,
        onClose: onMenuClose,
    };

    const onSelect = useCallback((fitOption: any) => {
        setSelectedFitOption(fitOption)
        storeDispatch(setViewportFitOption(fitOption.value))
    }, [storeDispatch]);

    const fitOptions = options.map(o => {
        return {
            buttonProps: {
                className: `${o.label}`,
                palette: MainPalettes.primary,
                token: MainThemeTokens.main,
                onClick: () => onSelect(o)
            },
            label: o.label
        }
    });

    return { fitIconProps, fitPanel, menuProps, fitOptions }
}