import { ButtonProps } from "@orbit/button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, batch } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { IPolarityRowProps } from "./polarity-row/types";
import { IConnectorAssignmentMap } from "../connector-assignment/reducer/types";
import { IInputDialogProps } from "../../../ui/dialog/input/types";
import { addOrUpdateUserFiberMap } from "../../assembly/store/reducer";
import { currentAssemblyPolarityConfigurationsSelector, currentConnectorAssignmentsSelector } from "../../assembly/store/selectors";
import { updateMultipleConnectorAssignments } from "../../assembly/store/reducer";
import { deleteUserFiberMap, showPolaritySchemes } from "../store/reducer";
import { polarityMapsSelector, showPolaritySchemesSelector, userFiberMapsSelector } from "../store/selectors";
import { CUSTOM_MAP_KEY } from "../store/types";
import { IPolarityConfigurationProps, IPolaritySchemeProps } from "./types";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { InputFieldValue } from "../../../ui/input/types";
import { validateInputChars } from "../../assembly/breakout/details/row/hooks";
import { useStoreDispatch } from "../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const usePolaritySchemeDialog = () => {
    const { dialogProps } = useDialog();
    const { selectedConfiguration, polarityConfigurationButtons } = usePolarityConfigurationButtons();
    const { selectedScheme, editInputDialogProps, deleteDialogProps, polaritySchemes, polaritySchemesRowProps } = usePolaritySchemes(selectedConfiguration);
    
    return { dialogProps, polarityConfigurationButtons, selectedScheme, selectedConfiguration, editInputDialogProps, deleteDialogProps, polaritySchemes, polaritySchemesRowProps };
}

const useDialog = () => {
    const open = useSelector(showPolaritySchemesSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showPolaritySchemes(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "polarity-schemes-dialog",
        title: t(LocalizationKeys.PolaritySchemes),
        header: {
            collapsible: true
        },
        modal: false,
        onClose
    };

    return { dialogProps };
}

export const usePolarityConfigurationButtons = () => {
    const polarityConfigurations = useSelector(currentAssemblyPolarityConfigurationsSelector);
    const [selectedConfiguration, setSelectedConfiguration] = useState<IPolarityConfigurationProps>(polarityConfigurations[0]);

    useEffect(() => {
        setSelectedConfiguration(polarityConfigurations[0]);
    }, [polarityConfigurations]);

    const onConfigurationClick = useCallback((configuration: IPolarityConfigurationProps) => {
        setSelectedConfiguration(configuration);
    }, []);

    const polarityConfigurationButtons = polarityConfigurations.map(c => {
        const polarityConfigurationButtonProps: ButtonProps = {
            className: selectedConfiguration === c ? " polarity-configuration-button active" : " polarity-configuration-button",
            palette: MainPalettes.primary,
            token: MainThemeTokens.main,
            onClick: () => onConfigurationClick(c)
        }
        return {
            buttonProps: polarityConfigurationButtonProps,
            label: c.label
        };
    });

    return { selectedConfiguration, polarityConfigurationButtons };
}

export const usePolaritySchemes = (configuration: IPolarityConfigurationProps) => {
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();
    const currentAssignments = useSelector(currentConnectorAssignmentsSelector);
    const predefinedPolarityMaps = useSelector(polarityMapsSelector);
    const userFiberMaps = useSelector(userFiberMapsSelector);
    const polaritySchemes = predefinedPolarityMaps.filter(m => configuration.polarityMaps.includes(m)).map(m => {
        const polarityScheme: IPolaritySchemeProps = { id: m.id, label: m.description, imageUri: m.imageUri, isCustom: m.key === CUSTOM_MAP_KEY }
        return polarityScheme;
    });
    const [selectedScheme, setSelectedScheme] = useState<IPolaritySchemeProps | undefined>(undefined);

    useEffect(() => {
        setSelectedScheme(undefined);
    }, [configuration]);

    const onSchemeClick = useCallback((scheme: IPolaritySchemeProps) => {
        setSelectedScheme(scheme);
    }, []);

    const [showEditInputDialog, setShowEditInputDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onEditClick = useCallback((scheme: IPolaritySchemeProps) => {
        setShowEditInputDialog(true);
        setSelectedScheme(scheme);
    }, []);

    const onSave = useCallback((value?: InputFieldValue) => {
        if (value) {
            const fiberMapName = value.toString();
            const userFiberMap = userFiberMaps.find((f) => f.id === selectedScheme?.id);
            if (userFiberMap) {
                const updatedFiberMap = { ...userFiberMap, name: fiberMapName };
                const updatedAssignments: IConnectorAssignmentMap[] = currentAssignments.filter(a => a.fiberMapData.name === userFiberMap.name).map(a => ({ ...a, fiberMapData: { ...a.fiberMapData, name: fiberMapName }}));
                batch(() => { 
                    storeDispatch(setStatus(Status.Synchronizing));
                    storeDispatch(addOrUpdateUserFiberMap(updatedFiberMap));
                    storeDispatch(updateMultipleConnectorAssignments(updatedAssignments));
                })
            }
        }
    }, [selectedScheme, storeDispatch, userFiberMaps, currentAssignments]);

    const onDeleteClose = useCallback(() => {
        setShowDeleteDialog(false);
    }, [])

    const onDeleteConfirm = useCallback(() => {
        setShowDeleteDialog(false);
        const userFiberMap = userFiberMaps.find((f) => f.id === selectedScheme?.id);
        if (userFiberMap && userFiberMap.id) {
            storeDispatch(setStatus(Status.Synchronizing))
            storeDispatch(deleteUserFiberMap(userFiberMap));
        }
    }, [selectedScheme, userFiberMaps, storeDispatch]);

    const onDeleteClick = useCallback((scheme: IPolaritySchemeProps) => {
        setShowDeleteDialog(true);
        setSelectedScheme(scheme);
    }, []);

    const validateSchemeName = useCallback((value: InputFieldValue) => {
        const isEmpty = !validateInputChars(value.toString());
        if (isEmpty) return false;
        const existingScheme = polaritySchemes.filter(m => m.label !== selectedScheme?.label).find(m => m.label.toLocaleLowerCase() === value.toString().toLowerCase());
        return existingScheme ? false : true;
    }, [polaritySchemes, selectedScheme]);

    const getValidationMessage = useCallback((value: InputFieldValue | undefined) => {
        if(value){
            const isEmpty = !validateInputChars(value.toString());
            if (isEmpty) return LocalizationKeys.EmptyInputField;
            const existingScheme = polaritySchemes.filter(m => m.label !== selectedScheme?.label).find(m => m.label.toLocaleLowerCase() === value.toString().toLowerCase());
            return existingScheme ? LocalizationKeys.PolarityExists : "";
        }
    },[polaritySchemes, selectedScheme]);

    const deleteDialogActions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onDeleteClose,
        confirmText: t(LocalizationKeys.Delete), 
        onConfirmClick: onDeleteConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const deleteDialogProps: DialogProps = {
        open: showDeleteDialog,
        className: "delete-polarity-scheme-dialog",
        title: t(LocalizationKeys.DeleteCustomPolarity),
        message: t(LocalizationKeys.DeleteCustomPolarityMessage),
        actions: deleteDialogActions,
        onClose: onDeleteClose
    };

    const onCloseEditInputDialog = useCallback(() => {
        setShowEditInputDialog(false);
    }, []);

    const editInputDialogProps: IInputDialogProps = {
        open: showEditInputDialog,
        onClose: onCloseEditInputDialog,
        value: selectedScheme?.label ?? "",
        title: t(LocalizationKeys.EditPolarityName),
        fieldLabel: t(LocalizationKeys.PolarityName),
        maxCharacterCount: 20,
        restriction: {
            validate: validateSchemeName,
            message: getValidationMessage,
        },
        cancelProps: {
            text: t(LocalizationKeys.Cancel)
        },
        saveProps: {
            text: t(LocalizationKeys.Save),
            onClick: onSave
        }
    }

    const polaritySchemesRowProps: IPolarityRowProps[] = polaritySchemes.map(p => ({ polarityScheme: p, onDeleteClick, onEditClick, onSchemeClick }));
    
    return { selectedScheme, editInputDialogProps, deleteDialogProps, polaritySchemes, polaritySchemesRowProps };
}