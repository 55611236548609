import { IColor, Blue, Orange, Green, Brown, Slate, White, Red, Black, Yellow, Violet, Rose, Aqua, Mustard, Lime, Beige } from "../../../ui/dialog/color/types";

export interface IAssemblyPalette {
    id? : number;
    assemblyId?: number;
    jacketColor: string;
    sideATrunkColor: string;
    sideALegColor: string;
    sideBTrunkColor: string;
    sideBLegColor: string;
}

export const FiberColors: IColor[] = [Blue, Orange, Green, Brown, Slate, White, Red, Black, Yellow, Violet, Rose, Aqua];
export const DefaultJacketColors = [Blue, Red, Violet, Black];
export const SinglemodeJacketColors = [Yellow];
export const MultimodeJacketColors = [Aqua, Green];
export const MultimodeOM5JacketColors = [Lime];
export const JacketColors: IColor[] = [...DefaultJacketColors, ...SinglemodeJacketColors, ...MultimodeJacketColors, ...MultimodeOM5JacketColors];
export const MTPExclusiveColors: IColor[] = [Mustard, Lime, Beige];
export const MTPColors: IColor[] = [...FiberColors, ...MTPExclusiveColors];
export const MDCColors: IColor[] = [Blue, Green, Aqua];
export const AllColors: IColor[] = [...FiberColors, ...MTPExclusiveColors];