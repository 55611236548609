import React from 'react';
import { usePolarity } from './hooks';
import { PolarityContext } from './reducer/types';
import { PolaritySchemeDialog } from './polarity-scheme/PolaritySchemeDialog';
import { ConnectorAssignmentDialog } from "./connector-assignment/ConnectorAssignmentDialog";
import { FiberMappingDialog } from './fiber-map/FiberMappingDialog';
import { PolarityToolbar } from './toolbar/PolarityToolbar';

export const Polarity: React.FC = () => {
    const { polarityContext } = usePolarity();
    return (
        <PolarityContext.Provider value={polarityContext}>
            <PolarityToolbar />
            <PolaritySchemeDialog />
            <ConnectorAssignmentDialog />
            <FiberMappingDialog />
        </PolarityContext.Provider>
    )
}