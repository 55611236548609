import React from 'react';
import { withAdjacentComponent } from '../../interactions/adjacent-component/AdjacentComponent';
import { BreakoutGraphic } from './breakout-graphic/BreakoutGraphic';
import { useBreakoutComponent } from './hooks';
import { BreakoutMarkers } from './marker/BreakoutMarkers';
import { BreakoutProps } from './types';

const BreakoutWithMarker = withAdjacentComponent(
    BreakoutGraphic,
    BreakoutMarkers
)

export const Breakout: React.FC<BreakoutProps> = (props) => {
    const { componentProps, adjacentProps, hocProps } = useBreakoutComponent(props)
    return (
        <BreakoutWithMarker component={{ ...componentProps }} adjacent={{...adjacentProps}} {...hocProps} />
    )
}