import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { batch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { cableSetupStateSelector } from "../store/selectors";
import { CableSetupReducer, CableSetupState, setWizardSetupState } from "./reducer";
import { convertBreakoutLengthUnits, setBreakoutStepState } from "./breakout-step/reducer";
import { BreakoutSetupProps } from "./breakout-setup/types";
import { convertGeneralLengthUnits, setGeneralSetupState } from "./general-setup/reducer";
import { ICableSetupContext, initialCableSetupState } from "./types";
import { Shapes } from "../../assembly/types";
import { unitSelector } from "../../assembly/length/store/selectors";
import { showAssemblyWizardSelector } from "../../store/selectors";

export const useCableSetup = () => {
    const display = useSelector(showAssemblyWizardSelector);
    const { context } = useCableSetupContext();
    const breakoutSteps = useBreakoutSteps(context)
    const stepTitle = useSetupTitle(context.state);
    return { context, stepTitle, breakoutSteps, display }
}

export const useCableSetupContext = () => {
    const [state, dispatch] = useReducer(CableSetupReducer, initialCableSetupState);
    const setupState = useSelector(cableSetupStateSelector);

    useEffect(() => {
        batch(() => {
            dispatch(setWizardSetupState({ ...setupState.wizardSetup }))
            dispatch(setBreakoutStepState({ ...setupState.breakoutSetup }))
            dispatch(setGeneralSetupState({ ...setupState.generalSetup }))
        })
    }, [setupState, dispatch])

    return { context: { state, dispatch } }
}

export const useBreakoutSteps = ({ state, dispatch }: ICableSetupContext) => {
    const { breakoutSetup, wizardSetup } = state;
    const breakoutSetupProps: BreakoutSetupProps[] = breakoutSetup.breakoutSetups.map((b, i) => {
        return { ...b, stepIndex: i + 1, key: `${b.current.side || "AB"}` }
    })

    useEffect(() => {
        if (wizardSetup.stepIndex && dispatch) {
            dispatch(setBreakoutStepState({ setupIndex: wizardSetup.stepIndex - 1 }))
        }
    }, [wizardSetup.stepIndex, dispatch])

    return breakoutSetupProps;
}

export const useSetupTitle = (state: CableSetupState) => {
    const { wizardSetup, generalSetup } = state;
    const { t } = useTranslation();

    let breakoutTitle = ''
    if (generalSetup.shape === Shapes.Symmetrical) {
        breakoutTitle = t(LocalizationKeys.BreakoutsAB).toLocaleUpperCase();
    }
    else if (wizardSetup.stepIndex === 1) {
        breakoutTitle = t(LocalizationKeys.BreakoutsA).toLocaleUpperCase();
    }
    else if (wizardSetup.stepIndex === 2) {
        breakoutTitle = t(LocalizationKeys.BreakoutsB).toLocaleUpperCase();
    }
    const stepCount = `${wizardSetup.stepIndex + 1}/${wizardSetup.stepCount}`
    const stepTitle = wizardSetup.stepIndex === 0 ? t(LocalizationKeys.GeneralInformation).toLocaleUpperCase() : breakoutTitle;

    return `${stepCount} ${stepTitle}`;
}

export const useWorkspaceUnits = ({ dispatch }: ICableSetupContext) => {
    const { unit, secondaryUnit } = useSelector(unitSelector)
    useEffect(() => {
        if (dispatch) {
            dispatch(convertGeneralLengthUnits({ unit, secondaryUnit }))
            dispatch(convertBreakoutLengthUnits(unit))
        }
    }, [dispatch, unit, secondaryUnit])
}