import { createContext } from "react";
import { IWorkspaceDialogSettings, Status, WorkspaceStatus } from "../../workspace/store/types";

export interface IStatusState {
    status: WorkspaceStatus;
    isWorkspaceInteractable: boolean;
    dialogs: IWorkspaceDialogSettings;
}

const initialStatus: IStatusState = {
    status: Status.Loading,
    isWorkspaceInteractable: true,
    dialogs: {
        showAssemblyWizard: false,
        showAssemblyEdit: false,
        showBreakoutEdit: false,
        showBreakoutDetails: false,
        showTermsConditions: false,
        showIdle: false,
        showTimeOut: false,
        showAnnotationEdit: false,
        showHelp: false
    }
}

export interface IStatusContext {
    state: IStatusState,
    dispatchBreakoutEdit: (display: boolean) => void;
}

const initialStatusContext: IStatusContext = {
    state: initialStatus,
    dispatchBreakoutEdit: () => { }
}

export const StatusContext = createContext(initialStatusContext);