import { createSlice } from "@reduxjs/toolkit";
import { setSelectedBreakoutAction, unselectBreakoutsAction } from "./actions";
import { initialSelectionState } from "./types";

const selectSlice = createSlice({
    initialState: initialSelectionState,
    name: "selection",
    reducers: {
        setSelectedBreakout: setSelectedBreakoutAction,
        unselectBreakouts: unselectBreakoutsAction,
    }
})

export const SelectionReducer = selectSlice.reducer;
export const { setSelectedBreakout, unselectBreakouts } = selectSlice.actions;