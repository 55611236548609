import { ButtonProps } from "@orbit/button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { ChangeEvent, HTMLProps, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { IFileSelectorProps } from "./types";

export const useFileSelector = ({ onFileChange }: IFileSelectorProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const ref = useRef<HTMLInputElement | null>(null);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onFileChange(e);
        setValue("");
    }
    const inputProps: HTMLProps<HTMLInputElement> = {
        type: "file",
        accept: ".jpg,.jpeg,.png",
        multiple: true,
        hidden: true,
        ref,
        value,
        onChange,
    }
    const buttonProps: ButtonProps = {
        className: "upload-file-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: () => ref?.current?.click()
    }
    const label = t(LocalizationKeys.UploadImage);
    return { inputProps, buttonProps, label }
}