import React from "react";
import { IGenericAutocompleteProps } from "./types";
import { Autocomplete } from "@mui/material";
import { FilledTextField } from "@orbit/text-field";
import { MainPalettes } from "@orbit/theme-provider";

import "./GenericAutocomplete.scss";

export const GenericAutocomplete: React.FC<IGenericAutocompleteProps> = ({ open, label, value, options, disabled, helperText, onOpen, onClose, onChange, onInputChange }: IGenericAutocompleteProps) => {
    return (
        <div className={!helperText ? "generic-autocomplete" : "generic-autocomplete invalid"}>
            <Autocomplete
                disableClearable
                open={open}
                options={options}
                value={value}
                disabled={disabled}
                onOpen={onOpen}
                onClose={onClose}
                onChange={(e, value) => onChange(value)}
                onInputChange={(e, value) => onInputChange(value)}
                renderInput={(params) => ( 
                    <FilledTextField {...params} palette={MainPalettes.primary} disabled={disabled} label={label} value={value} helperText={helperText} type="number" /> 
            )} />
        </div>
    );
};