import { IFiberMappingConnectorProps } from "./types";
import { useContext, useMemo } from 'react';
import { FiberMappingContext } from "../reducer/types";
import { Sides } from "../../../assembly/breakout/types";
import { setBreakoutNavigationIndex, setConnectorNavigationIndex } from "../reducer/reducer";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { PolarityContext } from "../../reducer/types";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";

export const useFiberMappingConnector = (props: IFiberMappingConnectorProps) => {
    const { side } = props;
    const { state, dispatch } = useContext(FiberMappingContext);
    const { sideA, sideB } = state.navigation;
    const { state: polarityState } = useContext(PolarityContext);
    const { connectorAssignment } = polarityState;
    const { t } = useTranslation();

    const { previousProps, nextProps } = useMemo(() => {
        let previousDisabled: boolean;
        let onPreviousClick: () => void;
        let nextDisabled: boolean;
        let onNextClick: () => void;
        if (side === Sides.SideA) {
            previousDisabled = sideA.breakout.currentIndex === 0 && sideA.connector.currentIndex === 0;
            onPreviousClick = () => {
                let previousIndex = sideA.connector.currentIndex - 1;
                if (previousIndex < 0) {
                    previousIndex = sideA.breakout.currentIndex - 1;
                    if (previousIndex >= 0) {
                        dispatch(setBreakoutNavigationIndex({ side: Sides.SideA, index: previousIndex }));
                        const breakoutPosition = connectorAssignment.connectors.sideAMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i)[previousIndex];
                        const connectors = connectorAssignment.connectors.sideAMapping.filter(c => c.breakoutPosition === breakoutPosition);
                        dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: connectors.length - 1 }));
                    }
                } else {
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: previousIndex }));
                }
            }
            nextDisabled = sideA.breakout.currentIndex === sideA.breakout.nbIndex - 1 && sideA.connector.currentIndex === sideA.connector.nbIndex - 1;
            onNextClick = () => {
                let nextIndex = sideA.connector.currentIndex + 1;
                if (nextIndex >= sideA.connector.nbIndex) {
                    nextIndex = (sideA.breakout.currentIndex + 1) % sideA.breakout.nbIndex;
                    dispatch(setBreakoutNavigationIndex({ side: Sides.SideA, index: nextIndex }));
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: 0 }));
                } else {
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideA, index: nextIndex }));
                }
            }
        } else {
            previousDisabled = sideB.breakout.currentIndex === 0 && sideB.connector.currentIndex === 0;
            onPreviousClick = () => {
                let previousIndex = sideB.connector.currentIndex - 1;
                if (previousIndex < 0) {
                    previousIndex = sideB.breakout.currentIndex - 1;
                    if (previousIndex >= 0) {
                        dispatch(setBreakoutNavigationIndex({ side: Sides.SideB, index: previousIndex }));
                        const breakoutPosition = connectorAssignment.connectors.sideBMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i)[previousIndex];
                        const connectors = connectorAssignment.connectors.sideBMapping.filter(c => c.breakoutPosition === breakoutPosition);
                        dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: connectors.length - 1 }));
                    }
                } else {
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: previousIndex }));
                }
            }
            nextDisabled = sideB.breakout.currentIndex === sideB.breakout.nbIndex - 1 && sideB.connector.currentIndex === sideB.connector.nbIndex - 1;
            onNextClick = () => {
                let nextIndex = sideB.connector.currentIndex + 1;
                if (nextIndex >= sideB.connector.nbIndex) {
                    nextIndex = (sideB.breakout.currentIndex + 1) % sideB.breakout.nbIndex;
                    dispatch(setBreakoutNavigationIndex({ side: Sides.SideB, index: nextIndex }));
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: 0 }));
                } else {
                    dispatch(setConnectorNavigationIndex({ side: Sides.SideB, index: nextIndex }));
                }
            }
        }

        const previousProps = {
            className: "previous-icon",
            palette: MainPalettes.primary,
            token: MainThemeTokens.main,
            title: t(LocalizationKeys.Previous),
            placement: "bottom" as TooltipPlacement,
            disabled: previousDisabled,
            onClick: onPreviousClick
        };

        const nextProps = {
            className: "next-icon",
            palette: MainPalettes.primary,
            token: MainThemeTokens.main,
            title: t(LocalizationKeys.Next),
            placement: "bottom" as TooltipPlacement,
            disabled: nextDisabled,
            onClick: onNextClick
        };

        return { previousProps, nextProps };
    }, [side, sideA, sideB, dispatch, connectorAssignment, t]);

    return { previousProps, nextProps };
}
