import { useSelector } from "react-redux";
import { selectedBreakoutSelector } from "../../store/selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { Sides } from "../types";
import { useCallback, useReducer } from "react";
import { showBreakoutDetailsSelector } from '../../../store/selectors';
import { BreakoutDetailsReducer, clearSelection } from './store/reducer';
import { IBreakoutDetailsContext, initialBreakoutDetailsState } from './store/types';
import { showBreakoutDetails } from '../../../store/reducer';
import { useStoreDispatch } from '../../../../store/hooks';
import { DialogProps } from '@orbit/dialog';

export const useBreakoutDetails = () => {
    const open = useSelector(showBreakoutDetailsSelector);
    const breakout = useSelector(selectedBreakoutSelector);
    const [state, dispatch] = useReducer(BreakoutDetailsReducer, initialBreakoutDetailsState);
    const context: IBreakoutDetailsContext = { state, dispatch };
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showBreakoutDetails(false));
        dispatch(clearSelection());
    }, [storeDispatch, dispatch]);

    const breakoutSidePosition = breakout.side === Sides.SideA ? `A${breakout.position}` : `B${breakout.position}`;
    const dialogProps: DialogProps = {
        open,
        className: "breakout-details-dialog",
        title: t(LocalizationKeys.BreakoutSection, { section: breakoutSidePosition }),
        header: {
            collapsible: true,
        },
        modal: false,
        onClose
    };

    return { dialogProps, context };
}