import { PayloadAction } from "@reduxjs/toolkit";
import { Side } from "../../../workspace/assembly/breakout/types";
import { ISelectionState } from "./types";

export const setSelectedBreakoutAction = (state: ISelectionState, action: PayloadAction<{ side: Side, position: number }>) => {
    const { position, side } = action.payload;
    const selected = state.selectedBreakout;
    if (selected) {
        if (selected.position !== position) selected.position = position;
        if (selected.side !== side) selected.side = side;
    }
    else {
        state.selectedBreakout = { position, side }
    }
}

export const unselectBreakoutsAction = (state: ISelectionState) => {
    state.selectedBreakout = undefined;
}