import { ButtonProps } from "@orbit/button";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { LocalizationKeys } from "../../../../localization/types";
import { useStoreDispatch } from "../../../../store/hooks";
import { IColor, IColorDialogProps } from "../../../../ui/dialog/color/types";
import { useInputField } from "../../../../ui/input/hooks";
import { useSelectInput } from "../../../../ui/select/hooks";
import { OffsetTypes, Side, Sides } from "../../../assembly/breakout/types";
import { ConnectorTypes, LSH, PIGTAIL_LEG, PIGTAIL_NO_LEG } from "../../../assembly/connector/types"
import { parseInput, useLengthField, validatePositiveInt } from "../../../assembly/length/hooks";
import { Length } from "../../../assembly/length/types";
import { setupAssemblyBreakouts, setupLegColor, setupSideLegColor, setupSideTrunkColor, setupTrunkColor } from "../../../assembly/store/reducer";
import { defaultBreakoutJacketColorSelectorFactory, defaultLegJacketColorSelectorFactory, filteredJacketColorSelector } from "../../../assembly/store/selectors";
import { isEMEAUserSelector } from "../../../store/selectors";
import { pushBreakoutSet, setCurrentBreakout } from "../breakout-step/reducer";
import { CableSetupContext, setApplyEnable, setResetEnable } from "../reducer";
import { IOffsetLengthFieldProps } from "./offset-lengthfield/types";
import { IBreakoutSet, BreakoutSetupProps } from "./types";

export const useBreakoutSetup = (props: BreakoutSetupProps) => {
    const [canAddBreakout, setCanAddBreakout] = useState(true);
    const { state: contextState, dispatch } = useContext(CableSetupContext)
    const { t } = useTranslation()
    const { wizardSetup, breakoutSetup } = contextState;
    const { current, sets } = props;
    const displayStep = wizardSetup.stepIndex === props.stepIndex;
    const storeDispatch = useStoreDispatch();
    const side = current.side;
    const position = current.startPosition || 1;
    const groupOffsetProps = useGroupOffsets(current);
    const shape = contextState.generalSetup.shape;

    const dispatchLengthA = useCallback((length: Length) => {
        if (dispatch && displayStep) {
            dispatch(setCurrentBreakout({ lengthA: length, side }))
            if (length.value === 0 && sets.length === 0) {
                dispatch(setCurrentBreakout({ count: 1, side }))
            }
        }
    }, [dispatch, sets, side, displayStep])

    const dispatchLengthAp = useCallback((length: Length) => {
        if (dispatch && displayStep) {
            dispatch(setCurrentBreakout({ lengthAp: length, side }))
        }
    }, [dispatch, side, displayStep])

    const dispatchSpare = useCallback((value: number) => {
        if (dispatch && displayStep) {
            dispatch(setCurrentBreakout({ spareCount: Number.parseInt((value || 0).toString()), side }))
        }
    }, [dispatch, side, displayStep])

    const validateLengthA = useCallback((value: number) => {
        if (sets.length && value === 0) {
            return false;
        }
        else return true;
    }, [sets])

    const dispatchBreakoutCount = useCallback((value: number) => {
        if (dispatch && displayStep) {
            const count = Number.parseInt(value.toString());
            if (!Number.isNaN(count)) {
                dispatch(setCurrentBreakout({ count, side }))
            }
        }
    }, [dispatch, side, displayStep])

    const validateMultipleBreakouts = useCallback((value: number) => {
        const validInt = validatePositiveInt(value);
        return (current.lengthA.value === 0 && value === 1) || (current.lengthA.value > 0 && validInt)
    }, [current.lengthA])

    const title = useBreakoutTitle(current)
    const connectorTypes = useConnectorTypes(current, dispatch);
    const connectorCount = useConnectorCount(current, dispatch);
    
    const { isPigtailWithLeg, isPigtailWithNoLeg } = connectorCount.pigTail;
    const onlySpares = current.connectorGroupCount === 0 || current.groupCount === 0
    const spareField = useInputField(t(LocalizationKeys.NSpareConnectors), isPigtailWithLeg || isPigtailWithNoLeg ? 0 : current.spareCount, undefined, parseInput, (value) => validatePositiveInt(value, !onlySpares), dispatchSpare)
    const spareHelperText = spareField.isValid ? "" : t(LocalizationKeys.InvalidEntry)
    
    const sideLabel = side && side === Sides.SideB ? `B${position}` : `A${position}`;
    const { jacket, primeJacket, paletteIcon, colorDialogProps } = useJacket(sideLabel, isPigtailWithNoLeg, side );
    
    const lengthAField = useLengthField({ label: t(LocalizationKeys.LengthName, { name: sideLabel }), length: current.lengthA, callback: dispatchLengthA, validateInput: validateLengthA });
    const lengthApField = useLengthField({ label: t( LocalizationKeys.LengthName, { name: `${sideLabel}'`}), length: current.lengthAp, callback: dispatchLengthAp });
    
    const multipleBreakoutField = useInputField(t(LocalizationKeys.NBreakouts), current.count, undefined, parseInput, validateMultipleBreakouts, dispatchBreakoutCount)
    const disableBreakoutCount = sets.length === 0 && current.lengthA.value === 0;
    const breakoutHelperText = multipleBreakoutField.isValid ? "" : t(LocalizationKeys.InvalidEntry)
    let pigtailsEnabled = true
    if (contextState.wizardSetup.stepIndex > 0) {    
        const isPigtail = contextState.breakoutSetup.breakoutSetups.some(bos => bos.current.connectorType.type === PIGTAIL_LEG || bos.current.connectorType.type === PIGTAIL_NO_LEG );
        const isCurrentPigtail = isPigtailWithLeg || isPigtailWithNoLeg;
        pigtailsEnabled = isPigtail ? isCurrentPigtail : contextState.breakoutSetup.breakoutSetups[props.stepIndex as number - 1].sets.length === 0;
    }

    useEffect(() => {
        if (!displayStep) {
            colorDialogProps.onClose();
        }
    }, [displayStep, colorDialogProps]);

    useEffect(() => {
        setCanAddBreakout(connectorCount.isValid && lengthAField.isValid && lengthApField.isValid && current.lengthA.value !== 0 && multipleBreakoutField.isValid!)
    }, [connectorCount.isValid, lengthAField.isValid, lengthApField.isValid, multipleBreakoutField.isValid, current.lengthA])

    useEffect(() => {
        const onlySpares = (current.connectorGroupCount === 0 || current.groupCount === 0) && current.spareCount > 0
        const validConnectorCount = (current.groupCount && current.connectorGroupCount) || onlySpares
        const validBreakoutSet = (validConnectorCount && current.lengthA.value && lengthApField.isValid && multipleBreakoutField.isValid) || false
        const noTrunk = current.lengthA.value === 0 && current.count === 1 && sets.length === 0
        const enableApply = validBreakoutSet ? !noTrunk : noTrunk; //xor validBreakoutSet, noTrunk
        if (dispatch) {
            dispatch(setApplyEnable(enableApply))
            if (enableApply) {
                storeDispatch(setupAssemblyBreakouts({ current, sets }))
            }
        }
    }, [lengthApField.isValid, multipleBreakoutField.isValid, current, sets, storeDispatch, dispatch, displayStep, breakoutSetup.breakoutSetups, onlySpares])

    const onAddBreakouts = useCallback(() => {
        if (dispatch) {
            dispatch(pushBreakoutSet(current))
        }
    }, [dispatch, current]);

    const addBreakoutsButtonProps: ButtonProps = {
        className: "add-breakouts-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: !canAddBreakout || isPigtailWithLeg || isPigtailWithNoLeg,
        onClick: onAddBreakouts
    }

    const addBreakouts = {
        buttonProps: addBreakoutsButtonProps,
        label: t(LocalizationKeys.AddNewBreakout)
    }

    useEffect(() => {
        if (dispatch) {
            let enable = false;
            if (wizardSetup.stepCount === 3) {
                if (wizardSetup.stepIndex > 0) {
                    enable = breakoutSetup.breakoutSetups[wizardSetup.stepIndex - 1].sets.length > 0
                }
            }
            else {
                enable = sets.length > 0
            }
            dispatch(setResetEnable(enable))
        }
    }, [sets, dispatch, wizardSetup, breakoutSetup]);

    const spare = {
        className: "spare-field",
        label: spareField.label,
        value: spareField.value,
        palette: MainPalettes.primary,
        error: !spareField.isValid,
        helperText: spareHelperText,
        disabled: isPigtailWithLeg || isPigtailWithNoLeg,
        onChange: spareField.onChange
    };

    const lengthA = {
        className: "length-a-field",
        label: lengthAField.label,
        value: lengthAField.value,
        palette: MainPalettes.primary,
        error: !lengthAField.isValid,
        disabled: isPigtailWithNoLeg,
        onChange: lengthAField.onChange
    };

    const lengthAp = {
        className: "length-ap-field",
        label: lengthApField.label,
        value: lengthApField.value,
        palette: MainPalettes.primary,
        error: !lengthApField.isValid,
        disabled: isPigtailWithNoLeg,
        onChange: lengthApField.onChange
    };

    const multipleBreakout = {
        className: "multiple-breakout-field",
        label: multipleBreakoutField.label,
        value: multipleBreakoutField.value,
        palette: MainPalettes.primary,
        disabled: disableBreakoutCount || isPigtailWithLeg || isPigtailWithNoLeg,
        error: !multipleBreakoutField.isValid,
        helperText: breakoutHelperText,
        onChange: multipleBreakoutField.onChange
    };

    return {
        fields: {
            title,
            connectorTypes,
            connectorCount,
            spare,
            jacket,
            lengthA,
            primeJacket,
            lengthAp,
            multipleBreakout,
        },
        paletteIcon,
        groupOffsetProps,
        addBreakouts,
        displayStep,
        colorDialogProps,
        shape,
        pigtailsEnabled
    }

}

const useBreakoutTitle = (state: IBreakoutSet) => {
    const { t } = useTranslation();
    let breakoutPosition = `A${state.startPosition} & B${state.startPosition}`
    if (state.side) {
        breakoutPosition = state.side === Sides.SideA ? `A${state.startPosition}` : `B${state.startPosition}`
    }
    const title = state.startPosition ? t(LocalizationKeys.BreakoutSection, { section: breakoutPosition }) : ""
    return title;
}

const connectorTypes = Object.values(ConnectorTypes);
const useConnectorTypes = (state: IBreakoutSet, dispatch?: React.Dispatch<AnyAction>) => {
    const [current, setCurrent] = useState(state.connectorType);
    const { t } = useTranslation();
    const isEMEAUser = useSelector(isEMEAUserSelector);

    const connectorOptions = useMemo(() => {
        let localTypes = connectorTypes;
        if (!isEMEAUser) {
            localTypes = connectorTypes.filter(c => c.type !== LSH);
        }
        return localTypes.map(c => c.description || "")
    }, [isEMEAUser]);

    const connectorTypeSelect = useSelectInput(connectorOptions, t(LocalizationKeys.ConnectorType), "", state.connectorType.description!)
    const { side } = state

    const onChange = useCallback((e) => {
        const value = e.target.value;
        const connectorType = connectorTypes.find(c => c.description === value);
        if (connectorType) {
            setCurrent(connectorType)
            if (dispatch) {
                dispatch(setCurrentBreakout({ connectorType: connectorType, offsetType: connectorType.type === PIGTAIL_LEG ? OffsetTypes.None : state.offsetType, side }))
            }
        }
    }, [dispatch, side, state.offsetType]);

    const connectorTypesProps = {
        label: connectorTypeSelect.label,
        select: {
            variant: "filled" as "filled",
            value: connectorTypeSelect.value,
            onChange
        },
        options: connectorOptions
    }

    useEffect(() => {
        if (state.connectorType.key !== current.key) {
            setCurrent(state.connectorType)
        }
    }, [state.connectorType, current.key]);

    return connectorTypesProps;
}

const useConnectorCount = (state: IBreakoutSet, dispatch?: React.Dispatch<AnyAction>) => {
    const [isValid, setIsValid] = useState(true);
    const [groupHelperText, setGroupHelperText] = useState("");
    const [connectorGroupHelperText, setConnectorGroupHelperText] = useState("");
    const { t } = useTranslation();
    const { side, spareCount, connectorType } = state;
    const isPigtailWithLeg = connectorType.type === PIGTAIL_LEG;
    const isPigtailWithNoLeg = connectorType.type === PIGTAIL_NO_LEG;
    const hasSpare = spareCount > 0;

    const dispatchGroupCount = useCallback((value: number) => {
        if (dispatch) {
            dispatch(setCurrentBreakout({ groupCount: Number.parseInt((value || 0).toString()), side }));
        }
    }, [dispatch, side])

    const dispatchConnectorGroupCount = useCallback((value: number) => {
        if (dispatch) {
            dispatch(setCurrentBreakout({ connectorGroupCount: Number.parseInt((value || 0).toString()), side }));
        }
    }, [dispatch, side])

    const validateCount = useCallback((value: number, includeZero?: boolean) => {
        const validCount = validatePositiveInt(value, includeZero)
        return validCount || hasSpare
    }, [hasSpare])

    const groupInputField = useInputField(t(LocalizationKeys.NGroups), isPigtailWithNoLeg ? 1 : state.groupCount, undefined, parseInput, validateCount, dispatchGroupCount);
    const connectorGroupInputField = useInputField(t(LocalizationKeys.NConnPerGroup), isPigtailWithLeg || isPigtailWithNoLeg ? 1 : state.connectorGroupCount, undefined, parseInput, validateCount, dispatchConnectorGroupCount);

    useEffect(() => {
        const validCount = (groupInputField.isValid && connectorGroupInputField.isValid) || hasSpare
        setGroupHelperText(groupInputField.isValid ? "" : t(LocalizationKeys.InvalidEntry))
        setConnectorGroupHelperText(connectorGroupInputField.isValid ? "" : t(LocalizationKeys.InvalidEntry))
        setIsValid(validCount!)
    }, [groupInputField.isValid, connectorGroupInputField.isValid, t, hasSpare]);

    const groupInput = {
        className: "group-input",
        label: groupInputField.label,
        value: groupInputField.value,
        palette: MainPalettes.primary,
        error: !groupInputField.isValid,
        helperText: groupHelperText,
        disabled: isPigtailWithNoLeg,
        onChange: groupInputField.onChange
    }

    const connectorGroupInput = {
        className: "connector-group-input",
        label: connectorGroupInputField.label,
        value: connectorGroupInputField.value,
        palette: MainPalettes.primary,
        error: !connectorGroupInputField.isValid,
        helperText: connectorGroupHelperText,
        disabled: isPigtailWithLeg || isPigtailWithNoLeg,
        onChange: connectorGroupInputField.onChange
    };

    return { groupInput, connectorGroupInput, isValid, pigTail: {isPigtailWithLeg, isPigtailWithNoLeg} }
}

const useJacket = (sideLabel: string, isPigtailWithNoLeg: boolean, side?: Side ) => {
    const { t } = useTranslation();
    const jacketColors = useSelector(filteredJacketColorSelector);
    const jacketColor = useSelector(defaultBreakoutJacketColorSelectorFactory(side));
    const primeJacketColor = useSelector(defaultLegJacketColorSelectorFactory(side));
    const storeDispatch = useStoreDispatch();
    const [showColorDialog, setShowColorDialog] = useState(false);
    const [isPrime, setIsPrime] = useState(false);

    const openColorDialog = useCallback((isPrime: boolean) => {
        setShowColorDialog(true);
        setIsPrime(isPrime);
    }, []);

    const closeColorDialog = useCallback(() => {
        setShowColorDialog(false);
    }, []);

    const jacketField = {
        className: "color-picker",
        label: t(LocalizationKeys.SideJacketColor, { side: sideLabel }),
        value: jacketColor.name,
        disabled: isPigtailWithNoLeg,
        palette: MainPalettes.primary
    };

    const jacket = {
        field: jacketField,
        onClick: () => openColorDialog(false)
    };

    const primeJacketField = {
        className: "color-picker",
        label: t(LocalizationKeys.SideJacketColor, { side: `${sideLabel}'` }),
        value: primeJacketColor.name,
        disabled: isPigtailWithNoLeg,
        palette: MainPalettes.primary
    };

    const primeJacket = {
        field: primeJacketField,
        onClick: () => openColorDialog(true)
    };

    const onJacketColorButtonClick = useCallback((color: IColor) => {
        if (side) {
            storeDispatch(setupSideTrunkColor({ side, color: color.name }));
        } else {
            storeDispatch(setupTrunkColor(color.name));
        }
    }, [side, storeDispatch]);

    const onPrimeJacketColorButtonClick = useCallback((color: IColor) => {
        if (side) {
            storeDispatch(setupSideLegColor({ side, color: color.name }));
        } else {
            storeDispatch(setupLegColor(color.name));
        }
    }, [side, storeDispatch]);

    const currentColor = isPrime ? primeJacketColor : jacketColor;
    const className = isPrime ? "breakout-setup-prime-jacket" : "breakout-setup-jacket";
    const onColorButtonClick = isPrime ? onPrimeJacketColorButtonClick : onJacketColorButtonClick;
    const colorDialogProps: IColorDialogProps = {
        open: showColorDialog,
        onClose: closeColorDialog,
        className,
        currentColor,
        colors: jacketColors,
        onColorButtonClick
    }

    const paletteIcon = {
        className: "palette-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: isPigtailWithNoLeg,
        placement: "bottom" as TooltipPlacement
    };

    return { jacket, primeJacket, paletteIcon, colorDialogProps };
}

const useGroupOffsets = (set: IBreakoutSet) => {
    const { dispatch } = useContext(CableSetupContext);
    const { t } = useTranslation();

    const isPigtailWithLeg = set.connectorType.type === PIGTAIL_LEG;
    const isPigtailWithNoLeg = set.connectorType.type === PIGTAIL_NO_LEG;
    const dispatchStagger = useCallback((length: Length, groupIndex: number) => {
        const { legStagger, offset } = set.groupStagger[groupIndex];
        const groupStagger = [...set.groupStagger];
        if (dispatch && length.value >= 0) {
            groupStagger[groupIndex] = { legStagger: { ...legStagger, value: length.value }, offset }
            dispatch(setCurrentBreakout({ groupStagger, side: set.side }));
        }
    }, [dispatch, set.groupStagger, set.side]);

    const dispatchOffset = useCallback((length: Length, groupIndex: number) => {
        const { legStagger, offset } = set.groupStagger[groupIndex];
        const groupStagger = [...set.groupStagger];
        if (dispatch && length.value >= 0) {
            groupStagger[groupIndex] = { legStagger, offset: { ...offset, value: length.value }}
            dispatch(setCurrentBreakout({ groupStagger, side: set.side }));
        }
    }, [dispatch, set.groupStagger, set.side]);
    const disableStaggerField = set.connectorGroupCount <= 1 || isPigtailWithLeg || isPigtailWithNoLeg;
    const disableOffsetField = set.groupCount <= 1 || isPigtailWithLeg || isPigtailWithNoLeg;
    const staggerFields: IOffsetLengthFieldProps[] = set.groupStagger.map((s, i) => {
        const staggerLabel = set.offsetType === OffsetTypes.Uniform ? 
            t(LocalizationKeys.StaggerLabel) : 
            t(LocalizationKeys.StaggerNumberLabel, { index: i + 1 });
        const offsetLabel = set.offsetType === OffsetTypes.Uniform ? 
            t(LocalizationKeys.GroupOffsetLabel) :
            t(LocalizationKeys.GroupOffsetNumberLabel, { index: i + 1 });
        
        const loadOffset = !(set.offsetType === OffsetTypes.Custom && i === 0)
        const offsetProps = loadOffset ? {
            callback: dispatchOffset,
            lengthFieldProps: {
                label: offsetLabel,
                length: s.offset,
                disabled: disableOffsetField,
            }
        } : undefined;
        return {
            groupIndex: i,
            staggerProps: {
                callback: dispatchStagger,
                lengthFieldProps: {
                    label: staggerLabel,
                    length: s.legStagger,
                    disabled: disableStaggerField, 
                    helperText: t(LocalizationKeys.StaggerHelp)
                }
            },
            offsetProps
        }
    });

    const onRadioChange = useCallback((e) => {
        if (dispatch) {
            const offsetType = e.currentTarget.value;
            dispatch(setCurrentBreakout({ offsetType, side: set.side }))
        }
    }, [dispatch, set.side]);
    const offsetType = isPigtailWithLeg ? OffsetTypes.None : set.offsetType
    const radioProps = {
        value: offsetType,
        options: [
            {
                className: offsetType === OffsetTypes.Uniform ? "selected" : "",
                label: t(LocalizationKeys.Uniform),
                radioButtonProps: { value: OffsetTypes.Uniform, disabled: isPigtailWithLeg || isPigtailWithNoLeg, onChange: onRadioChange },
            },
            { 
                className: offsetType === OffsetTypes.Custom ? "selected" : "",
                label: t(LocalizationKeys.Custom),
                radioButtonProps: { value: OffsetTypes.Custom, disabled: isPigtailWithLeg || isPigtailWithNoLeg, onChange: onRadioChange },
            },
            {
                className: offsetType === OffsetTypes.None ? "selected" : "",
                label: t(LocalizationKeys.None),
                radioButtonProps: { value: OffsetTypes.None, disabled: isPigtailWithLeg || isPigtailWithNoLeg, onChange: onRadioChange },
            }
        ]
    };

    return {
        label: t(LocalizationKeys.Offsets),
        type: set.offsetType,
        radioProps,
        hasMultipleGroups: staggerFields.length > 1,
        staggerFields,
        disableLegField: set.connectorGroupCount <= 1,
        disableOffsetField: set.groupCount <= 1,
        side: set.side
    }
}