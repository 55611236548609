export interface IColor {
    hex: string;
    name: string;
}

export interface IBootColor {
    bootAColor?: IColor,
    bootBColor?: IColor,
}

export const Blue: IColor = { hex: "#045EAA", name: "Blue" };
export const Orange: IColor = { hex: "#F26623", name: "Orange" };
export const Green: IColor = { hex: "#299145", name: "Green" };
export const Brown: IColor = { hex: "#754C29", name: "Brown" };
export const Slate: IColor = { hex: "#808285", name: "Slate" };
export const White: IColor = { hex: "#F1F1F2", name: "White" };
export const Red: IColor = { hex: "#C42032", name: "Red" };
export const Black: IColor = { hex: "#231F20", name: "Black" };
export const Yellow: IColor = { hex: "#F9ED3C", name: "Yellow" };
export const Violet: IColor = { hex: "#662D91", name: "Violet" };
export const Rose: IColor = { hex: "#F0BCD7", name: "Rose" };
export const Aqua: IColor = { hex: "#89CADF", name: "Aqua" };
export const Mustard: IColor = { hex: "#E3A433", name: "Mustard" };
export const Lime: IColor = { hex: "#2FEA36", name: "Lime" };
export const Beige: IColor = { hex: "#BEAF79", name: "Beige" };
export const DisabledGray: IColor = { hex: "#00000042", name: "N/A" };

export const WhiteWhiteBootColors: IBootColor = {
    bootAColor: White,
    bootBColor: White,
}

export const WhiteBlackBootColors: IBootColor = {
    bootAColor: White,
    bootBColor: Black,
}

export const WhiteBlueBootColors: IBootColor = {
    bootAColor: White,
    bootBColor: Blue,
}

export const BootColors = [WhiteWhiteBootColors, WhiteBlackBootColors, WhiteBlueBootColors];

export const getTextColor = (color: IColor): IColor => {
    switch (color) {
        case White:
        case Yellow:
        case Rose:
        case Aqua:
            return Black;
        default:
            return White;
    }
}

export const getConnectorTextColor = (color: string): string => {
    switch (color) {
        case Blue.hex:
        case Brown.hex:
        case Red.hex:
        case Black.hex:
        case Violet.hex:
            return "#FFF";
        default:
            return "#000";
    }
}

export const hexStringToNumber = (hex: string) => parseInt(hex.slice(1), 16);

export const hexNumberToString = (hex: number) => `#${("000000" + hex.toString(16)).substr(-6, 6)}`;

export const getColor = (color: string) => {
    switch(color){
        case Blue.name:
            return Blue;
        case Orange.name:
            return Orange;
        case Green.name:
            return Green;  
        case Brown.name:
            return Brown;
        case Slate.name:
            return Slate;
        case White.name:
            return White;
        case Red.name:
            return Red;
        case Black.name:
            return Black;
        case Yellow.name:
            return Yellow;
        case Violet.name:
            return Violet;
        case Rose.name:
            return Rose;
        case Aqua.name:
            return Aqua;
        case Mustard.name:
            return Mustard;
        case Lime.name:
            return Lime;
        case Beige.name:
            return Beige;
    }
}

export interface IColorDialogProps {
    open: boolean;
    onClose: () => void;
    className: string;
    currentColor?: IColor;
    colors: IColor[];
    onColorButtonClick: (color: IColor) => void;
}

export interface IColorButtonProps {
    onClick: (color: IColor) => void;
    color: IColor;
    selected: boolean;
}