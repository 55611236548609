import React, { useState, useCallback, useEffect } from "react";

export const useSelectInput = (options: string[], label: string, helperText?: string, value?: string) => {
    const [fieldValue, setFieldValue] = useState(value);

    const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setFieldValue(e.currentTarget.value)
    }, [])

    useEffect(() => {
        if (fieldValue !== value) {
            setFieldValue(value)
        }
    }, [fieldValue, value])

    return { label, value: fieldValue, options, helperText, onChange }
}

export type SelectInput = Partial<ReturnType<typeof useSelectInput>>