import React from 'react';
import { useSelector } from 'react-redux';
import { unitSelector } from '../assembly/length/store/selectors';
import { ScalePanel } from './scale-panel/ScalePanel';
import { FitPanel } from './fit-panel/FitPanel';
import { BreakoutToolbar } from './breakout-toolbar/BreakoutToolbar';
import { DetailsPanel } from './details-panel/DetailsPanel';
import { Typography } from '@orbit/theme-provider';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../localization/types';
import { Units } from '../assembly/length/types';

import "./BottomBar.scss";

export const BottomBar: React.FC = () => {
    const { t } = useTranslation();
    const { unit } = useSelector(unitSelector);
    const unitString = unit === Units.Millimeter ? t(LocalizationKeys.Millimeter) : t(LocalizationKeys.Inches)
    return (
        <div className="bottom-bar toggle-pointer-events">
            <div className="left-panel">
                <ScalePanel />
                <FitPanel />
                <div className="panel units-display">
                    <Typography variant="subtitle1" label={`${t(LocalizationKeys.Unit)}${t(LocalizationKeys.Colon)}${unitString}`} />
                </div>
            </div>
            <BreakoutToolbar />
            <div className="right-panel">
                <DetailsPanel />
            </div>
        </div>
    )
}