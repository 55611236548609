import React from 'react';
import { ColorChip } from '../../../ui/dialog/color/chip/ColorChip';
import { MTPColors } from '../../assembly/palette/types';

export const ColorChips: React.FC = () => {
    return (
        <div id="color-chips-container">
            { MTPColors.map((c, i) => <ColorChip key={`${i}`} color={c} scaleMultiplier={5} />) }
        </div>
    )
}