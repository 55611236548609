import React from 'react';
import { useConnectorAssignmentDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { ConnectorAssignmentContext } from './reducer/types';
import { Checkbox } from '@orbit/checkbox';
import { Table, TableBody } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { ChevronLeft, ChevronRight, Launch } from '@mui/icons-material';
import { ConnectorAssignmentRow } from './row/ConnectorAssignmentRow';
import { ContainedButton, TextButton } from '@orbit/button';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Typography } from '@orbit/theme-provider';
import { PropagationDialog } from './propagation-dialog/PropagationDialog';

import './ConnectorAssignmentDialog.scss';

export const ConnectorAssignmentDialog: React.FC = () => {
    const { dialogProps, assignmentContext, polaritySchemes, selectAllProps, sideAProps, sideBProps, resetAll, polarity, polarityPatterns, apply, propagation } = useConnectorAssignmentDialog();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <ConnectorAssignmentContext.Provider value={assignmentContext}>
                <div className="subheader">
                    <Checkbox {...selectAllProps} tabIndex={-1} />
                    <div className="polarity-container">
                        <FormControl className='select'>
                            <Select {...polarityPatterns.select}>
                                {polarityPatterns.options.map((o, i) => <MenuItem key={`polarity-type-${i}`} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl className='select'>
                            <Select {...polarity.select}>
                                {polarity.options.map((o, i) => <MenuItem key={`polarity-scheme-${i}`} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <IconButton {...polaritySchemes.buttonProps} icon={<Launch/>} />
                        <ContainedButton {...apply.buttonProps}>
                            {apply.label}
                        </ContainedButton>
                    </div>
                </div>
                <div className="assignment-container">
                    <div className="local-container side-a">
                        <div className="container-header">
                            <IconButton {...sideAProps.previousProps} icon={<ChevronLeft />} />
                            <div className="container-title">
                                <Typography variant="subtitle1" label={sideAProps.title} />
                            </div>
                            <IconButton {...sideAProps.nextProps} icon={<ChevronRight />} />
                        </div>
                        <div className='table-container'>
                            <Table className="assignment-table side-a" tabIndex={0}>
                                <TableBody>
                                    { sideAProps.rows.map((r) => <ConnectorAssignmentRow key={r.id} data={r} editable={true} />) }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <div className="local-container side-b">
                        <div className="container-header">
                            <IconButton {...sideBProps.previousProps} icon={<ChevronLeft />} />
                            <div className="container-title">
                                <Typography variant="subtitle1" label={sideBProps.title} />
                            </div>
                            <IconButton {...sideBProps.nextProps} icon={<ChevronRight />} />
                        </div>
                        <div className='table-container'>
                            <Table className="assignment-table side-b">
                                <TableBody>
                                    { sideBProps.rows.map((r) => <ConnectorAssignmentRow key={r.id} data={r} />) }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="assignment-action-container">
                    <TextButton {...resetAll.buttonProps}>
                        {resetAll.label}
                    </TextButton>
                    <TextButton {...propagation.buttonProps}>
                        {propagation.label}
                    </TextButton>
                </div>
                <Dialog {...resetAll.dialogProps} />
                <PropagationDialog />
            </ConnectorAssignmentContext.Provider>
        </Dialog>
    )
}