import React from 'react';
import { Help } from '@mui/icons-material';

import { Tooltip } from '@mui/material';
import "./HelpIcon.scss"

interface HelpIconProps {
    text: string
}

export const HelpIcon: React.FC<HelpIconProps> = ({ text }) => {
    return (
        <span className='help-icon-container' >
            <Tooltip
                arrow
                placement='top'
                title={text}
            >
                <Help className='help-icon' />
            </Tooltip>
        </span>
    )
}