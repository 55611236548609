import React from 'react';
import { useSetupFooter } from './hooks';
import { ContainedButton, OutlinedButton } from '@orbit/button';
import { Check, ChevronLeft, Undo } from '@mui/icons-material';

export const SetupFooter: React.FC = () => {
    const { previous, undo, finish, next } = useSetupFooter();
    const footerClassName = previous.display ? "wizard-footer flex" : "wizard-footer";
    return (
        <div className={footerClassName}>
            <div className="wizard-footer-left" hidden={!previous.display}>
                <OutlinedButton {...previous.buttonProps} startIcon={<ChevronLeft />}>
                    {previous.label}
                </OutlinedButton>
                <OutlinedButton {...undo.buttonProps} startIcon={<Undo />}>
                    {undo.label}
                </OutlinedButton>
            </div>
            <div className="wizard-footer-right">
                {
                    finish.display ? 
                    <ContainedButton {...finish.buttonProps} startIcon={<Check />}>
                        {finish.label}
                    </ContainedButton> :
                    <ContainedButton {...next.buttonProps}>
                        {next.label}
                    </ContainedButton>
                }
            </div>
        </div>
    )
}