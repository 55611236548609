import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { useAssemblyDocument } from "../../../report/document/hooks";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { showPrintDialog } from "../../../report/store/reducer";
import { setStatus } from "../../../store/reducer";
import { Status } from "../../../store/types";
import { useStoreDispatch } from "../../../../store/hooks";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { ButtonProps } from "@orbit/button";

export const useReportAction = () => {
    const disabled = useSelector(disabledOverlaySelector);
    const { exportAssembly } = useAssemblyDocument();
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const openMenu = useCallback((e) => {
        setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    }, []);

    const onMenuClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const menuProps = {
        open: menuOpen,
        className: "report-menu",
        anchorEl,
        onClose: onMenuClose,
    };

    const onPDFClick = useCallback(() => {
        storeDispatch(showPrintDialog(true));
        onMenuClose();
    }, [storeDispatch, onMenuClose]);

    const pdfButtonProps: ButtonProps = {
        className: "export-to-pdf-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        size: "large",
        onClick: onPDFClick
    };

    const pdfExport = {
        buttonProps: pdfButtonProps,
        label: t(LocalizationKeys.AsPDF)
    };

    const onImageClick = useCallback(() => {
        onMenuClose();
        storeDispatch(setStatus(Status.Printing));
        exportAssembly(true);
    }, [onMenuClose, storeDispatch, exportAssembly]);

    const imageButtonProps: ButtonProps = {
        className: "export-to-image-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        size: "large",
        onClick: onImageClick
    };

    const imageExport = {
        buttonProps: imageButtonProps,
        label: t(LocalizationKeys.AsImage)
    };

    const reportIconProps = {
        className: "report-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Report),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: openMenu 
    }

    return { reportIconProps, menuProps, pdfExport, imageExport };
}