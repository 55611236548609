import React from 'react';
import { Graphics } from '@inlet/react-pixi';
import { useBezierCurve } from './hooks';
import { BezierCurveGraphics, IBezierCurveProps } from './types';

export const BezierCurve: React.FC<IBezierCurveProps> = (props) => {
    const { drawCurve } = useBezierCurve(props);
    return (
        <Graphics name={BezierCurveGraphics} draw={drawCurve}/>
    )
}