import { ButtonProps } from "@orbit/button";
import saveAs from "file-saver";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../localization/types";
import { csvOutputSelector } from "../../../../assembly/store/selectors";
import { getDefaultFileName } from "../../../../services/export/file-saver";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const useExportCSVAction = () => {
    const { t } = useTranslation();
    const csvOutput = useSelector(csvOutputSelector);

    const onCSVClick = useCallback(() => {
        const fileName = `${getDefaultFileName()}.csv`
        const file = new File(csvOutput, fileName, { type: "text/csv;charset=utf-8," })
        saveAs(file)
    }, [csvOutput]);

    const csvButtonProps: ButtonProps = {
        className: "export-to-csv-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        size: "large",
        onClick: onCSVClick
    };

    const csvExport = {
        buttonProps: csvButtonProps,
        label: t(LocalizationKeys.AsCSV)
    };

    return { csvExport };
}