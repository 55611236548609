import { SecuredService } from "../../../services/secured-service";
import { IApiResult } from "../../../services/api-result";
import { IAssembly, IAssemblySide } from "../../assembly/types";
import { IBreakout, ICableFurcation, ICableTrunk, IConnector, IConnectorGroup, Side, Sides } from "../../assembly/breakout/types";
import { Length } from "../../assembly/length/types";
import { IAssemblyInfo } from "../../assembly/info/types";
import { AssignedIndexCodes, IFiberMapData, IFiberMapIndex } from "../../polarity/fiber-map/types";
import { IConnectorAssignmentMap, IConnectorMap } from "../../polarity/connector-assignment/reducer/types";
import { IAssemblyAnnotation } from "../../assembly/annotation/types";
import { IAssemblyPalette } from "../../assembly/palette/types";
import { isDuplex } from "../../assembly/connector/types";

export class AssemblyService extends SecuredService {
    private readonly baseURL = 'api/assembly';

    public async getAssembly(id: number): Promise<IApiResult<IAssembly>> {
        return await this.get(`${this.baseURL}/${id}`);
    }

    public async getAssembliesByUserId(userId: number): Promise<IApiResult<IAssembly[]>> {
        return await this.get(`${this.baseURL}/all/${userId}`);
    }

    public async getPolarityFiberMaps(): Promise<IApiResult<IFiberMapData[]>> {
        return this.get(`${this.baseURL}/polarity/fiberMaps`);
    }

    public async getUserFiberMaps(userId: number): Promise<IApiResult<IFiberMapData[]>> {
        return this.get(`${this.baseURL}/polarity/fiberMaps/${userId}`);
    }

    public async addAssembly(assembly: IAssembly, userId: number): Promise<IApiResult<IAssembly>> {
        const request = ToAssemblyRequest(assembly, userId);
        return await this.post(this.baseURL, request);
    }

    public async addConnectorAssignment(assemblyId: number, connectorAssignment: IConnectorAssignmentMap): Promise<IApiResult<IConnectorAssignmentMap>> {
        const assignment = ToConnectorAssignmentDTO(connectorAssignment);
        return await this.post(`${this.baseURL}/polarity/connectorAssignment/${assemblyId}`, assignment);
    }

    public async addConnectorAssignments(assemblyId: number, connectorAssignments: IConnectorAssignmentMap[]): Promise<IApiResult<IConnectorAssignmentMap[]>> {
        const assignments = connectorAssignments.map(a => ToConnectorAssignmentDTO(a));
        return await this.post(`${this.baseURL}/polarity/connectorAssignments/${assemblyId}`, assignments);
    }

    public async addOrUpdateUserFiberMap(fiberMapData: IFiberMapData): Promise<IApiResult<IFiberMapData>> {
        const userFiberMap = ToFiberMapDTO(fiberMapData);
        return await this.post(`${this.baseURL}/polarity/userFiberMap`, userFiberMap);
    }

    public async updateAssembly(assembly: IAssembly, userId: number): Promise<IApiResult<IAssembly>> {
        const request = ToAssemblyRequest(assembly, userId);
        return this.put(this.baseURL, request);
    }

    public async updateInfo(info: IAssemblyInfo): Promise<IApiResult<IAssemblyInfo>> {
        const assemblyInfo = ToAssemblyInfoDTO(info);
        return this.post(this.baseURL + '/info', { assemblyInfo });
    }

    public async updatePalette(palette: IAssemblyPalette): Promise<IApiResult<IAssemblyPalette>> {
        return this.post(`${this.baseURL}/palette`, palette);
    }

    public async updateAssemblyAnnotation(annotation: IAssemblyAnnotation): Promise<IApiResult<IAssemblyAnnotation>> {
        return this.post(`${this.baseURL}/annotation`, annotation);
    }

    public async updateLength(length: Length): Promise<IApiResult<Length>> {
        return this.post(this.baseURL + '/length', { length });
    }

    public async updateBreakout(breakoutToUpdate: IBreakout, propagateLegsAttributes: boolean): Promise<IApiResult<IBreakout>> {
        const breakout = ToBreakoutDTO(breakoutToUpdate);
        return this.post(this.baseURL + "/breakout", { breakout, propagateLegsAttributes });
    }

    public async updateTrunk(cableTrunk: ICableTrunk): Promise<IApiResult<ICableTrunk>> {
        const trunk = ToTrunkDTO(cableTrunk);
        return this.post(this.baseURL + "/trunk", { trunk });
    }

    public async updateConnector(connectorToUpdate: IConnector): Promise<IApiResult<IConnector>> {
        const connector = ToConnectorDTO(connectorToUpdate);
        return this.post(this.baseURL + '/connector', { connector });
    }

    public async updateConnectors(connectorsToUpdate: IConnector[]): Promise<IApiResult<IConnector[]>> {
        const connectors = connectorsToUpdate.map(c => ToConnectorDTO(c));
        return this.post(this.baseURL + '/connectors', { connectors });
    }

    public async updateConnectorAssignment(assignmentToUpdate: IConnectorAssignmentMap): Promise<IApiResult<IConnectorAssignmentMap>> {
        const assignment = ToConnectorAssignmentDTO(assignmentToUpdate);
        return this.post(`${this.baseURL}/polarity/connectorAssignment`, assignment);
    }

    public async updateMultipleConnectorAssignments(assignmentsToUpdate: IConnectorAssignmentMap[]): Promise<IApiResult<IConnectorAssignmentMap[]>> {
        const assignments: any[] = [];
        for (const assignment of assignmentsToUpdate) {
            assignments.push(ToConnectorAssignmentDTO(assignment));
        }
        return this.post(`${this.baseURL}/polarity/connectorAssignments`, assignments);
    } 

    public async updateAssignmentsConnectorTypes(assemblyId: number, applyAll: boolean, side: Side, breakoutPosition: number, previousType: string, newType: string): Promise<IApiResult<IConnectorAssignmentMap[]>> {
        return this.post(`${this.baseURL}/polarity/connectorTypes`, { assemblyId, applyAll, side, breakoutPosition, previousType, newType });
    }

    public async deleteAssembly(id: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseURL}/${id}`);
    }

    public async deleteConnectorAssignment(assignmentId: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseURL}/polarity/connectorAssignment/${assignmentId}`);
    }

    public async deleteConnectorAssignments(polarityId: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseURL}/polarity/connectorAssignments/${polarityId}`);
    }

    public async deleteUserFiberMap(userFiberMapId: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseURL}/polarity/fiberMaps/${userFiberMapId}`);
    }
}

export const ToAssemblyRequest = (assembly: IAssembly, userId: number) => {
    const { id, fiberCount, overallLength, sideA, sideB, assemblyInfo, palette, polarity } = assembly;
    const sideADTO = ToSideDTO(sideA!, Sides.SideA);
    const sideBDTO = ToSideDTO(sideB!, Sides.SideB);
    return {
        id: id,
        userId,
        fiberCount,
        overallLength: ToLengthDTO(overallLength),
        sides: [sideADTO, sideBDTO],
        assemblyInfo: ToAssemblyInfoDTO(assemblyInfo),
        palette,
        polarity
    };
};

const ToAssemblyInfoDTO = (assemblyInfo?: IAssemblyInfo) => {
    if (!assemblyInfo) return;
    const lastModified = new Date().toUTCString();
    const tolerances = { 
        legTolerance: assemblyInfo.legTolerance,
        legPrimeTolerance: assemblyInfo.legPrimeTolerance,
        legLabelTolerance: assemblyInfo.legLabelTolerance,
    }
    return { ...assemblyInfo, lastModified, tolerances: JSON.stringify(tolerances) };
}

const ToSideDTO = (side: IAssemblySide, sideType: Side) => {
    const { breakouts } = side;
    return {
        ...side,
        type: sideType,
        breakouts: breakouts.map(b => ToBreakoutDTO(b))
    };
};

const ToBreakoutDTO = (breakout: IBreakout) => {
    const { id, sideId, position, label, jacketColor, side, trunk, furcation } = breakout;
    return {
        id,
        sideId,
        label,
        position,
        sideType: side,
        jacketColor,
        trunk: ToTrunkDTO(trunk),
        furcation: ToFurcationDTO(furcation)
    };
};

const ToTrunkDTO = (trunk: ICableTrunk) => {
    const { length } = trunk;
    return {
        ...trunk,
        length: ToLengthDTO(length)
    };
};

const ToFurcationDTO = (furcation: ICableFurcation) => {
    const { groups } = furcation;
    return {
        ...furcation,
        groups: groups.map(g => ToConnectorGroupDTO(g))
    };
};

const ToConnectorGroupDTO = (group: IConnectorGroup) => {
    const { length, offset, connectors } = group;
    return {
        ...group,
        length: ToLengthDTO(length),
        offset: ToLengthDTO(offset),
        connectors: connectors.map(c => ToConnectorDTO(c))
    };
};

const ToConnectorDTO = (connector: IConnector) => {
    const { spare, length, bootColors, type } = connector;
    let bootColorsJSON: any = undefined;
    if (isDuplex(type)) {
        if (bootColors){
            bootColorsJSON = JSON.stringify({ 
                bootAColor: bootColors.bootAColor!.name, 
                bootBColor: bootColors.bootBColor!.name 
            });
        }
    }

    return {
        ...connector,
        isSpare: spare,
        length: ToLengthDTO(length),
        bootColors: bootColorsJSON
    };
};

const ToLengthDTO = (length: Length | undefined) => {
    return length ? { ...length } : undefined;
};

const ToConnectorAssignmentDTO = (connectorAssignment: IConnectorAssignmentMap) => {
    const { fiberMapData, connectors } = connectorAssignment;
    return {
        ...connectorAssignment,
        fiberMap: ToFiberMapDTO(fiberMapData),
        connectors: [...connectors.sideAMapping.map(c => ToPolarityConnectorDTO(c)), ...connectors.sideBMapping.map(c => ToPolarityConnectorDTO(c))],
    }
}

const ToFiberMapDTO = (fiberMapData: IFiberMapData) => {
    const { sourceIndices, destinationIndices, versionDate } = fiberMapData;
    const indices = [...sourceIndices, ...destinationIndices];
    return {
        ...fiberMapData,
        versionDate: versionDate ?? new Date().toISOString(),
        pins: indices.map(i => ToConnectorPinDTO(i))
    }
}

const ToConnectorPinDTO = (fiberIndex: IFiberMapIndex) => {
    const { breakoutPosition, connectorType, assignedIndex } = fiberIndex;
    return {
        ...fiberIndex,
        position: breakoutPosition,
        type: connectorType ?? "",
        blocked: assignedIndex === AssignedIndexCodes.Blocked
    }
}

const ToPolarityConnectorDTO = (connector: IConnectorMap) => {
    const { connectorType } = connector;
    return {
        ...connector,
        type: connectorType
    }
}