import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../localization/types";
import { setStatus, showBreakoutEdit } from "../../../store/reducer";
import { assembliesLoadedSelector, breakoutJacketColorSelectorFactory, currentAssemblyBootColorsSelector, currentAssemblySelector, filteredJacketColorSelector, legJacketColorSelectorFactory, selectedBreakoutSelector, sideBreakoutsSelectorFactory } from "../../store/selectors";
import { IBreakout, ICableFurcation, ICableTrunk, IConnector, IConnectorGroup, Side, Sides } from "../types";
import { showBreakoutEditSelector, workspaceSavingSelector } from '../../../store/selectors';
import { getColor, IColor, IColorDialogProps, Yellow } from "../../../../ui/dialog/color/types";
import { useLengthField } from "../../length/hooks";
import { useInputField } from "../../../../ui/input/hooks";
import { useSelectInput } from "../../../../ui/select/hooks";
import { ConnectorTypes, getConnectorType, isDuplex, isPigtailConnector } from "../../connector/types";
import { BreakoutReducer, setApplyAll, setBreakoutJacketColor, setBreakoutLabel, setBreakoutLength, setLegConnectorType, setLegJacketColor } from "./reducer/reducer";
import { IBreakoutEditContext, initialBreakoutState } from "./reducer/types";
import { Length } from "../../length/types";
import { updateAssignmentConnectorTypes, updateBreakout, updateBreakouts } from "../../store/reducer";
import { Status } from "../../../store/types";
import { getDefaultConnectorColor } from "../../../../pixi/cable/breakout/connector-furcation/connector-sprite/types";
import { useStoreDispatch } from "../../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { CheckboxProps } from "@orbit/checkbox";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";

export const useBreakoutEditDialog = () => {
    const open = useSelector(showBreakoutEditSelector);
    const breakout = useSelector(selectedBreakoutSelector);
    const [breakoutId, setBreakoutId] = useState(breakout.id);
    const sideCharacter = breakout.side === Sides.SideA ? "A" : "B";
    const sideBreakouts = useSelector(sideBreakoutsSelectorFactory(breakout.side));
    const [prevSideBreakouts, setPrevSideBreakouts] = useState(sideBreakouts);
    const breakoutSidePosition = `${sideCharacter}${breakout.position}`;
    const [state, dispatch] = useReducer(BreakoutReducer, initialBreakoutState);
    const context: IBreakoutEditContext = { state, dispatch };
    const { breakoutJacket, legJacket, colorDialogProps } = useJacket(breakout, breakoutSidePosition, context);
    const { breakoutLength, breakoutLabel, setHasLoadedAssemblies } = useBreakoutAttributes(breakout, breakoutSidePosition, context);
    const { legConnectorTypesDropdownProps, legCheckboxProps } = useLegsAttributes(breakout, prevSideBreakouts, sideCharacter, context);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (!open) {
            setPrevSideBreakouts(sideBreakouts);
        }
    }, [open, sideBreakouts]);

    const closeBreakoutEdit = useCallback(() => {
        storeDispatch(showBreakoutEdit(false));
        colorDialogProps.onClose();
    }, [storeDispatch, colorDialogProps]);

    const { applyDisabled, onApply, applyAllConfirmDialogProps } = useActions(breakout, prevSideBreakouts, setPrevSideBreakouts, setHasLoadedAssemblies, closeBreakoutEdit, context);

    const resetBreakout = useCallback(() => {
        storeDispatch(updateBreakouts(prevSideBreakouts));
        dispatch(setApplyAll(false));
    }, [storeDispatch, prevSideBreakouts]);

    const onClose = useCallback(() => {
        storeDispatch(showBreakoutEdit(false));
        colorDialogProps.onClose();
        resetBreakout();
    }, [storeDispatch, colorDialogProps, resetBreakout]);

    useEffect(() => {
        if (open && breakout.id !== breakoutId) {
            resetBreakout();
            if (prevSideBreakouts[0].side !== breakout.side) {
                setPrevSideBreakouts(sideBreakouts);
                const previousConnectorType = breakout.furcation.groups[0].connectors[0].type;
                storeDispatch(updateBreakout(breakout, false, previousConnectorType));
            }
            setBreakoutId(breakout.id);
        }
    }, [open, storeDispatch, prevSideBreakouts, sideBreakouts, breakoutId, resetBreakout, breakout]);

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onClose,
        confirmText: t(LocalizationKeys.Apply),
        disableConfirm: applyDisabled,
        onConfirmClick: onApply,
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        },
    }

    const dialogProps: DialogProps = {
        open,
        className: "breakout-edit-dialog",
        title: `${t(LocalizationKeys.Edit)} ${t(LocalizationKeys.Breakout)} ${breakoutSidePosition}`,
        header: {
            collapsible: true,
        },
        modal: false,
        actions,
        onClose
    };

    return { 
        dialogProps,
        breakoutAttributes: {
            title: t(LocalizationKeys.BreakoutAttributes),
            jacket: breakoutJacket,
            length: breakoutLength,
            label: breakoutLabel
        },
        legAttributes: {
            label: t(LocalizationKeys.LegsAttributes),
            jacket: legJacket,
            connectorTypes: legConnectorTypesDropdownProps,
            checkboxProps: legCheckboxProps
        },
        paletteIcon: {
            className: "palette-icon",
            palette: MainPalettes.primary,
            token: MainThemeTokens.main,
            placement: "bottom" as TooltipPlacement
        },
        colorDialogProps,
        applyAllConfirmDialogProps
    };
}

const useBreakoutAttributes = (breakout: IBreakout, sidePosition: string, context: IBreakoutEditContext) => {
    const display = useSelector(showBreakoutEditSelector);
    const { state, dispatch } = context;
    const { breakoutAttributes } = state;
    const breakoutLength = breakout.trunk.length;
    const breakoutLabel = breakout.label ?? breakout.position.toString();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();
    const assembliesLoaded = useSelector(assembliesLoadedSelector);
    const [hasLoadedAssemblies, setHasLoadedAssemblies] = useState(assembliesLoaded);

    useEffect(() => {
        if (display) {
            dispatch(setBreakoutLength(breakoutLength));
            dispatch(setBreakoutLabel(breakoutLabel));
        }
    }, [display, dispatch, breakoutLabel, breakoutLength, assembliesLoaded, hasLoadedAssemblies, breakout]);

    const dispatchBreakoutLength = useCallback((length: Length) => {
        if (length.value !== 0) {
            dispatch(setBreakoutLength(length));
            const trunk: ICableTrunk = { ...breakout.trunk, length };
            const breakoutToUpdate: IBreakout = { ...breakout, trunk };
            const previousConnectorType = breakout.furcation.groups[0].connectors[0].type;
            storeDispatch(updateBreakout(breakoutToUpdate, false, previousConnectorType));
        }
    }, [dispatch, storeDispatch, breakout]);

    const dispatchBreakoutLabel = useCallback((label: string) => {
        dispatch(setBreakoutLabel(label));
        const breakoutToUpdate: IBreakout = { ...breakout, label };
        const previousConnectorType = breakout.furcation.groups[0].connectors[0].type;
        storeDispatch(updateBreakout(breakoutToUpdate, false, previousConnectorType));
    }, [dispatch, storeDispatch, breakout])

    const breakoutLengthLabel = t(LocalizationKeys.LengthLabel, { name: sidePosition });
    const breakoutLengthField = useLengthField({ label: breakoutLengthLabel, length: breakoutAttributes.length, callback: dispatchBreakoutLength });
    const breakoutLabelField = useInputField(`${sidePosition} Label`, breakoutAttributes.label, 10, undefined, undefined, dispatchBreakoutLabel);

    return {
        setHasLoadedAssemblies,
        breakoutLength: {
            className: "breakout-length-field",
            label: breakoutLengthField.label,
            value: breakoutLengthField.value,
            palette: MainPalettes.primary,
            error: !breakoutLengthField.isValid,
            onChange: breakoutLengthField.onChange
        },
        breakoutLabel: {
            className: "breakout-label-field",
            label: breakoutLabelField.label,
            value: breakoutLabelField.value as string,
            palette: MainPalettes.primary,
            maxLength: breakoutLabelField.maxLength,
            error: !breakoutLabelField.isValid,
            onChange: breakoutLabelField.onChange
        }
    };
}

const useLegsAttributes = (breakout: IBreakout, prevSideBreakouts: IBreakout[], sideCharacter: string, context: IBreakoutEditContext) => {
    const display = useSelector(showBreakoutEditSelector);
    const { state, dispatch } = context;
    const { legsAttributes, applyAll } = state;
    const connectorType = breakout.furcation.groups[0].connectors[0].type;
    const connectorFiberCount = getConnectorType(connectorType).fiberCount
    const { t } = useTranslation();
    const connectorTypes = Object.values(ConnectorTypes).filter(t => t.fiberCount === connectorFiberCount && !isPigtailConnector(t.type)).map(c => c.description);
    const connectorTypeLabel = t(LocalizationKeys.ConnectorType)
    const legConnectorTypesDropdown = useSelectInput(connectorTypes, connectorTypeLabel, "", legsAttributes.connectorType);
    const currentBootColors = useSelector(currentAssemblyBootColorsSelector);
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        if (display) {
            dispatch(setLegConnectorType(connectorType));
        } else {
            dispatch(setApplyAll(false));
        }
    }, [display, dispatch, connectorType])

    const prevSelectedBreakout = prevSideBreakouts.find(b => b.id === breakout.id);

    const onCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setApplyAll(e.currentTarget.checked));
        if (prevSelectedBreakout) {
            if (e.currentTarget.checked) {
                const previousConnectorType = prevSelectedBreakout.furcation.groups[0].connectors[0].type;
                storeDispatch(updateBreakout(breakout, true, previousConnectorType));
            } else {
                const breakoutsToReset = prevSideBreakouts.filter(b => b.id !== prevSelectedBreakout.id);
                storeDispatch(updateBreakouts(breakoutsToReset));   
            }
        }
    }, [dispatch, storeDispatch, prevSideBreakouts, prevSelectedBreakout, breakout]);

    const legCheckboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.ApplyAllBreakouts, { sideCharacter }),
        checked: applyAll,
        onChange: onCheckboxChanged
    };

    const onlegConnectorTypesChange = useCallback((e) => {
        const connectorType = e.target.value;
        dispatch(setLegConnectorType(connectorType));
        
        const groups: IConnectorGroup[] = [];
        for (const group of breakout.furcation.groups) {
            const connectors: IConnector[] = [];
            for (const connector of group.connectors) {
                const color = getDefaultConnectorColor(connectorType).name;
                const bootColorCombinations = isDuplex(connectorType) ? { bootAColor: connector.bootColors?.bootAColor ?? currentBootColors[0], bootBColor: connector.bootColors?.bootBColor ?? currentBootColors[1] } : { bootAColor: undefined, bootBColor: undefined };   
                connectors.push({ ...connector, bootColors: bootColorCombinations, color, type: connectorType });
            }
            groups.push({ ...group, connectors });
        }
        const furcation: ICableFurcation = { ...breakout.furcation, groups };
        const previousConnectorType = breakout.furcation.groups[0].connectors[0].type;
        const breakoutToUpdate: IBreakout = { ...breakout, furcation };

        storeDispatch(updateBreakout(breakoutToUpdate, applyAll, previousConnectorType, false, prevSideBreakouts));
    }, [dispatch, storeDispatch, breakout, currentBootColors, applyAll, prevSideBreakouts]);

    return {
        legConnectorTypesDropdownProps: {
            label: legConnectorTypesDropdown.label,
            select: {
                variant: "filled" as "filled",
                defaultValue: connectorTypes[0],
                value: legConnectorTypesDropdown.value,
                onChange: onlegConnectorTypesChange
            },
            options: legConnectorTypesDropdown.options,
        },
        legCheckboxProps
    };
}

const useJacket = (breakout: IBreakout, sidePosition: string, context: IBreakoutEditContext) => {
    const display = useSelector(showBreakoutEditSelector);
    const { state, dispatch } = context;
    const { breakoutAttributes, legsAttributes, applyAll } = state;
    const breakoutJacketColor = useSelector(breakoutJacketColorSelectorFactory(breakout));
    const legJacketColor = useSelector(legJacketColorSelectorFactory(breakout));
    const jacketColors = useSelector(filteredJacketColorSelector);
    const { t } = useTranslation();
    const [showColorDialog, setShowColorDialog] = useState(false);
    const [isBreakoutJacketColor, setIsBreakoutJacketColor] = useState(false);
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        if (display) {
            dispatch(setBreakoutJacketColor(breakoutJacketColor));
            dispatch(setLegJacketColor(legJacketColor));
        }
    }, [display, dispatch, breakoutJacketColor, legJacketColor, breakoutAttributes.jacketColor]);

    const openColorDialog = useCallback((isBreakoutJacketColor: boolean) => {
        setShowColorDialog(true);
        setIsBreakoutJacketColor(isBreakoutJacketColor);
    }, []);

    const onBreakoutJacketPaletteClick = useCallback(() => {
        openColorDialog(true);
    }, [openColorDialog]);

    const onLegJacketPaletteClick = useCallback(() => {
        openColorDialog(false);
    }, [openColorDialog]);

    const breakoutJacketFieldProps = {
        className: "color-picker",
        label: `${sidePosition} ${t(LocalizationKeys.JacketColor)}`,
        value: breakoutAttributes.jacketColor.name,
        palette: MainPalettes.primary
    };

    const legJacketFieldProps = {
        className: "color-picker",
        label: `${sidePosition}' ${t(LocalizationKeys.JacketColor)}`,
        value: legsAttributes.jacketColor.name,
        palette: MainPalettes.primary
    }

    const onColorButtonClick = useCallback((color: IColor) => {
        const previousConnectorType = breakout.furcation.groups[0].connectors[0].type;
        const breakoutToUpdate: IBreakout = isBreakoutJacketColor ? { ...breakout, jacketColor: color.name } : { ...breakout, furcation: { ...breakout.furcation, jacketColor: color.name } };
        
        if (isBreakoutJacketColor) {
            dispatch(setBreakoutJacketColor(color));
            storeDispatch(updateBreakout(breakoutToUpdate, false, previousConnectorType));
        } else {
            dispatch(setLegJacketColor(color));
            storeDispatch(updateBreakout(breakoutToUpdate, applyAll, previousConnectorType));
        }
        storeDispatch(updateBreakout(breakoutToUpdate, false, previousConnectorType));
    }, [isBreakoutJacketColor, breakout, dispatch, storeDispatch, applyAll]);

    const closeColorDialog = useCallback(() => {
        setShowColorDialog(false);
    }, [])

    const currentColor = isBreakoutJacketColor ? breakoutAttributes.jacketColor : legsAttributes.jacketColor;
    const className = isBreakoutJacketColor ? "breakout-jacket" : "leg-jacket";
    const colorDialogProps: IColorDialogProps = {
        open: showColorDialog,
        onClose: closeColorDialog,
        className,
        currentColor,
        colors: jacketColors,
        onColorButtonClick
    }

    return { 
        breakoutJacket: {
            field: breakoutJacketFieldProps,
            onClick: onBreakoutJacketPaletteClick
        },
        legJacket: {
            field: legJacketFieldProps,
            onClick: onLegJacketPaletteClick
        },
        colorDialogProps,
    };
}

const useActions = (breakout: IBreakout, prevSideBreakouts: IBreakout[], setPrevSideBreakouts: Dispatch<SetStateAction<IBreakout[]>>, setHasLoadedAssemblies: (hasLoadedAssemblies: boolean) => void, closeBreakoutEdit: () => void, context: IBreakoutEditContext) => {
    const { state, dispatch } = context;
    const { id: assemblyId } = useSelector(currentAssemblySelector);
    const breakoutJacketColor = useSelector(breakoutJacketColorSelectorFactory(breakout));
    const legJacketColor = useSelector(legJacketColorSelectorFactory(breakout));
    const workspaceSaving = useSelector(workspaceSavingSelector);
    const sideBreakouts = useSelector(sideBreakoutsSelectorFactory(breakout.side));
    const { legsAttributes, applyAll } = state;
    const [showApplyAllConfirmDialog, setShowApplyAllConfirmDialog] = useState(false);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const prevSelectedBreakout = prevSideBreakouts.find(b => b.id === breakout.id);
    
    const applyDisabled = useMemo(() => {
        let disabled = workspaceSaving;
        if (prevSelectedBreakout) {
            const breakoutJacketColorModified = breakoutJacketColor !== getColor(prevSelectedBreakout.jacketColor ?? Yellow.name);
            const breakoutLabelModified = breakout.label !== prevSelectedBreakout.label;
            const breakoutLengthModified = breakout.trunk.length.value !== prevSelectedBreakout.trunk.length.value;
            const legJacketColorModified = legJacketColor !== getColor(prevSelectedBreakout.furcation.jacketColor ?? Yellow.name);
            const legConnectorTypeModified = breakout.furcation.groups[0].connectors[0].type !== prevSelectedBreakout.furcation.groups[0].connectors[0].type;
            disabled ||= (!breakoutJacketColorModified && !breakoutLabelModified && !breakoutLengthModified && !legJacketColorModified && !legConnectorTypeModified);
        }
        return disabled;
    }, [workspaceSaving, breakoutJacketColor, breakout, legJacketColor, prevSelectedBreakout]);

    const updatePolarityAssignments = useCallback((side: Side, breakoutPosition: number, previousType: string) => {
        if (assemblyId) {
            storeDispatch(updateAssignmentConnectorTypes(assemblyId, applyAll, side, breakoutPosition, previousType, legsAttributes.connectorType));
        }
    }, [assemblyId, storeDispatch, applyAll, legsAttributes.connectorType]);

    const onUpdateBreakout = useCallback(() => {
        storeDispatch(setStatus(Status.Synchronizing));
        if (prevSelectedBreakout) {
            const previousConnectorType = prevSelectedBreakout.furcation.groups[0].connectors[0].type;
            storeDispatch(updateBreakout(breakout, applyAll, previousConnectorType, true));
            updatePolarityAssignments(breakout.side, breakout.position, previousConnectorType);
        }
        setHasLoadedAssemblies(false);
        setPrevSideBreakouts(sideBreakouts);
    }, [breakout, storeDispatch, applyAll, updatePolarityAssignments, setHasLoadedAssemblies, prevSelectedBreakout, sideBreakouts, setPrevSideBreakouts]);
    
    const onApply = useCallback(() => {
        if (applyAll) {
            setShowApplyAllConfirmDialog(true);
        } else {
            onUpdateBreakout();
            closeBreakoutEdit();
        }
    }, [applyAll, onUpdateBreakout, closeBreakoutEdit]);

    const onApplyAllConfirmClose = useCallback(() => {
        setShowApplyAllConfirmDialog(false);
    }, [])

    const onApplyAllConfirm = useCallback(() => {
        onUpdateBreakout();
        setShowApplyAllConfirmDialog(false);
        dispatch(setApplyAll(false));
        closeBreakoutEdit();
    }, [dispatch, onUpdateBreakout, closeBreakoutEdit]);

    const applyAllActions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onApplyAllConfirmClose,
        confirmText: t(LocalizationKeys.ApplyToAllBreakouts), 
        onConfirmClick: onApplyAllConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const applyAllConfirmDialogProps: DialogProps = {
        open: showApplyAllConfirmDialog,
        className: "reset-connector-assignement-dialog",
        title: t(LocalizationKeys.LegsAttributes),
        message: t(LocalizationKeys.LegsAttributesConfirm),
        actions: applyAllActions,
        onClose: onApplyAllConfirmClose
    };

    return { applyDisabled, onApply, applyAllConfirmDialogProps, applyAll };
}