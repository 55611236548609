import LoginBackground from "./resources/image_login_background.jpg"
import CorningGlassLogo from "./resources/image_corning_glass_logo.png";

export interface ILoginState {
    email: string,
    password: string,
    showPassword: boolean,
}

export const LoginBackgroundUri = LoginBackground;
export const CorningGlassLogoUri = CorningGlassLogo;