import React from 'react';
import { useDetailsPanel } from './hooks';
import { TextButton } from '@orbit/button';
import { Divider } from '@mui/material';
import { Typography } from '@orbit/theme-provider';

import './DetailsPanel.scss';

export const DetailsPanel: React.FC = () => {
    const { tnc, details } = useDetailsPanel();
    return (
        <div className="details-panel">
            <TextButton {...tnc.buttonProps}>
                {tnc.label}
            </TextButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="details">
                <Typography variant="caption" label={details} />
            </div>
        </div>
    )
}