import { ILegRowProps } from "./types";
import { useLengthField } from "../../../length/hooks";
import { IEditableCellProps } from "../../../../../ui/table/cell/types";
import { useInputField } from "../../../../../ui/input/hooks";
import { ChangeEvent, useCallback, useContext } from "react";
import { BreakoutDetailsContext, updateSelectedLegs } from "../store/reducer";
import { CheckboxProps } from "@orbit/checkbox";
import { MainPalettes } from "@orbit/theme-provider";

export const useLegRow = ({ data, onFocus, onBlur, legLengthCallback, legLabelCallback }: ILegRowProps) => {
    const { state: breakoutDetailsState, dispatch: breakoutDetailsDispatch } = useContext(BreakoutDetailsContext);
    const { selectedLegs } = breakoutDetailsState;
    const legLengthField = useLengthField({ label: "", length: data.legLength, callback: legLengthCallback });

    const onCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        breakoutDetailsDispatch(updateSelectedLegs(data.id));
    }, [breakoutDetailsDispatch, data.id]);

    const checkboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        checked: selectedLegs.includes(data.id),
        onChange: onCheckboxChanged
    };

    const editableLegLengthProps: IEditableCellProps = {
        cellProps: { className: "leg-length", align: "left", onBlur },
        value: legLengthField.value,
        maxLength: 5,
        onChange: legLengthField.onChange,
        invalid: !legLengthField.isValid,
    };

    const legLabelField = useInputField("", data.label, 10, undefined, validateInputChars, legLabelCallback);
    const editableLegLabelProps: IEditableCellProps = {
        cellProps: { className: "leg-label", align: "left", onBlur },
        value: legLabelField.value,
        maxLength: 10, 
        onChange: legLabelField.onChange!,
        invalid: !legLabelField.isValid
    }

    return { data, onFocus, checkboxProps, editableLegLengthProps, editableLegLabelProps };
}

export const validateInputChars = (value: string) => {
    const stringValue = value.trim();
    return stringValue !== "" || stringValue.length !== 0; 
}