import React from 'react'
import { GeneralSetup } from './general-setup/GeneralSetup'
import { SetupFooter } from './setup-footer/SetupFooter';
import { SetupHeader } from './setup-header/SetupHeader';
import { BreakoutSetup } from './breakout-setup/BreakoutSetup';
import { CableSetupContext } from './reducer';
import { useCableSetup } from './hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';

import "./CableSetup.scss";

export const CableSetup: React.FC = () => {
    const { t } = useTranslation();
    const { context: {state, dispatch}, stepTitle, breakoutSteps, display} = useCableSetup();
    return (
        <CableSetupContext.Provider value={{state, dispatch}}>
            {display && <div className="card-container">
                <div className="cable-setup">
                    <SetupHeader title={t(LocalizationKeys.AssemblySetup)} step={stepTitle} />
                    <GeneralSetup />
                    {breakoutSteps.map(b => {
                        return (
                            <BreakoutSetup {...b} key={b.key} />
                        )
                    })}
                    <SetupFooter />
                </div>
            </div>}
        </CableSetupContext.Provider>
    )
}