import React from "react";
import { PredefinedPolarityMaps } from "../../polarity/store/types";

import "./PolarityDiagrams.scss";

export const PolarityDiagrams: React.FC = () => {
    return (
        <div id="polarity-container">
            { PredefinedPolarityMaps.map(m => <img key={m.key} id={`polarity-image-${m.key}`} alt={m.description} src={m.imageUri} /> ) }
        </div>
    )
};