import { createSelector } from "reselect";
import { DrawerStatus } from "./types";
import { TNC_CURRENT_VERSION } from "../../../localization/types";
import { rootSelector } from "../../../store/selectors";

export const projectManagerSelector = createSelector(
    rootSelector,
    root => root.projectManager
);

export const pmHomeOpenSelector = createSelector(
    projectManagerSelector,
    pm => pm.open && pm.status === DrawerStatus.Home
);

export const pmEditOpenSelector = createSelector(
    projectManagerSelector,
    pm => pm.open && pm.status === DrawerStatus.Edit
);

export const tncDateSelector = createSelector(
    projectManagerSelector,
    pm => pm.tncAcceptedAt !== "" ? new Date(pm.tncAcceptedAt) : undefined
);

export const tncVersionSelector = createSelector(
    projectManagerSelector,
    pm => pm.tncAcceptedVersion
);

export const tncCurrentVersionAcceptedSelector = createSelector(
    tncVersionSelector,
    tncVersion => tncVersion === TNC_CURRENT_VERSION
);

export const pmSortOptionsSelector = createSelector(
    projectManagerSelector,
    pm => {
        return {
            lastSaveOrder: pm.lastSaveOrder, 
            sortAscending: pm.sortAscending
        }
    }
);

export const pmCurrentAssemblyInfoSelector = createSelector(
    projectManagerSelector,
    pm => pm.currentAssemblyInfo
);