import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from "react";
import { GenericAutocomplete } from "../../../../../ui/select/auto-complete/GenericAutocomplete";
import { FiberCountProps } from "./types";

export const FiberCount: React.FC<FiberCountProps> = ({ access, ...props }: FiberCountProps) => {
    return (
        <div className="field-container">
            { !access ? 
                <FormControl className='select'>
                    <InputLabel>{props.label}</InputLabel>
                    <Select variant="filled" value={props.value} onChange={(e) => props.onChange(e.target.value)}>
                        { props.options.map((o, i) => <MenuItem key={`fiber-count-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl> :
                <GenericAutocomplete {...props} />
            }
        </div>
    )
};