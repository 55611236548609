import { createSlice } from "@reduxjs/toolkit";
import { initialReportState } from "./types";
import { setAssemblyDocumentAction, setUserProvidedImagesAction, showPrintDialogAction } from "./actions";

const reportSlice = createSlice({
    name: "report",
    initialState: initialReportState,
    reducers: {
        showPrintDialog: showPrintDialogAction,
        setAssemblyDocument: setAssemblyDocumentAction,
        setUserProvidedImages: setUserProvidedImagesAction
    }
})

export const ReportReducer = reportSlice.reducer;
export const { showPrintDialog, setAssemblyDocument, setUserProvidedImages } = reportSlice.actions;