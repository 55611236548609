import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import { LocalizationKeys } from "../../../localization/types";
import { incrementViewportScale } from "../../../pixi/viewport/store/reducer";
import { viewportScalePercentSelector } from "../../../pixi/viewport/store/selectors";
import { useStoreDispatch } from "../../../store/hooks";

export const useScalePanel = () => {
    const storeDispatch = useStoreDispatch();
    const scalePercent = useSelector(viewportScalePercentSelector);
    const { t } = useTranslation();

    const zoom = (increment: number) => storeDispatch(incrementViewportScale(increment));

    const zoomIn = {
        className: "zoom-in-icons",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.ZoomIn),
        placement: "top" as TooltipPlacement,
        onClick: () => zoom(0.1)
    };

    const zoomOut = {
        className: "zoom-out-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.ZoomOut),
        placement: "top" as TooltipPlacement,
        onClick: () => zoom(-0.1)
    };

    return { zoomIn, zoomOut, scalePercent };
}