import { useSelector } from "react-redux"
import { Sides } from "../../assembly/breakout/types"
import { currentAssemblyFiberCountSelector, sideFiberCountSelectorFactory } from "../../assembly/store/selectors"
import { workspaceLoadingSelector } from "../../store/selectors"

export const useFiberCounter = () => {
    const workspaceLoading = useSelector(workspaceLoadingSelector);
    const sideAFiberCount = useSelector(sideFiberCountSelectorFactory(Sides.SideA));
    const assemblyFiberCount = useSelector(currentAssemblyFiberCountSelector);
    const sideBFiberCount = useSelector(sideFiberCountSelectorFactory(Sides.SideB));

    const sideAClassName = sideAFiberCount > assemblyFiberCount ? "side-a error" : "side-a";
    const sideBClassName = sideBFiberCount > assemblyFiberCount ? "side-b error" : "side-b";

    return {
        workspaceLoading,
        sideA: {
            className: sideAClassName,
            fiberCount: sideAFiberCount
        },
        assemblyFiberCount,
        sideB: {
            className: sideBClassName,
            fiberCount: sideBFiberCount
        }, 
    };
}