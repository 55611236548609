import * as Pixi from 'pixi.js';
import { ComponentName } from '../../workspace/assembly/types';

class CanvasExtractor {
    public static canvasExtractor: CanvasExtractor | null = null;

    private app: Pixi.Application;
    private extract: Pixi.Extract;
    private canvasContainer: Pixi.Container | null = null;
    private assemblyContainer: Pixi.Container | null = null;

    private constructor(app: Pixi.Application) {
        this.app = app;
        this.extract = app.renderer.plugins.extract;
    }

    public static initialize(app: Pixi.Application) {
        if (this.canvasExtractor === null) {
            this.canvasExtractor = new CanvasExtractor(app);
        }
        return this.canvasExtractor;
    }

    public static getInstance() {
        return this.canvasExtractor;
    }

    public setCanvasContainer = () => {
        this.canvasContainer = this.app.stage.children[0] as Pixi.Container;
        const viewport = this.app.stage.children[2] as Pixi.Container;
        if (viewport) {
            this.assemblyContainer = viewport.children[0] as Pixi.Container
        }
    }

    public getDisplayObjects = (levels: number, container?: Pixi.Container, ...components: ComponentName[]) => {
        const displayList = Object.fromEntries(components.map(c => [c, new Array<Pixi.DisplayObject>()]));
        const parent = container ? container : this.assemblyContainer!

        if (!parent) return {}
        const component = components.find(c => c === parent.name)
        if (component) {
            displayList[component].push(parent);
        }
        if (levels === 0) {
            return displayList;
        }

        for (let i = 0; i < parent.children.length; i++) {
            const child = parent.children[i];
            const component = components.find(c => c === child.name);
            if (component) {
                displayList[component] = [...displayList[component], child];
            }
        }

        if (levels > 1) {
            for (let i = 0; i < parent.children.length; i++) {
                const child = parent.children[i] as Pixi.Container;
                if (child && child.children.length > 0 && child.name) {
                    const childDisplayList = this.getDisplayObjects(levels - 1, child, ...components);
                    const keys = Object.keys(childDisplayList);
                    keys.forEach(k => {
                        displayList[k] = [...displayList[k], ...childDisplayList[k]]
                    });
                }
            }
        }

        return displayList;
    }

    public getImageElements = (...components: ComponentName[]) => {
        this.setCanvasContainer();

        const imageElements = Object.fromEntries(components.map(c => [c, new Array<HTMLCanvasElement>()]));
        const displayObjects = this.getDisplayObjects(1, this.canvasContainer!, ...components);
        components.forEach(comp => {
            const components = displayObjects[comp];
            components?.map(c => c as Pixi.Container).forEach(component => {
                const imageElement = this.extract.canvas(component);
                imageElement.className = component.name;
                const context = imageElement.getContext('2d');
                if (context) {
                    context.globalCompositeOperation = 'destination-over'
                    context.fillStyle = "white";
                    context.fillRect(0, 0, imageElement.width, imageElement.height);
                }
                imageElements[comp].push(imageElement)
            })
        });

        return imageElements;
    }
}

export const initializeCanvasExtractor = (app: Pixi.Application) => CanvasExtractor.initialize(app);
export const pixiToImage = (...components: ComponentName[]) => CanvasExtractor.getInstance()!.getImageElements(...components);

export const canvasExtractor = () => { 
    const instance = CanvasExtractor.getInstance()
    if (instance) {
        instance.setCanvasContainer();
    }
    return instance;
}