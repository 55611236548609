import { Side } from "../../assembly/breakout/types";
import { ConnLC, ConnLCSimplex, ConnMTP12_NP, ConnMTP24_NP, ConnMTP8_NP, IConnectorType } from "../../assembly/connector/types";

export interface IFiberMapData {
    id?: number;
    userId?: number;
    key: number;
    name: string;
    versionDate?: string;
    sourceIndices: IFiberMapIndex[];
    destinationIndices: IFiberMapIndex[];
}

export interface IConnectorModel {
    id: string;
    name?: string;
    fiberCount: number;
    fiberIndices: IFiberMapIndex[];
}

export interface IFiberMapIndex {
    id?: number,
    side?: Side,
    breakoutPosition?: number;
    index: number;
    assignedIndex: number;
    connectorType?: string;
    connector?: IConnectorModel;
    transceiver?: number;
}

export interface IPinMap {
    pinIndex: number;
    side: Side;
    breakout: {
        index: number;
    }
    connector: {
        index: number;
    }
}

export interface IPinAssignmentMap {
    sideAPin: IPinMap;
    sideBPin: IPinMap;
}

export interface IFiberMap {
    name: string;
    versionDate?: string;
    pinAssignments: IPinAssignmentMap[];
    unused: IPinMap[];
}

export const AssignedIndexCodes = {
    Unassigned: -1,
    Blocked: -2,
} as const;

export type AssignedIndexCode = typeof AssignedIndexCodes[keyof typeof AssignedIndexCodes];

export function connectorModelToConnectorType(model: IConnectorModel | undefined): IConnectorType | undefined {
    if (!model) return undefined
    switch (model.fiberCount) {
        case 24:
            return ConnMTP24_NP
        case 12:
            return ConnMTP12_NP
        case 8:
            return ConnMTP8_NP
        case 2:
            return ConnLC
        case 1:
            return ConnLCSimplex
        default:
            break;
    }
}