import { IPosition } from "../../types";

export interface IBreakoutAnnotationProps {
    position: number;
    furcationPosition: IPosition;
    legEnd: IPosition;
    connectorType: string;
    nbConnectors: number;
}

export const BreakoutAnnotationContainer = "breakout-annotation-container";