import { PayloadAction } from "@reduxjs/toolkit";
import { OffsetType, OffsetTypes, Side, Sides } from "../../../assembly/breakout/types";
import { convertTo, Unit } from "../../../assembly/length/types";
import { defaultUniformGroupStagger, IBreakoutSet, IBreakoutSetSetup, IGroupStagger, initialBreakoutSet, initialGroupStagger } from "../breakout-setup/types";
import { IBreakoutStep } from "./types";

export const pushBreakoutSetAction = (state: IBreakoutStep, action: PayloadAction<IBreakoutSet>) => {
    const breakoutSet = action.payload;
    const currentStep = getCurrentBreakoutSet(state);
    const sets = currentStep.sets;
    sets.push(breakoutSet)
    const totalCount = sets.map(s => s.count).reduce((a, b) => {
        return Number.parseInt(a.toString()) + Number.parseInt(b.toString())
    })

    currentStep.current = { ...initialBreakoutSet, startPosition: totalCount + 1, side: breakoutSet.side };
    state.isModified = true;
}

export const undoBreakoutSetAction = (state: IBreakoutStep) => {
    const currentStep = getCurrentBreakoutSet(state);
    if (currentStep.sets.length > 0) {
        currentStep.current = currentStep.sets.pop()!
    }
}

export const resetBreakoutSetupAction = (state: IBreakoutStep, action: PayloadAction<Side | undefined>) => {
    const side = action.payload;
    const breakoutSets = state.breakoutSetups;
    if (side) {
        side === Sides.SideA ?
            resetBreakoutSetup(breakoutSets[0]) :
            resetBreakoutSetup(breakoutSets[1])
    }
    else {
        breakoutSets.forEach(b => resetBreakoutSetup(b))
    }
}

function resetBreakoutSetup(setSetup: IBreakoutSetSetup) {
    setSetup.sets = []
    setSetup.current = { ...initialBreakoutSet, side: setSetup.current.side };
}

export const setCurrentBreakoutAction = (state: IBreakoutStep, action: PayloadAction<Partial<IBreakoutSet>>) => {
    const { connectorGroupCount, connectorType, count, groupCount, lengthA, lengthAp, groupStagger, startPosition, spareCount, side, offsetType } = action.payload;
    const currentSetup = getCurrentBreakoutSet(state);
    const current = currentSetup.current;

    if (groupCount !== undefined) {
        current.groupCount = groupCount;
        if (current.offsetType !== OffsetTypes.Uniform) {
            current.groupStagger = resetGroupStagger(current.offsetType, groupCount)
        }
    } 
        
    if (connectorGroupCount !== undefined) current.connectorGroupCount = connectorGroupCount;
    if (spareCount !== undefined) current.spareCount = spareCount;
    if (connectorType) current.connectorType = connectorType;
    if (count !== undefined) current.count = count;
    if (lengthA) current.lengthA = lengthA;
    if (lengthAp) current.lengthAp = lengthAp;
    if (groupStagger !== undefined) current.groupStagger = groupStagger;
    if (startPosition !== undefined) current.startPosition = startPosition;
    if (side !== current.side) current.side = side; // undefined is used as default value

    if (offsetType !== undefined) {
        current.offsetType = offsetType;
        current.groupStagger = resetGroupStagger(offsetType, current.groupCount);
    } 
}

function resetGroupStagger(offsetType: OffsetType, groupCount: number) {
    switch (offsetType) { 
        case OffsetTypes.Custom:
            return Array(groupCount).fill(initialGroupStagger);
        case OffsetTypes.Uniform:
            return [ defaultUniformGroupStagger ];
        default:
            return [ initialGroupStagger ];
    }
}

function getCurrentBreakoutSet(state: IBreakoutStep): IBreakoutSetSetup {
    return state.breakoutSetups[state.setupIndex]
}

type setBreakoutStepStateArgs = Partial<Omit<IBreakoutStep, "isModified">>
export const setBreakoutStepStateAction = (state: IBreakoutStep, action: PayloadAction<setBreakoutStepStateArgs>) => {
    const newState = action.payload;
    const unit = state.breakoutSetups[0].current.lengthA.unit;

    if (newState.breakoutSetups !== undefined) {
        state.breakoutSetups = newState.breakoutSetups.map(setup => {
            return {
                current: convertBreakoutSet(setup.current, unit),
                sets: setup.sets.map(s => convertBreakoutSet(s, unit))
            }
        });
    }
    if (newState.setupIndex !== undefined) state.setupIndex = newState.setupIndex;

    state.isModified = state.breakoutSetups.some(b => b.sets.length > 0)
}

export const convertLengthUnitsAction = (state: IBreakoutStep, action: PayloadAction<Unit>) => {
    const { payload: unit } = action;
    for (let i = 0; i < state.breakoutSetups.length; i++) {
        const setup = state.breakoutSetups[i]
        const sets = [...setup.sets, setup.current]
        for (let i = 0; i < sets.length; i++) {
            const set = sets[i];
            set.lengthA = convertTo(set.lengthA, unit);
            set.lengthAp = convertTo(set.lengthAp, unit)
            const setGroupStagger: IGroupStagger[] = [];
            for (let groupStagger of set.groupStagger) {
                setGroupStagger.push({
                    legStagger: convertTo(groupStagger.legStagger, unit),
                    offset: convertTo(groupStagger.offset, unit)
                })
            }
            set.groupStagger = setGroupStagger;
        }
    }
}

function convertBreakoutSet(set: IBreakoutSet, unit: Unit): IBreakoutSet {
    return { ...set, lengthA: convertTo(set.lengthA, unit), lengthAp: convertTo(set.lengthAp, unit) }
}