import React from 'react';
import { IAssemblyInfoToolBarProps } from './types';
import { useAssemblyInfoToolbar } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { InputDialog } from '../../../../ui/dialog/input/InputDialog';
import { ControlPointDuplicate, Delete, Edit } from '@mui/icons-material';

export const AssemblyInfoToolBar: React.FC<IAssemblyInfoToolBarProps> = (props) => {
    const { duplicateProps, editProps, deleteProps, duplicateDialogProps } = useAssemblyInfoToolbar(props);
    return (
        <>
            <div className="assembly-info-toolbar animated-button">
                <IconButton {...duplicateProps} icon={<ControlPointDuplicate />} />
                <IconButton {...editProps} icon={<Edit />} />
                <IconButton {...deleteProps} icon={<Delete />} />
            </div>
            <InputDialog {...duplicateDialogProps} />
        </>
    )
}