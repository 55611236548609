import { PayloadAction } from "@reduxjs/toolkit";
import { IBreakoutSet, IBreakoutSetSetup, IGroupStagger, initialGroupStagger } from "../../wizard/cable-setup/breakout-setup/types";
import { IBreakout, ICableTrunk, IConnector, IConnectorGroup, IConnectorWithPositions, OffsetTypes, Side, Sides } from '../breakout/types';
import { convertTo, Length, Units } from "../length/types";
import { CableEnvironment, CableType, FiberType, FurcationOuterDiameters, getAssemblyDefaultPalette, IAssembly, IAssemblySide, initialAssembly, LSZH, PullingGrips } from "../types";
import { getSideFiberCount } from "./selectors";
import { getDefaultDescription, IAssemblyInfo } from "../info/types";
import { IAssemblyState } from "./types";
import { IConnectorAssignmentMap } from "../../polarity/connector-assignment/reducer/types";
import { IAssemblyAnnotation } from "../annotation/types";
import { IAssemblyPalette } from "../palette/types";
import { PolaritySortTypes } from "../settings/types";
import { ITolerance } from "../../../pixi/markers/tolerance/types";
import { Yellow } from "../../../ui/dialog/color/types";

export const showCableBaseReferencesAction = (state: IAssemblyState, action: PayloadAction<boolean>) => {
    if(state.currentAssembly.assemblyInfo){
        state.currentAssembly.assemblyInfo.showA0B0 = action.payload;
    }
}

export const showToleranceMarkerAction = (state: IAssemblyState, action: PayloadAction<boolean>) => {
    if (state.currentAssembly.assemblyInfo){
        state.currentAssembly.assemblyInfo.showLabelDistance = action.payload;
    }
}

export const showPullingGripAction = (state: IAssemblyState, action: PayloadAction<boolean>) => {
    if (state.currentAssembly.assemblyInfo){
        state.currentAssembly.assemblyInfo.showPullingGrip = action.payload;
    }
}

export const loadUserAssembliesAction = (state: IAssemblyState, action: PayloadAction<IAssemblyInfo[]>) => {
    state.assemblies = action.payload;
}

export const addAssemblyAction = (state: IAssemblyState, action: PayloadAction<IAssembly>) => {
    const assembly = action.payload;
    state.currentAssembly = assembly;
    if (assembly.assemblyInfo && !state.assemblies.find(a => a.assemblyId === assembly.id)) {
        state.assemblies.push({ ...assembly.assemblyInfo });
    }
};

export const addConnectorAssignmentAction = (state: IAssemblyState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignment = action.payload;
    if (state.currentAssembly.polarity) {
        state.currentAssembly.polarity.connectorAssignments.push(assignment);
    } else {
        state.currentAssembly.polarity = { id: assignment.polarityId, assemblyId: state.currentAssembly.id, connectorAssignments: [assignment] };
    }
}

export const setAnnotationsAction = (state: IAssemblyState, action: PayloadAction<string[]>) => {
    if (state.currentAssembly.annotation){
        state.currentAssembly.annotation.calloutVisibility = action.payload;
    }
}

export const updateAnnotationsAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    const annotation = action.payload;
    if ( state.currentAssembly.annotation && state.currentAssembly.annotation.calloutVisibility.includes(annotation)) {
        state.currentAssembly.annotation.calloutVisibility =  state.currentAssembly.annotation.calloutVisibility.filter(a => a !== annotation);
    } else if (state.currentAssembly.annotation) {
        state.currentAssembly.annotation.calloutVisibility.push(annotation);
    }
}

export const clearAnnotationsAction = (state: IAssemblyState) => {
    if ( state.currentAssembly.annotation){
        state.currentAssembly.annotation.calloutVisibility = []
    }
}

export const addConnectorAssignmentsAction = (state: IAssemblyState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignments = action.payload;
    if (state.currentAssembly.polarity) {
        state.currentAssembly.polarity.connectorAssignments.push(...assignments);
    } else {
        state.currentAssembly.polarity = { id: assignments[0].polarityId, assemblyId: state.currentAssembly.id, connectorAssignments: assignments };
    }
}

export const updateAssemblyAction = (state: IAssemblyState, action: PayloadAction<IAssembly>) => {
    const assembly = action.payload;
    updateAssembly(state, assembly);
};

export const updateAssembly = (state: IAssemblyState, assembly: IAssembly) => {
    if (assembly.id) {
        const index = state.assemblies.findIndex(a => a.id === assembly.id);
        if (assembly.assemblyInfo) {
            if (index < 0) {
                state.assemblies.push(assembly.assemblyInfo);
            }
            else if (index > -1) {
                state.assemblies[index] = { ...assembly.assemblyInfo }
            }
        }
    }
}

export const updateAssemblyInfoAction = (state: IAssemblyState, action: PayloadAction<IAssemblyInfo>) => {
    const assemblyInfo = action.payload;
    if (assemblyInfo.assemblyId) {
        const index = state.assemblies.findIndex(i => i.id === assemblyInfo.id);
        if (index < 0) {
            state.assemblies.push(assemblyInfo);
        } else {
            state.assemblies[index] = assemblyInfo;
            if (state.currentAssembly.id === assemblyInfo.assemblyId) {
                state.currentAssembly.assemblyInfo = assemblyInfo;
            }
        }
    }
}

export const updateAssemblyPaletteAction = (state: IAssemblyState, action: PayloadAction<IAssemblyPalette>) => {
    state.currentAssembly.palette = action.payload;
}

const updateConnectorAssignment = (state: IAssemblyState, assignment: IConnectorAssignmentMap) => {
    if (state.currentAssembly.polarity && assignment.id) {
        const index = state.currentAssembly.polarity.connectorAssignments.findIndex(a => a.id === assignment.id);
        if (index < 0) {
            state.currentAssembly.polarity.connectorAssignments.push(assignment);
        } else {
            state.currentAssembly.polarity.connectorAssignments[index] = { ...assignment };
        }
    }
}

export const updateConnectorAssignmentAction = (state: IAssemblyState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignment = action.payload;
    updateConnectorAssignment(state, assignment);
}

export const updateConnectorAssignmentsAction = (state: IAssemblyState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignments = action.payload;
    for (const assignment of assignments) {
        updateConnectorAssignment(state, assignment);
    }
}

export const deleteAssemblyAction = (state: IAssemblyState, action: PayloadAction<number>) => {
    state.assemblies = state.assemblies.filter(a => a.assemblyId !== action.payload);
};

export const deleteConnectorAssignmentAction = (state: IAssemblyState, action: PayloadAction<number>) => {
    if (state.currentAssembly.polarity) {
        state.currentAssembly.polarity.connectorAssignments = state.currentAssembly.polarity.connectorAssignments.filter(a => a.id !== action.payload);
    }
}

export const deleteConnectorAssignmentsAction = (state: IAssemblyState) => {
    if (state.currentAssembly.polarity) {
        state.currentAssembly.polarity.connectorAssignments = [];
    }
}

export const setCurrentAssemblyAction = (state: IAssemblyState, action: PayloadAction<IAssembly>) => {
    state.currentAssembly = action.payload;
}

export const resetCurrentAssemblyAction = (state: IAssemblyState) => {
    const emptyAssemblyReference = { id: 0, assemblyId: 0 }
    state.currentAssembly.fiberCount = initialAssembly.fiberCount;
    state.currentAssembly.overallLength = { id: 0, value: 0, unit: Units.Meter };
    state.currentAssembly.palette = {
        ...emptyAssemblyReference,
        jacketColor: Yellow.name,
        sideATrunkColor: Yellow.name,
        sideALegColor: Yellow.name,
        sideBTrunkColor: Yellow.name,
        sideBLegColor: Yellow.name
    };
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.id = 0;
        state.currentAssembly.assemblyInfo.assemblyId = 0;
        if (state.currentAssembly.assemblyInfo.fiberType) {
            state.currentAssembly.assemblyInfo.description = getDefaultDescription(state.currentAssembly.assemblyInfo.fiberType, state.currentAssembly.fiberCount);
        }
        state.currentAssembly.assemblyInfo.partNumber = "";
        state.currentAssembly.assemblyInfo.flameRating = LSZH.value;
        state.currentAssembly.assemblyInfo.pullingGrip = PullingGrips.None;
        state.currentAssembly.assemblyInfo.endADesignation = undefined;
        state.currentAssembly.assemblyInfo.endBDesignation = undefined;
        state.currentAssembly.assemblyInfo.endADescription = undefined;
        state.currentAssembly.assemblyInfo.endBDescription = undefined;
        state.currentAssembly.assemblyInfo.polaritySortType = PolaritySortTypes.EndA;
        state.currentAssembly.assemblyInfo.furcationOuterDiameter = FurcationOuterDiameters[0];
        state.currentAssembly.assemblyInfo.drawingNumber = "";
        state.currentAssembly.assemblyInfo.application = "";
        state.currentAssembly.assemblyInfo.approvalDate = "";
        state.currentAssembly.assemblyInfo.inServiceDate = "";
        state.currentAssembly.assemblyInfo.revision = "";
        state.currentAssembly.assemblyInfo.headerTitle = "";
        state.currentAssembly.assemblyInfo.drawingTitle = "";
        state.currentAssembly.assemblyInfo.legTolerance = initialAssembly.assemblyInfo?.legTolerance;
        state.currentAssembly.assemblyInfo.legPrimeTolerance = initialAssembly.assemblyInfo?.legPrimeTolerance;
        state.currentAssembly.assemblyInfo.legLabelTolerance = initialAssembly.assemblyInfo?.legLabelTolerance;
        state.currentAssembly.assemblyInfo.location = "";
        state.currentAssembly.assemblyInfo.notes = "";
        state.currentAssembly.assemblyInfo.drawnBy = "";
        state.currentAssembly.assemblyInfo.buildPlan = true;
        state.currentAssembly.assemblyInfo.connectorViews = true;
        state.currentAssembly.assemblyInfo.polarityDiagram = false;
        state.currentAssembly.assemblyInfo.uom = undefined;
    }
    state.currentAssembly.polarity = {
        ...emptyAssemblyReference,
        connectorAssignments: []
    };
    state.currentAssembly.annotation = {
        ...emptyAssemblyReference,
        notes: "",
        calloutVisibility: []
    };
}

export const setAssembliesLoadedAction = (state: IAssemblyState, action: PayloadAction<boolean>) => {
    state.loaded = action.payload;
}

export const setupOverallLengthAction = (state: IAssemblyState, action: PayloadAction<Length>) => {
    state.currentAssembly.overallLength = action.payload;
}

export const setupEndDesignationAction = (state: IAssemblyState, action: PayloadAction<{ end: Side; designation: string | undefined }>) => {
    const { assemblyInfo } = state.currentAssembly;
    if (!assemblyInfo) {
        return;
    }

    const { end, designation } = action.payload;
    if (end === Sides.SideA) {
        assemblyInfo.endADesignation = designation;
    } else {
        assemblyInfo.endBDesignation = designation;
    }
}

export const setupFiberCountAction = (state: IAssemblyState, action: PayloadAction<number>) => {
    state.currentAssembly.fiberCount = action.payload;
}

export const setupFlameRatingAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    const currentAssemblyInfo =  state.currentAssembly.assemblyInfo;
    if (currentAssemblyInfo) {
        currentAssemblyInfo.flameRating = action.payload;
    }
}

export const setupPullingGripAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    const currentAssemblyInfo = state.currentAssembly.assemblyInfo;
    if (currentAssemblyInfo) {
        currentAssemblyInfo.pullingGrip = action.payload;
    }
}

export const setupFurcationOuterDiameterAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    const currentAssemblyInfo = state.currentAssembly.assemblyInfo;
    if (currentAssemblyInfo) {
        currentAssemblyInfo.furcationOuterDiameter = action.payload;
    }
}

export const setupCableTypeAction = (state: IAssemblyState, action: PayloadAction<CableType>) => {
    const type = action.payload;
    const assembly = state.currentAssembly;
    
    assembly.assemblyInfo = assembly.assemblyInfo ? { ...assembly.assemblyInfo, type } : { type };
}

export const setLegTolerancesAction = (state: IAssemblyState, action: PayloadAction<ITolerance>) => {
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.legTolerance = action.payload;
        state.currentAssembly.assemblyInfo.legPrimeTolerance = action.payload;    
    }
}

export const setupLegLengthTolerancesAction = (state: IAssemblyState, action: PayloadAction<ITolerance>) => {
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.legTolerance = action.payload;
    }
}

export const setupLegPrimeTolerancesAction = (state: IAssemblyState, action: PayloadAction<ITolerance>) => {
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.legPrimeTolerance = action.payload;
    }
}

export const setupLegLabelTolerancesAction = (state: IAssemblyState, action: PayloadAction<ITolerance>) => {
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.legLabelTolerance = action.payload;
    }
}

export const setupFiberTypeAction = (state: IAssemblyState, action: PayloadAction<FiberType>) => {
    const fiberType = action.payload;
    const assembly  = state.currentAssembly;
    const environment = assembly.assemblyInfo?.environment;

    assembly.assemblyInfo = assembly.assemblyInfo ? { ...assembly.assemblyInfo, fiberType } : { fiberType };
    assembly.palette = getAssemblyDefaultPalette(fiberType, environment);
}

export const setupEnvironmentAction = (state: IAssemblyState, action: PayloadAction<CableEnvironment>) => {
    const assemblyInfo = state.currentAssembly.assemblyInfo;
    const environment = action.payload;
    const fiberType = assemblyInfo?.fiberType;
    if (assemblyInfo) {
        assemblyInfo.environment = environment;
    } else {
        state.currentAssembly.assemblyInfo = {
            environment
        }
    }
    state.currentAssembly.palette = getAssemblyDefaultPalette(fiberType, environment);
}

export const updateAssemblyDescriptionAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    if (state.currentAssembly.assemblyInfo) {
        state.currentAssembly.assemblyInfo.description = action.payload;
    }
}

export const setupJacketColorAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    if (state.currentAssembly.palette) {
        state.currentAssembly.palette.jacketColor = action.payload;
    }
}

export const setupTrunkColorAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    if (state.currentAssembly.palette) {
        const trunkColor = action.payload;
        state.currentAssembly.palette.sideATrunkColor = trunkColor;
        state.currentAssembly.palette.sideBTrunkColor = trunkColor;
    }
}

export const setupSideTrunkColorAction = (state: IAssemblyState, action: PayloadAction<{ side: Side, color: string }>) => {
    if (state.currentAssembly.palette) {
        const { side, color } = action.payload;
        if (side === Sides.SideA) {
            state.currentAssembly.palette.sideATrunkColor = color;
        } else {
            state.currentAssembly.palette.sideBTrunkColor = color;
        }
    }
}

export const setupLegColorAction = (state: IAssemblyState, action: PayloadAction<string>) => {
    if (state.currentAssembly.palette) {
        const legColor = action.payload;
        state.currentAssembly.palette.sideALegColor = legColor;
        state.currentAssembly.palette.sideBLegColor = legColor;
    }
}

export const setupSideLegColorAction = (state: IAssemblyState, action: PayloadAction<{ side: Side, color: string }>) => {
    if (state.currentAssembly.palette) {
        const { side, color } = action.payload;
        if (side === Sides.SideA) {
            state.currentAssembly.palette.sideALegColor = color;
        } else {
            state.currentAssembly.palette.sideBLegColor = color;
        }
    }
}

export const setupAssemblyBreakoutsAction = (state: IAssemblyState, action: PayloadAction<IBreakoutSetSetup>) => {
    const { currentAssembly } = state;
    const breakoutSetup = action.payload;
    const sets = [...breakoutSetup.sets, breakoutSetup.current]
    const sideABreakouts: IBreakout[] = []
    const sideBBreakouts: IBreakout[] = []
    const setupSide = breakoutSetup.current.side;

    for (let i = 0; i < sets.length; i++) {
        const set = sets[i];
        const startPosition = set.startPosition;

        const groups: IConnectorGroup[] = createConnectorGroups(set);

        for (let j = 0; j < set.count; j++) {
            if (set.side === Sides.SideA || !set.side) {
                const sideAbreakout: IBreakout = {
                    trunk: { length: convertTo({ ...set.lengthA }, Units.Millimeter) },
                    side: Sides.SideA,
                    position: startPosition ? startPosition + j : 1,
                    label: startPosition? (startPosition + j).toString() : "1",
                    furcation: {
                        groups: [...groups]
                    }
                }
                sideABreakouts.push(sideAbreakout)
            }

            if (set.side === Sides.SideB || !set.side) {
                const sideBbreakout: IBreakout = {
                    trunk: { length: convertTo({ ...set.lengthA }, Units.Millimeter) },
                    side: Sides.SideB,
                    position: startPosition ? startPosition + j : 1,
                    label: startPosition? (startPosition + j).toString() : "1",
                    furcation: {
                        groups: [...groups]
                    }
                }
                sideBBreakouts.push(sideBbreakout)
            }
        }
    }

    currentAssembly.id = 0;
    if (currentAssembly.assemblyInfo) {
        currentAssembly.assemblyInfo.id = 0
        currentAssembly.assemblyInfo.assemblyId = 0
        currentAssembly.assemblyInfo.name = ""
    }

    if (setupSide === Sides.SideA || !setupSide) {
        const previousSideA = currentAssembly.sideA;
        const sideA: IAssemblySide = { breakouts: sideABreakouts, fiberCount: getSideFiberCount(sideABreakouts) };
        if (previousSideA) {
            sideA.assemblyId = previousSideA.assemblyId;
        }
        currentAssembly.sideA = sideA;
    }

    if (setupSide === Sides.SideB || !setupSide) {
        const previousSideB = currentAssembly.sideB;
        const sideB: IAssemblySide = { breakouts: sideBBreakouts, fiberCount: getSideFiberCount(sideBBreakouts) };
        if (previousSideB) {
            sideB.assemblyId = previousSideB.assemblyId;
        }
        currentAssembly.sideB = sideB;
    }
}

export const updateSideBreakout = (previousSide: IAssemblySide, breakouts: IBreakout[]) => {
    const previousBreakouts = previousSide.breakouts;
    for (let bIdx = 0; bIdx < breakouts.length; bIdx++) {
        const previousBreakout = previousBreakouts[bIdx];
        const breakout = breakouts[bIdx];
        const previousTrunk = previousBreakout.trunk;
        const trunk = breakout.trunk;
        const previousFurcation = previousBreakout.furcation;
        const furcation = breakout.furcation;
        const previousGroups = previousFurcation.groups;
        const groups = furcation.groups;
        for (let gIdx = 0; gIdx < groups.length; gIdx++) {
            const previousGroup = previousGroups[gIdx];
            const group = groups[gIdx];
            const previousConnectors = previousGroup.connectors;
            const connectors = group.connectors;
            for (let cIdx = 0; cIdx < connectors.length; cIdx++) {
                const previousConnector = previousConnectors[cIdx];
                const connector = connectors[cIdx];
                if (previousConnector) {
                    connector.id = previousConnector.id;
                    connector.groupId = previousConnector.groupId;
                    if (connector.length && previousConnector.length) {
                        connector.length.id = previousConnector.length.id;
                    }
                }
            }
            if (previousGroup) {
                group.id = previousGroup.id;
                group.furcationId = previousGroup.furcationId;
                group.length.id = previousGroup.length.id;
            }
        }
        if (previousFurcation) {
            furcation.id = previousFurcation.id;
            furcation.breakoutId = previousFurcation.breakoutId;
        }
        if (previousTrunk) {
            trunk.id = previousTrunk.id;
            trunk.breakoutId = previousTrunk.id;
            trunk.length.id = previousTrunk.length.id;
        }
        if (previousBreakout) {
            breakout.id = previousBreakout.id;
            breakout.sideId = previousBreakout.sideId;
        }
    }

    return breakouts;
}

function createConnectorGroups(set: IBreakoutSet) {
    const groups: IConnectorGroup[] = [];
    if (set.groupCount > 0 && set.connectorGroupCount > 0) {
        for (let j = 0; j < set.groupCount; j++) {
            let lengthAp = set.lengthAp.value;
            let groupStagger: IGroupStagger = initialGroupStagger;
            let groupOffset = 0;
            let previousGroupStagger: IGroupStagger = initialGroupStagger;
            let previousStagger = 0;
            if (set.offsetType === OffsetTypes.Custom) {
                groupStagger = set.groupStagger[j];
                previousGroupStagger = set.groupStagger[j - 1];
            } else if (set.offsetType === OffsetTypes.Uniform) {
                groupStagger = set.groupStagger[0];
                previousGroupStagger = groupStagger;
            }
            if (j > 0) {
                const previousGroup = groups[j - 1];
                lengthAp = previousGroup.length.value;
                previousStagger = (set.connectorGroupCount - 1) * previousGroupStagger.legStagger.value;
                groupOffset = groupStagger.offset.value;
            }
            const groupStaggerOffset = previousStagger + groupOffset;
            const groupUnit = groupStagger.offset.unit;
            const groupLength = convertTo({ value: groupStaggerOffset + lengthAp, unit: groupUnit }, Units.Millimeter);
            const connectors: IConnector[] = [];

            for (let k = 0; k < set.connectorGroupCount; k++) {
                const legStagger = k * groupStagger.legStagger.value;
                const legUnit  = groupStagger.legStagger.unit;
                const connectorLength = convertTo({ value: legStagger + groupLength.value, unit: legUnit }, Units.Millimeter);

                const connector: IConnector = {
                    position: (j * set.connectorGroupCount) + (k + 1),
                    type: set.connectorType.type,
                    length: connectorLength
                };

                connectors.push(connector);
            }

            const connectorGroup: IConnectorGroup = {
                position: j + 1,
                length: groupLength,
                connectors,
                offset: { ...groupStagger.offset, value: groupOffset }
            };

            groups.push(connectorGroup);
        }
    }

    const spareCount = set.spareCount;
    if (spareCount) {
        const lastGroup = (groups.length > 0 && groups[groups.length - 1]) || {
            connectors: [],
            length: convertTo({ value: set.lengthAp.value, unit: set.lengthAp.unit }, Units.Millimeter),
            position: 0
        }
        const groupStagger = set.groupStagger[set.groupStagger.length - 1];
        const longestLegLength = (lastGroup.connectors.length > 0 && lastGroup.connectors[lastGroup.connectors.length - 1].length) || lastGroup.length;

        const spareLength = convertTo(longestLegLength, Units.Millimeter);
        const spareGroup: IConnectorGroup = {
            connectors: [],
            length: spareLength,
            position: lastGroup.position + 1,
            offset: { ...groupStagger.offset, value: 0 }
        };

        for (let k = 0; k < spareCount; k++) {
            const lastConnectorPosition = lastGroup.connectors.length > 0 ? lastGroup.connectors[lastGroup.connectors.length - 1].position : 0          
            const connector: IConnector = {
                position: lastConnectorPosition + (k + 1),
                type: set.connectorType.type,
                length: spareLength,
                spare: true
            };

            spareGroup.connectors.push(connector);
        }

        if (spareGroup.connectors.length) {
            groups.push(spareGroup);
        }
    }
    return groups;
}

export const updateBreakoutAction = (state: IAssemblyState, action: PayloadAction<IBreakout>) => {
    const assembly = state.currentAssembly;
    const breakout = action.payload;

    const assemblySide = breakout.side === Sides.SideA ? assembly.sideA : assembly.sideB;
    if (assemblySide) {
        const index = assemblySide.breakouts.findIndex(a => a.id === breakout.id);
        if (index < 0) {
            assemblySide.breakouts.push(breakout);
        } else {
            assemblySide.breakouts[index] = { ...breakout };
        }
    }

    if (breakout.side === Sides.SideA) {
        assembly.sideA = assemblySide;
    } else {
        assembly.sideB = assemblySide;
    }
}

export const updateBreakoutsAction = (state: IAssemblyState, action: PayloadAction<IBreakout[]>) => {
    for (const breakout of action.payload) {
        updateBreakoutAction(state, { payload: breakout, type: action.type });
    }
}

export const updateFurcationsAction = (state: IAssemblyState, action: PayloadAction<{ side: Side, jacketColor: string, previousConnectorType: string, newConnectorType: string, originalBreakouts?: IBreakout[], id?: number }>) => {
    const assembly = state.currentAssembly;
    const { side, jacketColor, previousConnectorType, newConnectorType, originalBreakouts, id } = action.payload;

    const assemblySide = side === Sides.SideA ? assembly.sideA : assembly.sideB;
    if (assemblySide) {
        for (const breakout of assemblySide.breakouts) {
            breakout.furcation.jacketColor = jacketColor;

            const connectorType = breakout.furcation.groups[0].connectors[0].type;

            let originalSelectedConnectorType = '';
            let originalCurrentConnectorType = '';
            if (originalBreakouts) {
                const originalSelectedBreakout = originalBreakouts.find(b => b.id === id);
                const originalCurrentBreakout = originalBreakouts.find(b => b.id === breakout.id);
                originalSelectedConnectorType = originalSelectedBreakout ? originalSelectedBreakout.furcation.groups[0].connectors[0].type : 'selected';
                originalCurrentConnectorType = originalCurrentBreakout ? originalCurrentBreakout.furcation.groups[0].connectors[0].type : 'current';
            }

            if (connectorType === previousConnectorType && originalSelectedConnectorType === originalCurrentConnectorType) {
                for (const group of breakout.furcation.groups) {
                    for (const connector of group.connectors) {
                        connector.type = newConnectorType;
                    }
                }
            }
        }
    }

    if (side === Sides.SideA) {
        assembly.sideA = assemblySide;
    } else {
        assembly.sideB = assemblySide;
    }
}

export const setTrunkAction = (state: IAssemblyState, action: PayloadAction<{side: Side, breakoutPosition: number, trunk: ICableTrunk}>) => {
    const assembly = state.currentAssembly;
    const { side, breakoutPosition, trunk } = action.payload;

    const assemblySide = side === Sides.SideA ? assembly.sideA : assembly.sideB;
    if (assemblySide) {
        const breakout = assemblySide.breakouts.find(b => b.position === breakoutPosition);
        if (breakout) {
            breakout.trunk = trunk;
        }
    }

    if (side === Sides.SideA) {
        assembly.sideA = assemblySide;
    } else {
        assembly.sideB = assemblySide;
    }
}

export const setConnectorAction = (state: IAssemblyState, action: PayloadAction<{side: Side, breakoutPosition: number, groupPosition: number, connector: IConnector}>) => {
    const assembly = state.currentAssembly;
    const { side, breakoutPosition, groupPosition, connector } = action.payload;

    const assemblySide = side === Sides.SideA ? assembly.sideA : assembly.sideB;
    if (assemblySide) {
        const breakout = assemblySide.breakouts.find(b => b.position === breakoutPosition);
        if (breakout) {
            const group = breakout.furcation.groups.find(g => g.position === groupPosition);
            if (group) {
                const connectorIndex = group.connectors.findIndex(c => c.position === connector.position);
                if (connectorIndex > -1) {
                    group.connectors[connectorIndex] = connector;
                }
            }
        }
    }

    if (side === Sides.SideA) {
        assembly.sideA = assemblySide;
    } else {
        assembly.sideB = assemblySide;
    }
}

const updateSideConnectors = (state: IAssemblyState, connectors: IConnectorWithPositions[]) => {
    const assembly = state.currentAssembly;
    let side;
    let assemblySide;

    for (const connector of connectors) {
        const { breakoutPosition, groupPosition } = connector;
        side = connector.side
        assemblySide = side === Sides.SideA ? assembly.sideA : assembly.sideB;
        if (assemblySide) {
            const breakout: IBreakout = assemblySide.breakouts.find(b => b.position === breakoutPosition);
            if (breakout) {
                const group = breakout.furcation.groups.find(g => g.position === groupPosition);
                if (group) {
                    const index = group.connectors.findIndex(c => c.position === connector.position);
                    if (index !== -1) {
                        group.connectors[index] = connector;   
                    }
                }
            }
        }
    }

    if (side === Sides.SideA) {
        assembly.sideA = assemblySide;
    } else {
        assembly.sideB = assemblySide;
    }
}

export const setConnectorsAction = (state: IAssemblyState, action: PayloadAction<IConnectorWithPositions[]>) =>{
    const sideAConnectors = action.payload.filter(b => b.side === Sides.SideA);
    const sideBConnectors = action.payload.filter(b => b.side === Sides.SideB);
    if (sideAConnectors.length > 0){
        updateSideConnectors(state, sideAConnectors);
    }

    if (sideBConnectors.length > 0) {
        updateSideConnectors(state, sideBConnectors);
    }
}

export const updateAssemblyAnnotationAction = (state: IAssemblyState, action: PayloadAction<IAssemblyAnnotation>) => {
    state.currentAssembly.annotation = action.payload;
}


