import { ButtonProps } from "@orbit/button";
import { CheckboxProps } from '@orbit/checkbox';
import { DialogProps } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { ChangeEvent, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from "react-i18next";
import { batch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { useInputField } from "../../../ui/input/hooks";
import { IAssemblyInfo } from "../../assembly/info/types";
import { unitSelector } from "../../assembly/length/store/selectors";
import { MetricUnits, Units } from "../../assembly/length/types";
import { savePropertiesAndPrintPDF } from "../../assembly/store/reducer";
import { currentAssemblySelector, currentConnectorAssignmentsSelector, toleranceSelector } from "../../assembly/store/selectors";
import { setStatus } from "../../store/reducer";
import { isOptimusUserSelector, workspacePrintingSelector, workspaceSavingSelector } from "../../store/selectors";
import { Status } from "../../store/types";
import { useAssemblyDocument } from "../document/hooks";
import { IUserProvidedImage } from "../document/types";
import { setUserProvidedImages, showPrintDialog } from "../store/reducer";
import { showPrintDialogSelector, userProvidedImagesSelector } from "../store/selectors";
import { PrintDialogReducer, setApplication, setApprovalDate, setBuildPlan, setConnectorViews, setDescription, setDrawingNumber, setDrawingTitle, setDrawnBy, setEndADescription, setEndBDescription, setHeaderTitle, setInServiceDate, setLocation, setNotes, setPolarityDiagram, setRevision, showCableOverview } from "./reducer/reducer";
import { initialPrintDialogState, IPrintDialogContext } from "./reducer/types";

export const usePrintDialog = () => {
    const { dialogProps } = useDialog();
    const { onTabChange, currentTabIndex } = useDialogTabs();
    const [state, dispatch] = useReducer(PrintDialogReducer, initialPrintDialogState);
    const context: IPrintDialogContext = { state, dispatch };
    const { drawingTitle, headerTitle, cableOverview } = useReportTitle(context);
    const { drawingNumber, description, application, endATitle, endBTitle } = useHeader(context);
    const { approvalDate, inServiceDate, revision, drawnBy, location, notes } = useFooter(context);
    const { polarityDiagram, buildPlan, connectorViews } = usePages(context);
    const { userProvidedImages, onFileChange, onDeleteImage } = useUserProvidedImages();
    const { print, cancel } = useActions(context);

    return { 
        dialogProps,
        drawingNumber,
        description,
        application,
        approvalDate,
        inServiceDate,
        revision,
        headerTitle,
        drawingTitle,
        cableOverview,
        print,
        cancel,
        endATitle,
        endBTitle,
        onTabChange,
        currentTabIndex,
        drawnBy,
        location,
        notes,
        connectorViews,
        polarityDiagram,
        buildPlan,
        userProvidedImages,
        onFileChange,
        onDeleteImage
    };
}

const useUserProvidedImages = () => {
    const userProvidedImages = useSelector(userProvidedImagesSelector);
    const storeDispatch = useStoreDispatch();
    
    const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const images: IUserProvidedImage[] = Array.from(e.target.files ?? []).map(file => ({ fileName: file.name, objectUrl: URL.createObjectURL(file) }));
        storeDispatch(setUserProvidedImages([...userProvidedImages, ...images]))
    }, [storeDispatch, userProvidedImages]);
    
    const onDeleteImage = useCallback((i: number) => {
        const images = [...userProvidedImages];
        images.splice(i, 1);
        storeDispatch(setUserProvidedImages(images));
    }, [storeDispatch, userProvidedImages])

    return { userProvidedImages, onFileChange, onDeleteImage };
}

const useDialog = () => {
    const open = useSelector(showPrintDialogSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showPrintDialog(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "print-dialog",
        title: t(LocalizationKeys.PrintSettings),
        preventOutsideDismiss: true,
        onClose
    };

    return { dialogProps };
};

export const useDialogTabs = () => {
    const display = useSelector(showPrintDialogSelector);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const onTabChange = (e: React.SyntheticEvent, newTabIndex: number) => {
        setCurrentTabIndex(newTabIndex);
    }

    useEffect(() => {
        if (!display) {
            setCurrentTabIndex(0);
        }
    }, [display]);
    
    return {
        onTabChange,
        currentTabIndex,
    };
}

const useReportTitle = ({ state, dispatch }: IPrintDialogContext) => {
    const display = useSelector(showPrintDialogSelector);
    const { assemblyInfo } = useSelector(currentAssemblySelector);
    const { reportTitle } = state;
    const { t } = useTranslation();
    
    useEffect(() => {
        if (display && assemblyInfo) {
            const headerTitle = assemblyInfo.headerTitle && assemblyInfo.headerTitle.length ? assemblyInfo.headerTitle : "INFRASTRUCTURE DELIVERY STANDARD CABLE ASSEMBLY DRAWING";
            const drawingTitle = assemblyInfo.drawingTitle && assemblyInfo.drawingTitle.length ? assemblyInfo.drawingTitle : "Cable Assembly Drawing";

            batch(() => {
                dispatch(setHeaderTitle(headerTitle));
                dispatch(setDrawingTitle(drawingTitle));
            });
        }
    }, [display, assemblyInfo, dispatch]);
    
    const dispatchHeaderTitle = useCallback((headerTitle: string) => {
        dispatch(setHeaderTitle(headerTitle));
    }, [dispatch]);

    const dispatchDrawingTitle = useCallback((drawingTitle: string) => {
        dispatch(setDrawingTitle(drawingTitle));
    }, [dispatch]);

    const headerTitleField = useInputField(t(LocalizationKeys.HeaderTitle), reportTitle.headerTitle, 80, undefined, undefined, dispatchHeaderTitle);
    const drawingTitleField = useInputField(t(LocalizationKeys.DrawingTitle), reportTitle.drawingTitle, 80, undefined, undefined, dispatchDrawingTitle);

    const cableOverviewCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(showCableOverview(e.currentTarget.checked));
    }, [dispatch]);

    const cableOverview: CheckboxProps = {
        label: t(LocalizationKeys.CableOverview),
        palette: MainPalettes.primary,
        placement: "end",
        checked: reportTitle.showCableOverview,
        onChange: cableOverviewCheckboxChanged
    };
    
    return {
        headerTitle: {
            className: "header-title-field",
            label: headerTitleField.label,
            value: headerTitleField.value,
            palette: MainPalettes.primary,
            maxLength: headerTitleField.maxLength,
            onChange: headerTitleField.onChange
        },
        drawingTitle: {
            className: "drawing-title-field",
            label: drawingTitleField.label,
            value: drawingTitleField.value,
            palette: MainPalettes.primary,
            maxLength: drawingTitleField.maxLength,
            onChange: drawingTitleField.onChange
        },
        cableOverview
    }
}

const useHeader = ({ state, dispatch }: IPrintDialogContext) => {
    const display = useSelector(showPrintDialogSelector);
    const { assemblyInfo } = useSelector(currentAssemblySelector);
    const isOptimusUser = useSelector(isOptimusUserSelector);
    const { header } = state;
    const { t } = useTranslation();
    
    useEffect(() => {
        if (!display) {
            dispatch(showCableOverview(!isOptimusUser));
        }
    }, [display, dispatch, isOptimusUser]);
    
    useEffect(() => {
        if (display && assemblyInfo) {
            const drawingNumber = assemblyInfo.drawingNumber && assemblyInfo.drawingNumber.length > 0 ? assemblyInfo.drawingNumber : "";
            const description = assemblyInfo.description && assemblyInfo.description.length > 0 ? assemblyInfo.description : "";
            const application = assemblyInfo.application && assemblyInfo.application.length > 0 ? assemblyInfo.application : "";
            const endADescription = assemblyInfo.endADescription && assemblyInfo.endADescription.length ? assemblyInfo.endADescription : "";
            const endBDescription = assemblyInfo.endBDescription && assemblyInfo.endBDescription.length ? assemblyInfo.endBDescription : "";

            batch(() => {
                dispatch(setDrawingNumber(drawingNumber));
                dispatch(setDescription(description));
                dispatch(setApplication(application));
                dispatch(setEndADescription(endADescription));
                dispatch(setEndBDescription(endBDescription));
            });
        }
    }, [display, assemblyInfo, dispatch]);

    const dispatchDrawingNumber = useCallback((drawingNumber: string) => {
        dispatch(setDrawingNumber(drawingNumber));
    }, [dispatch]);

    const dispatchDescription = useCallback((description: string) => {
        dispatch(setDescription(description));
    }, [dispatch]);

    const dispatchApplication = useCallback((application: string) => {
        dispatch(setApplication(application));
    }, [dispatch]);

    const dispatchEndADescription = useCallback((endADescription: string) => {
        dispatch(setEndADescription(endADescription));
    }, [dispatch]);

    const dispatchEndBDescription = useCallback((endBDescription: string) => {
        dispatch(setEndBDescription(endBDescription));
    }, [dispatch]);

    const drawingNumberField = useInputField(t(LocalizationKeys.AssemblyDrawingNumber), header.drawingNumber, 20, undefined, undefined, dispatchDrawingNumber);
    const descriptionField = useInputField(t(LocalizationKeys.Description), header.description, 130, undefined, undefined, dispatchDescription);
    const applicationField = useInputField(t(LocalizationKeys.Application), header.application, 130, undefined, undefined, dispatchApplication);
    const endADescriptionTitleField = useInputField(t(LocalizationKeys.EndLabel,{ side: "A" }), header.endADescription, 30, undefined, undefined, dispatchEndADescription);
    const endBDescriptionTitleField = useInputField(t(LocalizationKeys.EndLabel,{ side: "B" }), header.endBDescription, 30, undefined, undefined, dispatchEndBDescription);

    return {
        drawingNumber: {
            className: "drawing-number-field",
            label: drawingNumberField.label,
            value: drawingNumberField.value,
            palette: MainPalettes.primary,
            maxLength: drawingNumberField.maxLength,
            onChange: drawingNumberField.onChange
        },
        description: {
            className: "description-field",
            label: descriptionField.label,
            value: descriptionField.value,
            palette: MainPalettes.primary,
            maxLength: descriptionField.maxLength,
            onChange: descriptionField.onChange
        },
        application: {
            className: "application-field",
            label: applicationField.label,
            value: applicationField.value,
            palette: MainPalettes.primary,
            maxLength: applicationField.maxLength,
            onChange: applicationField.onChange
        },
        endATitle: {
            className: "end-a-title-field",
            label: endADescriptionTitleField.label,
            value: endADescriptionTitleField.value,
            palette: MainPalettes.primary,
            maxLength: endADescriptionTitleField.maxLength,
            onChange: endADescriptionTitleField.onChange
        },
        endBTitle: {
            className: "end-b-title-field",
            label: endBDescriptionTitleField.label,
            value: endBDescriptionTitleField.value,
            palette: MainPalettes.primary,
            maxLength: endBDescriptionTitleField.maxLength,
            onChange: endBDescriptionTitleField.onChange
        },
    };
}

const useFooter = ({ state, dispatch }: IPrintDialogContext) => {
    const display = useSelector(showPrintDialogSelector);
    const { assemblyInfo } = useSelector(currentAssemblySelector);
    const { footer } = state;
    const { t } = useTranslation();

    useEffect(() => {
        if (display && assemblyInfo) {
            const approvalDate = assemblyInfo.approvalDate && assemblyInfo.approvalDate.length > 0 ? assemblyInfo.approvalDate : "";
            const inServiceDate = assemblyInfo.inServiceDate && assemblyInfo.inServiceDate.length > 0 ? assemblyInfo.inServiceDate : "";
            const revision = assemblyInfo.revision && assemblyInfo.revision.length > 0 ? assemblyInfo.revision : "";
            const location = assemblyInfo.location && assemblyInfo.location.length > 0 ? assemblyInfo.location : "";
            const drawnBy = assemblyInfo.drawnBy && assemblyInfo.drawnBy.length > 0 ? assemblyInfo.drawnBy : "";
            const notes = assemblyInfo.notes && assemblyInfo.notes.length > 0 ? assemblyInfo.notes : "";
            batch(() => {
                dispatch(setApprovalDate(approvalDate));
                dispatch(setInServiceDate(inServiceDate));
                dispatch(setRevision(revision));
                dispatch(setLocation(location));
                dispatch(setDrawnBy(drawnBy));
                dispatch(setNotes(notes));
            });
        }
    }, [display, assemblyInfo, dispatch]);

    const dispatchApprovalDate = useCallback((approvalDate: string) => {
        dispatch(setApprovalDate(approvalDate));
    }, [dispatch]);

    const dispatchInServiceDate = useCallback((inServiceDate: string) => {
        dispatch(setInServiceDate(inServiceDate));
    }, [dispatch]);

    const dispatchRevision = useCallback((revision: string) => {
        dispatch(setRevision(revision));
    }, [dispatch]);

    const dispatchLocation = useCallback((location: string) => {
        dispatch(setLocation(location));
    },[dispatch]);

    const dispatchDrawnBy = useCallback((drawnBy: string) => {
        dispatch(setDrawnBy(drawnBy));
    },[dispatch]);

    const dispatchNotes = useCallback((notes: string) => {
        dispatch(setNotes(notes));
    },[dispatch]);

    const approvalDateField = useInputField(t(LocalizationKeys.ApprovalDate), footer.approvalDate, undefined, undefined, undefined, dispatchApprovalDate);
    const inServiceDateField = useInputField(t(LocalizationKeys.InServiceDate), footer.inServiceDate, undefined, undefined, undefined, dispatchInServiceDate);
    const revisionField = useInputField(t(LocalizationKeys.Revision), footer.revision, 20, undefined, undefined, dispatchRevision);
    const locationField = useInputField(t(LocalizationKeys.Location), footer.location, 18, undefined, undefined, dispatchLocation);
    const drawnByField = useInputField(t(LocalizationKeys.DrawnBy), footer.drawnBy, 15, undefined, undefined, dispatchDrawnBy);
    const notesField = useInputField(t(LocalizationKeys.Notes), footer.notes, 60, undefined, undefined, dispatchNotes);
    
    return {
        approvalDate: {
            className: "approvalDate-field",
            type: "date",
            label: approvalDateField.label,
            value: approvalDateField.value,
            palette: MainPalettes.primary,
            onChange: approvalDateField.onChange
        },
        inServiceDate: {
            className: "inServiceDate-field",
            type: "date",
            label: inServiceDateField.label,
            value: inServiceDateField.value,
            palette: MainPalettes.primary,
            onChange: inServiceDateField.onChange
        },
        revision: {
            className: "revision-field",
            label: revisionField.label,
            value: revisionField.value,
            palette: MainPalettes.primary,
            maxLength: revisionField.maxLength,
            onChange: revisionField.onChange
        },
        location: {
            className: "location-field",
            label: locationField.label,
            value: locationField.value,
            palette: MainPalettes.primary,
            maxLength: locationField.maxLength,
            onChange: locationField.onChange
        },
        drawnBy: {
            className: "drawnBy-field",
            label: drawnByField.label,
            value: drawnByField.value,
            palette: MainPalettes.primary,
            maxLength: drawnByField.maxLength,
            onChange: drawnByField.onChange
        },
        notes: {
            className: "notes-field",
            label: notesField.label,
            value: notesField.value,
            palette: MainPalettes.primary,
            maxLength: notesField.maxLength,
            onChange: notesField.onChange,
            onKeyDown: notesField.onKeyDown,
            helperText: t(LocalizationKeys.MaxNoteNewLines),
        }
    };
}

const usePages = ({ state, dispatch }: IPrintDialogContext) => {
    const connectorAssignments = useSelector(currentConnectorAssignmentsSelector);
    const display = useSelector(showPrintDialogSelector);
    const { assemblyInfo } = useSelector(currentAssemblySelector);
    const { pages } = state;

    useEffect(() => {
        if (display && assemblyInfo) {
            const buildPlan = assemblyInfo.buildPlan ?? true;
            const polarityDiagram = assemblyInfo.polarityDiagram ?? true;
            const connectorViews = assemblyInfo.connectorViews ?? true;
            batch(() => {
                dispatch(setBuildPlan(buildPlan));
                dispatch(setPolarityDiagram(polarityDiagram));
                dispatch(setConnectorViews(connectorViews));
            });
        }
    },[assemblyInfo, display, dispatch]);

    const onPolarityDiagramCheckBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setPolarityDiagram(e.currentTarget.checked));
    },[dispatch]);

    const onBuildPlanCheckBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setBuildPlan(e.currentTarget.checked));
    },[dispatch]);
    
    const onConnectorViewsCheckBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setConnectorViews(e.currentTarget.checked));
    }, [dispatch]);

    const connectorViews: CheckboxProps = {
        label: "Connector Views",
        palette: MainPalettes.primary,
        placement: "end",
        checked: pages.connectorViews,
        onChange: onConnectorViewsCheckBoxChanged
    };

    const polarityDisabled = connectorAssignments.length === 0;
    const polarityDiagram: CheckboxProps = {
        label: "Polarity Diagram",
        palette: MainPalettes.primary,
        placement: "end",
        checked: pages.polarityDiagram && !polarityDisabled,
        disabled: polarityDisabled,
        onChange: onPolarityDiagramCheckBoxChanged
    };

    const buildPlan: CheckboxProps = {
        label: "Build Plan",
        palette: MainPalettes.primary,
        placement: "end",
        checked: pages.buildPlan,
        onChange: onBuildPlanCheckBoxChanged
    };

    return {
        connectorViews,
        polarityDiagram,
        buildPlan
    }
}

const useActions = ({ state }: IPrintDialogContext) => {
    const { assemblyInfo, fiberCount } = useSelector(currentAssemblySelector);
    const workspaceSaving = useSelector(workspaceSavingSelector);
    const workspacePrinting = useSelector(workspacePrintingSelector);
    const { unit } = useSelector(unitSelector);
    const tolerance = useSelector(toleranceSelector);
    const userProvidedImages = useSelector(userProvidedImagesSelector);
    const isPrinting = workspaceSaving || workspacePrinting;
    const { reportTitle, header, footer, pages } = state;
    const { exportAssembly } = useAssemblyDocument();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onPrintClick = useCallback(() => {
        if (assemblyInfo) {
            storeDispatch(setStatus(Status.Synchronizing)); 
            const uom = unit === MetricUnits.Millimeter ? Units.Millimeter : Units.Inches;
            const trunkTolerance = unit === MetricUnits.Millimeter ? 15 : 0.59;
            const info: IAssemblyInfo = { 
                ...assemblyInfo, 
                ...reportTitle, 
                ...header,
                ...footer,
                ...pages,
                fiberCount, 
                uom, 
                tolerance, 
                trunkTolerance,
                userProvidedImages
            };
            storeDispatch(savePropertiesAndPrintPDF(info, exportAssembly));
        }
    }, [assemblyInfo, fiberCount, reportTitle, header, footer, pages, unit, tolerance, userProvidedImages, storeDispatch, exportAssembly]);

    const onCancelClick = useCallback(() => {
        storeDispatch(showPrintDialog(false));
    }, [storeDispatch]);

    const printButtonProps: ButtonProps = {
        className: "print-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: isPrinting,
        onClick: onPrintClick
    };

    const cancelButtonProps: ButtonProps = {
        className: "cancel-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: isPrinting,
        onClick: onCancelClick
    }

    const print = {
        isPrinting,
        buttonProps: printButtonProps,
        label: isPrinting ? t(LocalizationKeys.Printing) : t(LocalizationKeys.PrintReport),
    };

    const cancel = {
        isPrinting,
        buttonProps: cancelButtonProps,
        label: t(LocalizationKeys.Cancel),
    }
    return { print, cancel };
};