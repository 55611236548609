import React from 'react'
import { Container } from '@inlet/react-pixi'
import { ConnectorFurcationProps, FurcationContainer } from './types'
import { useConnectorFurcation } from './hooks'
import { ConnectorLeg } from './connector-leg/ConnectorLeg'
import { withSelection } from '../../../interactions/selection/Selection'

const ConnectorFurcationGraphic: React.FC<ConnectorFurcationProps> = (props) => {
    const { cableLegProps, x, y } = useConnectorFurcation(props);
    return (
        <Container name={FurcationContainer} {...{ x, y }}>
            {cableLegProps.map((c, i) => <ConnectorLeg key={`c${i}`} {...c} />)}
        </Container>
    )
}

export const ConnectorFurcation = withSelection(ConnectorFurcationGraphic, { x: 0, y: 0, width: 0, height: 0 });