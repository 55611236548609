import React from 'react';
import { usePolarityToolbar } from './hooks';
import { TextButton } from '@orbit/button';
import { IconButton } from '@orbit/icon-button';
import { Close } from '@mui/icons-material';
import { FontTokens, Typography } from '@orbit/theme-provider';
import { Divider } from '@mui/material';

import './PolarityToolbar.scss';

export const PolarityToolbar: React.FC = () => {
    const { display, progressProps, fiberPosition, connectorAssignment, closeProps } = usePolarityToolbar();
    if (!display) {
        return null;
    }
    return (
        <div className="polarity-toolbar toggle-pointer-events">
            <div className="progress-container">
                <div className="assigned-fibers">
                    <div className="title">
                        <Typography variant="caption" label={progressProps.title} fontToken={FontTokens.none} />
                    </div>
                    <div className="percentage">
                        <Typography variant="caption" label={progressProps.percentageText} fontToken={FontTokens.none} />
                    </div>
                </div>
                <div className={progressProps.className}>
                    <span style={{ width: `${progressProps.percentage}%` }} />
                </div>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <TextButton {...connectorAssignment.buttonProps}>
                {connectorAssignment.label}
            </TextButton>
            <TextButton {...fiberPosition.buttonProps}>
                {fiberPosition.label}
            </TextButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton {...closeProps} icon={<Close />} />
        </div>
    )
}