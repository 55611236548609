import { ICableFurcation, IConnector } from "../../../../../workspace/assembly/breakout/types";
import { IBezierCurveProps } from "../../../../bezier-curve/types";
import { IPosition } from "../../../types";

export interface IConnectorLegProps {
    groupPosition: number;
    connector: IConnector;
    bezierCurve: IBezierCurveProps;
    trunkOffset: number;
    furcation: ICableFurcation;
    lineEnd: IPosition;
    spriteMultiplier: number;
}

export const ConnectorLegContainer = "connector-leg-container";
export const ConnectorLegTrunk = "connector-leg-trunk";
export const ConnectorLegTrigger = "connector-leg-trigger";
export const ConnectorLegText = "connector-leg-text";

export const ConnectorLegContainers = {
    ConnectorLegContainer,
    ConnectorLegTrunk,
    ConnectorLegTrigger,
    ConnectorLegText
} as const;