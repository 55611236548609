import React from 'react';
import { BreakoutAnnotationContainer, IBreakoutAnnotationProps } from './types';
import { useBreakoutAnnotation } from './hooks';
import { Container } from '@inlet/react-pixi';
import { CircleMarker } from '../../../markers/circle/CircleMarker';

export const BreakoutAnnotation: React.FC<IBreakoutAnnotationProps> = (props) => {
    const { breakoutAnnotations } = useBreakoutAnnotation(props);
    return (
        <Container name={BreakoutAnnotationContainer}>
            { breakoutAnnotations.map(m => <CircleMarker key={`breakout-annotation-${m.text}`} {...m} />) }
        </Container>
    )
}