import React from 'react';
import { IEditableCellProps } from './types';
import { TableCell } from '@mui/material';
import { TextField } from '@orbit/text-field';
import { MainPalettes, MainThemeTokens } from '@orbit/theme-provider';
import { IconButton } from '@orbit/icon-button';
import { Edit } from '@mui/icons-material';

import './EditableCell.scss'

export const EditableCell: React.FC<IEditableCellProps> = ({ cellProps, value, maxLength, onChange, invalid }) => {
    return (
        <TableCell {...cellProps} className={"editable-cell " + cellProps.className}>
            <TextField
                label=""
                value={value}
                palette={MainPalettes.primary}
                maxLength={maxLength}
                onChange={onChange}
                error={invalid}
                InputProps={{ endAdornment: <IconButton palette={MainPalettes.primary} token={MainThemeTokens.main} placement="bottom" size="small" icon={<Edit />} /> }}
            />
        </TableCell>
    );
}