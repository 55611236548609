import { PayloadAction } from "@reduxjs/toolkit";
import { ICableColor } from "./types";

export const setColorsAction = (state: ICableColor, action: PayloadAction<Partial<ICableColor>>) => {
    const newColors = action.payload;
    if (newColors.jacketColor !== undefined) state.jacketColor = newColors.jacketColor;
    if (newColors.sideATrunkColor !== undefined) state.sideATrunkColor = newColors.sideATrunkColor;
    if (newColors.sideALegColor !== undefined) state.sideALegColor = newColors.sideALegColor;
    if (newColors.sideBTrunkColor !== undefined) state.sideBTrunkColor = newColors.sideBTrunkColor;
    if (newColors.sideBLegColor !== undefined) state.sideBLegColor = newColors.sideBLegColor;
    if (newColors.furcationColor !== undefined) state.furcationColor = newColors.furcationColor;
    if (newColors.shieldColor !== undefined) state.shieldColor = newColors.shieldColor;
    if (newColors.outlineColor !== undefined) state.outlineColor = newColors.outlineColor;
    if (newColors.centerLineColor !== undefined) state.centerLineColor = newColors.centerLineColor;
    if (newColors.spareColor !== undefined) state.spareColor = newColors.spareColor;
    if (newColors.legToleranceColors !== undefined) state.legToleranceColors = newColors.legToleranceColors;
    if (newColors.legPrimeToleranceColors !== undefined) state.legPrimeToleranceColors = newColors.legPrimeToleranceColors;
    if (newColors.legLabelToleranceColors !== undefined) state.legLabelToleranceColors = newColors.legLabelToleranceColors;
}