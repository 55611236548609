import { useContext, useMemo } from "react";
import { IConnector, Sides } from "../../../../workspace/assembly/breakout/types";
import { LC_SIMPLEX, SC_SIMPLEX, isMTP, isDuplex, isPigtailConnector, PIGTAIL_NO_LEG } from "../../../../workspace/assembly/connector/types";
import { convertTo, toLengthString, Units } from "../../../../workspace/assembly/length/types";
import { MarkerSettingsContext } from "../../../../workspace/store/types";
import { ColorContext } from "../../../color/types";
import { useText } from "../../../markers/base/text/hooks";
import { ILengthMarkerProps } from "../../../markers/length/types";
import { LineMarkerProps } from "../../../markers/line/types";
import { ILabelToleranceProps, IToleranceMarkerProps } from "../../../markers/tolerance/types";
import { Orientations, Unidirections } from "../../../markers/types";
import { CablePositionContext } from "../../position/types";
import { connectorSpacing, getConnectorHeight } from "../../side/hooks";
import { SideContext } from "../../side/types";
import { IPosition } from "../../types";
import { IBreakoutAnnotationProps } from "../annotation/types";
import { getConnectorSprite, LargestSprite } from "../connector-furcation/connector-sprite/types";
import { furcationTrunkBaseWidth, groupGap, legGap } from "../connector-furcation/hooks";
import { BreakoutProps, IBreakoutConnector } from "../types";

const markerColor = 0x000000;
const markerThicknessOffset = 0.4;

export const useBreakoutMarkers = (props: BreakoutProps) => {
    const { height, offset, width, x, y } = props;
    const mx = Math.sign(width)
    const connectorFurcationPoint = { x: mx * (props.offset + markerThicknessOffset), y: height / 2 }
    const { staggerMarkers, staggerTextProps, staggerPoints, topY, uniqueConnectorList, isPigtailWithNoLeg } = useStaggerLengthMarker(props, connectorFurcationPoint)
    const trunkLengthMarker = useTrunkLengthMarker(props, connectorFurcationPoint, topY)
    const toleranceMarker = useToleranceMarker(props, staggerPoints, uniqueConnectorList)
    const { breakoutAnnotationProps } = useBreakoutAnnotation(props, connectorFurcationPoint, staggerPoints, Object.values(uniqueConnectorList).map((c) => c.connector), mx)
    const { showABMarkers } = useContext(MarkerSettingsContext);
    return {
        height, offset, width,
        x, y, staggerPoints,
        connectorFurcationPoint, trunkLengthMarker, showABMarkers,
        staggerMarkers, staggerTextProps, toleranceMarker, breakoutAnnotationProps, isPigtailWithNoLeg
    }
}

const singleBreakoutMarkerOffset = -16.5;
const useTrunkLengthMarker = (props: BreakoutProps, connectorFurcationPoint: IPosition, topY: number) => {
    const { height, width, y, trunk, trunkFurcation, position, side } = props;
    const { breakouts, unit } = useContext(SideContext);
    const mx = Math.sign(width)
    const my = Math.sign(y)
    const lengthString = trunk ? toLengthString(convertTo(trunk.length, unit)) : ""
    const shortDelimeter = height / 2 + Math.abs(topY)
    let longDelimeter = breakouts.length === 1 ? shortDelimeter - 8 : -my * (Math.abs(trunkFurcation!.y) + Math.abs(topY));
    const { showTolerances, customTolerances } = useContext(MarkerSettingsContext);
    const { cableColor: { legToleranceColors }} = useContext(ColorContext);
    

    const trunkLineMarker: LineMarkerProps = {
        coordinate: { x: mx < 0 ? connectorFurcationPoint.x + 0.9 : 0.10, y: topY },
        startDelimiterLength: mx > 0 ? longDelimeter : shortDelimeter,
        endDelimiterLength: mx > 0 ? shortDelimeter : longDelimeter,
        length: Math.abs(connectorFurcationPoint.x),
        orientation: Orientations.Horizontal,
        text: lengthString,
        toleranceProps: showTolerances ? { tolerance: customTolerances.legTolerance, minTextColor: legToleranceColors.minColor, maxTextColor: legToleranceColors.maxColor } : undefined,
        color: markerColor,
        thickness: 1,
        alpha: 1
    }

    const markerText = side === Sides.SideA ? `A${position}` : `B${position}`
    const { textStyle, textSize } = useText({ text: markerText, fill: markerColor, fontSize: 10 })
    const markerTextPosition = {
        x: trunkLineMarker.coordinate.x + (trunkLineMarker.length / 2) - textSize.width,
        y: trunkLineMarker.coordinate.y - textSize.height - 14
    }
    const trunkLengthMarker: ILengthMarkerProps = {
        lineMarkerProps: trunkLineMarker,
        showMarker: (trunk && trunk.length.value > 0) || false,
        textProps: {
            text: markerText,
            style: textStyle,
            resolution: 10,
            scale: 1,
            position: markerTextPosition
        }
    }

    return trunkLengthMarker
}

const useStaggerLengthMarker = (props: BreakoutProps, connectorFurcationPoint: IPosition) => {
    const { breakouts, breakoutsProps, hasGroupOffsets, maxUniqueLength, unit } = useContext(SideContext);
    const { state: cablePositionState } = useContext(CablePositionContext);
    const { width, furcation, position: breakoutPosition, side, trunk, connectors: connectorsInfos, trunkLength, uniqueLegLengths, groupsUniqueLegLengths, uniqueGroupLengths } = props;
    const mx = Math.sign(width);
    const sidePositions = cablePositionState.sidePositions[side!];
    const { showTolerances, customTolerances } = useContext(MarkerSettingsContext);
    const { cableColor: { legPrimeToleranceColors } } = useContext(ColorContext);
    const isPigtailWithNoLeg = connectorsInfos[0].connector.type === PIGTAIL_NO_LEG;

    const breakoutConnectors = useMemo(() => { // We need to display only the distinct unique lengths as markers
        const breakoutConnectors: IBreakoutConnector[] = [];
        for (const uniqueLegLength of uniqueLegLengths) {
            const uniqueConnector = connectorsInfos.find(info => {
                const lengthValue = info.connector.length?.value ?? info.groupLength.value;
                return lengthValue === uniqueLegLength;
            })
            if(uniqueConnector) {
                breakoutConnectors.push(uniqueConnector);
            }
        }
        return breakoutConnectors;
    }, [connectorsInfos, uniqueLegLengths]);

    const { staggerPoints, breakoutHeight, topY, nbConnectors } = useMemo(() => {
        if (furcation && breakoutConnectors.length) {
            const connectors = furcation.groups.flatMap(g => g.connectors);
            const connectorHeight = (getConnectorHeight(connectors[0].type) + connectorSpacing * 0.5) * 0.5;
            const breakoutHeight = connectors.length * connectorHeight;
            let groupY = connectorFurcationPoint.y;
            if (connectors.length > 1) {
                groupY = connectorFurcationPoint.y - breakoutHeight * 0.5;
            }

            let previousStaggerOffset = 0;
            let previousGroupOffsetIndex = 0;
            const staggerPoints = breakoutConnectors.map(({ groupPosition, groupLength, connector, isLastGroupConnector }, i) => {
                const uniqueLegLength = uniqueLegLengths.find(g => connector.length && g === connector.length.value) || uniqueLegLengths[0];
                const nbGroupsOffsets = groupsUniqueLegLengths.map(g => g.groupOffset).filter(o => o > 0).length; 
                let trunkOffsetIndex = 0;
                let groupOffsetIndex = 0;
                let currentGroup = groupsUniqueLegLengths[0];
                let previousGroup = groupsUniqueLegLengths[0];
                if (hasGroupOffsets && groupPosition > 1) { // We will draw with group offset if possible
                    const uniqueGroupLength = groupLength.value;
                    currentGroup = groupsUniqueLegLengths[groupPosition - 1];
                    const { groupOffset, hasGap, uniqueLegLengths: groupUniqueLegLengths } = currentGroup;
                    previousGroup = groupsUniqueLegLengths[groupPosition - 2];
                    if (groupOffset > 0 && hasGap) { // Draw with group offset
                        trunkOffsetIndex = groupUniqueLegLengths.indexOf(uniqueLegLength);
                        groupOffsetIndex = uniqueGroupLengths.indexOf(uniqueGroupLength);
                    } else {
                        trunkOffsetIndex = uniqueLegLengths.indexOf(uniqueLegLength); // Draw relative
                        if (previousGroupOffsetIndex > 0) { // Draw relative to the previous group offset
                            trunkOffsetIndex = groupUniqueLegLengths.indexOf(uniqueLegLength);
                            groupOffsetIndex = previousGroupOffsetIndex;
                        }
                    }
                } else { // First group is always drawn relative. Obviously if we have no group offset, we want it relative as well
                    trunkOffsetIndex = uniqueLegLengths.indexOf(uniqueLegLength);
                }

                let trunkDelta = 0;
                let legDelta = 0;
                let groupDelta = 0;
                if (uniqueLegLengths.includes(maxUniqueLength) && !breakoutsProps.every(u => u.trunkLength === trunkLength && u.uniqueLegLengths.includes(maxUniqueLength) && u.uniqueLegLengths.length === uniqueLegLengths.length)) {
                    // Adjusting according to the longest An/Bn
                    const longerTrunks = breakoutsProps.filter(u => u.position !== breakoutPosition && u.trunkLength > trunkLength).map(u => u.trunkLength);
                    if (longerTrunks.length > 0) {
                        const longestTrunk = longerTrunks.reduce((a, b) => a > b ? a : b);
                        if (sidePositions[longestTrunk] && sidePositions[trunkLength]) {
                            const longestPosition = sidePositions[longestTrunk].positionOffset;
                            const localPosition = sidePositions[trunkLength].positionOffset;
                            trunkDelta = longestPosition - localPosition;
                        }
                    }

                    // Adjusting according to the breakout with the most unique lengths
                    const similarOrMoreUniqueLengths = breakoutsProps.filter(u => u.position !== breakoutPosition && u.uniqueLegLengths.length >= uniqueLegLengths.length)
                    if (similarOrMoreUniqueLengths.length > 0) {
                        const longestWithMostUniqueLengths = similarOrMoreUniqueLengths.reduce((a, b) => {
                            if (a.uniqueLegLengths.length === b.uniqueLegLengths.length) {
                                return a.trunkLength > b.trunkLength ? a : b;
                            }
                            return a.uniqueLegLengths.length > b.uniqueLegLengths.length ? a : b;
                        });

                        // Compensating for nb of unique lengths
                        let nbMostUniqueLengths = 0;
                        let nbLocalUniqueLengths = 0;
                        if (hasGroupOffsets) {
                            nbMostUniqueLengths = longestWithMostUniqueLengths.groupsUniqueLegLengths.map(g => g.uniqueLegLengths.length - 1).reduce((a, b) => a + b, 0);
                            nbLocalUniqueLengths = groupsUniqueLegLengths.map(g => g.uniqueLegLengths.length - 1).reduce((a, b) => a + b, 0);
                        } else {
                            nbMostUniqueLengths = longestWithMostUniqueLengths.uniqueLegLengths.length;
                            nbLocalUniqueLengths = uniqueLegLengths.length;
                        }
                        legDelta += nbMostUniqueLengths - nbLocalUniqueLengths;

                        // Compensating for group unique lengths
                        const nbMostGroupOffsets = longestWithMostUniqueLengths.groupsUniqueLegLengths.map(g => g.groupOffset).filter(o => o > 0).length;
                        groupDelta += nbMostGroupOffsets - nbGroupsOffsets;

                        if (!longestWithMostUniqueLengths.uniqueLegLengths.includes(maxUniqueLength) || (longestWithMostUniqueLengths.trunkLength !== trunkLength && longestWithMostUniqueLengths.uniqueLegLengths.length > uniqueLegLengths.length && nbGroupsOffsets > 0)) {
                            legDelta++
                        }                     
                    }
                }

                let groupStaggerOffset = 0;
                if (hasGroupOffsets && nbGroupsOffsets > 0) {
                    groupStaggerOffset = groupPosition > 1 && previousGroup.uniqueLegLengths.length === 1 && previousGroup.hasGap ? legGap * 0.5 : 0;
                    if (groupOffsetIndex > 0) {
                        if (connector.spare || groupOffsetIndex === previousGroupOffsetIndex) {
                            groupStaggerOffset = previousStaggerOffset;
                        } else {
                            if (!connector.spare && previousGroup.uniqueLegLengths.length === 1) {
                                groupStaggerOffset = previousStaggerOffset + groupGap * groupOffsetIndex;
                            } else {
                                groupStaggerOffset = previousStaggerOffset + groupGap;
                            }
                        }
                    }
                }

                const legStaggerOffset = legGap * trunkOffsetIndex;
                const staggerOffset = groupStaggerOffset + legStaggerOffset;
                const legDeltaOffset = legDelta * legGap;
                const groupDeltaOffset = groupDelta * groupGap;
                const deltaOffset = legDeltaOffset + groupDeltaOffset;
                const offsetX = mx * (15 + furcationTrunkBaseWidth + trunkDelta + staggerOffset + deltaOffset);
                const x = connectorFurcationPoint.x + offsetX + mx * LargestSprite.height * 0.5;

                if (isLastGroupConnector && currentGroup.uniqueLegLengths.length > 1) {
                    previousStaggerOffset = staggerOffset;
                    if (groupPosition > 1) { // Preserving offsets for next groups
                        previousGroupOffsetIndex = groupOffsetIndex;
                    }
                }

                const isMTPConnector = isMTP(connector.type);
                const mtpOffsetY = isMTPConnector ? -4 : 0;
                const y = groupY + (connector.position - 1) * connectorHeight + mtpOffsetY;

                return { x, y };
            })

            const markerOffset = -12;
            let topY = staggerPoints.map(s => s.y).reduce((a, b) => a < b ? a : b) + markerOffset
            if (breakouts.length === 1 && connectors.length === 1) {
                topY = singleBreakoutMarkerOffset
            }

            return { staggerPoints: [{ ...connectorFurcationPoint }, ...staggerPoints], breakoutHeight, topY, nbConnectors: connectors.length };
        }
        return { staggerPoints: [], breakoutHeight: 0, topY: 0 }
    }, [connectorFurcationPoint, mx, breakouts, furcation, breakoutsProps, breakoutPosition, sidePositions, breakoutConnectors, maxUniqueLength, groupsUniqueLegLengths, uniqueGroupLengths, uniqueLegLengths, hasGroupOffsets, trunkLength])

    const markers = useMemo(() => {
        const [, ...points] = staggerPoints;
        const markers: LineMarkerProps[] = points.map((s, i) => {
            const { connector } = breakoutConnectors[i];
            const p1 = staggerPoints[i];
            const p2 = staggerPoints[i + 1];
            const length = Math.abs(p2.x - p1.x) + markerThicknessOffset;
            let shortDelimiterLength = p1.y + Math.abs(topY);
            let longDelimiterLength = p2.y + Math.abs(topY);

            let text = "";
            if (connector.length && trunk) {
                const actualLength = connector.length.value - trunk.length.value;
                const lengthString = toLengthString(convertTo({ value: actualLength, unit: connector.length.unit }, unit));
                text = `${lengthString}`
            }

            return {
                coordinate: { x: mx > 0 ? p1.x : p2.x + 0.5, y: topY },
                startDelimiterLength: mx > 0 ? shortDelimiterLength : longDelimiterLength,
                endDelimiterLength: mx > 0 ? longDelimiterLength : shortDelimiterLength,
                color: markerColor,
                length,
                orientation: "horizontal",
                text,
                thickness: 1,
                alpha: 1,
                toleranceProps: showTolerances ? { tolerance: customTolerances.legPrimeTolerance, minTextColor: legPrimeToleranceColors.minColor, maxTextColor: legPrimeToleranceColors.maxColor } : undefined,
                unidirection: i ? mx > 0 ? "end" : "start" : undefined
            }
        })

        return markers;
    }, [staggerPoints, breakoutConnectors, mx, topY, trunk, unit, showTolerances, customTolerances, legPrimeToleranceColors])

    const left = markers.length > 1 ? markers.reduce((a, b) => a.coordinate.x < b.coordinate.x ? a : b) : markers[0] 
    const right = markers.length > 1 ? markers.reduce((a, b) => a.coordinate.x > b.coordinate.x ? a : b) : markers[0]
    const length = left && right ? (right.coordinate.x + right.length) - left.coordinate.x : 0;
    const markerText = side === Sides.SideA ? `A${breakoutPosition}'1 - A${breakoutPosition}'${nbConnectors}` : `B${breakoutPosition}'1 - B${breakoutPosition}'${nbConnectors}`
    const { textStyle, textSize } = useText({ text: markerText, fill: markerColor, fontSize: 10 })
    const markerTextPosition = {
        x: left ? left.coordinate.x + (length / 2) - textSize.width : 0,
        y: topY - textSize.height - 14
    }

    const staggerTextProps = {
        text: markerText,
        style: textStyle,
        resolution: 10,
        scale: 1,
        position: markerTextPosition
    }

    return {
        staggerMarkers: markers,
        staggerTextProps,
        staggerPoints: staggerPoints,
        topY,
        breakoutHeight,
        uniqueConnectorList: breakoutConnectors,
        isPigtailWithNoLeg
    }
}

const useToleranceMarker = ({ side, position, furcation, trunk }: BreakoutProps, staggerPoints: IPosition[], uniqueConnectorList: IBreakoutConnector[]) => {
    const { showToleranceMarker, customTolerances } = useContext(MarkerSettingsContext);
    const { cableColor: { legLabelToleranceColors } } = useContext(ColorContext);
    const { unit } = useContext(SideContext);
    const labelToleranceText = toLengthString(convertTo({ value: 50, unit: Units.Millimeter }, unit));
    const { textStyle: labelToleranceStyle, textSize: labelToleranceSize } = useText({ text: labelToleranceText, fill: markerColor, fontSize: 8 });

    if (showToleranceMarker && side === Sides.SideA && position === 1 && furcation && trunk) {
        const nbConnectors = furcation.groups.map(g => g.connectors.length).reduce((prev, cur) => { return prev + cur; }); // Inludes spares
        const lastGroup = furcation.groups[furcation.groups.length - 1];
        const sprite = getConnectorSprite(lastGroup.connectors[0].type);

        const connectors = furcation.groups.flatMap(g => g.connectors);
        const isPigtail = !isPigtailConnector(connectors[0].type)
        const connectorsHeights = connectors.map(c => (getConnectorHeight(c.type) + connectorSpacing * 0.5) * 0.5);
        const breakoutHeight = connectorsHeights.length > 1 ? connectorsHeights.reduce((a, b) => a + b) : connectorsHeights[0] * nbConnectors
        const topY = staggerPoints.length ? staggerPoints.map(s => s.y).reduce((a,b) => a < b ? a : b): 0
        
        const isLcSimplex = sprite.name === LC_SIMPLEX;
        const isScSimplex = sprite.name === SC_SIMPLEX;

        const yOffset = (isLcSimplex || isScSimplex) ? 3 : 0;
        const y = topY + breakoutHeight + yOffset;

        const lastConnector = connectors[connectors.length - 1];
        const isMTPConnector = isMTP(lastConnector.type);
        const connectorLength = lastConnector.length?.value ?? lastGroup.length.value;
        const uniqueLength = trunk.length.value + connectorLength;
        const uniqueLengthIndex = uniqueConnectorList.findIndex(ul => ul.connector.length?.value === uniqueLength);
        const staggerPointIndex = uniqueLengthIndex > -1 ? uniqueLengthIndex + 1 : staggerPoints.length - 1;
        const mtpOffsetX = isMTPConnector ? 3.55 : 0;
        const x = staggerPoints[staggerPointIndex].x + (sprite.height * 0.5) + 0.5 - mtpOffsetX

        let labelToleranceLength = 10;
        let lineMarkerOffsetX = 0;
        if (isDuplex(lastConnector.type)) {
            labelToleranceLength = 20;
            lineMarkerOffsetX = 19.5;
        }
        const labelToleranceDelimiterLength = 8;
        let offsetX = 0;
        let minTolerance = customTolerances.legLabelTolerance.min;
        let maxTolerance = customTolerances.legLabelTolerance.max;
        if (unit === Units.Inches) {
            offsetX = 7;
        }

        const labelTolerancePosition: IPosition = { x: x - 3 * labelToleranceLength + 2 - offsetX, y: y - labelToleranceSize.height };
        const labelToleranceTextProps = {
            text: labelToleranceText,
            style: labelToleranceStyle,
            position: labelTolerancePosition,
            resolution: 10,
            scale: 1
        };

        const toleranceProps: IToleranceMarkerProps = {
            tolerance: { min: minTolerance, max: maxTolerance },
            minTextColor: legLabelToleranceColors.minColor,
            maxTextColor: legLabelToleranceColors.maxColor,
            textProps: labelToleranceTextProps
        };

        const leftLineMarkerProps: LineMarkerProps = {
            startDelimiterLength: 0,
            endDelimiterLength: labelToleranceDelimiterLength,
            color: markerColor,
            length: labelToleranceLength,
            unidirection: Unidirections.End,
            thickness: 1,
            text: "",
            alpha: 1,
            orientation: Orientations.Horizontal,
            coordinate: { x: x - labelToleranceLength - lineMarkerOffsetX, y }
        }

        const rightLineMarkerProps: LineMarkerProps = {
            startDelimiterLength: labelToleranceDelimiterLength,
            endDelimiterLength: 0,
            color: markerColor,
            length: labelToleranceLength,
            unidirection: Unidirections.Start,
            thickness: 1,
            text: "",
            alpha: 1,
            orientation: Orientations.Horizontal,
            coordinate: { x: x + labelToleranceLength - lineMarkerOffsetX, y }
        }

        const labelToleranceProp: ILabelToleranceProps = {
            leftLineMarkerProps,
            rightLineMarkerProps,
            toleranceProps,
            isPigtail
        }

        return labelToleranceProp;
    }
}

const useBreakoutAnnotation = ({ furcation, trunk, position }: BreakoutProps, furcationPoint: IPosition, staggerPoints: IPosition[], uniqueConnectorLengths: IConnector[], mx: number) => {
    let legEnd: IPosition = { x: 0, y: 0 };
    let connectorType: string = "";
    let connectors: IConnector[] = [];
    if (furcation && trunk) {
        connectors = furcation.groups.flatMap(g => g.connectors);
        const lastGroup = furcation.groups[furcation.groups.length - 1];
        connectorType = connectors[0].type;
        const connectorHeight = (getConnectorHeight(connectorType) + connectorSpacing * 0.5) * 0.5;

        const lastConnector = connectors[connectors.length - 1];
        const connectorLength = lastConnector.length?.value ?? lastGroup.length.value;
        const uniqueLength = trunk.length.value + connectorLength;
        const uniqueLengthIndex = uniqueConnectorLengths.findIndex(ul => ul.length?.value === uniqueLength);
        let breakoutHeight = connectors.length > 1 ? (connectors.length - 1) * connectorHeight : 10;
        
        const staggerPointIndex = uniqueLengthIndex > -1 ? uniqueLengthIndex + 1 : staggerPoints.length - 1;
        const staggerPoint = staggerPoints[staggerPointIndex];

        const sprite = getConnectorSprite(connectorType);
        const isMTPConnector = isMTP(connectorType);
        const mtpOffsetX = isMTPConnector ? 3.55 : 0;
        const mtpOffsetY = isMTPConnector ? 4 : 0;
        const widthOffset = mx * sprite.height * 0.5 - mx * mtpOffsetX;
        const heightOffset = breakoutHeight + mtpOffsetY;
        legEnd = { x: (staggerPoint.x - widthOffset) * 0.5, y: heightOffset * 0.5 };
    }

    const breakoutAnnotationProps: IBreakoutAnnotationProps = {
        position: position ?? 1,
        furcationPosition: { x: furcationPoint.x * 0.5, y: furcationPoint.y * 0.5 },
        legEnd,
        connectorType,
        nbConnectors: connectors.length
    }

    return { breakoutAnnotationProps };
}