import { createSlice } from "@reduxjs/toolkit";
import { resetCableSetupAction, setCableSetupStateAction } from "./actions";
import { initialCableSetupStore } from "./types";

const cableSetupStoreSlice = createSlice({
    initialState: initialCableSetupStore,
    name: "cable-setup-store",
    reducers: {
        setCableSetupState: setCableSetupStateAction,
        resetCableSetup: resetCableSetupAction,
    }
})

export const CableSetupStoreReducer = cableSetupStoreSlice.reducer;

export const { setCableSetupState, resetCableSetup } = cableSetupStoreSlice.actions;