import { showTermsConditionsSelector, userIdSelector } from "../store/selectors";
import { tncDateSelector, tncCurrentVersionAcceptedSelector } from "../project-drawer/store/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys, TNC_CURRENT_VERSION } from "../../localization/types";
import { useCallback } from "react";
import { logOff, setStatus, showTermsConditions } from "../store/reducer";
import { ButtonProps } from "@orbit/button";
import { updateTermsAndConditions } from "../project-drawer/store/reducer";
import { Status } from "../store/types";
import { useStoreDispatch } from "../../store/hooks";
import { DialogProps } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const useTermsConditionsDialog = () => {
    const open = useSelector(showTermsConditionsSelector);
    const tncDate = useSelector(tncDateSelector);
    const tncCurrentVersionAccepted = useSelector(tncCurrentVersionAcceptedSelector);
    const userId = useSelector(userIdSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showTermsConditions(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "tnc-dialog",
        title: t(LocalizationKeys.TermsAndConditionsTitle),
        preventOutsideDismiss: true
    };

    const tncAcceptedAt = tncDate && tncCurrentVersionAccepted ? t(LocalizationKeys.TermsAndConditionsAccepted, { tncDate: `${tncDate.getMonth() + 1}/${tncDate.getDate()}/${tncDate.getFullYear()}`}) : "";

    const onAcceptButtonClick = useCallback(() => {
        if (!tncCurrentVersionAccepted) {
            storeDispatch(setStatus(Status.Synchronizing));
            storeDispatch(updateTermsAndConditions(userId, new Date().toISOString(), TNC_CURRENT_VERSION));
        }
        onClose();
    }, [tncCurrentVersionAccepted, storeDispatch, userId, onClose]);

    const acceptButtonProps: ButtonProps = {
        className: "accept-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onAcceptButtonClick
    };

    const accept = {
        buttonProps: acceptButtonProps,
        label: tncCurrentVersionAccepted ? t(LocalizationKeys.Close) : t(LocalizationKeys.Accept)
    };

    const onCloseButtonClick = useCallback(() => {
        storeDispatch(logOff());
    }, [storeDispatch]);

    const cancelButtonProps: ButtonProps = {
        className: " cancel-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onCloseButtonClick
    };

    const cancel = {
        buttonProps: cancelButtonProps,
        label: t(LocalizationKeys.Cancel),
    };

    return { 
        dialogProps,
        tncAcceptedAt,
        tncCurrentVersionAccepted,
        accept,
        cancel
    }
}