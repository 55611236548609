import { PayloadAction } from "@reduxjs/toolkit";
import { WorkspaceStatus } from "./types";
import { IWorkspaceState } from "./types";

export const loginAction = (state: IWorkspaceState, action: PayloadAction<{ userId: number, company: string, uom: string }>) => {
    const { userId, company, uom } = action.payload
    state.userId = userId;
    state.company = company;
    state.userUom = uom;
    state.loggedIn = true;
}

export const logoutAction = (state: IWorkspaceState) => {
    state.userId = 0;
    state.loggedIn = false;
}

export const showAnnotationDialogAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showAnnotationEdit = action.payload;
}

export const setUserUomAction = (state: IWorkspaceState, action: PayloadAction<string>) => {
    state.userUom = action.payload;
}

export const setStatusAction = (state: IWorkspaceState, action: PayloadAction<WorkspaceStatus>) => {
    state.status = action.payload;
}

export const showSettingsAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.settings.showSettings = action.payload;
}

export const showAssemblyWizardAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showAssemblyWizard = action.payload;
}

export const showAssemblyEditAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showAssemblyEdit = action.payload;
}

export const showBreakoutEditAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showBreakoutEdit = action.payload;
}

export const showBreakoutDetailsAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showBreakoutDetails = action.payload;
}

export const showTermsConditionsAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showTermsConditions = action.payload;
}

export const showIdleAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showIdle = action.payload;
}

export const showTimeOutAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showTimeOut = action.payload;
}

export const showHelpAction = (state: IWorkspaceState, action: PayloadAction<boolean>) => {
    state.dialogs.showHelp = action.payload;
}