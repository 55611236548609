import { Length, Units } from "../../length/types";
import { IEditableCellProps } from "../../../../ui/table/cell/types";
import { LC } from "../../connector/types";
import { Blue, IColor } from "../../../../ui/dialog/color/types";

export interface IBreakoutDetailsSubheaderProps {
    breakoutLabel: string;
    lengthLabel: string;
    editableLengthProps: IEditableCellProps;
    editableLabelProps: IEditableCellProps;
}

export interface IBreakoutDetailsTableHeaderProps {
    connectorLabel: string;
    groupLabel: string;
    connectorColorLabel: string;
    label: string;
    labelColorLabel: string;
    breakoutLengthLabel: string;
    legLengthLabel: string;
    totalLengthLabel: string;
}

export interface ILegRowData {
    id: number;
    connectorType: string;
    connectorPosition: number;
    groupPosition: number;
    groupLabel: string;
    connectorColor: IColor;
    label: string;
    labelColor: IColor;
    breakoutLength: number;
    legLength: Length;
    totalLength: number;
}

export const initialLegRow: ILegRowData = {
    id: -1,
    connectorType: LC,
    connectorPosition: -1,
    groupPosition: -1,
    groupLabel: "",
    connectorColor: Blue,
    label: "",
    labelColor: Blue,
    breakoutLength: 0,
    legLength: { id: 0, value: 0, unit: Units.Millimeter },
    totalLength: 0
}