import { ILocalPolarityState, INavigationInfo, initialLocalConnectorAssignment, initialLocalNavigation } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { IConnectorAssignmentMap } from "../connector-assignment/reducer/types";

export const setupFiberMappingAction = (state: ILocalPolarityState, action: PayloadAction<{ navigation: INavigationInfo, assignment: IConnectorAssignmentMap }>) => {
    const { navigation, assignment } = action.payload;
    state.initialNavigation = navigation;
    state.connectorAssignment = assignment;
}

export const setConnectorAssignmentToUpdateAction = (state: ILocalPolarityState, action: PayloadAction<IConnectorAssignmentMap | undefined>) => {
    state.connectorAssignmentToUpdate = action.payload;
}

export const resetLocalPolarityAction = (state: ILocalPolarityState) => {
    state.initialNavigation = initialLocalNavigation;
    state.connectorAssignment = initialLocalConnectorAssignment;
}