import { useSelector } from "react-redux";
import { showSettingsSelector } from "../../../store/selectors";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { useTranslation } from "react-i18next"
import { LocalizationKeys } from "../../../../localization/types";
import { useCallback } from "react";
import { showSettings } from "../../../store/reducer";
import { useStoreDispatch } from "../../../../store/hooks";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const useSettingsAction = () => {
    const settingsDisplay = useSelector(showSettingsSelector);
    const dispatch = useStoreDispatch();
    const disabled = useSelector(disabledOverlaySelector);
    const { t } = useTranslation();

    const onSettingsClick = useCallback(() => {
        dispatch(showSettings(!settingsDisplay))
    }, [dispatch, settingsDisplay]);

    const settingsIconProps = {
        className: "settings-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Settings),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: onSettingsClick
    }

    return { settingsIconProps };
}