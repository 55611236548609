import { useSelector } from "react-redux";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { useCallback } from "react";
import { useStoreDispatch } from "../../../../store/hooks";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { showAnnotationDialogSelector } from "../../../store/selectors";
import { showAnnotationDialog } from "../../../store/reducer";

export const useAnnotationAction = () => {
    const dispatch = useStoreDispatch();
    const displayAnnotationDialog = useSelector(showAnnotationDialogSelector);
    const disabled = useSelector(disabledOverlaySelector);
    const { t } = useTranslation();

    const onAnnotationClick = useCallback(() => {
        dispatch(showAnnotationDialog(!displayAnnotationDialog));
    }, [dispatch, displayAnnotationDialog]);

    const iconProps = {
        className: "annotation-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Annotations),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: onAnnotationClick
    }

    return { iconProps };
}