import React from 'react';
import { disabledOverlaySelector } from '../../workspace/overlay/store/selectors';
import { useSelector } from "react-redux"
import { showAssemblyWizardSelector } from '../../workspace/store/selectors';

export const MetricIcon: React.FC = () => {
    const disabledOverlay = useSelector(disabledOverlaySelector);
    const showWizard = useSelector(showAssemblyWizardSelector);
    const disabled = disabledOverlay && !showWizard;
    return (
        <svg id="Icon_Metric" data-name="Icon Metric" className="metric-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <rect id="Rectangle_605" data-name="Rectangle 605" width="24" height="24" fill="none"/>
            <path id="Subtraction_4" data-name="Subtraction 4" d="M16,20H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16A4,4,0,0,1,16,20Zm-3.714-4.3h0V16h4.451v-.3h-.025l-.107,0c-.29,0-.984-.036-1.153-.366a3.149,3.149,0,0,1-.214-1.148V7.007a1.644,1.644,0,0,1,.383-1.069,1.4,1.4,0,0,1,.851-.227,2.293,2.293,0,0,1,.264.014V5.388H13.8l-3.79,8.338-3.9-8.338H3.132v.337l.015,0c.029,0,.1-.007.186-.007a1.718,1.718,0,0,1,.9.222c.433.286.475,1.157.475,1.165L4.639,14.19c0,.01.048,1.021-.3,1.289a1.992,1.992,0,0,1-1.053.226c-.093,0-.153,0-.154,0V16H6.855v-.3l-.023,0c-.028,0-.08,0-.147,0a1.609,1.609,0,0,1-1.094-.3A2.169,2.169,0,0,1,5.37,14.19V7.1L9.422,16H9.7l4.1-8.9V14.19a3.407,3.407,0,0,1-.184,1.148c-.134.328-.7.377-1.028.377-.17,0-.293-.013-.3-.013Z" transform="translate(2 2)" fill={disabled? "#0000003b" : "#005293"}/>
        </svg>
    );
}