import { DialogProps } from "@orbit/dialog";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { displayUnitConverter, setWorkspaceUnit } from "../../assembly/length/store/reducer";
import { displayConverterSelector, unitSelector } from "../../assembly/length/store/selectors"
import { Units } from "../../assembly/length/types";
import { updateAssemblyInfo } from "../../assembly/store/reducer";
import { currentAssemblyInfoSelector } from "../../assembly/store/selectors";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";

export const useUnitConverter = () => {
    const open = useSelector(displayConverterSelector);
    const { unit } = useSelector(unitSelector);
    const info = useSelector(currentAssemblyInfoSelector);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        storeDispatch(displayUnitConverter(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "unit-dialog",
        title: t(LocalizationKeys.UnitsSetup),
        modal: false,
        onClose,
    };
    const onUnitChange = useCallback(async (e) => {
        const unit = e.currentTarget.value as string;
        storeDispatch(setWorkspaceUnit(unit));
        if (info && info.id && info.id > 0) { // We will update the build only if it's existing and loaded
            storeDispatch(setStatus(Status.Synchronizing));
            storeDispatch(updateAssemblyInfo({ ...info, uom: unit }));
        }
    }, [info, storeDispatch]);

    const unitProps = {
        value: unit,
        options: [
            { 
                title: t(LocalizationKeys.Metric),
                className: unit === Units.Millimeter ? "selected" : "",
                label: t(LocalizationKeys.Millimeter),
                radioButtonProps: { value: Units.Millimeter, onChange: onUnitChange },
                hint: t(LocalizationKeys.Example, { examples: "1234 millimeters" })
            },
            { 
                title: t(LocalizationKeys.Imperial),
                className: unit === Units.Inches ? "selected" : "",
                label: t(LocalizationKeys.Inches),
                radioButtonProps: { value: Units.Inches, onChange: onUnitChange },
                hint: t(LocalizationKeys.Example, { examples: "48.6 inches" })
            }
        ],
        hint: t(LocalizationKeys.UnitsSetupHint)
    };

    return {
        dialogProps,
        unitProps
    }
}