import React from 'react';
import { DrawerHeaderProps } from './types';
import { useDrawerHeader } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Close } from '@mui/icons-material';
import { Divider } from '@mui/material';

import './DrawerHeader.scss';

export const DrawerHeader: React.FC<DrawerHeaderProps> = (props) => {
    const { logo, closeIconProps } = useDrawerHeader(props); 
    return (
        <>
            <div className="drawer-header">
                <div className="corning-logo">
                    { logo ? <img alt={"logo"} src={logo} /> : null }
                </div>
                <IconButton {...closeIconProps} icon={<Close />} />
            </div>
            <Divider />
        </>
    );
}