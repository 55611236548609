import { _ReactPixi } from "@inlet/react-pixi";
import * as Pixi from 'pixi.js';
import { useCallback, useContext, useMemo } from "react";
import { getTextColor, hexStringToNumber, White } from "../../../../../ui/dialog/color/types";
import { getSpareDefaultText, Sides } from "../../../../../workspace/assembly/breakout/types";
import { getConnectorFamilyFromType, getConnectorTypeString, isDuplex, isPigtailConnector, LCDUPLEX_FAMILY, LSH, PIGTAIL_NO_LEG, SCDUPLEX_FAMILY } from "../../../../../workspace/assembly/connector/types";
import { AllColors, FiberColors } from "../../../../../workspace/assembly/palette/types";
import { getSideSpares } from "../../../../../workspace/assembly/store/selectors";
import { ColorContext } from "../../../../color/types";
import { IContainerBox } from "../../../../interactions/bounding-box/types";
import { useText } from "../../../../markers/base/text/hooks";
import { SideContext } from "../../../side/types";
import { BreakoutContext } from "../../types";
import { getConnectorSprite, getDefaultConnectorColor } from "../connector-sprite/types";
import { getHighlightContainerOffset, GREEN_HIGHLIGHT, HighlightContext, HighlightStatuses, IConnectorHighlightProps, ORANGE_HIGHLIGHT } from "../highlight/types";
import { IConnectorLegProps } from "./types";

const triggerWidth = 8;
const triggerHeight = 4.5;
const borderThickness = 0.5;

const textOffset = 0.1;

export const useConnectorLeg = (props: IConnectorLegProps) => {
    const { bezierCurve, connector, lineEnd, spriteMultiplier } = props;
    const { m, breakouts } = useContext(SideContext)
    const { cableColor: { outlineColor } } = useContext(ColorContext);
    const highlightProps = useHighlight(props);

    const sideSpares = getSideSpares(breakouts);
    const connectorIndex = (connector.position - 1) % 12;
    const connectorColor = AllColors.find(c => c.hex === connector.color || c.name === connector.color) || getDefaultConnectorColor(connector.type);
    const labelColor = FiberColors.find(c => c.hex === connector.labelColor || c.name === connector.labelColor) || FiberColors[connectorIndex];
    const textColor = getTextColor(labelColor);
    const { text, textStyle, textSize } = useText({ text: connector.label || connector.position.toString(), fill: hexStringToNumber(textColor.hex), fontSize: 7 });
    const { text: spareText, textStyle: spareTextStyle, textSize: spareTextSize } = useText({ text: getSpareDefaultText(connector, sideSpares), fill: hexStringToNumber(textColor.hex), fontSize: 7 });

    let connectorText = text;
    let connectorTextStyle = textStyle;
    let connectorTextSize = textSize;
    if (!connector.label && connector.spare) {
        connectorText = spareText;
        connectorTextStyle = spareTextStyle;
        connectorTextSize = spareTextSize;
    }
    const isPigtail = isPigtailConnector(connector.type)
    const isPigtailWithNoLeg = connector.type === PIGTAIL_NO_LEG
    const triggerStart = isDuplex(connector.type) ? 0 : isPigtail ? 55 : 10;
    const connectorTextX = m > 0 ? (lineEnd.x - triggerStart - (triggerWidth) * 0.5 - textOffset) - connectorTextSize.width : lineEnd.x - m * (triggerStart + (triggerWidth) * 0.5) - textOffset;
    const connectorTextY = lineEnd.y - connectorTextSize.height * 0.5;
    const connectorTextProps: _ReactPixi.IText = {
        text: connectorText,
        style: connectorTextStyle,
        position: { x: connectorTextX, y: connectorTextY },
        resolution: 20,
        scale: 0.5
    }

    const drawTrunk = useCallback((g: Pixi.Graphics) => {
        g.clear();
        const offset = 0.2;
        const lineStartX = bezierCurve.dst.x - m * offset
        const edgeBorder = isPigtail ? (bezierCurve.outline.thickness - bezierCurve.line.thickness) / 2 : 0;
        g.lineStyle(bezierCurve.outline.thickness, bezierCurve.outline.color)
        g.moveTo(lineStartX, bezierCurve.dst.y)
        g.lineTo(lineEnd.x + m * edgeBorder, lineEnd.y)

        g.lineStyle(bezierCurve.line.thickness, bezierCurve.line.color)
        g.moveTo(lineStartX, bezierCurve.dst.y)
        g.lineTo(lineEnd.x, lineEnd.y)
    }, [bezierCurve, lineEnd.x, lineEnd.y, m, isPigtail])

    const drawTrigger = useCallback((g: Pixi.Graphics) => {
        g.clear()
        const color = hexStringToNumber(labelColor.hex);
        g.lineStyle(borderThickness, outlineColor)
        g.beginFill(color);

        const dimensions = (lineEnd.x - m * (triggerStart + triggerWidth)) - (m * connectorTextSize.width);
        const path: Pixi.Point[] = [
            new Pixi.Point(lineEnd.x - m * (triggerStart), lineEnd.y - triggerHeight / 2),
            new Pixi.Point(dimensions, lineEnd.y - triggerHeight / 2),
            new Pixi.Point(dimensions, lineEnd.y + triggerHeight / 2),
            new Pixi.Point(lineEnd.x - m * (triggerStart), lineEnd.y + triggerHeight / 2),
        ]

        g.drawPolygon(path);
        g.endFill();

    }, [lineEnd.x, lineEnd.y, outlineColor, m, connectorTextSize, labelColor, triggerStart])

    const connectorSpriteProps = useMemo(() => {
        const sprite = getConnectorSprite(connector.type);
        const offsetY = m * sprite.height / spriteMultiplier;
        const position = {
            x: lineEnd.x,
            y: lineEnd.y + offsetY
        }

        const bootColors = isDuplex(connector.type) && connector.bootColors ? { bootAColor: connector.bootColors?.bootAColor ?? White, bootBColor: connector.bootColors?.bootBColor ?? White } : undefined;
        return {
            ...position,
            bootColors,
            rotation: -m * Math.PI / 2,
            sprite,
            color: connectorColor,
            jacketColor: bezierCurve.line.color,
            type: getConnectorTypeString(connector.type)
        };
    }, [lineEnd.x, lineEnd.y, connector, m, spriteMultiplier, connectorColor, bezierCurve.line.color]);

    return { drawTrunk, drawTrigger, connectorSpriteProps, connectorTextProps, bezierCurve, highlightProps, isPigtail, isPigtailWithNoLeg }
}

const useHighlight = ({ connector, lineEnd }: IConnectorLegProps) => {
    const { m } = useContext(SideContext);
    const side = m > 0 ? Sides.SideB : Sides.SideA;
    const { position: breakoutPosition } = useContext(BreakoutContext);
    const { width, height } = getConnectorSprite(connector.type);
    const containerOffset = getHighlightContainerOffset(connector.type);
    const highlightedConnectors = useContext(HighlightContext);
    const connectorFamily = getConnectorFamilyFromType(connector.type);
    
    const boundingBox: IContainerBox = useMemo(() => {
        const widthAdjustment = connectorFamily === LCDUPLEX_FAMILY || connectorFamily === SCDUPLEX_FAMILY ? 20 : 0;
        const connectorWidth = (height - widthAdjustment * 2 + containerOffset * 2) * 0.5;
        const connectorHeight = width - containerOffset;
        const connectorX = m > 0 ? lineEnd.x + widthAdjustment - containerOffset * 0.5 : lineEnd.x - connectorWidth - widthAdjustment + containerOffset * 0.5;
        const yAdjustment = connector.type === LSH ? 1 : 0;
        const connectorY = lineEnd.y - connectorHeight * 0.5 - yAdjustment;
        return { width: connectorWidth, height: connectorHeight, x: connectorX, y: connectorY };
    }, [connectorFamily, connector.type, height, containerOffset, width, m, lineEnd]);

    const highlightProps: IConnectorHighlightProps = useMemo(() => {
        let color = 0;
        let fillAlpha: number | undefined = undefined;
        let borderColor = 0;
        let alpha = 0;
        const highlightedConnector = highlightedConnectors.find(c => c.side === side && c.breakoutPosition === breakoutPosition && c.connectorPosition === connector.position);
        if (highlightedConnector) {
            switch (highlightedConnector.status) {
                case HighlightStatuses.Selected:
                    color = ORANGE_HIGHLIGHT;
                    borderColor = ORANGE_HIGHLIGHT;
                    break;
                case HighlightStatuses.Assigned:
                    fillAlpha = 0;
                    borderColor = GREEN_HIGHLIGHT;
                    break;
                case HighlightStatuses.AssignedSelected:
                    color = GREEN_HIGHLIGHT;
                    borderColor = GREEN_HIGHLIGHT;
                    break;
            }
            alpha = 1;
        }
        return {
            boundingBox,
            color: color,
            borderColor: borderColor,
            alpha,
            fillAlpha
        }
    }, [highlightedConnectors, side, breakoutPosition, connector.position, boundingBox]);

    return highlightProps;
}