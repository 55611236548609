import React from 'react';
import { IToleranceMarkerProps, ToleranceMarkerContainer } from "./types";
import { Container, Text } from '@inlet/react-pixi';
import { useTolerance } from "./hooks";

export const ToleranceMarker: React.FC<IToleranceMarkerProps> = (props) => {
    const { textProps, maxProps, minProps } = useTolerance(props);
    return (
        <Container name={ToleranceMarkerContainer}>
            <Text {...textProps} />
            <Text {...maxProps} />
            <Text {...minProps} />
        </Container>
    )
}