import { PayloadAction } from "@reduxjs/toolkit";
import { IAssemblyInfo } from "../../assembly/info/types";
import { initialProjectManagerResponse, IProjectAssemblyResponse } from "../../services/project-manager/types";
import { SortOption } from "../sub-header/types";
import { ProjectDrawerStatus, ProjectManagerState, DrawerStatus } from "./types";

export const setProjectManagerAction = (state: ProjectManagerState, action: PayloadAction<IProjectAssemblyResponse>) => {
    const { recentAssembly, tncAcceptedAt, tncAcceptedVersion, lastSaveOrder, sortAscending } = action.payload;
	state.tncAcceptedAt = tncAcceptedAt;
    state.tncAcceptedVersion = tncAcceptedVersion;
    state.lastSaveOrder = lastSaveOrder;
    state.sortAscending = sortAscending;
    if (recentAssembly?.id) {
        setRecentAssemblyId(state, recentAssembly.id);
    }
}

const setRecentAssemblyId = (state: ProjectManagerState, recentAssemblyId: number) => {
    state.recentAssemblyId = recentAssemblyId;
}

export const setRecentAssemblyIdAction = (state: ProjectManagerState, action: PayloadAction<number | undefined>) => {
    setRecentAssemblyId(state, action.payload ?? 0);
}

export const setTermsAndConditionsAction = (state: ProjectManagerState, action: PayloadAction<{ date: string, version: string }>) => {
    const { date, version } = action.payload;
    state.tncAcceptedAt = date;
    state.tncAcceptedVersion = version;
}

export const setOpenAction = (state: ProjectManagerState, action: PayloadAction<boolean>) => {
    state.open = action.payload;

    if (!action.payload) {
        state.status = DrawerStatus.Home;
        state.currentAssemblyInfo = undefined;
    }
}

export const setDrawerStatusAction = (state: ProjectManagerState, action: PayloadAction<ProjectDrawerStatus>) => {
    state.status = action.payload;

    if (action.payload === DrawerStatus.Home) {
        state.currentAssemblyInfo = undefined;
    }
}

export const setSortOptionsAction = (state: ProjectManagerState, action: PayloadAction<{ sortType: SortOption, sortAscending: boolean }>) => {
    const { sortType, sortAscending } = action.payload;
    state.lastSaveOrder = sortType;
    state.sortAscending = sortAscending;
}

export const setCurrentAssemblyInfoAction = (state: ProjectManagerState, action: PayloadAction<IAssemblyInfo | undefined>) => {
    state.currentAssemblyInfo = action.payload;
}

export const resetProjectManagerAction = (state: ProjectManagerState) => {
    state.recentAssemblyId = initialProjectManagerResponse.recentAssemblyId;
    state.lastSaveOrder = initialProjectManagerResponse.lastSaveOrder;
    state.sortAscending = initialProjectManagerResponse.sortAscending;
}