import React from 'react';
import { CircleMarkerContainer, CircleMarkerProps } from "./types"
import { useCircleMarker } from "./hooks"
import { Container, Text } from '@inlet/react-pixi';
import { LineMarker } from '../line/LineMarker';
import { Circle } from '../base/circle/Circle';

export const CircleMarker: React.FC<CircleMarkerProps> = (props) => {
    const { coordinate, lineMarkerProps, circleProps, text, textProps } = useCircleMarker(props);
    return (
        <Container name={CircleMarkerContainer} {...coordinate}>
            <LineMarker {...lineMarkerProps} />
            <Circle {...circleProps} />
            { text ? <Text {...textProps} /> : null }
        </Container>
    )
}