import React, { createContext } from 'react';
import { Viewport as PixiViewport } from 'pixi-viewport';
import { AnyAction } from 'redux';
import { IViewportState, initialViewportState } from './store/types';

interface IViewportInstance {
    viewport?: PixiViewport
}

export interface IViewportComponentProps {
    viewportRef: React.MutableRefObject<PixiViewport | undefined>
}

export type ViewportProps = IViewportInstance & Partial<IViewportState> & React.PropsWithChildren<{}>;

export interface IViewportContext {
    state: IViewportState,
    dispatch: React.Dispatch<AnyAction>
}

const intialViewportContext: IViewportContext = {
    state: initialViewportState,
    dispatch: (a: AnyAction) => {}
}

export const ViewportContext = createContext<IViewportContext>(intialViewportContext)