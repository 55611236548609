import { _ReactPixi } from "@inlet/react-pixi";
import { IPosition } from "../../cable/types";
import { useText } from "../base/text/hooks";
import { ITextSize } from "../base/text/types";
import { getToleranceLength } from "../tolerance/hooks";
import { DrawingSides, Orientation, Orientations, Unidirections } from "../types";
import { LineMarkerProps } from "./types";

const defaultGap = 2;

export const useLineMarker = (props: LineMarkerProps) => {
    const { orientation, length: originalLength, text, startDelimiterLength, endDelimiterLength, unidirection, toleranceProps, thickness, color, alpha } = props;
    const offsetX = orientation === Orientations.Horizontal ? thickness * 0.5 : 0;
    const start: IPosition = { x: offsetX, y: 0 };
    const direction = getLineDirection(orientation);
    const { textStyle, textSize } = useText({ text, fill: color, fontSize: 8 });
    const length = originalLength - thickness * 2;
    let gapOffset = 0;
    if (toleranceProps) {
        const { tolerance } = toleranceProps;
        const minLength = tolerance.min === 0 ? defaultGap * 0.5 : getToleranceLength(tolerance.min);
        const maxLength = tolerance.max === 0 ? defaultGap * 0.5 : getToleranceLength(tolerance.max);
        gapOffset = Math.max(minLength, maxLength) * defaultGap; 
    }
    const { startPath, end, endPath, textPosition } = getLineCoordinates(start, offsetX, orientation, length, textSize, thickness, gapOffset);
    let delimiters = startDelimiterLength !== 0 && endDelimiterLength !== 0;
    if (unidirection) {
        delimiters = unidirection === Unidirections.Start ? startDelimiterLength !== 0 : endDelimiterLength !== 0;
    }
    const { startPath: startDelimiterPath, endPath: endDelimiterPath, thickness: delimiterThickness } = useDelimiters(start, startDelimiterLength, end, endDelimiterLength, orientation, thickness);
    const textResolution = 10;
    const textScale = 1;

    const textProps: _ReactPixi.IText = {
        text,
        style: textStyle,
        position: textPosition,
        resolution: textResolution,
        scale: textScale
    }

    return { start, startPath, end, endPath, delimiters, startDelimiterPath, endDelimiterPath, unidirection, toleranceProps, direction, text, textProps, thickness, delimiterThickness, color, alpha };
}

const getLineCoordinates = (start: IPosition, offsetX: number, orientation: Orientation, length: number, textSize: ITextSize, textOffset: number = 6, gapOffset: number = 0) => {
    let textPosition: IPosition = {...start};
    let startPath: IPosition[] = [start, {...start}];
    let end: IPosition = {...start};
    let endPath: IPosition[] = [{...end}, end];

    const halfLength = length * 0.5;
    let textGap = 0;
    if (orientation === Orientations.Horizontal) {
        textGap = textSize.width + gapOffset;
        textPosition.x += halfLength - textGap - 2 * offsetX + 1;
        textPosition.y -= textSize.height;
        end.x += length;
        startPath[1].x = textPosition.x - textOffset - 1;
        endPath[0].x = end.x + textGap + textOffset + 1.5 - halfLength;
    } else {
        textGap = textSize.height + gapOffset;
        textPosition.x -= textSize.width;
        textPosition.y += halfLength - textGap + 1;
        end.y += length;
        startPath[1].y = textPosition.y - textOffset;
        endPath[0].y = end.y + textGap + textOffset - halfLength;
    }

    return { startPath, end, endPath, textPosition }
}

const getLineDirection = (orientation: Orientation) => {
    if (orientation === Orientations.Horizontal) {
        return { start: DrawingSides.Left, end: DrawingSides.Right };
    } else {
        return { start: DrawingSides.Top, end: DrawingSides.Bottom };
    }
}

export const useDelimiters = (start: IPosition, startLength: number, end: IPosition, endLength: number, orientation: Orientation, markerThickness: number) => {
    const startPath = useDelimiter(start, startLength, orientation, 1);
    const endPath = useDelimiter(end, endLength, orientation, -1);
    const thickness = markerThickness * 0.5;
    return { startPath, endPath, thickness };
}

export const useDelimiter = (coordinate: IPosition, length: number, markerOrientation: Orientation, offsetX: number, offsetY: number = 8) => {
    let start: IPosition = { x: 0, y: 0 };
    let end: IPosition = { x: 0, y: 0 };
    offsetY = length < 0 ? -offsetY: offsetY;
    if (markerOrientation === Orientations.Horizontal) {
        start = { x: coordinate.x - offsetX, y: coordinate.y - offsetY };
        end = { x: start.x, y: start.y + length + offsetY };
    } else {
        start = { x: coordinate.x, y: coordinate.y - offsetX };
        end = { x: start.x - length, y: start.y };
    }

    return [start, end];
}