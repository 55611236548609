import { createSelector } from "@reduxjs/toolkit";
import { viewportBreakoutSelectionSelector } from "../../../pixi/viewport/store/selectors";
import { getSpareDefaultText, IBreakout, IConnector, IConnectorWithPositions, Side, Sides } from "../breakout/types";
import { ConnectorTypes, getConnectorFamilyFromType, getConnectorType, isDuplex, isPigtailConnector, PIGTAIL_NO_LEG, SN } from "../connector/types";
import { SortOptions } from "../../project-drawer/sub-header/types";
import { IAssemblyInfo } from "../info/types";
import { convertTo, Length, toLengthString, Units } from "../length/types";
import { IAssemblyLengthIndexer, ICSVSideInfo } from "./types";
import { LengthPositions } from "../../../pixi/cable/position/types";
import { breakoutOffsetWidth } from "../../../pixi/cable/breakout/hooks";
import { breakoutTrunkWidth } from "../../../pixi/cable/side/hooks";
import { DefaultJacketColors, FiberColors, JacketColors, MultimodeJacketColors, SinglemodeJacketColors } from "../palette/types";
import { Black, Blue, White, Yellow } from "../../../ui/dialog/color/types";
import { IAnnotationData } from "../../annotation/row/types";
import { ILegTableRow } from "../../offscreen/leg-table/types";
import { generateFiberMapReportRows } from "../../polarity/connector-assignment/reducer/actions";
import { polarityMapsSelector } from "../../polarity/store/selectors";
import { IPolarityConfigurationProps } from "../../polarity/polarity-scheme/types";
import { PolaritySortTypes } from "../settings/types";
import { IFiberMapInfo } from "../../offscreen/fiber-map-table/types";
import i18next from "i18next";
import { LocalizationKeys } from "../../../localization/types";
import { CableTypes, BootColorCombinations, BootColorCombination, FiberTypes, FurcationOuterDiameters } from "../types";
import { PullingGrips } from "../types";
import { rootSelector } from "../../../store/selectors";
import { convertToleranceUnits, defaultLabelTolerances, defaultTolerances, ITolerance, tolerances } from "../../../pixi/markers/tolerance/types";
import { unitSelector } from "../length/store/selectors";
import { RootState } from "../../../store/reducer";

export const assemblySelector = createSelector(
    rootSelector,
    root => root.assembly
);

export const currentAssemblySelector = createSelector(
    assemblySelector,
    assembly => assembly.currentAssembly
);

export const currentAssemblyIdSelector = ((state: RootState) => state.assembly.currentAssembly.id)

export const assembliesInfosSelector = createSelector(
    assemblySelector,
    assembly => assembly.assemblies
);

export const assembliesLoadedSelector = createSelector(
    assemblySelector,
    assembly => assembly.loaded
);

export const assemblyInfoSelector = (assemblyId?: number) => {
    return createSelector(
        currentAssemblySelector,
        assembliesInfosSelector,
        (currentAssembly, assemblies) => {
            return assemblies.find(a => a.id === assemblyId) || currentAssembly.assemblyInfo;
        }
    );
};

export const currentAssemblyInfoSelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.assemblyInfo
);

export const showCableBaseReferencesSelector = ((state: RootState) => !!state.assembly.currentAssembly.assemblyInfo?.showA0B0)

export const showToleranceMarkerSelector = ((state: RootState) => !!state.assembly.currentAssembly.assemblyInfo?.showLabelDistance)

export const showPullingGripSelector = ((state: RootState) => !!state.assembly.currentAssembly.assemblyInfo?.showPullingGrip)

export const annotationsListSelector = ((state: RootState) => state.assembly.currentAssembly.annotation?.calloutVisibility ?? [])

export const workspaceHasAnnotationsSelector = ((state: RootState) => state.assembly.currentAssembly.annotation?.calloutVisibility ? state.assembly.currentAssembly.annotation?.calloutVisibility.length > 0 : false)

export const annotationDataListSelector = (isOptimusUser: boolean) => {
    return createSelector(
        annotationsListSelector,
        assemblyGlobalAnnotationsDataSelector(isOptimusUser),
        (annotations, annotationData) => annotationData.filter((d) => annotations.includes(d.number))
    );
};

export const sortedAssemblySelector = (ascending: boolean, lastSaveOrder: string) => {
    return createSelector(
        assembliesInfosSelector,
        (assemblies) => {
            let sortedAssemblies: IAssemblyInfo[] = []
            const sortValue = ascending ? -1 : 1;
            if (lastSaveOrder === SortOptions.dateModified) {
                sortedAssemblies = [...assemblies].sort((a, b) => {
                    return new Date(a.lastModified!) < new Date(b.lastModified!) ? sortValue : -sortValue
                })
            }
            else {
                sortedAssemblies = [...assemblies].sort((a, b) => {
                    const aName = a.name && a.name.length ? a.name : `${a.id}`
                    const bName = b.name && b.name.length ? b.name : `${b.id}`
                    return aName < bName ? sortValue : -sortValue
                })
            }

            return sortedAssemblies;
        }
    )
}

export const currentAssemblyFiberCountSelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.fiberCount
)

export const currentFlameRatingSelector = createSelector(
    currentAssemblyInfoSelector,
    assemblyInfo => assemblyInfo?.flameRating
)

export const currentPullingGripSelector = ((state: RootState) => state.assembly.currentAssembly.assemblyInfo?.pullingGrip ?? "None");

export const currentCableTypeSelector = createSelector(
    currentAssemblyInfoSelector,
    assemblyInfo => assemblyInfo?.type ?? CableTypes.Fanout
)

export const currentFiberTypeSelector = createSelector(
    currentAssemblyInfoSelector,
    assemblyInfo => assemblyInfo?.fiberType ?? FiberTypes.SMOS2
)

export const currentAssemblyPaletteSelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.palette
)

export const currentAssemblyAnnotationSelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.annotation
)

export const currentPolaritySortTypeSelector = createSelector(
    currentAssemblyInfoSelector,
    assemblyInfo => assemblyInfo?.polaritySortType ?? PolaritySortTypes.EndA
)

export const currentFurcationOuterDiameterSelector = ((state: RootState) => state.assembly.currentAssembly.assemblyInfo?.furcationOuterDiameter ?? FurcationOuterDiameters[0]);

export const currentAssemblyPolaritySelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.polarity
)

export const currentConnectorAssignmentsSelector = createSelector(
    currentAssemblyPolaritySelector,
    polarity => polarity?.connectorAssignments ?? []
)

export const currentJacketColorSelector = createSelector(
    currentAssemblyPaletteSelector,
    palette => JacketColors.find(c => c.hex === palette?.jacketColor || c.name === palette?.jacketColor) ?? Yellow
)

export const currentEndDesignationSelectorFactory = (side: Side) => {
    return createSelector(
        currentAssemblyInfoSelector,
        assemblyInfo => {
            if (side === Sides.SideA) {
                return assemblyInfo?.endADesignation ? `END A (${assemblyInfo.endADesignation})` : `END A`;
            } else {
                return assemblyInfo?.endBDesignation ? `END B (${assemblyInfo.endBDesignation})` : `END B`;
            }
        }
    )
}

export const toleranceSelector = createSelector(
    currentAssemblyInfoSelector,
    unitSelector,
    (assemblyInfo, unit) => {
        const defaultTolerance: ITolerance = unit.unit === Units.Millimeter ? defaultTolerances.milimeter : defaultTolerances.inch;
        if (assemblyInfo && assemblyInfo.type) {
            switch(assemblyInfo.type) {
                case CableTypes.Altos:
                case CableTypes.BareRibbon:
                case CableTypes.EDGE:
                case CableTypes.JacketedRibbon:
                case CableTypes.TMIC:
                    return unit.unit === Units.Millimeter ? tolerances.milimeter : tolerances.inch;
                default:
                    return defaultTolerance;
            }
        }
        return defaultTolerance;
    }
);

export const tolerancesSelector = createSelector(
    currentAssemblyInfoSelector,
    (assmemblyInfo) => {
        if (assmemblyInfo && assmemblyInfo.type) {
                switch(assmemblyInfo.type) {
                    case CableTypes.TMIC:
                    case CableTypes.Altos:
                    case CableTypes.BareRibbon:
                    case CableTypes.JacketedRibbon:
                    case CableTypes.EDGE:
                        return tolerances;
                    default:
                        return defaultTolerances;
                }
        }
        return defaultTolerances;
    }
);

export const currentCustomTolerancesSelector = createSelector(
    tolerancesSelector,
    currentAssemblyInfoSelector,
    (defaultTolerances, assemblyInfo) => {
        if (assemblyInfo) {
            return {
                legTolerance: assemblyInfo.legTolerance ?? defaultTolerances.milimeter,
                legPrimeTolerance: assemblyInfo.legPrimeTolerance ?? defaultTolerances.milimeter,
                legLabelTolerance: assemblyInfo.legLabelTolerance ?? defaultLabelTolerances.millimeter,
            }
        }
        return {
            legTolerance: defaultTolerances.milimeter,
            legPrimeTolerance: defaultTolerances.milimeter,
            legLabelTolerance: defaultLabelTolerances.millimeter,
        };
    } 
);

export const currentConvertedCustomTolerancesSelector = createSelector(
    tolerancesSelector,
    currentAssemblyInfoSelector,
    unitSelector,
    (defaultTolerances, assemblyInfo, {unit}) => {
        const defaultTolerance = convertToleranceUnits(defaultTolerances.milimeter, Units.Millimeter, unit);
        const defaultLabelTolerance = convertToleranceUnits(defaultLabelTolerances.millimeter, Units.Millimeter, unit);
        if (assemblyInfo) {
            return {
                legTolerance: assemblyInfo.legTolerance ? convertToleranceUnits(assemblyInfo.legTolerance, Units.Millimeter, unit) : defaultTolerance,
                legPrimeTolerance: assemblyInfo.legPrimeTolerance ? convertToleranceUnits(assemblyInfo.legPrimeTolerance, Units.Millimeter, unit) : defaultTolerance,
                legLabelTolerance: assemblyInfo.legLabelTolerance ? convertToleranceUnits(assemblyInfo.legLabelTolerance, Units.Millimeter, unit) : defaultLabelTolerance,
            }
        }
        return {
            legTolerance: defaultTolerance,
            legPrimeTolerance: defaultTolerance,
            legLabelTolerance: defaultLabelTolerance,
        };
    } 
);

export const currentConvertedDisplayCustomTolerancesSelector = createSelector(
    currentConvertedCustomTolerancesSelector,
    (customTolerances) => {
        return {
            legTolerance: { ...customTolerances.legTolerance, min: customTolerances.legTolerance.min * -1 },
            legPrimeTolerance: { ...customTolerances.legPrimeTolerance, min: customTolerances.legPrimeTolerance.min * -1 },
            legLabelTolerance: { ...customTolerances.legLabelTolerance, min: customTolerances.legLabelTolerance.min * -1 }            
        }
    }
);

export const filteredJacketColorSelector = createSelector(
    currentAssemblyInfoSelector,
    assemblyInfo => {
        if (assemblyInfo && assemblyInfo.fiberType) {
            switch (assemblyInfo.fiberType) {
                case FiberTypes.SMOS2:
                    return [...SinglemodeJacketColors, ...DefaultJacketColors];
                case FiberTypes.MMOM3:
                case FiberTypes.MMOM4:
                    return [...DefaultJacketColors, ...MultimodeJacketColors];
                case FiberTypes.MM0M5:
                    return JacketColors;
                default: 
                    return DefaultJacketColors;
            }
        }
        return DefaultJacketColors;
    }
)

export const defaultBreakoutJacketColorSelectorFactory = (side?: Side) => {
    return createSelector(
        currentAssemblyPaletteSelector,
        palette => {
            if (side && side === Sides.SideB) {
                return JacketColors.find(c => c.hex === palette?.sideBTrunkColor || c.name === palette?.sideBTrunkColor) ?? Yellow;
            } else {
                return JacketColors.find(c => c.hex === palette?.sideATrunkColor || c.name === palette?.sideATrunkColor) ?? Yellow;
            }
        }
    )
}

export const breakoutJacketColorSelectorFactory = (breakout: IBreakout) => {
    return createSelector(
        defaultBreakoutJacketColorSelectorFactory(breakout.side),
        defaultJacketColor => {
            if (breakout.jacketColor) {
                return JacketColors.find(c => c.name === breakout.jacketColor) ?? Yellow;
            } else {
                return defaultJacketColor;
            }
        }
    )
}

export const defaultLegJacketColorSelectorFactory = (side?: Side) => {
    return createSelector(
        currentAssemblyPaletteSelector,
        palette => {
            if (side && side === Sides.SideB) {
                return JacketColors.find(c => c.hex === palette?.sideBLegColor || c.name === palette?.sideBLegColor) ?? Yellow;
            } else {
                return JacketColors.find(c => c.hex === palette?.sideALegColor || c.name === palette?.sideALegColor) ?? Yellow;
            }
        }
    )
}

export const legJacketColorSelectorFactory = (breakout: IBreakout) => {
    return createSelector(
        defaultLegJacketColorSelectorFactory(breakout.side),
        defaultJacketColor => {
            const legJacketColor = breakout.furcation.jacketColor
            if (legJacketColor) {
                return JacketColors.find(c => c.name === legJacketColor) ?? Yellow;
            } else {
                return defaultJacketColor;
            }
        }
    )
}

export const currentOverallLengthSelector = createSelector(
    currentAssemblySelector,
    assembly => assembly.overallLength
);

export const sideSelectorFactory = (side: Side) => {
    return createSelector(
        currentAssemblySelector,
        assembly => side === Sides.SideA ? assembly.sideA : assembly.sideB
    );
};

export const sideBreakoutsSelectorFactory = (side: Side) => {
    return createSelector(
        sideSelectorFactory(side),
        side => side?.breakouts ?? []
    );
}

export const sortedSideBreakoutsSelectorFactory = (side: Side) => {
    return createSelector(
        sideSelectorFactory(side),
        side => side?.breakouts.slice().sort((a, b) => a.position > b.position ? 1 : -1) ?? []
    );
};

export const sortedBreakoutsSelector = createSelector(
    sortedSideBreakoutsSelectorFactory(Sides.SideA),
    sortedSideBreakoutsSelectorFactory(Sides.SideB),
    (sideABreakouts, sideBBreakouts) => [...sideABreakouts, ...sideBBreakouts]
);

export const selectedBreakoutSelector = createSelector(
    viewportBreakoutSelectionSelector,
    currentAssemblySelector,
    sortedBreakoutsSelector,
    (selectedBreakout, currentAssembly, breakouts) => {
        if (selectedBreakout) {
            if (selectedBreakout.side === Sides.SideA) {
                return currentAssembly.sideA?.breakouts.find(b => b.position === selectedBreakout.position) ?? breakouts[0];
            } else {
                return currentAssembly.sideB?.breakouts.find(b => b.position === selectedBreakout.position) ?? breakouts[0];
            }
        } else {
            return breakouts[0];
        }
    }
);

export const sideFiberCountSelectorFactory = (side: Side) => {
    return createSelector(
        sortedSideBreakoutsSelectorFactory(side),
        sideBreakouts => getSideFiberCount(sideBreakouts)
    );
};

export const getSideFiberCount = (sideBreakouts: IBreakout[]) => {
    return sideBreakouts.flatMap(b => b.furcation.groups.flatMap(g => g.connectors.flatMap(c => getConnectorType(c.type).fiberCount))).reduce((prev, cur) => { return prev + cur; })
};

export const sideSparesSelectorFactory = (side: Side) => {
    return createSelector(
        sortedSideBreakoutsSelectorFactory(side),
        sideBreakouts => getSideSpares(sideBreakouts)
    )
};

export const getSideSpares = (sideBreakouts: IBreakout[]) => {
    return sideBreakouts.flatMap(b => b.furcation).flatMap(g => g.groups).flatMap(c => c.connectors).filter(c => c.spare);
}

export const assemblySpecificAnnotationDataSelector = createSelector(
    currentAssemblySelector,
    currentFiberTypeSelector,
    (assembly,assemblyFiberType) => {
        const fiberCountRow: IAnnotationData = { number: "1", description: `${assembly.fiberCount} FIBER ${assemblyFiberType} MICRO CABLE`, quantity: 1, disabled: false };
        return fiberCountRow;
    }
)
export const sideBreakoutAnnotationsDataSelectorFactory = (side: Side, isOptimusUser: boolean) => {
    return createSelector(
        sortedSideBreakoutsSelectorFactory(side),
        sideConnectorFiberCountsSelectorFactory(side),
        currentFiberTypeSelector,
        (sideBreakouts, sideConnectorFiberCounts, fiberType) => {
            const isPigtail = isPigtailConnector(sideBreakouts[0].furcation.groups[0].connectors[0].type);
            const nbBreakouts = sideBreakouts.length;
            const connectors = sideBreakouts.map(b => b.furcation).flatMap(f => f.groups).flatMap(g => g.connectors);
            const nbConnectors = connectors.length;
            const connectorsInfo: { type: string, quantity: number}[] = [];
            const connectorTypes = connectors.map(c => c.type).filter((t, i, self) => self.indexOf(t) === i);
            for (let connectorType of connectorTypes) {
                const nbConnectors = connectors.filter(c => c.type === connectorType).length;
                connectorsInfo.push({
                    type: connectorType.toUpperCase(),
                    quantity: nbConnectors
                });
            }

            const sideCharacter = side === Sides.SideA ? "a" : "b";
            const serialNumberRow: IAnnotationData = { number: "", description: "SERIAL NUMBER LABEL, WRAP-AROUND STYLE, 150 MM FROM BREAKOUT", quantity: 1, disabled: isPigtail };
            const heatshrinkTransitionRow: IAnnotationData = { number: "", description: "HEATSHRINK SLEEVE OVER TRANSITION POINT", quantity: 1, disabled: isPigtail };
            const subunitRow: IAnnotationData = { number: "", description: `SUBUNIT NUMBER`, quantity: nbBreakouts, disabled: isPigtail };
            const heatshrinkBreakRow: IAnnotationData = { number: "", description: "HEATSHRINK SLEEVE OVER TRANSITION POINT", quantity: nbBreakouts, disabled: isPigtail };
            const furcationTubingWidth = getFurcationTubingWidth(sideConnectorFiberCounts); 
            const furcationTubingRow: IAnnotationData = { number: "", description:  `${furcationTubingWidth} FURCATION TUBING` , quantity: nbConnectors, disabled: isPigtail };
            const labelDistance = isOptimusUser ? "150 MM FROM LEG" : "50 MM FROM BREAKOUT"
            const tailLabelRow: IAnnotationData = { number: "", description: `COLORED LEG LABEL, WRAP-AROUND STYLE, ${labelDistance}`, quantity: nbConnectors, disabled: isPigtail };
            const connectorTypeRows: IAnnotationData[] = [];

            for (let connectorInfo of connectorsInfo) {
                const connectorType = connectorInfo.type;
                connectorTypeRows.push({
                    number: "",
                    description: `${connectorType === SN ? connectorType.replace("SN", "SENKO NANO") : connectorType} ${fiberType} CONNECTOR`,
                    quantity: connectorInfo.quantity,
                    disabled: isPigtail
                });
            }
            
            let annotations: IAnnotationData[] = [serialNumberRow, heatshrinkTransitionRow, subunitRow, heatshrinkBreakRow, furcationTubingRow, tailLabelRow, ...connectorTypeRows]
            if (sideBreakouts[0].trunk.length.value === 0) {
                annotations = [serialNumberRow, heatshrinkTransitionRow, furcationTubingRow, tailLabelRow, ...connectorTypeRows]
            }

            return annotations.map((a, index) => { return { ...a, number: `${index + 2}${sideCharacter}` } });
        }
    )
}

const getFurcationTubingWidth = (fiberCount: number[]) => {
    if (Math.max(...fiberCount) > 2) {
        if (fiberCount.indexOf(2) > -1)  return "2 MM / 3 MM";
        return "3 MM";
    }
    return "2 MM";
}

export const assemblyGlobalAnnotationsDataSelector = (isOptimusUser: boolean) => {
    return createSelector(
        assemblySpecificAnnotationDataSelector,
        sideBreakoutAnnotationsDataSelectorFactory(Sides.SideA, isOptimusUser),
        sideBreakoutAnnotationsDataSelectorFactory(Sides.SideB, isOptimusUser),
        (assemblyAnnotationData, sideAAnnotationsData, sideBAnnotationsData) => [assemblyAnnotationData, ...sideAAnnotationsData, ...sideBAnnotationsData]
    );
};

export const lengthIndexerSelector = createSelector(
    currentAssemblySelector,
    (assembly) => {
        const lengthSortedAssembly: IAssemblyLengthIndexer = {
            sideA: {
                lengthPairs: {},
                relativeIndices: {}
            },
            sideB: {
                lengthPairs: {},
                relativeIndices: {}
            }
        }
        const sideA = assembly.sideA;
        if (sideA) {
            const breakouts = sideA.breakouts;
            const lengthPairs = generateLengthPairs(breakouts);
            const relativeIndices = generateRelativeIndices(lengthPairs);
            lengthSortedAssembly.sideA.lengthPairs = lengthPairs
            lengthSortedAssembly.sideA.relativeIndices = relativeIndices
        }

        const sideB = assembly.sideB;
        if (sideB) {
            const breakouts = sideB.breakouts;
            const lengthPairs = generateLengthPairs(breakouts);
            const relativeIndices = generateRelativeIndices(lengthPairs);
            lengthSortedAssembly.sideB.lengthPairs = lengthPairs
            lengthSortedAssembly.sideB.relativeIndices = relativeIndices
        }

        return lengthSortedAssembly;
    }
)

function generateRelativeIndices(lengthPairs: { [lengthValue: number]: Length[]; }) : LengthPositions {
    const lengthValues = Object.keys(lengthPairs).map(k => Number.parseFloat(k));
    const sortedValues = [...lengthValues].sort((a, b) => a < b ? -1 : 1);
    const relativeIndices: LengthPositions = {};
    const breakoutTrunkBase = sortedValues.length && sortedValues[0] ? breakoutTrunkWidth : breakoutTrunkWidth / 2
    sortedValues.forEach((length, i) => {
        const offset = length ? breakoutTrunkBase : 0
        relativeIndices[length] = { positionIndex: i, positionOffset: offset + (i * breakoutOffsetWidth)};
    });

    return relativeIndices;
}

function generateLengthPairs(breakouts: IBreakout[], withConnectorsLengths: boolean = false) {
    const sideUniqueLengths: { [lengthValue: number]: Length[]; } = {};
    for (let i = 0; i < breakouts.length; i++) {
        const breakout = breakouts[i];
        const trunk = breakout.trunk;

        if (sideUniqueLengths[trunk.length.value]) {
            sideUniqueLengths[trunk.length.value].push({ ...trunk.length });
        }
        else {
            sideUniqueLengths[trunk.length.value] = [{ ...trunk.length }];
        }

        if (withConnectorsLengths) {
            const connectorLengths = breakout.furcation.groups.map(g => g.connectors).flat().map(c => c.length!).filter(c => c !== undefined && c !== null);
            const groupLengths = breakout.furcation.groups.map(g => g.length).filter(g => g !== undefined && g !== null);
            connectorLengths.push(...groupLengths);

            for (let i = 0; i < connectorLengths.length; i++) {
                const connectorLength = connectorLengths[i];
                const adjustedLength: Length = { ...connectorLength, value: connectorLength.value + trunk.length.value };
                if (sideUniqueLengths[adjustedLength.value]) {
                    sideUniqueLengths[adjustedLength.value].push({ ...adjustedLength });
                }
                else {
                    sideUniqueLengths[adjustedLength.value] = [{ ...adjustedLength }];
                }
            }
        }
    }

    return sideUniqueLengths;
}

export const buildPlanTableRowsSelector = createSelector(
    sideBreakoutsSelectorFactory(Sides.SideA),
    sideBreakoutsSelectorFactory(Sides.SideB),
    currentEndDesignationSelectorFactory(Sides.SideA),
    currentEndDesignationSelectorFactory(Sides.SideB),
    currentPullingGripSelector,
    unitSelector,
    (sideABreakouts, sideBBreakouts, endADesignation, endBDesignation, pullingGrip, unit) => {
        const isSideAPigtailWithNoleg = sideABreakouts[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG;
        const isSideBPigtailWithNoleg = sideBBreakouts[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG;
        const sideARows = isSideAPigtailWithNoleg ? [] : getBuildPlanSideRows(Sides.SideA, unit.unit, endADesignation, pullingGrip, sideABreakouts);
        const sideBRows = isSideBPigtailWithNoleg ? [] : getBuildPlanSideRows(Sides.SideB, unit.unit, endBDesignation, pullingGrip, sideBBreakouts);

        return [...sideARows, ...sideBRows];
    }
)

export const sideBreakoutsTableRowsSelectorFactory = (side: Side) => {
    return createSelector(
        sideBreakoutsSelectorFactory(side),
        defaultBreakoutJacketColorSelectorFactory(side),
        (breakouts, defaultBreakoutJacketColor) => {
            const sideCharacter = side === Sides.SideA ? "A" : "B";
            if (breakouts[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG) {
                return [{
                    breakout: "N/A",
                    label: "",
                    lengthMM: "",
                    lengthIN: "",
                    jacketColor: ""
                }]
            }
            return breakouts.map(b => {
                if (b.trunk.length.value > 0 && b.furcation.groups[0].connectors[0].type !== PIGTAIL_NO_LEG) {
                    const millimeterLength = convertTo(b.trunk.length, Units.Millimeter);
                    const inchesLength = convertTo(b.trunk.length, Units.Inches);
                    return {
                        breakout: `${sideCharacter}${b.position}`,
                        label: b.label ?? b.position,
                        lengthMM: toLengthString(millimeterLength),
                        lengthIN: toLengthString(inchesLength, 1),
                        jacketColor: b.jacketColor ?? defaultBreakoutJacketColor.name
                    }
                } else {
                    return {
                        breakout: "N/A",
                        label: "",
                        lengthMM: "",
                        lengthIN: "",
                        jacketColor: ""
                    }
                }
            })
        }
    )
}

const getLabel = (connector: IConnector, sideSpares: IConnector[]) => {
    if (connector.label) return connector.label;
    if (connector.spare) return getSpareDefaultText(connector, sideSpares);
    return connector.position.toString();
}

export const sideLegsTableRowsSelectorFactory = (side: Side) => {
    return createSelector(
        sideBreakoutsSelectorFactory(side),
        defaultLegJacketColorSelectorFactory(side),
        sideSparesSelectorFactory(side),
        (breakouts, defaultLegJacketColor, sideSpares) => {
            const sideCharacter = side === Sides.SideA ? "A" : "B";
            let rows: ILegTableRow[] = [];
            let legIndex = 1;
            if (breakouts[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG) {
                return [{
                    legNumber: "",
                    breakout: "",
                    legId: "",
                    labelColor: "",
                    label: "",
                    lengthMM: "",
                    lengthIN: "",
                    jacketColor: ""
                }]
            }
            for (const breakout of breakouts) {
                for (const group of breakout.furcation.groups) {
                    for (const connector of group.connectors) {
                        const breakoutPosition = `${sideCharacter}${breakout.position}'`;
                        const lengthMM = connector.length ? toLengthString(convertTo(connector.length, Units.Millimeter)) : toLengthString(convertTo(group.length, Units.Millimeter));
                        const lengthIN = connector.length ? toLengthString(convertTo(connector.length, Units.Inches), 1) : toLengthString(convertTo(group.length, Units.Inches), 1);
                        const connectorIndex = (connector.position - 1) % 12;
                        rows.push({
                            legNumber: legIndex.toString(),
                            breakout: breakoutPosition,
                            legId: `${breakoutPosition}${connector.position}`,
                            labelColor: connector.labelColor ?? FiberColors[connectorIndex].name,
                            label: getLabel(connector, sideSpares),
                            lengthMM,
                            lengthIN,
                            jacketColor: breakout.furcation.jacketColor ?? defaultLegJacketColor.name
                        });
                        legIndex++;
                    }
                }
            }
            return rows;
        }
    )
}

export const polarityTableRowsSelector = createSelector(
    currentConnectorAssignmentsSelector,
    currentPolaritySortTypeSelector,
    (assignments, sortType) => {
        let polarityTableRows = assignments.flatMap(a => generateFiberMapReportRows(a.connectors, a.fiberMapData));

        if (sortType === PolaritySortTypes.EndA) {
            polarityTableRows = polarityTableRows.sort((a, b) => sortPolarityTableRows(a.sideA, b.sideA));
        } else {
            polarityTableRows = polarityTableRows.sort((a, b) => sortPolarityTableRows(a.sideB, b.sideB));
        }

        return polarityTableRows;
    } 
)

function sortPolarityTableRows(a: IFiberMapInfo, b: IFiberMapInfo) {
    if (a.breakout === b.breakout && a.legID === b.legID && a.connectorPosition === b.connectorPosition) {
        const aFiberPos = Number(a.position);
        const bFiberPos = Number(b.position);
        if (!isNaN(aFiberPos) && !isNaN(bFiberPos)) {
            return aFiberPos > bFiberPos ? 1 : -1;
        } else {
            return a.position > b.position ? 1 : -1;
        }
    }
    if (a.breakout === b.breakout && a.legID === b.legID) {
        return a.connectorPosition > b.connectorPosition ? 1 : -1;
    }
    if (a.breakout === b.breakout) {
        return a.legID.localeCompare(b.legID, undefined, { numeric: true });
    }
    return a.breakout.localeCompare(b.breakout, undefined, { numeric: true });
}

export const sideConnectorFiberCountsSelectorFactory = (side: Side) => {
    return createSelector(
        sideBreakoutsSelectorFactory(side),
        (breakouts) => {
            const connectors = breakouts.map(b => b.furcation).flatMap(f => f.groups).flatMap(g => g.connectors);
            return connectors.map(c => getConnectorType(c.type).fiberCount).filter((t, i, self) => self.indexOf(t) === i);
        }
    )
}

export const currentAssemblyFiberCountsSelector = createSelector(
    sideConnectorFiberCountsSelectorFactory(Sides.SideA),
    sideConnectorFiberCountsSelectorFactory(Sides.SideB),
    (sideAFiberCounts, sideBFiberCounts) => { return { sideAFiberCounts, sideBFiberCounts } }
)

export const sideConnectorTypesSelectorFactory = (side: Side) => {
    return createSelector(
        sideBreakoutsSelectorFactory(side),
        (breakouts) => {
            const connectors = breakouts.map(b => b.furcation).flatMap(f => f.groups).flatMap(g => g.connectors);
            return connectors.map(c => c.type).filter((t, i, self) => self.indexOf(t) === i);
        }
    )
}

export const currentAssemblyConnectorTypesSelector = createSelector(
    sideConnectorTypesSelectorFactory(Sides.SideA),
    sideConnectorTypesSelectorFactory(Sides.SideB),
    (sideAConnectorTypes, sideBConnectorTypes) => { return { sideAConnectorTypes, sideBConnectorTypes } }
)

export const currentAssemblyHasMultiBootConnectorsSelector = createSelector(
    currentAssemblyConnectorTypesSelector,
    (connectorTypes) => {
        const sideAHasLCDuplex = connectorTypes.sideAConnectorTypes.includes(ConnectorTypes.ConnLCDuplex.type);
        const sideBHasLCDuplex = connectorTypes.sideBConnectorTypes.includes(ConnectorTypes.ConnLCDuplex.type);
        const sideAHasSCDuplex = connectorTypes.sideAConnectorTypes.includes(ConnectorTypes.ConnSCDuplex.type);
        const sideBHasSCDuplex = connectorTypes.sideBConnectorTypes.includes(ConnectorTypes.ConnSCDuplex.type);
        return sideAHasLCDuplex || sideAHasSCDuplex || sideBHasLCDuplex || sideBHasSCDuplex;
    }
)

export const currentAssemblyHasLCDuplexConnectorsSelector = createSelector(
    currentAssemblyConnectorTypesSelector,
    (connectorTypes) => {
        const sideAHasLCDuplex = connectorTypes.sideAConnectorTypes.includes(ConnectorTypes.ConnLCDuplex.type);
        const sideBHasLCDuplex = connectorTypes.sideBConnectorTypes.includes(ConnectorTypes.ConnLCDuplex.type);
        return sideAHasLCDuplex ||  sideBHasLCDuplex;
    }
)

export const currentAssemblyHasSCDuplexConnectorsSelector = createSelector(
    currentAssemblyConnectorTypesSelector,
    (connectorTypes) => {
        const sideAHasSCDuplex = connectorTypes.sideAConnectorTypes.includes(ConnectorTypes.ConnSCDuplex.type);
        const sideBHasSCDuplex = connectorTypes.sideBConnectorTypes.includes(ConnectorTypes.ConnSCDuplex.type);
        return sideAHasSCDuplex || sideBHasSCDuplex;
    }
)

export const toConnectorsWithPositions = (breakouts: IBreakout[]) => {
    const connectorsWithPositions: IConnectorWithPositions[] = [];
    for (const breakout of breakouts){
        for (const group of breakout.furcation.groups){
            for (const connector of group.connectors) {
                connectorsWithPositions.push({ ...connector, breakoutPosition: breakout.position , groupPosition: group.position, side: breakout.side })
            }
        }
    }
    return connectorsWithPositions
}

export const currentAssemblyConnectorsSelector = createSelector(
    sideBreakoutsSelectorFactory(Sides.SideA),
    sideBreakoutsSelectorFactory(Sides.SideB),
    (sideABreakouts, sideBBreakouts) => { 
        const sideAConnectorsWithPositions: IConnectorWithPositions[] = toConnectorsWithPositions(sideABreakouts);
        const sideBConnectorsWithPositions: IConnectorWithPositions[] = toConnectorsWithPositions(sideBBreakouts);
        return { sideAConnectorsWithPositions, sideBConnectorsWithPositions };
    }
)

export const currentAssemblyBootColorCombinationSelector = createSelector(
    currentAssemblyHasMultiBootConnectorsSelector,
    currentAssemblyConnectorsSelector,
    (hasDuplexConnector, currentAssemblyConnectors) => {
        if (hasDuplexConnector) {
            const mergedSides = [...currentAssemblyConnectors.sideAConnectorsWithPositions, ...currentAssemblyConnectors.sideBConnectorsWithPositions]
            const duplexConnector = mergedSides.find(c => isDuplex(c.type) === true);
            const bootAColor = duplexConnector?.bootColors?.bootAColor?.name ?? White.name;
            const bootBColor = duplexConnector?.bootColors?.bootBColor?.name ?? White.name;

            const colorCombination = `A (${bootAColor}), B (${bootBColor})`;
            return colorCombination as BootColorCombination;   
        }
        return BootColorCombinations.WhiteWhite;
    }
)

export const currentAssemblyBootColorsSelector = createSelector(
    currentAssemblyBootColorCombinationSelector,
    (colorCombination) => {
        switch(colorCombination){
            case BootColorCombinations.WhiteWhite:
                return [White, White];
            case BootColorCombinations.WhiteBlue:
                return [White, Blue];
            case BootColorCombinations.WhiteBlack:
                return [White, Black];
        }
    }
)

export const currentAssemblyPolarityConfigurationsSelector = createSelector(
    polarityMapsSelector,
    currentAssemblyConnectorTypesSelector,
    (predefinedPolarityMaps, { sideAConnectorTypes, sideBConnectorTypes }) => {
        const polarityConfigurations: IPolarityConfigurationProps[] = [];
        for (const sideAConnectorType of sideAConnectorTypes) {
            const sideAFiberCount = getConnectorType(sideAConnectorType).fiberCount;
            for (const sideBConnectorType of sideBConnectorTypes) {
                const sideBFiberCount = getConnectorType(sideBConnectorType).fiberCount;
                const polarityMaps = predefinedPolarityMaps.filter(m => {
                    return m.from && m.from[0] === sideAFiberCount && m.to && m.to[0] === sideBFiberCount;
                });
                const label = i18next.t(LocalizationKeys.TypeToType, { from: getConnectorFamilyFromType(sideAConnectorType), to: getConnectorFamilyFromType(sideBConnectorType) });
                if (!polarityConfigurations.some(b => b.label === label)) {
                    polarityConfigurations.push({ label, polarityMaps });
                }
            }
        }
        return polarityConfigurations;
    }
)

export const currentAssemblyPolarityProgressSelector = createSelector(
    currentConnectorAssignmentsSelector,
    currentAssemblyFiberCountSelector,
    (assignments, fiberCount) => {
        const assignedFibers = assignments.flatMap(a => a.connectors.sideBMapping.flatMap(m => m.fiberCount - m.unassignedFibers)).reduce((a, b) => a + b, 0);
        const percentage = Math.min(100, (assignedFibers / fiberCount) * 100);
        return {
            percentage,
            percentageText: `${assignedFibers}/${fiberCount}`,
            exceeded: assignedFibers > fiberCount
        }
    }
)

const sideCSVOutputSelectorFactory = (side: Side) => {
    return createSelector(
        sideBreakoutsSelectorFactory(side),
        sideConnectorTypesSelectorFactory(side),
        (breakouts, connectors) => {
            let breakoutCount = breakouts.length;
            if (breakoutCount === 1 && breakouts[0].trunk.length.value === 0) {
                breakoutCount = 0;
            }
            const csvSideInfo: ICSVSideInfo = {
                breakouts: breakoutCount,
                legs: breakouts.map(breakout => breakout.furcation.groups.map(g => g.connectors.length).reduce((a, b) => a + b)) ?? [],
                stagger: breakouts.filter(breakout => breakout.furcation.groups.filter(g => g.length.value !== breakout.furcation.groups[0].length.value).length !== 0).length !== 0,
                uniqueConnectorTypes: connectors.length,
                connectors: connectors
            }
            const csvSideOutput = Object.entries(csvSideInfo).map((key) => {
                if (key[0] === "uniqueConnectorTypes") {
                    key[0] = "UNIQUE CONNECTORS";
                } else if (key[0] === "connectors") {
                    key[1] = key[1].map((c : string) => c.toString().replaceAll(",", ""));
                } else if (key[0] === "stagger") {
                    key[1] = key[1] ? "YES" : "NO";
                }
                return `\n${key.toString().toUpperCase()},`;
            })
            return csvSideOutput;
        }
    )
}

const sidesCSVOutputSelector = createSelector(
    sideCSVOutputSelectorFactory(Sides.SideA),
    sideCSVOutputSelectorFactory(Sides.SideB),
    (sideA, sideB) => {
        return { sideA, sideB }
    }
)

export const csvOutputSelector = createSelector(
    currentAssemblyFiberCountSelector,
    sidesCSVOutputSelector,
    (fiberCount, sidesCSVOutput) => {
        return [
            "FIBER COUNT,",
            `${fiberCount.toString()},`,
            "\nEND A,",
            ...sidesCSVOutput.sideA,
            "\nEND B,",
            ...sidesCSVOutput.sideB
        ]
    }
)

export const getBuildPlanSideRows = (side: Side, unit: string, endDesignation: string, pullingGrip: string, breakouts: IBreakout[]) => {
    const delimiter = { description: endDesignation, quantity: "", uom: "", section: true };
    const connectorRows = getBuildPlanConnectorRows(breakouts);
    const breakoutRows = getBuildPlanBreakoutRows(unit, breakouts);
    const pullingGripRow = getBuildPlanPullingGripRow(side, pullingGrip);

    return pullingGripRow 
        ? [delimiter, ...connectorRows, ...breakoutRows, pullingGripRow] 
        : [delimiter, ...connectorRows, ...breakoutRows];
}

export const getBuildPlanPullingGripRow = (side: Side, pullingGrip: string):  { description: string, quantity: number, uom: string } | undefined => {
    const row = { description: i18next.t(LocalizationKeys.PullingGrip), quantity: 1, uom: "PCE" };
    if (side === "SideA") {
        if (pullingGrip === PullingGrips.EndA || pullingGrip === PullingGrips.BothEnds) {
            return row;
        }
    } else if (pullingGrip === PullingGrips.EndB || pullingGrip === PullingGrips.BothEnds) {
        return row;
    }
};

export const getBuildPlanConnectorRows = (breakouts: IBreakout[]) => {
    const mapping: { [description: string] : number } = {};
    const connectors = breakouts.flatMap(b => b.furcation.groups.flatMap(g => g.connectors));
    for (const { type } of connectors) {
        if (mapping[type]) {
            mapping[type] = mapping[type] + 1;
        } else {
            mapping[type] = 1;
        }
    }

    return Object.keys(mapping).map(m => ({ description: m, quantity: `${mapping[m]}`, uom: "PCE" }));
};

export const getBuildPlanBreakoutRows = (unit: string, breakouts: IBreakout[]) => {
    const mapping: { [breakout: string]: { [description: string]: number } } = {};
    for (const { trunk, furcation, position: breakoutPosition, side } of breakouts) {
        const position = `${side === "SideA" ? "A" : "B"}${breakoutPosition}`
        const length = Math.round(convertTo(trunk.length, unit).value * 10) / 10;
        const breakout = `${i18next.t(LocalizationKeys.Breakout)} ${position}: ${length} ${unit}`;

        mapping[breakout] = {};

        const connectors = furcation.groups.flatMap(g => g.connectors);
        for (const connector of connectors) {
            if (connector.length) {
                const legLength = Math.round(convertTo(connector.length, unit).value * 10) / 10;
                const leg = `${legLength} ${unit}`;
                if (mapping[breakout][leg]) {
                    mapping[breakout][leg] = mapping[breakout][leg] + 1;
                } else {
                    mapping[breakout][leg] = 1;
                }
            }
        }
    }

    return Object.keys(mapping).flatMap(breakoutKey => {
        const legs = Object.keys(mapping[breakoutKey])
                           .map(legKey => ({ description: `\u00A0\u00A0\u00A0\u00A0\u2022 ${legKey}`, quantity: `${mapping[breakoutKey][legKey]}`, uom: "PCE" }));
        const breakout = { description: breakoutKey, quantity: "", uom: "" };
        return [breakout, ...legs];
    });

};