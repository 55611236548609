import { ButtonProps } from "@orbit/button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { useStoreDispatch } from "../../../../store/hooks";
import { Shapes } from "../../../assembly/types";
import { useAssemblyManager } from "../../../store/hooks";
import { showAssemblyWizard } from "../../../store/reducer";
import { undoBreakoutSet } from "../breakout-step/reducer";
import { CableSetupContext, moveNextStep, movePreviousStep } from "../reducer";

export const useSetupFooter = () => {
    const { state, dispatch } = useContext(CableSetupContext);
    const { saveAssembly } = useAssemblyManager();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const enableUndo = state.breakoutSetup.breakoutSetups[state.breakoutSetup.setupIndex].sets.length > 0;
    const enableApply = state.wizardSetup.enableApply;
    const displayPrevious = state.wizardSetup.stepIndex > 0;
    const displayFinish = state.wizardSetup.stepIndex === state.wizardSetup.stepCount - 1;

    let previousLabel = "";
    let nextLabel = ""
    switch (state.wizardSetup.stepIndex) {
        case 0:
            nextLabel = state.generalSetup.shape === Shapes.Asymmetrical ? t(LocalizationKeys.SideA) : t(LocalizationKeys.Continue)
            break;
        case 1:
            nextLabel = t(LocalizationKeys.SideB)
            previousLabel = t(LocalizationKeys.General)
            break;
        case 2:
            previousLabel = t(LocalizationKeys.SideA)
            break;
        default:
            break;
    }

    const moveNext = useCallback(() => {
        if (dispatch) {
            dispatch(moveNextStep)
        }
    }, [dispatch]);

    const moveBack = useCallback(() => {
        if (dispatch) {
            dispatch(movePreviousStep)
        }
    }, [dispatch]);

    const onUndoClick = useCallback(() => {
        if (dispatch) {
            dispatch(undoBreakoutSet())
        }
    }, [dispatch]);

    const closeSetup = useCallback(async () => {
        if (dispatch) {
            await saveAssembly();
            storeDispatch(showAssemblyWizard(false));
        }
    }, [dispatch, saveAssembly, storeDispatch]);

    const previousButtonProps: ButtonProps = {
        className: "previous-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: moveBack
    };

    const previous = {
        display: displayPrevious,
        buttonProps: previousButtonProps,
        label: previousLabel
    };

    const undoButtonProps: ButtonProps = {
        className: "undo-button",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        disabled: !enableUndo,
        onClick: onUndoClick
    };

    const undo = {
        buttonProps: undoButtonProps,
        label: t(LocalizationKeys.Undo)
    };

    const finishButtonProps: ButtonProps = {
        className: "finish-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: !enableApply,
        onClick: closeSetup
    };

    const finish = {
        display: displayFinish,
        buttonProps: finishButtonProps,
        label: t(LocalizationKeys.Finish)
    };

    const nextButtonProps: ButtonProps = {
        className: "next-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: !enableApply,
        onClick: moveNext
    };

    const next = {
        buttonProps: nextButtonProps,
        label: nextLabel
    }

    return {
        previous,
        undo,
        finish,
        next
    }
}