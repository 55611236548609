import { createSlice } from "@reduxjs/toolkit";
import { setApplyAllAction, setBreakoutJacketColorAction, setBreakoutLabelAction, setBreakoutLengthAction, setLegConnectorTypeAction, setLegJacketColorAction } from "./actions";
import { initialBreakoutState } from "./types";

const breakoutSlice = createSlice({
    name: "breakout",
    initialState: initialBreakoutState,
    reducers: {
        setBreakoutJacketColor: setBreakoutJacketColorAction,
        setBreakoutLength: setBreakoutLengthAction,
        setBreakoutLabel: setBreakoutLabelAction,
        setLegJacketColor: setLegJacketColorAction,
        setLegConnectorType: setLegConnectorTypeAction,
        setApplyAll: setApplyAllAction
    }
})

export const BreakoutReducer = breakoutSlice.reducer;
export const { setBreakoutJacketColor, setBreakoutLength, setBreakoutLabel, setLegJacketColor, setLegConnectorType, setApplyAll } = breakoutSlice.actions;