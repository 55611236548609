import { PayloadAction } from "@reduxjs/toolkit";
import { ICablePosition, SetBreakoutPositionActionArgs, SidePositions } from "./types";


export const setBreakoutPositionAction = (state: ICablePosition, action: PayloadAction<SetBreakoutPositionActionArgs>) => {
    const { side, breakout, breakouts } = action.payload;
    if (state.sides[side]) {
        const breakouts = [...state.sides[side]]
        const breakoutPosition = breakouts.find(s => s.position === breakout.position)
        if (breakoutPosition) {
            breakoutPosition.breakoutBox = breakout.breakoutBox
            breakoutPosition.trunkBox = breakout.trunkBox
            state.sides[side] = breakouts;

        }
        else {
            state.sides[side] = [...breakouts, breakout].sort((a, b) => a.position > b.position ? 1 : -1)
        }
    }
    else {
        state.sides[side] = [breakout]
    }

    if (state.sides[side]) {
        for (let i = state.sides[side].length - 1; i > 0; i--) {
            const breakout = state.sides[side][i];
            if (!breakouts.find(b => b.position === breakout.position)) {
                state.sides[side].splice(i, 1)
            }

        }
    }
}

export const setRelativeIndicesAction = (state: ICablePosition, action: PayloadAction<SidePositions>) => {
    state.sidePositions = action.payload;
}