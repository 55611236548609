import React from "react";
import { useIdleDialog } from "./hooks"
import { Dialog } from "@orbit/dialog";

export const IdleDialog : React.FC = () => {
    const { dialogProps } = useIdleDialog();
    if (!dialogProps.open) {
        return null;
    }
    return <Dialog {...dialogProps} />
};