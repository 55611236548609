import { useEffect, useMemo, useState, useContext } from "react";
import { IBreakout, IConnectorGroup, Sides } from "../../../workspace/assembly/breakout/types";
import { IAssemblySide } from "../../../workspace/assembly/types";
import { getConnectorSprite } from "../breakout/connector-furcation/connector-sprite/types";
import { BreakoutProps, IBreakoutConnector } from "../breakout/types";
import { cableAnchorPoint } from "../cable-base/hooks";
import { CablePositionContext } from "../position/types";
import { initialSideProps, ISideProps, SideProps } from "./types";

const trunkHeight = 200;
export const breakoutTrunkWidth = 125;

export const useSideContext = (props: SideProps = initialSideProps) => {
    const mx = useSymmetryContext(props)
    const { side, unit, flipsideConnectorType } = props;
    const [breakouts, setBreakouts] = useState(props.breakouts || []);
    const { state: cablePositionState } = useContext(CablePositionContext)

    useEffect(() => {
        const breakouts = props.breakouts;
        if (breakouts) {
            setBreakouts(breakouts)
        }
    }, [props.breakouts])

    const breakoutsProps: BreakoutProps[] = useMemo(() => {
        const sizes = breakouts.map(b => {
            return { width: breakoutTrunkWidth, height: getBreakoutHeight(b) + breakoutSpacing + (b.furcation.groups.length) * connectorSpacing };
        });

        const totalHeight = sizes.length > 1 ? sizes.map(s => s.height).reduce((a, b) => a + b) : sizes[0].height;
        const midHeight = totalHeight * 0.5;
        let heightPosition = cableAnchorPoint.y - midHeight;
        let breakoutProps: BreakoutProps[] = [];
        for (let i = 0; i < breakouts.length; i++) {
            const breakout = breakouts[i];
            const size = sizes[i];
            if (i > 0) {
                heightPosition = heightPosition + sizes[i - 1].height;
            }

            const x = cableAnchorPoint.x;
            const y = cableAnchorPoint.y;
            const height = heightPosition + sizes[i].height * 0.5;

            let offset = 0;
            const positionIndices = cablePositionState.sidePositions[side];
            if (side && positionIndices && positionIndices[breakout.trunk.length.value]) {
                offset = positionIndices[breakout.trunk.length.value].positionOffset;
            }

            // local trunk position in breakout
            const trunkFurcationOffset = breakouts.length > 1 ? sizes[i].height / 3.5 : sizes[i].height / 4.5;
            const trunkFurcationY = -height + trunkFurcationOffset;
            const trunkFurcation = { x: 0, y: trunkFurcationY };

            const connectors: IBreakoutConnector[] = [];
            const uniqueLegLengthsPairs: { [legLength: number]: number } = {};
            const uniqueGroupLengthsPairs: { [groupLength: number]: number } = {};
            const groupsUniqueLegLengths: { groupPosition: number, groupOffset: number, uniqueLegLengths: number[], hasGap: boolean }[] = [];
            for (let i = 0; i < breakout.furcation.groups.length; i++) {
                const group = breakout.furcation.groups[i];
                const groupUniqueLegLengthPairs: { [length: number]: number } = {};
                let groupOffset = 0;
                let hasGap = false;
                for (const connector of group.connectors) {
                    const legLength = connector.length ?? group.length; // Legacy connectors might not have its own personal length
                    const totalLength = { ...legLength, value: legLength.value + breakout.trunk.length.value };
                    
                    if (!uniqueLegLengthsPairs[totalLength.value]) { // Keeps tracks of the unique leg lengths for the breakout
                        uniqueLegLengthsPairs[totalLength.value] = totalLength.value;
                    }

                    if (!groupUniqueLegLengthPairs[totalLength.value]) { // Keeps track of the unique leg lengths within a group
                        groupUniqueLegLengthPairs[totalLength.value] = totalLength.value;
                    }

                    connectors.push({ 
                        groupPosition: group.position, 
                        groupLength: group.length,
                        connector: { ...connector, length: totalLength },
                        isLastGroupConnector: connector.position === group.connectors[group.connectors.length - 1].position
                    });
                }
                
                if (group.offset) {
                    groupOffset = group.offset.value;
                    if (!uniqueGroupLengthsPairs[group.length.value]) {
                        uniqueGroupLengthsPairs[group.length.value] = group.length.value;
                    }
                }

                const groupUniqueLegLengths = Object.values(groupUniqueLegLengthPairs).sort((a, b) => a - b);
                const uniqueGroupLengths = Object.values(uniqueGroupLengthsPairs);
                if (group.position > 1 && uniqueGroupLengths.length > 1) {
                    const previousGroup = groupsUniqueLegLengths.find(g => g.groupPosition === group.position - 1);
                    if (previousGroup) {
                        const previousGroupLastUniqueLength = previousGroup.uniqueLegLengths[previousGroup.uniqueLegLengths.length - 1];
                        if (groupUniqueLegLengths.every(l => l > previousGroupLastUniqueLength)) {
                            hasGap = true;
                        }
                    }
                }
                groupsUniqueLegLengths.push({
                    groupPosition: group.position,
                    groupOffset,
                    uniqueLegLengths: groupUniqueLegLengths,
                    hasGap
                });
            }

            breakoutProps.push({
                ...breakout,
                width: size.width,
                height,
                x,
                y,
                offset,
                side,
                trunkFurcation,
                connectors,
                trunkLength: breakout.trunk.length.value, 
                uniqueLegLengths: Object.values(uniqueLegLengthsPairs).sort((a, b) => a - b),
                groupsUniqueLegLengths,
                uniqueGroupLengths: Object.values(uniqueGroupLengthsPairs).sort((a, b) => a - b)
            });
        }

        const halfLength = Math.floor(breakoutProps.length * 0.5);
        const firstHalf = breakoutProps.slice(0, halfLength);
        const secondHalf = [...breakoutProps.slice(halfLength, breakoutProps.length)].reverse();
        
        breakoutProps = [...firstHalf, ...secondHalf];

        return breakoutProps;
    }, [side, breakouts, cablePositionState]);

    const hasGroupOffsets = breakoutsProps.flatMap(b => b.groupsUniqueLegLengths).map(g => g.groupOffset).some(o => o > 0);
    const maxUniqueLength = Math.max(...breakoutsProps.flatMap(b => b.uniqueLegLengths));

    return { m: mx, breakouts, breakoutsProps, side, unit, hasGroupOffsets, maxUniqueLength, flipsideConnectorType }
}

export const useSymmetryContext = ({ side }: ISideProps) => {
    let mx: 1 | -1 = side === Sides.SideA ? -1 : 1;

    return mx;
}

export function getSideHeight({ breakouts }: IAssemblySide): number {
    const breakoutHeights = breakouts.map(b => getBreakoutHeight(b) + breakoutSpacing)
    const sideHeight = breakoutHeights.length > 1 ? breakoutHeights.reduce((a, b) => a + b) : breakoutHeights[0]
    return sideHeight - breakoutSpacing
}

export const connectorSpacing = 5;
export const breakoutSpacing = 50;

export function getBreakoutHeight(breakout: IBreakout): number {
    let height = 0;
    if (breakout.position) {
        height = trunkHeight;

        const connectors = breakout.furcation.groups.flatMap(g => g.connectors);
        if (connectors.length > 1) {
            height = connectors.map(c => getConnectorHeight(c.type)).reduce((a, b) => a + b);
        } else if (connectors.length) {
            height = getConnectorHeight(connectors[0].type)
        }
    }
    return (height * 0.5);
}

export function getGroupHeight(group: IConnectorGroup) {
    const connectors = group.connectors;
    return connectors.length > 1
        ? (connectors.map(c => getConnectorHeight(c.type) + connectorSpacing / 2).reduce((a, b) => a + b) / 2)
        : (getConnectorHeight(connectors[0].type) + connectorSpacing / 2) / 2
}

export function getConnectorHeight(connectorType: string): number {
    const sprite = getConnectorSprite(connectorType)
    return sprite.width + connectorSpacing// connectors are oriented left/right 
}

