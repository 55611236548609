import { createContext, Dispatch } from "react";
import { AnyAction } from "redux";
import { Side, Sides } from "../../../assembly/breakout/types";
import { IPinAssignmentMap, IPinMap } from "../types";

export interface INavigationMap {
    nbIndex: number;
    currentIndex: number;
}

export interface ISideNavigation {
    side: Side;
    breakout: INavigationMap;
    connector: INavigationMap;
}

export interface IFiberMappingNavigation {
    sideA: ISideNavigation;
    sideB: ISideNavigation;
    selectedPinIndex: number;
}

export interface IFiberMappingState {
    navigation: IFiberMappingNavigation;
    assignments: IPinAssignmentMap[];
    unused: IPinMap[];
    isModified: boolean;
}

export const initialNavigationMap: INavigationMap = {
    nbIndex: 0,
    currentIndex: -1
}

export const initialFiberMappingNavigation: IFiberMappingNavigation = {
    sideA: {
        side: Sides.SideA,
        breakout: initialNavigationMap,
        connector: initialNavigationMap
    },
    sideB: {
        side: Sides.SideB,
        breakout: initialNavigationMap,
        connector: initialNavigationMap
    },
    selectedPinIndex: -1
}

export const initialFiberMappingState: IFiberMappingState = {
    navigation: initialFiberMappingNavigation,
    assignments: [],
    unused: [],
    isModified: false
}

export interface IFiberMappingContext {
    state: IFiberMappingState;
    dispatch: Dispatch<AnyAction>;
}

export const initialFiberMappingContext: IFiberMappingContext = {
    state: initialFiberMappingState,
    dispatch: () => {}
}

export const FiberMappingContext = createContext(initialFiberMappingContext);