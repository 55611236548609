import { Dispatch } from "react"
import { AnyAction } from "redux"
import { IAssembly } from "../../assembly/types"
import { initialSymmetricBreakoutStep } from "./breakout-step/types"
import { initialGeneralSetupState } from "./general-setup/types"
import { CableSetupState } from "./reducer"

export interface WizardSetupState {
    enableContinue: boolean,
    enableApply: boolean,
    enableReset: boolean,
    stepCount: number,
    stepIndex: number,
    hidden: boolean,
    prevAssembly?: IAssembly
}

export const initialWizardSetupState: WizardSetupState = {
    enableApply: true,
    enableContinue: true,
    enableReset: false,
    stepCount: 2,
    stepIndex: 0,
    hidden: false
}

export const initialCableSetupState: CableSetupState = { 
    generalSetup: initialGeneralSetupState,
    breakoutSetup: initialSymmetricBreakoutStep,
    wizardSetup: initialWizardSetupState
}

export interface ICableSetupContext {
    state: CableSetupState,
    dispatch?: Dispatch<AnyAction>
}

export const initialCableSetupContext = {
    state: initialCableSetupState
}