import { DrawerHeaderProps } from "./types";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";

export const useDrawerHeader = ({ logo, onClose }: DrawerHeaderProps) => {
    const closeIconProps = {
        className: "close-drawer",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        onClick: onClose
    };

    return { logo, closeIconProps };
}