import { createSlice } from "@reduxjs/toolkit";
import { setSizeAction } from "./actions";
import { initialContainerBox } from "./bounding-box/types";

export const interactionSlice = createSlice({
    name: "interaction",
    initialState: initialContainerBox,
    reducers: {
        setSize: setSizeAction
    }
})


export const InteractionReducer = interactionSlice.reducer;
export const { setSize } = interactionSlice.actions;