import { useSelector } from "react-redux"
import { displayConverterSelector, unitSelector } from "../../../assembly/length/store/selectors";
import { useTranslation } from "react-i18next";
import { useCallback } from "react"
import { localizeUnit } from "../../../../localization/types";
import { isMetric } from "../../../assembly/length/types";
import { displayUnitConverter } from "../../../assembly/length/store/reducer";
import { useStoreDispatch } from "../../../../store/hooks";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { showAssemblyWizardSelector } from "../../../store/selectors";

export const useUnitAction = () => {
    const display = useSelector(displayConverterSelector);
    const unit = useSelector(unitSelector);
    const disabled = useSelector(disabledOverlaySelector);
    const showWizard = useSelector(showAssemblyWizardSelector);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        storeDispatch(displayUnitConverter(!display))
    }, [storeDispatch, display]);

    const isMetricUnit = isMetric(unit.unit);
    const unitProps = {
        className: "unit-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(localizeUnit(unit.unit)),
        placement: "bottom" as TooltipPlacement,
        disabled: (disabled && !showWizard),
        onClick
    };

    return { unitProps, isMetricUnit };
}