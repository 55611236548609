import React from 'react';
import { getConnectorTextColor, Yellow } from '../../../../../ui/dialog/color/types';

export const SCDuplex: React.FC = () => {
    return (
        <svg id="SC_UPC_Duplex_Connector_global_" data-name="SC UPC Duplex Connector (global)" xmlns="http://www.w3.org/2000/svg" width="250" height="950.07" viewBox="0 0 25 95.007">
            <g id="SC_Connector_Cable_link" data-name="SC Connector Cable link" transform="translate(4.503)">
                <g id="Group_1442" data-name="Group 1442" transform="translate(-1025.48 214.076) rotate(-90)">
                    <path id="Path_476" data-name="Path 476" d="M2523.076,821.48c7.993.02,12.4,3.727,20,4,.053.057,20,0,20,0v4h-20c-7.867,0-13.207-3.828-20-4C2523.05,824.591,2523.065,822.9,2523.076,821.48Z" transform="translate(-2349 204)" fill="#4c4649"/>
                    <path id="Path_477" data-name="Path 477" d="M2523.076,822.48c9.225.261,12.116,3.846,19.993,4l20.007,0v2h-20c-8.475-.159-11.236-3.658-20-4C2523.052,823.954,2523.053,823.5,2523.076,822.48Z" transform="translate(-2349 204)" fill="#fce43b"/>
                </g>
                <g id="Group_1443" data-name="Group 1443" transform="translate(7.996 40.007) rotate(-90)">
                    <path id="Path_476-2" data-name="Path 476" d="M2523.076,829.216c7.993-.02,12.4-3.727,20-4,1.284.007,20,0,20,0v-4s-18.976-.01-20.018,0c-7.867,0-13.189,3.832-19.982,4C2523.05,826.105,2523.065,827.795,2523.076,829.216Z" transform="translate(-2523.069 -821.212)" fill="#4c4649"/>
                    <path id="Path_477-2" data-name="Path 477" d="M2523.076,828.188c9.225-.261,12.116-3.853,19.993-4.01l20.007.01v-2s-19.3,0-20.007.014c-8.475.159-11.23,3.644-19.993,3.986C2523.052,826.714,2523.053,827.168,2523.076,828.188Z" transform="translate(-2523.069 -821.184)" fill="#fce43b"/>
                </g>
            </g>
            <rect id="Rectangle_1161" data-name="Rectangle 1161" width="2" height="5" transform="translate(13 83.007)" fill="none"/>
            <rect id="Rectangle_1165" data-name="Rectangle 1165" width="2" height="5" transform="translate(0 83.007)" fill="none"/>
            <g id="Path_194" data-name="Path 194" transform="translate(-1183.526 -1536.993)" fill="#f1f1f2">
                <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
                <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <g id="Path_441" data-name="Path 441" transform="translate(-1196.526 -1536.993)" fill="#f1f1f2">
                <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
                <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <rect id="Rectangle_474" data-name="Rectangle 474" width="2" height="3" transform="translate(18 92.007)" fill="#c4c4c4"/>
            <rect id="Rectangle_1166" data-name="Rectangle 1166" width="2" height="3" transform="translate(5 92.007)" fill="#c4c4c4"/>
            <g id="Rectangle_1135" data-name="Rectangle 1135" transform="translate(14 66.007)" fill="#045eaa" stroke="#484646" stroke-width="1">
                <rect width="10" height="26" stroke="none"/>
                <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
            </g>
            <g id="Rectangle_1162" data-name="Rectangle 1162" transform="translate(1 66.007)" fill="#045eaa" stroke="#484646" stroke-width="1">
                <rect width="10" height="26" stroke="none"/>
                <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
            </g>
            <rect id="Rectangle_621" data-name="Rectangle 621" width="2" height="1" transform="translate(20 43.007)" fill="#484646"/>
            <rect id="Rectangle_1180" data-name="Rectangle 1180" width="2" height="1" transform="translate(7 43.007)" fill="#484646"/>
            <rect id="Rectangle_1156" data-name="Rectangle 1156" width="2" height="1" transform="translate(20 47.007)" fill="#484646"/>
            <rect id="Rectangle_1177" data-name="Rectangle 1177" width="2" height="1" transform="translate(7 47.007)" fill="#484646"/>
            <rect id="Rectangle_1159" data-name="Rectangle 1159" width="2" height="1" transform="translate(20 51.007)" fill="#484646"/>
            <rect id="Rectangle_1174" data-name="Rectangle 1174" width="2" height="1" transform="translate(7 51.007)" fill="#484646"/>
            <rect id="Rectangle_1154" data-name="Rectangle 1154" width="2" height="1" transform="translate(16 43.007)" fill="#484646"/>
            <rect id="Rectangle_1181" data-name="Rectangle 1181" width="2" height="1" transform="translate(3 43.007)" fill="#484646"/>
            <rect id="Rectangle_1155" data-name="Rectangle 1155" width="2" height="1" transform="translate(16 47.007)" fill="#484646"/>
            <rect id="Rectangle_1178" data-name="Rectangle 1178" width="2" height="1" transform="translate(3 47.007)" fill="#484646"/>
            <rect id="Rectangle_1158" data-name="Rectangle 1158" width="2" height="1" transform="translate(16 51.007)" fill="#484646"/>
            <rect id="Rectangle_1175" data-name="Rectangle 1175" width="2" height="1" transform="translate(3 51.007)" fill="#484646"/>
            <rect id="Rectangle_622" data-name="Rectangle 622" width="4" height="1" transform="translate(17 41.007)" fill="#484646"/>
            <rect id="Rectangle_1182" data-name="Rectangle 1182" width="4" height="1" transform="translate(4 41.007)" fill="#484646"/>
            <rect id="Rectangle_743" data-name="Rectangle 743" width="4" height="1" transform="translate(17 45.007)" fill="#484646"/>
            <rect id="Rectangle_1179" data-name="Rectangle 1179" width="4" height="1" transform="translate(4 45.007)" fill="#484646"/>
            <rect id="Rectangle_1157" data-name="Rectangle 1157" width="4" height="1" transform="translate(17 49.007)" fill="#484646"/>
            <rect id="Rectangle_1176" data-name="Rectangle 1176" width="4" height="1" transform="translate(4 49.007)" fill="#484646"/>
            <rect id="Rectangle_1160" data-name="Rectangle 1160" width="4" height="1" transform="translate(17 53.007)" fill="#484646"/>
            <rect id="Rectangle_1173" data-name="Rectangle 1173" width="4" height="1" transform="translate(4 53.007)" fill="#484646"/>
            <g id="Rectangle_738" data-name="Rectangle 738" transform="translate(17 84.007)" fill="none" stroke="#484646" stroke-width="1">
                <rect width="4" height="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="4" fill="none"/>
            </g>
            <g id="Rectangle_1164" data-name="Rectangle 1164" transform="translate(4 84.007)" fill="none" stroke="#484646" stroke-width="1">
                <rect width="4" height="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="4" fill="none"/>
            </g>
            <g id="Rectangle_1172" data-name="Rectangle 1172" transform="translate(0 67.007)" fill="#045eaa" stroke="#484646" stroke-width="1">
                <rect width="25" height="13" stroke="none"/>
                <rect x="0.5" y="0.5" width="24" height="12" fill="none"/>
            </g>
            <rect id="Rectangle_623" data-name="Rectangle 623" width="21" height="1" transform="translate(2 69.007)" fill="#484646"/>
            <rect id="Rectangle_1150" data-name="Rectangle 1150" width="21" height="1" transform="translate(2 71.007)" fill="#484646"/>
            <rect id="Rectangle_1151" data-name="Rectangle 1151" width="9" height="1" transform="translate(8 73.007)" fill="#484646"/>
            <rect id="Rectangle_1188" data-name="Rectangle 1188" width="9" height="1" transform="translate(8 75.007)" fill="#484646"/>
            <rect id="Rectangle_1153" data-name="Rectangle 1153" width="2" height="1" transform="translate(2 73.007)" fill="#484646"/>
            <rect id="Rectangle_1187" data-name="Rectangle 1187" width="2" height="1" transform="translate(2 75.007)" fill="#484646"/>
            <rect id="Rectangle_1185" data-name="Rectangle 1185" width="2" height="1" transform="translate(21 73.007)" fill="#484646"/>
            <rect id="Rectangle_1186" data-name="Rectangle 1186" width="2" height="1" transform="translate(21 75.007)" fill="#484646"/>
            <rect id="Rectangle_1184" data-name="Rectangle 1184" width="21" height="1" transform="translate(2 77.007)" fill="#484646"/>
            <text id="B" transform="translate(20 73.007) rotate(180)" fill="#fff" font-size="3" font-family="ArialMT, Arial">
                <tspan x="0" y="0">B</tspan>
            </text>
            <text id="A" transform="translate(7 73.007) rotate(180)" fill="#fff" font-size="3" font-family="ArialMT, Arial">
                <tspan x="0" y="0">A</tspan>
            </text>
        </svg>
    );
}

export const getSCDuplexSVGString = (color: string, jacketColor?: string, colorA?: string, colorB?: string) => {
    const textColor = getConnectorTextColor(color);
    const trunkColor = jacketColor ?? Yellow.hex;
    const bootAColor = colorA ?? "#f1f1f2";
    const bootBColor = colorB ?? "#f1f1f2";
    return `
        <svg id="SC_UPC_Duplex_Connector_global_" data-name="SC UPC Duplex Connector (global)" xmlns="http://www.w3.org/2000/svg" width="250" height="950.07" viewBox="0 0 25 95.007">
            <g id="SC_Connector_Cable_link" data-name="SC Connector Cable link" transform="translate(4.503)">
                <g id="Group_1442" data-name="Group 1442" transform="translate(-1025.48 214.076) rotate(-90)">
                    <path id="Path_476" data-name="Path 476" d="M2523.076,821.48c7.993.02,12.4,3.727,20,4,.053.057,20,0,20,0v4h-20c-7.867,0-13.207-3.828-20-4C2523.05,824.591,2523.065,822.9,2523.076,821.48Z" transform="translate(-2349 204)" fill="#4c4649"/>
                    <path id="Path_477" data-name="Path 477" d="M2523.076,822.48c9.225.261,12.116,3.846,19.993,4l20.007,0v2h-20c-8.475-.159-11.236-3.658-20-4C2523.052,823.954,2523.053,823.5,2523.076,822.48Z" transform="translate(-2349 204)" fill="${trunkColor}"/>
                </g>
                <g id="Group_1443" data-name="Group 1443" transform="translate(7.996 40.007) rotate(-90)">
                    <path id="Path_476-2" data-name="Path 476" d="M2523.076,829.216c7.993-.02,12.4-3.727,20-4,1.284.007,20,0,20,0v-4s-18.976-.01-20.018,0c-7.867,0-13.189,3.832-19.982,4C2523.05,826.105,2523.065,827.795,2523.076,829.216Z" transform="translate(-2523.069 -821.212)" fill="#4c4649"/>
                    <path id="Path_477-2" data-name="Path 477" d="M2523.076,828.188c9.225-.261,12.116-3.853,19.993-4.01l20.007.01v-2s-19.3,0-20.007.014c-8.475.159-11.23,3.644-19.993,3.986C2523.052,826.714,2523.053,827.168,2523.076,828.188Z" transform="translate(-2523.069 -821.184)" fill="${trunkColor}"/>
                </g>
            </g>
            <rect id="Rectangle_1161" data-name="Rectangle 1161" width="2" height="5" transform="translate(13 83.007)" fill="none"/>
            <rect id="Rectangle_1165" data-name="Rectangle 1165" width="2" height="5" transform="translate(0 83.007)" fill="none"/>
            <g id="Path_194" data-name="Path 194" transform="translate(-1183.526 -1536.993)" fill="${bootBColor}">
                <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
                <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <g id="Path_441" data-name="Path 441" transform="translate(-1196.526 -1536.993)" fill="${bootAColor}">
                <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
                <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <rect id="Rectangle_474" data-name="Rectangle 474" width="2" height="3" transform="translate(18 92.007)" fill="#c4c4c4"/>
            <rect id="Rectangle_1166" data-name="Rectangle 1166" width="2" height="3" transform="translate(5 92.007)" fill="#c4c4c4"/>
            <g id="Rectangle_1135" data-name="Rectangle 1135" transform="translate(14 66.007)" fill="${color}" stroke="#484646" stroke-width="1">
                <rect width="10" height="26" stroke="none"/>
                <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
            </g>
            <g id="Rectangle_1162" data-name="Rectangle 1162" transform="translate(1 66.007)" fill="${color}" stroke="#484646" stroke-width="1">
                <rect width="10" height="26" stroke="none"/>
                <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
            </g>
            <rect id="Rectangle_621" data-name="Rectangle 621" width="2" height="1" transform="translate(20 43.007)" fill="#484646"/>
            <rect id="Rectangle_1180" data-name="Rectangle 1180" width="2" height="1" transform="translate(7 43.007)" fill="#484646"/>
            <rect id="Rectangle_1156" data-name="Rectangle 1156" width="2" height="1" transform="translate(20 47.007)" fill="#484646"/>
            <rect id="Rectangle_1177" data-name="Rectangle 1177" width="2" height="1" transform="translate(7 47.007)" fill="#484646"/>
            <rect id="Rectangle_1159" data-name="Rectangle 1159" width="2" height="1" transform="translate(20 51.007)" fill="#484646"/>
            <rect id="Rectangle_1174" data-name="Rectangle 1174" width="2" height="1" transform="translate(7 51.007)" fill="#484646"/>
            <rect id="Rectangle_1154" data-name="Rectangle 1154" width="2" height="1" transform="translate(16 43.007)" fill="#484646"/>
            <rect id="Rectangle_1181" data-name="Rectangle 1181" width="2" height="1" transform="translate(3 43.007)" fill="#484646"/>
            <rect id="Rectangle_1155" data-name="Rectangle 1155" width="2" height="1" transform="translate(16 47.007)" fill="#484646"/>
            <rect id="Rectangle_1178" data-name="Rectangle 1178" width="2" height="1" transform="translate(3 47.007)" fill="#484646"/>
            <rect id="Rectangle_1158" data-name="Rectangle 1158" width="2" height="1" transform="translate(16 51.007)" fill="#484646"/>
            <rect id="Rectangle_1175" data-name="Rectangle 1175" width="2" height="1" transform="translate(3 51.007)" fill="#484646"/>
            <rect id="Rectangle_622" data-name="Rectangle 622" width="4" height="1" transform="translate(17 41.007)" fill="#484646"/>
            <rect id="Rectangle_1182" data-name="Rectangle 1182" width="4" height="1" transform="translate(4 41.007)" fill="#484646"/>
            <rect id="Rectangle_743" data-name="Rectangle 743" width="4" height="1" transform="translate(17 45.007)" fill="#484646"/>
            <rect id="Rectangle_1179" data-name="Rectangle 1179" width="4" height="1" transform="translate(4 45.007)" fill="#484646"/>
            <rect id="Rectangle_1157" data-name="Rectangle 1157" width="4" height="1" transform="translate(17 49.007)" fill="#484646"/>
            <rect id="Rectangle_1176" data-name="Rectangle 1176" width="4" height="1" transform="translate(4 49.007)" fill="#484646"/>
            <rect id="Rectangle_1160" data-name="Rectangle 1160" width="4" height="1" transform="translate(17 53.007)" fill="#484646"/>
            <rect id="Rectangle_1173" data-name="Rectangle 1173" width="4" height="1" transform="translate(4 53.007)" fill="#484646"/>
            <g id="Rectangle_738" data-name="Rectangle 738" transform="translate(17 84.007)" fill="none" stroke="#484646" stroke-width="1">
                <rect width="4" height="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="4" fill="none"/>
            </g>
            <g id="Rectangle_1164" data-name="Rectangle 1164" transform="translate(4 84.007)" fill="none" stroke="#484646" stroke-width="1">
                <rect width="4" height="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="4" fill="none"/>
            </g>
            <g id="Rectangle_1172" data-name="Rectangle 1172" transform="translate(0 67.007)" fill="${color}" stroke="#484646" stroke-width="1">
                <rect width="25" height="13" stroke="none"/>
                <rect x="0.5" y="0.5" width="24" height="12" fill="none"/>
            </g>
            <rect id="Rectangle_623" data-name="Rectangle 623" width="21" height="1" transform="translate(2 69.007)" fill="#484646"/>
            <rect id="Rectangle_1150" data-name="Rectangle 1150" width="21" height="1" transform="translate(2 71.007)" fill="#484646"/>
            <rect id="Rectangle_1151" data-name="Rectangle 1151" width="9" height="1" transform="translate(8 73.007)" fill="#484646"/>
            <rect id="Rectangle_1188" data-name="Rectangle 1188" width="9" height="1" transform="translate(8 75.007)" fill="#484646"/>
            <rect id="Rectangle_1153" data-name="Rectangle 1153" width="2" height="1" transform="translate(2 73.007)" fill="#484646"/>
            <rect id="Rectangle_1187" data-name="Rectangle 1187" width="2" height="1" transform="translate(2 75.007)" fill="#484646"/>
            <rect id="Rectangle_1185" data-name="Rectangle 1185" width="2" height="1" transform="translate(21 73.007)" fill="#484646"/>
            <rect id="Rectangle_1186" data-name="Rectangle 1186" width="2" height="1" transform="translate(21 75.007)" fill="#484646"/>
            <rect id="Rectangle_1184" data-name="Rectangle 1184" width="21" height="1" transform="translate(2 77.007)" fill="#484646"/>
            <text id="B" transform="translate(20 73.007) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                <tspan x="0" y="0">B</tspan>
            </text>
            <text id="A" transform="translate(7 73.007) rotate(180)" fill="${textColor}" font-size="3" font-family="ArialMT, Arial">
                <tspan x="0" y="0">A</tspan>
            </text>
        </svg>
    `
}