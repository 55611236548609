import { createSlice } from "@reduxjs/toolkit";
import { incrementViewportScaleAction, setViewportFitOptionAction, setViewportScaleAction, setViewportBreakoutSelectionAction, setIsDraggingAction } from "./actions";
import { initialViewportState } from "./types";

const viewportBridgeSlice = createSlice({
    name: "viewport-bridge",
    initialState: initialViewportState,
    reducers: {
        incrementViewportScale : incrementViewportScaleAction,
        setViewportScale: setViewportScaleAction,
        setViewportFitOption: setViewportFitOptionAction,
        setViewportBreakoutSelection: setViewportBreakoutSelectionAction,
        setIsDragging: setIsDraggingAction,
    }
})

export const ViewportBridgeReducer = viewportBridgeSlice.reducer;

export const { incrementViewportScale, setViewportScale, setViewportFitOption, setViewportBreakoutSelection, setIsDragging } = viewportBridgeSlice.actions;