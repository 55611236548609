import { IColorDialogProps } from "./types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { DialogProps } from "@orbit/dialog";

export const useColorDialog = (props: IColorDialogProps) => {
    const { open, onClose, className, colors, currentColor, onColorButtonClick } = props;
    const { t } = useTranslation();

    const dialogProps: DialogProps = {
        open,
        className: `color-dialog ${className}`,
        title: t(LocalizationKeys.ColorSelection),
        modal: false,
        preventOutsideDismiss: true,
        onClose
    };

    return { dialogProps, colors, currentColor, onColorButtonClick };
}