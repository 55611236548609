import { useContext, useEffect, useReducer, useRef } from "react";
import { BoundingBox, initialContainerBox } from "./types";
import * as Pixi from 'pixi.js';
import { SideContext } from "../../cable/side/types";
import { InteractionReducer, setSize } from "../reducers";
import { StatusContext } from "../../status/types";
import { PIGTAIL_NO_LEG } from "../../../workspace/assembly/connector/types";

export const useBoundingBox = (props: BoundingBox, bufferRec = initialContainerBox, parentHitbox = true) => {
    const containerRef = useRef<Pixi.Container>(null)
    const [state, dispatch] = useReducer(InteractionReducer, { ...initialContainerBox });
    const { m, breakouts } = useContext(SideContext)
    const { state: statusState } = useContext(StatusContext);
    const { isWorkspaceInteractable } = statusState;
    const isPigtailWithNoLeg = breakouts[0].furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG;
    const isInteractive = isWorkspaceInteractable && !isPigtailWithNoLeg;
    
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const width = m * (container.width + bufferRec.width)
            const height = container.height + bufferRec.height
            let x = (props.x || 0) + bufferRec.x;
            let y = (props.y || 0) + bufferRec.y - height / 2;

            if (state.x !== x || state.y !== y || state.width !== width || state.height !== height) {
                dispatch(setSize({
                    x,
                    y,
                    width,
                    height
                }))
            }
        }
    }, [props, state, m, bufferRec])

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;
        if (parentHitbox) {
            const x = state.width > 0 ? state.x : state.x + state.width
            container.hitArea = new Pixi.Rectangle(
                x,
                state.y,
                Math.abs(state.width),
                state.height
            )

        }
    }, [state, containerRef, parentHitbox])

    return { state, containerRef, isInteractive }
}