import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../localization/types";
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from "react";
import { annotationsListSelector, assemblyGlobalAnnotationsDataSelector, currentAssemblyAnnotationSelector, currentAssemblySelector } from "../assembly/store/selectors";
import { AnnotationTab, AnnotationTabs } from "./types";
import { ButtonProps } from "@orbit/button";
import { updateAssemblyAnnotation, updateAssemblyCalloutDesc } from "../assembly/store/reducer";
import { IAssemblyAnnotation } from "../assembly/annotation/types";
import { isOptimusUserSelector, showAnnotationDialogSelector} from "../store/selectors";
import { setStatus, showAnnotationDialog } from "../store/reducer";
import { Status } from "../store/types";
import { workspaceSavingSelector } from "../store/selectors";
import { useStoreDispatch } from "../../store/hooks";
import { DialogProps } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { AlertPalettes, AlertProps, AlertVariant } from "@orbit/snackbar";

export const useAnnotationDialog = () => {
    const dispatch = useStoreDispatch();
    const { t } = useTranslation();
    const open = useSelector(showAnnotationDialogSelector);
    const annotations = useSelector(annotationsListSelector);
    const isOptimusUser = useSelector(isOptimusUserSelector);
    const annotationsData = useSelector(assemblyGlobalAnnotationsDataSelector(isOptimusUser));
    const { id: assemblyId } = useSelector(currentAssemblySelector);
    const assemblyAnnotation = useSelector(currentAssemblyAnnotationSelector);
    const workspaceSaving = useSelector(workspaceSavingSelector);
    const [selectedTab, setSelectedTab] = useState<AnnotationTab>(AnnotationTabs.Callout);
    const showCalloutDescription = selectedTab === AnnotationTabs.Callout;
    const showNotes = selectedTab === AnnotationTabs.Notes;
    const [notes, setNotes] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (!open) {
            setSelectedTab(AnnotationTabs.Callout);
            if (assemblyAnnotation && notes !== assemblyAnnotation.notes) {
                setNotes(!assemblyAnnotation.notes ? "" : assemblyAnnotation.notes);
            }
        }
    }, [open, assemblyAnnotation, notes]);

    useEffect(() => {
        setNotes(!assemblyAnnotation || !assemblyAnnotation?.notes ? "" : assemblyAnnotation?.notes);
    }, [assemblyAnnotation]);

    const onClose = useCallback(() => {
        dispatch(showAnnotationDialog(false));
    }, [dispatch])

    const dialogProps: DialogProps = {
        open,
        className: "annotation-dialog",
        title: t(LocalizationKeys.Annotations),
        header: {
            collapsible: true,
        },
        modal: false,
        onClose
    }

    const onTabChange = useCallback((e, tab) => {
        setSelectedTab(tab);
    }, []);

    const tabsProps = {
        value: selectedTab,
        onChange: onTabChange
    };

    const calloutTab = {
        value: AnnotationTabs.Callout,
        label: t(LocalizationKeys.CalloutDescription)
    };
    
    const noteTab = {
        value: AnnotationTabs.Notes,
        label: t(LocalizationKeys.Notes)
    }

    const onMasterButtonClick = useCallback(() => {
        dispatch(setStatus(Status.Synchronizing))
        const newAnnotations = annotationsData.filter((a => !a.disabled)).map(an => an.number)    
        const annotation: IAssemblyAnnotation = { ...assemblyAnnotation as IAssemblyAnnotation, assemblyId, calloutVisibility : annotations.length === 0 ? newAnnotations : []};
        dispatch(updateAssemblyCalloutDesc(annotation))
    }, [annotations, annotationsData, assemblyAnnotation, assemblyId, dispatch]);

    const masterButtonProps = {
        className: "master-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: workspaceSaving,
        onClick: onMasterButtonClick
    };

    const tableHeaderProps = {
        masterButtonProps,
        numberLabel: t(LocalizationKeys.Number),
        descriptionLabel: t(LocalizationKeys.Description),
        quantityLabel: t(LocalizationKeys.Quantity)
    };

    const onNotesChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const notes = e.currentTarget.value;
        setNotes(notes);
    }, []);

    const textArea = {
        className: "notes-input",
        value: notes,
        maxLength: 2000,
        palette: MainPalettes.primary,
        autoComplete: "off",
        spellCheck: false,
        disabled: workspaceSaving,
        multiline: true,
        onChange: onNotesChange
    }

    const onClearClick = useCallback(() => {
        setNotes("");
    }, []);

    const clearDisabled = notes.length === 0 || workspaceSaving; 
    const clearButtonProps: ButtonProps = {
        className: "clear-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: clearDisabled,
        onClick: onClearClick
    }

    const clear = {
        buttonProps: clearButtonProps,
        label: t(LocalizationKeys.Clear)
    };

    const onSaveClick = useCallback(() => {
        dispatch(setStatus(Status.Synchronizing));
        const annotation: IAssemblyAnnotation = { ...assemblyAnnotation as IAssemblyAnnotation, assemblyId, notes };
        dispatch(updateAssemblyAnnotation(annotation, setShowSnackbar));
    }, [assemblyAnnotation, assemblyId, notes, dispatch]);

    const saveDisabled = (assemblyAnnotation ? notes === assemblyAnnotation.notes : notes.length === 0) || workspaceSaving;
    const saveButtonProps: ButtonProps = {
        className: "save-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: saveDisabled,
        onClick: onSaveClick
    };

    const save = {
        buttonProps: saveButtonProps,
        label: t(LocalizationKeys.Save)
    };

    const onSnackbarClose = useCallback(() => {
        setShowSnackbar(false);
    }, [])

    const snackbarProps: AlertProps = {
        className: "annotation-snackbar",
        palette: AlertPalettes.success,
        variant: "filled" as AlertVariant,
        snackbarProps: {
            open: showSnackbar,
            onClose: onSnackbarClose,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            message: t(LocalizationKeys.NotesSaved),
            autoHideDuration: 5000,
        },
    }

    return { 
        dialogProps, 
        tabsProps,
        annotations,
        calloutProps: {
            tab: calloutTab,
            display: showCalloutDescription,
            tableHeaderProps, 
            annotationsData 
        },
        notesProps: {
            tab: noteTab,
            display: showNotes,
            textArea,
            clear,
            save
        },
        snackbarProps
    };
}