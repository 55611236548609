import { IColor } from "../types";

export const chipOriginalWidth = 110;
export const chipOriginalHeight = 26;

export interface IColorChipProps {
    color: IColor;
    scaleMultiplier: number;
}

export function getScaledChipDimensions(scaleMultiplier: number) {
    return { width: chipOriginalWidth * scaleMultiplier, height: chipOriginalHeight * scaleMultiplier };
}