import { Graphics, Text } from '@inlet/react-pixi';
import React from 'react';
import { BezierCurve } from '../../../bezier-curve/BezierCurve';
import { SelectFilter } from '../../../interactions/selection/Selection';
import { ConnectorFurcation } from '../connector-furcation/ConnectorFurcation';
import { FurcationContainer } from '../connector-furcation/types';
import { useBreakout } from '../hooks';
import { BreakoutProps, BreakoutContainer, BreakoutContext, BreakoutShield, BreakoutFurcation, BreakoutLabel } from '../types';

export const BreakoutGraphic: React.FC<BreakoutProps> = (props) => {
    const { context, bezierTrunkStart, bezierTrunkMid, bezierTrunkEnd, drawShield, drawFurcation, isPigtailWithNoLeg, showTransitions, selection, selectFilterProps, connectorFurcationProps, breakoutLabelTextProps } = useBreakout(props)
    return (
        <SelectFilter name={BreakoutContainer} adjust={selection.hoverFilters} glow={selection.selectionFilters} {...selectFilterProps}>
            <BreakoutContext.Provider value={context}>
                <ConnectorFurcation name={FurcationContainer} {...connectorFurcationProps} />
                <BezierCurve {...bezierTrunkStart} />
                <BezierCurve {...bezierTrunkEnd} />
                <BezierCurve {...bezierTrunkMid} />
                { 
                    showTransitions && !isPigtailWithNoLeg && 
                        <>
                            <Graphics name={BreakoutShield} draw={drawShield} />
                            <Graphics name={BreakoutFurcation} draw={drawFurcation} />
                            <Text name={BreakoutLabel} {...breakoutLabelTextProps}/>
                        </>
                }
            </BreakoutContext.Provider>
        </SelectFilter>
    )
}