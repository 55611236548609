import React from 'react';
import { useBreakoutToolbar } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Edit, ListAlt } from '@mui/icons-material';

export const BreakoutToolbar: React.FC = () => {
    const { showToolbar, editProps, detailsProps } = useBreakoutToolbar();
    if (!showToolbar) {
        return null;
    }
    return (
        <div className="toolbar-container">
            <div className="breakout-toolbar-actions">
                <IconButton {...editProps} icon={<Edit />} />
                <IconButton {...detailsProps} icon={<ListAlt />} />
            </div>
        </div>
    )
}