import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { IPolarityRowProps } from "./types";

export const usePolarityRow = ({ polarityScheme, onSchemeClick, onEditClick, onDeleteClick }: IPolarityRowProps) => {
    const { t } = useTranslation();

    const lockProps = {
        className: "lock-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
    };

    const editProps = {
        className: "edit-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Edit),
        placement: "bottom" as TooltipPlacement,
        onClick: () => onEditClick(polarityScheme)
    };

    const deleteProps = {
        className: "delete-button",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Delete),
        placement: "bottom" as TooltipPlacement,
        onClick: () => onDeleteClick(polarityScheme)
    };

    return { polarityScheme, onSchemeClick, lockProps, editProps, deleteProps };
}