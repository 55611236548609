import React from 'react'
import { IAnnotationRowProps } from './types'
import { useAnnotationRow } from './hooks'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { TableCell, TableRow } from '@mui/material'
import { IconButton } from '@orbit/icon-button'
import { FontTokens, Typography } from '@orbit/theme-provider'

export const AnnotationRow: React.FC<IAnnotationRowProps> = (props) => {
    const { annotations, buttonProps, data } = useAnnotationRow(props);
    const icon = annotations.includes(data.number) ? <Visibility /> : <VisibilityOff />
    return (
        <TableRow>
            <TableCell align="center">
                <IconButton {...buttonProps} icon={icon} />
            </TableCell>
            <TableCell align="left">
                <Typography variant="body2" label={data.number} />
            </TableCell>
            <TableCell className="description-column" align="left">
                <Typography variant="body2" label={data.description} fontToken={FontTokens.none} />
            </TableCell>
            <TableCell align="right">
                <Typography variant="body2" label={`${data.quantity}`} />
            </TableCell>
        </TableRow>
    )
}