import React from "react";
import { IConnectorRowProps } from "./types";

export const ConnectorRow: React.FC<IConnectorRowProps> = ({ sideAConnector, sideBConnector }) => {
    return (
        <tr>
            <td id={sideAConnector.key}></td>
            <td id={sideBConnector.key}></td>
        </tr>
    )
}