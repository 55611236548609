import { INavigationBarProps } from "./types";
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../localization/types';
import { useCallback, useMemo } from "react";
import { setCurrentIndex } from "./store/reducer";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";

export const useNavigationBar = (props: INavigationBarProps) => {
    const { context, displayThreshold, onFirstClick, onPreviousClick, buttons, onNextClick, onLastClick } = props;
    const { state, dispatch } = context;
    const { currentIndex, disabled } = state;
    const { t } = useTranslation();

    const onFirstCallback = useCallback(() => {
        if (onFirstClick) {
            onFirstClick();
        }
        dispatch(setCurrentIndex(0));
    }, [onFirstClick, dispatch]);

    const onPreviousCallback = useCallback(() => {
        if (onPreviousClick) {
            onPreviousClick();
        }
        dispatch(setCurrentIndex(currentIndex - 1));
    }, [onPreviousClick, dispatch, currentIndex]);

    const onNextCallback = useCallback(() => {
        if (onNextClick) {
            onNextClick();
        }
        dispatch(setCurrentIndex(currentIndex + 1));
    }, [onNextClick, dispatch, currentIndex]);

    const onLastCallback = useCallback(() => {
        if (onLastClick) {
            onLastClick();
        }
        if (buttons && buttons.length > 0) {
            dispatch(setCurrentIndex(buttons.length - 1));
        }
    }, [onLastClick, buttons, dispatch]);

    const firstPreviousDisabled = currentIndex === 0 || disabled || (buttons && currentIndex && buttons[currentIndex - 1].buttonProps.disabled);
    const firstProps = {
        className: "first-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.First),
        placement: "bottom" as TooltipPlacement,
        disabled: firstPreviousDisabled,
        onClick: onFirstCallback
    };
    const previousProps = {
        className: "previous-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Previous),
        placement: "bottom" as TooltipPlacement,
        disabled: firstPreviousDisabled,
        onClick: onPreviousCallback
    };
    const nextLastDisabled = currentIndex + 1 === buttons?.length || disabled || (buttons && currentIndex + 1 < buttons.length && buttons[currentIndex + 1].buttonProps.disabled);
    const nextProps = {
        className: "next-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Next),
        placement: "bottom" as TooltipPlacement,
        disabled: nextLastDisabled,
        onClick: onNextCallback
    };
    const lastProps = {
        className: "last-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Last),
        placement: "bottom" as TooltipPlacement,
        disabled: nextLastDisabled,
        onClick: onLastCallback
    };
    const thresholdProps = {
        className: "threshold-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: true,
    };

    let showPreviousThreshold = false;
    let showNextThreshold = false;
    if (displayThreshold && buttons && buttons.length > displayThreshold) {
        const nbGroups = Math.ceil(buttons.length / displayThreshold);
        const currentGroup = Math.floor(currentIndex / displayThreshold) + 1;
        showPreviousThreshold = currentGroup > 1;
        showNextThreshold = currentGroup < nbGroups;
    }

    const displayedButtons = useMemo(() => { 
        if (buttons && buttons.length > 0) {
            buttons[currentIndex].buttonProps.className = "selected";
            if (displayThreshold && buttons.length > displayThreshold) {
                const firstChildIndex = Math.floor(currentIndex / displayThreshold) * displayThreshold;
                const lastChildIndex = firstChildIndex + displayThreshold;
                return buttons.slice(firstChildIndex, lastChildIndex);
            } else {
                return buttons;
            }
        }
    }, [buttons, currentIndex, displayThreshold]);
    
    return { firstProps, previousProps, buttons: displayedButtons, nextProps, lastProps, showPreviousThreshold, showNextThreshold, thresholdProps };
}