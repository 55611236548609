import { Orientations, Unidirection, Unidirections } from "../../../markers/types";
import { IBreakoutAnnotationProps } from "./types";
import { useContext, useMemo } from "react"
import { AnnotationContext } from "../../../../workspace/annotation/store/types";
import { SideContext } from "../../side/types";
import { CircleMarkerProps } from "../../../markers/circle/types"
import { isMTP } from "../../../../workspace/assembly/connector/types";
import { getConnectorSprite } from "../connector-furcation/connector-sprite/types";
import { MarkerSettingsContext } from "../../../../workspace/store/types";

const radius = 10;
const length = 50;
const orientation = Orientations.Vertical;
const unidirection = Unidirections.Start;
const color = 0x000000;
const thickness = 1;
const alpha = 1;

export const useBreakoutAnnotation = (props: IBreakoutAnnotationProps) => {
    const { position, furcationPosition, legEnd, connectorType, nbConnectors } = props;
    const { annotations } = useContext(AnnotationContext);
    const { m, breakouts } = useContext(SideContext);
    const { showToleranceMarker } = useContext(MarkerSettingsContext);
    const connectorTypes = breakouts.map(b => b.furcation).flatMap(f => f.groups).flatMap(g => g.connectors).map(c => c.type).filter((t, i, self) => self.indexOf(t) === i);
    const breakoutAnnotations: CircleMarkerProps[] = useMemo(() => {
        const annotationMarkers: CircleMarkerProps[] = [];
        const sideCharacter = m > 0 ? "b" : "a";
        const offsetX = 7.7;
        const coordinateX = legEnd.x - m * offsetX;
        const offsetY = showToleranceMarker && position === 1 && m < 0 ? 8 : 0
        let subunitName = `4${sideCharacter}`;
        let heatshrinkBreakName = `5${sideCharacter}`;
        let furcationTubingName = `6${sideCharacter}`;
        let tailLabelName = `7${sideCharacter}`;
        let connectorTypeIndex = 8;
        if (breakouts[0].trunk.length.value === 0) {
            subunitName = "";
            heatshrinkBreakName = "";
            furcationTubingName = `4${sideCharacter}`;
            tailLabelName = `5${sideCharacter}`;
            connectorTypeIndex = 6;
        }
        const typesFromPreviousBreakouts = breakouts.filter(b => b.position < position).map(b => b.furcation).flatMap(f => f.groups).flatMap(g => g.connectors).map(c => c.type).filter((t, i, self) => self.indexOf(t) === i);
        
        if (position === breakouts.length) {
            if (annotations.includes(subunitName)) {
                annotationMarkers.push({
                    coordinate: { x: furcationPosition.x - m * 11.5, y: furcationPosition.y + 4 },
                    radius,
                    orientation,
                    unidirection,
                    length: 30,
                    color,
                    thickness,
                    alpha,
                    text: subunitName
                });
            }

            if (annotations.includes(heatshrinkBreakName)) {
                annotationMarkers.push({
                    coordinate: { x: furcationPosition.x - m * 4.5, y: furcationPosition.y + 4 },
                    radius,
                    orientation,
                    unidirection,
                    length,
                    color,
                    thickness,
                    alpha,
                    text: heatshrinkBreakName
                });
            }

            const coordinateY = nbConnectors > 1 ? legEnd.y + 2 : legEnd.y + 1;
            if (annotations.includes(furcationTubingName)) {
                annotationMarkers.push({
                    coordinate: { x: coordinateX - m * 20, y: coordinateY },
                    radius,
                    orientation: Orientations.Vertical,
                    unidirection: Unidirections.Start,
                    length: 30,
                    color,
                    thickness,
                    alpha,
                    text: furcationTubingName
                });
            }

            if (annotations.includes(tailLabelName)) {
                annotationMarkers.push({
                    coordinate: { x: coordinateX, y: coordinateY + offsetY },
                    radius,
                    orientation: Orientations.Vertical,
                    unidirection: Unidirections.Start,
                    length: showToleranceMarker && position === 1 && m < 0 ? 38 : 30,
                    color,
                    thickness,
                    alpha,
                    text: tailLabelName
                });
            }

            if (connectorTypes.length === 1) {
                const connectorTypeName = `${connectorTypeIndex}${sideCharacter}`;
                if (annotations.includes(connectorTypeName)) {
                    const sprite = getConnectorSprite(connectorType);
                    const isMTPConnector = isMTP(connectorType);
                    const mtpOffsetX = isMTPConnector ? 3.55 : 0;
                    const widthOffset = m * sprite.height * 0.5;
                    const offsetX = (widthOffset - m * mtpOffsetX) * 0.5;
                    annotationMarkers.push({
                        coordinate: { x: legEnd.x + offsetX, y: coordinateY + offsetY },
                        radius,
                        orientation: Orientations.Vertical,
                        unidirection: Unidirections.Start,
                        length: showToleranceMarker && position === 1 && m < 0 ? 38 : 30,
                        color,
                        thickness,
                        alpha,
                        text: connectorTypeName
                    });
                }
            }
        }

        if (connectorType !== "" && !typesFromPreviousBreakouts.includes(connectorType) && connectorTypes.length > 1) {
            const localIndex = connectorTypes.indexOf(connectorType);
            if (localIndex > -1) {
                const annotationIndex = connectorTypeIndex + localIndex;
                const connectorTypeName = `${annotationIndex}${sideCharacter}`;
                if (annotations.includes(connectorTypeName)) {
                    const sprite = getConnectorSprite(connectorType);
                    const isMTPConnector = isMTP(connectorType);
                    const mtpOffsetX = isMTPConnector ? 3.55 : 0;
                    const widthOffset = m * sprite.height * 0.5;
                    const spacing = m * 2.5;
                    let offsetX = (widthOffset - length + mtpOffsetX) * 0.5 + 0.5 + spacing;
                    let unidirection: Unidirection = Unidirections.End;
                    if (m > 0) {
                        offsetX = (widthOffset - mtpOffsetX) * 0.5 + spacing
                        unidirection = Unidirections.Start;
                    }
                    
                    annotationMarkers.push({
                        coordinate: { x: legEnd.x + offsetX, y: furcationPosition.y },
                        radius,
                        orientation: Orientations.Horizontal,
                        unidirection,
                        length,
                        color,
                        thickness,
                        alpha,
                        text: connectorTypeName
                    });
                }
            }
        }

        return annotationMarkers;
    }, [position, breakouts, annotations, m, furcationPosition, legEnd, connectorType, connectorTypes, showToleranceMarker, nbConnectors]);

    return { breakoutAnnotations };
}