import { createSelector } from "@reduxjs/toolkit";
import { rootSelector } from "../../../store/selectors";

export const reportSelector = createSelector(
    rootSelector,
    root => root.report
);

export const showPrintDialogSelector = createSelector(
    reportSelector,
    report => report.showPrint
);

export const assemblyDocumentSelector = createSelector(
    reportSelector,
    report => report.assemblyDocument
)

export const userProvidedImagesSelector = createSelector(
    assemblyDocumentSelector,
    assemblyDocument => assemblyDocument.userProvidedImages
)