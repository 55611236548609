import { useSelector } from "react-redux"
import { pmCurrentAssemblyInfoSelector, pmEditOpenSelector } from "../../project-drawer/store/selectors";
import { useState, useEffect, useCallback } from "react";
import { setOpen, setDrawerStatus } from "../../project-drawer/store/reducer";
import { DrawerStatus } from "../../project-drawer/store/types";
import { ButtonProps } from "@orbit/button";
import { updateAssemblyInfo } from "../store/reducer";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { useStoreDispatch } from "../../../store/hooks";
import { DrawerHeaderProps } from "../../../ui/drawer/drawer-header/types";
import { CorningLogo } from "../../top-bar/project-manager-panel/types";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";

export const useAssemblyInfoDrawer = () => {
    const info = useSelector(pmCurrentAssemblyInfoSelector);
    const open = useSelector(pmEditOpenSelector);
    const { t } = useTranslation();
    const dispatch = useStoreDispatch();

    const initialName = info && info.name && info.name.length > 0 ? info.name : `CAB #${info?.assemblyId}`;
    const initialDescription = info?.description ?? "";
    const initialPartNumber = info?.partNumber ?? "";
    const [name, setName] = useState(initialName);
    const [description, setDescription] = useState(initialDescription);
    const [partNumber, setPartNumber] = useState(initialPartNumber);

    const resetInfo = useCallback(() => {
        setName(initialName);
        setDescription(initialDescription);
        setPartNumber(initialPartNumber);
    }, [initialName, initialDescription, initialPartNumber]);

    useEffect(() => {
        if (open) {
            resetInfo();
        }
    }, [open, resetInfo]);

    const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setName(entry);
    }, []);

    const nameField = {
        className: "name-field",
        label: t(LocalizationKeys.Name),
        value: name,
        palette: MainPalettes.primary,
        maxLength: 20,
        onChange: onNameChange
    };

    const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setDescription(entry);
    }, []);

    const descriptionField = {
        className: "description-field",
        label: t(LocalizationKeys.Description),
        value: description,
        palette: MainPalettes.primary,
        maxLength: 130,
        onChange: onDescriptionChange
    };

    const onPartNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setPartNumber(entry);
    }, []);

    const partNumberField = {
        className: "part-number-field",
        label: `${t(LocalizationKeys.Custom)} ${t(LocalizationKeys.PartNumber)}`,
        value: partNumber,
        palette: MainPalettes.primary,
        maxLength: 20,
        onChange: onPartNumberChange
    }

    const onClose = useCallback(() => {
        if (open) {
            dispatch(setOpen(false));
        }
    }, [open, dispatch]);

    const drawerProps = {
        className: "assembly-info-drawer",
        open,
        onClose
    };

    const header: DrawerHeaderProps = {
        logo: CorningLogo,
        onClose
    };

    const onBackClick = useCallback(() => {
        dispatch(setDrawerStatus(DrawerStatus.Home));
    }, [dispatch]);

    const backButtonProps: ButtonProps = {
        className: "back-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onBackClick
    };

    const back = {
        buttonProps: backButtonProps,
        label: t(LocalizationKeys.Back)
    };

    const onCancelClick = useCallback(() => {
        resetInfo();
    }, [resetInfo]);

    const nameChanged = name !== initialName;
    const descriptionChanged = description !== initialDescription;
    const partNumberChanged = partNumber !== initialPartNumber;
    const saveDisabled = !nameChanged && !descriptionChanged && !partNumberChanged;

    const cancelButtonProps: ButtonProps = {
        className: "cancel-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: saveDisabled,
        onClick: onCancelClick
    };

    const cancel = {
        buttonProps: cancelButtonProps,
        label: t(LocalizationKeys.Cancel)
    };

    const onSaveClick = useCallback(() => {
        if (info && info.assemblyId) {
            dispatch(setStatus(Status.Synchronizing));
            dispatch(updateAssemblyInfo({...info, name, description, partNumber }));
            onBackClick();
        }
    }, [info, name, description, partNumber, dispatch, onBackClick]);

    const saveButtonProps: ButtonProps = {
        className: "save-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: saveDisabled,
        onClick: onSaveClick
    };

    const save = {
        buttonProps: saveButtonProps,
        label: t(LocalizationKeys.Save)
    };

    return { drawerProps, header, name: nameField, description: descriptionField, partNumber: partNumberField, back, cancel, save };
}