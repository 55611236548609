import { Orientation, Unidirection } from "../types";
import { CircleProps } from "../base/circle/types";

interface ICircleMarkerProps {
    orientation: Orientation;
    unidirection: Unidirection;
    length: number;
    text: string;
}

export type CircleMarkerProps = ICircleMarkerProps & CircleProps;

export const CircleMarkerContainer = "circle-marker-container";