import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { logOff } from "../../../store/reducer";
import { useStoreDispatch } from "../../../../store/hooks";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { ButtonProps } from "@orbit/button";

export const useMenuAction = () => {
    const disabled = useSelector(disabledOverlaySelector);
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const openMenu = useCallback((e) => {
        setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    }, []);

    const onMenuClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const menuProps = {
        open: menuOpen,
        className: "user-menu",
        anchorEl,
        onClose: onMenuClose,
    };

    const menuIconProps = {
        className: "user-menu-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.UserMenu),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: openMenu
    };

    const onLogOutClick = useCallback(() => {
        storeDispatch(logOff());
    }, [storeDispatch]);

    const logoutButtonProps: ButtonProps = {
        className: "logout-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        size: "large",
        onClick: onLogOutClick
    };

    const logout = {
        buttonProps: logoutButtonProps,
        label: t(LocalizationKeys.LogOut)
    };

    return { menuIconProps, menuProps, logout };
}