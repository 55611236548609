import { createSlice } from "@reduxjs/toolkit";
import { initialBreakoutDetailsState, initialBreakoutDetailsContext } from "./types";
import { setSelectedLegsAction, updateSelectedLegsAction, clearSelectionAction } from "./actions";
import { createContext } from "react";

const breakoutDetailsSlice = createSlice({
    name: "breakoutDetails",
    initialState: initialBreakoutDetailsState,
    reducers: {
        setSelectedLegs: setSelectedLegsAction,
        updateSelectedLegs: updateSelectedLegsAction,
        clearSelection: clearSelectionAction
    }
});

export const BreakoutDetailsReducer = breakoutDetailsSlice.reducer;
export const { setSelectedLegs, updateSelectedLegs, clearSelection } = breakoutDetailsSlice.actions;

export const BreakoutDetailsContext = createContext(initialBreakoutDetailsContext);