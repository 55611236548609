import { createSelector } from "@reduxjs/toolkit";
import { rootSelector } from "../../../store/selectors";
import { getFiberMapDataConnectorTypes } from "../connector-assignment/reducer/actions";
import { IFiberMapData } from "../fiber-map/types";
import { IPolarityMap, PredefinedPolarityMaps } from "./types";

export const polaritySelector = createSelector(
    rootSelector,
    root => root.polarity
);

export const showPolaritySchemesSelector = createSelector(
    polaritySelector,
    polarity => polarity.showPolaritySchemes
);

export const showConnectorAssignmentSelector = createSelector(
    polaritySelector,
    polarity => polarity.showConnectorAssignment
);

export const showFiberMappingSelector = createSelector(
    polaritySelector,
    polarity => polarity.showFiberMapping
);

export const showPropagationSelector = createSelector(
    polaritySelector,
    polarity => polarity.showPropagation
);

export const isPolarityModeSelector = createSelector(
    showPolaritySchemesSelector,
    showConnectorAssignmentSelector,
    showFiberMappingSelector,
    (showPolaritySchemes, showConnectorAssignment, showFiberMapping) => showPolaritySchemes || showConnectorAssignment || showFiberMapping
);

export const predefinedFiberMapsSelector = createSelector(
    polaritySelector,
    polarity => polarity.predefinedFiberMaps
);

export const userFiberMapsSelector = createSelector(
    polaritySelector,
    polarity => polarity.userFiberMaps
);

export const highlightedConnectorsSelector = createSelector(
    polaritySelector,
    polarity => polarity.highlightedConnectors
);

export const forceUpdatePolaritySelector = createSelector(
    polaritySelector,
    polarity => polarity.forceUpdate
);

export const fiberMapsSelector = createSelector(
    predefinedFiberMapsSelector,
    userFiberMapsSelector,
    (predefinedFiberMaps, userFiberMaps) => [...predefinedFiberMaps, ...userFiberMaps]
)

export const polarityMapsSelector = createSelector(
    userFiberMapsSelector,
    (userFiberMaps) => {
        const userPolarityMaps = userFiberMaps.map(f => generatePolarityMapFromFiberMapData(f));
        return [...PredefinedPolarityMaps, ...userPolarityMaps];
    }
)

export function generatePolarityMapFromFiberMapData(fiberMapData: IFiberMapData): IPolarityMap {
    const sourceConnectorTypes = getFiberMapDataConnectorTypes(fiberMapData.sourceIndices);
    const destinationConnectorTypes = getFiberMapDataConnectorTypes(fiberMapData.destinationIndices);
    return {
        id: fiberMapData.id,
        key: fiberMapData.key,
        description: fiberMapData.name,
        from: sourceConnectorTypes.map(t => t.fiberCount),
        to: destinationConnectorTypes.map(t => t.fiberCount)
    }
}