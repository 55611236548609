import { PayloadAction } from "@reduxjs/toolkit";
import { isMetric, Unit, Units } from "../types";
import { IUnitState } from "./types";

export const displayUnitConverterAction = (state: IUnitState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
}

export const setWorkspaceUnitAction = (state: IUnitState, action: PayloadAction<Unit>) => {
    state.unit = action.payload;
    state.secondaryUnit = isMetric(state.unit) ? Units.Meter : Units.Feet
}