import React from 'react';
import { useFiberMapTable } from './hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';

export const FiberMapTable: React.FC = () => {
    const { endALabel, endBLabel, rows } = useFiberMapTable();
    const { t } = useTranslation();
    return (
        <table id={"fiber-map-table"}>
            <thead>
                <tr>
                    <th colSpan={6}>{endALabel}</th>
                    <th colSpan={1} />
                    <th colSpan={6}>{endBLabel}</th>
                </tr>
                <tr>
                    <th>Pos.</th>
                    <th>{t(LocalizationKeys.PositionColor)}</th>
                    <th>{t(LocalizationKeys.Connector)}</th>
                    <th>{t(LocalizationKeys.ConnectorType)}</th>
                    <th>{`${t(LocalizationKeys.Leg)} ID`}</th>
                    <th>{t(LocalizationKeys.Breakout)}</th>
                    <th>{`${t(LocalizationKeys.Fiber)} #`}</th>
                    <th>{t(LocalizationKeys.Breakout)}</th>
                    <th>{`${t(LocalizationKeys.Leg)} ID`}</th>
                    <th>{t(LocalizationKeys.ConnectorType)}</th>
                    <th>{t(LocalizationKeys.Connector)}</th>
                    <th>{t(LocalizationKeys.PositionColor)}</th>
                    <th>Pos.</th>
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((r, index) => (
                        <tr key={index + 1}>
                            <td>{r.sideA.position}</td>
                            <td>{r.sideA.fiberColor}</td>
                            <td>{r.sideA.connectorPosition}</td>
                            <td>{r.sideA.connectorType}</td>
                            <td>{r.sideA.legID}</td>
                            <td>{r.sideA.breakout}</td>
                            <td>{index + 1}</td>
                            <td>{r.sideB.breakout}</td>
                            <td>{r.sideB.legID}</td>
                            <td>{r.sideB.connectorType}</td>
                            <td>{r.sideB.connectorPosition}</td>
                            <td>{r.sideB.fiberColor}</td>
                            <td>{r.sideB.position}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}