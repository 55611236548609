import { getFillOpacity, IConnectorPinDrawingProps, IConnectorPinProps, ITemplateProps } from "./types";
import { useCallback, useContext, useMemo } from "react";
import { FiberMappingContext } from "../../reducer/types";
import { PolarityContext } from "../../../reducer/types";
import { Sides } from "../../../../assembly/breakout/types";
import { FiberColors } from "../../../../assembly/palette/types";
import { getTextColor } from "../../../../../ui/dialog/color/types";
import { getConnectorType, LC_APC, LC_APC_SIMPLEX, LC_APC_UNIBOOT, LC_SIMPLEX, LC_UPC, LSH, MDC, SC_APC, SC_APC_SIMPLEX, SC_SIMPLEX, SC_UPC, SC_UPC_SIMPLEX, SN } from "../../../../assembly/connector/types";
import { IPinAssignmentMap } from "../../types";
import { breakAssignment, handleAssignment, handleUnusedPin, setSelectedPinIndex } from "../../reducer/reducer";

const colorDisabled = "#E0E0E0";
const colorUnused = "#E11725";
const grey = "#606065";

const LCFiberCount = 2;
const LCStartX = 17;
const LCStartY = 40;
const MMCStartX = 32;
const MMCStartY = 68;
const MMC24StartY = 38;
const MTPStartX = 18;
const MTP24StartY = 40;
const MTP12StartY = 70;
const pinStep = 56;

export const useConnectorPin = (props: IConnectorPinProps) => {
    const { side, pinIndex: index, disabled } = props;
    const { state, dispatch } = useContext(FiberMappingContext);
    const { assignments, navigation, unused } = state;
    const { sideA, sideB, selectedPinIndex } = navigation;
    const { state: polarityState } = useContext(PolarityContext);
    const { connectorAssignment } = polarityState;

    const pinProps = useMemo(() => {
        let className = "";
        let tooltip = "";
        let assignment: IPinAssignmentMap | undefined;
        let selected = false;
        let assigned = false;
        let isAssignedToSideB = false;
        let isUnused = false;

        if (side === Sides.SideA) {
            assignment = assignments.find(m => m.sideAPin.pinIndex === index && m.sideAPin.breakout.index === sideA.breakout.currentIndex && m.sideAPin.connector.index === sideA.connector.currentIndex);
            selected = selectedPinIndex === index;
            if (assignment) {
                assigned = assignment.sideAPin.breakout.index === sideA.breakout.currentIndex && assignment.sideAPin.connector.index === sideA.connector.currentIndex;
                const sideBPositions = connectorAssignment.connectors.sideBMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i);
                const sideBPosition = sideBPositions[assignment.sideBPin.breakout.index];
                const sideBConnectors = connectorAssignment.connectors.sideBMapping.filter(c => c.breakoutPosition === sideBPosition);
                const sideBConnector = sideBConnectors[assignment.sideBPin.connector.index];
                if (assigned && sideBConnector) {
                    tooltip = `B${sideBConnector.breakoutPosition} C${sideBConnector.index}`;
                }
            }
            isUnused = unused.find(p => p.side === Sides.SideA && p.pinIndex === index && p.breakout.index === sideA.breakout.currentIndex && p.connector.index === sideA.connector.currentIndex) !== undefined;
        } else {
            assignment = assignments.find(m => m.sideBPin.pinIndex === index && m.sideBPin.breakout.index === sideB.breakout.currentIndex && m.sideBPin.connector.index === sideB.connector.currentIndex);
            if (assignment) {
                assigned = assignment.sideBPin.breakout.index === sideB.breakout.currentIndex && assignment.sideBPin.connector.index === sideB.connector.currentIndex
                const sideAPositions = connectorAssignment.connectors.sideAMapping.map(c => c.breakoutPosition).filter((c, i, self) => self.indexOf(c) === i);
                const sideAPosition = sideAPositions[assignment.sideAPin.breakout.index];
                const sideAConnectors = connectorAssignment.connectors.sideAMapping.filter(c => c.breakoutPosition === sideAPosition);
                const sideAConnector = sideAConnectors[assignment.sideAPin.connector.index];
                if (assigned && sideAConnector) {
                    tooltip = `A${sideAConnector.breakoutPosition} C${sideAConnector.index}`;
                }
            }
            isUnused = unused.find(p => p.side === Sides.SideB && p.pinIndex === index && p.breakout.index === sideB.breakout.currentIndex && p.connector.index === sideB.connector.currentIndex) !== undefined;
        }

        if (assignment) {
            isAssignedToSideB = assignment.sideBPin.breakout.index === sideB.breakout.currentIndex && assignment.sideBPin.connector.index === sideB.connector.currentIndex;
        }

        className = disabled ? "connector-pin disabled" : selected ? "connector-pin selected" : "connector-pin";

        return {
            className,
            tooltip,
            fiberMap: assignment,
            selected,
            assigned,
            isAssignedToTAP: isAssignedToSideB,
            disabled,
            unused: isUnused
        }
    }, [side, assignments, index, sideA, selectedPinIndex, connectorAssignment, unused, sideB, disabled]);

    const isBottomPin = index > 12;
    const color = FiberColors[(index - 1) % 12];
    const drawingProps: IConnectorPinDrawingProps = useMemo(() => {
        let fill = color.hex;
        let fillOpacity = 0;
        let stroke = color.hex;
        let text = index.toString();
        let textColor = grey;
        let strokeDashArray = side === Sides.SideA && isBottomPin ? "4" : "";

        if (pinProps.assigned) {
            fillOpacity = getFillOpacity(color)
            if (pinProps.isAssignedToTAP) {
                fillOpacity = 1;
                textColor = getTextColor(color).hex
            }
            if (side === Sides.SideB && pinProps.fiberMap) {
                const sideAPinIndex = pinProps.fiberMap.sideAPin.pinIndex;
                const sideAColor = FiberColors[(sideAPinIndex - 1) % 12];
                const sideAIsBottomPin = sideAPinIndex > 12;
                const isAssignedToSideA = pinProps.fiberMap.sideAPin.connector.index === sideA.connector.currentIndex;
                fill = sideAColor.hex;
                fillOpacity = isAssignedToSideA ? 1 : 0.2;
                stroke = sideAColor.hex;
                text = sideAPinIndex.toString();
                textColor = isAssignedToSideA ? getTextColor(sideAColor).hex : grey;
                strokeDashArray = sideAIsBottomPin ? "4" : ""
            }
        } else {
            if (side === Sides.SideB) {
                fillOpacity = 0;
                stroke = grey;
                textColor = "none";
            }
        }

        if (pinProps.disabled) {
            fill = colorDisabled;
            fillOpacity = 1;
            stroke = colorDisabled;
        }

        if (pinProps.unused) {
            fillOpacity = 0;
            stroke = colorUnused;
            strokeDashArray = ""
        }

        return {
            fill,
            fillOpacity,
            stroke,
            strokeDashArray,
            text,
            textColor
        };
    }, [color, index, side, isBottomPin, pinProps, sideA]);

    const onClick = useCallback((e: any) => {
        e.stopPropagation();
        if (side === Sides.SideA && !pinProps.selected) {
            dispatch(setSelectedPinIndex(index));
        } else {
            if (side === Sides.SideB && selectedPinIndex !== -1) {
                if (!pinProps.unused && !pinProps.assigned) {
                    dispatch(handleAssignment(index));
                }
            } else {
                if (pinProps.unused) {
                    dispatch(handleUnusedPin({ side, pinIndex: index }));
                } else {
                    if (pinProps.assigned) {
                        if (side === Sides.SideA && pinProps.selected) {
                            dispatch(breakAssignment());
                        }
                    } else {
                        dispatch(handleUnusedPin({ side, pinIndex: index }));
                    }
                }
            }
        }
    }, [side, pinProps, dispatch, index, selectedPinIndex]);

    return {
        tooltip: pinProps.tooltip,
        className: pinProps.className,
        onClick,
        drawingProps,
        unused: pinProps.unused,
        disabled: pinProps.disabled,
    };
}

export const useTemplate = () => {
    const { state, dispatch } = useContext(FiberMappingContext);

    const onClick = useCallback(() => {
        if (state.navigation.selectedPinIndex !== -1) {
            dispatch(setSelectedPinIndex(-1));
        }
    }, [state.navigation.selectedPinIndex, dispatch]);

    return { onClick };
}

export const useSimplexTemplate = ({ type, side }: ITemplateProps) => {
    const { onClick } = useTemplate();

    let connectorName = "";
    switch (type) {
        case LC_SIMPLEX:
            connectorName = "LC Simplex";
            break;
        case LC_APC_SIMPLEX:
            connectorName = "LC APC Simplex";
            break;
        case SC_SIMPLEX:
            connectorName = "SC Simplex";
            break;
        case SC_APC_SIMPLEX:
            connectorName = "SC APC Simplex";
            break;
        case SC_UPC_SIMPLEX:
            connectorName = "SC UPC Simplex";
            break;
    }

    const pin = {
        side,
        position: { x: 11.5, y: 40 },
        index: 1
    }

    return { onClick, connectorName, pin };
}

export const useLCTemplate = ({ type, side }: ITemplateProps) => {
    const { onClick } = useTemplate();

    let connectorName = "";
    switch (type) {
        case LSH: 
            connectorName = "LSH";
            break;
        case MDC:
            connectorName = "MDC";
            break;
        case SN: 
            connectorName = "SN"
            break;
        case LC_APC:
            connectorName = "LC APC Duplex";
            break;
        case LC_UPC:
            connectorName = "LC UPC Duplex";
            break;
        case SC_APC:
            connectorName = "SC APC Duplex";
            break;
        case SC_UPC:
            connectorName = "SC UPC Duplex"
            break;
        case LC_APC_UNIBOOT:
            connectorName = "LC APC Uniboot";
            break;
        default: 
            connectorName = "LC Uniboot"
            break;
    }

    const pinIndexes = Array.from(Array(LCFiberCount).keys());
    const pins = pinIndexes.map(i => {
        const x = LCStartX + i * pinStep;
        const y = LCStartY;
        return {
            side,
            position: { x, y },
            index: i + 1
        }
    });

    return { onClick, connectorName, pins };
}

export const useMTPTemplate = ({ type, side }: ITemplateProps) => {
    const { onClick } = useTemplate();

    const fiberCount = getConnectorType(type).fiberCount;
    const isMTP24 = fiberCount === 24;
    const nbPins = isMTP24 ? 24 : 12;

    const pinIndexes = Array.from(Array(nbPins).keys());
    const pins = pinIndexes.map(i => {
        const pinColumnIndex = i % 12;
        const x = MTPStartX + pinColumnIndex * pinStep;
        let y = MTP12StartY;
        if (isMTP24) {
            y = i >= 12 ? MTP24StartY + pinStep : MTP24StartY;
        }
        const disabled = fiberCount === 8 && i > 3 && i < 8;
        return {
            side,
            position: { x, y },
            index: i + 1,
            disabled
        }
    });

    return { onClick, fiberCount, isMTP24, pins };
}

export const useMMCTemplate = ({ type, side }: ITemplateProps) => {
    const { onClick } = useTemplate();
    
    const fiberCount = getConnectorType(type).fiberCount;
    const isMMC24 = fiberCount === 24;
    const nbPins = isMMC24 ? 24 : 16;

    const pinIndexes = Array.from(Array(nbPins).keys());
    const step = isMMC24 ? 76.5 : pinStep
    const pins = pinIndexes.map(i => {
        const pinColumnIndex = isMMC24 ? i % 12 : i;
        const x = MMCStartX + pinColumnIndex * step;
        let y = MMCStartY;
        if (isMMC24) {
            y = i >= 12 ? MMC24StartY + pinStep : MMC24StartY;
        }
        return {
            position: { x, y },
            index: i + 1,
            side,
        }
    });

    return { onClick, isMMC24, pins, side, type };
}