import { Side } from "../../../../assembly/breakout/types";
import { IColor, White } from "../../../../../ui/dialog/color/types";

export interface ITemplateProps {
    side: Side;
    type: string;
}

export interface IConnectorPinProps {
    side: Side;
    pinIndex: number;
    disabled?: boolean;
}

export interface IConnectorPinDrawingProps {
    fill: string;
    fillOpacity: number;
    stroke: string;
    strokeDashArray: string;
    text: string;
    textColor: string;
}

export const initialConnectorPinDrawingProps: IConnectorPinDrawingProps = {
    fill: "#000000",
    fillOpacity: 0,
    stroke: "#000000",
    text: "",
    textColor: "#000000",
    strokeDashArray: ""
}

export const getFillOpacity = (color: IColor) => {
    switch (color) {
        case White:
            return 0.65;
        default:
            return 0.2
    }
}