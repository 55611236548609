import React from 'react';
import { useTimeOutDialog } from './hooks';
import { Dialog } from '@orbit/dialog';

export const TimeOutDialog: React.FC = () => {
    const { dialogProps } = useTimeOutDialog();
    if (!dialogProps.open) {
        return null;
    }
    return <Dialog {...dialogProps} />
}