import { IPolarityState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFiberMapData } from "../fiber-map/types";
import { IHighlightedConnector } from "../../../pixi/cable/breakout/connector-furcation/highlight/types";

export const showPolaritySchemesAction = (state: IPolarityState, action: PayloadAction<boolean>) => {
    state.showPolaritySchemes = action.payload;
    if (action.payload) {
        state.showConnectorAssignment = false;
        state.showFiberMapping = false;
        state.showPropagation = false;
    }
}

export const showConnectorAssignmentAction = (state: IPolarityState, action: PayloadAction<boolean>) => {
    state.showConnectorAssignment = action.payload;
    if (action.payload) {
        state.showPolaritySchemes = false;
        state.showFiberMapping = false;
        state.showPropagation = false;
    }
}

export const showFiberMappingAction = (state: IPolarityState, action: PayloadAction<boolean>) => {
    state.showFiberMapping = action.payload;
    if (action.payload) {
        state.showPolaritySchemes = false;
        state.showConnectorAssignment = false;
        state.showPropagation = false;
    }
}

export const setShowPropagationAction = (state: IPolarityState, action: PayloadAction<boolean>) => {
    state.showPropagation = action.payload;
    if (action.payload) {
        state.showPolaritySchemes = false;
        state.showFiberMapping = false;
    }
}

export const setFiberMapsAction = (state: IPolarityState, action: PayloadAction<IFiberMapData[]>) => {
    state.predefinedFiberMaps = action.payload;
}

export const setUserFiberMapsAction = (state: IPolarityState, action: PayloadAction<IFiberMapData[]>) => {
    state.userFiberMaps = action.payload;
}

export const updateUserFiberMapAction = (state: IPolarityState, action: PayloadAction<IFiberMapData>) => {
    const userFiberMap = action.payload;
    if (userFiberMap.id) {
        const index = state.userFiberMaps.findIndex(f => f.id === userFiberMap.id);
        if (index < 0) {
            state.userFiberMaps.push(userFiberMap);
        } else {
            state.userFiberMaps[index] = { ...userFiberMap };
        }
    }
}

export const deleteUserFiberMapAction = (state: IPolarityState, action: PayloadAction<number>) => {
    state.userFiberMaps = state.userFiberMaps.filter(f => f.id !== action.payload);
}

export const setHighlightedConnectorsAction = (state: IPolarityState, action: PayloadAction<IHighlightedConnector[]>) => {
    state.highlightedConnectors = action.payload;
}

export const setForceUpdateAction = (state: IPolarityState, action: PayloadAction<boolean>) => {
    state.forceUpdate = action.payload;
}