import React from 'react';
import { Overlay } from './overlay/Overlay';
import { Offscreen } from './offscreen/Offscreen';
import { PixiComponent } from '../pixi/PixiComponent';

import './Workspace.scss'

export const Workspace: React.FC = () => {
    return (
        <div className="workspace">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <Offscreen />
            <PixiComponent />
            <Overlay />
        </div>
    )
} 