import React from "react";
import { ILegRowProps } from "./types";
import { useLegRow } from "./hooks";
import { TableCell, TableRow } from "@mui/material";
import { Checkbox } from "@orbit/checkbox";
import { EditableCell } from "../../../../../ui/table/cell/EditableCell";
import { ColorChip } from "../../../../../ui/dialog/color/chip/ColorChip";
import { Typography } from "@orbit/theme-provider";

export const LegRow : React.FC<ILegRowProps> = (props) => {
    const { data, onFocus, checkboxProps, editableLegLengthProps, editableLegLabelProps } = useLegRow(props);
    return (
        <TableRow onFocus={onFocus}>
            <TableCell align="center" className="leg-checkbox">
                <Checkbox {...checkboxProps} />
            </TableCell>
            <TableCell align="right" component="th" scope="row">
                <Typography variant="body2" label={`${data.connectorPosition}`} />
            </TableCell>
            <TableCell align="right">
                <Typography variant="body2" label={data.groupLabel} />
            </TableCell>
            <TableCell align="left">
                <ColorChip color={data.connectorColor} scaleMultiplier={1} />
            </TableCell>
            <EditableCell  {...editableLegLabelProps}/>
            <TableCell align="left">
                <ColorChip color={data.labelColor} scaleMultiplier={1} />
            </TableCell>
            <TableCell align="right">
                <Typography variant="body2" label={`${data.breakoutLength}`} />
            </TableCell>
            <EditableCell {...editableLegLengthProps} />
            <TableCell align="right">
                <Typography variant="body2" label={`${data.totalLength}`} />
            </TableCell>
        </TableRow>
    );
}