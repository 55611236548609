import { IBootColor } from "../../../ui/dialog/color/types";
import { Length } from "../length/types";

export const Sides = {
    SideA: "SideA",
    SideB: "SideB"
} as const;

export type Side = typeof Sides [keyof typeof Sides]

export const OffsetTypes = {
    Uniform: "Uniform",
    Custom: "Custom",
    None: "None"
} as const;

export type OffsetType = typeof OffsetTypes [keyof typeof OffsetTypes];

export interface IBreakout {
    id?: number;
    sideId?: number;
    label?: string; 
    position: number;
    jacketColor?: string;
    side: Side;
    trunk: ICableTrunk;
    furcation: ICableFurcation;
}

export type SideABreakout = Partial<IBreakout> & { side: typeof Sides.SideA }

export interface ICableFurcation {
    id?: number;
    breakoutId?: number;
    jacketColor?: string;
    groups: IConnectorGroup[];
}

export interface IConnectorGroup {
    id?: number;
    furcationId?: number;
    position: number;
    length: Length;
    offset?: Length;
    connectors: IConnector[];
}

export interface IConnector {
    id?: number;
    groupId?: number;
    position: number,
    color?: string;
    type: string;
    length?: Length;
    spare?: boolean;
    label?: string;
    labelColor?: string;
    bootColors?: IBootColor;
}

export interface IConnectorWithPositions extends IConnector {
    groupPosition: number;
    breakoutPosition: number;
    side: Side;
}

export interface ICableTrunk {
    id?: number;
    breakoutId?: number;
    length: Length;
}

export function getSpareDefaultText(connector: IConnector, sideSpares: IConnector[]) {
    const spareIndex = connector.id ? sideSpares.findIndex(s => s.id === connector.id) : -1;
    return spareIndex > -1 ? "SPARE " + (spareIndex + 1) : "SPARE";
}

export function toConnector(connectorWithPosition: IConnectorWithPositions) {
    const { id, groupId, position, color, type, length, spare, label, labelColor, bootColors } = connectorWithPosition;
    return { id, groupId, position, color, type, length, spare, label, labelColor, bootColors } as IConnector;
}

export function toConnectorWithPositions(side: Side, breakout: number, group: number, connector: IConnector) : IConnectorWithPositions {
    return { ...connector, breakoutPosition: breakout, groupPosition: group, side };
}