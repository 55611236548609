import { useSelector } from "react-redux";
import { loggedInSelector, statusSelector, userIdSelector } from "./selectors";
import { useCallback, useEffect } from "react";
import { setStatus } from "./reducer";
import { Status } from "./types";
import { loadUserAssemblies, updateAssembly, addAssembly } from "../assembly/store/reducer";
import { currentAssemblySelector } from "../assembly/store/selectors";
import { getPolarityFiberMaps, getUserFiberMaps } from "../polarity/store/reducer";
import { useStoreDispatch } from "../../store/hooks";
import { userUomSelector } from "./selectors";

export const useWorkspace = () => {
    const loggedIn = useSelector(loggedInSelector);
    const status = useSelector(statusSelector);
    const { loadAssemblies, loadFiberMaps } = useAssemblyManager();
    const dispatch = useStoreDispatch();
    const userId = useSelector(userIdSelector);
    
    useEffect(() => {
        if (loggedIn) {
            dispatch(setStatus(Status.InitialLoad));                             
        }
    }, [dispatch, loggedIn, userId]);

    useEffect(() => {
        if (status === Status.InitialLoad) {
            (async function executeLoadAssemblies() {
                await loadFiberMaps();
                await loadAssemblies();
            })();      
        }
    }, [dispatch, loadAssemblies, loadFiberMaps, status]);
}

export const useAssemblyManager = () => {
    const { loadAssemblies, loadFiberMaps } = useLoadManager();
    const { saveAssembly } = useSaveManager();

    return { loadAssemblies, loadFiberMaps, saveAssembly };
}

export const useLoadManager = () => {
    const userId = useSelector(userIdSelector);
    const userUOM = useSelector(userUomSelector);
    const dispatch = useStoreDispatch();

    const loadAssemblies = useCallback(async () => {
        dispatch(loadUserAssemblies(userId, userUOM));
    }, [dispatch, userId, userUOM]);

    const loadFiberMaps = useCallback(async () => {
        dispatch(getPolarityFiberMaps());
        dispatch(getUserFiberMaps(userId));
    }, [dispatch, userId]);

    return { loadAssemblies, loadFiberMaps };
}

export const useSaveManager = () => {
    const assembly = useSelector(currentAssemblySelector);
    const userId = useSelector(userIdSelector);
    const dispatch = useStoreDispatch();

    const saveAssembly = useCallback(async () => {
        if (assembly.id) {
            dispatch(setStatus(Status.Synchronizing));
            dispatch(updateAssembly(assembly, userId));
        } else {
            dispatch(setStatus(Status.Saving));
            dispatch(addAssembly(assembly, userId));
        }
    }, [assembly, dispatch, userId]);

    return { saveAssembly };
}