import React from 'react';
import { IFiberMappingConnectorProps } from './types';
import { useFiberMappingConnector } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { LC, LC_SIMPLEX, LC_UPC, SC_SIMPLEX, SC_UPC, SN, MDC, LSH, SC_APC_SIMPLEX, SC_UPC_SIMPLEX, SC_APC, LC_APC, LC_APC_UNIBOOT, LC_APC_SIMPLEX, MMC16F_NP, MMC16F_P, MMC24F_NP, MMC24F_P } from '../../../assembly/connector/types';
import { SimplexTemplate } from './templates/SimplexTemplate';
import { LCTemplate } from './templates/LCTemplate';
import { MTPTemplate } from './templates/MTPTemplate';
import { MMCTemplate } from './templates/MMCTemplate';
import './FiberMappingConnector.scss';


export const FiberMappingConnector: React.FC<IFiberMappingConnectorProps> = (props) => {
    const { previousProps, nextProps } = useFiberMappingConnector(props)
    const template = getTemplate(props);
    return (
        <div className="connector-container">
            <IconButton {...previousProps} icon={<ChevronLeft />} />
            {template}
            <IconButton {...nextProps} icon={<ChevronRight />} />
        </div>
    )
}

export function getTemplate(props: IFiberMappingConnectorProps): JSX.Element {
    const { type } = props;
    switch (type) {
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
        case LSH:
            return <SimplexTemplate {...props} />
        case LC:
        case LC_APC_UNIBOOT:
        case LC_APC:
        case LC_UPC:
        case SC_APC:
        case SC_UPC:
        case SN:
        case MDC:
            return <LCTemplate {...props} />
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
            return <MMCTemplate {...props}/>
        default:
            return <MTPTemplate {...props} />
    }
}