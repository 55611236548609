import { getConnectorLabel, getConnectorLabelColor, getConnectorTypeColors, getDefaultConnectorColor } from "../../../pixi/cable/breakout/connector-furcation/connector-sprite/types";
import { LengthPositions } from "../../../pixi/cable/position/types";
import { IConnectorAssignmentMap } from "../../polarity/connector-assignment/reducer/types";
import { IConnectorWithPositions } from "../breakout/types";
import { IAssemblyInfo } from "../info/types";
import { Length } from "../length/types";
import { IAssembly, initialAssembly } from "../types";

export interface IAssemblyState {
    currentAssembly: IAssembly;
    assemblies: IAssemblyInfo[];
    loaded: boolean;
}

export type LengthPair = { [length: number]: Length[] }

export interface IAssemblyLengthIndexer {
    sideA: {
        lengthPairs: LengthPair,
        relativeIndices: LengthPositions
    },
    sideB: {
        lengthPairs: LengthPair,
        relativeIndices: LengthPositions
    }
}

export const initialAssemblyState: IAssemblyState = {
    currentAssembly: initialAssembly,
    assemblies: [],
    loaded: false
}

export interface ICSVSideInfo {
    breakouts: number,
    legs: number[],
    uniqueConnectorTypes: number,
    connectors: string[],
    stagger: boolean
}

export interface IPropagationSource {
    position: number;
    index: number;
    fibercount: number; 
    indices: number[]
    color?: string; 
    labelColor?: string; 
    label?: string;
}

export interface IPropagationDestination {
    position: number;
    index: number;
    fibercount: number;
    type: string;
};

export interface IPropagationMap {
    destination: IPropagationDestination;
    sources: IPropagationSource[];
};

export interface IPropagationAttributes { 
    propagateConnectorColor: boolean;
    propagateLabelColor: boolean;
    propagateLabelText: boolean;
};

export const getPropagationResults = (isValidPropagation: boolean, attributes: IPropagationAttributes) => {
    let propagatedTriggerColor: boolean | undefined = false;
    let propagatedLabelText: boolean | undefined = false;
    let propagatedLabelColor: boolean | undefined = false;
    if (isValidPropagation) {
        propagatedTriggerColor = attributes.propagateConnectorColor ? true : undefined;
        propagatedLabelColor = attributes.propagateConnectorColor ? true : undefined;
        propagatedLabelText = attributes.propagateLabelText ? true : undefined;
    }

    return { propagatedTriggerColor, propagatedLabelText, propagatedLabelColor };
}

export const getPropagationMaps = (assignment: IConnectorAssignmentMap, connectors: IConnectorWithPositions[][], payload: IPropagationAttributes) : IPropagationMap[] => {
    const { propagateConnectorColor, propagateLabelColor, propagateLabelText } = payload;
    const { sideAMapping, sideBMapping } = assignment.connectors;
    const { sourceIndices, destinationIndices } = assignment.fiberMapData;

    let start = 0;
    const sources: IPropagationSource[] = sideAMapping.map(s => {
        const position = s.breakoutPosition - 1;
        const index = s.index - 1;
        const connector = connectors[position][index];
        const color: string | undefined = propagateConnectorColor ? connector.color ?? getDefaultConnectorColor(connector.type).name : undefined;
        const labelColor: string | undefined = propagateLabelColor ? getConnectorLabelColor(connector): undefined;
        const label: string | undefined = propagateLabelText ? getConnectorLabel(connector) : undefined;
        const fibercount = (s.fiberCount === 8 ? 12 : s.fiberCount);
        const end = start + fibercount;
        const indices = sourceIndices.slice(start, end).map(s => s.index);
        start += fibercount;
        return { position, index, color, labelColor, label, fibercount: s.fiberCount, indices };
    });


    start = 0;
    const propagationMaps: IPropagationMap[] = sideBMapping.flatMap((s) => {
        const position = s.breakoutPosition - 1;
        const index = s.index - 1;
        const fibercount = (s.fiberCount === 8 ? 12 : s.fiberCount);
        const type = s.connectorType;
        const end = start + fibercount;
        const destinations = destinationIndices.slice(start, end).map(s => s.assignedIndex).filter(c => c >= 0); 
        start += fibercount;
        return { destination: { position, index, fibercount: s.fiberCount, type }, sources: sources.filter(source => compareIndices(source, destinations)) }
    });

    return propagationMaps;
};

export const toConnector = (propagation: IPropagationMap, connectors: IConnectorWithPositions[][]) : IConnectorWithPositions => {
    const { destination, sources } = propagation;
    const connector = connectors[destination.position][destination.index];
    const color = sources[0].color ?? connector.color;
    const labelColor = sources[0].labelColor ?? connector.labelColor;
    const label = sources[0].label ?? connector.label;
    return { ...connector, color, labelColor, label };
};

export const validatePropagationMaps = (propagationMaps: IPropagationMap[]) => {
    return propagationMaps.every(p => {
        if (p.sources.length === 0) return false;

        let color = false;
        if (p.sources.every((s, i, self) => self[0].color === s.color)) {
            const colors = getConnectorTypeColors(p.destination.type).map(c => c.name);
            color = p.sources[0].color ? colors.includes(p.sources[0].color) : true;
        }

        const labelColor = p.sources.every((s, i, self) => self[0].labelColor === s.labelColor);
        const label = p.sources.every((s, i, self) => self[0].label === s.label);

        return color && labelColor && label;
    });
}

export const compareIndices = (source: IPropagationSource, destinations: number[]) => {
    return source.indices.length > destinations.length ? destinations.every(d => d >= 0 ? source.indices.includes(d) : true) : source.indices.every(s => s >= 0 ? destinations.includes(s) : true);
}