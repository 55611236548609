import React from 'react';
import * as Pixi from 'pixi.js';
import { LineProps } from './types';
import { Graphics } from '@inlet/react-pixi';

export const Line: React.FC<LineProps> = (props) => {
    return <Graphics draw={(grfx: Pixi.Graphics) => drawLine(grfx, props)} />
}

const drawLine = (grfx: Pixi.Graphics, props: LineProps) => {
    const { coordinates, thickness, color, alpha } = props;
    const [ start, end ] = coordinates;

    grfx.clear();
    grfx.lineStyle(thickness, color, alpha);
    grfx.moveTo(start.x, start.y);
    grfx.lineTo(end.x, end.y);
}