import React from 'react';
import { IColorDialogProps } from './types';
import { useColorDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { ColorButton } from './ColorButton';

import './ColorDialog.scss';

export const ColorDialog: React.FC<IColorDialogProps> = (props) => {
    const { dialogProps, colors, currentColor, onColorButtonClick } = useColorDialog(props);
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="color-palette">
                { colors.map(c => <ColorButton key={`color-${c.name}`} color={c} selected={currentColor !== undefined && c === currentColor} onClick={onColorButtonClick} />) }
            </div>
        </Dialog>
    )
}