export const getEndAPullingGripSVGString = () => {
  return `
    <svg version="1.1" id="Group_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1387" height="400" viewBox="0 0 138.7 40" style="enable-background:new 0 0 138.7 40;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:none;}
        .st1{fill:none;stroke:#000000;stroke-width:1.25;stroke-miterlimit:10;}
        .st2{fill:#FFFFFF;}
        .st3{fill:none;stroke:#000000;}
        .st4{fill:url(#SVGID_00000178186522866225904790000001255626907099646342_);}
        .st5{fill:url(#SVGID_00000067203329338389643940000001395374174771701941_);}
        .st6{fill:none;stroke:#000000;stroke-miterlimit:10;}
        .st7{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
        .st8{enable-background:new    ;}
      </style>
      <pattern y="40" width="40" height="40" patternUnits="userSpaceOnUse" id="SVGID_0000002760466306437217793" viewBox="38.2 -80.4 40 40" style="overflow:visible;">
        <g>
          <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
          <g>
            <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
            <g>
              <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                  <line class="st1" x1="102.7" y1="-40.4" x2="62.7" y2="-0.4"/>
                  <line class="st1" x1="107.1" y1="-40.4" x2="67.1" y2="-0.4"/>
                  <line class="st1" x1="111.6" y1="-40.4" x2="71.6" y2="-0.4"/>
                  <line class="st1" x1="116" y1="-40.4" x2="76" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-40.4" x2="116" y2="-0.4"/>
                  <line class="st1" x1="71.6" y1="-40.4" x2="111.6" y2="-0.4"/>
                  <line class="st1" x1="67.1" y1="-40.4" x2="107.1" y2="-0.4"/>
                  <line class="st1" x1="62.7" y1="-40.4" x2="102.7" y2="-0.4"/>
                  <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-0.4"/>
                  <line class="st1" x1="62.7" y1="-40.4" x2="22.7" y2="-0.4"/>
                  <line class="st1" x1="67.1" y1="-40.4" x2="27.1" y2="-0.4"/>
                  <line class="st1" x1="71.6" y1="-40.4" x2="31.6" y2="-0.4"/>
                  <line class="st1" x1="76" y1="-40.4" x2="36" y2="-0.4"/>
                  <line class="st1" x1="80.4" y1="-40.4" x2="40.4" y2="-0.4"/>
                  <line class="st1" x1="84.9" y1="-40.4" x2="44.9" y2="-0.4"/>
                  <line class="st1" x1="89.3" y1="-40.4" x2="49.3" y2="-0.4"/>
                  <line class="st1" x1="93.8" y1="-40.4" x2="53.8" y2="-0.4"/>
                  <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-0.4"/>
                  <line class="st1" x1="53.8" y1="-40.4" x2="93.8" y2="-0.4"/>
                  <line class="st1" x1="49.3" y1="-40.4" x2="89.3" y2="-0.4"/>
                  <line class="st1" x1="44.9" y1="-40.4" x2="84.9" y2="-0.4"/>
                  <line class="st1" x1="40.4" y1="-40.4" x2="80.4" y2="-0.4"/>
                  <line class="st1" x1="36" y1="-40.4" x2="76" y2="-0.4"/>
                  <line class="st1" x1="31.6" y1="-40.4" x2="71.6" y2="-0.4"/>
                  <line class="st1" x1="27.1" y1="-40.4" x2="67.1" y2="-0.4"/>
                  <line class="st1" x1="22.7" y1="-40.4" x2="62.7" y2="-0.4"/>
                  <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-40.4" x2="0.4" y2="-0.4"/>
                  <line class="st1" x1="44.9" y1="-40.4" x2="4.9" y2="-0.4"/>
                  <line class="st1" x1="49.3" y1="-40.4" x2="9.3" y2="-0.4"/>
                  <line class="st1" x1="53.8" y1="-40.4" x2="13.8" y2="-0.4"/>
                  <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                  <line class="st1" x1="13.8" y1="-40.4" x2="53.8" y2="-0.4"/>
                  <line class="st1" x1="9.3" y1="-40.4" x2="49.3" y2="-0.4"/>
                  <line class="st1" x1="4.9" y1="-40.4" x2="44.9" y2="-0.4"/>
                  <line class="st1" x1="0.4" y1="-40.4" x2="40.4" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                  <line class="st1" x1="102.7" y1="-80.4" x2="62.7" y2="-40.4"/>
                  <line class="st1" x1="107.1" y1="-80.4" x2="67.1" y2="-40.4"/>
                  <line class="st1" x1="111.6" y1="-80.4" x2="71.6" y2="-40.4"/>
                  <line class="st1" x1="116" y1="-80.4" x2="76" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-80.4" x2="116" y2="-40.4"/>
                  <line class="st1" x1="71.6" y1="-80.4" x2="111.6" y2="-40.4"/>
                  <line class="st1" x1="67.1" y1="-80.4" x2="107.1" y2="-40.4"/>
                  <line class="st1" x1="62.7" y1="-80.4" x2="102.7" y2="-40.4"/>
                  <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-40.4"/>
                  <line class="st1" x1="62.7" y1="-80.4" x2="22.7" y2="-40.4"/>
                  <line class="st1" x1="67.1" y1="-80.4" x2="27.1" y2="-40.4"/>
                  <line class="st1" x1="71.6" y1="-80.4" x2="31.6" y2="-40.4"/>
                  <line class="st1" x1="76" y1="-80.4" x2="36" y2="-40.4"/>
                  <line class="st1" x1="80.4" y1="-80.4" x2="40.4" y2="-40.4"/>
                  <line class="st1" x1="84.9" y1="-80.4" x2="44.9" y2="-40.4"/>
                  <line class="st1" x1="89.3" y1="-80.4" x2="49.3" y2="-40.4"/>
                  <line class="st1" x1="93.8" y1="-80.4" x2="53.8" y2="-40.4"/>
                  <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-40.4"/>
                  <line class="st1" x1="53.8" y1="-80.4" x2="93.8" y2="-40.4"/>
                  <line class="st1" x1="49.3" y1="-80.4" x2="89.3" y2="-40.4"/>
                  <line class="st1" x1="44.9" y1="-80.4" x2="84.9" y2="-40.4"/>
                  <line class="st1" x1="40.4" y1="-80.4" x2="80.4" y2="-40.4"/>
                  <line class="st1" x1="36" y1="-80.4" x2="76" y2="-40.4"/>
                  <line class="st1" x1="31.6" y1="-80.4" x2="71.6" y2="-40.4"/>
                  <line class="st1" x1="27.1" y1="-80.4" x2="67.1" y2="-40.4"/>
                  <line class="st1" x1="22.7" y1="-80.4" x2="62.7" y2="-40.4"/>
                  <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-80.4" x2="0.4" y2="-40.4"/>
                  <line class="st1" x1="44.9" y1="-80.4" x2="4.9" y2="-40.4"/>
                  <line class="st1" x1="49.3" y1="-80.4" x2="9.3" y2="-40.4"/>
                  <line class="st1" x1="53.8" y1="-80.4" x2="13.8" y2="-40.4"/>
                  <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                  <line class="st1" x1="13.8" y1="-80.4" x2="53.8" y2="-40.4"/>
                  <line class="st1" x1="9.3" y1="-80.4" x2="49.3" y2="-40.4"/>
                  <line class="st1" x1="4.9" y1="-80.4" x2="44.9" y2="-40.4"/>
                  <line class="st1" x1="0.4" y1="-80.4" x2="40.4" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                  <line class="st1" x1="102.7" y1="-120.4" x2="62.7" y2="-80.4"/>
                  <line class="st1" x1="107.1" y1="-120.4" x2="67.1" y2="-80.4"/>
                  <line class="st1" x1="111.6" y1="-120.4" x2="71.6" y2="-80.4"/>
                  <line class="st1" x1="116" y1="-120.4" x2="76" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-120.4" x2="116" y2="-80.4"/>
                  <line class="st1" x1="71.6" y1="-120.4" x2="111.6" y2="-80.4"/>
                  <line class="st1" x1="67.1" y1="-120.4" x2="107.1" y2="-80.4"/>
                  <line class="st1" x1="62.7" y1="-120.4" x2="102.7" y2="-80.4"/>
                  <line class="st1" x1="58.2" y1="-120.4" x2="98.2" y2="-80.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-120.4" x2="18.2" y2="-80.4"/>
                  <line class="st1" x1="62.7" y1="-120.4" x2="22.7" y2="-80.4"/>
                  <line class="st1" x1="67.1" y1="-120.4" x2="27.1" y2="-80.4"/>
                  <line class="st1" x1="71.6" y1="-120.4" x2="31.6" y2="-80.4"/>
                  <line class="st1" x1="76" y1="-120.4" x2="36" y2="-80.4"/>
                  <line class="st1" x1="80.4" y1="-120.4" x2="40.4" y2="-80.4"/>
                  <line class="st1" x1="84.9" y1="-120.4" x2="44.9" y2="-80.4"/>
                  <line class="st1" x1="89.3" y1="-120.4" x2="49.3" y2="-80.4"/>
                  <line class="st1" x1="93.8" y1="-120.4" x2="53.8" y2="-80.4"/>
                  <line class="st1" x1="98.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-120.4" x2="98.2" y2="-80.4"/>
                  <line class="st1" x1="53.8" y1="-120.4" x2="93.8" y2="-80.4"/>
                  <line class="st1" x1="49.3" y1="-120.4" x2="89.3" y2="-80.4"/>
                  <line class="st1" x1="44.9" y1="-120.4" x2="84.9" y2="-80.4"/>
                  <line class="st1" x1="40.4" y1="-120.4" x2="80.4" y2="-80.4"/>
                  <line class="st1" x1="36" y1="-120.4" x2="76" y2="-80.4"/>
                  <line class="st1" x1="31.6" y1="-120.4" x2="71.6" y2="-80.4"/>
                  <line class="st1" x1="27.1" y1="-120.4" x2="67.1" y2="-80.4"/>
                  <line class="st1" x1="22.7" y1="-120.4" x2="62.7" y2="-80.4"/>
                  <line class="st1" x1="18.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-120.4" x2="0.4" y2="-80.4"/>
                  <line class="st1" x1="44.9" y1="-120.4" x2="4.9" y2="-80.4"/>
                  <line class="st1" x1="49.3" y1="-120.4" x2="9.3" y2="-80.4"/>
                  <line class="st1" x1="53.8" y1="-120.4" x2="13.8" y2="-80.4"/>
                  <line class="st1" x1="58.2" y1="-120.4" x2="18.2" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                  <line class="st1" x1="13.8" y1="-120.4" x2="53.8" y2="-80.4"/>
                  <line class="st1" x1="9.3" y1="-120.4" x2="49.3" y2="-80.4"/>
                  <line class="st1" x1="4.9" y1="-120.4" x2="44.9" y2="-80.4"/>
                  <line class="st1" x1="0.4" y1="-120.4" x2="40.4" y2="-80.4"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </pattern>
      <pattern  y="40" width="40" height="40" patternUnits="userSpaceOnUse" id="SVGID_1_" viewBox="38.2 -80.4 40 40" style="overflow:visible;">
        <g>
          <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
          <g>
            <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
            <g>
              <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                  <line class="st1" x1="102.7" y1="-40.4" x2="62.7" y2="-0.4"/>
                  <line class="st1" x1="107.1" y1="-40.4" x2="67.1" y2="-0.4"/>
                  <line class="st1" x1="111.6" y1="-40.4" x2="71.6" y2="-0.4"/>
                  <line class="st1" x1="116" y1="-40.4" x2="76" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-40.4" x2="116" y2="-0.4"/>
                  <line class="st1" x1="71.6" y1="-40.4" x2="111.6" y2="-0.4"/>
                  <line class="st1" x1="67.1" y1="-40.4" x2="107.1" y2="-0.4"/>
                  <line class="st1" x1="62.7" y1="-40.4" x2="102.7" y2="-0.4"/>
                  <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-0.4"/>
                  <line class="st1" x1="62.7" y1="-40.4" x2="22.7" y2="-0.4"/>
                  <line class="st1" x1="67.1" y1="-40.4" x2="27.1" y2="-0.4"/>
                  <line class="st1" x1="71.6" y1="-40.4" x2="31.6" y2="-0.4"/>
                  <line class="st1" x1="76" y1="-40.4" x2="36" y2="-0.4"/>
                  <line class="st1" x1="80.4" y1="-40.4" x2="40.4" y2="-0.4"/>
                  <line class="st1" x1="84.9" y1="-40.4" x2="44.9" y2="-0.4"/>
                  <line class="st1" x1="89.3" y1="-40.4" x2="49.3" y2="-0.4"/>
                  <line class="st1" x1="93.8" y1="-40.4" x2="53.8" y2="-0.4"/>
                  <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-0.4"/>
                  <line class="st1" x1="53.8" y1="-40.4" x2="93.8" y2="-0.4"/>
                  <line class="st1" x1="49.3" y1="-40.4" x2="89.3" y2="-0.4"/>
                  <line class="st1" x1="44.9" y1="-40.4" x2="84.9" y2="-0.4"/>
                  <line class="st1" x1="40.4" y1="-40.4" x2="80.4" y2="-0.4"/>
                  <line class="st1" x1="36" y1="-40.4" x2="76" y2="-0.4"/>
                  <line class="st1" x1="31.6" y1="-40.4" x2="71.6" y2="-0.4"/>
                  <line class="st1" x1="27.1" y1="-40.4" x2="67.1" y2="-0.4"/>
                  <line class="st1" x1="22.7" y1="-40.4" x2="62.7" y2="-0.4"/>
                  <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-40.4" x2="0.4" y2="-0.4"/>
                  <line class="st1" x1="44.9" y1="-40.4" x2="4.9" y2="-0.4"/>
                  <line class="st1" x1="49.3" y1="-40.4" x2="9.3" y2="-0.4"/>
                  <line class="st1" x1="53.8" y1="-40.4" x2="13.8" y2="-0.4"/>
                  <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-0.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-0.4"/>
                  <line class="st1" x1="13.8" y1="-40.4" x2="53.8" y2="-0.4"/>
                  <line class="st1" x1="9.3" y1="-40.4" x2="49.3" y2="-0.4"/>
                  <line class="st1" x1="4.9" y1="-40.4" x2="44.9" y2="-0.4"/>
                  <line class="st1" x1="0.4" y1="-40.4" x2="40.4" y2="-0.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                  <line class="st1" x1="102.7" y1="-80.4" x2="62.7" y2="-40.4"/>
                  <line class="st1" x1="107.1" y1="-80.4" x2="67.1" y2="-40.4"/>
                  <line class="st1" x1="111.6" y1="-80.4" x2="71.6" y2="-40.4"/>
                  <line class="st1" x1="116" y1="-80.4" x2="76" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-80.4" x2="116" y2="-40.4"/>
                  <line class="st1" x1="71.6" y1="-80.4" x2="111.6" y2="-40.4"/>
                  <line class="st1" x1="67.1" y1="-80.4" x2="107.1" y2="-40.4"/>
                  <line class="st1" x1="62.7" y1="-80.4" x2="102.7" y2="-40.4"/>
                  <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-40.4"/>
                  <line class="st1" x1="62.7" y1="-80.4" x2="22.7" y2="-40.4"/>
                  <line class="st1" x1="67.1" y1="-80.4" x2="27.1" y2="-40.4"/>
                  <line class="st1" x1="71.6" y1="-80.4" x2="31.6" y2="-40.4"/>
                  <line class="st1" x1="76" y1="-80.4" x2="36" y2="-40.4"/>
                  <line class="st1" x1="80.4" y1="-80.4" x2="40.4" y2="-40.4"/>
                  <line class="st1" x1="84.9" y1="-80.4" x2="44.9" y2="-40.4"/>
                  <line class="st1" x1="89.3" y1="-80.4" x2="49.3" y2="-40.4"/>
                  <line class="st1" x1="93.8" y1="-80.4" x2="53.8" y2="-40.4"/>
                  <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-40.4"/>
                  <line class="st1" x1="53.8" y1="-80.4" x2="93.8" y2="-40.4"/>
                  <line class="st1" x1="49.3" y1="-80.4" x2="89.3" y2="-40.4"/>
                  <line class="st1" x1="44.9" y1="-80.4" x2="84.9" y2="-40.4"/>
                  <line class="st1" x1="40.4" y1="-80.4" x2="80.4" y2="-40.4"/>
                  <line class="st1" x1="36" y1="-80.4" x2="76" y2="-40.4"/>
                  <line class="st1" x1="31.6" y1="-80.4" x2="71.6" y2="-40.4"/>
                  <line class="st1" x1="27.1" y1="-80.4" x2="67.1" y2="-40.4"/>
                  <line class="st1" x1="22.7" y1="-80.4" x2="62.7" y2="-40.4"/>
                  <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-80.4" x2="0.4" y2="-40.4"/>
                  <line class="st1" x1="44.9" y1="-80.4" x2="4.9" y2="-40.4"/>
                  <line class="st1" x1="49.3" y1="-80.4" x2="9.3" y2="-40.4"/>
                  <line class="st1" x1="53.8" y1="-80.4" x2="13.8" y2="-40.4"/>
                  <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-40.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-40.4"/>
                  <line class="st1" x1="13.8" y1="-80.4" x2="53.8" y2="-40.4"/>
                  <line class="st1" x1="9.3" y1="-80.4" x2="49.3" y2="-40.4"/>
                  <line class="st1" x1="4.9" y1="-80.4" x2="44.9" y2="-40.4"/>
                  <line class="st1" x1="0.4" y1="-80.4" x2="40.4" y2="-40.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="98.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                  <line class="st1" x1="102.7" y1="-120.4" x2="62.7" y2="-80.4"/>
                  <line class="st1" x1="107.1" y1="-120.4" x2="67.1" y2="-80.4"/>
                  <line class="st1" x1="111.6" y1="-120.4" x2="71.6" y2="-80.4"/>
                  <line class="st1" x1="116" y1="-120.4" x2="76" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="76" y1="-120.4" x2="116" y2="-80.4"/>
                  <line class="st1" x1="71.6" y1="-120.4" x2="111.6" y2="-80.4"/>
                  <line class="st1" x1="67.1" y1="-120.4" x2="107.1" y2="-80.4"/>
                  <line class="st1" x1="62.7" y1="-120.4" x2="102.7" y2="-80.4"/>
                  <line class="st1" x1="58.2" y1="-120.4" x2="98.2" y2="-80.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="58.2" y1="-120.4" x2="18.2" y2="-80.4"/>
                  <line class="st1" x1="62.7" y1="-120.4" x2="22.7" y2="-80.4"/>
                  <line class="st1" x1="67.1" y1="-120.4" x2="27.1" y2="-80.4"/>
                  <line class="st1" x1="71.6" y1="-120.4" x2="31.6" y2="-80.4"/>
                  <line class="st1" x1="76" y1="-120.4" x2="36" y2="-80.4"/>
                  <line class="st1" x1="80.4" y1="-120.4" x2="40.4" y2="-80.4"/>
                  <line class="st1" x1="84.9" y1="-120.4" x2="44.9" y2="-80.4"/>
                  <line class="st1" x1="89.3" y1="-120.4" x2="49.3" y2="-80.4"/>
                  <line class="st1" x1="93.8" y1="-120.4" x2="53.8" y2="-80.4"/>
                  <line class="st1" x1="98.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="58.2" y1="-120.4" x2="98.2" y2="-80.4"/>
                  <line class="st1" x1="53.8" y1="-120.4" x2="93.8" y2="-80.4"/>
                  <line class="st1" x1="49.3" y1="-120.4" x2="89.3" y2="-80.4"/>
                  <line class="st1" x1="44.9" y1="-120.4" x2="84.9" y2="-80.4"/>
                  <line class="st1" x1="40.4" y1="-120.4" x2="80.4" y2="-80.4"/>
                  <line class="st1" x1="36" y1="-120.4" x2="76" y2="-80.4"/>
                  <line class="st1" x1="31.6" y1="-120.4" x2="71.6" y2="-80.4"/>
                  <line class="st1" x1="27.1" y1="-120.4" x2="67.1" y2="-80.4"/>
                  <line class="st1" x1="22.7" y1="-120.4" x2="62.7" y2="-80.4"/>
                  <line class="st1" x1="18.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                </g>
              </g>
              <g>
                <g>
                  <line class="st1" x1="40.4" y1="-120.4" x2="0.4" y2="-80.4"/>
                  <line class="st1" x1="44.9" y1="-120.4" x2="4.9" y2="-80.4"/>
                  <line class="st1" x1="49.3" y1="-120.4" x2="9.3" y2="-80.4"/>
                  <line class="st1" x1="53.8" y1="-120.4" x2="13.8" y2="-80.4"/>
                  <line class="st1" x1="58.2" y1="-120.4" x2="18.2" y2="-80.4"/>
                </g>
                <g>
                  <line class="st1" x1="18.2" y1="-120.4" x2="58.2" y2="-80.4"/>
                  <line class="st1" x1="13.8" y1="-120.4" x2="53.8" y2="-80.4"/>
                  <line class="st1" x1="9.3" y1="-120.4" x2="49.3" y2="-80.4"/>
                  <line class="st1" x1="4.9" y1="-120.4" x2="44.9" y2="-80.4"/>
                  <line class="st1" x1="0.4" y1="-120.4" x2="40.4" y2="-80.4"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </pattern>
      <g>
        <g>
          <path class="st2" d="M6.5,32.1l-1.7-9.9c-0.2-1.4-0.2-2.8-0.1-3.9C5,15,5.1,12.8,4.8,9.9C4.6,8.8,4.4,7.7,4.1,6.7l0,0
            C3.9,5.8,4.2,4.8,5,4.2c1.3-1,3.8-2.5,8.2-3.2c4.7-0.8,7.4,0,8.8,0.7c0.7,0.4,1.2,1,1.4,1.7l0,0c0.4,1.4,1,4.2,3,6.4
            c0.9,1,1.5,2.2,1.7,3.4l1,5.9L6.5,32.1z"/>
          <path d="M13,0.3C8.5,1.1,5.9,2.5,4.5,3.6C3.5,4.4,3,5.7,3.4,6.8v0.1c0.3,1,0.5,2,0.6,3c0.3,2.8,0.2,5-0.1,8.2
            c-0.2,1.4-0.1,2.8,0.1,4.1l1.7,9.9c0.1,0.4,0.4,0.6,0.8,0.6l0,0c0.4-0.1,0.6-0.4,0.6-0.8L5.4,22c-0.2-1.2-0.2-2.5-0.1-3.8
            c0.3-3.4,0.4-5.6,0.1-8.5c-0.1-1-0.3-2.1-0.6-3.2V6.4c-0.2-0.5,0-1.2,0.6-1.7c1.2-1,3.6-2.3,7.8-3h0.1c4.7-0.8,7.2,0.1,8.4,0.6
            c0.5,0.3,0.9,0.7,1,1.2v0.1c0,0.2,0.1,0.3,0.2,0.5c0.4,1.5,1.2,4.1,3,6.1c0.8,0.9,1.4,2,1.5,3.1l1,5.9c0.1,0.4,0.4,0.6,0.8,0.6
            s0.6-0.4,0.6-0.8l-1-5.9c-0.2-1.4-0.9-2.7-1.9-3.8c-1.6-1.8-2.3-4.1-2.7-5.6c0-0.2-0.1-0.4-0.1-0.5c-0.2-1-0.8-1.8-1.7-2.2
            c-1.6-0.8-4.5-1.6-9.2-0.8L13,0.3L13,0.3z"/>
        </g>
        <path d="M13.2,11.3c-0.4,0.1-0.6,0.4-0.6,0.8c0.8,4.8,2.5,8.1,5.1,9.9c2.4,1.6,4.6,1.3,4.6,1.2c0.4-0.1,0.6-0.4,0.6-0.8
          s-0.4-0.6-0.8-0.6l0,0c-0.3,0-6.2,0.9-8.1-10C14,11.5,13.6,11.3,13.2,11.3L13.2,11.3z"/>
      </g>
      <g id="Group_1552">
        <g id="Rectangle_1343">
          <rect x="99.6" y="27.7" class="st2" width="39" height="6.4"/>
          <rect x="99.6" y="28.1" class="st3" width="38.6" height="5.6"/>
        </g>
        <g id="Path_542">
          
            <pattern  id="SVGID_00000166649232296798257590000002642949480599703176_" xlink:href="#SVGID_1_" patternTransform="matrix(1 0 0 1 -18.3999 16502.4844)">
          </pattern>
          <polygon style="fill:url(#SVGID_00000166649232296798257590000002642949480599703176_);" points="38.5,31.2 46,23.8 95.8,23.8 
            99.6,27.6 99.6,34.5 95.8,38.4 45,38.4 		"/>
          
            <pattern  id="SVGID_00000181782947145345850930000011705330861308772495_" xlink:href="#SVGID_0000002760466306437217793" patternTransform="matrix(1 0 0 1 -28.3999 16502.4844)">
          </pattern>
          <polygon style="fill:url(#SVGID_00000181782947145345850930000011705330861308772495_);" points="38.5,31.2 46,23.8 95.8,23.8 
            99.6,27.6 99.6,34.5 95.8,38.4 45,38.4 		"/>
          <polygon class="st6" points="38.5,31.2 46,23.8 95.8,23.8 99.6,27.6 99.6,34.5 95.8,38.4 45,38.4 		"/>
        </g>
        <rect id="Rectangle_1342" x="109" y="26.1" width="6.4" height="9.6"/>
      </g>
      <path class="st7" d="M38.5,30.9l-6.8,1.3c-0.9,0.4-9.9,0.6-10.9,0.6H4.4C2.5,32.8,1,32.9,1,31l0,0c0-1.9,1.5-1.8,3.4-1.8h16.5
        c1,0,10,0.2,10.9,0.6l6.8,1.4"/>
      <g>
        <g>
          <path class="st2" d="M19,22.5L19,22.5c-1.6,0.3-2.5,1.8-2.3,3.2l1.3,7.5l0.3,1.5l0.2,1.2c0.3,1.5,1.7,2.6,3.3,2.3s2.6-1.7,2.3-3.2
            l-1.8-10.3C22.1,23.3,20.6,22.2,19,22.5C19.1,22.5,19.1,22.5,19,22.5L19,22.5z"/>
          <path d="M19,21.8c1.9-0.3,3.7,1,4,2.9L24.8,35c0.3,1.9-1,3.7-2.9,4s-3.8-1-4.1-2.9L16,25.9c-0.3-1.9,0.9-3.7,2.8-4.1H19L19,21.8
            L19,21.8z M23.4,35.2l-1.8-10.3c-0.2-1.1-1.3-1.9-2.4-1.8h-0.1c-1.1,0.2-1.9,1.3-1.7,2.4l1.8,10.3c0.2,1.2,1.3,1.9,2.5,1.7
            S23.5,36.4,23.4,35.2L23.4,35.2z"/>
        </g>
        <g>
          <path class="st2" d="M16.2,39.3c-1.6,0.3-3-0.8-3.3-2.3l-1.8-10.3c-0.3-1.5,0.8-3,2.3-3.2l0,0c1.6-0.3,3,0.8,3.3,2.3L18.5,36
            C18.8,37.5,17.7,39,16.2,39.3L16.2,39.3z"/>
          <path d="M17.4,25.6l1.8,10.3c0.3,1.9-1,3.7-2.9,4s-3.8-1-4.1-2.9l-1.8-10.3c-0.3-1.9,1-3.7,2.9-4S17.1,23.7,17.4,25.6z M17.8,36.1
            L16,25.8c-0.2-1.1-1.3-1.9-2.5-1.7s-1.9,1.3-1.8,2.4l1.8,10.3c0.2,1.2,1.3,1.9,2.5,1.7S18,37.3,17.8,36.1z"/>
        </g>
        <g>
          <path class="st2" d="M27.4,37.4c-1.5,0.3-3-0.8-3.3-2.3l-1.8-10.3c-0.3-1.5,0.8-3,2.3-3.2l0,0c1.5-0.3,3,0.8,3.3,2.3l1.8,10.3
            C29.9,35.7,28.9,37.1,27.4,37.4L27.4,37.4z"/>
          <path d="M28.6,23.8L30.4,34c0.3,1.9-1,3.7-2.9,4s-3.8-1-4.1-2.9l-1.8-10.3c-0.3-1.9,1-3.7,2.9-4S28.3,21.8,28.6,23.8z M29,34.3
            L27.2,24c-0.2-1.1-1.3-1.9-2.5-1.7s-2,1.3-1.8,2.4L24.8,35c0.2,1.2,1.3,1.9,2.5,1.7S29.2,35.4,29,34.3z"/>
        </g>
        <g>
          <path class="st2" d="M26.2,27.8L18.9,29c-2,0.3-3.3-0.8-3.6-2.5c-0.2-1.2,0.5-2.9,2.7-3.3l4.2-0.7l2.5-2.8l4.3-0.6
            C29.9,23.9,30.9,27,26.2,27.8L26.2,27.8z"/>
          <path d="M24.7,19.1c-0.1,0-0.3,0.1-0.4,0.2l-2.4,2.6L18,22.5c-2.5,0.4-3.6,2.4-3.3,4.1c0.4,2.2,2.1,3.5,4.4,3.1l7.2-1.2
            c1.7-0.3,2.8-0.9,3.5-1.8c1.1-1.6,0.7-3.8,0.2-6.6c-0.1-0.3-0.1-0.6-0.2-1s-0.4-0.6-0.8-0.6l0,0c-0.4,0.1-0.6,0.4-0.6,0.8
            c0.1,0.3,0.1,0.7,0.2,1c0.5,2.5,0.8,4.5,0,5.6c-0.4,0.6-1.3,1-2.6,1.2l-7.2,1.2c-1.5,0.2-2.5-0.5-2.8-1.9
            c-0.1-0.8,0.3-2.2,2.2-2.5l4.2-0.7c0.2,0,0.3-0.1,0.4-0.2l2.5-2.8c0.3-0.3,0.2-0.7,0-1C25.1,19.1,24.9,19,24.7,19.1L24.7,19.1z"/>
        </g>
        <g>
          <path class="st2" d="M8.3,26.6c-1.5,0.2-2.5,1.7-2.3,3.2l1.2,6.8c0.2,1.5,1.7,2.5,3.2,2.2l0,0c1.5-0.2,2.5-1.7,2.3-3.2l-1.2-6.8
            C11.3,27.4,9.9,26.4,8.3,26.6L8.3,26.6z"/>
          <path d="M12.2,28.8l1.2,6.8c0.3,1.9-1,3.7-2.9,4s-3.7-1-4-2.8L5.3,30c-0.3-1.9,1-3.6,2.9-4S11.9,26.9,12.2,28.8z M12,35.8L10.8,29
            c-0.2-1.1-1.3-1.9-2.4-1.7s-1.9,1.2-1.7,2.4l1.2,6.8c0.2,1.1,1.3,1.9,2.4,1.7S12.2,36.9,12,35.8z"/>
        </g>
      </g>
      <g>
        <g class="st8">
          <path d="M36.5,16V5.2c0,0,1.5-0.1,2.8-0.1c2.8,0,4.1,1.5,4.1,3.4c0,2.2-1.6,3.8-4.3,3.8c-0.6,0-1.1,0-1.1,0V16H36.5z M38,11
            c0,0,0.6,0.1,1.2,0.1c1.8,0,2.7-1,2.7-2.4s-0.8-2.4-2.8-2.4c-0.6,0-1.2,0-1.2,0L38,11L38,11z"/>
          <path d="M50.4,16c0-0.5,0-1.2,0.1-1.7l0,0c-0.5,1.1-1.4,1.8-2.8,1.8c-1.8,0-2.4-1.1-2.4-2.5V8h1.4v5.1c0,1.1,0.3,1.8,1.4,1.8
            c1.3,0,2.3-1.3,2.3-3.1V8h1.4v8H50.4z"/>
          <path d="M54.5,16V4.3H56V16H54.5z"/>
          <path d="M58.7,16V4.3h1.4V16H58.7z"/>
          <path d="M63.6,6.5c-0.5,0-1-0.4-1-0.9s0.4-0.9,1-0.9c0.5,0,1,0.4,1,0.9C64.5,6,64.1,6.5,63.6,6.5z M62.8,16V8h1.4v8H62.8z"/>
          <path d="M72,16v-5.1c0-1.1-0.3-1.8-1.4-1.8c-1.3,0-2.3,1.3-2.3,3.1V16H67V8h1.4c0,0.5,0,1.2-0.1,1.7l0,0c0.5-1.1,1.4-1.8,2.8-1.8
            c1.8,0,2.4,1.1,2.4,2.6V16H72z"/>
          <path d="M81.5,9.2c0.4,0.4,0.5,0.9,0.5,1.4c0,1.7-1.1,3-3.2,3c-0.3,0-0.6,0-0.8-0.1c-0.3,0.2-0.5,0.5-0.5,0.7
            c0,0.3,0.3,0.6,1.2,0.6H80c1.9,0.1,2.9,0.9,2.9,2.3c0,1.7-1.6,3-4.1,3c-2.1,0-3.4-0.8-3.4-2.4c0-0.9,0.6-1.7,1.4-2.2
            c-0.5-0.3-0.7-0.7-0.7-1.2s0.3-1,0.9-1.4c-0.8-0.5-1.2-1.3-1.2-2.2c0-1.7,1.1-3,3.3-3c0.6,0,1.1,0.1,1.6,0.1h2.2v1.1h-1.4V9.2z
             M77.9,15.9c-0.7,0.5-1.1,1-1.1,1.6c0,1,0.8,1.5,2.1,1.5c1.6,0,2.4-0.7,2.4-1.7c0-1.3-1.4-1.3-3.1-1.4
            C78.2,15.9,78.1,15.9,77.9,15.9z M78.9,9c-1.1,0-1.8,0.7-1.8,1.8s0.7,1.8,1.8,1.8c1.1,0,1.8-0.7,1.8-1.8S80,9,78.9,9z"/>
          <path d="M96.5,15.5c-1.1,0.4-2.4,0.6-3.6,0.6c-3.4,0-5.4-2-5.4-5.3c0-3.4,2-5.8,5.8-5.8c1.1,0,2.1,0.2,3,0.5L96,6.8
            c-0.9-0.3-1.8-0.5-2.8-0.5c-2.9,0-4.1,2-4.1,4.3c0,2.7,1.5,4.2,4,4.2c0.7,0,1.3-0.1,1.9-0.3v-4.1h1.5V15.5z"/>
          <path d="M103,9.3c-1.7-0.3-2.5,0.9-2.5,3.5V16h-1.4V8h1.4c0,0.5,0,1.2-0.2,1.9l0,0c0.3-1.1,1.2-2.2,2.7-2V9.3z"/>
          <path d="M105.5,6.5c-0.5,0-1-0.4-1-0.9s0.4-0.9,1-0.9s1,0.4,1,0.9C106.5,6,106,6.5,105.5,6.5z M104.8,16V8h1.4v8H104.8z"/>
          <path d="M109,20V8h1.4c0,0.4,0,1.2-0.1,1.6l0,0c0.4-1.1,1.4-1.8,2.7-1.8c1.9,0,2.9,1.3,2.9,3.7c0,2.8-1.5,4.4-4.1,4.4
            c-0.5,0-0.9,0-1.3-0.1v4H109V20z M110.4,14.8c0.4,0.1,0.9,0.2,1.4,0.2c1.5,0,2.5-0.9,2.5-3.2c0-1.7-0.6-2.7-1.8-2.7
            c-1.4,0-2.2,1.7-2.2,3.1L110.4,14.8L110.4,14.8z"/>
        </g>
      </g>
    </svg>    
  `
}