import AtoA from '../resources/Type A-A LC-LC.png';
import AtoB from '../resources/Type A-B LC-LC.png';
import TypeUMTP8LC from '../resources/Type-U MTP-LC 8f.png';
import TypeUMTP12LC from '../resources/Type U MTP_LC 12f Breakout.png';
import TypeAMTP24LC from '../resources/Type A MTP_LC 24f Breakout.png';
import TypeBMTP8 from '../resources/Type B MTP_MTP 8f.png';
import TypeAMTP8 from '../resources/Type A MTP_MTP 8f.png';
import Mesh4x4 from '../resources/Type 4x4 Mesh MTP_MTP.png';
import TypeBMTP12 from '../resources/Type B MTP_MTP 8f.png';
import TypeAMTP12 from '../resources/Type A MTP_MTP.png';
import TypeU2MTP8LC from "../resources/Type-U2 MTP8-LC.png"
import TypeBMTP12LC from "../resources/Type B MTP12_LC.png"
import TypeBMTP8LC from "../resources/Type B MTP8_LC.png"
import { IFiberMapData } from "../fiber-map/types";
import { IHighlightedConnector } from '../../../pixi/cable/breakout/connector-furcation/highlight/types';

export interface IPolarityMap {
    id?: number;
    key: number;
    description: string;
    from?: number[];
    to?: number[];
    imageUri?: string;
}

export const ATOA_KEY = 0;
export const ATOB_KEY = 1;
export const TYPEU1_MTP8_LC_KEY = 2;
export const TYPEU_MTP12_LC_KEY = 3;
export const TYPEA_MTP24_LC_KEY = 4;
export const TYPEB_MTP8_KEY = 5;
export const TYPEA_MTP8_KEY = 6;
export const MESH_4x4_KEY = 7;
export const TYPEB_MTP12_KEY = 8;
export const TYPEA_MTP12_KEY = 9;
export const TYPEU2_MTP8_LC_KEY = 10;
export const TYPEB_MTP8_LC_KEY = 11;
export const TYPEB_MTP12_LC_KEY = 12;

// Reversed Schemes
export const TYPEU1_LC_MTP8_KEY = 200;
export const TYPEU_LC_MTP12_KEY = 300;
export const TYPEA_LC_MTP24_KEY = 400;
export const TYPEU2_LC_MTP8_KEY = 1000;
export const TYPEB_LC_MTP8_KEY = 1100;
export const TYPEB_LC_MTP12_KEY = 1200;

export const CUSTOM_MAP_KEY = 9999;

export const AtoA_Map: IPolarityMap = { key: ATOA_KEY, description: "A to A", imageUri: AtoA, from: [2], to: [2] };
export const AtoB_Map: IPolarityMap = { key: ATOB_KEY, description: "A to B", imageUri: AtoB, from: [2], to: [2] };
export const TypeU1_MTP8LC_Map: IPolarityMap = { key: TYPEU1_MTP8_LC_KEY, description: "Type U1", imageUri: TypeUMTP8LC, from: [8], to: new Array(4).fill(2) };
export const TypeU2_MTP8LC_Map: IPolarityMap = { key: TYPEU2_MTP8_LC_KEY, description: "Type U2", from: [8], imageUri: TypeU2MTP8LC, to: new Array(4).fill(2) };
export const TypeB_MTP8LC_Map: IPolarityMap = { key: TYPEB_MTP8_LC_KEY, description: "Type B", from: [8], imageUri: TypeBMTP8LC, to: new Array(4).fill(2) };
export const TypeU_MTP12LC_Map: IPolarityMap = { key: TYPEU_MTP12_LC_KEY, description: "Type U", imageUri: TypeUMTP12LC,  from: [12], to: new Array(6).fill(2) };
export const TypeB_MTP12LC_Map: IPolarityMap = { key: TYPEB_MTP12_LC_KEY, description: "Type B", imageUri: TypeBMTP12LC, from: [12], to: new Array(6).fill(2) };
export const TypeA_MTP24LC_Map: IPolarityMap = { key: TYPEA_MTP24_LC_KEY, description: "Type A", imageUri: TypeAMTP24LC,  from: [24], to: new Array(12).fill(2) };
export const TypeB_MTP8_Map: IPolarityMap = { key: TYPEB_MTP8_KEY, description: "Type B", imageUri: TypeBMTP8,  from: [8], to: [8] };
export const TypeA_MTP8_Map: IPolarityMap = { key: TYPEA_MTP8_KEY, description: "Type A", imageUri: TypeAMTP8,  from: [8], to: [8] };
export const Mesh4x4_Map: IPolarityMap = { key: MESH_4x4_KEY, description: "Type 4x4 Mesh", imageUri: Mesh4x4,  from: new Array(4).fill(8), to: new Array(4).fill(8) };
export const TypeB_MTP12_Map: IPolarityMap = { key: TYPEB_MTP12_KEY, description: "Type B", imageUri: TypeBMTP12,  from: [12], to: [12] };
export const TypeA_MTP12_Map: IPolarityMap = { key: TYPEA_MTP12_KEY, description: "Type A", imageUri: TypeAMTP12,  from: [12], to: [12] };
export const CustomMap: IPolarityMap = { key: CUSTOM_MAP_KEY, description: "Custom" };

export const TypeU1_LCMTP8_Map: IPolarityMap = { key: TYPEU1_LC_MTP8_KEY, description: "Type U1", imageUri: TypeUMTP8LC, from: new Array(4).fill(2), to: [8] };
export const TypeU_LCMTP12_Map: IPolarityMap = { key: TYPEU_LC_MTP12_KEY, description: "Type U", imageUri: TypeUMTP12LC,  from: new Array(6).fill(2), to: [12] };
export const TypeA_LCMTP24_Map: IPolarityMap = { key: TYPEA_LC_MTP24_KEY, description: "Type A", imageUri: TypeAMTP24LC,  from: new Array(12).fill(2), to: [24] };
export const TypeU2_LCMTP8_Map: IPolarityMap = { key: TYPEU2_LC_MTP8_KEY, description: "Type U2", imageUri: TypeU2MTP8LC, from: new Array(4).fill(2), to: [8] };
export const TypeB_LCMTP8_Map: IPolarityMap = { key: TYPEB_LC_MTP8_KEY, description: "Type B", imageUri: TypeBMTP8LC, from: new Array(4).fill(2), to: [8] };
export const TypeB_LCMTP12_Map: IPolarityMap = { key: TYPEB_LC_MTP12_KEY, description: "Type B", imageUri: TypeBMTP12LC, from: new Array(6).fill(2), to: [12] };

export const LCToLCMaps: IPolarityMap[] = [AtoA_Map, AtoB_Map];
export const MTPToLCMaps: IPolarityMap[] = [TypeU1_MTP8LC_Map, TypeU2_MTP8LC_Map, TypeU_MTP12LC_Map, TypeB_MTP12LC_Map, TypeA_MTP24LC_Map, TypeB_MTP8LC_Map];
export const LCToMTPMaps: IPolarityMap[] = [TypeU1_LCMTP8_Map, TypeU2_LCMTP8_Map, TypeU_LCMTP12_Map, TypeB_LCMTP12_Map, TypeA_LCMTP24_Map, TypeB_LCMTP8_Map];
export const MTPToMTPMaps: IPolarityMap[] = [TypeB_MTP8_Map, TypeA_MTP8_Map, Mesh4x4_Map, TypeB_MTP12_Map, TypeA_MTP12_Map];
export const PredefinedPolarityMaps: IPolarityMap[] = [...LCToLCMaps, ...MTPToLCMaps, ...LCToMTPMaps, ...MTPToMTPMaps];

export interface IPolarityState {
    showPolaritySchemes: boolean;
    showConnectorAssignment: boolean;
    showFiberMapping: boolean;
    showPropagation: boolean;
    predefinedFiberMaps: IFiberMapData[];
    userFiberMaps: IFiberMapData[];
    highlightedConnectors: IHighlightedConnector[];
    forceUpdate: boolean;
}

export const initialPolarityState: IPolarityState = {
    showPolaritySchemes: false,
    showConnectorAssignment: false,
    showFiberMapping: false,
    showPropagation: false,
    predefinedFiberMaps: [],
    userFiberMaps: [],
    highlightedConnectors: [],
    forceUpdate: false
}