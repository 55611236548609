export const getEndBPullingGripSVGString = () => {
  return `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1387" height="400" viewBox="0 0 138.7 40" style="enable-background:new 0 0 138.7 40;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:none;}
        .st1{fill:none;stroke:#000000;stroke-width:1.25;stroke-miterlimit:10;}
        .st2{fill:#FFFFFF;}
        .st3{fill:none;stroke:#000000;}
        .st4{fill:url(#SVGID_1_);}
        .st5{fill:url(#SVGID_00000110442364620111866430000004730787145085397393_);}
        .st6{fill:none;stroke:#000000;stroke-miterlimit:10;}
        .st7{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
        .st8{enable-background:new    ;}
      </style>
      <pattern  y="40" width="40" height="40" patternUnits="userSpaceOnUse" id="New_Pattern_Swatch_2" viewBox="38.2 -80.4 40 40" style="overflow:visible;">
        <g>
          <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
          <g>
            <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-120.4"/>
                <line class="st1" x1="102.7" y1="-80.4" x2="62.7" y2="-120.4"/>
                <line class="st1" x1="107.1" y1="-80.4" x2="67.1" y2="-120.4"/>
                <line class="st1" x1="111.6" y1="-80.4" x2="71.6" y2="-120.4"/>
                <line class="st1" x1="116" y1="-80.4" x2="76" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-80.4" x2="116" y2="-120.4"/>
                <line class="st1" x1="71.6" y1="-80.4" x2="111.6" y2="-120.4"/>
                <line class="st1" x1="67.1" y1="-80.4" x2="107.1" y2="-120.4"/>
                <line class="st1" x1="62.7" y1="-80.4" x2="102.7" y2="-120.4"/>
                <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-120.4"/>
                <line class="st1" x1="62.7" y1="-80.4" x2="22.7" y2="-120.4"/>
                <line class="st1" x1="67.1" y1="-80.4" x2="27.1" y2="-120.4"/>
                <line class="st1" x1="71.6" y1="-80.4" x2="31.6" y2="-120.4"/>
                <line class="st1" x1="76" y1="-80.4" x2="36" y2="-120.4"/>
                <line class="st1" x1="80.4" y1="-80.4" x2="40.4" y2="-120.4"/>
                <line class="st1" x1="84.9" y1="-80.4" x2="44.9" y2="-120.4"/>
                <line class="st1" x1="89.3" y1="-80.4" x2="49.3" y2="-120.4"/>
                <line class="st1" x1="93.8" y1="-80.4" x2="53.8" y2="-120.4"/>
                <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-120.4"/>
                <line class="st1" x1="53.8" y1="-80.4" x2="93.8" y2="-120.4"/>
                <line class="st1" x1="49.3" y1="-80.4" x2="89.3" y2="-120.4"/>
                <line class="st1" x1="44.9" y1="-80.4" x2="84.9" y2="-120.4"/>
                <line class="st1" x1="40.4" y1="-80.4" x2="80.4" y2="-120.4"/>
                <line class="st1" x1="36" y1="-80.4" x2="76" y2="-120.4"/>
                <line class="st1" x1="31.6" y1="-80.4" x2="71.6" y2="-120.4"/>
                <line class="st1" x1="27.1" y1="-80.4" x2="67.1" y2="-120.4"/>
                <line class="st1" x1="22.7" y1="-80.4" x2="62.7" y2="-120.4"/>
                <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-80.4" x2="0.4" y2="-120.4"/>
                <line class="st1" x1="44.9" y1="-80.4" x2="4.9" y2="-120.4"/>
                <line class="st1" x1="49.3" y1="-80.4" x2="9.3" y2="-120.4"/>
                <line class="st1" x1="53.8" y1="-80.4" x2="13.8" y2="-120.4"/>
                <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-120.4"/>
                <line class="st1" x1="13.8" y1="-80.4" x2="53.8" y2="-120.4"/>
                <line class="st1" x1="9.3" y1="-80.4" x2="49.3" y2="-120.4"/>
                <line class="st1" x1="4.9" y1="-80.4" x2="44.9" y2="-120.4"/>
                <line class="st1" x1="0.4" y1="-80.4" x2="40.4" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-80.4"/>
                <line class="st1" x1="102.7" y1="-40.4" x2="62.7" y2="-80.4"/>
                <line class="st1" x1="107.1" y1="-40.4" x2="67.1" y2="-80.4"/>
                <line class="st1" x1="111.6" y1="-40.4" x2="71.6" y2="-80.4"/>
                <line class="st1" x1="116" y1="-40.4" x2="76" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-40.4" x2="116" y2="-80.4"/>
                <line class="st1" x1="71.6" y1="-40.4" x2="111.6" y2="-80.4"/>
                <line class="st1" x1="67.1" y1="-40.4" x2="107.1" y2="-80.4"/>
                <line class="st1" x1="62.7" y1="-40.4" x2="102.7" y2="-80.4"/>
                <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-80.4"/>
                <line class="st1" x1="62.7" y1="-40.4" x2="22.7" y2="-80.4"/>
                <line class="st1" x1="67.1" y1="-40.4" x2="27.1" y2="-80.4"/>
                <line class="st1" x1="71.6" y1="-40.4" x2="31.6" y2="-80.4"/>
                <line class="st1" x1="76" y1="-40.4" x2="36" y2="-80.4"/>
                <line class="st1" x1="80.4" y1="-40.4" x2="40.4" y2="-80.4"/>
                <line class="st1" x1="84.9" y1="-40.4" x2="44.9" y2="-80.4"/>
                <line class="st1" x1="89.3" y1="-40.4" x2="49.3" y2="-80.4"/>
                <line class="st1" x1="93.8" y1="-40.4" x2="53.8" y2="-80.4"/>
                <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-80.4"/>
                <line class="st1" x1="53.8" y1="-40.4" x2="93.8" y2="-80.4"/>
                <line class="st1" x1="49.3" y1="-40.4" x2="89.3" y2="-80.4"/>
                <line class="st1" x1="44.9" y1="-40.4" x2="84.9" y2="-80.4"/>
                <line class="st1" x1="40.4" y1="-40.4" x2="80.4" y2="-80.4"/>
                <line class="st1" x1="36" y1="-40.4" x2="76" y2="-80.4"/>
                <line class="st1" x1="31.6" y1="-40.4" x2="71.6" y2="-80.4"/>
                <line class="st1" x1="27.1" y1="-40.4" x2="67.1" y2="-80.4"/>
                <line class="st1" x1="22.7" y1="-40.4" x2="62.7" y2="-80.4"/>
                <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-40.4" x2="0.4" y2="-80.4"/>
                <line class="st1" x1="44.9" y1="-40.4" x2="4.9" y2="-80.4"/>
                <line class="st1" x1="49.3" y1="-40.4" x2="9.3" y2="-80.4"/>
                <line class="st1" x1="53.8" y1="-40.4" x2="13.8" y2="-80.4"/>
                <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-80.4"/>
                <line class="st1" x1="13.8" y1="-40.4" x2="53.8" y2="-80.4"/>
                <line class="st1" x1="9.3" y1="-40.4" x2="49.3" y2="-80.4"/>
                <line class="st1" x1="4.9" y1="-40.4" x2="44.9" y2="-80.4"/>
                <line class="st1" x1="0.4" y1="-40.4" x2="40.4" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-0.4" x2="58.2" y2="-40.4"/>
                <line class="st1" x1="102.7" y1="-0.4" x2="62.7" y2="-40.4"/>
                <line class="st1" x1="107.1" y1="-0.4" x2="67.1" y2="-40.4"/>
                <line class="st1" x1="111.6" y1="-0.4" x2="71.6" y2="-40.4"/>
                <line class="st1" x1="116" y1="-0.4" x2="76" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-0.4" x2="116" y2="-40.4"/>
                <line class="st1" x1="71.6" y1="-0.4" x2="111.6" y2="-40.4"/>
                <line class="st1" x1="67.1" y1="-0.4" x2="107.1" y2="-40.4"/>
                <line class="st1" x1="62.7" y1="-0.4" x2="102.7" y2="-40.4"/>
                <line class="st1" x1="58.2" y1="-0.4" x2="98.2" y2="-40.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-0.4" x2="18.2" y2="-40.4"/>
                <line class="st1" x1="62.7" y1="-0.4" x2="22.7" y2="-40.4"/>
                <line class="st1" x1="67.1" y1="-0.4" x2="27.1" y2="-40.4"/>
                <line class="st1" x1="71.6" y1="-0.4" x2="31.6" y2="-40.4"/>
                <line class="st1" x1="76" y1="-0.4" x2="36" y2="-40.4"/>
                <line class="st1" x1="80.4" y1="-0.4" x2="40.4" y2="-40.4"/>
                <line class="st1" x1="84.9" y1="-0.4" x2="44.9" y2="-40.4"/>
                <line class="st1" x1="89.3" y1="-0.4" x2="49.3" y2="-40.4"/>
                <line class="st1" x1="93.8" y1="-0.4" x2="53.8" y2="-40.4"/>
                <line class="st1" x1="98.2" y1="-0.4" x2="58.2" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-0.4" x2="98.2" y2="-40.4"/>
                <line class="st1" x1="53.8" y1="-0.4" x2="93.8" y2="-40.4"/>
                <line class="st1" x1="49.3" y1="-0.4" x2="89.3" y2="-40.4"/>
                <line class="st1" x1="44.9" y1="-0.4" x2="84.9" y2="-40.4"/>
                <line class="st1" x1="40.4" y1="-0.4" x2="80.4" y2="-40.4"/>
                <line class="st1" x1="36" y1="-0.4" x2="76" y2="-40.4"/>
                <line class="st1" x1="31.6" y1="-0.4" x2="71.6" y2="-40.4"/>
                <line class="st1" x1="27.1" y1="-0.4" x2="67.1" y2="-40.4"/>
                <line class="st1" x1="22.7" y1="-0.4" x2="62.7" y2="-40.4"/>
                <line class="st1" x1="18.2" y1="-0.4" x2="58.2" y2="-40.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-0.4" x2="0.4" y2="-40.4"/>
                <line class="st1" x1="44.9" y1="-0.4" x2="4.9" y2="-40.4"/>
                <line class="st1" x1="49.3" y1="-0.4" x2="9.3" y2="-40.4"/>
                <line class="st1" x1="53.8" y1="-0.4" x2="13.8" y2="-40.4"/>
                <line class="st1" x1="58.2" y1="-0.4" x2="18.2" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-0.4" x2="58.2" y2="-40.4"/>
                <line class="st1" x1="13.8" y1="-0.4" x2="53.8" y2="-40.4"/>
                <line class="st1" x1="9.3" y1="-0.4" x2="49.3" y2="-40.4"/>
                <line class="st1" x1="4.9" y1="-0.4" x2="44.9" y2="-40.4"/>
                <line class="st1" x1="0.4" y1="-0.4" x2="40.4" y2="-40.4"/>
              </g>
            </g>
          </g>
        </g>
      </pattern>
      <pattern  y="40" width="40" height="40" patternUnits="userSpaceOnUse" id="New_Pattern_Swatch_2-2" viewBox="38.2 -80.4 40 40" style="overflow:visible;">
        <g>
          <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
          <g>
            <rect x="38.2" y="-80.4" class="st0" width="40" height="40"/>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-120.4"/>
                <line class="st1" x1="102.7" y1="-80.4" x2="62.7" y2="-120.4"/>
                <line class="st1" x1="107.1" y1="-80.4" x2="67.1" y2="-120.4"/>
                <line class="st1" x1="111.6" y1="-80.4" x2="71.6" y2="-120.4"/>
                <line class="st1" x1="116" y1="-80.4" x2="76" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-80.4" x2="116" y2="-120.4"/>
                <line class="st1" x1="71.6" y1="-80.4" x2="111.6" y2="-120.4"/>
                <line class="st1" x1="67.1" y1="-80.4" x2="107.1" y2="-120.4"/>
                <line class="st1" x1="62.7" y1="-80.4" x2="102.7" y2="-120.4"/>
                <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-120.4"/>
                <line class="st1" x1="62.7" y1="-80.4" x2="22.7" y2="-120.4"/>
                <line class="st1" x1="67.1" y1="-80.4" x2="27.1" y2="-120.4"/>
                <line class="st1" x1="71.6" y1="-80.4" x2="31.6" y2="-120.4"/>
                <line class="st1" x1="76" y1="-80.4" x2="36" y2="-120.4"/>
                <line class="st1" x1="80.4" y1="-80.4" x2="40.4" y2="-120.4"/>
                <line class="st1" x1="84.9" y1="-80.4" x2="44.9" y2="-120.4"/>
                <line class="st1" x1="89.3" y1="-80.4" x2="49.3" y2="-120.4"/>
                <line class="st1" x1="93.8" y1="-80.4" x2="53.8" y2="-120.4"/>
                <line class="st1" x1="98.2" y1="-80.4" x2="58.2" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-80.4" x2="98.2" y2="-120.4"/>
                <line class="st1" x1="53.8" y1="-80.4" x2="93.8" y2="-120.4"/>
                <line class="st1" x1="49.3" y1="-80.4" x2="89.3" y2="-120.4"/>
                <line class="st1" x1="44.9" y1="-80.4" x2="84.9" y2="-120.4"/>
                <line class="st1" x1="40.4" y1="-80.4" x2="80.4" y2="-120.4"/>
                <line class="st1" x1="36" y1="-80.4" x2="76" y2="-120.4"/>
                <line class="st1" x1="31.6" y1="-80.4" x2="71.6" y2="-120.4"/>
                <line class="st1" x1="27.1" y1="-80.4" x2="67.1" y2="-120.4"/>
                <line class="st1" x1="22.7" y1="-80.4" x2="62.7" y2="-120.4"/>
                <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-80.4" x2="0.4" y2="-120.4"/>
                <line class="st1" x1="44.9" y1="-80.4" x2="4.9" y2="-120.4"/>
                <line class="st1" x1="49.3" y1="-80.4" x2="9.3" y2="-120.4"/>
                <line class="st1" x1="53.8" y1="-80.4" x2="13.8" y2="-120.4"/>
                <line class="st1" x1="58.2" y1="-80.4" x2="18.2" y2="-120.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-80.4" x2="58.2" y2="-120.4"/>
                <line class="st1" x1="13.8" y1="-80.4" x2="53.8" y2="-120.4"/>
                <line class="st1" x1="9.3" y1="-80.4" x2="49.3" y2="-120.4"/>
                <line class="st1" x1="4.9" y1="-80.4" x2="44.9" y2="-120.4"/>
                <line class="st1" x1="0.4" y1="-80.4" x2="40.4" y2="-120.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-80.4"/>
                <line class="st1" x1="102.7" y1="-40.4" x2="62.7" y2="-80.4"/>
                <line class="st1" x1="107.1" y1="-40.4" x2="67.1" y2="-80.4"/>
                <line class="st1" x1="111.6" y1="-40.4" x2="71.6" y2="-80.4"/>
                <line class="st1" x1="116" y1="-40.4" x2="76" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-40.4" x2="116" y2="-80.4"/>
                <line class="st1" x1="71.6" y1="-40.4" x2="111.6" y2="-80.4"/>
                <line class="st1" x1="67.1" y1="-40.4" x2="107.1" y2="-80.4"/>
                <line class="st1" x1="62.7" y1="-40.4" x2="102.7" y2="-80.4"/>
                <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-80.4"/>
                <line class="st1" x1="62.7" y1="-40.4" x2="22.7" y2="-80.4"/>
                <line class="st1" x1="67.1" y1="-40.4" x2="27.1" y2="-80.4"/>
                <line class="st1" x1="71.6" y1="-40.4" x2="31.6" y2="-80.4"/>
                <line class="st1" x1="76" y1="-40.4" x2="36" y2="-80.4"/>
                <line class="st1" x1="80.4" y1="-40.4" x2="40.4" y2="-80.4"/>
                <line class="st1" x1="84.9" y1="-40.4" x2="44.9" y2="-80.4"/>
                <line class="st1" x1="89.3" y1="-40.4" x2="49.3" y2="-80.4"/>
                <line class="st1" x1="93.8" y1="-40.4" x2="53.8" y2="-80.4"/>
                <line class="st1" x1="98.2" y1="-40.4" x2="58.2" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-40.4" x2="98.2" y2="-80.4"/>
                <line class="st1" x1="53.8" y1="-40.4" x2="93.8" y2="-80.4"/>
                <line class="st1" x1="49.3" y1="-40.4" x2="89.3" y2="-80.4"/>
                <line class="st1" x1="44.9" y1="-40.4" x2="84.9" y2="-80.4"/>
                <line class="st1" x1="40.4" y1="-40.4" x2="80.4" y2="-80.4"/>
                <line class="st1" x1="36" y1="-40.4" x2="76" y2="-80.4"/>
                <line class="st1" x1="31.6" y1="-40.4" x2="71.6" y2="-80.4"/>
                <line class="st1" x1="27.1" y1="-40.4" x2="67.1" y2="-80.4"/>
                <line class="st1" x1="22.7" y1="-40.4" x2="62.7" y2="-80.4"/>
                <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-40.4" x2="0.4" y2="-80.4"/>
                <line class="st1" x1="44.9" y1="-40.4" x2="4.9" y2="-80.4"/>
                <line class="st1" x1="49.3" y1="-40.4" x2="9.3" y2="-80.4"/>
                <line class="st1" x1="53.8" y1="-40.4" x2="13.8" y2="-80.4"/>
                <line class="st1" x1="58.2" y1="-40.4" x2="18.2" y2="-80.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-40.4" x2="58.2" y2="-80.4"/>
                <line class="st1" x1="13.8" y1="-40.4" x2="53.8" y2="-80.4"/>
                <line class="st1" x1="9.3" y1="-40.4" x2="49.3" y2="-80.4"/>
                <line class="st1" x1="4.9" y1="-40.4" x2="44.9" y2="-80.4"/>
                <line class="st1" x1="0.4" y1="-40.4" x2="40.4" y2="-80.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="98.2" y1="-0.4" x2="58.2" y2="-40.4"/>
                <line class="st1" x1="102.7" y1="-0.4" x2="62.7" y2="-40.4"/>
                <line class="st1" x1="107.1" y1="-0.4" x2="67.1" y2="-40.4"/>
                <line class="st1" x1="111.6" y1="-0.4" x2="71.6" y2="-40.4"/>
                <line class="st1" x1="116" y1="-0.4" x2="76" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="76" y1="-0.4" x2="116" y2="-40.4"/>
                <line class="st1" x1="71.6" y1="-0.4" x2="111.6" y2="-40.4"/>
                <line class="st1" x1="67.1" y1="-0.4" x2="107.1" y2="-40.4"/>
                <line class="st1" x1="62.7" y1="-0.4" x2="102.7" y2="-40.4"/>
                <line class="st1" x1="58.2" y1="-0.4" x2="98.2" y2="-40.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="58.2" y1="-0.4" x2="18.2" y2="-40.4"/>
                <line class="st1" x1="62.7" y1="-0.4" x2="22.7" y2="-40.4"/>
                <line class="st1" x1="67.1" y1="-0.4" x2="27.1" y2="-40.4"/>
                <line class="st1" x1="71.6" y1="-0.4" x2="31.6" y2="-40.4"/>
                <line class="st1" x1="76" y1="-0.4" x2="36" y2="-40.4"/>
                <line class="st1" x1="80.4" y1="-0.4" x2="40.4" y2="-40.4"/>
                <line class="st1" x1="84.9" y1="-0.4" x2="44.9" y2="-40.4"/>
                <line class="st1" x1="89.3" y1="-0.4" x2="49.3" y2="-40.4"/>
                <line class="st1" x1="93.8" y1="-0.4" x2="53.8" y2="-40.4"/>
                <line class="st1" x1="98.2" y1="-0.4" x2="58.2" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="58.2" y1="-0.4" x2="98.2" y2="-40.4"/>
                <line class="st1" x1="53.8" y1="-0.4" x2="93.8" y2="-40.4"/>
                <line class="st1" x1="49.3" y1="-0.4" x2="89.3" y2="-40.4"/>
                <line class="st1" x1="44.9" y1="-0.4" x2="84.9" y2="-40.4"/>
                <line class="st1" x1="40.4" y1="-0.4" x2="80.4" y2="-40.4"/>
                <line class="st1" x1="36" y1="-0.4" x2="76" y2="-40.4"/>
                <line class="st1" x1="31.6" y1="-0.4" x2="71.6" y2="-40.4"/>
                <line class="st1" x1="27.1" y1="-0.4" x2="67.1" y2="-40.4"/>
                <line class="st1" x1="22.7" y1="-0.4" x2="62.7" y2="-40.4"/>
                <line class="st1" x1="18.2" y1="-0.4" x2="58.2" y2="-40.4"/>
              </g>
            </g>
            <g>
              <g>
                <line class="st1" x1="40.4" y1="-0.4" x2="0.4" y2="-40.4"/>
                <line class="st1" x1="44.9" y1="-0.4" x2="4.9" y2="-40.4"/>
                <line class="st1" x1="49.3" y1="-0.4" x2="9.3" y2="-40.4"/>
                <line class="st1" x1="53.8" y1="-0.4" x2="13.8" y2="-40.4"/>
                <line class="st1" x1="58.2" y1="-0.4" x2="18.2" y2="-40.4"/>
              </g>
              <g>
                <line class="st1" x1="18.2" y1="-0.4" x2="58.2" y2="-40.4"/>
                <line class="st1" x1="13.8" y1="-0.4" x2="53.8" y2="-40.4"/>
                <line class="st1" x1="9.3" y1="-0.4" x2="49.3" y2="-40.4"/>
                <line class="st1" x1="4.9" y1="-0.4" x2="44.9" y2="-40.4"/>
                <line class="st1" x1="0.4" y1="-0.4" x2="40.4" y2="-40.4"/>
              </g>
            </g>
          </g>
        </g>
      </pattern>
      <g>
        <g>
          <path class="st2" d="M132.2,32.1l1.7-9.9c0.2-1.4,0.2-2.8,0.1-3.9c-0.3-3.3-0.4-5.5-0.1-8.4c0.1-1.1,0.4-2.1,0.6-3.2v0
            c0.3-0.9-0.1-1.9-0.9-2.5c-1.3-1-3.8-2.5-8.2-3.2c-4.7-0.8-7.4,0-8.8,0.7c-0.7,0.4-1.2,1-1.3,1.7l0,0c-0.4,1.4-1,4.2-3,6.4
            c-0.9,1-1.5,2.2-1.7,3.4l-1,5.9L132.2,32.1L132.2,32.1z"/>
          <path d="M125.7,0.3c4.5,0.8,7.1,2.2,8.5,3.3c1,0.8,1.4,2.1,1.1,3.2c0,0,0,0.1,0,0.1c-0.3,1-0.5,2-0.6,3c-0.3,2.8-0.2,5,0.1,8.2
            c0.1,1.4,0.1,2.8-0.1,4.1l-1.7,9.9c-0.1,0.4-0.4,0.6-0.8,0.6l0,0c-0.4-0.1-0.6-0.4-0.6-0.8l1.7-9.9c0.2-1.2,0.2-2.5,0.1-3.8
            c-0.3-3.4-0.4-5.6-0.1-8.5c0.1-1.1,0.4-2.2,0.7-3.3c0,0,0-0.1,0-0.1c0.2-0.6-0.1-1.3-0.6-1.8c-1.2-1-3.6-2.3-7.8-3l-0.1,0
            c-4.7-0.8-7.2,0.1-8.4,0.6c-0.5,0.3-0.9,0.7-1,1.2c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c-0.4,1.5-1.2,4.1-3,6.1
            c-0.8,0.9-1.4,2-1.6,3.1l-1,5.9c-0.1,0.4-0.4,0.6-0.8,0.6s-0.6-0.4-0.6-0.8l1-5.9c0.2-1.4,0.9-2.7,1.9-3.8
            c1.6-1.8,2.3-4.1,2.7-5.6c0.1-0.2,0.1-0.4,0.2-0.5c0.2-1,0.8-1.8,1.7-2.2C117.9,0.3,120.8-0.5,125.7,0.3L125.7,0.3L125.7,0.3z"/>
        </g>
        <path d="M125.5,11.3c0.4,0.1,0.6,0.4,0.6,0.8c-0.8,4.8-2.5,8.1-5.1,9.9c-2.4,1.6-4.6,1.3-4.7,1.2c-0.4-0.1-0.6-0.4-0.6-0.8
          s0.4-0.6,0.8-0.6l0,0c0.3,0,6.2,0.9,8.1-10C124.7,11.5,125.1,11.3,125.5,11.3L125.5,11.3z"/>
      </g>
      <g id="Group_1552">
        <g id="Rectangle_1343">
          <rect x="0.1" y="27.7" class="st2" width="39" height="6.4"/>
          <rect x="0.5" y="28.1" class="st3" width="38.6" height="5.6"/>
        </g>
        <g id="Path_542">
          <pattern  id="SVGID_1_" xlink:href="#New_Pattern_Swatch_2" patternTransform="matrix(1 0 0 -1 8.9 -15503.8838)">
          </pattern>
          <polygon class="st4" points="100.2,31.2 92.7,23.8 43,23.8 39.1,27.6 39.1,34.5 43,38.4 93.7,38.4 		"/>

            <pattern  id="SVGID_00000077293166876173901430000006036675361050223036_" xlink:href="#New_Pattern_Swatch_2-2" patternTransform="matrix(1 0 0 -1 -1.1 -15503.8838)">
          </pattern>
          <polygon style="fill:url(#SVGID_00000077293166876173901430000006036675361050223036_);" points="100.2,31.2 92.7,23.8 43,23.8 
            39.1,27.6 39.1,34.5 43,38.4 93.7,38.4 		"/>
          <polygon class="st6" points="100.2,31.2 92.7,23.8 43,23.8 39.1,27.6 39.1,34.5 43,38.4 93.7,38.4 		"/>
        </g>
        <rect id="Rectangle_1342" x="23.3" y="26.1" width="6.4" height="9.6"/>
      </g>
      <path class="st7" d="M100.2,30.9l6.8,1.3c0.9,0.4,9.9,0.6,10.9,0.6h16.5c1.9,0,3.4,0.1,3.4-1.8l0,0c0-1.9-1.5-1.8-3.4-1.8h-16.5
        c-1,0-10,0.2-10.9,0.6l-6.8,1.4"/>
      <g>
        <g>
          <path class="st2" d="M119.7,22.5C119.7,22.5,119.7,22.5,119.7,22.5c1.6,0.3,2.6,1.8,2.3,3.2l-1.3,7.5l-0.3,1.5l-0.2,1.2
            c-0.3,1.5-1.7,2.6-3.3,2.3s-2.6-1.7-2.3-3.2l1.8-10.3C116.6,23.3,118.1,22.2,119.7,22.5C119.6,22.5,119.6,22.5,119.7,22.5
            L119.7,22.5z"/>
          <path d="M119.8,21.8L119.8,21.8c1.9,0.4,3.2,2.2,2.9,4.1l-1.8,10.3c-0.3,1.9-2.2,3.2-4.1,2.9s-3.2-2.1-2.9-4l1.8-10.3
            C116,22.8,117.8,21.5,119.8,21.8L119.8,21.8L119.8,21.8z M117,37.7c1.2,0.2,2.3-0.6,2.5-1.7l1.8-10.3c0.2-1.1-0.6-2.2-1.7-2.4
            l-0.1,0c-1.1-0.2-2.2,0.6-2.4,1.8l-1.8,10.3C115.1,36.4,115.9,37.5,117,37.7L117,37.7z"/>
        </g>
        <g>
          <path class="st2" d="M122.5,39.3c1.6,0.3,3-0.8,3.3-2.3l1.8-10.3c0.3-1.5-0.8-3-2.3-3.2l0,0c-1.6-0.3-3,0.8-3.3,2.3L120.2,36
            C119.9,37.5,121,39,122.5,39.3L122.5,39.3z"/>
          <path d="M125.4,22.7c1.9,0.3,3.2,2.1,2.9,4l-1.8,10.3c-0.3,1.9-2.2,3.2-4.1,2.9s-3.2-2.1-2.9-4l1.8-10.3
            C121.6,23.7,123.4,22.4,125.4,22.7z M122.7,38.6c1.2,0.2,2.3-0.6,2.5-1.7l1.8-10.3c0.2-1.1-0.6-2.2-1.8-2.4s-2.3,0.6-2.5,1.7
            l-1.8,10.3C120.7,37.3,121.5,38.4,122.7,38.6z"/>
        </g>
        <g>
          <path class="st2" d="M111.3,37.4c1.6,0.3,3-0.8,3.3-2.3l1.8-10.3c0.3-1.5-0.8-3-2.3-3.2l0,0c-1.6-0.3-3,0.8-3.3,2.3L109,34.2
            C108.8,35.7,109.8,37.1,111.3,37.4L111.3,37.4z"/>
          <path d="M114.2,20.9c1.9,0.3,3.2,2.1,2.9,4l-1.8,10.3c-0.3,1.9-2.2,3.2-4.1,2.9s-3.2-2.1-2.9-4l1.8-10.3
            C110.4,21.8,112.2,20.6,114.2,20.9z M111.5,36.7c1.2,0.2,2.3-0.6,2.5-1.7l1.8-10.3c0.2-1.1-0.6-2.2-1.8-2.4s-2.3,0.6-2.5,1.7
            l-1.8,10.3C109.5,35.4,110.3,36.5,111.5,36.7z"/>
        </g>
        <g>
          <path class="st2" d="M112.5,27.8l7.2,1.2c2,0.3,3.3-0.8,3.6-2.5c0.2-1.2-0.5-2.9-2.7-3.3l-4.2-0.7l-2.6-2.8l-4.3-0.6
            C108.7,23.9,107.8,27,112.5,27.8L112.5,27.8z"/>
          <path d="M114,19.1c0.2,0,0.3,0.1,0.4,0.2l2.4,2.6l3.9,0.6c2.5,0.4,3.6,2.4,3.3,4.1c-0.4,2.2-2.2,3.5-4.4,3.1l-7.2-1.2
            c-1.7-0.3-2.8-0.9-3.5-1.8c-1.1-1.6-0.7-3.8-0.2-6.6c0.1-0.3,0.1-0.6,0.2-1c0.1-0.4,0.4-0.6,0.8-0.6l0,0c0.4,0.1,0.6,0.4,0.6,0.8
            c-0.1,0.3-0.1,0.7-0.2,1c-0.5,2.5-0.8,4.5,0,5.6c0.4,0.6,1.3,1,2.6,1.2l7.2,1.2c1.5,0.2,2.5-0.5,2.8-1.9c0.1-0.8-0.3-2.2-2.2-2.5
            l-4.2-0.7c-0.2,0-0.3-0.1-0.4-0.2l-2.6-2.8c-0.3-0.3-0.2-0.7,0-1C113.6,19.1,113.8,19,114,19.1L114,19.1z"/>
        </g>
        <g>
          <path class="st2" d="M130.4,26.6c1.5,0.2,2.5,1.7,2.3,3.2l-1.2,6.8c-0.2,1.5-1.7,2.5-3.2,2.2l0,0c-1.5-0.2-2.5-1.7-2.3-3.2
            l1.2-6.8C127.4,27.4,128.9,26.4,130.4,26.6L130.4,26.6z"/>
          <path d="M130.5,26c1.9,0.3,3.2,2.1,2.9,4l-1.2,6.8c-0.3,1.9-2.1,3.1-4,2.8s-3.2-2.1-2.9-4l1.2-6.8C126.8,26.9,128.6,25.6,130.5,26
            z M128.4,38.2c1.1,0.2,2.2-0.6,2.4-1.7l1.2-6.8c0.2-1.1-0.6-2.2-1.7-2.4s-2.2,0.6-2.4,1.7l-1.2,6.8
            C126.5,36.9,127.3,38,128.4,38.2z"/>
        </g>
      </g>
      <g class="st8">
        <path d="M21.9,16V5.2c0,0,1.5-0.1,2.8-0.1c2.8,0,4.1,1.5,4.1,3.4c0,2.2-1.6,3.8-4.3,3.8c-0.6,0-1.1,0-1.1,0V16H21.9z M23.4,11
          c0,0,0.6,0.1,1.2,0.1c1.8,0,2.7-1,2.7-2.4c0-1.4-0.8-2.4-2.8-2.4c-0.6,0-1.2,0-1.2,0V11z"/>
        <path d="M35.8,16c0-0.5,0-1.2,0.1-1.7h0c-0.5,1.1-1.4,1.8-2.8,1.8c-1.8,0-2.4-1.1-2.4-2.5V8h1.4v5.1c0,1.1,0.3,1.8,1.4,1.8
          c1.3,0,2.3-1.3,2.3-3.1V8h1.4v8H35.8z"/>
        <path d="M39.9,16V4.3h1.4V16H39.9z"/>
        <path d="M44.1,16V4.3h1.4V16H44.1z"/>
        <path d="M49,6.5c-0.5,0-1-0.4-1-0.9c0-0.5,0.4-0.9,1-0.9c0.5,0,1,0.4,1,0.9C49.9,6,49.5,6.5,49,6.5z M48.3,16V8h1.4v8H48.3z"/>
        <path d="M57.5,16v-5.1c0-1.1-0.3-1.8-1.4-1.8c-1.3,0-2.3,1.3-2.3,3.1V16h-1.4V8h1.4c0,0.5,0,1.2-0.1,1.7h0c0.5-1.1,1.4-1.8,2.8-1.8
          c1.8,0,2.4,1.1,2.4,2.6V16H57.5z"/>
        <path d="M67,9.2c0.4,0.4,0.5,0.9,0.5,1.4c0,1.7-1.1,3-3.2,3c-0.3,0-0.6,0-0.8-0.1c-0.3,0.2-0.5,0.5-0.5,0.7c0,0.3,0.3,0.6,1.2,0.6
          l1.3,0c1.9,0.1,2.9,0.9,2.9,2.3c0,1.7-1.6,3-4.1,3c-2.1,0-3.4-0.8-3.4-2.4c0-0.9,0.6-1.7,1.4-2.2c-0.5-0.3-0.7-0.7-0.7-1.2
          c0-0.5,0.3-1,0.9-1.4c-0.8-0.5-1.2-1.3-1.2-2.2c0-1.7,1.1-3,3.3-3C65,7.9,65.6,8,66.1,8h2.2v1.1H67z M63.4,15.9
          c-0.7,0.5-1.1,1-1.1,1.6c0,1,0.8,1.5,2.1,1.5c1.6,0,2.4-0.7,2.4-1.7c0-1.3-1.4-1.3-3.1-1.4C63.6,15.9,63.5,15.9,63.4,15.9z M64.4,9
          c-1.1,0-1.8,0.7-1.8,1.8s0.7,1.8,1.8,1.8c1.1,0,1.8-0.7,1.8-1.8S65.5,9,64.4,9z"/>
        <path d="M81.9,15.5c-1.1,0.4-2.4,0.6-3.6,0.6c-3.4,0-5.4-2-5.4-5.3c0-3.4,2-5.8,5.8-5.8c1.1,0,2.1,0.2,3,0.5l-0.3,1.3
          c-0.9-0.3-1.8-0.5-2.8-0.5c-2.9,0-4.1,2-4.1,4.3c0,2.7,1.5,4.2,4,4.2c0.7,0,1.3-0.1,1.9-0.3v-4.1h1.5V15.5z"/>
        <path d="M88.5,9.3C86.8,9,86,10.3,86,12.8V16h-1.4V8h1.4c0,0.5,0,1.2-0.2,1.9h0c0.3-1.1,1.2-2.2,2.7-2L88.5,9.3z"/>
        <path d="M91,6.5c-0.5,0-1-0.4-1-0.9c0-0.5,0.4-0.9,1-0.9s1,0.4,1,0.9C91.9,6,91.5,6.5,91,6.5z M90.2,16V8h1.4v8H90.2z"/>
        <path d="M94.4,20V8h1.4c0,0.4,0,1.2-0.1,1.6l0,0c0.4-1.1,1.4-1.8,2.7-1.8c1.9,0,2.9,1.3,2.9,3.7c0,2.8-1.5,4.4-4.1,4.4
          c-0.5,0-0.9,0-1.3-0.1v4H94.4z M95.8,14.8c0.4,0.1,0.9,0.2,1.4,0.2c1.5,0,2.5-0.9,2.5-3.2c0-1.7-0.6-2.7-1.8-2.7
          c-1.4,0-2.2,1.7-2.2,3.1V14.8z"/>
      </g>
    </svg>  
  `
}