import React from 'react';
import { useSettingsAction } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Settings } from '@mui/icons-material';

export const SettingsAction: React.FC = () => {
    const { settingsIconProps } = useSettingsAction();
    return (
        <IconButton {...settingsIconProps} icon={<Settings />} />
    )
}