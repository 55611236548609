import { InputFieldValue, InputField } from "./types";
import { useState, useCallback, useEffect } from "react";

export function useInputField<T extends InputFieldValue>(
        label: string,
        value: T,
        maxLength?: number,
        parse?: (value: string) => T,
        validate?: (value: T) => boolean,
        callback?: (value: T, isValid: boolean) => void,
    )
    : InputField & {parsedValue?: T}{

    const [fieldValue, setFieldValue] = useState(value.toString());
    const [isValid, setIsValid] = useState(true);
    const [parsedValue, setParsedValue] = useState(value);

    const parseValue = useCallback((input: string): T | string => {
        let parsedValue = input;
        if (parse) {
            let parsedValue = parse(input);
            setFieldValue(parsedValue.toString())
            setParsedValue(parsedValue)
        }
        else {
            setFieldValue(input.toString())
        }

        return parsedValue;
    }, [parse]);

    const validateValue = useCallback((input?: T) => {
        let valid = true;
        if (input !== undefined) {
            if (validate) {
                valid = validate(input);
                setIsValid(valid)
            }
    
            if (callback) {
                if (parsedValue !== input as T) {
                    callback(input as T, valid)
                }
            }
        }

        return valid;
    }, [validate, callback, parsedValue])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseValue(e.currentTarget.value);
        validateValue(parsedValue as T);
        if (!e.currentTarget.value.length) {
            setFieldValue(e.currentTarget.value)
        }
    }, [parseValue, validateValue])

    useEffect(() => {
        let stringValue = value as string;
        let intValue = value as number;
        if (intValue && !Number.isNaN(stringValue)) {
            stringValue = intValue.toString()
        }
        
        if (maxLength) {
            if (maxLength && stringValue && stringValue.length > maxLength) {
                const lastchar = stringValue[maxLength - 1];
                let sliceRange = lastchar === ',' ? maxLength - 1 : maxLength
                stringValue = stringValue.slice(0, sliceRange)
            }
            setParsedValue(value)
            setFieldValue(`${value}`)
            validateValue(value)
        }
        else {
            
            setParsedValue(value)
            setFieldValue(stringValue)
            validateValue(value)
        }
    }, [value, validateValue, maxLength])

    return { label, value: fieldValue, onChange, maxLength, isValid, parsedValue }
}

export function getDateNowString() {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');;
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}