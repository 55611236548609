import { useState, useCallback, ChangeEvent, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { DialogProps } from "@orbit/dialog";
import { CheckboxProps } from "@orbit/checkbox";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { ButtonProps } from "@orbit/button";
import { showPropagationSelector } from "../../store/selectors";
import { useStoreDispatch } from "../../../../store/hooks";
import { setShowPropagation } from "../../store/reducer";
import { batch } from "react-redux";
import { currentConnectorAssignmentsSelector, sideBreakoutsSelectorFactory } from "../../../assembly/store/selectors";
import { setConnectors, updateConnectorAssignments, updateConnectors } from "../../../assembly/store/reducer";
import { ConnectorAssignmentContext, IConnectorAssignmentMap } from "../reducer/types";
import { IConnectorWithPositions, Sides, toConnectorWithPositions } from "../../../assembly/breakout/types";
import { IPropagationMap, getPropagationMaps, toConnector, IPropagationAttributes, getPropagationResults, validatePropagationMaps } from "../../../assembly/store/types";
import { setStatus } from "../../../store/reducer";
import { Status } from "../../../store/types";
import { setPropagating } from "../reducer/reducer";

export const usePropagationDialog = () => {
    const { dispatch } = useContext(ConnectorAssignmentContext);
    const currentAssignments = useSelector(currentConnectorAssignmentsSelector);
    const sideABreakouts = useSelector(sideBreakoutsSelectorFactory(Sides.SideA));
    const sideBBreakouts = useSelector(sideBreakoutsSelectorFactory(Sides.SideB));
    const showPropagation = useSelector(showPropagationSelector);
    const { attributes, triggerColorProps, labelColorProps, labelTextProps, resetPropagationOptions } = usePropagateAttributes();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onDialogClose = useCallback(() => {
        if (showPropagation) {
            storeDispatch(setShowPropagation(false));
        }
        resetPropagationOptions();
    },[showPropagation, storeDispatch, resetPropagationOptions]);

    const onSave = useCallback(() => {
        if (currentAssignments.length > 0) {
            storeDispatch(setStatus(Status.Synchronizing));
            dispatch(setPropagating(true));
            const sideAConnectors: IConnectorWithPositions[][] = sideABreakouts.map(b => b.furcation.groups.flatMap(g => g.connectors.map(c => toConnectorWithPositions(b.side, b.position, g.position, c))));
            const sideBConnectors: IConnectorWithPositions[][] = sideBBreakouts.map(b => b.furcation.groups.flatMap(g => g.connectors.map(c => toConnectorWithPositions(b.side, b.position, g.position, c))));
    
            const propagations: IPropagationMap[] = [];
            const updatedAssigments: IConnectorAssignmentMap[] = [];
            for (const assignment of currentAssignments) {
                const propagationMaps = getPropagationMaps(assignment, sideAConnectors, attributes);

                const isValidPropagation = validatePropagationMaps(propagationMaps);
                if (isValidPropagation) {
                    propagations.push(...propagationMaps);
                }
    
                const results = getPropagationResults(isValidPropagation, attributes);
                updatedAssigments.push({ ...assignment, ...results });
            }
    
            const updatedConnectors: IConnectorWithPositions[] = propagations.map(p => toConnector(p, sideBConnectors));

            batch(() => {
                storeDispatch(setShowPropagation(false));
                storeDispatch(updateConnectorAssignments(updatedAssigments));
                if (updatedConnectors.length > 0) {
                    storeDispatch(setConnectors(updatedConnectors));
                    storeDispatch(updateConnectors(updatedConnectors));
                } else {
                    storeDispatch(setStatus(Status.Active));
                }
            });

            resetPropagationOptions();
        }
    },[attributes, sideABreakouts, sideBBreakouts, currentAssignments, resetPropagationOptions, dispatch, storeDispatch]);

    const dialogProps: DialogProps = {
        open: showPropagation,
        className: "propagation-dialog",
        title: t(LocalizationKeys.PropagateAttributes),
        header: { collapsible: false },
        message: t(LocalizationKeys.PropagateEndToEnd),
        modal: true,
        onClose: onDialogClose
    };

    const cancelButtonProps: ButtonProps = {
        className: "cancel-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: false,
        onClick: onDialogClose
    }

    const saveButtonProps: ButtonProps = {
        className: "save-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: !attributes.propagateConnectorColor && !attributes.propagateLabelColor && !attributes.propagateLabelText,
        onClick: onSave
    }

    return {
        dialogProps,
        triggerColorProps, 
        labelColorProps, 
        labelTextProps,
        cancelProps: {
            cancelButtonProps,
            label: t(LocalizationKeys.Cancel)
        },
        saveProps: {
            saveButtonProps,
            label: t(LocalizationKeys.Save)
        },
    }
}

export const usePropagateAttributes = () => {
    const [propagateConnectorColor, setPropagateConnectorColor] = useState(false);
    const [propagateLabelColor, setPropagateLabelColor] = useState(false);
    const [propagateLabelText, setPropagateLabelText] = useState(false);
    const { t } = useTranslation();
    
    const onTriggerColorCheckBoxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPropagateConnectorColor(e.currentTarget.checked);
    },[]);  

    const onLabelColorCheckBoxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPropagateLabelColor(e.currentTarget.checked);
    },[]);

    const onLabelTextCheckBoxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPropagateLabelText(e.currentTarget.checked);
    },[]);

    const resetPropagationOptions = useCallback(() => {
        setPropagateConnectorColor(false);
        setPropagateLabelColor(false);
        setPropagateLabelText(false);
    }, []);

    const triggerColorProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.TriggerHousingColor),
        disabled: false,
        checked: propagateConnectorColor,
        onChange: onTriggerColorCheckBoxChange
    };

    const labelColorProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.LabelColor),
        disabled: false,
        checked: propagateLabelColor,
        onChange: onLabelColorCheckBoxChange
    };

    const labelTextProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.LabelText),
        disabled: false,
        checked: propagateLabelText,
        onChange: onLabelTextCheckBoxChange
    };

    const attributes: IPropagationAttributes = {
        propagateConnectorColor,
        propagateLabelColor,
        propagateLabelText
    }

    return {
        attributes,
        triggerColorProps,
        labelColorProps,
        labelTextProps,
        resetPropagationOptions,
    };
}