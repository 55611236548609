import React from 'react';
import { usePolarityAction } from './hooks';
import { ContainedButton } from '@orbit/button';

export const PolarityAction: React.FC = () => {
    const { polarity } = usePolarityAction();
    return (
        <ContainedButton {...polarity.buttonProps}>
            {polarity.label}
        </ContainedButton>
    )
}