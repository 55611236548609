import { IViewportState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { FitOption } from "../../../workspace/bottom-bar/fit-panel/types";
import { Side } from "../../../workspace/assembly/breakout/types";

export const incrementViewportScaleAction = (state: IViewportState, action: PayloadAction<number>) => {
    state.increment = { scale: action.payload }
}

export const setViewportScaleAction = (state: IViewportState, action: PayloadAction<number>) => {
    if (state.scale !== action.payload) {
        state.scale = action.payload;
    }
}

export const setViewportFitOptionAction = (state: IViewportState, action: PayloadAction<FitOption>) => {
    state.fitOption = { option: action.payload }
}

export const setViewportBreakoutSelectionAction = (state: IViewportState, action: PayloadAction<{ position: number, side: Side } | undefined>) => {
    if (action.payload) {
        if (state.selection && state.selection.breakout) {
            if (state.selection.breakout.side !== action.payload.side || state.selection.breakout.position !== action.payload.position) {
                state.selection = { breakout: action.payload };
            }
        } else {
            state.selection = { breakout: action.payload };
        }
    } else {
        state.selection = { breakout: undefined };
    }
}

export const setIsDraggingAction = (state: IViewportState, action: PayloadAction<boolean>) => {
    state.isDragging = action.payload;
}