import { Side } from "../../assembly/breakout/types";
import { IConnectorMap } from "./reducer/types";

export interface IAssignmentRowData {
    id: number;
    assignmentId?: number;
    className?: string;
    side: Side;
    position: number;
    connectorPosition: number;
    fiberCount: number;
    unassignedFibers: number;
    assigned?: boolean;
    selected?: boolean;
    selectionIndex: number;
    fiberAssignmentLabel: string;
    selectionLabel: string;
    connectorType: string;
    propagated?: boolean;
}

export const assignmentRowToConnectorMap = (data: IAssignmentRowData): IConnectorMap => {
    return {
        side: data.side, 
        breakoutPosition: data.position, 
        index: data.id, 
        orderIndex: data.selectionIndex, 
        connectorType: data.connectorType, 
        fiberCount: data.fiberCount, 
        unassignedFibers: data.unassignedFibers, 
        assignedIndices: [], 
    }
}

export const AssignmentPatterns = {
    Standard: "Standard",
};
export type AssignmentPattern = (typeof AssignmentPatterns)[keyof typeof AssignmentPatterns];