import { IAssemblyInfo } from "../../assembly/info/types";
import { IAssembly } from "../../assembly/types";

export interface IProjectAssemblyResponse {
    id: number;
    tncAcceptedAt: string;
    tncAcceptedVersion: string;
    lastSaveOrder: string;
	sortAscending: boolean;
    recentAssembly?: IAssembly;
    assemblies: IAssemblyInfo[];
}

export interface IProjectManagerResponse {
    id: number;
    recentAssemblyId: number;
    tncAcceptedAt: string;
    tncAcceptedVersion: string;
    lastSaveOrder: string;
	sortAscending: boolean;
    assemblies: IAssemblyEntry[];
}

export interface IAssemblyEntry {
    id: number;
    index: number;
}

export const initialProjectManagerResponse: IProjectManagerResponse = {
    id: 0,
    recentAssemblyId: 0,
    tncAcceptedAt: "",
    tncAcceptedVersion: "",
    lastSaveOrder: "",
    sortAscending: false,
    assemblies: [],
}