import React from 'react';
import { useFitPanel } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { UnfoldMore, ZoomIn } from '@mui/icons-material';
import { TextButton } from '@orbit/button';
import { Menu } from '@mui/material';

import "./FitPanel.scss";

export const FitPanel: React.FC = () => {
    const { fitIconProps, fitPanel, menuProps, fitOptions } = useFitPanel();
    return (
        <div className="panel fit-panel toggle-pointer-events">
            <IconButton {...fitIconProps} icon={<ZoomIn />} />
            <TextButton {...fitPanel.buttonProps} endIcon={<UnfoldMore />}>
                {fitPanel.label}
            </TextButton>
            <Menu anchorOrigin={{ vertical: "top", horizontal: "left" }} {...menuProps}>
                <div className='fit-menu-container'>
                    { fitOptions.map((o, i) => <TextButton key={`fit-option-${i}`} {...o.buttonProps}>{o.label}</TextButton>)}
                </div>
            </Menu>
        </div>
    )
}