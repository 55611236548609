import { useSelector } from "react-redux"
import { showConnectorAssignmentSelector } from "../../../polarity/store/selectors"
import { disabledOverlaySelector } from "../../../overlay/store/selectors";
import { useCallback } from "react";
import { showConnectorAssignment } from "../../../polarity/store/reducer";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { ButtonProps } from "@orbit/button";
import { useStoreDispatch } from "../../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { isPigtailConnector } from "../../../assembly/connector/types";
import { currentAssemblySelector } from "../../../assembly/store/selectors";

export const usePolarityAction = () => {
    const displayConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const disabled = useSelector(disabledOverlaySelector);
    const currentAssembly = useSelector(currentAssemblySelector)
    const isPigtail = isPigtailConnector(currentAssembly.sideA?.breakouts[0].furcation.groups[0].connectors[0].type as string) || 
                              isPigtailConnector(currentAssembly.sideB?.breakouts[0].furcation.groups[0].connectors[0].type as string)
    const dispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onPolarityClick = useCallback(() => {
        dispatch(showConnectorAssignment(!displayConnectorAssignment));
    }, [dispatch, displayConnectorAssignment]);

    const buttonProps: ButtonProps = {
        className: "polarity-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: disabled || isPigtail,
        onClick: onPolarityClick
    };

    const polarity = {
        buttonProps,
        label: t(LocalizationKeys.Polarity),
    };

    return { polarity };
}