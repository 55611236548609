import { IAssemblyInfoToolBarProps } from "./types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { useCallback, useState } from "react";
import { IInputDialogProps } from "../../../../ui/dialog/input/types";
import { useSelector } from "react-redux";
import { pmCurrentAssemblyInfoSelector } from "../../store/selectors";
import { userIdSelector } from "../../../store/selectors";
import { duplicateAssembly } from "../../../assembly/store/reducer";
import { useStoreDispatch } from "../../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";

export const useAssemblyInfoToolbar = (props: IAssemblyInfoToolBarProps) => {
    const { onDuplicate, onEdit, onDelete } = props;
    const userId = useSelector(userIdSelector);
    const info = useSelector(pmCurrentAssemblyInfoSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();
    
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

    const onDuplicateClick = useCallback(() => {
        setShowDuplicateDialog(true);
        if (onDuplicate) {
            onDuplicate();
        }
    }, [onDuplicate]);

    const duplicateProps = {
        className: "duplicate-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Duplicate),
        placement: "top" as TooltipPlacement,
        onClick: onDuplicateClick
    };

    const editProps = {
        className: "edit-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Edit),
        placement: "top" as TooltipPlacement,
        onClick: onEdit
    };

    const deleteProps = {
        className: "delete-icon",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Delete),
        placement: "top" as TooltipPlacement,
        onClick: onDelete
    };

    const onCloseDuplicateDialog = useCallback(() => {
        setShowDuplicateDialog(false);
    }, []);

    const onSave = useCallback((name?: string | number) => {
        if (name && info && info.assemblyId) {
            storeDispatch(duplicateAssembly(info.assemblyId, userId, name as string));
        }
        onCloseDuplicateDialog()
    }, [info, storeDispatch, userId, onCloseDuplicateDialog]);

    const name = info && info.name !== "" && info.name ? info.name + "-Copy" : `CAB #${info?.assemblyId}-Copy`;
    const duplicateDialogProps: IInputDialogProps = {
        open: showDuplicateDialog,
        onClose: onCloseDuplicateDialog,
        value: name,
        title: t(LocalizationKeys.DuplicateAssembly),
        fieldLabel: t(LocalizationKeys.AssemblyNewName),
        maxCharacterCount: 20,
        cancelProps: {
            text: t(LocalizationKeys.Cancel)
        },
        saveProps: {
            text: t(LocalizationKeys.Save),
            onClick: onSave
        }
    }

    return { duplicateProps, editProps, deleteProps, duplicateDialogProps };
}