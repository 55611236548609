import CreateAssemblyAnimation from "./resources/CreateCableAssembly.mp4";
import DeleteCableAnimation from "./resources/DeleteCable.mp4";
import DuplicateCableAnimation from "./resources/DuplicateCable.mp4";
import EditCableInformationAnimation from "./resources/EditCableInformation.mp4";
import PolarityProcessAnimation from "./resources/PolarityProcess.mp4";
import PrintReportAnimation from "./resources/PrintReport.mp4";
import PropagateAttributesAnimation from "./resources/PropagateConnectorAttributes.mp4";
import ShowHideAnnotationAnimation from "./resources/ShowHideAnnotation.mp4";
import ShowHideDrawingSpecsAnimation from "./resources/ShowHideDrawingSpecs.mp4";
import UnitsSetupAnimation from "./resources/UnitsSetup.mp4";

export const UserInteractions = {
    CreateCableAssembly: "CreateCableAssembly",
    DuplicateCable: "DuplicateCable",
    DeleteCable: "DeleteCable",
    EditCableInformation: "EditCableInformation",
    PropagateAttributes: "PropagateAttributes",
    PolarityProcess: "PolarityProcess",
    UnitsSetup: "UnitsSetup",
    PrintAReport: "PrintAReport",
    ShowHideAnnotation: "ShowHideAnnotation",
    ShowHideDrawingSpecs: "ShowHideDrawingSpecs",
} as const;

export type UserInteraction = typeof UserInteractions [keyof typeof UserInteractions];

export const getAnimation = (interaction: UserInteraction) => {
    switch (interaction) {
        case UserInteractions.CreateCableAssembly:
            return CreateAssemblyAnimation;
        case UserInteractions.DeleteCable:
            return DeleteCableAnimation;
        case UserInteractions.DuplicateCable:
            return DuplicateCableAnimation;
        case UserInteractions.EditCableInformation:
            return EditCableInformationAnimation;
        case UserInteractions.PolarityProcess:
            return PolarityProcessAnimation;
        case UserInteractions.PrintAReport:
            return PrintReportAnimation;
        case UserInteractions.PropagateAttributes:
            return PropagateAttributesAnimation;
        case UserInteractions.ShowHideAnnotation:
            return ShowHideAnnotationAnimation;
        case UserInteractions.ShowHideDrawingSpecs:
            return ShowHideDrawingSpecsAnimation;
        case UserInteractions.UnitsSetup:
            return UnitsSetupAnimation;
    }
};

