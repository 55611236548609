import { ButtonProps } from "@orbit/button";
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { resetCurrentAssembly } from "../../assembly/store/reducer";
import { currentAssemblySelector } from "../../assembly/store/selectors";
import { disabledOverlaySelector } from "../../overlay/store/selectors";
import { isPolarityModeSelector } from "../../polarity/store/selectors";
import { showAssemblyEdit, showAssemblyWizard } from "../../store/reducer";
import { resetCableSetup } from "../../wizard/store/reducer";
import { setWorkspaceUnit } from "../../assembly/length/store/reducer";
import { userUomSelector } from "../../store/selectors";

export const useActionPanel = () => {
    const isPolarityMode = useSelector(isPolarityModeSelector);
    const display = !isPolarityMode;
    const disabled = useSelector(disabledOverlaySelector);
    const { onClick } = useAddNewCable();
    const { t } = useTranslation();
    const dispatch = useStoreDispatch();

    const onAssemblyEditClick = useCallback(() => {
        dispatch(showAssemblyEdit(true));
    }, [dispatch]);

    const assemblyEditButtonProps: ButtonProps = {
        className: "assembly-edit-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled,
        onClick: onAssemblyEditClick
    };

    const assemblyEdit = {
        buttonProps: assemblyEditButtonProps,
        label: t(LocalizationKeys.EditAssembly)
    };

    const newCableIconProps = {
        className: "new-cable-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.NewAssembly),
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick
    };

    return { display, disabled, assemblyEdit, newCableIconProps };
};

export const useAddNewCable = () => {
    const assembly = useSelector(currentAssemblySelector);
    const dispatch = useStoreDispatch();
    const userUom = useSelector(userUomSelector)
    
    const onClick = useCallback(() => {
        dispatch(resetCableSetup({...assembly}));
        dispatch(resetCurrentAssembly());
        dispatch(setWorkspaceUnit(userUom));
        dispatch(showAssemblyWizard(true));
    }, [dispatch, assembly, userUom]);

    return { onClick };
};