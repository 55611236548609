import React from 'react';
import { LengthField } from '../../../../assembly/length/LengthField';
import { useOffsetLengthField } from './hooks';
import { IOffsetLengthFieldProps } from "./types";

export const OffsetLengthField: React.FC<IOffsetLengthFieldProps> = (props) => {
    const { offsetFieldProps, staggerFieldProps } = useOffsetLengthField(props);
    const className = offsetFieldProps ? "field-container horizontal" : "field-container";
    return (
        <div className={className}>
            <div className='field' >
                <LengthField {...staggerFieldProps} />
            </div>
            <div className='field' >
                {offsetFieldProps ? <LengthField {...offsetFieldProps} /> : null}
            </div>
        </div>
    )
}