import { IConnectorAssignmentRowProps } from "./types";
import { useCallback, useContext } from "react";
import { ConnectorAssignmentContext } from "../reducer/types";
import { deleteAssignment, handleSelection } from "../reducer/reducer";
import { IIconProps } from "../../../../ui/icon/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../localization/types";
import { deleteConnectorAssignment } from "../../../assembly/store/reducer";
import { showConnectorAssignment, showFiberMapping } from "../../store/reducer";
import { INavigationInfo, PolarityContext } from "../../reducer/types";
import { setupFiberMapping } from "../../reducer/reducer";
import { assignmentRowToConnectorMap } from "../types";
import { setStatus } from "../../../store/reducer";
import { Status } from "../../../store/types";
import { useStoreDispatch } from "../../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { batch } from "react-redux";

const ORANGE = "#f5770a"
const BLUE = "#0098db";
const DARK_ORANGE = "#ca651d";

export const useConnectorAssignmentRow = ({ data, editable }: IConnectorAssignmentRowProps) => {
    const assignmentContext = useContext(ConnectorAssignmentContext);
    const { state: assignmentState, dispatch: assignmentDispatch } = assignmentContext;
    const { dispatch: polarityDispatch } = useContext(PolarityContext);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const assignedIconProps = {
        className: "assigned-icon",
        palette: MainPalettes.success,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement
    };

    const fill = data.assigned ? BLUE : ORANGE;
    const stroke = data.assigned ? BLUE : DARK_ORANGE;
    const selectionIconProps: IIconProps = { text: data.selectionLabel, fill, stroke };

    const onRowClick = useCallback(() => {
        const connectorMap = assignmentRowToConnectorMap(data);
        assignmentDispatch(handleSelection(connectorMap));
    }, [assignmentDispatch, data]);

    const onButtonCellClick = (e: any) => { e.stopPropagation(); };

    const onDeleteAssignment = useCallback(async () => {
        if (data.assignmentId) {
            const assignmentId = data.assignmentId;
            batch(() => {
                storeDispatch(setStatus(Status.Synchronizing));
                assignmentDispatch(deleteAssignment(assignmentId));
                storeDispatch(deleteConnectorAssignment(assignmentId));
            });
        }
    }, [data.assignmentId, storeDispatch, assignmentDispatch]);

    const onEditFiberMap = useCallback(() => {
        const assignment = assignmentState.assignments.find(a => a.id === data.assignmentId)
        if (assignment) {
            const navigation: INavigationInfo = {
                side: data.side,
                breakoutPosition: data.position,
                connectorPosition: data.id
            }
            polarityDispatch(setupFiberMapping({ navigation, assignment }));
            storeDispatch(showFiberMapping(true));
            storeDispatch(showConnectorAssignment(false));
        }
    }, [assignmentState, data, polarityDispatch, storeDispatch]);

    const onCellClick = data.assigned && !data.selected ? onButtonCellClick : undefined;
    const deleteIconProps = {
        className: "delete-icon",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        title: editable && data.assigned ? t(LocalizationKeys.Delete) : "",
        placement: "bottom" as TooltipPlacement,
        onClick: onDeleteAssignment
    };

    const editIconProps = {
        className: "edit-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: editable && data.assigned ? t(LocalizationKeys.Edit) : "",
        placement: "bottom" as TooltipPlacement,
        onClick: onEditFiberMap
    };

    const propagationTableCellProps = {
        className: "propagation-icon",
    };

    const { propagated } = data;
    const propagationIconProps = {
        className: "link-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: propagated ? t(LocalizationKeys.PropagationSuccessful) : t(LocalizationKeys.PropagationFailed),
        placement: "bottom" as TooltipPlacement
    };

    return {
        rowProps: {
            key: data.id,
            className: data.className,
            onClick: onRowClick
        },
        assigned: data.assigned,
        assignedIconProps,
        selected: data.selected,
        selectionIconProps,
        connectorInfo: data.id + ' | ' + data.fiberAssignmentLabel,
        editable,
        deleteProps: {
            className: "delete-button",
            onClick: onCellClick
        },
        deleteIconProps,
        editProps: {
            className: "edit-button",
            onClick: onCellClick
        },
        editIconProps,
        propagated,
        propagationTableCellProps,
        propagationIconProps
    };
}