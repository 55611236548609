import LCSprite from "./resources/image_2F_LC.svg";
import SNSprite from "./resources/image_2F_SN.svg";
import MTP8_12F from "./resources/image_8-12F_MTP.svg";
import MTP24F from "./resources/image_24F_MTP.svg";
import { LC, SN, MTP, MTP12F_FP_NP, MTP12F_FP_P, MTP12F_NP, MTP12F_P, MTP24F_FP_NP, MTP24F_FP_P, MTP24F_NP, MTP24F_P, MTP8F_NP, MTP8F_P, LC_UPC, SC_UPC, SC_SIMPLEX, LC_SIMPLEX, LSH, MDC, SC_APC, SC_APC_SIMPLEX, SC_UPC_SIMPLEX, LC_APC, LC_APC_UNIBOOT, LC_APC_SIMPLEX, PIGTAIL_LEG, PIGTAIL_NO_LEG, MMC16F_P, MMC16F_NP, MMC24F_NP, MMC24F_P } from "../../../../../workspace/assembly/connector/types";
import { IPosition } from "../../../types";
import { FiberColors, MDCColors, MTPColors } from "../../../../../workspace/assembly/palette/types";
import { Aqua, Blue, Green, hexStringToNumber, IBootColor, IColor, Mustard, Yellow } from "../../../../../ui/dialog/color/types";
import { getPinnedMTPSVGString, getUnpinnedMTPSVGString, MTP24BootColor, MTPBootColor } from "../connector-sprite/MTP";
import { getSNSVGString } from "../connector-sprite/SN";
import { getLCUnibootSVGString } from "./LCUniboot";
import { getLCDuplexSVGString } from "./LCDuplex";
import { getSCDuplexSVGString } from "./SCDuplex";
import { getLCSimplexSVGString } from "./LCSimplex";
import { getSCSimplexSVGString } from "./SCSimplex";
import { getMDCSVGString } from "./MDC";
import { IConnector, Side } from "../../../../../workspace/assembly/breakout/types";
import { getLSHSVGString } from "./LSH";
import { FiberType } from "../../../../../workspace/assembly/types";
import { getPigtailSVGString } from "./Pigtail";
import { getPinnedMMCSVGString, getUnpinnedMMCSVGString } from "./MMC";

interface IMetaSprite {
    name: string;
    path: string;
    width: number;
    height: number;
}

export const MetaMDCConnectorSprite: IMetaSprite = { name: MDC, path: "", width: 13, height: 62 };
export const MetaSNConnectorSprite: IMetaSprite = { name: SN, path: SNSprite, width: 14, height: 70 };
export const MetaLCConnectorSprite: IMetaSprite = { name: LC, path: LCSprite, width: 14, height: 70 };
export const MetaLCSimplexConnectorSprite: IMetaSprite = { name: LC_SIMPLEX, path: "", width: 8, height: 47 };
export const MetaLSHConnectorSprite: IMetaSprite = { name: LSH, path: "", width: 12, height: 64 };
export const MetaLCDuplexConnectorSprite: IMetaSprite = { name: LC_UPC, path: "", width: 19, height: 86.8 };
export const MetaSCSimplexConnectorSprite: IMetaSprite = { name: SC_SIMPLEX, path: "", width: 12, height: 56 };
export const MetaSCDuplexConnectorSprite: IMetaSprite = { name: SC_UPC, path: "", width: 25, height: 95.007 };
export const MetaMTPConnectorSprite: IMetaSprite = { name: "8-12F MTP", path: MTP8_12F, width: 26, height: 100 };
export const MetaMTP24FConnectorSprite: IMetaSprite = { name: "24F MTP", path: MTP24F, width: 26, height: 100 };
export const MetaMMCConnectorSprite: IMetaSprite = { name: "16-24F MMC", path: "", width: 14, height: 68 };

export const MetaConnectorSprites = {
    MetaLCConnectorSprite,
    MetaLCDuplexConnectorSprite,
    MetaSCDuplexConnectorSprite,
    MetaSNConnectorSprite,
    MetaLSHConnectorSprite,
    MetaMTPConnectorSprite,
    MetaMTP24FConnectorSprite,
    MetaMMCConnectorSprite   
} as const;

export type MetaConnectorSprite = typeof MetaConnectorSprites [keyof typeof MetaConnectorSprites]

export type ConnectorSpriteProps = IPosition & {
    sprite: MetaConnectorSprite;
    rotation: number;
    color: IColor;
    jacketColor: number;
    type: string;
    bootColors?: IBootColor
}

export const initialConnectorSpriteProps: ConnectorSpriteProps = {
    x: 0,
    y: 0,
    rotation: Math.PI / 2,
    sprite: MetaConnectorSprites.MetaLCConnectorSprite,
    color: Blue,
    jacketColor: hexStringToNumber(Yellow.hex),
    type: "LCUniboot"
}

export function getConnectorSprite(connector: string): IMetaSprite {
    switch (connector) {
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
            return MetaMTPConnectorSprite;
        case MMC24F_NP:
        case MMC16F_NP:
        case MMC24F_P:
        case MMC16F_P:
            return MetaMMCConnectorSprite;
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return MetaMTP24FConnectorSprite;
        case LC:
        case LC_APC_UNIBOOT:
            return MetaLCConnectorSprite;
        case LC_APC:
        case LC_UPC:
            return MetaLCDuplexConnectorSprite;
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
            return MetaLCSimplexConnectorSprite;
        case SC_APC:
        case SC_UPC:
            return MetaSCDuplexConnectorSprite;
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
            return MetaSCSimplexConnectorSprite;
        case SN:
            return MetaSNConnectorSprite;
        case LSH:
            return MetaLSHConnectorSprite;
        case MDC: 
            return MetaMDCConnectorSprite;
        default:
            return MetaMTPConnectorSprite;
    }
}

export const getDefaultConnectorColor = (connectorType: string): IColor => {
    switch (connectorType) {
        case MMC24F_NP:
        case MMC16F_NP:
        case MMC24F_P:
        case MMC16F_P:
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case LC_APC:
        case LC_APC_UNIBOOT:
        case LC_APC_SIMPLEX:
            return Green;
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return Mustard;
        default:
            return Blue;
    }
}

export const getConnectorLabelColor = (connector: IConnector) => connector.labelColor ?? FiberColors[(connector.position - 1) % 12].name;
export const getConnectorLabel = (connector: IConnector) => connector.label ?? connector.position.toString(); 

export const getConnectorTypeColors = (connectorType: string, fiberType?: FiberType): IColor[] => {
    switch (connectorType) {
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return MTPColors;
        case MDC:
            return getMDCTypeColors(fiberType);
        case SN:
        case LSH:
            return [Blue];
        default:
            return FiberColors;
    }
}

export const getMDCTypeColors = (fiberType?: FiberType) => {
    if (fiberType && fiberType === "SM OS2") {
        return MDCColors.filter(c => c.name !== Aqua.name);
    }
    return MDCColors;
}

export const getConnectorColor = (connectorType: string, color: string, fiberType: FiberType) => {
    const colors = getConnectorTypeColors(connectorType, fiberType);
    return colors.find(c => color === c.name) || getDefaultConnectorColor(connectorType);
}

export const getMTPBootColor = (connectorType: string) => {
    switch(connectorType) {
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return MTP24BootColor;
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        default:
            return MTPBootColor;
    }
}

export const LargestSprite = getConnectorSprite(MTP);

export const getConnectorSVG = (type: string, color: string, side?: Side, bootColors?: IBootColor) => {
    switch (type) {
        case MMC24F_NP:
        case MMC16F_NP:
            return getUnpinnedMMCSVGString();
        case MMC24F_P:
        case MMC16F_P:
            return getPinnedMMCSVGString();
        case MTP:
        case MTP8F_P:
        case MTP12F_P:
        case MTP12F_FP_P:
        case MTP24F_P:
        case MTP24F_FP_P:
            return getPinnedMTPSVGString(color, getMTPBootColor(type));
        case MTP8F_NP:
        case MTP12F_NP:
        case MTP12F_FP_NP:
        case MTP24F_NP:
        case MTP24F_FP_NP:
            return getUnpinnedMTPSVGString(color, getMTPBootColor(type));
        case LC:
        case LC_APC_UNIBOOT:
            return getLCUnibootSVGString(color);
        case LC_APC:
        case LC_UPC:
            return getLCDuplexSVGString(color, undefined, bootColors?.bootAColor?.hex, bootColors?.bootBColor?.hex);
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
            return getLCSimplexSVGString(color);
        case MDC: 
            return getMDCSVGString(color);
        case SC_APC:
        case SC_UPC:
            return getSCDuplexSVGString(color, undefined, bootColors?.bootAColor?.hex, bootColors?.bootBColor?.hex);
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
            return getSCSimplexSVGString(color);
        case SN:
            return getSNSVGString(color, side);
        case LSH:
            return getLSHSVGString();
        case PIGTAIL_LEG:
        case PIGTAIL_NO_LEG:
            return getPigtailSVGString();
        default:
            return getLCUnibootSVGString(color);
    }
}

export const ConnectorSpriteContainer = "connector-sprite-container";