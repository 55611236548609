import { useCallback } from "react";
import { ILengthFieldProps, Length } from "../../../../assembly/length/types";
import { IOffsetLengthFieldProps } from "./types";

export const useOffsetLengthField = ({ offsetProps, staggerProps, groupIndex }: IOffsetLengthFieldProps) => {
    const { callback: staggerCallback, lengthFieldProps: staggerField } = staggerProps;
    const dispatchStagger = useCallback((length: Length) => {
        if (staggerCallback) {
            staggerCallback(length, groupIndex);
        }
    }, [staggerCallback, groupIndex]);

    const dispatchOffset = useCallback((length: Length) => {
        if (offsetProps?.callback) {
            offsetProps.callback(length, groupIndex);
        }
    }, [offsetProps, groupIndex]);

    const staggerFieldProps: ILengthFieldProps = { ...staggerField, callback: dispatchStagger }
    const offsetFieldProps: ILengthFieldProps|undefined = offsetProps ? { ...offsetProps.lengthFieldProps, callback: dispatchOffset } : undefined;

    return { offsetFieldProps, staggerFieldProps };
}