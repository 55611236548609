import React from 'react';
import { IPolarityRowProps } from './types';
import { usePolarityRow } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Delete, Edit, Lock, LockOpen } from '@mui/icons-material';
import { Typography } from '@orbit/theme-provider';
import { Divider } from '@mui/material';

import './PolaritySchemeRow.scss';

export const PolaritySchemeRow: React.FC<IPolarityRowProps> = (props) => {
    const { polarityScheme, onSchemeClick, lockProps, editProps, deleteProps } = usePolarityRow(props);
    const lockIcon = polarityScheme.isCustom ? <LockOpen /> : <Lock />;
    return (
        <div>
            <div className="polarity-schemes-row-container" onClick={() => onSchemeClick(polarityScheme)}>
                <IconButton {...lockProps} icon={lockIcon} />
                <Typography variant="subtitle1" label={polarityScheme.label} />
                { polarityScheme.isCustom ? 
                    <>
                        <IconButton {...editProps} icon={<Edit />} />
                        <IconButton {...deleteProps} icon={<Delete />} />
                    </> : null
                }
            </div>
            <Divider />
        </div>  
    )
}