import React from 'react';
import * as Pixi from 'pixi.js';
import { ArrowProps } from "./types";
import { Graphics } from '@inlet/react-pixi';
import { DrawingSide, DrawingSides } from '../../types';
import { IPosition } from '../../../cable/types';

export const Arrow: React.FC<ArrowProps> = (props) => {
    return <Graphics draw={(grfx: Pixi.Graphics) => drawArrow(grfx, props)} />
}

const drawArrow = (grfx: Pixi.Graphics, props: ArrowProps) => {
    const { coordinate, side, thickness, color, alpha } = props;
    const { x, y } = coordinate;
    const [ start, end ] = calculateArrowPoints(coordinate, side);

    grfx.clear();
    grfx.lineStyle(thickness, color, alpha);
    grfx.moveTo(start.x, start.y);
    grfx.lineTo(x, y);
    grfx.lineTo(end.x, end.y);
}

const calculateArrowPoints = (coordinate: IPosition, side: DrawingSide, offsetX: number = 3, offsetY: number = 3) => {
    const { x, y } = coordinate;
    let start = { x, y };
    let end = { x, y }
    if (side === DrawingSides.Left || side === DrawingSides.Right) {
        start.y += offsetY;
        end.y -= offsetY;
        if (side === DrawingSides.Right) {
            start.x -= offsetX;
            end.x -= offsetX;
        }
        else {
            start.x += offsetX;
            end.x += offsetX;
        }
    } else if (side === DrawingSides.Top || side === DrawingSides.Bottom) {
        start.x += offsetY;
        end.x -= offsetY;
        if (side === DrawingSides.Bottom) {
            start.y -= offsetX;
            end.y -= offsetX;
        }
        else {
            start.y += offsetX;
            end.y += offsetX;
        }
    }

    return [ start, end ];
}