import { ButtonProps } from "@orbit/button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { disabledOverlaySelector } from "../../overlay/store/selectors";
import { showTermsConditions } from "../../store/reducer";

export const useDetailsPanel = () => {
    const overlayDisabled = useSelector(disabledOverlaySelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onTNCClick = useCallback(() => {
        storeDispatch(showTermsConditions(true));
    }, [storeDispatch])

    const tncButtonProps: ButtonProps = {
        className: "tnc-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        size: "small",
        disabled: overlayDisabled,
        onClick: onTNCClick
    };

    const tnc = {
        buttonProps: tncButtonProps,
        label: t(LocalizationKeys.TermsAndConditionsTitle),
    }

    return { 
        tnc,
        details: t(LocalizationKeys.DrawingDetails)
     };
}