import { CircleMarkerProps } from "./types";
import { LineMarkerProps } from "../line/types";
import { Orientations, Unidirections } from "../types";
import { CircleProps } from "../base/circle/types";
import { useText } from "../base/text/hooks";
import { _ReactPixi } from "@inlet/react-pixi";
import { IPosition } from "../../cable/types";

export const useCircleMarker = (props: CircleMarkerProps) => {
    const { coordinate, orientation, unidirection, length, radius, color, thickness, alpha, text } = props;
    const { textStyle, textSize } = useText({ text, fill: color, fontSize: 8 });

    const lineMarkerProps: LineMarkerProps = {
        coordinate,
        orientation,
        length,
        color,
        thickness,
        alpha,
        text: "",
        startDelimiterLength: 0,
        endDelimiterLength: 0,
        unidirection
    };

    let circleCoordinate: IPosition = { x: coordinate.x, y: coordinate.y };
    if (orientation === Orientations.Vertical) {
        if (unidirection === Unidirections.Start) {
            circleCoordinate = { x: coordinate.x, y: coordinate.y + length - thickness * 2 }
        }
    } else {
        if (unidirection === Unidirections.Start) {
            circleCoordinate = { x: coordinate.x + length - thickness * 2, y: coordinate.y }
        } else {
            circleCoordinate = { x: coordinate.x + thickness * 0.5, y: coordinate.y }
        }
    }
    const circleProps: CircleProps = {
        coordinate: circleCoordinate,
        radius,
        color,
        thickness,
        alpha
    }

    const textPosition = { x: circleCoordinate.x - textSize.width, y: circleCoordinate.y - textSize.height }
    const textProps: _ReactPixi.IText = {
        text,
        style: textStyle,
        position: textPosition,
        resolution: 10,
        scale: 1
    }

    return { coordinate, lineMarkerProps, circleProps, text, textProps }
}