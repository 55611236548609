import { useEffect } from "react";
import { useStoreDispatch } from "../../store/hooks";
import { login } from "../../workspace/store/reducer";
import { AuthenticationService } from "../authentication/authentication-service"

export const useSecureRoute = () => {
    const dispatch = useStoreDispatch();
    const token = AuthenticationService.getToken();
    const secured = token && !token.isExpired();
    useEffect(() => {
        const token = AuthenticationService.getToken();
        if (token && !token.isExpired()) {
            dispatch(login({ userId: Number(token.sub), company: token.company, uom: token.uom }));
        } else {
            window.location.reload();
        }
    }, [dispatch])

    return { secured }
}