import { PayloadAction } from "@reduxjs/toolkit";
import { IAssembly } from "../../assembly/types";
import { CableSetupState } from "../cable-setup/reducer";
import { ICableSetupStore, initialCableSetupStore } from "./types";

export const setCableSetupStateAction = (state: ICableSetupStore, action: PayloadAction<CableSetupState>) => {
    state.setupState = {...action.payload };
}

export const resetCableSetupAction = (state: ICableSetupStore, action: PayloadAction<IAssembly | undefined >) => {
    state.setupState = { ...initialCableSetupStore.setupState, wizardSetup: {...initialCableSetupStore.setupState.wizardSetup, hidden: false }};
    state.setupState.wizardSetup.prevAssembly = action.payload;
}