import { createSlice } from "@reduxjs/toolkit";
import { initialTextFieldState } from "./types";
import { setStringValueAction, setNumberValueAction, setIsValidAction, setHelperTextAction, updateStringValueAction } from "./actions";

export const textFieldSlice = createSlice({
    name: "textField",
    initialState: initialTextFieldState,
    reducers: {
        setStringValue: setStringValueAction,
        updateStringValue: updateStringValueAction,
        setNumberValue: setNumberValueAction,
        setIsValid: setIsValidAction,
        setHelperText: setHelperTextAction
    }
});

export const TextFieldReducer = textFieldSlice.reducer;
export const { setStringValue, setNumberValue, setIsValid, setHelperText, updateStringValue } = textFieldSlice.actions;