
import { createContext } from "react";
import { Side, Sides } from "../../../workspace/assembly/breakout/types";
import { Unit, Units } from "../../../workspace/assembly/length/types";
import { IAssemblySide } from "../../../workspace/assembly/types";
import { useSideContext } from "./hooks";
import { LC } from "../../../workspace/assembly/connector/types";

export interface ISideProps {
    side: Side;
    unit: Unit;
    flipsideConnectorType: string;
}

export type SideProps = ISideProps & Partial<IAssemblySide>

export type ISideContext = ReturnType<typeof useSideContext>

export const initialSideProps: ISideProps = {
    side: Sides.SideA,
    unit: Units.Millimeter,
    flipsideConnectorType: LC
}

export const initialSideContext: ISideContext = {
    m: -1,
    breakouts: [],
    breakoutsProps: [],
    side: Sides.SideA,
    unit: Units.Millimeter,
    hasGroupOffsets: false,
    maxUniqueLength: 0,
    flipsideConnectorType: LC
}

export const SideContext = createContext(initialSideContext)

export const SideContainer = "side-container";