import { DialogProps, IDialogActions } from "@orbit/dialog";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { batch, useSelector } from "react-redux"
import { LocalizationKeys } from "../../localization/types";
import { AuthenticationService } from "../../services/authentication/authentication-service";
import { useStoreDispatch } from "../../store/hooks";
import { renewToken, setStatus, showIdle, showTimeOut } from "../../workspace/store/reducer";
import { showIdleSelector } from "../../workspace/store/selectors";
import { Status } from "../../workspace/store/types";
import { useTimer } from "../../workspace/tracker/timer/hooks";
import { FIVE_MINS } from "../../workspace/tracker/timer/types";

export const useIdleDialog = () => {
    const open = useSelector(showIdleSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onTimerEnd = useCallback(() => {
        batch(() => {
            storeDispatch(showIdle(false));
            storeDispatch(showTimeOut(true));
        });
    }, [storeDispatch]);

    const { counter } = useTimer(open, FIVE_MINS, onTimerEnd);

    const onClick = useCallback(async () => {
        const tokenStr = AuthenticationService.getTokenString();
        batch(() => {
            if (tokenStr) {
                storeDispatch(renewToken(tokenStr));
            }
            storeDispatch(showIdle(false));
            storeDispatch(setStatus(Status.Active));
        })
    }, [storeDispatch]);

    const dialogActions: IDialogActions = {
        confirmText: t(LocalizationKeys.BackToWork),
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        },
        onConfirmClick: onClick,
    };

    const dialogProps: DialogProps = {
        open,
        className: "idle-dialog",
        title: t(LocalizationKeys.IdledSessionTitle),
        message: t(LocalizationKeys.IdledSessionMessage, { countdown: `${counter.min}:${counter.sec}` }),
        actions: dialogActions,
    };

    return { dialogProps };
};