import React from 'react';
import { ProjectManagerPanel } from './project-manager-panel/ProjectManagerPanel';
import { StatusIndicator } from '../../ui/status/StatusIndicator';
import { FiberCounter } from './fiber-counter/FiberCounter';
import { ActionPanel } from './action-panel/ActionPanel';

import "./TopBar.scss";

export const TopBar: React.FC = () => {
    return (
        <div className="top-bar toggle-pointer-events">
            <div className="left-panel">
                <ProjectManagerPanel />
                <StatusIndicator />
            </div>
            <FiberCounter />
            <ActionPanel />
        </div>
    )
}