import { PayloadAction } from "@reduxjs/toolkit";
import { Side, Sides } from "../../../assembly/breakout/types";
import { generateFiberMapUI } from "../../connector-assignment/reducer/actions";
import { IConnectorAssignmentMap } from "../../connector-assignment/reducer/types";
import { IPinAssignmentMap, IPinMap } from "../types";
import { IFiberMappingState, initialFiberMappingNavigation, ISideNavigation } from "./types";

export const setSideNavigationAction = (state: IFiberMappingState, action: PayloadAction<ISideNavigation>) => {
    const { side } = action.payload;
    if (side === Sides.SideA) {
        state.navigation.sideA = action.payload;
    } else {
        state.navigation.sideB = action.payload;
    }
}

export const setBreakoutNavigationIndexAction = (state: IFiberMappingState, action: PayloadAction<{ side: Side, index: number }>) => {
    const { side, index } = action.payload;
    if (side === Sides.SideA) {
        state.navigation.sideA.breakout.currentIndex = index;
    } else {
        state.navigation.sideB.breakout.currentIndex = index;
    }
}

export const setConnectorNavigationIndexAction = (state: IFiberMappingState, action: PayloadAction<{ side: Side, index: number }>) => {
    const { side, index } = action.payload;
    if (side === Sides.SideA) {
        state.navigation.sideA.connector.currentIndex = index;
    } else {
        state.navigation.sideB.connector.currentIndex = index;
    }
}

export const setSelectedPinIndexAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.selectedPinIndex = action.payload;
}

export const prepareFiberAssignmentsAction = (state: IFiberMappingState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const { connectors: connectorSet, fiberMapData } = action.payload;
    const fiberMap = generateFiberMapUI(connectorSet, fiberMapData);
    state.assignments = fiberMap.pinAssignments;
    state.unused = fiberMap.unused;
}

export const handleAssignmentAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    const { navigation, assignments } = state;
    const { selectedPinIndex, sideA, sideB } = navigation;
    const unusedSideAPin = state.unused.find(p => p.side === Sides.SideA && p.pinIndex === selectedPinIndex && p.breakout.index === sideA.breakout.currentIndex && p.connector.index === sideA.connector.currentIndex)
    if (!unusedSideAPin) {
        const sideBPinIndex = action.payload;
        const assignment: IPinAssignmentMap = {
            sideAPin: {
                pinIndex: selectedPinIndex,
                side: Sides.SideA,
                breakout: {
                    index: sideA.breakout.currentIndex
                },
                connector: {
                    index: sideA.connector.currentIndex
                }
            },
            sideBPin: {
                pinIndex: sideBPinIndex,
                side: Sides.SideB,
                breakout: {
                    index: sideB.breakout.currentIndex
                },
                connector: {
                    index: sideB.connector.currentIndex
                }
            }
        };
        const existingAssignment = assignments.find(m => m.sideAPin.pinIndex === assignment.sideAPin.pinIndex && m.sideAPin.connector.index === assignment.sideAPin.connector.index);
        if (existingAssignment) {
            existingAssignment.sideBPin.pinIndex = sideBPinIndex;
        } else {
            state.assignments.push(assignment);
        }
        state.navigation.selectedPinIndex = -1;
        state.isModified = true;
    }
}

export const breakAssignmentAction = (state: IFiberMappingState) => {
    const { navigation, assignments } = state;
    const { selectedPinIndex, sideA, sideB } = navigation;
    const existingAssignment = assignments.find(m => m.sideAPin.pinIndex === selectedPinIndex && m.sideAPin.breakout.index === sideA.breakout.currentIndex && m.sideAPin.connector.index === sideA.connector.currentIndex && m.sideBPin.breakout.index === sideB.breakout.currentIndex && m.sideBPin.connector.index === sideB.connector.currentIndex);
    if (existingAssignment) {
        state.assignments = state.assignments.filter(m => m !== existingAssignment);
        state.navigation.selectedPinIndex = -1;
        state.isModified = true;
    }
}

export const handleUnusedPinAction = (state: IFiberMappingState, action: PayloadAction<{ side: Side, pinIndex: number }>) => {
    const { side, pinIndex } = action.payload;
    const { sideA, sideB } = state.navigation;
    let pin: IPinMap;
    if (side === Sides.SideA) {
        pin = {
            pinIndex,
            side,
            breakout: {
                index: sideA.breakout.currentIndex
            },
            connector: {
                index: sideA.connector.currentIndex
            }
        }
    } else {
        pin = {
            pinIndex,
            side,
            breakout: {
                index: sideB.breakout.currentIndex
            },
            connector: {
                index: sideB.connector.currentIndex
            }
        }
    }

    const existingUnusedPin = state.unused.find(p => p.side === pin.side && p.pinIndex === pin.pinIndex && p.breakout.index === pin.breakout.index && p.connector.index === pin.connector.index);
    if (existingUnusedPin) {
        state.unused = state.unused.filter(p => p !== existingUnusedPin);
    } else {
        state.unused.push(pin);
    }
    state.navigation.selectedPinIndex = -1;
    state.isModified = true;
}

export const resetNavigationAction = (state: IFiberMappingState) => {
    state.navigation = initialFiberMappingNavigation;
}

export const clearAllAction = (state: IFiberMappingState, action: PayloadAction<boolean>) => {
    state.navigation.selectedPinIndex = -1;
    state.assignments = [];
    state.unused = [];
    state.isModified = action.payload;
}