import React from 'react';
import { Divider } from '@mui/material';
import { DrawerRowProps } from './types';

import './DrawerRow.scss';

export const withDrawerRow = <T extends object>(Component: React.ComponentType<T>)
    : React.FC<T & Partial<DrawerRowProps>> => {
    return (props) => (
        <div className={`drawer-row-container`}>
            <div className={props.selected ? "drawer-row selected" : "drawer-row"}>
                {<Component {...props} />}
            </div>
            <Divider variant="middle" />
        </div>
    )
}