import { Dispatch } from "react";
import { AnyAction } from "redux";

export interface IBreakoutDetailsState {
    selectedLegs: number[]
}

export const initialBreakoutDetailsState: IBreakoutDetailsState = {
    selectedLegs: []
}

export interface IBreakoutDetailsContext {
    state: IBreakoutDetailsState,
    dispatch: Dispatch<AnyAction>
}

export const initialBreakoutDetailsContext: IBreakoutDetailsContext = {
    state: initialBreakoutDetailsState,
    dispatch: () => {}
}