import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { getDefaultConnectorColor } from "../../../pixi/cable/breakout/connector-furcation/connector-sprite/types";
import { initialConnector } from "../../../pixi/cable/types";
import { IBreakout, IConnector } from "../../assembly/breakout/types";
import { SN, MTP, MTP8F_NP, MTP8F_P, MTP12F_NP, MTP12F_P, MTP12F_FP_NP, MTP12F_FP_P, MTP24F_NP, MTP24F_P, MTP24F_FP_NP, MTP24F_FP_P, LC, LC_UPC, SC_UPC, LSH, LC_APC, LC_APC_UNIBOOT, LC_APC_SIMPLEX, PIGTAIL_NO_LEG, PIGTAIL_LEG } from "../../assembly/connector/types";
import { currentAssemblySelector } from "../../assembly/store/selectors";
import { IAssembly } from "../../assembly/types";
import { IConnectorRowProps } from "./types";

export const useConnectorTable = () => {
    const currentAssembly = useSelector(currentAssemblySelector);
    const connectorRows: IConnectorRowProps[] = [];

    const { t } = useTranslation();

    if (currentAssembly) {
        const extractedConnectorMappings = extractConnectorMapping(currentAssembly, t);
        connectorRows.push(...extractedConnectorMappings);
    }

    return { connectorRows };
}

export const getUniqueConnectors = (breakouts: IBreakout[]) => {
    return breakouts.flatMap(breakout => breakout.furcation)
                    .flatMap(furcation => furcation.groups)
                    .flatMap(group => group.connectors)
                    .filter((c, i, self) => self.findIndex(s => s.type === c.type) === i);
}

export const extractConnectorMapping = (currentAssembly: IAssembly, t: TFunction): IConnectorRowProps[] => {
    const { sideA, sideB } = currentAssembly;
    if (sideA && sideB) {
        const sideAConnectors = getUniqueConnectors(sideA.breakouts);
        const sideBConnectors = getUniqueConnectors(sideB.breakouts);

        if (sideAConnectors.length > sideBConnectors.length) {
            return sideAConnectors.map((aC, index) => {
                const sideBConnector: IConnector = sideBConnectors[index] ?? initialConnector;
                const sideAKey = `${getConnectorKeyInfo(aC.type, t)}/${aC.type}-${index}`;
                const sideBKey = `${getConnectorKeyInfo(sideBConnector.type, t)}/${sideBConnector.type}-${index}`;
                let row: IConnectorRowProps = { 
                    sideAConnector: { 
                        key: sideAKey, 
                        type: aC.type, 
                        color: aC.color ?? getDefaultConnectorColor(aC.type).hex,
                        bootColors: aC.bootColors
                    }, 
                    sideBConnector: { 
                        key: sideBKey, 
                        type: sideBConnector.type, 
                        color: sideBConnector.color ?? getDefaultConnectorColor(sideBConnector.type).hex,
                        bootColors: sideBConnector.bootColors
                    } 
                };
                if (index >= sideBConnectors.length) {
                    row.sideBConnector = { key: `${index}`, type: "", color: "" };
                }
                return row;
            })    
        } else if (sideAConnectors.length < sideBConnectors.length) {
            return sideBConnectors.map((bC, index) => {
                const sideAConnector: IConnector = sideAConnectors[index] ?? initialConnector;
                const sideBKey = `${getConnectorKeyInfo(bC.type, t)}/${bC.type}-${index}`;
                const sideAKey = `${getConnectorKeyInfo(sideAConnector.type, t)}/${sideAConnector.type}-${index}`;
                let row: IConnectorRowProps= { 
                    sideAConnector: { 
                        key: sideAKey, 
                        type: sideAConnector.type, 
                        color: sideAConnector.color ?? getDefaultConnectorColor(sideAConnector.type).hex,
                        bootColors: sideAConnector.bootColors
                    }, 
                    sideBConnector: { 
                        key: sideBKey, 
                        type: bC.type, 
                        color: bC.color ?? getDefaultConnectorColor(bC.type).hex,
                        bootColors: bC.bootColors
                    } 
                };
                if (index >= sideAConnectors.length) {
                    row.sideAConnector = { key: `${index}`, type: "", color: "" };
                }
                return row;
            })
        } else {
            return sideAConnectors.map((aC, index) => {
                const sideBConnector = sideBConnectors[index];
                const sideAKey = `${getConnectorKeyInfo(aC.type, t)}/${aC.type}-${index}`;
                const sideBKey = `${getConnectorKeyInfo(sideBConnector.type, t)}/${sideBConnector.type}-${index}`;
                const row: IConnectorRowProps = { 
                    sideAConnector: { 
                        key: sideAKey, 
                        type: aC.type, 
                        color: aC.color ?? getDefaultConnectorColor(aC.type).hex,
                        bootColors: aC.bootColors
                    }, 
                    sideBConnector: { 
                        key: sideBKey, 
                        type: sideBConnector.type, 
                        color: sideBConnector.color ?? getDefaultConnectorColor(sideBConnector.type).hex,
                        bootColors: sideBConnector.bootColors
                    }
                };
                return row;
            })
        }
    }

    return [];
}

const getConnectorKeyInfo = (connectorType: string, t: TFunction) => {
    switch (connectorType) {
        case SN:
            return t(LocalizationKeys.TactilePattern);
        case LSH:
            return t(LocalizationKeys.SideView);
        case PIGTAIL_LEG:
        case PIGTAIL_NO_LEG:
            return ""
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
        case LC:
        case LC_APC_UNIBOOT:
        case LC_APC_SIMPLEX:
        case LC_APC:
        case LC_UPC:
        case SC_UPC:
        default:
            return t(LocalizationKeys.KeyUp);
    }
}