import React from "react"

export const SCSimplex: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="560" viewBox="0 0 12 56">
            <g transform="translate(10 44)" fill="#045eaa" stroke="#484646" strokeWidth="1">
                <rect width="2" height="5" stroke="none"/>
                <rect x="0.5" y="0.5" width="1" height="4" fill="none"/>
            </g>
            <rect width="2" height="5" transform="translate(0 44)" fill="none"/>
            <g transform="translate(-1196.526 -1576)" fill="#f1f1f2">
                <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
                <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
            </g>
            <rect width="2" height="3" transform="translate(5 53)" fill="#c4c4c4"/>
            <g transform="translate(1 27)" fill="#045eaa" stroke="#484646" strokeWidth="1">
                <rect width="10" height="26" stroke="none"/>
                <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
            </g>
            <rect width="2" height="1" transform="translate(7 4)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(7 8)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(7 12)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(3 4)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(3 8)" fill="#484646"/>
            <rect width="2" height="1" transform="translate(3 12)" fill="#484646"/>
            <rect width="4" height="1" transform="translate(4 2)" fill="#484646"/>
            <rect width="4" height="1" transform="translate(4 6)" fill="#484646"/>
            <rect width="4" height="1" transform="translate(4 10)" fill="#484646"/>
            <rect width="4" height="1" transform="translate(4 14)" fill="#484646"/>
            <rect width="8" height="1" transform="translate(2 30)" fill="#484646"/>
            <rect width="8" height="1" transform="translate(2 32)" fill="#484646"/>
            <rect width="8" height="1" transform="translate(2 34)" fill="#484646"/>
            <rect width="8" height="1" transform="translate(2 36)" fill="#484646"/>
            <g transform="translate(3 44)" fill="#f1f1f2" stroke="#484646" strokeWidth="1">
                <rect width="6" height="7" stroke="none"/>
                <rect x="0.5" y="0.5" width="5" height="6" fill="none"/>
            </g>
            <g transform="translate(4 42)" fill="#f1f1f2" stroke="#484646" strokeWidth="1">
                <rect width="4" height="3" stroke="none"/>
                <rect x="0.5" y="0.5" width="3" height="2" fill="none"/>
            </g>
            <g transform="translate(3 50)" fill="#045eaa" stroke="#484646" strokeWidth="1">
                <rect width="6" height="3" stroke="none"/>
                <rect x="0.5" y="0.5" width="5" height="2" fill="none"/>
            </g>
        </svg>
    )
}

export const getSCSimplexSVGString = (color: string) => 
`
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="560" viewBox="0 0 12 56">
        <g transform="translate(10 44)" fill="#045eaa" stroke="#484646" stroke-width="1">
            <rect width="2" height="5" stroke="none"/>
            <rect x="0.5" y="0.5" width="1" height="4" fill="none"/>
        </g>
        <rect width="2" height="5" transform="translate(0 44)" fill="none"/>
        <g transform="translate(-1196.526 -1576)" fill="#f1f1f2">
            <path d="M 1206.0263671875 1603.5 L 1199.0263671875 1603.5 L 1199.0263671875 1600.010498046875 L 1200.005981445312 1576.5 L 1205.046752929688 1576.5 L 1206.0263671875 1600.010498046875 L 1206.0263671875 1603.5 Z" stroke="none"/>
            <path d="M 1200.485595703125 1577 L 1199.5263671875 1600.020751953125 L 1199.5263671875 1603 L 1205.5263671875 1603 L 1205.5263671875 1600.020751953125 L 1204.567138671875 1577 L 1200.485595703125 1577 M 1199.5263671875 1576 L 1205.5263671875 1576 L 1206.5263671875 1600 L 1206.5263671875 1604 L 1198.5263671875 1604 L 1198.5263671875 1600 L 1199.5263671875 1576 Z" stroke="none" fill="#484646"/>
        </g>
        <rect width="2" height="3" transform="translate(5 53)" fill="#c4c4c4"/>
        <g transform="translate(1 27)" fill="${color}" stroke="#484646" stroke-width="1">
            <rect width="10" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="9" height="25" fill="none"/>
        </g>
        <rect width="2" height="1" transform="translate(7 4)" fill="#484646"/>
        <rect width="2" height="1" transform="translate(7 8)" fill="#484646"/>
        <rect width="2" height="1" transform="translate(7 12)" fill="#484646"/>
        <rect width="2" height="1" transform="translate(3 4)" fill="#484646"/>
        <rect width="2" height="1" transform="translate(3 8)" fill="#484646"/>
        <rect width="2" height="1" transform="translate(3 12)" fill="#484646"/>
        <rect width="4" height="1" transform="translate(4 2)" fill="#484646"/>
        <rect width="4" height="1" transform="translate(4 6)" fill="#484646"/>
        <rect width="4" height="1" transform="translate(4 10)" fill="#484646"/>
        <rect width="4" height="1" transform="translate(4 14)" fill="#484646"/>
        <rect width="8" height="1" transform="translate(2 30)" fill="#484646"/>
        <rect width="8" height="1" transform="translate(2 32)" fill="#484646"/>
        <rect width="8" height="1" transform="translate(2 34)" fill="#484646"/>
        <rect width="8" height="1" transform="translate(2 36)" fill="#484646"/>
        <g transform="translate(3 44)" fill="#f1f1f2" stroke="#484646" stroke-width="1">
            <rect width="6" height="7" stroke="none"/>
            <rect x="0.5" y="0.5" width="5" height="6" fill="none"/>
        </g>
        <g transform="translate(4 42)" fill="#f1f1f2" stroke="#484646" stroke-width="1">
            <rect width="4" height="3" stroke="none"/>
            <rect x="0.5" y="0.5" width="3" height="2" fill="none"/>
        </g>
        <g transform="translate(3 50)" fill="${color}" stroke="#484646" stroke-width="1">
            <rect width="6" height="3" stroke="none"/>
            <rect x="0.5" y="0.5" width="5" height="2" fill="none"/>
        </g>
    </svg>
`