import React from 'react';
import { useStatusIndicator } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { LinearProgressIndeterminate } from '@orbit/progress-indicator';
import { FontTokens, MainPalettes, Typography } from '@orbit/theme-provider';
import { Status, WorkspaceStatus } from '../../workspace/store/types';
import { CloudDownload, CloudUpload, EmojiEmotions, HourglassBottom, Print } from '@mui/icons-material';

import './StatusIndicator.scss';

export const StatusIndicator: React.FC = () => {
    const { display, background, color, icon, iconProps, label } = useStatusIndicator();
    if (!display) {
        return null;
    }
    return (
        <div className="status-indicator">
            <div className="status-container" style={{ background, color }}>
                <div className="status-label">
                    { icon ? <IconButton {...iconProps} icon={icon} /> : null }
                    <Typography variant="h6" label={label} fontToken={FontTokens.none} />
                </div>
                <LinearProgressIndeterminate palette={MainPalettes.primary} />
            </div>
        </div>
    );
}

export const getIndicatorStatusIcon = (status: WorkspaceStatus) => {
    switch (status) {
        case Status.InitialLoad:
        case Status.Loading:
            return <CloudDownload />;
        case Status.Saving:
            return <CloudUpload />;
        case Status.Saved:
            return <EmojiEmotions />
        case Status.Synchronizing:
            return <HourglassBottom />;
        case Status.Printing:
            return <Print />;
    }
}