import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SideContext } from "../side/types";
import { IPosition } from "../types";
import * as Pixi from 'pixi.js';
import { initialBezierCurveProps } from "../../bezier-curve/types";
import { BreakoutProps, IBreakoutContext } from "./types";
import { Orientations } from "../../markers/types";
import { LineMarkerProps } from "../../markers/line/types";
import { getBreakoutHeight, getConnectorHeight } from "../side/hooks";
import { useText } from "../../markers/base/text/hooks";
import { normalTrunkHeight, textOffset } from "../cable-base/hooks";
import { LC, PIGTAIL_NO_LEG } from "../../../workspace/assembly/connector/types";
import { Length, toLengthString } from "../../../workspace/assembly/length/types";
import { ILengthMarkerProps } from "../../markers/length/types";
import { ColorContext } from "../../color/types";
import { SelectionContext } from "../../interactions/selection/types";
import { setSelectedBreakout } from "../../interactions/selection/reducers";
import { glowFilter, hoverFilter } from "../../interactions/selection/Selection";
import { CablePositionContext } from "../position/types";
import { BoundingBoxContext, IContainerBox } from "../../interactions/bounding-box/types";
import { setBreakoutPosition } from "../position/reducer";
import { _ReactPixi } from "@inlet/react-pixi";
import { ViewportContext } from "../../viewport/types";
import { StatusContext } from "../../status/types";
import { hexStringToNumber } from "../../../ui/dialog/color/types";
import { JacketColors } from "../../../workspace/assembly/palette/types";
import { MarkerSettingsContext } from "../../../workspace/store/types";
import { getTrunkHeight } from "./connector-furcation/hooks";

const borderThickness = 0.5;

const shieldHeight = 5;
const shieldWidth = 2 * shieldHeight;
const jacketHeight = 6.5;
const jacketWidth = 2.7 * jacketHeight;
const jacketRubberWidth = 2;
const jacketRubberHeight = 6;

const normalTrunkThickness = 4.5;
const trunkBorderThickness = 1;

export const breakoutOffsetWidth = 30;
export let connectorOffset = 0;

export const mmMaxTolerance = 60;
export const mmMinTolerance = 0;
export const inMaxTolerance = 2.36;
export const inMinTolerance = 0;

export const useBreakout = (props: BreakoutProps) => {
    const { position, label, furcation, trunk, height, offset, jacketColor } = props;
    const { cableColor: { sideATrunkColor, sideBTrunkColor, outlineColor, shieldColor, furcationColor } } = useContext(ColorContext);
    const { m } = useContext(SideContext);
    const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });
    const [connectorAnchorPoint, setConnectorAnchorPoint] = useState({ x: 0, y: 0 });
    const [bezierTrunkStart, setBezierCurveStart] = useState({ ...initialBezierCurveProps });
    const [bezierTrunkMid, setBezierCurveMid] = useState({ ...initialBezierCurveProps });
    const [bezierTrunkEnd, setBezierCurveEnd] = useState({ ...initialBezierCurveProps });
    const selection = useSelection(props);
    const breakoutJacketColor = JacketColors.find(c => c.name === jacketColor);
    const defaultTrunkColor = m > 0 ? sideBTrunkColor : sideATrunkColor;
    const trunkColor = breakoutJacketColor ? hexStringToNumber(breakoutJacketColor.hex) : defaultTrunkColor;
    const { showTransitions } = useContext(MarkerSettingsContext);

    useCablePositionDispatcher(props, connectorAnchorPoint)

    let nbGroups = 0;
    let nbConnectors = 0;
    let nbConnectorsPerGroup = 0;
    let nbSpares = 0;
    let connectorHeight = 0;
    let trunkThickness = normalTrunkThickness;
    let isPigtailWithNoLeg = false;
    let trunkBorderWidth = trunkBorderThickness;
    if (furcation && furcation.groups.length > 0) {
        nbGroups = furcation.groups.length;
        nbConnectors = furcation.groups.map(g => g.connectors.length).reduce((prev, cur) => { return prev + cur; }); // Inludes spares
        nbConnectorsPerGroup = furcation.groups[0].connectors.length;
        nbSpares = furcation.groups[nbGroups - 1]?.connectors[0]?.spare ? furcation.groups[nbGroups - 1].connectors.length : 0;
        const connectorType = furcation.groups[0].connectors[0].type;
        isPigtailWithNoLeg = connectorType === PIGTAIL_NO_LEG
        connectorHeight = getConnectorHeight(connectorType);
        connectorOffset = connectorType === LC ? 6 : 7;
        trunkThickness = showTransitions && isPigtailWithNoLeg ? normalTrunkHeight : showTransitions ? normalTrunkThickness : getTrunkHeight(connectorType);
        trunkBorderWidth = showTransitions && isPigtailWithNoLeg ? 2 : trunkBorderThickness;
    }
    const breakoutPosition = position ?? 0;
    const trunkLength: Length = trunk?.length ?? { id: -1, value: 0, unit: "mm" };

    const context: IBreakoutContext = { position: breakoutPosition, nbGroups, nbConnectors, nbConnectorsPerGroup, nbSpares };
    const lengthMarkerProps = useLengthMarker(connectorAnchorPoint, nbConnectors, connectorHeight, trunkLength, breakoutPosition);

    const breakoutLabelText = label ? label : breakoutPosition.toString(); // Should consider breakout label when we implement it
    const { textStyle: breakoutLabelTextStyle, textSize: breakoutLabelTextSize } = useText({ text: breakoutLabelText, fill: 0x000000, fontSize: 8 });
    const connectorTextX = m > 0 ?
        (connectorAnchorPoint.x - jacketWidth - jacketRubberWidth - (shieldWidth) * 0.5) - breakoutLabelTextSize.width :
        connectorAnchorPoint.x - m * (jacketWidth + jacketRubberWidth + (shieldWidth) * 0.5);

    const connectorTextY = connectorAnchorPoint.y - breakoutLabelTextSize.height * 0.5;
    const breakoutLabelTextProps: _ReactPixi.IText = {
        text: breakoutLabelText,
        style: breakoutLabelTextStyle,
        position: { x: connectorTextX + (m * 3), y: connectorTextY },
        resolution: 20,
        scale: 0.5
    }

    useEffect(() => {
        const cp: IPosition = { x: 0, y: 0 };
        let cp2 = cp;
        let dst = cp;

        if (trunk && trunk.length.value) {
            const dstX = m * 30;
            const dstY = height * 0.5;
            dst = { x: dstX, y: dstY };

            const cp2X = m * 20;
            const cp2Y = 0;
            cp2 = { x: cp2X, y: cp2Y };
        }

        setBezierCurveStart({
            cp, cp2, dst,
            line: {
                color: trunkColor,
                thickness: trunkThickness
            },
            outline: {
                color: outlineColor,
                thickness: trunkBorderWidth + trunkThickness
            }
        });
    }, [trunk, m, height, trunkColor, outlineColor, trunkThickness, trunkBorderWidth]);

    useEffect(() => {
        const cp: IPosition = { x: bezierTrunkStart.dst.x, y: bezierTrunkStart.dst.y };
        let cp2 = cp;
        let dst = cp;

        if (trunk && trunk.length.value) {
            const dstX = cp.x + m * 30;
            const dstY = height;
            dst = { x: dstX, y: dstY };

            const my = Math.sign(dstY);
            const cp2X = dstX - m * 20.3;
            const cp2Y = dstY - my * 3;
            cp2 = { x: cp2X, y: cp2Y };
        }

        setBezierCurveMid({
            cp, cp2, dst,
            line: {
                color: trunkColor,
                thickness: trunkThickness
            },
            outline: {
                color: outlineColor,
                thickness: trunkBorderWidth + trunkThickness
            }
        });
    }, [bezierTrunkStart, trunk, m, height, trunkColor, outlineColor, trunkThickness, trunkBorderWidth]);

    useEffect(() => {
        const cp: IPosition = { x: bezierTrunkMid.dst.x - m * 1.1, y: bezierTrunkMid.dst.y };
        let cp2 = cp;
        let dst = cp;

        if (trunk && trunk.length.value) {
            const dstX = m * offset;
            const dstY = height;
            dst = { x: dstX, y: dstY };

            const cp2X = cp.x;
            const cp2Y = cp.y;
            cp2 = { x: cp2X, y: cp2Y };
        }
        
        setBezierCurveEnd({
            cp, cp2, dst,
            line: {
                color: trunkColor,
                thickness: trunkThickness
            },
            outline: {
                color: outlineColor,
                thickness: trunkBorderWidth + trunkThickness
            }
        });
    }, [bezierTrunkMid, trunk, m, offset, height, trunkColor, outlineColor, trunkThickness, trunkBorderWidth]);

    useEffect(() => {
        if (props.x || props.y) {
            setContainerPosition({
                x: m * (props.x),
                y: props.y
            });
        }
    }, [m, props.x, props.y]);

    useEffect(() => {
        let anchorPoint: IPosition = { x: 0, y: 0 }
        if (trunk && trunk.length.value) {
            anchorPoint = { x: m * offset, y: height }  
        }
        setConnectorAnchorPoint(anchorPoint);
    }, [trunk, m, offset, height]);

    const drawShield = useCallback((g: Pixi.Graphics) => {
        const startX = (connectorAnchorPoint.x - m * (jacketWidth + jacketRubberWidth));
        g.clear();
        g.lineStyle(borderThickness, outlineColor);
        g.beginFill(shieldColor);

        const dimensions = (startX - m * shieldWidth) - (m * breakoutLabelTextSize.width);
        const path: Pixi.Point[] = [
            new Pixi.Point(startX, connectorAnchorPoint.y - shieldHeight / 2),
            new Pixi.Point(dimensions + (m * 6), connectorAnchorPoint.y - shieldHeight / 2),
            new Pixi.Point(dimensions + (m * 6), connectorAnchorPoint.y + shieldHeight / 2),
            new Pixi.Point(startX, connectorAnchorPoint.y + shieldHeight / 2),
        ];

        g.drawPolygon(path);
        g.endFill();

    }, [m, connectorAnchorPoint, shieldColor, outlineColor, breakoutLabelTextSize]);

    const drawFurcation = useCallback((g: Pixi.Graphics) => {
        const rubberStartX = connectorAnchorPoint.x - m * (jacketWidth)
        g.clear();
        g.lineStyle(borderThickness, outlineColor);
        g.beginFill(outlineColor);

        const rubberPath: Pixi.Point[] = [
            new Pixi.Point(rubberStartX, connectorAnchorPoint.y - jacketRubberHeight / 2),
            new Pixi.Point(rubberStartX - m * jacketRubberWidth, connectorAnchorPoint.y - jacketRubberHeight / 2),
            new Pixi.Point(rubberStartX - m * jacketRubberWidth, connectorAnchorPoint.y + jacketRubberHeight / 2),
            new Pixi.Point(rubberStartX, connectorAnchorPoint.y + jacketRubberHeight / 2),
        ];

        g.drawPolygon(rubberPath);
        g.endFill();

        g.beginFill(furcationColor)
        const furcationStartX = connectorAnchorPoint.x
        const furcationPath: Pixi.Point[] = [
            new Pixi.Point(furcationStartX, connectorAnchorPoint.y - jacketHeight / 2),
            new Pixi.Point(furcationStartX - m * jacketWidth, connectorAnchorPoint.y - jacketHeight / 2),
            new Pixi.Point(furcationStartX - m * jacketWidth, connectorAnchorPoint.y + jacketHeight / 2),
            new Pixi.Point(furcationStartX, connectorAnchorPoint.y + jacketHeight / 2),
        ];

        g.drawPolygon(furcationPath);
        g.endFill();

    }, [m, connectorAnchorPoint, outlineColor, furcationColor]);

    return {
        context,
        selection,
        lengthMarkerProps,
        bezierTrunkStart,
        bezierTrunkMid,
        bezierTrunkEnd,
        containerPosition,
        drawShield,
        drawFurcation,
        showTransitions,
        connectorAnchorPoint,
        breakoutLabelTextProps,
        isPigtailWithNoLeg,
        selectFilterProps: {
            ...containerPosition,
            visible: containerPosition.x !== 0 || containerPosition.y !== 0,
            hoverFilter: selection.hoverFilters,
            selectFilter: selection.selectionFilters,
            interactive: true,
            interactiveChildren: true

        },
        connectorFurcationProps: {
            ...props,
            ...connectorAnchorPoint,
            ...selection
        }
    }
}

const useLengthMarker = (connectorAnchorPoint: IPosition, nbConnectors: number, connectorHeight: number, trunkLength: Length, breakoutPosition: number): ILengthMarkerProps => {
    const { m, breakouts } = useContext(SideContext);
    let coordinateX = 0;
    let coordinateY = 0;
    let offsetY = 0;
    let startDelimiterLength = 0;
    let endDelimiterLength = 0;
    if (breakouts.length > 1) {
        offsetY = nbConnectors > 1 ? connectorHeight * 0.25 + nbConnectors * connectorOffset * 0.5 + 1 : connectorHeight * 0.25 + 1;
        coordinateY = connectorAnchorPoint.y * 0.5 - offsetY;
        if (m > 0) {
            startDelimiterLength = -coordinateY * 2;
            endDelimiterLength = offsetY * 2;
        } else {
            coordinateX = connectorAnchorPoint.x * 0.5;
            startDelimiterLength = offsetY * 2;
            endDelimiterLength = -coordinateY * 2;
        }
    } else {
        if (nbConnectors > 1) {
            offsetY = connectorHeight * 0.25 + nbConnectors * connectorOffset * 0.5 + 1;
            startDelimiterLength = offsetY * 2;
            endDelimiterLength = offsetY * 2;
        } else {
            offsetY = 25;
            startDelimiterLength = 40;
            endDelimiterLength = 40;
        }
        coordinateX = m > 0 ? 0 : connectorAnchorPoint.x * 0.5;
        coordinateY = connectorAnchorPoint.y * 0.5 - offsetY;
    }
    const length = m * connectorAnchorPoint.x;
    const text = toLengthString(trunkLength);
    const lineMarkerProps: LineMarkerProps = {
        coordinate: { x: coordinateX, y: coordinateY },
        orientation: Orientations.Horizontal,
        length,
        text,
        thickness: 1,
        color: 0x000000,
        alpha: 1,
        startDelimiterLength,
        endDelimiterLength,
        toleranceProps: { tolerance: { min: mmMinTolerance, max: mmMaxTolerance } }
    };

    const lengthText = m > 0 ? "B" + breakoutPosition : "A" + breakoutPosition;
    const { textStyle, textSize } = useText({ text: lengthText, fill: 0x000000, fontSize: 16 });
    const textX = m > 0 ? length * 0.5 - textSize.width : coordinateX * 2 + length * 0.5 - textSize.width;
    const textY = coordinateY * 2 - textSize.height - textOffset;
    const lengthPosition: IPosition = { x: textX, y: textY };
    const textProps = {
        text: lengthText,
        style: textStyle,
        position: lengthPosition,
        resolution: 10,
        scale: 1
    }

    const showMarker = trunkLength.value > 0;

    return { showMarker, lineMarkerProps, textProps };
}

const useSelection = (props: BreakoutProps) => {
    const { position, side } = props;
    const { state, dispatch } = useContext(SelectionContext)
    const [selectionFilters, setSelectionFilters] = useState({ ...glowFilter, enabled: false });
    const [hoverFilters, setHoverFilters] = useState({ ...hoverFilter, enabled: false });
    const { state: viewportState } = useContext(ViewportContext);
    const { state: statusState, dispatchBreakoutEdit } = useContext(StatusContext);
    const { isWorkspaceInteractable } = statusState;
    const { showBreakoutEdit } = statusState.dialogs

    const onSingleClick = useCallback(() => {
        if (side && position !== undefined && !viewportState.isDragging && isWorkspaceInteractable) {
            dispatch(setSelectedBreakout({ side, position }))
        }
    }, [position, side, viewportState.isDragging, isWorkspaceInteractable, dispatch]);

    const onDoubleClick = useCallback(() => {
        if (!viewportState.selection?.breakout && side && position !== undefined && !viewportState.isDragging && isWorkspaceInteractable && !showBreakoutEdit) {
            dispatch(setSelectedBreakout({ side, position }));
            dispatchBreakoutEdit(true);
        }
    }, [viewportState.selection, position, side, viewportState.isDragging, isWorkspaceInteractable, showBreakoutEdit, dispatch, dispatchBreakoutEdit]);

    const { triggerClick } = useClickEvents(onSingleClick, onDoubleClick);

    const mousedown = useCallback((e: Pixi.InteractionEvent) => {
        triggerClick();
    }, [triggerClick]);

    const mouseover = useCallback((e: Pixi.InteractionEvent) => {
        if (isWorkspaceInteractable) {
            setHoverFilters({ ...hoverFilter, enabled: true })
        }
    }, [isWorkspaceInteractable])

    const mouseout = useCallback((e: Pixi.InteractionEvent) => {
        if (isWorkspaceInteractable) {
            setHoverFilters({ ...hoverFilter, enabled: false })
        }
    }, [isWorkspaceInteractable])

    useEffect(() => {
        if (isWorkspaceInteractable) {
            const { selectedBreakout } = state;
            if (selectedBreakout && selectedBreakout.position === position && selectedBreakout.side === side) {
                setSelectionFilters({ ...glowFilter, enabled: true })
            }
            else {
                setSelectionFilters({ ...glowFilter, enabled: false })
            }
        } else {
            setSelectionFilters({ ...glowFilter, enabled: false })
        }
    }, [state, position, side, isWorkspaceInteractable])

    return { mousedown, mouseover, mouseout, selectionFilters, hoverFilters }
}

const useClickEvents = (triggerSingleClick: () => void, triggerDoubleClick: () => void) => {
    let delay = 250;
    let nbClicks = useRef(0);
    const triggerClick = useCallback(() => {
        nbClicks.current++;
        setTimeout(() => {
            if (nbClicks.current === 1) {
                triggerSingleClick();
            } else if (nbClicks.current === 2) {
                triggerDoubleClick();
            }
            nbClicks.current = 0;
        }, delay);
    }, [triggerSingleClick, triggerDoubleClick, delay]);

    return { triggerClick };
}

const useCablePositionDispatcher = ({ position, side }: BreakoutProps, connectorFurcationPoint: IPosition) => {
    const { dispatch } = useContext(CablePositionContext)
    const trunkBox = useContext(BoundingBoxContext)
    const { breakouts } = useContext(SideContext)

    useEffect(() => {
        const { x, y, width, height } = trunkBox
        const hasPosition = position !== undefined && side !== undefined
        const hasTrunkBox = x !== undefined && y !== undefined && width && height
        if (hasPosition && hasTrunkBox) {
            const breakoutBox: IContainerBox = {
                height: height!,
                width: width! - connectorFurcationPoint.x,
                x: x! + connectorFurcationPoint.x,
                y: y!
            }

            const trunkBox: IContainerBox = {
                x: x!,
                y: y!,
                width: width!,
                height: height!
            }

            dispatch(setBreakoutPosition({
                side: side!,
                breakout: {
                    breakoutBox,
                    trunkBox,
                    position: position!,
                },
                breakouts
            }))
        }

    }, [dispatch, trunkBox, position, side, connectorFurcationPoint, breakouts])
}

export const useBreakoutComponent = (props: BreakoutProps) => {
    const { containerPosition } = useBreakout(props);
    const breakoutHeight = getBreakoutHeight({ furcation: props.furcation!, position: props.position!, side: props.side!, trunk: props.trunk! })
    const breakoutPosition = { ...containerPosition, y: props.height }
    const bufferHeight = props.height ? -(Math.abs(props.height) - 0.5 * breakoutHeight) : 0
    const bufferWidth = props.trunk && props.trunk.length.value ? 0 : -30
    const bufferBox: IContainerBox = {
        x: 0,
        y: 0,
        width: bufferWidth,
        height: bufferHeight
    }

    return {
        componentProps: props,
        adjacentProps: props,
        hocProps: {
            ...breakoutPosition,
            bufferBox
        }
    }
}