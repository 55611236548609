import { ButtonProps } from "@orbit/button"
import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../localization/types";
import { useStoreDispatch } from "../../../store/hooks";
import { currentAssemblyPolarityProgressSelector, currentConnectorAssignmentsSelector } from "../../assembly/store/selectors";
import { showConnectorAssignment, showFiberMapping } from "../store/reducer";
import { isPolarityModeSelector, showConnectorAssignmentSelector, showFiberMappingSelector } from "../store/selectors";
import { INavigationInfo, PolarityContext } from "../reducer/types";
import { setupFiberMapping } from "../reducer/reducer";
import { workspaceSavingSelector } from "../../store/selectors";

export const usePolarityToolbar = () => {
    const { t } = useTranslation();
    const { dispatch: polarityDispatch } = useContext(PolarityContext);
    const display = useSelector(isPolarityModeSelector);
    const displayConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const displayFiberMapping = useSelector(showFiberMappingSelector);
    const { percentage, percentageText, exceeded } = useSelector(currentAssemblyPolarityProgressSelector);
    const currentConnectorAssignments = useSelector(currentConnectorAssignmentsSelector);
    const workspaceSaving = useSelector(workspaceSavingSelector);
    const storeDispatch = useStoreDispatch();

    const progressProps = {
        className: exceeded ? "progress-bar exceeded" : "progress-bar",
        title: t(LocalizationKeys.AssignedFibers),
        percentage,
        percentageText
    };


    const onConnectorAssignmentClick = useCallback(() => {
        storeDispatch(showConnectorAssignment(true));
    }, [storeDispatch]);

    const connectorAssignmentButtonProps: ButtonProps = {
        className: displayConnectorAssignment ? " connector-assignment-button active" : " connector-assignment-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        onClick: onConnectorAssignmentClick
    };

    const connectorAssignment = {
        buttonProps: connectorAssignmentButtonProps,
        label: t(LocalizationKeys.ConnectorAssignment)
    };

    const onFiberPositionClick = useCallback(() => {
        if (currentConnectorAssignments.length) {
            const assignment = currentConnectorAssignments[0];
            const con = assignment.connectors.sideAMapping[0];
            const navigation: INavigationInfo = {
                side: "SideA",
                breakoutPosition: con.breakoutPosition,
                connectorPosition: con.index
            }
            polarityDispatch(setupFiberMapping({ navigation, assignment }));
            storeDispatch(showFiberMapping(true));
            storeDispatch(showConnectorAssignment(false));
        }
    }, [currentConnectorAssignments, polarityDispatch, storeDispatch]);
    
    const fiberPositionButtonProps: ButtonProps = {
        className: displayFiberMapping ? " fiber-position-button active" : " fiber-position-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: currentConnectorAssignments.length === 0 || workspaceSaving,
        onClick: onFiberPositionClick
    };

    const fiberPosition = {
        buttonProps: fiberPositionButtonProps,
        label: t(LocalizationKeys.FiberPosition)
    };

    const onClose = useCallback(() => {
        if (displayConnectorAssignment) {
            storeDispatch(showConnectorAssignment(false));
        }
        if (displayFiberMapping) {
            storeDispatch(showFiberMapping(false));
        }
    }, [displayConnectorAssignment, displayFiberMapping, storeDispatch]);

    const closeProps = {
        className: "close-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: t(LocalizationKeys.Close),
        placement: "bottom" as TooltipPlacement,
        onClick: onClose
    };

    return { display, progressProps, connectorAssignment, fiberPosition, closeProps };
}