import { IPosition } from "../cable/types";
import { initialCableColor } from "../color/types";

export interface IBezierCurveProps {
    cp: IPosition,
    cp2: IPosition,
    dst: IPosition,
    line: { color: number, thickness: number},
    outline: { color: number, thickness: number}
}

export const initialBezierCurveProps: IBezierCurveProps = {
    cp: { x: 0, y: 0 },
    cp2: { x: 0, y: 0 },
    dst: { x: 0, y: 0 },
    line: {
        color: initialCableColor.jacketColor,
        thickness: 7
    },
    outline: {
        color: initialCableColor.outlineColor,
        thickness: 8
    }
}

export const BezierCurveGraphics = "bezier-curve-graphics";