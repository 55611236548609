import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens, useOrbitTheme } from "@orbit/theme-provider";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../localization/types";
import { showStatusIndicatorSelector, statusSelector } from "../../workspace/store/selectors";
import { Status } from "../../workspace/store/types";
import { getIndicatorStatusIcon } from "./StatusIndicator";

export const useStatusIndicator = () => {
    const display = useSelector(showStatusIndicatorSelector);
    const status = useSelector(statusSelector);
    const icon = getIndicatorStatusIcon(status);
    const { paper: { background }, color } = useOrbitTheme({ palette: MainPalettes.primary, token: MainThemeTokens.main });
    const { t } = useTranslation();

    const iconProps = {
        className: "status-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement
    };

    const label = useMemo(() => {
        switch (status) {
            case Status.InitialLoad:
            case Status.Loading:
                return `${t(LocalizationKeys.Loading)} ...`;
            case Status.Saving:
                return `${t(LocalizationKeys.Saving)} ...`;
            case Status.Saved:
                return `${t(LocalizationKeys.Saved)}!`;
            case Status.Synchronizing:
                return `${t(LocalizationKeys.Synchronizing)} ...`;
            case Status.Printing:
                return `${t(LocalizationKeys.Printing)} ...`;
            default:
                return "";
        }
    }, [status, t]);

    return {
        display,
        background,
        color,
        icon,
        iconProps,
        label
    };
}