import React from 'react';
import { DocumentGraphicsContainer, DocumentGraphicsProps } from './types';
import { Container } from '@inlet/react-pixi';
import { Cable } from '../cable/Cable';
import { CablePositionContext } from '../cable/position/types';

export const DocumentGraphics: React.FC<DocumentGraphicsProps> = (props) => {
    const { cablePosition, position, loaded } = props;
    return (
        <CablePositionContext.Provider value={cablePosition}>
            <Container name={DocumentGraphicsContainer} {...position}>
                <Container scale={3}>
                    {loaded && <Cable {...props} /> }
                </Container>
            </Container>
        </CablePositionContext.Provider>
    );
}