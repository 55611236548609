export interface IDrawingProps {
    thickness: number;
    color: number;
    alpha?: number;
}

export const DrawingSides = {
    Left: "left",
    Right: "right",
    Top: "top",
    Bottom: "bottom"
} as const;

export type DrawingSide = typeof DrawingSides[keyof typeof DrawingSides];

export const Orientations = {
    Horizontal: "horizontal",
    Vertical: "vertical"
} as const;

export type Orientation = typeof Orientations[keyof typeof Orientations];

export const Unidirections = {
    Start: "start",
    End: "end"
} as const;

export type Unidirection = typeof Unidirections[keyof typeof Unidirections];

export const ArrowStyles = {
    Unidirectional: "Unidirectional",
    Bidirectional: "Bidirectional"
}

export type ArrowStyle = typeof ArrowStyles[keyof typeof ArrowStyles];