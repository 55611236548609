export interface ITextFieldState {
    stringValue: string;
    numberValue?: number;
    isValid: boolean;
    helperText: string;
}

export const initialTextFieldState: ITextFieldState = {
    stringValue: "",
    isValid: true,
    helperText: ""
}