import { IAssemblyInfo } from "../../assembly/info/types";

export const DrawerStatus = {
    Home: "home",
    Edit: "edit",
    Details: "details"
} as const;

export type ProjectDrawerStatus = typeof DrawerStatus [keyof typeof DrawerStatus];

export interface ProjectManagerState {
    open: boolean;
    status: ProjectDrawerStatus;
    recentAssemblyId: number;
    tncAcceptedAt: string;
    tncAcceptedVersion: string;
    lastSaveOrder: string;
    sortAscending: boolean;
    currentAssemblyInfo?: IAssemblyInfo;
}

export const initialProjectManager: ProjectManagerState = {
    open: false,
    status: DrawerStatus.Home,
    recentAssemblyId: -1,
    tncAcceptedAt: "",
    tncAcceptedVersion: "",
    lastSaveOrder: "",
    sortAscending: false
}