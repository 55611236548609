import React from 'react';
import { useAssemblyInfoDrawer } from './hooks';
import { Drawer } from '@orbit/drawer';
import { DrawerHeader } from '../../../ui/drawer/drawer-header/DrawerHeader';
import { FilledTextField } from '@orbit/text-field';
import { ContainedButton, OutlinedButton, TextButton } from '@orbit/button';
import { ChevronLeft } from '@mui/icons-material';
import { Typography } from '@orbit/theme-provider';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';

import './AssemblyInfoDrawer.scss';

export const AssemblyInfoDrawer : React.FC = () => {
    const { drawerProps, header, name, description, partNumber, back, cancel, save } = useAssemblyInfoDrawer();
    const { t } = useTranslation();

    return (
        <Drawer variant="temporary" {...drawerProps}>
            <DrawerHeader {...header} />
            <div className="page-title">
                <Typography variant="h6" label={t(LocalizationKeys.AssemblyInfo)} />
            </div>
            <div className="field-container">
                <FilledTextField {...name} />
            </div>
            <div className="field-container">
                <FilledTextField {...description} />
            </div>
            <div className="field-container">
                <FilledTextField {...partNumber} />
            </div>
            <div className="info-buttons">
                <OutlinedButton {...back.buttonProps} startIcon={<ChevronLeft />}>
                    {back.label}
                </OutlinedButton>
                <div className="right-info-buttons">
                    <TextButton {...cancel.buttonProps}>
                        {cancel.label}
                    </TextButton>
                    <ContainedButton {...save.buttonProps}>
                        {save.label}
                    </ContainedButton>
                </div>
            </div>
        </Drawer>
    );
}