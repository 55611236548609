import React from "react";
import { Dialog } from "@orbit/dialog";
import { Checkbox } from "@orbit/checkbox";
import { ContainedButton, TextButton } from "@orbit/button";
import { usePropagationDialog } from "./hooks";

import "./PropagationDialog.scss";

export const PropagationDialog: React.FC = () => {
    const { dialogProps, triggerColorProps, labelColorProps, labelTextProps, cancelProps, saveProps } = usePropagationDialog();
    return (
        <Dialog {...dialogProps}>
            <div className="connectorAttributes-container">
                <Checkbox {...triggerColorProps}/>
                <Checkbox {...labelColorProps}/>
                <Checkbox {...labelTextProps}/>
            </div>
            <div className="buttons-container">
                <TextButton {...cancelProps.cancelButtonProps}>
                    {cancelProps.label}
                </TextButton>
                <ContainedButton {...saveProps.saveButtonProps}>
                    {saveProps.label}
                </ContainedButton>
            </div>
        </Dialog>
    )
}