import React from 'react';
import { IDrawerSubHeaderProps } from './types';
import { useDrawerSubHeader } from './hooks';
import { FormControl, MenuItem, Select } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { ContainedButton } from '@orbit/button';
import { useTranslation } from 'react-i18next/';
import { LocalizationKeys } from '../../../localization/types';
import { Typography } from '@orbit/theme-provider';

import { ReactComponent as SortAscending } from "./resources/icon_sort_asc.svg"
import { ReactComponent as SortDescending } from "./resources/icon_sort_desc.svg"

import "./DrawerSubHeader.scss"

export const DrawerSubHeader: React.FC<IDrawerSubHeaderProps> = (props) => {
    const { sort, sortAscending, sortOrderIconProps, newAssembly } = useDrawerSubHeader(props);
    const { t } = useTranslation();
    const sortOrderIcon = sortAscending ? <SortAscending /> : <SortDescending />;
    return (
        <div className="drawer-sub-header">
            <Typography variant="h6" label={t(LocalizationKeys.CableCount, { count: props.cableCount })} />
            <div className="sort-container right-align">
                <div className="sort-label">
                    <Typography variant="body2" label={t(LocalizationKeys.SortBy)} />
                </div>
                <FormControl className='select'>
                    <Select {...sort.select}>
                        { sort.options.map((o, i) => <MenuItem key={`sort-type-${i}`} value={o}>{o.toUpperCase()}</MenuItem>)}
                    </Select>
                </FormControl>
                <IconButton {...sortOrderIconProps} icon={sortOrderIcon} />
                <ContainedButton {...newAssembly.buttonProps}>
                    {newAssembly.label}
                </ContainedButton>
            </div>
        </div>
    )
}