import { createContext } from "react";
import { IBreakout, Side } from "../../../workspace/assembly/breakout/types";
import { IContainerBox, initialContainerBox } from "../../interactions/bounding-box/types";
import { IPosition } from "../types";

export type SetBreakoutPositionActionArgs = { side: Side, breakout: IBreakoutPosition, breakouts: IBreakout[] }
export interface IBreakoutPosition {
    breakoutBox: IContainerBox,
    position: number,
    trunkBox: IContainerBox
}

export interface IPositionIndex {
    positionIndex: number,
    positionOffset: number
}

export type LengthPositions =  { [length: number]: IPositionIndex }
export type SidePositions = { [side: string] : LengthPositions }

export interface ICablePosition {
    center: IPosition,
    sides: { [side: string]: IBreakoutPosition[] },
    sidePositions: SidePositions
}

export const initialCablePostion: ICablePosition = {
    center: initialContainerBox,
    sides: {},
    sidePositions: {}
}

export interface ICablePositionContext {
    state: ICablePosition,
    dispatch: React.Dispatch<any>
}

export const CablePositionContext = createContext<ICablePositionContext>({ state: initialCablePostion, dispatch: () => { } })