import React from 'react';
import { useSetupHeader } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Close } from '@mui/icons-material';
import { FontTokens, Typography } from '@orbit/theme-provider';

import "./SetupHeader.scss";

export const SetupHeader: React.FC<{ title: string, step: string }> = ({ title, step }) => {
    const { displayClose, closeProps } = useSetupHeader();
    return (
        <div className="wizard-header">
            <div className="top-container">
                <div className="title-container">
                    <div className="setup-title">
                        <Typography variant="h6" label={title} fontToken={FontTokens.none} />
                    </div>
                    <div className="setup-step">
                        <Typography variant="caption" label={step} fontToken={FontTokens.none} />
                    </div>
                </div>
                <div className="close-container">
                    { displayClose && <IconButton {...closeProps} icon={<Close />} /> }
                </div>
            </div>
        </div>
    )
}