import React from 'react';
import { ILegTableProps } from './types';
import { useLegTable } from './hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';
import { Units } from '../../assembly/length/types';

export const LegTable: React.FC<ILegTableProps> = (props) => {
    const { side, endLabel, rows } = useLegTable(props);
    const { t } = useTranslation();
    return (
        <table id={`leg-table-${side}`}>
            <thead>
                <tr>
                    <th colSpan={8}>{endLabel}</th>
                </tr>
                <tr>
                    <th>{`${t(LocalizationKeys.Leg)} #`}</th>
                    <th>{t(LocalizationKeys.Breakout)}</th>
                    <th>{`${t(LocalizationKeys.Leg)} ID`}</th>
                    <th>{t(LocalizationKeys.LabelColor)}</th>
                    <th>{t(LocalizationKeys.Label)}</th>
                    <th>{t(LocalizationKeys.LengthUnit, { unit: Units.Millimeter })}</th>
                    <th>{t(LocalizationKeys.LengthUnit, { unit: "in" })}</th>
                    <th>{t(LocalizationKeys.JacketColor)}</th>
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((r, index) => (
                        <tr key={index + 1}>
                            <td>{r.legNumber}</td>
                            <td>{r.breakout}</td>
                            <td>{r.legId}</td>
                            <td>{r.labelColor}</td>
                            <td>{r.label}</td>
                            <td>{r.lengthMM}</td>
                            <td>{r.lengthIN}</td>
                            <td>{r.jacketColor}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}