import { TooltipPlacement } from "@orbit/icon-button";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux"
import { useStoreDispatch } from "../../../../store/hooks";
import { loadAssembly, setCurrentAssembly } from "../../../assembly/store/reducer";
import { assembliesInfosSelector, currentAssemblySelector } from "../../../assembly/store/selectors";
import { setStatus, showAssemblyWizard } from "../../../store/reducer";
import { Status } from "../../../store/types";
import { CableSetupContext } from "../reducer";
import { setWorkspaceUnit } from "../../../assembly/length/store/reducer";
import { userUomSelector } from "../../../store/selectors";

export const useSetupHeader = () => {
    const storeDispatch = useStoreDispatch();
    const { state } = useContext(CableSetupContext);
    const assembly = useSelector(currentAssemblySelector);
    const assemblies = useSelector(assembliesInfosSelector);
    const userUom = useSelector(userUomSelector)

    const displayClose = assembly.id !== undefined && assemblies.length > 0;

    const onClick = useCallback(() => {
        storeDispatch(showAssemblyWizard(false));
        const previousAssembly = state.wizardSetup.prevAssembly;
        if (previousAssembly && assemblies.find(a => a.assemblyId === previousAssembly.id)) {
            storeDispatch(setWorkspaceUnit(previousAssembly.assemblyInfo?.uom?.length ? previousAssembly.assemblyInfo.uom : userUom))
            storeDispatch(setCurrentAssembly(previousAssembly))
        }
        else if (assemblies.length && assemblies[0].assemblyId) {
            storeDispatch(setStatus(Status.Loading))
            storeDispatch(loadAssembly(assemblies[0].assemblyId!, userUom))
        }
        
    }, [storeDispatch, state.wizardSetup.prevAssembly, assemblies, userUom])

    const closeProps = {
        className: "close-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        onClick 
    };

    return { displayClose, closeProps }
}