import React from 'react';
import { ILengthFieldProps } from './types';
import { useLengthField } from './hooks';
import { FilledTextField } from '@orbit/text-field';
import { MainPalettes } from '@orbit/theme-provider';
import { FormControl, FormHelperText } from '@mui/material';

export const LengthField = (props: ILengthFieldProps) => {
    const field = useLengthField(props);
    return (
        <div className='field-container'>
            <FormControl>
                <FilledTextField
                    palette={MainPalettes.primary}
                    label={field.label}
                    value={field.value}
                    error={!field.isValid}
                    onChange={field.onChange}
                    disabled={field.disabled}
                />
                <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
        </div>
    )
}