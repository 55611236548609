import { IAnnotationRowProps } from "./types";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { useStoreDispatch } from "../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { updateAssemblyCalloutDesc } from "../../assembly/store/reducer";
import { annotationsListSelector, currentAssemblyAnnotationSelector, currentAssemblyIdSelector } from "../../assembly/store/selectors";
import { IAssemblyAnnotation } from "../../assembly/annotation/types";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { workspaceSavingSelector } from "../../store/selectors";

export const useAnnotationRow = (props: IAnnotationRowProps) => {
    const { data } = props;
    const annotations = useSelector(annotationsListSelector);
    const assemblyAnnotation = useSelector(currentAssemblyAnnotationSelector)
    const assemblyId = useSelector(currentAssemblyIdSelector) as number;
    const workspaceSaving = useSelector(workspaceSavingSelector)
    const storeDispatch = useStoreDispatch();

    const onButtonClick = useCallback(async () => {
        storeDispatch(setStatus(Status.Synchronizing));
        const newAnnotations = annotations.includes(data.number) ? annotations.filter(a => a !== data.number) : [...annotations, data.number]
        const annotation: IAssemblyAnnotation = { ...assemblyAnnotation as IAssemblyAnnotation, assemblyId, calloutVisibility : newAnnotations};
        storeDispatch(updateAssemblyCalloutDesc(annotation));
    }, [annotations, assemblyAnnotation, assemblyId, data.number, storeDispatch]);
    
    const buttonProps = {
        className: "annotation-visibility-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: workspaceSaving || data.disabled,
        onClick: onButtonClick
    };

    return { annotations, buttonProps, data };
}