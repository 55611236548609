import { createSelector } from "@reduxjs/toolkit";
import { rootSelector } from "../../../store/selectors";


export const viewportBridgeSelector = createSelector(
    rootSelector,
    root => root.viewportBridge
)

export const viewportScalePercentSelector = createSelector(
    viewportBridgeSelector,
    bridge => {
        const scale = bridge.scale;
        const scalePercent = (scale * 100).toFixed(1) + ' %';
        return scalePercent;
    }
)

export const viewportIncrementSelector = createSelector(
    viewportBridgeSelector,
    bridge => bridge.increment
)

export const viewportFitOptionSelector = createSelector(
    viewportBridgeSelector,
    bridge => bridge.fitOption
)

export const viewportBreakoutSelectionSelector = createSelector(
    viewportBridgeSelector,
    bridge => bridge.selection?.breakout
)