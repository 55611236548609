import React from 'react';
import { useProjectManagerPanel } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { Menu } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/types';
import { CorningLogo } from './types';
import { FontTokens, Typography } from '@orbit/theme-provider';
import { Divider } from '@mui/material';

import "./ProjectManagerPanel.scss"

export const ProjectManagerPanel: React.FC = () => {
    const { t } = useTranslation();
    const { drawerIconProps, cableName, partNumber } = useProjectManagerPanel();
    return (
        <div className="project-manager-panel toggle-pointer-events">
            <IconButton {...drawerIconProps} icon={<Menu />} />
            <img className="panel-item logo non-selectable" src={CorningLogo} alt="Corning"/>
            <div className="panel-item cab-title">
                <Typography variant="h6" label={t(LocalizationKeys.CabTitle)} fontToken={FontTokens.none} />
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="panel-item cable-name non-selectable">
                <Typography variant="subtitle1" label={cableName ?? ""} />
            </div>
            { partNumber ? 
                <div className="panel-item part-number non-selectable">
                    <Typography variant="subtitle2" label={partNumber} fontToken={FontTokens.none} />
                </div> 
            : null }
        </div>
    )
}