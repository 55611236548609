import { getSpareDefaultText, IBreakout, IConnector, Sides } from "../../types";
import { ILegRowProps } from "../row/types";
import { IBreakoutDetailsContext } from "../store/types";
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector, batch } from "react-redux";
import { AllColors, FiberColors } from "../../../palette/types";
import { Blue, DisabledGray, IColor, IColorDialogProps } from "../../../../../ui/dialog/color/types";
import { getConnectorTypeColors, getDefaultConnectorColor } from "../../../../../pixi/cable/breakout/connector-furcation/connector-sprite/types";
import { setConnector, updateConnector, updateConnectors } from "../../../store/reducer";
import { BreakoutDetailsContext, clearSelection, setSelectedLegs } from "../store/reducer";
import { useTranslation } from "react-i18next";
import { currentFiberTypeSelector, selectedBreakoutSelector, sideSparesSelectorFactory, sortedBreakoutsSelector } from "../../../store/selectors";
import { IBreakoutDetailsTableHeaderProps, ILegRowData, initialLegRow } from "../types";
import { initialConnector } from "../../../../../pixi/cable/types";
import { convertTo, Length, toLengthString } from "../../../length/types";
import { NavigationBarReducer, setCurrentIndex, setDisabled } from "../../../../../ui/navigation/store/reducer";
import { initialNavigationBarState } from "../../../../../ui/navigation/store/types";
import { setViewportBreakoutSelection } from "../../../../../pixi/viewport/store/reducer";
import { LocalizationKeys } from "../../../../../localization/types";
import { INavigationBarProps } from "../../../../../ui/navigation/types";
import { isPigtailConnector, LSH, MMC16F_NP, MMC16F_P, MMC24F_NP, MMC24F_P, PIGTAIL_LEG, PIGTAIL_NO_LEG, SN } from "../../../connector/types";
import { unitSelector } from "../../../length/store/selectors";
import { setStatus } from "../../../../store/reducer";
import { Status } from "../../../../store/types";
import { useStoreDispatch } from "../../../../../store/hooks";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";
import { CheckboxProps } from "@orbit/checkbox";

export const useLegTable = () => {
    const breakoutDetailsContext = useContext(BreakoutDetailsContext);
    const breakout = useSelector(selectedBreakoutSelector);
    const { legRows } = useLegRows(breakout);
    const { colorDialogProps, masterCheckboxProps, tableHeaderProps, connectorColorIcon, labelColorIcon } = useTableHeader(breakout, legRows, breakoutDetailsContext)
    const { navigationBarProps } = useBreakoutNavigationBar(breakoutDetailsContext, breakout);
    
    return { colorDialogProps, masterCheckboxProps, tableHeaderProps, connectorColorIcon, labelColorIcon, legRows, navigationBarProps };
}

const useTableHeader = (breakout: IBreakout, legRows: ILegRowProps[], breakoutDetailsContext: IBreakoutDetailsContext) => {
    const { onConnectorPaletteClick, onLabelPaletteClick, colorDialogProps } = useColorDialog(breakout, legRows, breakoutDetailsContext);
    const { state, dispatch } = breakoutDetailsContext;
    const { selectedLegs } = state;
    const { t } = useTranslation();
    const { unit : primaryUnit } = useSelector(unitSelector);

    const hasSelection = selectedLegs.length > 0;
    const hasSNConnectors = hasConnectorType(legRows, SN);
    const hasLSHConnectors = hasConnectorType(legRows, LSH);
    const hasMMCConnectors = hasConnectorType(legRows, MMC16F_NP) || hasConnectorType(legRows, MMC16F_P) ||
                             hasConnectorType(legRows, MMC24F_NP) || hasConnectorType(legRows, MMC24F_P);

    const breakoutSidePosition = breakout.side === Sides.SideA ? `A${breakout.position}` : `B${breakout.position}`;
    const breakoutLengthLabel = `${breakoutSidePosition} (${primaryUnit})`;

    const isPigtail = isPigtailConnector(breakout.furcation.groups[0].connectors[0].type) 
    
    const tableHeaderProps: IBreakoutDetailsTableHeaderProps = {
        connectorLabel: t(LocalizationKeys.Connector),
        groupLabel: t(LocalizationKeys.Group),
        connectorColorLabel: t(LocalizationKeys.ConnectorColor),
        label: t(LocalizationKeys.Label),
        labelColorLabel: t(LocalizationKeys.LabelColor),
        breakoutLengthLabel,
        legLengthLabel: `${breakoutSidePosition}' (${primaryUnit})`,
        totalLengthLabel: t(LocalizationKeys.TotalLength, { units: primaryUnit }),
    };

    const connectorColorIcon = {
        className: "connector-color-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: !hasSelection || hasSNConnectors || hasLSHConnectors || isPigtail || hasMMCConnectors,
        onClick: onConnectorPaletteClick
    };

    const labelColorIcon = {
        className: "label-color-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: !hasSelection,
        onClick: onLabelPaletteClick
    };

    const onMasterCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let selectedLegs: number[] = [];
        if (e.currentTarget.checked) {
            selectedLegs = legRows.map(r => r.data.id)
        }
        dispatch(setSelectedLegs(selectedLegs));
    }, [legRows, dispatch]);

    const isMasterChecked = selectedLegs.length === legRows.length;
    const masterCheckboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        checked: isMasterChecked,
        indeterminate: !isMasterChecked && hasSelection,
        onChange: onMasterCheckboxChanged
    };

    return { colorDialogProps, masterCheckboxProps, tableHeaderProps, connectorColorIcon, labelColorIcon };
}

const useLegRows = (breakout: IBreakout) => {
    const sideSpares = useSelector(sideSparesSelectorFactory(breakout.side));
    const [focusedRow, setFocusedRow] = useState(initialLegRow);
    const storeDispatch = useStoreDispatch();
    const { unit: primaryUnit } = useSelector(unitSelector);

    const legRows : ILegRowProps[] = useMemo(() => {
        if (breakout) {
            const legs = getLegsRowData(breakout, sideSpares, primaryUnit);
            const focusedConnector = getConnectorFromBreakout(breakout, focusedRow.groupPosition, focusedRow.connectorPosition);

            const onRowFocus = (e: React.FocusEvent<HTMLTableRowElement>) => {
                const rowIndex = e.currentTarget.rowIndex;
                setFocusedRow(legs[rowIndex - 1]);
            };

            const onRowBlur = () => {
                batch(() => {
                    storeDispatch(setStatus(Status.Synchronizing));
                    storeDispatch(updateConnector({ ...focusedConnector}));
                });
                setFocusedRow(initialLegRow);
            };

            const legLengthCallback = (legLength: Length) => {
                if (legLength.value === 0) {
                    return;
                }
                const updatedConnector = { ...focusedConnector, length: { ...focusedConnector.length!, value: legLength.value } };
                storeDispatch(setConnector({ side: breakout.side, breakoutPosition: breakout.position, groupPosition: focusedRow.groupPosition, connector: updatedConnector }));
            };

            const legLabelCallback = (legLabel: string) => {
                if (legLabel.length === 0 || legLabel === focusedConnector.label) {
                    return;
                }
                const updatedConnector = {...focusedConnector, label: legLabel };
                storeDispatch(setConnector({ side: breakout.side, breakoutPosition: breakout.position, groupPosition: focusedRow.groupPosition, connector: updatedConnector }));
            }

            return legs.map(l => {
                return {
                    data: l,
                    onFocus: onRowFocus,
                    onBlur: onRowBlur,
                    legLengthCallback,
                    legLabelCallback
                }
            });
        }

        return [];
    }, [breakout, sideSpares, storeDispatch, focusedRow.groupPosition, focusedRow.connectorPosition, primaryUnit]);

    return { legRows };
}

const getLegsRowData = (breakout: IBreakout, sideSpares: IConnector[], unit: string): ILegRowData[] => {
    const { trunk, furcation } = breakout;
    const breakoutLength = Number(toLengthString(convertTo(trunk.length, unit)));
    const rows: ILegRowData[] = [];

    let legId = 1;
    for (let i = 0; i < furcation.groups.length; i++) {
        const group = furcation.groups[i];
        for (let j = 0; j < group.connectors.length; j++) {
            const connector = group.connectors[j];

            const connectorIndex = (connector.position - 1) % 12;
            const connectorColor = connector.type === PIGTAIL_LEG ? DisabledGray : AllColors.find(c => c.hex === connector.color || c.name === connector.color) || getDefaultConnectorColor(connector.type);
            let label = connector.spare ? getSpareDefaultText(connector, sideSpares) : connector.position.toString();
            if (connector.label) {
                label = connector.label;
            }
            const labelColor = FiberColors.find(c => c.hex === connector.labelColor || c.name === connector.labelColor) || FiberColors[connectorIndex];
            const legLength = convertTo(connector.length ?? group.length, unit)
            const totalLength= Number(toLengthString({value: breakoutLength + legLength.value, unit: unit}));
            rows.push({
                id: legId,
                connectorType: connector.type,
                connectorPosition: connector.position,
                groupPosition: group.position,
                groupLabel: connector.spare ? "N/A" : group.position.toString(),
                connectorColor,
                label,
                labelColor,
                breakoutLength,
                legLength,
                totalLength
            });
            legId++
        }
    }

    return rows;
}

export const getConnectorFromBreakout = (breakout: IBreakout, groupPosition: number, connectorPosition: number): IConnector => {
    let connector = initialConnector;
    const group = breakout.furcation.groups.find(g => g.position === groupPosition);
    if (group) {
        let existingConnector = group.connectors.find(c => c.position === connectorPosition);
        if (existingConnector) {
            if (!existingConnector.length) {
                existingConnector = { ...existingConnector, length: { ...group.length, id: 0 } };
            }
            connector = existingConnector;
        }
    }
    return connector;
}

const useColorDialog = (breakout: IBreakout, legRows: ILegRowProps[], breakoutDetailsContext: IBreakoutDetailsContext) => {
    const { state: breakoutDetailsState, dispatch: breakoutDetailsDispatch } = breakoutDetailsContext;
    const { selectedLegs } = breakoutDetailsState;
    const fiberType = useSelector(currentFiberTypeSelector);
    const storeDispatch = useStoreDispatch();
    const [showColorDialog, setShowColorDialog] = useState(false);
    const [isConnectorColor, setIsConnectorColor] = useState(false);

    const colorProps = useMemo(() => {
        let colors = FiberColors;
        let connectorColors = [Blue]
        let connectorColor = Blue;
        let labelColors = [Blue];
        let labelColor = Blue;
        if (selectedLegs.length > 0) {
            const selectedLegRows = selectedLegs.length > 0 ? legRows.filter(r => selectedLegs.includes(r.data.id)) : [legRows[0]];
            colors = getConnectorTypeColors(selectedLegRows[0].data.connectorType, fiberType);
            connectorColors = Array.from(new Set(selectedLegRows.map(r => r.data.connectorColor)));
            connectorColor = connectorColors[0];
            labelColors = Array.from(new Set(selectedLegRows.map(r => r.data.labelColor)));
            labelColor = labelColors[0];
        }
        return {
            colors,
            connectorColors,
            connectorColor,
            labelColors,
            labelColor
        }
    }, [selectedLegs, fiberType, legRows])
    

    const openColorDialog = useCallback((isConnectorColor: boolean) => {
        setShowColorDialog(true);
        setIsConnectorColor(isConnectorColor);
    }, []);

    const onConnectorPaletteClick = useCallback(() => {
        openColorDialog(true);
    }, [openColorDialog]);

    const onLabelPaletteClick = useCallback(() => {
        openColorDialog(false);
    }, [openColorDialog]);

    const closeColorDialog = useCallback(() => {
        setShowColorDialog(false);
    }, [])

    const onConnectorColorButtonClick = useCallback((color: IColor) => {
        const selectedRows = legRows.filter(c => selectedLegs.includes(c.data.id));
        if (selectedRows.length > 0) {
            storeDispatch(setStatus(Status.Synchronizing));
            const connectorsToUpdate: IConnector[] = [];
            for (let selectedRow of selectedRows) {
                const { data } = selectedRow;
                const connector = getConnectorFromBreakout(breakout, data.groupPosition, data.connectorPosition);
                const updatedConnector: IConnector = { ...connector, color: color.name };
                connectorsToUpdate.push(updatedConnector);
                storeDispatch(setConnector({ side: breakout.side, breakoutPosition: breakout.position, groupPosition: data.groupPosition, connector: updatedConnector }));
            }

            storeDispatch(updateConnectors(connectorsToUpdate));
            breakoutDetailsDispatch(clearSelection());
            closeColorDialog();
        }
    }, [legRows, selectedLegs, breakout, storeDispatch, breakoutDetailsDispatch, closeColorDialog]);

    const onLabelColorButtonClick = useCallback((color: IColor) => {
        const selectedRows = legRows.filter(c => selectedLegs.includes(c.data.id));
        if (selectedRows.length > 0) {
            storeDispatch(setStatus(Status.Synchronizing));
            const connectorsToUpdate: IConnector[] = [];
            for (let selectedRow of selectedRows) {
                const { data } = selectedRow;
                const connector = getConnectorFromBreakout(breakout, data.groupPosition, data.connectorPosition);
                const updatedConnector: IConnector = { ...connector, labelColor: color.name };
                connectorsToUpdate.push(updatedConnector);
                storeDispatch(setConnector({ side: breakout.side, breakoutPosition: breakout.position, groupPosition: data.groupPosition, connector: updatedConnector }));
            }

            batch(() => {
                storeDispatch(updateConnectors(connectorsToUpdate));
            });
            breakoutDetailsDispatch(clearSelection());
            closeColorDialog();
        }
    }, [legRows, selectedLegs, breakout, storeDispatch, breakoutDetailsDispatch, closeColorDialog]);

    const currentConnectorColor = colorProps.connectorColors.length > 1 ? undefined : colorProps.connectorColor;
    const currentLabelColor = colorProps.labelColors.length > 1 ? undefined : colorProps.labelColor;
    const currentColor = isConnectorColor ? currentConnectorColor : currentLabelColor ;
    const colors = isConnectorColor ? colorProps.colors : FiberColors;
    const className = isConnectorColor ? "breakout-details-connector" : "breakout-details-label"
    const onColorButtonClick = isConnectorColor ? onConnectorColorButtonClick : onLabelColorButtonClick;
    const colorDialogProps: IColorDialogProps = {
        open: showColorDialog,
        onClose: closeColorDialog,
        className,
        currentColor,
        colors,
        onColorButtonClick
    }

    useEffect(() => {
        if (showColorDialog && selectedLegs.length === 0) {
            closeColorDialog()
        }
    }, [showColorDialog, selectedLegs, closeColorDialog]);

    return { onConnectorPaletteClick, onLabelPaletteClick, colorDialogProps }
}

export const useBreakoutNavigationBar = (context: IBreakoutDetailsContext, selectedBreakout: IBreakout) => {
    const sortedBreakouts = useSelector(sortedBreakoutsSelector);
    const [state, dispatch] = useReducer(NavigationBarReducer, initialNavigationBarState);
    const { currentIndex } = state;
    const storeDispatch = useStoreDispatch();
    const { dispatch: breakoutDetailsDispatch } = context;

    const selectBreakout = useCallback((breakout: IBreakout) => {
        storeDispatch(setViewportBreakoutSelection({ position: breakout.position, side: breakout.side }));
        breakoutDetailsDispatch(clearSelection());
    }, [storeDispatch, breakoutDetailsDispatch]);

    const onNavigationButtonClick = useCallback((breakout: IBreakout, index: number) => {
        selectBreakout(breakout);
        dispatch(setCurrentIndex(index));
    }, [selectBreakout, dispatch]);

    const onFirstClick = useCallback(() => {
        const firstIndex = sortedBreakouts.findIndex(b => b.furcation.groups[0].connectors[0].type !== PIGTAIL_NO_LEG);
        const firstBreakout = sortedBreakouts[firstIndex];
        selectBreakout(firstBreakout);
    }, [sortedBreakouts, selectBreakout]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        const previousBreakout = sortedBreakouts[previousIndex];
        selectBreakout(previousBreakout);
    }, [currentIndex, sortedBreakouts, selectBreakout]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % sortedBreakouts.length;
        const nextBreakout = sortedBreakouts[nextIndex];
        selectBreakout(nextBreakout);
    }, [currentIndex, sortedBreakouts, selectBreakout]);

    const onLastClick = useCallback(() => {
        const reversedBreakouts = [...sortedBreakouts].reverse();
        const lastIndex = sortedBreakouts.length -1 - reversedBreakouts.findIndex(b => b.furcation.groups[0].connectors[0].type !== PIGTAIL_NO_LEG);
        const lastBreakout = sortedBreakouts[lastIndex];
        selectBreakout(lastBreakout);
    }, [sortedBreakouts, selectBreakout]);

    const buttons = sortedBreakouts.map((b, i) => {
        const section = b.side === Sides.SideA ? "A" + b.position : "B" + b.position;
        const disabled = b.furcation.groups[0].connectors[0].type === PIGTAIL_NO_LEG
        return {
            buttonProps: {
                className: "breakout-button",
                palette: MainPalettes.primary,
                token: MainThemeTokens.main,
                onClick: () => onNavigationButtonClick(b, i),
                disabled 
            },
            label: section
        }
    });

    const navigationBarProps: INavigationBarProps = {
        context: { state, dispatch },
        displayThreshold: 8,
        onFirstClick,
        onPreviousClick,
        buttons,
        onNextClick,
        onLastClick
    };

    useEffect(() => {
        if (selectedBreakout && sortedBreakouts.length > 0) {
            const index = sortedBreakouts.indexOf(selectedBreakout)
            dispatch(setCurrentIndex(index));
            dispatch(setDisabled(index !== currentIndex));
        } else {
            dispatch(setCurrentIndex(0));
        }
    }, [selectedBreakout, sortedBreakouts, dispatch, currentIndex]);

    return { navigationBarProps };
}

export function hasConnectorType(legRows: ILegRowProps[], connectorType: string) {
    return legRows.length > 0 && legRows.map(r => r.data.connectorType).includes(connectorType);
}