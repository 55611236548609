import { ITextProps, ITextSize } from "./types";
import { useMemo } from "react";
import * as Pixi from 'pixi.js';

const defaultFill = 0x484646;
const defaultFontFamily = "TheSansC4s";
const defaultFontWeight = "700";
const defaultFontSize = "16";

export const useText = (props: ITextProps) => {
    const { text, fill, fontFamily, fontWeight, fontSize } = props;

    const textStyleRef = useMemo(() => {
        return new Pixi.TextStyle({
            align: 'center',
            fontSize: fontSize ?? defaultFontSize,
            fontFamily: fontFamily ?? defaultFontFamily,
            fontWeight: fontWeight ?? defaultFontWeight,
            fill: fill ?? defaultFill,
        })
    }, [fontSize, fontFamily, fontWeight, fill])
    
    const textMetrics = Pixi.TextMetrics.measureText(text, textStyleRef);
    const textSize: ITextSize = { width: textMetrics.width * 0.5, height: textMetrics.height * 0.5 };

    return { text, textStyle: textStyleRef, textSize };
}