import { Side } from "../../../assembly/breakout/types";
import { createContext, Dispatch } from "react";
import { AnyAction } from "redux";
import { IFiberMapData } from "../../fiber-map/types";
import { IHighlightedConnector } from "../../../../pixi/cable/breakout/connector-furcation/highlight/types";
import { AssignmentPattern } from "../types";

export interface IConnectorMap {
    id?: number;
    side: Side;
    breakoutPosition: number;
    index: number;
    orderIndex: number;
    connectorType: string;
    fiberCount: number;
    unassignedFibers: number;
    blockedFiberCount?: number;
    assignedIndices: { breakoutPosition: number, index: number }[];
}

export interface IConnectorAssignmentSet {
    sideAMapping: IConnectorMap[];
    sideBMapping: IConnectorMap[];
}

export interface IConnectorAssignmentMap {
    id?: number;
    polarityId?: number;
    polarityKey: number;
    fiberMapData: IFiberMapData;
    connectors: IConnectorAssignmentSet;
    propagatedTriggerColor?: boolean;
    propagatedLabelColor?: boolean;
    propagatedLabelText?: boolean;
}

export interface IConnectorAssignmentState {
    selection: IConnectorAssignmentSet;
    assignedSelection: IConnectorAssignmentSet;
    assignments: IConnectorAssignmentMap[];
    highlights: IHighlightedConnector[];
    automaticMode: boolean;
    saving: boolean;
    propagating: boolean;
    patternType: AssignmentPattern;
}

export const initialConnectorAssignmentState: IConnectorAssignmentState = {
    selection: {
        sideAMapping: [],
        sideBMapping: [],
    },
    assignedSelection: {
        sideAMapping: [],
        sideBMapping: [],
    },
    assignments: [],
    highlights: [],
    automaticMode: false,
    saving: false,
    propagating: false,
    patternType: "Standard"
}

export interface IConnectorAssignmentContext {
    state: IConnectorAssignmentState;
    dispatch: Dispatch<AnyAction>;
}

export const initialConnectorAssignmentContext: IConnectorAssignmentContext = {
    state: initialConnectorAssignmentState,
    dispatch: () => {}
}

export const ConnectorAssignmentContext = createContext(initialConnectorAssignmentContext);