import { AssemblyDocument, initialAssemblyDocument } from "../document/types";

export interface IReportState {
    showPrint: boolean;
    assemblyDocument: AssemblyDocument
}

export const initialReportState: IReportState = {
    showPrint: false,
    assemblyDocument: initialAssemblyDocument
}