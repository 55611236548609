import { createSlice } from "@reduxjs/toolkit";
import { initialLocalPolarityState } from "./types";
import { setupFiberMappingAction, setConnectorAssignmentToUpdateAction, resetLocalPolarityAction } from "./actions";

const localPolaritySlice = createSlice({
    initialState: initialLocalPolarityState,
    name: "localPolarity",
    reducers: {
        setupFiberMapping: setupFiberMappingAction,
        setConnectorAssignmentToUpdate: setConnectorAssignmentToUpdateAction,
        resetLocalPolarity: resetLocalPolarityAction
    }
});

export const LocalPolarityReducer = localPolaritySlice.reducer;
export const { setupFiberMapping, setConnectorAssignmentToUpdate, resetLocalPolarity } = localPolaritySlice.actions;