import { useEffect, useReducer, useMemo } from "react";
import { useSelector } from "react-redux";
import { hexStringToNumber, Yellow } from "../ui/dialog/color/types";
import { IAnnotationContext } from "../workspace/annotation/store/types";
import { unitSelector } from "../workspace/assembly/length/store/selectors";
import { JacketColors } from "../workspace/assembly/palette/types";
import { currentAssemblySelector, assembliesLoadedSelector, toleranceSelector, currentConvertedDisplayCustomTolerancesSelector, annotationsListSelector, showCableBaseReferencesSelector, showToleranceMarkerSelector, showPullingGripSelector } from "../workspace/assembly/store/selectors";
import { IAssemblySide, isDefaultLabelToleranceValue, isDefaultToleranceValue } from "../workspace/assembly/types";
import { highlightedConnectorsSelector } from "../workspace/polarity/store/selectors";
import { isOptimusUserSelector } from "../workspace/store/selectors";
import { IMarkerSettings } from "../workspace/store/types";
import { useCablePosition } from "./cable/position/hooks";
import { CableProps, IPosition } from "./cable/types";
import { ColorReducer, setColors } from "./color/reducer";
import { IColorContext, initialCableColor } from "./color/types";
import { DocumentGraphicsProps } from "./document/types";
import { ICustomTolerances } from "./markers/tolerance/types";
import { useStatusContext } from "./status/hooks";
import { useWindowSize, useViewportBridge } from "./viewport/hooks";

const nonDefaultToleranceColor = 0xC60C30;
const defaultMarkerColor = 0x000000;

export const usePixiComponent = () => {
    const windowSize = useWindowSize();
    const assembly = useSelector(currentAssemblySelector);
    const assemblyCableType = assembly.assemblyInfo?.type;
    const customTolerances: ICustomTolerances = useSelector(currentConvertedDisplayCustomTolerancesSelector);
    const { palette } = assembly;
    const loaded = useSelector(assembliesLoadedSelector);
    const graphicsPosition: IPosition = { x: 2 * windowSize.width, y: 2 * windowSize.height };
    const { viewportContext, viewportRef } = useViewportBridge();
    const { cablePositionContext } = useCablePosition();
    const statusContext = useStatusContext();

    const [colorState, colorDispatch] = useReducer(ColorReducer, initialCableColor);
    const colorContext: IColorContext = { cableColor: colorState, dispatch: colorDispatch };
    const annotations = useSelector(annotationsListSelector);
    const annotationContext: IAnnotationContext = { annotations };

    const showCableBaseReferences = useSelector(showCableBaseReferencesSelector);
    const showToleranceMarker = useSelector(showToleranceMarkerSelector);
    const isOptimusUser = useSelector(isOptimusUserSelector);
    const showPullingGrip = useSelector(showPullingGripSelector);
    const tolerance = useSelector(toleranceSelector);
    const { unit } = useSelector(unitSelector);

    const isSingleBreakoutAndLeg = getNumberOfLegs(assembly.sideA) === 1 && getNumberOfLegs(assembly.sideB) === 1;

    const showTransitions = !isSingleBreakoutAndLeg;
    const showABMarkers = !isSingleBreakoutAndLeg;
    
    const markerSettings: IMarkerSettings = { 
        showCableBaseReferences, 
        showToleranceMarker, 
        showTolerances: !isOptimusUser,
        tolerance, 
        customTolerances,
        showPullingGrip,
        showTransitions,
        showABMarkers,
    };

    const highlightContext = useSelector(highlightedConnectorsSelector);

    const toleranceColors = useMemo(() => {
        let legToleranceColors = { minColor: defaultMarkerColor, maxColor: defaultMarkerColor };
        let legPrimeToleranceColors = { minColor: defaultMarkerColor, maxColor: defaultMarkerColor };
        let legLabelToleranceColors = { minColor: defaultMarkerColor, maxColor: defaultMarkerColor };

        if (assembly.id) {
            const legMinToleranceColor = isDefaultToleranceValue(customTolerances.legTolerance.min, assemblyCableType) ? defaultMarkerColor : nonDefaultToleranceColor;
            const legMaxToleranceColor = isDefaultToleranceValue(customTolerances.legTolerance.max, assemblyCableType) ? defaultMarkerColor : nonDefaultToleranceColor;
        
            const legPrimeMinToleranceColor = isDefaultToleranceValue(customTolerances.legPrimeTolerance.min, assemblyCableType) ? defaultMarkerColor : nonDefaultToleranceColor;
            const legPrimeMaxToleranceColor = isDefaultToleranceValue(customTolerances.legPrimeTolerance.max, assemblyCableType) ? defaultMarkerColor : nonDefaultToleranceColor;
        
            const legLabelMinToleranceColor = isDefaultLabelToleranceValue(customTolerances.legLabelTolerance.min) ? defaultMarkerColor : nonDefaultToleranceColor;
            const legLabelMaxToleranceColor = isDefaultLabelToleranceValue(customTolerances.legLabelTolerance.max) ? defaultMarkerColor : nonDefaultToleranceColor;

            legToleranceColors = { minColor: legMinToleranceColor, maxColor: legMaxToleranceColor };
            legPrimeToleranceColors = { minColor: legPrimeMinToleranceColor, maxColor: legPrimeMaxToleranceColor };
            legLabelToleranceColors = { minColor: legLabelMinToleranceColor, maxColor: legLabelMaxToleranceColor };
        }
        return { legToleranceColors, legPrimeToleranceColors, legLabelToleranceColors };
    },[customTolerances, assemblyCableType, assembly.id])

    useEffect(() => {
        if (palette) {
            let jacketColorHex = hexStringToNumber(Yellow.hex);
            const jacketColor = JacketColors.find(c => c.hex === palette.jacketColor || c.name === palette.jacketColor);
            if (jacketColor) {
                jacketColorHex = hexStringToNumber(jacketColor.hex);
            }
            let sideATrunkColorHex = hexStringToNumber(Yellow.hex);
            const sideATrunkColor = JacketColors.find(c => c.hex === palette.sideATrunkColor || c.name === palette.sideATrunkColor);
            if (sideATrunkColor) {
                sideATrunkColorHex = hexStringToNumber(sideATrunkColor.hex);
            }
            let sideBTrunkColorHex = hexStringToNumber(Yellow.hex);
            const sideBTrunkColor = JacketColors.find(c => c.hex === palette.sideBTrunkColor || c.name === palette.sideBTrunkColor);
            if (sideBTrunkColor) {
                sideBTrunkColorHex = hexStringToNumber(sideBTrunkColor.hex);
            }
            let sideALegColorHex = hexStringToNumber(Yellow.hex);
            const sideALegColor = JacketColors.find(c => c.hex === palette.sideALegColor || c.name === palette.sideALegColor);
            if (sideALegColor) {
                sideALegColorHex = hexStringToNumber(sideALegColor.hex);
            }
            let sideBLegColorHex = hexStringToNumber(Yellow.hex);
            const sideBLegColor = JacketColors.find(c => c.hex === palette.sideBLegColor || c.name === palette.sideBLegColor);
            if (sideBLegColor) {
                sideBLegColorHex = hexStringToNumber(sideBLegColor.hex);
            }
            colorDispatch(setColors({ ...toleranceColors, jacketColor: jacketColorHex, sideATrunkColor: sideATrunkColorHex, sideALegColor: sideALegColorHex, sideBTrunkColor: sideBTrunkColorHex, sideBLegColor: sideBLegColorHex }));
        }
    }, [palette, colorDispatch, toleranceColors]);

    const documentGraphicsProps: DocumentGraphicsProps = {
        position: graphicsPosition,
        cablePosition: cablePositionContext,
        loaded,
        assembly,
        markerSettings,
        annotationContext,
        colorContext,
        highlightContext,
        unit
    }

    const cableProps: CableProps = {
        assembly,
        markerSettings,
        annotationContext,
        colorContext,
        highlightContext,
        unit
    }
    
    return {
        windowSize,
        loaded,
        statusContext,
        viewportContext,
        viewportRef,
        cablePositionContext,
        documentGraphicsProps,
        cableProps
    }
}

const getNumberOfLegs = (side: IAssemblySide | undefined) => side?.breakouts.flatMap(breakout => breakout.furcation.groups).map(g => g.connectors.length).reduce((prev, cur) => { return prev + cur; }) ?? 0;