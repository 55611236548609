import React from 'react';
import { IInputDialogProps } from './types';
import { useInputDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { FilledTextField } from '@orbit/text-field';

import './InputDialog.scss';

export const InputDialog: React.FC<IInputDialogProps> = (props) => {
    const { dialogProps, input } = useInputDialog(props);
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="dialog-container">
                <FilledTextField {...input} />
            </div>
        </Dialog>
    )
}