import { CableBaseAnnotationContainer } from "./annotation/types";

export const CableBaseContainer = "cable-base-container";
export const CableBaseJacket = "cable-base-jacket";
export const CableBaseFurcation = "cable-base-furcation";
export const CableBaseShield = "cable-base-shield";
export const CableBaseStart = "cable-base-start";

export const CableBaseContainers = {
    CableBaseContainer,
    CableBaseJacket,
    CableBaseFurcation,
    CableBaseShield,
    CableBaseStart,
    CableBaseAnnotationContainer
} as const;