import { Dispatch } from "react"
import { AnyAction } from "redux"

export interface INavigationBarState {
    currentIndex: number;
    disabled: boolean;
}

export const initialNavigationBarState: INavigationBarState = {
    currentIndex: 0,
    disabled: false
}

export interface INavigationBarContext {
    state: INavigationBarState;
    dispatch: Dispatch<AnyAction>;
}

export const initialNavigationBarContext: INavigationBarContext = {
    state: initialNavigationBarState,
    dispatch: () => {}
}