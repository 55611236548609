import React from 'react';
import * as Pixi from 'pixi.js';
import { ConnectorHighlightGraphics, IConnectorHighlightProps } from './types';
import { Graphics } from '@inlet/react-pixi';

export const ConnectorHighlight: React.FC<IConnectorHighlightProps> = (props) => {
    return props.alpha > 0 ? <Graphics name={ConnectorHighlightGraphics} draw={grfx => drawRectangle(grfx, props)} calculateBounds={() => {}} /> : null
}

export function drawRectangle(grfx: Pixi.Graphics, { color, fillAlpha, borderColor, boundingBox, alpha }: IConnectorHighlightProps) {
    grfx.clear();

    grfx.beginFill(color, fillAlpha ?? 0.33);
    grfx.lineStyle(1, borderColor);
    grfx.drawRoundedRect(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height, 2);
    grfx.alpha = alpha;
    grfx.endFill();
}