import { ITextFieldState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setStringValueAction = (state: ITextFieldState, action: PayloadAction<string>) => {
    state.stringValue = action.payload;
}

export const updateStringValueAction = (state: ITextFieldState, action: PayloadAction<string>) => {
    const newStringValue = action.payload;
    const newValue = Number(newStringValue);
    const oldValue = Number(state.stringValue);
    if (newStringValue !== state.stringValue && newValue !== undefined && newValue !== oldValue) {
        state.stringValue = action.payload;
    }
}

export const setNumberValueAction = (state: ITextFieldState, action: PayloadAction<number | undefined>) => {
    state.numberValue = action.payload;
}

export const setIsValidAction = (state: ITextFieldState, action: PayloadAction<boolean>) => {
    state.isValid = action.payload;
}

export const setHelperTextAction = (state: ITextFieldState, action: PayloadAction<string>) => {
    state.helperText = action.payload;
}