import { Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { FilledTextField } from '@orbit/text-field';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../localization/types';
import { ColorDialog } from '../../../../ui/dialog/color/ColorDialog';
import { TitledField } from '../general-setup/titled-field/TitledField';
import { useBreakoutSetup } from './hooks';
import { BreakoutSetupProps } from './types';
import { OffsetLengthField } from './offset-lengthfield/OffsetLengthField';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { ContainedButton } from '@orbit/button';
import { IconButton, IconButtonProps } from '@orbit/icon-button';
import { Palette } from '@mui/icons-material';
import { FontTokens, Typography } from '@orbit/theme-provider';
import { PIGTAIL_LEG, PIGTAIL_NO_LEG } from '../../../assembly/connector/types';

export const BreakoutSetup: React.FC<BreakoutSetupProps> = (props) => {
    const {
        fields: {
            title,
            connectorTypes,
            connectorCount: { connectorGroupInput, groupInput },
            spare,
            jacket,
            lengthA,
            primeJacket,
            lengthAp,
            multipleBreakout,
        },
        paletteIcon,
        groupOffsetProps,
        addBreakouts,
        displayStep,
        colorDialogProps,
        shape,
        pigtailsEnabled
    } = useBreakoutSetup(props)
    const { t } = useTranslation();
    const iconProps: IconButtonProps = {
        ...paletteIcon,
        icon: <Palette />
    };
    return (
        <div className="breakout setup" hidden={!displayStep}>
            <div className="step-hint">
                <Typography variant="body1" label={t(LocalizationKeys.BreakoutStepHint)} fontToken={FontTokens.none} />
            </div>
            <div className="sub-title">
                <Typography variant="subtitle1" label={title} fontToken={FontTokens.none} />
                <Divider />
            </div>
            <div className="field-container">
                <FormControl className='select'>
                    <InputLabel>{connectorTypes.label}</InputLabel>
                    <Select {...connectorTypes.select}>
                        {shape === t(LocalizationKeys.Symmetrical) || !pigtailsEnabled ?
                            connectorTypes.options.filter((o) => o !== PIGTAIL_LEG && o !== PIGTAIL_NO_LEG).map((o, i) => <MenuItem key={`connector-types-${i}`} value={o}>{o}</MenuItem>) :
                            connectorTypes.options.map((o, i) => <MenuItem key={`connector-types-${i}`} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <FilledTextField {...groupInput} />
                </div>
                <div className="multiply">
                    <Typography variant="subtitle1" label="x" />
                </div>
                <div className="field">
                    <FilledTextField {...connectorGroupInput} />
                </div>
            </div>
            <div className="field-container">
                <FormControl>
                    <FilledTextField {...spare} />
                    <FormHelperText>{spare.error ? '' : t(LocalizationKeys.NSpareConnectorsHelp)}</FormHelperText>
                </FormControl>
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <FilledTextField {...jacket.field} InputProps={{ endAdornment: <IconButton {...iconProps} onClick={jacket.onClick} />, readOnly: true }} />
                </div>
                <div className="field">
                    <FormControl>
                        <FilledTextField {...lengthA} />
                        <FormHelperText>{lengthA.error ? '' : t(LocalizationKeys.LengthA1Help)}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <FilledTextField {...primeJacket.field} InputProps={{ endAdornment: <IconButton {...iconProps} onClick={primeJacket.onClick} />, readOnly: true }} />
                </div>
                <div className="field">
                    <FormControl>
                        <FilledTextField {...lengthAp} />
                        <FormHelperText>{lengthAp.error ? '' : t(LocalizationKeys.LengthA1PHelp)}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <TitledField title={groupOffsetProps.label}>
                <RadioGroup row value={groupOffsetProps.radioProps.value}>
                    {groupOffsetProps.radioProps.options.map((o, i) => <FormControlLabel key={`offset-type-${i}`} className={o.className} label={o.label} control={<Radio {...o.radioButtonProps} />} />)}
                </RadioGroup>
            </TitledField>
            <div>
                {
                    groupOffsetProps.staggerFields.map((s, i) => {
                        return (
                            <div key={i}>
                                <OffsetLengthField {...s} />
                            </div>
                        )
                    })
                }
            </div>
            <div className="field-container horizontal">
                <div className="field">
                    <FormControl>
                        <FilledTextField {...multipleBreakout} />
                        <FormHelperText>{multipleBreakout.error ? '' : t(LocalizationKeys.NBreakoutsHelp)}</FormHelperText>
                    </FormControl>
                </div>
                <div className="field">
                    <ContainedButton {...addBreakouts.buttonProps}>
                        {addBreakouts.label}
                    </ContainedButton>
                </div>
            </div>
            <ColorDialog {...colorDialogProps} />
        </div>
    )
}