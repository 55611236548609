import React from 'react';
import { INavigationBarProps } from './types';
import { useNavigationBar } from './hooks';
import { IconButton } from '@orbit/icon-button';
import { FirstPage, ChevronLeft, ChevronRight, LastPage, MoreHoriz } from '@mui/icons-material';
import { TextButton } from '@orbit/button';

import './NavigationBar.scss';

export const NavigationBar: React.FC<INavigationBarProps> = (props) => {
    const { firstProps, previousProps, buttons, nextProps, lastProps, showPreviousThreshold, showNextThreshold, thresholdProps } = useNavigationBar(props);
    return (
        <div className="navigation-bar">
            <IconButton {...firstProps} icon={<FirstPage />} />
            <IconButton {...previousProps} icon={<ChevronLeft />}  />
            { showPreviousThreshold ? <IconButton {...thresholdProps} icon={<MoreHoriz />} /> : null }
            { 
                buttons?.map(b => {
                    return (
                        <TextButton key={b.label} {...b.buttonProps}>
                            {b.label}
                        </TextButton>
                    );
                })
            }
            { showNextThreshold ? <IconButton {...thresholdProps} icon={<MoreHoriz />} /> : null }
            <IconButton {...nextProps} icon={<ChevronRight />}  />
            <IconButton {...lastProps} icon={<LastPage />}  />
        </div>
    );
}