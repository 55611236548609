import { IToleranceMarkerProps } from "./types";
import { useText } from "../base/text/hooks";
import { IPosition } from "../../cable/types";
import { _ReactPixi } from "@inlet/react-pixi";

const defaultMarkerColor = 0x000000;
export const useTolerance = ({ tolerance: { min, max }, textProps, minTextColor, maxTextColor }: IToleranceMarkerProps) => {
    const { text, position } = textProps;
    const maxText = getToleranceText(max);
    const minText = getToleranceText(min);
    const { textSize } = useText({ text: text!, fill: 0x000000, fontSize: 8 });
    const { textStyle: maxStyle, textSize: maxSize } = useText({ text: maxText, fill: maxTextColor ?? defaultMarkerColor, fontSize: 8 });
    const { textStyle: minStyle } = useText({ text: minText, fill: minTextColor ?? defaultMarkerColor, fontSize: 8 });

    let maxPosition: IPosition = { x: 0, y: 0 };
    let minPosition: IPosition = { x: 0, y: 0 };
    const toleranceOffset = 1;
    if (position) {
        const pos = position as IPosition;
        const defaultX = pos.x + textSize.width * 2;
        const maxOffsetX = max > 0 ? toleranceOffset : 2 * toleranceOffset;
        maxPosition = { x: defaultX + maxOffsetX, y: pos.y };
        const minOffsetX = min > 0 ? toleranceOffset : 2 * toleranceOffset;
        minPosition = { x: defaultX + minOffsetX, y: pos.y + textSize.height - maxSize.height * 0.25};
    }

    const resolution = 10;
    const scale = 0.5;
    const maxProps: _ReactPixi.IText = {
        text: maxText,
        style: maxStyle,
        position: maxPosition,
        resolution,
        scale
    }

    const minProps: _ReactPixi.IText = {
        text: minText,
        style: minStyle,
        position: minPosition,
        resolution,
        scale
    }

    return { textProps, maxProps, minProps }
}

const getToleranceText = (tolerance: number) => {
    if (tolerance <= 0) {
        return tolerance.toString();
    } else {
        return "+" + tolerance;
    }
}

export const getToleranceLength = (tolerance: number) => {
    if (tolerance < 0 || tolerance % 1 !== 0) {
        return tolerance.toString().length - 1.5;
    } else {
        return tolerance.toString().length;
    }
}