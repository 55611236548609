import { PayloadAction } from "@reduxjs/toolkit";
import { IColor } from "../../../../../ui/dialog/color/types";
import { Length } from "../../../length/types";
import { IBreakoutState } from "./types";

export const setBreakoutJacketColorAction = (state: IBreakoutState, action: PayloadAction<IColor>) => {
    state.breakoutAttributes.jacketColor = action.payload;
}

export const setBreakoutLengthAction = (state: IBreakoutState, action: PayloadAction<Length>) => {
    state.breakoutAttributes.length = action.payload;
}

export const setBreakoutLabelAction = (state: IBreakoutState, action: PayloadAction<string>) => {
    state.breakoutAttributes.label = action.payload;
}

export const setLegJacketColorAction = (state: IBreakoutState, action: PayloadAction<IColor>) => {
    state.legsAttributes.jacketColor = action.payload;
}

export const setLegConnectorTypeAction = (state: IBreakoutState, action: PayloadAction<string>) => {
    state.legsAttributes.connectorType = action.payload;
}

export const setApplyAllAction = (state: IBreakoutState, action: PayloadAction<boolean>) => {
    state.applyAll = action.payload;
}