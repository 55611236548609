import React from 'react';
import * as Pixi from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { CircleProps } from './types';

export const Circle: React.FC<CircleProps> = (props) => {
    return <Graphics draw={(grfx: Pixi.Graphics) => drawCircle(grfx, props)} />
}

const drawCircle = (grfx: Pixi.Graphics, props: CircleProps) => {
    const { coordinate, thickness, color, alpha, radius } = props;
    const { x , y } = coordinate;

    grfx.clear();
    grfx.lineStyle(thickness, color, alpha);
    grfx.beginFill(0xFFFFFF);
    grfx.drawCircle(x, y, radius);
    grfx.endFill();
}