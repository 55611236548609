import { createSlice } from "@reduxjs/toolkit";
import { setBreakoutPositionAction, setRelativeIndicesAction } from "./actions";
import { initialCablePostion } from "./types";

const cablePositionSlice = createSlice({
    name: 'cable-position',
    initialState: initialCablePostion,
    reducers: {
        setBreakoutPosition: setBreakoutPositionAction,
        setRelativeIndices: setRelativeIndicesAction
    }
})

export const CablePositionReducer = cablePositionSlice.reducer;

export const { setBreakoutPosition, setRelativeIndices } = cablePositionSlice.actions;