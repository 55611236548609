import { CableProps } from "./types";
import { initialLineMarkerProps, LineMarkerProps } from '../markers/line/types';
import { useTranslation } from 'react-i18next';
import { Orientations } from '../markers/types';
import { LocalizationKeys } from '../../localization/types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CablePositionContext } from './position/types';
import { Side, Sides } from '../../workspace/assembly/breakout/types';
import { cableAnchorPoint } from "./cable-base/hooks";
import { getConnectorSprite } from "./breakout/connector-furcation/connector-sprite/types";
import { getConnectorTypeString, MMC_KEY, MTP_KEY } from "../../workspace/assembly/connector/types";
import { useText } from "../markers/base/text/hooks";
import { _ReactPixi } from "@inlet/react-pixi";
import { SideProps } from "./side/types";
import { convertTo, Length, toLengthString, Unit, Units } from "../../workspace/assembly/length/types";
import { IPointData } from "pixi.js";
import { IPullingGripProps } from "./pulling-grip/types";
import { PullingGrips } from "../../workspace/assembly/types";
import { ITextSize } from "../markers/base/text/types";

const overallLengthSpacing = 40;
export const useCable = ({ assembly, markerSettings, annotationContext, colorContext, highlightContext, unit }: CableProps) => {
    const { t } = useTranslation();
    const sideABreakouts = useMemo(() => assembly.sideA ? assembly.sideA.breakouts : [], [assembly.sideA]);
    const sideBBreakouts = useMemo(() => assembly.sideB ? assembly.sideB.breakouts : [], [assembly.sideB]);
    const [markerProps, setMarkerProps] = useState(initialLineMarkerProps);
    const [y, setY] = useState(0);
    const [leftLength, setLeftLength] = useState(0);
    const [rightLength, setRightLength] = useState(0);
    const endAPosition: IPointData = { x: leftLength, y: y - overallLengthSpacing };
    const { endLabelProps: endADesignationLabelProps, endLabelSize: endATextSize } = useEndDesignationLabel(Sides.SideA, endAPosition, assembly.assemblyInfo?.endADesignation);
    const endBPosition: IPointData = { x: rightLength + cableAnchorPoint.x, y: y - overallLengthSpacing };
    const { endLabelProps: endBDesignationLabelProps, endLabelSize: endBTextSize } = useEndDesignationLabel(Sides.SideB, endBPosition, assembly.assemblyInfo?.endBDesignation);
    const { endAPullingGrip, endBPullingGrip } = usePullingGrips(endAPosition, endATextSize, endBPosition, endBTextSize, assembly.assemblyInfo?.pullingGrip, !!assembly.assemblyInfo?.showPullingGrip);
    const { state } = useContext(CablePositionContext);
    const sideAConnectorType = sideABreakouts[0].furcation.groups[0].connectors[0].type;
    const sideBConnectorType = sideBBreakouts[0].furcation.groups[0].connectors[0].type;
    
    useEffect(() => {
        const sideABreakoutPositions = state.sides[Sides.SideA];
        const sideBBreakoutPositions = state.sides[Sides.SideB];
        const sidePositions = state.sidePositions;
        const sideAPositions = sidePositions[Sides.SideA];
        const sideBPositions = sidePositions[Sides.SideB];
        if (sideABreakoutPositions && sideBBreakoutPositions && sideABreakoutPositions.length && sideBBreakoutPositions.length && sideAPositions && sideBPositions) {
            const topLeft = sideABreakoutPositions.length > 1 ?
                sideABreakoutPositions.reduce((a, b) => a.trunkBox.y < b.trunkBox.y ? a : b) :
                sideABreakoutPositions[0]
            const bottomLeft = sideABreakoutPositions.length > 1 ?
                sideABreakoutPositions.reduce((a, b) => a.trunkBox.y > b.trunkBox.y ? a : b) :
                sideABreakoutPositions[0]

            const topRight = sideBBreakoutPositions.length > 1 ?
                sideBBreakoutPositions.reduce((a, b) => a.trunkBox.y < b.trunkBox.y ? a : b) :
                sideBBreakoutPositions[0]
            const bottomRight = sideBBreakoutPositions.length > 1 ?
                sideBBreakoutPositions.reduce((a, b) => a.trunkBox.y > b.trunkBox.y ? a : b) :
                sideBBreakoutPositions[0]

            setY(topLeft.breakoutBox.y < topRight.breakoutBox.y ? topLeft.breakoutBox.y : topRight.breakoutBox.y);

            let leftDelimiterOffset = 0;
            if (sideABreakouts[bottomLeft.position - 1]) {
                const sideAConnector = sideABreakouts[bottomLeft.position - 1].furcation.groups[0].connectors[0];
                const sideAConnectorSprite = getConnectorSprite(sideAConnector.type);
                leftDelimiterOffset = - (sideAConnectorSprite.width / 2.5);
                if (sideABreakouts.length === 1) {
                    leftDelimiterOffset = - (sideAConnectorSprite.width / 2)
                }
            }

            let rightDelimiterOffset = 0;
            if (sideBBreakouts[bottomRight.position - 1]) {
                const sideBConnector = sideBBreakouts[bottomRight.position - 1].furcation.groups[0].connectors[0];
                const sideBConnectorSprite = getConnectorSprite(sideBConnector.type);
                rightDelimiterOffset = - (sideBConnectorSprite.width / 2.5);
                if (sideBBreakouts.length === 1) {
                    rightDelimiterOffset = - (sideBConnectorSprite.width / 2)
                }
            }

            let leftHasMTP = false;
            let leftHasMMC = false;
            const largestSideAPosition = sideABreakoutPositions.length > 1 ? sideABreakoutPositions.reduce((a, b) => a.trunkBox.width < b.trunkBox.width ? a : b) : sideABreakoutPositions[0];
            if (sideABreakouts[largestSideAPosition.position - 1]) {
                const sideAConnector = sideABreakouts[largestSideAPosition.position - 1].furcation.groups[0].connectors[0];
                leftHasMTP = getConnectorTypeString(sideAConnector.type).includes(MTP_KEY);
                leftHasMMC = getConnectorTypeString(sideAConnector.type).includes(MMC_KEY);
            }
            let rightHasMTP = false;
            let rightHasMMC = false;
            const largestSideBPosition = sideBBreakoutPositions.length > 1 ? sideBBreakoutPositions.reduce((a, b) => a.trunkBox.width > b.trunkBox.width ? a : b) : sideBBreakoutPositions[0];
            if (sideBBreakouts[largestSideBPosition.position - 1]) {
                const sideBConnector = sideBBreakouts[largestSideBPosition.position - 1].furcation.groups[0].connectors[0];
                rightHasMTP = getConnectorTypeString(sideBConnector.type).includes(MTP_KEY);
                rightHasMMC = getConnectorTypeString(sideBConnector.type).includes(MMC_KEY);
            }

            const sideAMaxWidth = Math.min(...sideABreakoutPositions.map(p => p.trunkBox.width));
            const sideBMaxWidth = Math.max(...sideBBreakoutPositions.map(p => p.trunkBox.width));
            let leftOffset = 0;
            if (sideABreakouts.length === 1) {
                leftOffset = sideABreakouts[0].trunk.length.value === 0 ? -4.65 : 0.1;
            } else {
                leftOffset = 0.6;
            }
            const leftMTPOffset = leftHasMTP ? 3.55 : 0;
            const leftMMCPOffset = leftHasMMC ? 1.2 : 0;

            setLeftLength(sideAMaxWidth + leftOffset - cableAnchorPoint.x + leftMTPOffset + leftMMCPOffset);
            let rightOffset = 0;
            if (sideABreakouts.length === 1) {
                rightOffset = sideABreakouts[0].trunk.length.value === 0 ? -5.55 : -0.8;
            } else {
                rightOffset = -0.275;
            }
            const rightMTPOffset = rightHasMTP ? 3.55 : 0;
            const rightMMCOffset = rightHasMMC ? 1.1 : 0;
            setRightLength(sideBMaxWidth - rightOffset - rightMTPOffset - rightMMCOffset);
            const coordinateY = y - overallLengthSpacing;
            const length = rightLength + cableAnchorPoint.x - leftLength
            const overallLength = getOverallLength(assembly.overallLength, unit);
            const markerProps: LineMarkerProps = {
                coordinate: { x: leftLength, y: coordinateY },
                color: 0x000000,
                startDelimiterLength: (bottomLeft.breakoutBox.y + bottomLeft.breakoutBox.height) - y + overallLengthSpacing + leftDelimiterOffset,
                endDelimiterLength: bottomRight.breakoutBox.y + bottomRight.breakoutBox.height - y + overallLengthSpacing + rightDelimiterOffset,
                length,
                orientation: Orientations.Horizontal,
                text: t(LocalizationKeys.OverallLengthMarker, { overallLength }),
                thickness: 1,
                alpha: 1,
            }
            setMarkerProps(markerProps);
        }
    }, [state, t, assembly.overallLength, sideABreakouts, sideBBreakouts, leftLength, rightLength, y, unit]);

    const sideAProps: SideProps = { side: Sides.SideA, unit, breakouts: sideABreakouts, flipsideConnectorType: sideBConnectorType };
    const sideBProps: SideProps = { side: Sides.SideB, unit, breakouts: sideBBreakouts, flipsideConnectorType: sideAConnectorType };

    return { 
        markerSettings,
        annotationContext,
        colorContext,
        highlightContext,
        markerProps,
        endADesignationLabelProps,
        endBDesignationLabelProps,
        endAPullingGrip,
        endBPullingGrip,
        sideAProps,
        sideBProps
    };
};

const useEndDesignationLabel = (side: Side, position: IPointData, designation: string | undefined) => {
    const { t } = useTranslation();
    const { x, y } = position;
    const end = t(LocalizationKeys.EndLabel, { side: side === Sides.SideA ? "A" : "B"});
    const text = designation ? `${end} (${designation})` : end;
    const { textStyle, textSize } = useText({
        text,
        fill: 0x000000,
        fontSize: 16
    });

    const endLabelProps: _ReactPixi.IText = {
        text,
        position: {
            x: side === Sides.SideA ? x + 10 : x - textSize.width * 2 - 10,
            y: y - textSize.height * 2 - 10,
        },
        style: textStyle,
        resolution: 10,
        scale: 1,
    };

    return { endLabelProps, endLabelSize: textSize };
};

const usePullingGrips = (endAPosition: IPointData, endATextSize: ITextSize, endBPosition: IPointData, endBTextSize: ITextSize, pullingGrip: string | undefined, forceDisplay: boolean) => {
    const endAPullingGrip: IPullingGripProps = {
        display: forceDisplay && (pullingGrip === PullingGrips.EndA || pullingGrip === PullingGrips.BothEnds),
        position: { x: endAPosition.x + endATextSize.width * 2 + 24, y: endAPosition.y - endATextSize.height * 3 },
        textureKey: "PullingGrip_EndA"
    };

    const endBPullingGrip: IPullingGripProps = {
        display: forceDisplay && (pullingGrip === PullingGrips.EndB || pullingGrip === PullingGrips.BothEnds),
        position: { x: endBPosition.x - endBTextSize.width * 2 - 93, y: endBPosition.y - endBTextSize.height * 3 },
        textureKey: "PullingGrip_EndB"
    };

    return { endAPullingGrip, endBPullingGrip };
};

const getOverallLength = (l:Length | undefined, unit: Unit) => {
    if (!l || l.value <= 0) {
        return 'XXX';
    }
    if (unit === Units.Inches) {
        return `${toLengthString(convertTo(l, Units.Feet), 1, false)} ${Units.Feet}`;
    } 
    return `${toLengthString(l, 1, false)} ${l.unit}`
}