import { createSlice } from "@reduxjs/toolkit";
import { setColorsAction } from "./actions";
import { initialCableColor } from "./types";

const colorSlice = createSlice({
    name: "color",
    initialState: initialCableColor,
    reducers: {
        setColors: setColorsAction,
    }
})

export const ColorReducer = colorSlice.reducer;
export const { setColors } = colorSlice.actions;