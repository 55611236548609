import { createSlice } from "@reduxjs/toolkit";
import { initialConnectorAssignmentState } from "./types";
import { setConnectorAssignmentsAction, handleSelectionAction, handleMultiSelectionAction, addAssignmentAction, addAssignmentsAction, updateAssignmentAction, deleteAssignmentAction, resetSelectionAction, resetAssignmentSelectionAction, resetAllAction, setAutomaticModeAction, addOrUpdateHighlightsAction, setSavingAction, setPropagatingAction, handleAssignmentMethodAction, setPatternTypeAction } from "./actions";

const connectorAssignmentSlice = createSlice({
    initialState: initialConnectorAssignmentState,
    name: "connectorAssignment",
    reducers: {
        setConnectorAssignments: setConnectorAssignmentsAction,
        handleSelection: handleSelectionAction,
        handleMultiSelection: handleMultiSelectionAction,
        addAssignment: addAssignmentAction,
        addAssignments: addAssignmentsAction,
        updateAssignment: updateAssignmentAction,
        deleteAssignment: deleteAssignmentAction,
        resetSelection: resetSelectionAction,
        resetAssignmentSelection: resetAssignmentSelectionAction,
        resetAll: resetAllAction,
        setAutomaticMode: setAutomaticModeAction,
        addOrUpdateHighlights: addOrUpdateHighlightsAction,
        setSaving: setSavingAction,
        setPropagating: setPropagatingAction,
        handleAssignmentMethod: handleAssignmentMethodAction,
        setPolarityAssignment: setPatternTypeAction,
    }
});

export const ConnectorAssignmentReducer = connectorAssignmentSlice.reducer;
export const { setConnectorAssignments, handleSelection, handleMultiSelection, 
               addAssignment, addAssignments, updateAssignment, 
               deleteAssignment, resetSelection, resetAssignmentSelection, 
               resetAll, setAutomaticMode, addOrUpdateHighlights, 
               setSaving, setPropagating, handleAssignmentMethod, 
               setPolarityAssignment } = connectorAssignmentSlice.actions;