import { useCallback } from "react";
import { useSelector } from "react-redux"
import { useStoreDispatch } from "../../store/hooks";
import { showBreakoutEdit } from "../../workspace/store/reducer";
import { dialogsSelector, statusSelector, workspaceInteractableSelector } from "../../workspace/store/selectors"
import { IStatusContext, IStatusState } from "./types";

export const useStatusContext = () =>  {
    const status = useSelector(statusSelector);
    const dialogs = useSelector(dialogsSelector);
    const isWorkspaceInteractable = useSelector(workspaceInteractableSelector);
    const storeDispatch = useStoreDispatch();

    const statusState: IStatusState = { 
        status,
        isWorkspaceInteractable,
        dialogs
    };
    const dispatchBreakoutEdit = useCallback((display: boolean) => {
        storeDispatch(showBreakoutEdit(display));
    }, [storeDispatch]);
    
    const statusContext: IStatusContext = {
        state: statusState,
        dispatchBreakoutEdit
    };

    return statusContext;
}