import { createContext } from "react"

export interface IAnnotationContext {
    annotations: string[];
}

export const initialAnnotationContext: IAnnotationContext = {
    annotations: []
}

export const AnnotationContext = createContext(initialAnnotationContext);