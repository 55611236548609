import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { createSecuredAsyncAction, extractData } from "../../../store/actions";
import { AssemblyService } from "../../services/assembly/assembly-service";
import { projectManagerService } from "../../services/project-manager/project-manager-service";
import { setStatus, showAssemblyWizard, showHelp, showTermsConditions } from "../../store/reducer";
import { Status } from "../../store/types";
import { IAssemblyInfo } from "../info/types";
import { IBreakout, ICableTrunk, IConnector, Side, Sides } from "../breakout/types";
import { Length } from "../length/types";
import { IAssembly } from "../types";
import { loadUserAssembliesAction, addAssemblyAction, addConnectorAssignmentAction, 
         addConnectorAssignmentsAction, updateAssemblyAction, updateAssemblyInfoAction, 
         updateAssemblyPaletteAction, updateAssemblyAnnotationAction, updateConnectorAssignmentAction, 
         updateConnectorAssignmentsAction, deleteAssemblyAction, deleteConnectorAssignmentAction, 
         deleteConnectorAssignmentsAction, setCurrentAssemblyAction, setAssembliesLoadedAction, 
         setupAssemblyBreakoutsAction, setupOverallLengthAction, setupFiberCountAction, 
         resetCurrentAssemblyAction, setupFiberTypeAction, setupEnvironmentAction, 
         updateAssemblyDescriptionAction, updateBreakoutAction, setTrunkAction, 
         setConnectorAction, setupJacketColorAction, setupTrunkColorAction, 
         setupSideTrunkColorAction, setupLegColorAction, setupSideLegColorAction, 
         setupFlameRatingAction, setupPullingGripAction, setupEndDesignationAction, 
         updateFurcationsAction, setupCableTypeAction, setConnectorsAction, 
         setLegTolerancesAction, setupLegPrimeTolerancesAction, setupLegLengthTolerancesAction, 
         setupLegLabelTolerancesAction, updateBreakoutsAction, setupFurcationOuterDiameterAction, setAnnotationsAction, updateAnnotationsAction, clearAnnotationsAction, showCableBaseReferencesAction, showToleranceMarkerAction, showPullingGripAction } from "./actions";
import { initialAssemblyState } from "./types";
import { IConnectorAssignmentMap } from "../../polarity/connector-assignment/reducer/types";
import { Dispatch, SetStateAction } from "react";
import { addAssignment, resetSelection, setSaving } from "../../polarity/connector-assignment/reducer/reducer";
import { setConnectorAssignmentToUpdate } from "../../polarity/reducer/reducer";
import { setForceUpdate, updateUserFiberMap } from "../../polarity/store/reducer";
import { IFiberMapData } from "../../polarity/fiber-map/types";
import { IAssemblyAnnotation } from "../annotation/types";
import { IAssemblyPalette } from "../palette/types";
import { setProjectManager, setRecentAssemblyId } from "../../project-drawer/store/reducer";
import { setAssemblyDocument, showPrintDialog } from "../../report/store/reducer";
import { TNC_CURRENT_VERSION } from "../../../localization/types";
import { getColor } from "../../../ui/dialog/color/types";
import { isDuplex } from "../connector/types";
import { batch } from "react-redux";
import { initialAssemblyDocument } from "../../report/document/types";
import { setWorkspaceUnit } from "../length/store/reducer";

const assemblySlice = createSlice({
    initialState: initialAssemblyState,
    name: "assembly",
    reducers: {
        loadUserAssemblies: loadUserAssembliesAction,
        addAssembly: addAssemblyAction,
        addConnectorAssignment: addConnectorAssignmentAction,
        addConnectorAssignments: addConnectorAssignmentsAction,
        updateAssembly: updateAssemblyAction,
        updateAssemblyInfo: updateAssemblyInfoAction,
        updateAssemblyPalette: updateAssemblyPaletteAction,
        updateAssemblyAnnotation: updateAssemblyAnnotationAction,
        updateConnectorAssignment: updateConnectorAssignmentAction,
        updateConnectorAssignments: updateConnectorAssignmentsAction,
        deleteAssembly: deleteAssemblyAction,
        deleteConnectorAssignment: deleteConnectorAssignmentAction,
        deleteConnectorAssignments: deleteConnectorAssignmentsAction,
        setCurrentAssembly: setCurrentAssemblyAction,
        resetCurrentAssembly: resetCurrentAssemblyAction,
        setAssembliesLoaded: setAssembliesLoadedAction,
        setupOverallLength: setupOverallLengthAction,
        setupEndDesignation: setupEndDesignationAction,
        setupFiberCount: setupFiberCountAction,
        setupAssemblyBreakouts: setupAssemblyBreakoutsAction,
        setupCableType: setupCableTypeAction,
        setupFiberType: setupFiberTypeAction,
        setupEnvironment: setupEnvironmentAction,
        updateAssemblyDescription: updateAssemblyDescriptionAction,
        setTrunk: setTrunkAction,
        setLegTolerances: setLegTolerancesAction,
        updateBreakout: updateBreakoutAction,
        updateBreakouts: updateBreakoutsAction,
        setConnector: setConnectorAction,
        setConnectors: setConnectorsAction,
        setupJacketColor: setupJacketColorAction,
        setupTrunkColor: setupTrunkColorAction,
        setupSideTrunkColor: setupSideTrunkColorAction,
        setupLegColor: setupLegColorAction,
        setupSideLegColor: setupSideLegColorAction,
        setupFlameRating: setupFlameRatingAction,
        setupPullingGrip: setupPullingGripAction,
        setupOuterDiameter: setupFurcationOuterDiameterAction,
        setupLegLengthTolerances: setupLegLengthTolerancesAction,
        setupLegPrimeTolerances: setupLegPrimeTolerancesAction,
        setupLegLabelTolerances: setupLegLabelTolerancesAction,
        updateFurcations: updateFurcationsAction,
        setAnnotations: setAnnotationsAction,
        updateAnnotations: updateAnnotationsAction,
        clearAnnotation: clearAnnotationsAction,
        showCableBaseReferences: showCableBaseReferencesAction,
        showToleranceMarker: showToleranceMarkerAction,
        showPullingGrip: showPullingGripAction
    }
})

export const AssemblyReducer = assemblySlice.reducer;
const { actions } = assemblySlice;
export const { 
    setCurrentAssembly, resetCurrentAssembly, setAssembliesLoaded, 
    setupOverallLength, setupFiberCount, setupAssemblyBreakouts, setupCableType,
    setupFiberType, setupEnvironment, updateAssemblyDescription, setTrunk, setConnector, setConnectors, setupJacketColor,
    setupTrunkColor, setupSideTrunkColor, setupLegColor, setupSideLegColor, setLegTolerances, setupLegLengthTolerances,
    setupLegPrimeTolerances, setupLegLabelTolerances, setupFlameRating, setupPullingGrip, setupOuterDiameter, 
    setupEndDesignation, updateConnectorAssignments, updateBreakouts, setAnnotations, updateAnnotations, clearAnnotation, showCableBaseReferences,
    showPullingGrip, showToleranceMarker
} = assemblySlice.actions;

export const ToBreakoutData = (breakouts: IBreakout[], palette?: IAssemblyPalette) => {
    const breakoutsCopy = [...breakouts];
    for (const breakout of breakoutsCopy) {
        if (palette) {
            breakout.jacketColor = breakout.side === Sides.SideA ? palette.sideATrunkColor : palette.sideBTrunkColor;
            breakout.furcation.jacketColor = breakout.side === Sides.SideA ? palette.sideALegColor : palette.sideBLegColor;
        }
        for (const group of breakout.furcation.groups) {
            for (const connector of group.connectors) {
                if (isDuplex(connector.type) && connector.bootColors) {
                    const bootAColor: any = connector.bootColors.bootAColor;
                    const bootBColor: any = connector.bootColors.bootBColor;
                    connector.bootColors.bootAColor = getColor(bootAColor as string);
                    connector.bootColors.bootBColor = getColor(bootBColor as string);
                } 
            }
        }
    }
    return breakoutsCopy;
}

const ToAssemblyData = (assembly: IAssembly) => {
    const sideA = assembly.sideA;
    const sideB = assembly.sideB;

    if (sideA) {
        sideA.breakouts = ToBreakoutData(sideA.breakouts, assembly.palette);
    }

    if (sideB) {
        sideB.breakouts = ToBreakoutData(sideB.breakouts, assembly.palette);
    }

    return { ...assembly, sideA, sideB };
} 

export const loadUserAssemblies = (userId: number, userUOM: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const res = await projectManagerService.getProjectAssembly(userId)
        const pm = extractData(res, dispatch)
        if (pm) {
            if (pm.recentAssembly) {
                const parsedAssembly = ToAssemblyData(pm.recentAssembly);
                dispatch(setCurrentAssembly(parsedAssembly));
                dispatch(actions.loadUserAssemblies([...pm.assemblies]));
                dispatch(setProjectManager(pm));
                // Upon loading the current assembly, we can determine if the workspace unit needs to change
                const unit = parsedAssembly.assemblyInfo?.uom?.length ? parsedAssembly.assemblyInfo.uom : userUOM;
                dispatch(setWorkspaceUnit(unit));
            } else {
                dispatch(showHelp(true));
                dispatch(showAssemblyWizard(true));
            }

            if (pm.tncAcceptedAt.length === 0 || pm.tncAcceptedVersion !== TNC_CURRENT_VERSION) {
                dispatch(showTermsConditions(true));
            }

        } else {
            dispatch(showTermsConditions(true));
            dispatch(showHelp(true));
            dispatch(showAssemblyWizard(true));
        }

        dispatch(setStatus(Status.Active));
        dispatch(setAssembliesLoaded(true));

    });
}

export const loadAssembly = (assemblyId: number, userUom: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(setAssembliesLoaded(false));
        dispatch(setStatus(Status.Loading))
        const res = await projectManagerService.getAssembly(assemblyId)
        const assembly = extractData(res, dispatch)
        if (res.succesful && assembly) {
            const parsedAssembly = ToAssemblyData(assembly);
            dispatch(setCurrentAssembly(parsedAssembly));
            const unit = parsedAssembly.assemblyInfo?.uom?.length ? parsedAssembly.assemblyInfo.uom : userUom;
            dispatch(setWorkspaceUnit(unit));
            dispatch(setRecentAssemblyId(assembly.id));
            dispatch(setAssemblyDocument(initialAssemblyDocument));
            dispatch(setStatus(Status.Active));
        }
        else {
            dispatch(showHelp(true));
        }
        dispatch(setAssembliesLoaded(true));
    });
}

export const addAssembly = (assemblyToAdd: IAssembly, userId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const res = await projectManagerService.addAssembly(assemblyToAdd, userId);
        const pm = extractData(res, dispatch);
        if (pm?.recentAssembly && pm.recentAssembly.assemblyInfo) {
            const parsedAssembly = ToAssemblyData(pm.recentAssembly);
            dispatch(actions.addAssembly(parsedAssembly));
            dispatch(setRecentAssemblyId(pm.recentAssembly.id));
            dispatch(setAssemblyDocument(initialAssemblyDocument));
            dispatch(setStatus(Status.Active));
        }
    });
}

export const addConnectorAssignment = (assemblyId: number, connectorAssignment: IConnectorAssignmentMap, assignmentDispatch: Dispatch<AnyAction>) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.addConnectorAssignment(assemblyId, connectorAssignment);
        const assignment = extractData(res, dispatch);
        if (assignment) {
            dispatch(actions.addConnectorAssignment(assignment));
            assignmentDispatch(addAssignment(assignment));
            assignmentDispatch(resetSelection());
        }
    });
}

export const addConnectorAssignments = (assemblyId: number, connectorAssignments: IConnectorAssignmentMap[], assignmentDispatch: Dispatch<AnyAction>) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.addConnectorAssignments(assemblyId, connectorAssignments);
        const assignments = extractData(res, dispatch);
        if (assignments) {
            dispatch(actions.addConnectorAssignments(assignments));
            assignmentDispatch(resetSelection());
            assignmentDispatch(setSaving(false));
            dispatch(setStatus(Status.Active));
        }
    });
}

export const addOrUpdateUserFiberMap = (fiberMapData: IFiberMapData) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.addOrUpdateUserFiberMap(fiberMapData);
        const fiberMap = extractData(res, dispatch);
        if (fiberMap && fiberMap.userId) {
            dispatch(updateUserFiberMap(fiberMap));
        }
    });
}

export const duplicateAssembly = (assemblyId: number, userId: number, name: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(setAssembliesLoaded(false));
        dispatch(setStatus(Status.Loading))
        const res = await projectManagerService.duplicateAssembly(assemblyId, userId, name);
        const pm = extractData(res, dispatch);
        if (pm && pm.recentAssembly && pm.recentAssembly.assemblyInfo) {
            const parsedAssembly = ToAssemblyData(pm.recentAssembly);
            dispatch(setCurrentAssembly(parsedAssembly));
            dispatch(actions.addAssembly(pm.recentAssembly));
            dispatch(setRecentAssemblyId(pm.recentAssembly.id));
            dispatch(setStatus(Status.Active));
        } else {
            dispatch(showAssemblyWizard(true));
        }
        dispatch(setAssembliesLoaded(true));
    });
}

export const updateAssembly = (assemblyToUpdate: IAssembly, userId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateAssembly(assemblyToUpdate, userId);
        const assembly = extractData(res, dispatch);
        if (assembly) {
            dispatch(actions.updateAssembly(assembly));
            dispatch(setStatus(Status.Active));
        }
    });
}

export const updateAssemblyInfo = (info: IAssemblyInfo) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateAssemblyInfo(info)) // Pre-update for the UI
        const service = new AssemblyService();
        const res = await service.updateInfo(info);
        const updatedInfo = extractData(res, dispatch);
        if (updatedInfo) {
            dispatch(setStatus(Status.Active));
        } else {
            console.error("Something wrong happened when trying to update the assembly info");
        }
    });
}

export const savePropertiesAndPrintPDF = (info: IAssemblyInfo, printPDF: (saveAsImage?: boolean | undefined, info?: IAssemblyInfo) => Promise<void>) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateAssemblyInfo(info)) // Pre-update for the UI
        const service = new AssemblyService();
        const res = await service.updateInfo(info);
        const updatedInfo = extractData(res, dispatch);
        if (updatedInfo) {
            dispatch(setStatus(Status.Printing));
            printPDF(false, info);
            dispatch(showPrintDialog(false));
        } else {
            console.error("Something wrong happened when trying to print the PDF report");
        }
    });
}

export const updateAssemblyPalette = (palette: IAssemblyPalette) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateAssemblyPalette(palette)) // Pre-update for the UI
        const service = new AssemblyService();
        const res = await service.updatePalette(palette);
        const updatedPalette = extractData(res, dispatch);
        if (!updatedPalette) {
            console.error("Something wrong happened when trying to update the assembly palette");
        }
    });
}

export const updateAssemblyAnnotation = (annotation: IAssemblyAnnotation, snackbarDispatch: Dispatch<SetStateAction<boolean>>) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateAssemblyAnnotation(annotation);
        const updatedAnnotation = extractData(res, dispatch);
        if (updatedAnnotation) {
            dispatch(actions.updateAssemblyAnnotation(updatedAnnotation));
            snackbarDispatch(true);
            dispatch(setStatus(Status.Active));
        }
    });
}

export const updateAssemblyCalloutDesc = (annotation: IAssemblyAnnotation) => {
    return createSecuredAsyncAction(async (dispatch) => { 
        dispatch(actions.updateAssemblyAnnotation(annotation));  
        const service = new AssemblyService();
        const res = await service.updateAssemblyAnnotation(annotation);
        const updatedAnnotation = extractData(res, dispatch);
        if (updatedAnnotation) {
            dispatch(actions.updateAssemblyAnnotation(updatedAnnotation));
            dispatch(setStatus(Status.Active));
        }
    });
}

export const updateOverallLength = (overallLength: Length) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.setupOverallLength(overallLength)); // Pre-update for the UI
        const service = new AssemblyService();
        const res = await service.updateLength(overallLength);
        const updatedOverallLength = extractData(res, dispatch);
        if (updatedOverallLength) {
            dispatch(actions.setupOverallLength(updatedOverallLength));
        }
    });
}

export const updateBreakout = (breakout: IBreakout, applyToAll: boolean, previousConnectorType: string, persistChanges: boolean = false, originalBreakouts?: IBreakout[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateBreakout(breakout)); // Pre-update for the UI
        if (applyToAll && breakout.furcation.jacketColor) {
            const newConnectorType = breakout.furcation.groups[0].connectors[0].type;
            dispatch(actions.updateFurcations({ side: breakout.side, jacketColor: breakout.furcation.jacketColor, previousConnectorType, newConnectorType, originalBreakouts, id: breakout.id }));
        }

        if (persistChanges) {
            const service = new AssemblyService();
            const res = await service.updateBreakout(breakout, applyToAll);
            const updatedBreakout = extractData(res, dispatch);
            if (!updatedBreakout) {
                console.warn("Something wrong happened when trying to update the breakout", breakout);
            }
        }
    })
}

export const updateTrunk = (trunk: ICableTrunk) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateTrunk(trunk);
        const updatedTrunk = extractData(res, dispatch);
        if (!updatedTrunk) {
            console.warn("Something wrong happened when trying to update the trunk", trunk);
        }
    })
}

export const updateConnector = (connector: IConnector) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateConnector(connector);
        const updatedConnector = extractData(res, dispatch);
        if (updatedConnector) {
            dispatch(setStatus(Status.Active));
        } else {
            console.warn("Something wrong happened when trying to update the connector", connector);
        }
    });
}

export const updateConnectors = (connectors: IConnector[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateConnectors(connectors);
        const updatedConnectors = extractData(res, dispatch);
        if (updatedConnectors) {
            dispatch(setStatus(Status.Active));
        } else {
            console.warn("Something wrong happened when trying to update the connectors", connectors);
        }
    });
}

export const updateConnectorAssignment = (assignment: IConnectorAssignmentMap, polarityDispatch: Dispatch<AnyAction>) => {
    const { userId } = assignment.fiberMapData;
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateConnectorAssignment(assignment);
        const updatedConnectorAssignment = extractData(res, dispatch);
        if (updatedConnectorAssignment) {
            dispatch(actions.updateConnectorAssignment(updatedConnectorAssignment));
            polarityDispatch(setConnectorAssignmentToUpdate(updatedConnectorAssignment));
            
            const { fiberMapData } = updatedConnectorAssignment;
            if (userId) {
                const userFiberMapData: IFiberMapData = {
                    ...fiberMapData,
                    id: undefined,
                    userId: assignment.fiberMapData.userId,
                    sourceIndices: fiberMapData.sourceIndices.map(i => { return { ...i, id: undefined } }),
                    destinationIndices: fiberMapData.destinationIndices.map(i => { return { ...i, id: undefined } })
                }
                dispatch(addOrUpdateUserFiberMap(userFiberMapData));
            }
            dispatch(setStatus(Status.Active));
        }
    });
}

export const updateMultipleConnectorAssignments = (assignmentsData: IConnectorAssignmentMap[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateMultipleConnectorAssignments(assignmentsData);
        const assignments = extractData(res, dispatch);
        if(assignments){
            batch(() => {
                dispatch(actions.updateConnectorAssignments(assignments));
                dispatch(setForceUpdate(true));
                dispatch(setStatus(Status.Active));
            })
        }
    });
}

export const updateAssignmentConnectorTypes = (assemblyId: number, applyAll: boolean, side: Side, breakoutPosition: number, previousType: string, newType: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.updateAssignmentsConnectorTypes(assemblyId, applyAll, side, breakoutPosition, previousType, newType);
        const updatedAssignments = extractData(res, dispatch);
        if (updatedAssignments) {
            if (updatedAssignments.length > 0) {
                dispatch(actions.updateConnectorAssignments(updatedAssignments));
                dispatch(setForceUpdate(true));
            }
            dispatch(setStatus(Status.Active));
        }
    })
}

export const deleteAssembly = (userId: number, assemblyId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.deleteAssembly(assemblyId));
        await projectManagerService.deleteProjectAssembly(assemblyId, userId);
    });
}

export const deleteConnectorAssignment = (assignmentId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.deleteConnectorAssignment(assignmentId);
        if (res.succesful) {
            dispatch(actions.deleteConnectorAssignment(assignmentId));
            dispatch(setStatus(Status.Active));
        }
    });
}

export const deleteConnectorAssignments = (polarityId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new AssemblyService();
        const res = await service.deleteConnectorAssignments(polarityId);
        if (res.succesful) {
            dispatch(actions.deleteConnectorAssignments());
            dispatch(setStatus(Status.Active));
        }
    });
}