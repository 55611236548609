import React from 'react';
import { AssemblyInfoRowProps } from '../types';
import { FontTokens, Typography } from '@orbit/theme-provider';

export const AssemblyInfo: React.FC<AssemblyInfoRowProps> = ({name, description}) => {
    return (
        <div className="assembly-info">
            <div className="assembly-name">
                <Typography variant="h6" label={name ?? ""} fontToken={FontTokens.none} />
            </div>
            <div className="assembly-description">
                <Typography variant="body2" label={description ?? ""} fontToken={FontTokens.none} />
            </div>
        </div>
    )
}