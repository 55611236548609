import React from "react"

export const MDC: React.FC = () => {
    return (
        <svg id="Group_1504" data-name="Group 1504" xmlns="http://www.w3.org/2000/svg" width="130" height="700" viewBox="0 0 13 62">
            <path id="Path_520" data-name="Path 520" d="M12.9,30.2,8.908,22.7c0-.17-.145-.263-.311-.289L7.909,5.8h0v-3h0l1-2V0H.923V.8l1,2v3h0l-.1,4.1H5.115v1H1.821l-.1,3H5.115v1H1.621l-.1,3H5.115v1H1.421l-.133,3.685L.2,23.576a.758.758,0,0,0-.2.4L.024,34.5a.472.472,0,0,0,.5.5H8.408V52.5l1,1h1l1-1V41a.472.472,0,0,0,.5-.5V35h.6a.472.472,0,0,0,.5-.5V30.4A.349.349,0,0,1,12.9,30.2ZM2.819,8.8l.1-2H7.011l.1,3h-1v-1Zm-.1,4.1v-1H6.113v-1h1.1l.1,3h-1.2v-1Zm-.2,3.9v-1H6.013v-1h1.2l.1,3h-1.2v-1Zm3.594,3v-1h1.4l.13,3.61-5.326.058L2.419,19.8Z" fill="#484646"/>
            <g id="Group_1501" data-name="Group 1501">
                <g id="Group_1500" data-name="Group 1500">
                    <path id="Rectangle_474" data-name="Rectangle 474" d="M2.02,59h2v2a.945.945,0,0,1-1,1h0a1,1,0,0,1-1-1Z" fill="#c4c4c4"/>
                    <path id="Rectangle_474-2" data-name="Rectangle 474" d="M5.913,59h2v2a.944.944,0,0,1-1,1h0a1,1,0,0,1-1-1Z" fill="#c4c4c4"/>
                </g>
                <path id="Path_521" data-name="Path 521" d="M8.408,58.5v-5h1V30.4h-2.5v4H3.018v-4H.523V53.5h1v5Z" fill="#045eaa"/>
                <path id="Path_522" data-name="Path 522" d="M.024,54V29.9H3.517v4h3v-4H9.906V54h-1v5H1.022V54Zm1-23.1V53h1v5H8.009V53h1V30.9H7.41v4H2.419v-4Z" fill="#484646"/>
            </g>
            <rect id="Rectangle_1150" data-name="Rectangle 1150" width="7.985" height="1" transform="translate(1.022 37.9)" fill="#484646"/>
            <path id="AB" d="M7.325,36.879a.483.483,0,0,1,.335-.465h0a.458.458,0,0,1-.4-.468c0-.267.2-.609.791-.609.143,0,.318.006.516.009v2.025s-.282.012-.551.012C7.538,37.38,7.325,37.185,7.325,36.879Zm.288-.042c0,.18.1.324.425.324.075,0,.17-.006.249-.009v-.66H8.081c-.313,0-.469.123-.469.345Zm-.066-.912c0,.228.153.356.535.356h.2v-.708a2.049,2.049,0,0,0-.26-.014c-.33,0-.473.159-.473.366ZM2.081,37.368,1.36,35.343h.308l.165.483h.859l.168-.483h.282l-.725,2.025Zm.093-.546c.033.1.06.2.087.285h0c.024-.09.054-.186.084-.282l.274-.78H1.895Z" fill="#fff"/>
            <g id="Group_1502" data-name="Group 1502">
                <rect id="Rectangle_1241" data-name="Rectangle 1241" width="1.996" height="1" transform="translate(4.017 41.4)" fill="#484646"/>
            </g>
            <g id="Group_1503" data-name="Group 1503">
                <rect id="Rectangle_1242" data-name="Rectangle 1242" width="0.998" height="1" transform="translate(4.516 44.4)" fill="#484646"/>
            </g>
            <path id="Path_523" data-name="Path 523" d="M6.412,54.5H3.418v-7H6.412v7m1,1v-9H2.419v9H7.41Z" fill="#484646"/>
        </svg>
    )
}

export const getMDCSVGString = (color: string) => {
    return `
        <svg id="Group_1504" data-name="Group 1504" xmlns="http://www.w3.org/2000/svg" width="130" height="620" viewBox="0 0 13 62">
            <path id="Path_520" data-name="Path 520" d="M12.9,30.2,8.908,22.7c0-.17-.145-.263-.311-.289L7.909,5.8h0v-3h0l1-2V0H.923V.8l1,2v3h0l-.1,4.1H5.115v1H1.821l-.1,3H5.115v1H1.621l-.1,3H5.115v1H1.421l-.133,3.685L.2,23.576a.758.758,0,0,0-.2.4L.024,34.5a.472.472,0,0,0,.5.5H8.408V52.5l1,1h1l1-1V41a.472.472,0,0,0,.5-.5V35h.6a.472.472,0,0,0,.5-.5V30.4A.349.349,0,0,1,12.9,30.2ZM2.819,8.8l.1-2H7.011l.1,3h-1v-1Zm-.1,4.1v-1H6.113v-1h1.1l.1,3h-1.2v-1Zm-.2,3.9v-1H6.013v-1h1.2l.1,3h-1.2v-1Zm3.594,3v-1h1.4l.13,3.61-5.326.058L2.419,19.8Z" fill="#484646"/>
            <g id="Group_1501" data-name="Group 1501">
                <g id="Group_1500" data-name="Group 1500">
                    <path id="Rectangle_474" data-name="Rectangle 474" d="M2.02,59h2v2a.945.945,0,0,1-1,1h0a1,1,0,0,1-1-1Z" fill="#c4c4c4"/>
                    <path id="Rectangle_474-2" data-name="Rectangle 474" d="M5.913,59h2v2a.944.944,0,0,1-1,1h0a1,1,0,0,1-1-1Z" fill="#c4c4c4"/>
                </g>
                <path id="Path_521" data-name="Path 521" d="M8.408,58.5v-5h1V30.4h-2.5v4H3.018v-4H.523V53.5h1v5Z" fill="${color}"/>
                <path id="Path_522" data-name="Path 522" d="M.024,54V29.9H3.517v4h3v-4H9.906V54h-1v5H1.022V54Zm1-23.1V53h1v5H8.009V53h1V30.9H7.41v4H2.419v-4Z" fill="#484646"/>
            </g>
            <rect id="Rectangle_1150" data-name="Rectangle 1150" width="7.985" height="1" transform="translate(1.022 37.9)" fill="#484646"/>
            <path id="AB" d="M7.325,36.879a.483.483,0,0,1,.335-.465h0a.458.458,0,0,1-.4-.468c0-.267.2-.609.791-.609.143,0,.318.006.516.009v2.025s-.282.012-.551.012C7.538,37.38,7.325,37.185,7.325,36.879Zm.288-.042c0,.18.1.324.425.324.075,0,.17-.006.249-.009v-.66H8.081c-.313,0-.469.123-.469.345Zm-.066-.912c0,.228.153.356.535.356h.2v-.708a2.049,2.049,0,0,0-.26-.014c-.33,0-.473.159-.473.366ZM2.081,37.368,1.36,35.343h.308l.165.483h.859l.168-.483h.282l-.725,2.025Zm.093-.546c.033.1.06.2.087.285h0c.024-.09.054-.186.084-.282l.274-.78H1.895Z" fill="#fff"/>
            <g id="Group_1502" data-name="Group 1502">
                <rect id="Rectangle_1241" data-name="Rectangle 1241" width="1.996" height="1" transform="translate(4.017 41.4)" fill="#484646"/>
            </g>
            <g id="Group_1503" data-name="Group 1503">
                <rect id="Rectangle_1242" data-name="Rectangle 1242" width="0.998" height="1" transform="translate(4.516 44.4)" fill="#484646"/>
            </g>
            <path id="Path_523" data-name="Path 523" d="M6.412,54.5H3.418v-7H6.412v7m1,1v-9H2.419v9H7.41Z" fill="#484646"/>
        </svg>
    `
}