import React from 'react';
import { useFiberMappingDialog } from './hooks';
import { Dialog } from '@orbit/dialog';
import { OutlinedButton, TextButton, ContainedButton } from '@orbit/button';
import { FiberMappingContext } from './reducer/types';
import { FormControl, MenuItem, Select } from '@mui/material';
import { FiberMappingConnector } from './connector/FiberMappingConnector';
import { Sides } from '../../assembly/breakout/types';
import { InputDialog } from '../../../ui/dialog/input/InputDialog';
import { Typography } from '@orbit/theme-provider';

import './FiberMappingDialog.scss';

export const FiberMappingDialog : React.FC = () => {
    const { dialogProps, clearAll, fiberMappingContext, polarityType, sideA, sideB, cancel, save, saveAs } = useFiberMappingDialog();
    if (!dialogProps.open) {
        return null;
    }
    return (
        <Dialog {...dialogProps}>
            <div className="subheader-container">
                <div className="polarity-type" tabIndex={0}>
                    <Typography variant="subtitle1" label={polarityType} />
                </div>
                <OutlinedButton {...clearAll.buttonProps}>
                    {clearAll.label}
                </OutlinedButton>
            </div>
            <FiberMappingContext.Provider value={fiberMappingContext}>
                <div className="mapping-container">
                    <div className="selection-container">
                        <FormControl className='select'>
                            <Select {...sideA.breakouts.select}>
                                { sideA.breakouts.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl className='select'>
                            <Select {...sideA.connectors.select}>
                                { sideA.connectors.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <FiberMappingConnector side={Sides.SideA} type={sideA.connectorType} />
                </div>
                <div className="mapping-container">
                    <div className="selection-container">
                        <FormControl className='select'>
                            <Select {...sideB.breakouts.select}>
                                { sideB.breakouts.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl className='select'>
                            <Select {...sideB.connectors.select}>
                                { sideB.connectors.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <FiberMappingConnector side={Sides.SideB} type={sideB.connectorType} />
                </div>
            </FiberMappingContext.Provider>
            <div className="mapping-action-container">
                <TextButton {...cancel.buttonProps}>
                    {cancel.label}
                </TextButton>
                <ContainedButton {...save.buttonProps}>
                    {save.label}
                </ContainedButton>
                <ContainedButton {...saveAs.buttonProps}>
                    {saveAs.label}
                </ContainedButton>
            </div>
            <Dialog {...clearAll.dialogProps} />
            <InputDialog {...saveAs.inputDialogProps} />
            <Dialog {...saveAs.confirmDialogProps} />
        </Dialog>
    );
}