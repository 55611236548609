import React from 'react';
import { useConnectorAssignmentRow } from './hooks';
import { TableRow, TableCell } from "@mui/material"
import { IConnectorAssignmentRowProps } from './types';
import { TableRowSelectionIcon } from '../../../../ui/icon/TableRowSelectionIcon';
import { IconButton } from '@orbit/icon-button';
import { Cancel, CheckCircle, Edit, Link, LinkOff } from '@mui/icons-material';

export const ConnectorAssignmentRow: React.FC<IConnectorAssignmentRowProps> = (props) => {
    const { rowProps, assigned, assignedIconProps, selected, selectionIconProps, connectorInfo, editable, deleteProps, deleteIconProps, editProps, editIconProps, propagated, propagationIconProps, propagationTableCellProps } = useConnectorAssignmentRow(props);

    return (
        <TableRow {...rowProps}>
            <TableCell className="assignment-icon">
                { assigned && !selected ? <IconButton {...assignedIconProps} icon={<CheckCircle />} /> : <TableRowSelectionIcon {...selectionIconProps} /> }
            </TableCell>
            <TableCell className="connector-info">
                {connectorInfo}
            </TableCell>
            {
                editable ?
                <>
                    <TableCell {...deleteProps}>
                        <IconButton {...deleteIconProps} icon={<Cancel />} />
                    </TableCell>
                    <TableCell {...editProps}>
                        <IconButton {...editIconProps} icon={<Edit />} />
                    </TableCell>
                </> : null
            }
            {
                propagated !== undefined ? 
                    <>
                        <TableCell {...propagationTableCellProps}>
                            <IconButton {...propagationIconProps} icon={ propagated ? <Link /> : <LinkOff /> }/>
                        </TableCell>
                    </>
                :null
            }
        </TableRow>
    );
}