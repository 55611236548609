import { CableEnvironments, Shapes, CableEnvironment, Shape, LSZH, FiberCounts, PullingGrips, FiberType, FiberTypes, CableType, CableTypes, FurcationOuterDiameters } from "../../../assembly/types";
import { Length, Units } from "../../../assembly/length/types";


export const initialGeneralSetupState: IGeneralSetupState = {
    shape: Shapes.Symmetrical,
    fiberCount: FiberCounts[6],
    flameRating: LSZH.value,
    type: CableTypes.Fanout,
    fiberType: FiberTypes.SMOS2,
    environment: CableEnvironments.Indoor,
    overallLength: { value: 0, unit: Units.Meter },
    lengthA: { value: 150, unit: Units.Millimeter },
    lengthB: { value: 150, unit: Units.Millimeter },
    pullingGrip: PullingGrips.None,
    furcationOuterDiameter: FurcationOuterDiameters[0]
}

export interface IGeneralSetupState {
    shape: Shape;
    fiberCount: number;
    type: CableType;
    fiberType: FiberType;
    environment: CableEnvironment;
    overallLength: Length;
    lengthA: Length;
    lengthB: Length;
    flameRating: string;
    pullingGrip: string;
    furcationOuterDiameter: string;
}